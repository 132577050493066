import applyTextureModificationToSpineAssetInstance from 'src/utils/businessLogic/foSpineAsset/applyTextureModificationToSpineAssetInstance';
import loadFoTextureComps from 'src/utils/businessLogic/foTextureComp/loadFoTextureComps';
import destroyFoTextureCompDisplayObject from 'src/utils/businessLogic/foTextureComp/destroyFoTextureCompDisplayObject';
import getFoTextureCompsOfFoSpineAsset from 'src/reduxStore/utils/getFoTextureCompsOfFoSpineAsset';
import clearAllTextureModificationsFromSpineAssetInstance from 'src/utils/businessLogic/foSpineAsset/clearAllTextureModificationsFromSpineAssetInstance';

export default async function applyTextureModificationsToFoSpineAsset({
    app,
    spineAssetInstance,
    foSpineAsset,
    foTextureComps,
}) {
    if (!spineAssetInstance) {
        throw new Error('Cannot applyTextureModifications without spineAssetInstance. ');
    }

    const relatedFoTextureComps = getFoTextureCompsOfFoSpineAsset({ foSpineAsset, foTextureComps });
    const foTextureCompsDisplayObjects = await loadFoTextureComps({
        loader: app.loader,
        foTextureComps: relatedFoTextureComps,
    });

    clearAllTextureModificationsFromSpineAssetInstance(spineAssetInstance);
    const textureModifications = foSpineAsset.modifications.textures;
    textureModifications.forEach((textureModification) => {
        const { current: foTextureCompId } = textureModification;
        if (!foTextureCompId) {
            return;
        }

        const textureModificationAtom = textureModification.atomsByCompId[foTextureCompId];

        const foTextureCompDisplayObject = foTextureCompsDisplayObjects.find((displayObject) => {
            return displayObject.id === foTextureCompId;
        });

        applyTextureModificationToSpineAssetInstance({
            app,
            foSpineAsset,
            spineAssetInstance,
            foTextureCompDisplayObject,
            textureModificationAtom,
        });
    });

    foTextureCompsDisplayObjects.forEach((foTextureCompDisplayObject) => {
        const foTextureCompId = foTextureCompDisplayObject.id;
        const foTextureComp = foTextureComps.byId[foTextureCompId];
        destroyFoTextureCompDisplayObject({
            foTextureComp,
            displayObject: foTextureCompDisplayObject,
        });
    });
}
