import { useState, useCallback } from 'react';

import updateFoTexturesZIndices from 'src/components/views/TextureRouteManager/TextureEditor/utils/updateFoTexturesZIndices';
import makeFoTextureComp from 'src/utils/businessLogic/foTextureComp/makeFoTextureComp';

export default function useLocalFoTextureComp({ existingFoTextureComp, initialName }) {
    const [foTextureComp, setFoTextureComp] = useState(function _initializedFoTextureComp() {
        if (existingFoTextureComp) {
            const writableCloneOfImmerObject = JSON.parse(JSON.stringify(existingFoTextureComp));
            return writableCloneOfImmerObject;
        }
        const newFoTextureComp = makeFoTextureComp({ name: initialName });
        return newFoTextureComp;
    });

    const updateFoTextures = useCallback(
        function _updateFoTextures(newFoTextures) {
            updateFoTexturesZIndices(newFoTextures);
            setFoTextureComp({ ...foTextureComp, foTextures: newFoTextures });
        },
        [foTextureComp, setFoTextureComp],
    );

    const setFoTextureCompName = useCallback(
        function _setFoTextureCompName(name) {
            setFoTextureComp({ ...foTextureComp, name });
        },
        [foTextureComp, setFoTextureComp],
    );

    return {
        foTextureComp,
        updateFoTextures,
        setFoTextureCompName,
    };
}
