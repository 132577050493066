import getMaskedDisplayObjectContent from 'src/pixi/tools/MaskTool/utils/getMaskedDisplayObjectContent';

export default function clearMaskTool(maskTool) {
    maskTool.visible = false;

    const { target } = maskTool;

    if (!target) {
        return;
    }

    const content = getMaskedDisplayObjectContent(target);
    content.cursor = 'pointer';

    delete target.maskTool;
    delete maskTool.target;

    if (maskTool.maskOutline) {
        maskTool.maskOutline.destroy();
        delete maskTool.maskOutline;
    }

    if (maskTool.pointControlsById) {
        Object.values(maskTool.pointControlsById).forEach((pointControl) => {
            pointControl.destroy();
        });
        delete maskTool.pointControlsById;
    }

    maskTool.removeChildren();
    maskTool.addChild(maskTool.bgGhostLayer);
}
