import { createSlice } from '@reduxjs/toolkit';
import { videoExportFormats } from 'src/components/views/SceneEditor/Monitor/VideoDownloadButton/constants';

const initialState = {
    fileName: 'my video',
    format: videoExportFormats.MOV,
    frameRate: 30,
    quality: 50,
    startTime: 0,
    endTime: 3,
    isIncludeAudio: true,
    isDownloadFrames: false,
    videoDownloadScale: 0.5,
    videoDownloadPaddingPercentX: 1,
    videoDownloadPaddingPercentY: 0.3,
};

const videoDownloadFormSlice = createSlice({
    name: 'videoDownloadForm',
    initialState,
    reducers: {
        setFileName(state, action) {
            state.fileName = action.payload;
        },
        setFormat(state, action) {
            state.format = action.payload;
        },
        setFrameRate(state, action) {
            state.frameRate = action.payload;
        },
        setQuality(state, action) {
            state.quality = action.payload;
        },
        setStartTime(state, action) {
            state.startTime = action.payload;
        },
        setEndTime(state, action) {
            state.endTime = action.payload;
        },
        setIsIncludeAudio(state, action) {
            state.isIncludeAudio = action.payload;
        },
        setIsDownloadFrames(state, action) {
            state.isDownloadFrames = action.payload;
        },
        setVideoDownloadScale(state, action) {
            state.videoDownloadScale = action.payload;
        },
        setVideoDownloadPaddingPercentX(state, action) {
            state.videoDownloadPaddingPercentX = action.payload;
        },
        setVideoDownloadPaddingPercentY(state, action) {
            state.videoDownloadPaddingPercentY = action.payload;
        },
    },
});

export const {
    setFileName,
    setFormat,
    setFrameRate,
    setQuality,
    setStartTime,
    setEndTime,
    setIsIncludeAudio,
    setIsDownloadFrames,
    setVideoDownloadScale,
    setVideoDownloadPaddingPercentX,
    setVideoDownloadPaddingPercentY,
} = videoDownloadFormSlice.actions;

export default videoDownloadFormSlice.reducer;
