/**
 * @amount Number, in seconds
 */
export default function fastForwardSpineInstance({ spineAssetInstance, amount, deltaStep = 0.01 }) {
    let currFastForwardAmount = 0;
    do {
        const delta = Math.min(amount - currFastForwardAmount, deltaStep);
        currFastForwardAmount += delta;

        spineAssetInstance.update(delta);
    } while (currFastForwardAmount < amount);
}
