import TransformTool from 'src/pixi/tools/TransformTool';
import TOOL_Z_INDICES from 'src/components/views/TextureRouteManager/TextureEditor/constants/toolZIndices';
import * as eventNames from 'src/pixi/tools/TransformTool/constants/eventNames';

export default function getTransformTool({ transformToolRef, app, handleTransformToolDragEnd }) {
    let transformTool = transformToolRef.current;
    if (!transformTool) {
        transformTool = new TransformTool({
            app,
        });
        transformTool.addEventHandler(eventNames.ANY_DRAG_END, handleTransformToolDragEnd);
        transformToolRef.current = transformTool;
        app.stage.addChild(transformTool);
        app.stage.sortableChildren = true;
        transformTool.zIndex = TOOL_Z_INDICES.MASK_TOOL;
    }

    return transformTool;
}
