import 'pixi.js';
import 'pixi-spine';

import 'src/spine/utils/debugger';
import 'src/spine/utils/monkeyPatchSpine';
import 'src/spine/utils/peventUsingDefaultAtlasParser';

export default function loadSpineAsset({
    skeletonName,
    skeletonPath,
    loader,
    preLoadedSkeletonData,
}) {
    if (loader.resources[skeletonName]) {
        return Promise.resolve({ resources: loader.resources });
    }

    return new Promise(function _executor(resolve, reject) {
        // todo - now that we are using the same pixi app througout funimoto, this might not be necessary.
        // in fact we might need to check resources before loading spine
        PIXI.utils.clearTextureCache();

        function onLoad(loader, resources) {
            const resource = resources[skeletonName];
            if (preLoadedSkeletonData) {
                resource.data = preLoadedSkeletonData;
            }
            PIXI.spine.AtlasParser.originalUse.call(loader, resource, () => {
                resolve({ resources });
            });
        }

        loader.add(skeletonName, skeletonPath).load(onLoad);
        loader.onError.add((error) => {
            reject(error);
        });
    });
}
