import movePivotToGlobalPositionWithoutMovingParent from 'src/pixi/utils/pivotUtils/movePivotToGlobalPositionWithoutMovingParent';

export default function restoreFoTextureCompAfterRender({
    foTextureCompDisplayObject: displayObject,
    slotContainer,
    correctionAngle,
    originalPivotGlobal,
}) {
    const spineDisplayObject = slotContainer.children[0];
    const { attachment } = spineDisplayObject;

    const isMesh = attachment.constructor.name === 'MeshAttachment';
    if (!isMesh) {
        displayObject.rotation -= correctionAngle;
        spineDisplayObject.rotation += correctionAngle;
        movePivotToGlobalPositionWithoutMovingParent({
            displayObject,
            position: originalPivotGlobal,
        });
    }
}
