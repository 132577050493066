import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import Line from 'src/components/shared/Line';
import useRenameModeManager from 'src/components/views/TextureRouteManager/TextureManager/FoTextureCompList/FoTextureCompListItem/hooks/useRenameModeManager';
import useHandleLabelClick from 'src/components/views/TextureRouteManager/TextureManager/FoTextureCompList/FoTextureCompListItem/hooks/useHandleLabelClick';
import useHandleNameInputChange from 'src/components/views/TextureRouteManager/TextureManager/FoTextureCompList/FoTextureCompListItem/hooks/useHandleNameInputChange';
import EditableTextBox from 'src/components/shared/EditableTextBox';
import SelectableListItem from 'src/components/shared/SelectableListItem';
import { foTextureCompLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import {
    setFoTextureComp,
    setFoSpineAssetLinkOnFoTextureComp,
} from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice';
import { setEditingFoTextureCompId } from 'src/components/views/TextureRouteManager/TextureEditor/textureEditorSlice';
import { setFoSpineAssetTextureModifications } from 'src/components/views/AssetSelector/assetSelectorSlice';
import getSelectedTrackFoSpineAsset from 'src/reduxStore/utils/getSelectedTrackFoSpineAsset';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import getFoTextureCompIdCurrentlyAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getFoTextureCompIdCurrentlyAssignedToSlot';
import withRouter from 'src/reduxStore/utils/withRouter';

import Buttons from './Buttons';

import styles from './styles.module.scss';

function FoTextureCompListItem(props) {
    const {
        className,
        foTextureComp,
        setFoTextureComp,
        setEditingFoTextureCompId,
        setFoSpineAssetTextureModifications,
        foSpineAsset,
        selectedSlotName,
        setFoSpineAssetLinkOnFoTextureComp,
        ...attributes
    } = props;

    const { name } = foTextureComp;

    const selectedFoTextureCompId = getFoTextureCompIdCurrentlyAssignedToSlot({
        foSpineAsset,
        slotName: selectedSlotName,
    });
    const isSelected = foTextureComp.id === selectedFoTextureCompId;
    const { toggleRenameMode, isEditingName } = useRenameModeManager({
        isSelected,
    });
    const handleNameInputChange = useHandleNameInputChange({
        foTextureComp,
        setFoTextureComp,
    });

    const handleLabelClick = useHandleLabelClick({
        foTextureComp,
        isSelected,
        selectedSlotName,
        foSpineAsset,
        setFoSpineAssetTextureModifications,
        setFoSpineAssetLinkOnFoTextureComp,
    });

    return (
        <SelectableListItem
            className={classnames(styles.FoTextureCompListItem, className)}
            isSelected={isSelected}
            {...attributes}
        >
            <EditableTextBox
                isEditing={isEditingName}
                inputProps={{
                    value: name,
                    onChange: handleNameInputChange,
                }}
                labelProps={{
                    onClick: handleLabelClick,
                }}
            />
            {isSelected && <Line />}
            {isSelected && (
                <Buttons
                    foTextureCompId={foTextureComp.id}
                    toggleRenameMode={toggleRenameMode}
                    isEditingName={isEditingName}
                    setEditingFoTextureCompId={setEditingFoTextureCompId}
                />
            )}
        </SelectableListItem>
    );
}

FoTextureCompListItem.propTypes = {
    className: PropTypes.string,
    foTextureComp: foTextureCompLike.isRequired,
    foSpineAsset: foSpineAssetLike.isRequired,
    selectedSlotName: PropTypes.string.isRequired,
    setFoTextureComp: PropTypes.func.isRequired,
    setEditingFoTextureCompId: PropTypes.func.isRequired,
    setFoSpineAssetTextureModifications: PropTypes.func.isRequired,
    setFoSpineAssetLinkOnFoTextureComp: PropTypes.func.isRequired,
};

FoTextureCompListItem.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { textureEditor } = state;
    const { selectedSlotName } = textureEditor;

    const { sceneId: selectedSceneId } = ownProps.router.params;
    const foSpineAsset = getSelectedTrackFoSpineAsset(state, { selectedSceneId });

    return {
        foSpineAsset,
        selectedSlotName,
    };
}

export default withRouter(
    connect(mapState, {
        setFoTextureComp,
        setEditingFoTextureCompId,
        setFoSpineAssetTextureModifications,
        setFoSpineAssetLinkOnFoTextureComp,
    })(FoTextureCompListItem),
);
