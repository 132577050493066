import getPivotPercent from 'src/pixi/utils/pivotUtils/getPivotPercent';
import getGlobalScale from 'src/pixi/utils/getGlobalScale';

const OFFSET = 15;

export default function positionRotateKnobs(transformTool) {
    const { target } = transformTool;

    const pivotPercent = getPivotPercent(target);
    const targetGlobalScale = getGlobalScale(target);

    Object.values(transformTool.rotateKnobs).forEach((rotateKnob) => {
        const knobToPivotDistancePercent = {
            x: rotateKnob.gridPosition.x - pivotPercent.x,
            y: rotateKnob.gridPosition.y - pivotPercent.y,
        };
        const knobToPivotDistance = {
            x: knobToPivotDistancePercent.x * target.originalSize.width * targetGlobalScale.x,
            y: knobToPivotDistancePercent.y * target.originalSize.height * targetGlobalScale.y,
        };
        const gridPositionSign = {
            x: Math.sign(rotateKnob.gridPosition.x - 0.5),
            y: Math.sign(rotateKnob.gridPosition.y - 0.5),
        };
        const scaleSign = {
            x: Math.sign(targetGlobalScale.x),
            y: Math.sign(targetGlobalScale.y),
        };
        const offsetKnobToPivotDistance = {
            x: knobToPivotDistance.x + gridPositionSign.x * OFFSET * scaleSign.x,
            y: knobToPivotDistance.y + gridPositionSign.y * OFFSET * scaleSign.y,
        };
        rotateKnob.position.set(offsetKnobToPivotDistance.x, offsetKnobToPivotDistance.y);
    });
}
