import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

export default function SelectableList(props) {
    const { className, children, nodeRef, ...attributes } = props;

    return (
        <ul className={classnames('SelectableList', className)} ref={nodeRef} {...attributes}>
            {children}
        </ul>
    );
}

SelectableList.propTypes = {
    className: PropTypes.string,
};

SelectableList.defaultProps = {
    className: '',
};
