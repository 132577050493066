import React from 'react';
import { Navigate } from 'react-router-dom';

import * as routes from 'src/components/constants/routes';

export default function Home() {
    return <Navigate to={`/${routes.SCENES}`} />;
}

Home.propTypes = {};

Home.defaultProps = {};
