import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StyledForm from 'src/components/shared/formFactory/StyledForm';
import LoadingToolButton from 'src/components/shared/buttonFactory/LoadingToolButton';
import FormElementGroup from 'src/components/shared/FormElementGroup';
import {
    sceneLike,
    trackFoSpineAssetLike,
} from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import { setScene } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import useHandleSubmitSceneEditorForm from 'src/components/views/SceneSelector/SceneEditorForm/hooks/useHandleSubmitSceneEditorForm';

import styles from './styles.module.scss';

function SceneEditorForm(props) {
    const { className, scene, closeEditSceneForm, setScene, trackFoSpineAsset } = props;

    const [sceneName, setSceneName] = useState(scene?.name || 'My Awesome Scene');
    const handleSceneNameChange = useCallback(
        function _handleSceneNameChange(event) {
            setSceneName(event.target.value);
        },
        [setSceneName],
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitSceneUpdate = useHandleSubmitSceneEditorForm({
        trackFoSpineAsset,
        setScene,
        setIsLoading,
        sceneId: scene?.id,
        closeEditSceneForm,
        sceneName,
    });

    return (
        <StyledForm
            headerContent={scene ? 'Edit scene' : 'Create scene'}
            className={classnames(styles.SceneEditorForm, className)}
            onSubmit={handleSubmitSceneUpdate}
        >
            <FormElementGroup
                label='Name'
                elementProps={{
                    value: sceneName,
                    onChange: handleSceneNameChange,
                }}
            />

            <LoadingToolButton type='submit' isLoading={isLoading}>
                Submit
            </LoadingToolButton>
        </StyledForm>
    );
}

SceneEditorForm.propTypes = {
    className: PropTypes.string,
    closeEditSceneForm: PropTypes.func.isRequired,
    setScene: PropTypes.func.isRequired,
    scene: sceneLike,
    trackFoSpineAsset: trackFoSpineAssetLike.isRequired,
};

SceneEditorForm.defaultProps = {
    className: '',
    scene: null,
};

function mapState(state, ownProps) {
    const { sceneId } = ownProps;
    const scene = state.sceneSelector.scenes.byId[sceneId];

    const foSpineAssetId = state.assetSelector.foSpineAssets.allIds[0];
    const { fallbackHistory } = state.assetSelector.foSpineAssets.byId[foSpineAssetId];
    const trackFoSpineAsset = { id: foSpineAssetId, fallbackHistory };

    return { scene, trackFoSpineAsset };
}

export default connect(mapState, {
    setScene,
})(SceneEditorForm);
