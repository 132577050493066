export default function moveSpeechTrackLayerToTop({ allLayerIds, layersById }) {
    const speechTrackLayerIndex = allLayerIds.findIndex((layerId) => {
        const layer = layersById[layerId];
        return layer.speechTrackId;
    });
    if (speechTrackLayerIndex !== -1) {
        const speechTrackLayerId = allLayerIds[speechTrackLayerIndex];
        allLayerIds.splice(speechTrackLayerIndex, 1);
        allLayerIds.push(speechTrackLayerId);
    }

    return { allLayerIds, layersById };
}
