import getMaskedDisplayObjectContent from 'src/pixi/tools/MaskTool/utils/getMaskedDisplayObjectContent';

export default function destroyFoTextureDisplayObject(foTextureDisplayObject) {
    const content = getMaskedDisplayObjectContent(foTextureDisplayObject);

    delete content.mask;
    delete foTextureDisplayObject.content;
    delete foTextureDisplayObject.maskData;

    foTextureDisplayObject.destroy({
        children: true,
    });
}
