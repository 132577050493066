import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

const sceneEditorSlice = createSlice({
    name: 'sceneEditor',
    initialState,
    reducers: {
        setIsPixiMounted(state, action) {
            const isPixiMounted = action.payload;
            state.isPixiMounted = isPixiMounted;
        },
        setIsMonitorOverlaysShown(state, action) {
            state.isMonitorOverlaysShown = action.payload;
        },
        setSelectedFoSpineAssetTrackId(state, action) {
            state.selectedFoSpineAssetTrackId = action.payload;
        },
    },
});

export const {
    setIsPixiMounted,
    setSelectedFoSpineAssetTrackId,
    setIsMonitorOverlaysShown,
} = sceneEditorSlice.actions;

export default sceneEditorSlice.reducer;
