import { rotateLayerZIndices } from 'src/pixi/tools/TransformTool/constants/zIndices';

import makePivotKnobGraphics from './makePivotKnobGraphics';
import {
    handlePivotKnobDragStart,
    handlePivotKnobDragEnd,
    handlePivotKnobDragMove,
} from './pivotKnobDragHandlers';

export default function makePivotKnob(transformTool) {
    const pivotKnob = makePivotKnobGraphics();

    pivotKnob.zIndex = rotateLayerZIndices.PIVOT_KNOB;
    pivotKnob.interactive = true;
    pivotKnob.buttonMode = true;
    pivotKnob
        .on('pointerdown', handlePivotKnobDragStart)
        .on('pointermove', handlePivotKnobDragMove)
        .on('pointerup', handlePivotKnobDragEnd)
        .on('pointerupoutside', handlePivotKnobDragEnd);

    transformTool.pivotKnob = pivotKnob;
    pivotKnob.transformTool = transformTool;

    transformTool.rotateLayer.addChild(pivotKnob);
}
