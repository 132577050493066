import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function TimeIndicatorHead(props) {
    const { className } = props;
    return (
        <div className={classnames(styles.TimeIndicatorHead, className)}>
            <div className={styles.TimeIndicatorHead__root} />
            <div className={styles.TimeIndicatorHead__arrow} />
        </div>
    );
}

TimeIndicatorHead.propTypes = {
    className: PropTypes.string,
};

TimeIndicatorHead.defaultProps = {
    className: '',
};
