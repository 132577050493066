import { useEffect, useRef, useCallback } from 'react';

import makeToolTargetSelectable from 'src/components/views/TextureRouteManager/TextureEditor/utils/makeToolTargetSelectable';
import offsetDisplayObjectByPercent from 'src/pixi/utils/offsetDisplayObjectByPercent';
import pixiStore from 'src/pixi/pixiStore';
import loadFoTexture from 'src/utils/businessLogic/foTexture/loadFoTexture';
import cloneFoTexture from 'src/utils/businessLogic/foTexture/cloneFoTexture';
import updateFoTextureWithDisplayObjectProps from 'src/utils/businessLogic/foTexture/updateFoTextureWithDisplayObjectProps';
import getChildById from 'src/pixi/utils/getChildById';

export default function useHandleCloneFoTextureOntoStage({
    onFoTextureSelectionChange,
    updateFoTextures,
    foTextures,
    foTextureId,
}) {
    const recentlyClonedFoTextureRef = useRef();

    useEffect(function _selectRecentlyClonedFoTexture() {
        const foTextureClone = recentlyClonedFoTextureRef.current;
        if (foTextureClone) {
            onFoTextureSelectionChange({
                foTextureId: foTextureClone.id,
            });

            recentlyClonedFoTextureRef.current = null;
        }
    });

    return useCallback(
        async function _handlCloneFoTextureOntoStage() {
            const foTextureIndex = foTextures.findIndex((foTexture) => {
                return foTexture.id === foTextureId;
            });
            const foTexture = foTextures[foTextureIndex];

            const app = pixiStore.pixiAppManager.getPixiApp();
            const displayObject = getChildById({
                parent: app.stage,
                id: foTexture.id,
            });
            const updatedFoTexture = updateFoTextureWithDisplayObjectProps({
                foTexture,
                displayObject,
            });

            const foTextureClone = cloneFoTexture({ foTexture: updatedFoTexture });
            const displayObjectClone = await loadFoTexture({
                loader: app.loader,
                foTexture: foTextureClone,
            });

            makeToolTargetSelectable({
                toolTarget: displayObjectClone,
            });

            displayObject.parent.addChild(displayObjectClone);

            offsetDisplayObjectByPercent({
                displayObject: displayObjectClone,
                percent: { x: 0.1, y: 0.1 },
            });

            const newFoTextures = [...foTextures];
            newFoTextures.splice(foTextureIndex, 0, foTextureClone);

            updateFoTextures(newFoTextures);

            recentlyClonedFoTextureRef.current = foTextureClone;
        },
        [foTextures, updateFoTextures, foTextureId],
    );
}
