import { useEffect, useState } from 'react';

import { getAudioTracksEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default function useInitializeAudioTracks({ setAudioTracks }) {
    const [isFetchingTracks, setIsFetchingTracks] = useState(false);

    useEffect(function _fetchAudioTracksOnMount() {
        async function fetchAudioTracks() {
            try {
                setIsFetchingTracks(true);
                const audioTracksEndpoint = getAudioTracksEndpoint();
                const response = await fetchWithAuth(audioTracksEndpoint);
                if (response.ok) {
                    const audioTracks = await response.json();
                    setAudioTracks(audioTracks);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.errors[0].message);
                }
            } catch (err) {
                console.error(err);
            }
            setIsFetchingTracks(false);
        }
        fetchAudioTracks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isFetchingTracks;
}
