const { AnimationState } = PIXI.spine.core;

AnimationState.prototype.addEmptyAnimation = function _addEmptyAnimation(
    trackIndex,
    mixDuration,
    delay,
    timeScale = 1,
) {
    if (delay <= 0) {
        delay -= mixDuration * timeScale;
    }
    const entry = this.addAnimationWith(trackIndex, AnimationState.emptyAnimation, false, delay);
    entry.mixDuration = mixDuration;
    entry.trackEnd = mixDuration;
    return entry;
};
