export default function getLocalRotation({ globalRotation, refDisplayObject }) {
    let current = refDisplayObject;
    let { parent } = refDisplayObject;
    let localRotation = globalRotation;

    while (parent) {
        current = parent;
        localRotation -= current.rotation;
        parent = current.parent;
    }

    return localRotation;
}
