import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Line from 'src/components/shared/Line';
import TextureEditorContext from 'src/components/views/TextureRouteManager/TextureEditor/context/TextureEditorContext';
import useRenameModeManager from 'src/components/views/TextureRouteManager/TextureEditor/SortableFoTextureList/FoTextureList/SortableFoTextureListItem/FoTextureListItem/hooks/useRenameModeManager';
import useHandleNameInputChange from 'src/components/views/TextureRouteManager/TextureEditor/SortableFoTextureList/FoTextureList/SortableFoTextureListItem/FoTextureListItem/hooks/useHandleNameInputChange';
import EditableTextBox from 'src/components/shared/EditableTextBox';
import SelectableListItem from 'src/components/shared/SelectableListItem';

import Buttons from './Buttons';

import styles from './styles.module.scss';

export default function FoTextureListItem(props) {
    const { className, onFoTextureSelectionChange, foTextureId, name, ...attributes } = props;

    const { updateFoTextures, foTextureComp, selectedFoTextureId } = useContext(
        TextureEditorContext,
    );
    const isSelected = foTextureId === selectedFoTextureId;
    const { toggleRenameMode, isEditingName } = useRenameModeManager({
        isSelected,
    });
    const handleNameInputChange = useHandleNameInputChange({
        foTextures: foTextureComp.foTextures,
        updateFoTextures,
        foTextureId,
    });

    const handleLabelClick = useCallback(
        function _handleLabelClick() {
            onFoTextureSelectionChange({ foTextureId });
        },
        [foTextureId, onFoTextureSelectionChange],
    );

    return (
        <SelectableListItem
            className={classnames(styles.FoTextureListItem, className)}
            isSelected={isSelected}
            {...attributes}
        >
            <EditableTextBox
                isEditing={isEditingName}
                inputProps={{
                    value: name,
                    onChange: handleNameInputChange,
                }}
                labelProps={{
                    onClick: handleLabelClick,
                }}
            />
            {isSelected && <Line />}
            {isSelected && (
                <Buttons
                    onFoTextureSelectionChange={onFoTextureSelectionChange}
                    foTextureId={foTextureId}
                    toggleRenameMode={toggleRenameMode}
                    isEditingName={isEditingName}
                />
            )}
        </SelectableListItem>
    );
}

FoTextureListItem.propTypes = {
    className: PropTypes.string,
    foTextureId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onFoTextureSelectionChange: PropTypes.func.isRequired,
};

FoTextureListItem.defaultProps = {
    className: '',
};
