import { useContext, useEffect } from 'react';

import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import getFirstSequentialClipsFromSelection from 'src/components/views/SceneEditor/Timeline/utils/getFirstSequentialClipsFromSelection';
import reduxStore from 'src/reduxStore';
import getClipTimes from 'src/utils/businessLogic/clipUtils/getClipTimes';

function roundTime(time) {
    const accuracy = 2;
    const roundedTime = Math.round(time * 10 ** accuracy) / 10 ** accuracy;
    return roundedTime;
}

export default function useInitializeSomeFormFields({
    selectedSceneId,
    infoForWritingTranscriptRef,
    transcriptWritingFormStateRef,
}) {
    const { currentTimeManager } = useContext(SceneEditorContext);

    useEffect(function _initializeUsingSelectedClips() {
        const reduxState = reduxStore.getState();
        const { clipSelectionByTrackId, timeWidth } = reduxState.timeline;
        const { tracks } = reduxState.sceneSelector.scenes.byId[selectedSceneId];

        const { trackSpeechLayer, trackId } = infoForWritingTranscriptRef.current;

        const firstSequentialSelectedClips = getFirstSequentialClipsFromSelection({
            tracks,
            clipSelectionByTrackId,
            selectedTrackId: trackId,
            selectedLayerId: trackSpeechLayer?.trackLayerId,
        });

        const {
            setTranscriptText,
            setStartTime,
            setEndTime,
            setIsEstimateEndTime,
        } = transcriptWritingFormStateRef.current;

        const lastClip = firstSequentialSelectedClips[firstSequentialSelectedClips.length - 1];
        if (lastClip) {
            const { clipEndTime: lastClipEndTime } = getClipTimes({
                clip: lastClip,
                timeWidth,
            });
            const { clipStartTime: firstClipStartTime } = getClipTimes({
                clip: firstSequentialSelectedClips[0],
                timeWidth,
            });

            const text = firstSequentialSelectedClips.reduce(function _addClipTitleToText(
                text,
                clip,
            ) {
                if (clip.data.graphemeChar === null) {
                    return text;
                }
                const newText = `${text} ${clip.data.title}`.trim();
                return newText;
            },
            '');

            setStartTime(firstClipStartTime);
            setEndTime(lastClipEndTime);
            setTranscriptText(text);
            setIsEstimateEndTime(false);
        } else {
            const roundedTime = roundTime(currentTimeManager.currentTime);
            setStartTime(roundedTime);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
