import traverseBreadthFirst from 'src/utils/treeUtils/traverseBreadthFirst';

export default function getIfContainsChild({ parent, child, isShallow }) {
    let foundChild;

    if (!parent) {
        console.error('cannot look for a child if parent node is not specified');
        return null;
    }
    if (!child) {
        console.error('child displayObject is requied');
        return null;
    }
    function checkNode(node) {
        if (node === child) {
            foundChild = node;
            return node;
        }
        return null;
    }

    if (isShallow) {
        foundChild = parent.children.find((currentChild) => {
            return currentChild === child;
        });
    } else {
        traverseBreadthFirst({
            root: parent,
            callback: checkNode,
            getIsStop: checkNode,
        });
    }

    const isContainsChild = !!foundChild;
    return isContainsChild;
}
