export default function smoothenWaveformData({ waveformData, numberOfSamples = 200 }) {
    const blockSize = Math.floor(waveformData.length / numberOfSamples);

    const filteredData = [];
    for (let i = 0; i < numberOfSamples; i++) {
        const blockStart = i * blockSize;
        let blockAbsoluteSum = 0;
        for (let j = 0; j < blockSize; j++) {
            const sampleValue = waveformData[blockStart + j];
            const absoluteSampleValue = Math.abs(sampleValue);
            blockAbsoluteSum += absoluteSampleValue;
        }

        const averageBlockSampleValue = blockAbsoluteSum / blockSize;

        filteredData.push(averageBlockSampleValue);
    }

    return filteredData;
}
