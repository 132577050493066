import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

const sceneEditorSlice = createSlice({
    name: 'sceneSelector',
    initialState,
    reducers: {
        setScene(state, action) {
            const scene = action.payload;
            const isNewScene = !state.scenes.byId[scene.id];

            state.scenes.byId[scene.id] = scene;
            if (isNewScene) {
                state.scenes.allIds.push(scene.id);
            }
        },
        deleteScene(state, action) {
            const sceneId = action.payload;

            delete state.scenes.byId[sceneId];
            const filteredAllIds = state.scenes.allIds.filter((currentSceneId) => {
                return currentSceneId !== sceneId;
            });
            state.scenes.allIds = filteredAllIds;
        },
        setScenes(state, action) {
            const { scenesById, allSceneIds } = action.payload;
            if (scenesById) {
                state.scenes.byId = scenesById;
            }
            if (allSceneIds) {
                state.scenes.allIds = allSceneIds;
            }
        },
        setFoSpineAssetOnTrack(state, action) {
            const { sceneId, trackId, foSpineAssetId, fallbackHistory } = action.payload;

            state.scenes.byId[sceneId].tracks.byId[trackId].foSpineAsset.id = foSpineAssetId;
            state.scenes.byId[sceneId].tracks.byId[
                trackId
            ].foSpineAsset.fallbackHistory = fallbackHistory;
        },
        setClipsById(state, action) {
            const { sceneId, trackId, trackLayerId, newClipsById } = action.payload;
            state.scenes.byId[sceneId].tracks.byId[trackId].layers.byId[
                trackLayerId
            ].clipsById = newClipsById;
        },
        setTrackLayer(state, action) {
            const { sceneId, trackId, trackLayer } = action.payload;
            const isNewLayer = !state.scenes.byId[sceneId].tracks.byId[trackId].layers.byId[
                trackLayer.trackLayerId
            ];
            state.scenes.byId[sceneId].tracks.byId[trackId].layers.byId[
                trackLayer.trackLayerId
            ] = trackLayer;
            if (isNewLayer) {
                state.scenes.byId[sceneId].tracks.byId[trackId].layers.allIds.push(
                    trackLayer.trackLayerId,
                );
            }
        },
        setTrackLayers(state, action) {
            const { sceneId, layersById, allLayerIds, trackId } = action.payload;
            if (layersById) {
                state.scenes.byId[sceneId].tracks.byId[trackId].layers.byId = layersById;
            }
            if (allLayerIds) {
                state.scenes.byId[sceneId].tracks.byId[trackId].layers.allIds = allLayerIds;
            }
        },
        setTracks(state, action) {
            const { sceneId, tracksById, allTrackIds } = action.payload;
            if (tracksById) {
                state.scenes.byId[sceneId].tracks.byId = tracksById;
            }
            if (allTrackIds) {
                state.scenes.byId[sceneId].tracks.allIds = allTrackIds;
            }
        },
        setExpressionTimelineKeys(state, action) {
            const { sceneId, trackId, expressionTimelineKeys } = action.payload;
            state.scenes.byId[sceneId].tracks.byId[
                trackId
            ].expressionTimelineKeys = expressionTimelineKeys;
        },
        setAudioTrackSelection(state, action) {
            const { sceneId, selectedAudioTrackId } = action.payload;
            state.scenes.byId[sceneId].selectedAudioTrackId = selectedAudioTrackId;
        },
        toggleIsTrackLayerHidden(state, action) {
            const { sceneId, trackId, trackLayerId } = action.payload;
            const { isHidden } = state.scenes.byId[sceneId].tracks.byId[trackId].layers.byId[
                trackLayerId
            ].settings;
            state.scenes.byId[sceneId].tracks.byId[trackId].layers.byId[
                trackLayerId
            ].settings.isHidden = !isHidden;
        },
        toggleIsTrackLayerDisabled(state, action) {
            const { sceneId, trackId, trackLayerId } = action.payload;
            const { isDisabled } = state.scenes.byId[sceneId].tracks.byId[trackId].layers.byId[
                trackLayerId
            ].settings;
            state.scenes.byId[sceneId].tracks.byId[trackId].layers.byId[
                trackLayerId
            ].settings.isDisabled = !isDisabled;
        },
    },
});

export const {
    setScene,
    setScenes,
    setClipsById,
    setTrackLayer,
    setTracks,
    setAudioTrackSelection,
    setFoSpineAssetOnTrack,
    toggleIsTrackLayerHidden,
    toggleIsTrackLayerDisabled,
    setTrackLayers,
    setExpressionTimelineKeys,
    deleteScene,
} = sceneEditorSlice.actions;

export default sceneEditorSlice.reducer;
