import clipTypes from 'src/components/constants/clipTypes';
import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';

export default function cloneClip({ clip, isDeep = false }) {
    let clipClone = clip;

    if (isDeep && clip.data.type === clipTypes.CLIP_GROUP) {
        clipClone = cloneClip({ clip });

        const { clipsById: oldClipsById } = clip.data;
        clipClone.data.clipsById = {};
        Object.values(oldClipsById).forEach(function _cloneChildClip(childClip) {
            const childClipClone = cloneClip({ clip: childClip, isDeep: true });
            clipClone.data.clipsById[childClipClone.clipId] = childClipClone;
        });
    } else {
        clipClone = generateClip({ ...clip, clipId: undefined });
    }

    return clipClone;
}
