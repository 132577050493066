import { getSceneTracksEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import reduxStore from 'src/reduxStore';
import { setTracks } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import { isSceneSelectorUsingLocalMocks } from 'src/config/localMocksFlags';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';
import updateTranscriptsOnBackend from 'src/utils/backendUtils/updateTranscriptsOnBackend';

async function updateTracksOnBackend({ selectedSceneId }) {
    if (isSceneSelectorUsingLocalMocks) {
        return;
    }

    const reduxState = reduxStore.getState();
    const { tracks } = reduxState.sceneSelector.scenes.byId[selectedSceneId];

    const sceneTracksEndpoint = getSceneTracksEndpoint(selectedSceneId);
    try {
        const updateResponse = await fetchWithAuth(sceneTracksEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tracks),
        });

        if (updateResponse.ok) {
            updateTranscriptsOnBackend({ selectedSceneId });
            return;
        }

        const errorData = await updateResponse.json();
        console.error(
            'There was a problem with persisting tracks to the backend',
            errorData.errors[0].message,
        );
    } catch (err) {
        console.error(err);
    }

    const getResponse = await fetchWithAuth(sceneTracksEndpoint);
    if (getResponse.ok) {
        const confirmedTracks = await getResponse.json();

        console.log('Rolling tracks back to the backend version.');

        reduxStore.dispatch(
            setTracks({
                sceneId: selectedSceneId,
                tracksById: confirmedTracks.byId,
                allTrackIds: confirmedTracks.allIds,
            }),
        );
    } else {
        console.log("Couldn't roll tracks back to the backend version.");
    }
}

const flagsBySceneId = {};
export default async function debouncedUpdate(...args) {
    const { selectedSceneId } = args[0];
    flagsBySceneId[selectedSceneId] = flagsBySceneId[selectedSceneId] || {
        timeoutId: null,
        isUpdatePending: false,
    };

    const { isUpdatePending, timeoutId } = flagsBySceneId[selectedSceneId];
    if (timeoutId) {
        clearTimeout(timeoutId);
    }

    if (isUpdatePending) {
        flagsBySceneId[selectedSceneId].timeoutId = setTimeout(() => {
            flagsBySceneId[selectedSceneId].timeoutId = null;
            debouncedUpdate(...args);
        }, 300);
    } else {
        flagsBySceneId[selectedSceneId].timeoutId = null;
        flagsBySceneId[selectedSceneId].isUpdatePending = true;
        await updateTracksOnBackend(...args);
        flagsBySceneId[selectedSceneId].isUpdatePending = false;
    }
}
