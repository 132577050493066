import vahramMaskPointsData from './vahramMaskPointsData';

export default {
    bakedUrl: '',
    id: 'fo_texture_comp-1',
    name: 'Vahram',
    foTextures: [
        {
            id: 'fo_texture-1',
            maskData: {
                points: vahramMaskPointsData,
                type: 'bezier',
            },
            name: 'vahram 00face.png',
            transform: {
                pivot: {
                    x: 59.53926583993871,
                    y: 73.90077067697469,
                },
                position: {
                    x: 81.28861643718052,
                    y: 82.20818649726353,
                },
                rotation: 0,
                scale: {
                    x: 0.7518687810555398,
                    y: 0.7518687810555392,
                },
            },
            url: '/images/vahram_face.png',
            zIndex: 1,
        },
    ],
    linkedFoSpineAssetIds: ['zombie_01'],
};
