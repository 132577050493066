import { isObject, isNumber } from 'lodash';

export default function assertPivotIsValid(pivot) {
    if (!isObject(pivot)) {
        throw new Error('pivot has to be an object, received: ', pivot);
    }
    if (!isNumber(pivot.x)) {
        throw new Error('pivot.x has to be a number, received: ', pivot.x);
    }
    if (!isNumber(pivot.y)) {
        throw new Error('pivot.y has to be a number, received: ', pivot.y);
    }
}
