import getChildById from 'src/pixi/utils/getChildById';
import makeToolTargetSelectable from 'src/components/views/TextureRouteManager/TextureEditor/utils/makeToolTargetSelectable';

export default function makeFoTexturesSelectable({ foTextureComp, foTextureCompDisplayObject }) {
    const { foTextures } = foTextureComp;
    foTextures.forEach((foTexture) => {
        const foTextureDisplayObject = getChildById({
            parent: foTextureCompDisplayObject,
            id: foTexture.id,
            isShallow: true,
        });

        makeToolTargetSelectable({
            toolTarget: foTextureDisplayObject,
        });
    });
}
