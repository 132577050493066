import makeMainKnobGraphics from './makeMainKnobGraphics';
import {
    handleMainKnobDragStart,
    handleMainKnobDragMove,
    handleMainKnobDragEnd,
} from './mainKnobDragHandlers';

export default function makeMainKnob({ point, maskTool }) {
    const mainKnob = makeMainKnobGraphics();
    mainKnob.pointId = point.id;
    mainKnob.maskTool = maskTool;

    mainKnob.interactive = true;
    mainKnob.buttonMode = true;

    mainKnob
        .on('pointerdown', handleMainKnobDragStart)
        .on('pointermove', handleMainKnobDragMove)
        .on('pointerup', handleMainKnobDragEnd)
        .on('pointerupoutside', handleMainKnobDragEnd);

    return mainKnob;
}
