import getFoTextureCompsOfFoSpineAsset from 'src/reduxStore/utils/getFoTextureCompsOfFoSpineAsset';

export default function getFoTextureCompsOfFoSpineAssets({ foSpineAssets, foTextureComps }) {
    const relatedFoTextureComps = { byId: {}, allIds: [] };

    foSpineAssets.allIds.forEach((foSpineAssetId) => {
        const foSpineAsset = foSpineAssets.byId[foSpineAssetId];

        getFoTextureCompsOfFoSpineAsset({
            foSpineAsset,
            foTextureComps,
            relatedFoTextureComps,
        });
    });

    return relatedFoTextureComps;
}
