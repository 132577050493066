import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import refLike from 'src/components/shared/propTypes/refLike';

import './styles.scss';

export default function Layover(props) {
    const { className, children, isTransparent, nodeRef, ...attributes } = props;

    return (
        <div
            className={classnames(
                'Layover',
                {
                    'Layover--transparent': isTransparent,
                },
                className,
            )}
            ref={nodeRef}
            {...attributes}
        >
            {children}
        </div>
    );
}

Layover.propTypes = {
    nodeRef: refLike,
    className: PropTypes.string,
    isTransparent: PropTypes.bool,
};

Layover.defaultProps = {
    className: '',
    nodeRef: { current: null },
    isTransparent: false,
};
