import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    editingFoTextureCompId: '',
    selectedSlotName: '',
};

const textureEditorSlice = createSlice({
    name: 'textureEditor',
    initialState,
    reducers: {
        setEditingFoTextureCompId(state, action) {
            state.editingFoTextureCompId = action.payload;
        },
        setSelectedSlotName(state, action) {
            state.selectedSlotName = action.payload;
        },
    },
});

export const { setEditingFoTextureCompId, setSelectedSlotName } = textureEditorSlice.actions;

export default textureEditorSlice.reducer;
