export default function filterDeletedExpressionClipsFromSelection(tracks, clipSelectionByTrackId) {
    const tracksWithSelectedClips = Object.keys(clipSelectionByTrackId);
    const updatedClipSelectionByTrackId = {};
    let isAnyClipMissing = false;

    tracksWithSelectedClips.forEach(function _filterDeletedClipsFromTrack(trackId) {
        const track = tracks.byId[trackId];
        const isTrackDeleted = !track;
        if (isTrackDeleted) {
            isAnyClipMissing = true;
            return;
        }

        const clipSelectionOnTrackByLayerId = clipSelectionByTrackId[trackId];
        const layerIds = Object.keys(clipSelectionOnTrackByLayerId);
        const { expressionTimelineKeys } = tracks.byId[trackId];
        const updatedClipSelectionOnTrackByLayerId = {};

        layerIds.forEach(function _filterDeletedClipsFromLayer(layerId) {
            const clipSelectionOnLayer = clipSelectionOnTrackByLayerId[layerId];

            const updatedClipSelectionOnLayer = [];
            clipSelectionOnLayer.forEach((selectedClipId) => {
                const isClipDeleted = !expressionTimelineKeys.find((expressionTimelineKey) => {
                    return expressionTimelineKey.id === selectedClipId;
                });
                if (isClipDeleted) {
                    isAnyClipMissing = true;
                } else {
                    updatedClipSelectionOnLayer.push(selectedClipId);
                }
            });
            if (updatedClipSelectionOnLayer.length) {
                updatedClipSelectionOnTrackByLayerId[layerId] = updatedClipSelectionOnLayer;
            }
        });

        const isAnySelectedOnTrack = !!Object.keys(updatedClipSelectionOnTrackByLayerId).length;
        if (isAnySelectedOnTrack) {
            updatedClipSelectionByTrackId[trackId] = updatedClipSelectionOnTrackByLayerId;
        }
    });

    if (isAnyClipMissing) {
        return updatedClipSelectionByTrackId;
    }

    return null;
}
