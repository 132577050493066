/**
 * @zoomContainer pixi container, but sprite, graphics or other pixi display object could work too
 * @currentDistance number
 * @newDistance number
 * @direction one of 'x' or 'y'
 */
export default function calcLocalDirectinalScale({
    currentDistance,
    newDistance,
    direction,
    zoomContainer,
}) {
    const currentGlobalPoint1 = { x: 0, y: 0 };
    const currentGlobalPoint2 = { x: 0, y: 0 };
    currentGlobalPoint2[direction] = currentDistance;

    const newGlobalPoint1 = { x: 0, y: 0 };
    const newGlobalPoint2 = { x: 0, y: 0 };
    newGlobalPoint2[direction] = newDistance;

    const currentLocalPoint1 = zoomContainer.toLocal(currentGlobalPoint1);
    const currentLocalPoint2 = zoomContainer.toLocal(currentGlobalPoint2);

    const newLocalPoint1 = zoomContainer.toLocal(newGlobalPoint1);
    const newLocalPoint2 = zoomContainer.toLocal(newGlobalPoint2);

    const currentLocalDistance = currentLocalPoint2[direction] - currentLocalPoint1[direction];
    const expectedLocalDistance = newLocalPoint2[direction] - newLocalPoint1[direction];

    const localDirectionalScale = expectedLocalDistance / currentLocalDistance;

    return localDirectionalScale;
}
