import { useCallback, useState, useRef } from 'react';

import { getConvertedVideoEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

import useCapturedFramesPercent from './useCapturedFramesPercent';
import captureAppFrames from './captureAppFrames';
import prepareFormData from './prepareFormData';
import uploadFrames from './uploadFrames';
import downloadVideoFile from './downloadVideoFile';

export const downloadPhases = Object.freeze({
    CAPTURING: 'CAPTURING',
    PROCESSING: 'PROCESSING',
    DOWNLOADING: 'DOWNLOADING',
});
export default function useDownloadVideo({
    videoDownloadFormStateRef,
    currentTimeManager,
    jumpAppToTime,
    selectedAudioTrackId,
}) {
    const [downloadPhase, setDownloadPhase] = useState('');

    const {
        capturedFramesPercent,
        resetCapturedFramesPercent,
        updateCapturedFramesPercent,
    } = useCapturedFramesPercent();

    const [videoConversionPercent, setVideoConversionPercent] = useState(0);
    const [videoDownloadPercent, setVideoDownloadPercent] = useState(0);

    const isDownloadCancelledRef = useRef(false);
    const markVideoDownloadStopped = useCallback(
        function _markVideoDownloadStopped() {
            isDownloadCancelledRef.current = true;
            setDownloadPhase('');
        },
        [isDownloadCancelledRef],
    );

    const downloadVideo = useCallback(
        async function _downloadVideo() {
            const {
                fileName: outputName,
                format,
                frameRate,
                quality,
                startTime,
                endTime,
                isIncludeAudio,
                isDownloadFrames,
                videoDownloadScale,
                videoDownloadPaddingPercentX,
                videoDownloadPaddingPercentY,
            } = videoDownloadFormStateRef.current;

            resetCapturedFramesPercent();
            setVideoConversionPercent(0);
            setVideoDownloadPercent(0);
            setDownloadPhase(downloadPhases.CAPTURING);
            isDownloadCancelledRef.current = false;

            const ext = 'png';
            const allImageFiles = await captureAppFrames({
                fps: frameRate,
                startTime,
                endTime,
                currentTimeManager,
                jumpAppToTime,
                ext,
                updateCapturedFramesPercent,
                isDownloadCancelledRef,
                isDownloadFrames,
                videoDownloadScale,
                videoDownloadPaddingPercentX,
                videoDownloadPaddingPercentY,
            });

            const formData = prepareFormData({
                allImageFiles,
                outputName,
                format,
                ext,
                quality,
                startTime,
                endTime,
                isIncludeAudio,
                selectedAudioTrackId,
                frameRate,
            });

            if (isDownloadCancelledRef.current) {
                return;
            }

            setDownloadPhase(downloadPhases.PROCESSING);

            try {
                const videoFileName = await uploadFrames({ setVideoConversionPercent, formData });

                if (!videoFileName) {
                    throw new Error('Did not receive a valid videoFileName: ', videoFileName);
                }
                const videoUrl = getConvertedVideoEndpoint(videoFileName);

                setDownloadPhase(downloadPhases.DOWNLOADING);
                await downloadVideoFile({ videoUrl, outputName, format, setVideoDownloadPercent });

                fetchWithAuth(videoUrl, { method: 'DELETE' });
            } catch (err) {
                console.error(err);
            }

            markVideoDownloadStopped();
        },
        [
            currentTimeManager,
            jumpAppToTime,
            videoDownloadFormStateRef,
            setDownloadPhase,
            selectedAudioTrackId,
            resetCapturedFramesPercent,
            updateCapturedFramesPercent,
            setVideoConversionPercent,
            isDownloadCancelledRef,
            markVideoDownloadStopped,
        ],
    );

    return {
        downloadVideo,
        downloadPhase,
        markVideoDownloadStopped,
        capturedFramesPercent,
        videoConversionPercent,
        videoDownloadPercent,
    };
}
