import { useCallback } from 'react';

export default function useHandlePlayButtonClick({ playingId, setPlayingId }) {
    return useCallback(
        function _handlePlayButtonClick(id) {
            const isPlaying = playingId === id;

            if (isPlaying) {
                setPlayingId('');
            } else {
                setPlayingId(id);
            }
        },
        [playingId, setPlayingId],
    );
}
