import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Clip from './Clip';

import styles from './styles.module.scss';

export default function ClipContainer(props) {
    const { className, data, clipId, isSelected, isInPreview, clipError, ...attributes } = props;

    return (
        <div className={classnames(styles.ClipContainer, className)} {...attributes}>
            {/* put edit menu layer here */}
            <Clip
                data={data}
                clipId={clipId}
                isSelected={isSelected}
                isInPreview={isInPreview}
                clipError={clipError}
            />
        </div>
    );
}

ClipContainer.propTypes = {
    className: PropTypes.string,
    clipId: PropTypes.string.isRequired,
    ...Clip.propTypes,
};

ClipContainer.defaultProps = {
    className: '',
};
