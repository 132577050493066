import { DEFAULT_TANGENT_DISTANCE } from 'src/pixi/tools/MaskTool/constants/maskControls';
import {
    calcPointWithAngleAndRadius,
    calcAngleRadBetween2PointsAndAxisX,
} from 'src/utils/mathUtils/geoMathUtils';
import getOtherTangentName from 'src/pixi/tools/MaskTool/utils/getOtherTangentName';

export default function generateOtherTangent({ point, tangentName }) {
    const otherTangentName = getOtherTangentName(tangentName);
    const tangentAngle = calcAngleRadBetween2PointsAndAxisX({
        point1: point.main,
        point2: point[tangentName],
    });
    const otherTangetPositionFromMain = calcPointWithAngleAndRadius({
        angle: tangentAngle + Math.PI,
        radius: DEFAULT_TANGENT_DISTANCE,
    });
    const otherTargetPosition = {
        x: point.main.x + otherTangetPositionFromMain.x,
        y: point.main.y + otherTangetPositionFromMain.y,
    };
    point[otherTangentName] = otherTargetPosition;
}
