import expressions from 'src/utils/businessLogic/expressions/constants/expressions';

// start in secs
// end in secs
// expressions: amount [0-100]
export default [
    {
        id: 'expression_timeline_key-01',
        start: 0.5,
        end: 1.5,
        influences: [
            {
                expression: expressions.ANGRY,
                amount: 50,
            },
            {
                expression: expressions.SURPRISE,
                amount: 50,
            },
            {
                expression: expressions.THINK,
                amount: 50,
            },
        ],
    },
    {
        id: 'expression_timeline_key-02',
        start: 1.5,
        end: 3,
        influences: [
            {
                expression: expressions.SILLY,
                amount: 50,
            },
            {
                expression: expressions.THINK,
                amount: 50,
            },
        ],
    },
    {
        id: 'expression_timeline_key-03',
        start: 3,
        end: 5,
        influences: [
            {
                expression: expressions.CONFUSED,
                amount: 50,
            },
            {
                expression: expressions.THINK,
                amount: 50,
            },
        ],
    },
    {
        id: 'expression_timeline_key-04',
        start: 5,
        end: 7,
        influences: [
            {
                expression: expressions.ANGRY,
                amount: 50,
            },
            {
                expression: expressions.THINK,
                amount: 50,
            },
        ],
    },
    {
        id: 'expression_timeline_key-05',
        start: 7,
        end: 10,
        influences: [
            {
                expression: expressions.ANNOYED,
                amount: 50,
            },
            {
                expression: expressions.THINK,
                amount: 50,
            },
        ],
    },
];
