import getConflictInfo from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getConflictInfo';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import getPositionRelativeToLeftNeighbout from 'src/utils/businessLogic/clipUtils/insertClip/insertShift/getPositionRelativeToLeftNeighbout';
import shiftClipsToRight from 'src/utils/businessLogic/clipUtils/insertClip/insertShift/shiftClipsToRight';
import shiftClipsToLeft from 'src/utils/businessLogic/clipUtils/insertClip/insertShift/shiftClipsToLeft';

export default function shiftClipsForLeftConflict({
    newClip,
    clipsById,
    conflictInfo,
    isCompactShift,
    timeWidth,
}) {
    const {
        //
        isConflictToLeft,
        leftNeighbourIndex,
        leftNeighbour,
        sortedClips,
    } = conflictInfo || getConflictInfo({ newClip, clipsById, timeWidth });

    if (!isConflictToLeft) {
        return clipsById;
    }

    const { isInLeftNeighboursFirstHalf } = getPositionRelativeToLeftNeighbout({
        clip: newClip,
        leftNeighbour,
        timeWidth,
    });

    let newClipsById = clipsById;
    if (isInLeftNeighboursFirstHalf) {
        const newClipEnd = getClipEnd({ clip: newClip, timeWidth });
        const overlapWidth = newClipEnd - leftNeighbour.position;

        newClipsById = shiftClipsToRight({
            startIndex: leftNeighbourIndex,
            clipsById: newClipsById,
            sortedClips,
            shiftAmount: overlapWidth,
            isCompactShift,
            timeWidth,
        });
    } else {
        const leftNeighbourEnd = getClipEnd({ clip: leftNeighbour, timeWidth });
        const overlapWidth = leftNeighbourEnd - newClip.position;
        newClipsById = shiftClipsToLeft({
            startIndex: leftNeighbourIndex,
            clipsById: newClipsById,
            sortedClips,
            shiftAmount: overlapWidth,
            isCompactShift,
            timeWidth,
        });
    }

    return newClipsById;
}
