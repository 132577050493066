import { useCallback, useEffect, useState, useRef } from 'react';

export default function useRenameFoTextureManagement({ isSelected }) {
    const [isEditingName, setIsEditingName] = useState(false);

    const toggleRenameMode = useCallback(
        function _handleRenameButtonClick() {
            setIsEditingName(!isEditingName);
        },
        [setIsEditingName, isEditingName],
    );

    const isSelectedAtLastRenderRef = useRef(isSelected);

    useEffect(
        function _exitEditNameModeIfDeselected() {
            const isActiveAtLastRender = isSelectedAtLastRenderRef.current;
            const isBecameInactive = isActiveAtLastRender && !isSelected;
            if (isBecameInactive && isEditingName) {
                setIsEditingName(false);
            }
            isSelectedAtLastRenderRef.current = isSelected;
        },
        [isSelectedAtLastRenderRef, isSelected, isEditingName, setIsEditingName],
    );

    return { toggleRenameMode, isEditingName };
}
