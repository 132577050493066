import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import Spinner from 'src/components/shared/Spinner';

import './styles.scss';

export default function LoadingToolButton(props) {
    const { className, children, isLoading, ...otherProps } = props;

    return (
        <ToolButton className={classnames('LoadingToolButton', className)} {...otherProps}>
            <div className='LoadingToolButton__content'>{children}</div>
            {isLoading && <Spinner />}
        </ToolButton>
    );
}

LoadingToolButton.propTypes = {
    ...ToolButton.propTypes,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
};

LoadingToolButton.defaultProps = {
    ...ToolButton.defaultProps,
    className: '',
    isLoading: false,
};
