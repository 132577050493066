export default function resetTransform(displayObject) {
    displayObject.position.x = 0;
    displayObject.position.y = 0;

    displayObject.pivot.x = 0;
    displayObject.pivot.y = 0;

    displayObject.scale.x = 1;
    displayObject.scale.y = 1;

    displayObject.rotation = 0;

    if (displayObject.anchor) {
        displayObject.anchor.x = 0;
        displayObject.anchor.y = 0;
    }
}
