import { useEffect, useRef } from 'react';

import placeSpineAssetInstanceOnScreen from 'src/spine/utils/placeSpineAssetInstanceOnScreen';
import pixiStore from 'src/pixi/pixiStore';
import loadFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/loadFoSpineAsset';

export default function useDisplayFoSpineAsset({ foSpineAsset, foTextureComps }) {
    const spineAssetInstanceCacheRef = useRef({});
    const spineAssetInstanceCache = spineAssetInstanceCacheRef.current;

    useEffect(() => {
        if (!foSpineAsset) {
            return;
        }
        const app = pixiStore.pixiAppManager.getPixiApp();

        const spineAssetInstance = spineAssetInstanceCache[foSpineAsset.id];
        if (spineAssetInstance) {
            app.stage.removeChildren();
            placeSpineAssetInstanceOnScreen({
                spineAssetInstance,
                app,
            });
            return;
        }

        loadFoSpineAsset({ app, foSpineAsset, foTextureComps })
            .then((spineAssetInstance) => {
                spineAssetInstanceCache[foSpineAsset.id] = spineAssetInstance;

                app.stage.removeChildren();
                placeSpineAssetInstanceOnScreen({
                    spineAssetInstance,
                    app,
                });
            })
            .catch((error) => {
                console.log('error: ', error);
            });
    }, [spineAssetInstanceCache, foSpineAsset, foTextureComps]);
}
