import generateExpressionTimelineKey from 'src/components/views/SceneEditor/Timeline/utils/expressionTimelineKeyUtils/generateExpressionTimelineKey';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

export default function convertClipToExpressionTimelineKey({ clip, timeWidth }) {
    const start = clip.position / timeWidth;
    const end = getClipEnd({ clip, timeWidth }) / timeWidth;
    const expressionTimelineKey = generateExpressionTimelineKey({
        id: clip.clipId,
        start,
        end,
        influences: clip.data.influences,
    });
    return expressionTimelineKey;
}
