export default [
    {
        main: { x: 56.18992034110133, y: 66.95010120799475 },
        isTangentsLinked: true,
        id: '1',
        tangent1: { x: 37.77783021799729, y: 84.37804604850334 },
        tangent2: { x: 91.55075519387677, y: 33.47933959385331 },
    },
    {
        id: 'maskToolPoint-kru19xlx',
        isTangentsLinked: true,
        main: { x: 33.7377418306794, y: 107.4138468342891 },
        tangent1: { x: 27.75328215020106, y: 123.13558148299393 },
        tangent2: { x: 38.559812835217656, y: 94.74581582760277 },
    },
    {
        id: 'maskToolPoint-kru169pv',
        isTangentsLinked: true,
        main: { x: 20.349445977129136, y: 154.91660434950177 },
        tangent1: { x: 17.512313467312083, y: 175.57220722418248 },
        tangent2: { x: 24.87829950360065, y: 121.94451030852862 },
    },
    {
        id: 'maskToolPoint-kru16zmx',
        isTangentsLinked: true,
        main: { x: 29.6072274209524, y: 203.3984488518248 },
        tangent1: { x: 32.339981821894966, y: 217.32341514715256 },
        tangent2: { x: 27.366414230578073, y: 191.98020792270006 },
    },
    {
        id: 'maskToolPoint-kru166sf',
        isTangentsLinked: true,
        main: { x: 33.23311906201948, y: 245.15155087419555 },
        tangent1: { x: 38.06421029607709, y: 283.32515810361235 },
        tangent2: { x: 31.451430015219287, y: 231.07326201928157 },
    },
    {
        id: 'maskToolPoint-kru164jh',
        isTangentsLinked: true,
        main: { x: 46.913297640858076, y: 362.4572133451444 },
        tangent1: { x: 54.156704205571515, y: 392.858052396979 },
        tangent2: { x: 37.99502544641928, y: 325.0269027335087 },
    },
    {
        id: 'maskToolPoint_kg92l0kj',
        isTangentsLinked: true,
        main: { x: 81.96562124438606, y: 440.19586113595255 },
        tangent1: { x: 103.32588230505226, y: 473.2959324708298 },
        tangent2: { x: 65.02014364733327, y: 413.93698182389755 },
    },
    {
        id: 'maskToolPoint-kru15yvg',
        isTangentsLinked: true,
        main: { x: 191.78508197250184, y: 502.83742711034006 },
        tangent1: { x: 232.6198880873526, y: 502.1924321368175 },
        tangent2: { x: 148.54677931085482, y: 503.5203858539662 },
    },
    {
        id: 'maskToolPoint_9',
        isTangentsLinked: true,
        main: { x: 287.3216006655597, y: 443.2756698392825 },
        tangent1: { x: 316.8728392834504, y: 412.6462169399157 },
        tangent2: { x: 260.51659579781534, y: 471.058689180029 },
    },
    {
        id: 'maskToolPoint-kru18d51',
        isTangentsLinked: true,
        main: { x: 327.7841549977709, y: 339.81441210649973 },
        tangent1: { x: 328.62801739218895, y: 334.12156172925586 },
        tangent2: { x: 321.9744196256569, y: 379.0079517447323 },
    },
    {
        id: 'maskToolPoint-kru15spi',
        isTangentsLinked: true,
        main: { x: 346.14983427985317, y: 329.5598780383074 },
        tangent1: { x: 350.86829030169827, y: 315.2416100463904 },
        tangent2: { x: 344.3485927756096, y: 335.0257885939044 },
    },
    {
        id: 'maskToolPoint-kru15rrh',
        isTangentsLinked: true,
        main: { x: 346.6034978766154, y: 284.70088424215487 },
        tangent1: { x: 348.23979266227775, y: 269.5512863184855 },
        tangent2: { x: 344.4914764747449, y: 304.25498618324923 },
    },
    {
        id: 'maskToolPoint-kru15laz',
        isTangentsLinked: true,
        main: { x: 354.3010132449052, y: 233.50182333559417 },
        tangent1: { x: 352.43816681812683, y: 226.43926849205013 },
        tangent2: { x: 357.13114188076645, y: 244.23160675613013 },
    },
    {
        id: 'maskToolPoint-kru15p0z',
        isTangentsLinked: true,
        main: { x: 336.84353895198717, y: 225.6817927758077 },
        tangent1: { x: 340.568449864699, y: 178.14464832571167 },
        tangent2: { x: 336.27295399809947, y: 232.96357212828906 },
    },
    {
        main: { x: 325.725159924305, y: 100.77811934235372 },
        isTangentsLinked: true,
        id: '2',
        tangent1: { x: 320.9988934111184, y: 86.91891807348163 },
        tangent2: { x: 340.22312584014264, y: 143.2916396984797 },
    },
    {
        id: 'maskToolPoint-kru19yvk',
        isTangentsLinked: true,
        main: { x: 310.34790454943914, y: 80.75650993487876 },
        tangent1: { x: 302.67219108611573, y: 72.91916388466733 },
        tangent2: { x: 315.75068054360474, y: 86.27305576483694 },
    },
    {
        id: 'maskToolPoint-kru15ii1',
        isTangentsLinked: true,
        main: { x: 275.2648580985041, y: 55.07443534157473 },
        tangent1: { x: 258.710976628166, y: 30.501323055822517 },
        tangent2: { x: 283.4459762031251, y: 67.21874930009109 },
    },
    {
        id: 'maskToolPoint-kru1atnk',
        isTangentsLinked: true,
        main: { x: 219.9604096824162, y: 25.30791493473209 },
        tangent1: { x: 205.95874459666643, y: 19.151639816630052 },
        tangent2: { x: 239.54980407420936, y: 33.92101205813226 },
    },
    {
        id: 'maskToolPoint-kru15f5c',
        isTangentsLinked: true,
        main: { x: 175.62787382147374, y: 14.044652601650817 },
        tangent1: { x: 131.9137915219254, y: 18.514396632806378 },
        tangent2: { x: 190.5753260644685, y: 12.516282750803045 },
    },
];
