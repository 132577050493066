import selectRandomlyByLikelihood from 'src/utils/objectUtils/selectRandomlyByLikelihood';

export default function getMostRelevantAnimationName({
    bodyPart,
    influence,
    animationsByExpression,
    bodyPartsAlreadyUsedForThisKey,
}) {
    const { expression } = influence;
    const animationMapForExpression = animationsByExpression[expression];

    const animationMapMatchingBodyPart = Object.keys(animationMapForExpression).reduce(
        (animationMapMatchingBodyPart, animationName) => {
            const bodyPartsUsedByAnimation =
                animationMapForExpression[animationName].affectedBodyParts;
            const isAnimationIncludesBodyPart = bodyPartsUsedByAnimation.includes(bodyPart);
            const isAnimationUsingAlreadyUsedBodyPart = bodyPartsUsedByAnimation.some(
                (usedBodyPart) => {
                    return bodyPartsAlreadyUsedForThisKey.has(usedBodyPart);
                },
            );

            if (isAnimationIncludesBodyPart && !isAnimationUsingAlreadyUsedBodyPart) {
                animationMapMatchingBodyPart[animationName] =
                    animationMapForExpression[animationName];
            }
            return animationMapMatchingBodyPart;
        },
        {},
    );

    if (!Object.keys(animationMapMatchingBodyPart).length) {
        return null;
    }

    const mostRelevantAnimationName = selectRandomlyByLikelihood({
        collection: animationMapMatchingBodyPart,
    });

    return mostRelevantAnimationName;
}
