import calcTimeIndicatorHeadWidth from 'src/components/views/SceneEditor/Timeline/TimeTracker/utils/calcTimeIndicatorHeadWidth';
import calcTimeIndicatorLineWidth from 'src/components/views/SceneEditor/Timeline/TimeTracker/utils/calcTimeIndicatorLineWidth';
import calcZoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcZoomedValue';

const timeIndicatorHeadWidth = calcTimeIndicatorHeadWidth();
const headCenterOffset = -timeIndicatorHeadWidth / 2;

const timeIndicatorLineWidth = calcTimeIndicatorLineWidth();
const lineCenterOffset = -timeIndicatorLineWidth / 2;

export default function calcTimeIndicatorPositions({ currentTime, timeWidth, zoomLevel }) {
    const currentTimePosition = currentTime * timeWidth;
    const zoomedCurrentTimePositon = calcZoomedValue({ value: currentTimePosition, zoomLevel });

    const timeIndicatorHeadPosition = zoomedCurrentTimePositon + headCenterOffset;
    const timeIndicatorLinePosition = zoomedCurrentTimePositon + lineCenterOffset;

    return { timeIndicatorHeadPosition, timeIndicatorLinePosition };
}
