import { useCallback } from 'react';

import updateSceneOnBackend from 'src/utils/backendUtils/updateSceneOnBackend';

export default function useHandleAudioTrackClick({
    selectedAudioTrackId,
    setAudioTrackSelection,
    clearAudioListItemSelection,
    selectedSceneId,
}) {
    return useCallback(
        function _handleAudioTrackClick(id) {
            const isAlreadySelected = id === selectedAudioTrackId;
            if (isAlreadySelected) {
                return;
            }

            clearAudioListItemSelection({ isSkipBackendUpdate: true });

            setAudioTrackSelection({
                sceneId: selectedSceneId,
                selectedAudioTrackId: id,
            });

            updateSceneOnBackend({ sceneChanges: { selectedAudioTrackId: id }, selectedSceneId });
        },
        [
            selectedAudioTrackId,
            clearAudioListItemSelection,
            setAudioTrackSelection,
            selectedSceneId,
        ],
    );
}
