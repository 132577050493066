import { useEffect, useRef } from 'react';
import pixiStore from 'src/pixi/pixiStore';
import { Container, Graphics } from 'pixi.js';

import drawPreviewFrame from 'src/components/views/TextureRouteManager/TextureManager/hooks/useFoTextureCompPreview/drawPreviewFrame';

export default function useSetupPreviewContainer() {
    const previewContainerRef = useRef(new Container());
    const previewContainer = previewContainerRef.current;

    const previewFrameGraphicsRef = useRef(new Graphics());
    const previewFrameGraphics = previewFrameGraphicsRef.current;

    useEffect(function _setupPreviewContainer() {
        const app = pixiStore.pixiAppManager.getPixiApp();

        drawPreviewFrame({ screen: app.screen, graphics: previewFrameGraphics });
        previewContainer.addChild(previewFrameGraphics);

        app.stage.addChild(previewContainer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { previewContainer, previewFrameGraphics };
}
