import { sortBy } from 'lodash';

import flattenClips from './flattenClips';

export default function flattenAndSortLayerClips({ track, timeWidth }) {
    const layersWithSortedClips = track.layers.allIds.map(function _flattenAndSortLayer(
        trackLayerId,
    ) {
        const { clipsById } = track.layers.byId[trackLayerId];
        const flattenedClips = flattenClips({ clipsById, timeWidth });

        const sortedClips = sortBy(flattenedClips, 'position');

        return sortedClips;
    });

    return layersWithSortedClips;
}
