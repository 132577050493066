export default function rearrangeAnimationTreeNodes(animationTreeNodes) {
    const leavesArr = [];
    const branchesArr = [];

    animationTreeNodes.forEach((animationTreeNode) => {
        if (animationTreeNode.children) {
            const rearrangedAnimationTreeNodes = rearrangeAnimationTreeNodes(
                animationTreeNode.children,
            );

            const updatedAnimationTreeNode = {
                ...animationTreeNode,
                children: rearrangedAnimationTreeNodes,
            };

            branchesArr.push(updatedAnimationTreeNode);
        } else {
            leavesArr.push(animationTreeNode);
        }
    });

    return [...branchesArr, ...leavesArr];
}
