import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as routes from 'src/components/constants/routes';
import Spinner from 'src/components/shared/Spinner';
import { setScene } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import { setSelectedFoSpineAssetTrackId } from 'src/components/views/SceneEditor/sceneEditorSlice';
import { sceneLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import useLoadScene from 'src/components/views/ScenesManager/RequireSceneLoad/hooks/useLoadScene';
import useEnsureSelectedTrack from 'src/components/views/ScenesManager/RequireSceneLoad/hooks/useEnsureSelectedTrack';
import withRouter from 'src/reduxStore/utils/withRouter';

import styles from './styles.module.scss';

function RequireSceneLoad({
    selectedScene,
    selectedSceneId,
    selectedFoSpineAssetTrackId,
    setScene,
    setSelectedFoSpineAssetTrackId,
    children,
}) {
    const location = useLocation();

    const isSceneLoaded = !!selectedScene?.tracks;

    const sceneLoadingErrorMessage = useLoadScene({
        selectedSceneId,
        isSceneLoaded,
        setScene,
    });

    const selectedTrack = useEnsureSelectedTrack({
        selectedScene,
        selectedFoSpineAssetTrackId,
        setSelectedFoSpineAssetTrackId,
        isSceneLoaded,
    });

    if (!selectedSceneId) {
        return <Navigate to={`/${routes.SCENES}`} state={{ fromLocation: location }} replace />;
    }

    if (isSceneLoaded && selectedTrack) {
        return children;
    }

    const sceneName = selectedScene?.name ? `name: ${selectedScene?.name}, ` : '';
    const sceneCredentialsString = `${sceneName}id: ${selectedSceneId}`;

    let loadingMessage = (
        <>
            <Spinner />
            <div>Loading scene: {sceneCredentialsString}</div>
        </>
    );
    if (sceneLoadingErrorMessage) {
        loadingMessage = (
            <div>
                There was a problem with loading scene:
                <br />
                {sceneCredentialsString}
                <br />
                <br />
                {sceneLoadingErrorMessage.message}
            </div>
        );
    }

    return <div className={styles.RequireSceneLoad}>{loadingMessage}</div>;
}

RequireSceneLoad.propTypes = {
    selectedScene: sceneLike,
    selectedSceneId: PropTypes.string,
    selectedFoSpineAssetTrackId: PropTypes.string,
    setScene: PropTypes.func.isRequired,
};

RequireSceneLoad.defaultProps = {
    selectedScene: null,
    selectedSceneId: '',
    selectedFoSpineAssetTrackId: '',
};

function mapState(state, ownProps) {
    const { selectedFoSpineAssetTrackId } = state.sceneEditor;
    const { sceneId: selectedSceneId } = ownProps.router.params;
    const { scenes } = state.sceneSelector;
    const selectedScene = scenes.byId[selectedSceneId];

    return {
        selectedScene,
        selectedSceneId,
        selectedFoSpineAssetTrackId,
    };
}

export default withRouter(
    connect(mapState, { setScene, setSelectedFoSpineAssetTrackId })(RequireSceneLoad),
);
