import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

const HiddenDragPreview = forwardRef((props, ref) => {
    const { className } = props;

    return <div className={classnames('HiddenDragPreview', className)} ref={ref} />;
});

HiddenDragPreview.propTypes = {
    className: PropTypes.string,
};

HiddenDragPreview.defaultProps = {
    className: '',
};

export default HiddenDragPreview;
