import drawBezierCurvesFromPoints from 'src/pixi/tools/MaskTool/utils/drawBezierCurvesFromPoints';

export default function drawBezierFill({ graphics, points, color = 0xffffff }) {
    graphics.clear();
    graphics.beginFill(color);
    drawBezierCurvesFromPoints({
        points,
        graphics,
    });
    graphics.endFill();
}
