import { isObject, isNumber } from 'lodash';

export default function assertAnchorIsValid(anchor) {
    if (!isObject(anchor)) {
        throw new Error('anchor has to be an object, received: ', anchor);
    }
    if (!isNumber(anchor.x)) {
        throw new Error('anchor.x has to be a number, received: ', anchor.x);
    }
    if (!isNumber(anchor.y)) {
        throw new Error('anchor.y has to be a number, received: ', anchor.y);
    }
}
