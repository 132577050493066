import AudioController from 'src/utils/audioUtils/AudioController';

export default class AudioManager {
    cache = {};

    emptyCache() {
        this.cache = {};
    }

    getAudioControllerById(id) {
        const audioCache = this.cache[id];
        if (audioCache) {
            return audioCache.audioController;
        }
        return null;
    }

    setAudioControllereById({ id, audioController }) {
        const audioCache = this.cache[id] || {};
        this.cache[id] = { ...audioCache, audioController };
    }

    getWaveformDataById(id) {
        const buffer = this.getAudioBufferById(id);
        if (buffer) {
            const waveformData = buffer.getChannelData(0);
            return waveformData;
        }
        return null;
    }

    getAudioBufferById(id) {
        let audioBuffer = null;
        const audioController = this.getAudioControllerById(id);
        if (audioController) {
            audioBuffer = audioController.getBuffer();
        }
        return audioBuffer;
    }

    forceGetAudioController({ url, id }) {
        let audioController = this.getAudioControllerById(id);

        if (!audioController) {
            audioController = new AudioController(url);
            this.setAudioControllereById({
                id,
                audioController,
            });
        }

        return audioController;
    }
}
