import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

export default function DangerMessage(props) {
    const { className, children, ...attributes } = props;

    return (
        <div className={classnames('DangerMessage', className)} {...attributes}>
            {children}
        </div>
    );
}

DangerMessage.propTypes = {
    className: PropTypes.string,
};

DangerMessage.defaultProps = {
    className: '',
};
