import { getCurrentUserEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import store from 'src/reduxStore';
import { setUser } from 'src/components/views/UserProfile/userProfileSlice';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function setCurrentUserIfTokenPresent() {
    const token = localStorage.getItem('token');
    if (!token) {
        return;
    }

    try {
        const currentUserEndpoint = getCurrentUserEndpoint();

        const response = await fetchWithAuth(currentUserEndpoint);

        if (response.ok) {
            const userData = await response.json();

            store.dispatch(setUser(userData));
        } else {
            if (response.status === 401) {
                localStorage.removeItem('token');
            }
            const errorData = await response.json();
            throw new Error(errorData.errors[0].message);
        }
    } catch (err) {
        console.log(err);
    }
}
