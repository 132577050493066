import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import MODES from 'src/components/views/SceneEditor/Timeline/constants/trimModes';
import ToolContainer from 'src/components/shared/ToolContainer';

import styles from './styles.module.scss';

export default function TrimModes(props) {
    const { className, activeMode, setTrimMode, isDisabled, ...attributes } = props;

    return (
        <ToolContainer
            className={classnames(styles.TrimModes, className)}
            isSubContainer
            {...attributes}
        >
            {Object.values(MODES).map((mode) => {
                const isCurrentActive = activeMode === mode;
                return (
                    <ToolButton
                        key={mode}
                        isActive={isCurrentActive}
                        onClick={() => setTrimMode(mode)}
                        className='ToolContainer__tool'
                        isDisabled={isDisabled}
                    >
                        {mode}
                    </ToolButton>
                );
            })}
        </ToolContainer>
    );
}

TrimModes.propTypes = {
    className: PropTypes.string,
    activeMode: PropTypes.oneOf([...Object.values(MODES)]).isRequired,
    setTrimMode: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
};

TrimModes.defaultProps = {
    className: '',
};
