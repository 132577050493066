import makeBgGhostLayerGraphics from './makeBgGhostLayerGraphics';
import {
    handleStageClick,
    handleEscDown,
    handleWindowResize,
} from './bgGhostLayerInteractionHandlers';

export default function makeBgGhostLayer({ transformTool, app }) {
    const bgGhostLayer = makeBgGhostLayerGraphics();

    transformTool.bgGhostLayer = bgGhostLayer;
    bgGhostLayer.transformTool = transformTool;

    transformTool.addChild(bgGhostLayer);

    // we use stage instead of the bgGhostLayer itself for interaction, because
    // ghost layer will sit on top of other targets and will take two clicks to select them.
    // 1st to deselect current target and hide ghost layer, 2nd to select new target.
    app.stage.interactive = true;
    app.stage.on('pointerdown', handleStageClick);
    app.stage.transformTool = transformTool;
    transformTool.stage = app.stage;

    window.addEventListener('keydown', handleEscDown);
    window.addEventListener('resize', handleWindowResize);
    window.transformTool = transformTool;

    return bgGhostLayer;
}
