import { Graphics } from 'pixi.js';

import buildPointsRelToRoot from './buildPointsRelToRoot';
import drawLines from './drawLines';

export default function drawAttachmentBounds({
    slot,
    graphics = new Graphics(),
    lineWidth = 2,
    color = 0x00ff00,
    isSkipClear,
    spineAssetInstance,
    root,
} = {}) {
    if (!isSkipClear) {
        graphics.clear();
    }

    graphics.lineStyle(lineWidth, color);
    let verticesRelToSpineAssetInstance;
    const attachment = slot.getAttachment();
    if (attachment instanceof PIXI.spine.core.RegionAttachment) {
        const vertices = [];
        attachment.updateOffset();
        attachment.computeWorldVertices(slot.bone, vertices, 0, 2);
        verticesRelToSpineAssetInstance = vertices.slice(0, 8);
    } else if (attachment instanceof PIXI.spine.core.MeshAttachment && attachment.hullLength > 0) {
        const vertices = [];

        attachment.computeWorldVertices(slot, 0, attachment.worldVerticesLength, vertices, 0, 2);

        const { hullLength } = attachment;
        const wholeHullLength = Math.floor(hullLength / 2) * 2;
        verticesRelToSpineAssetInstance = vertices.slice(0, wholeHullLength);
    }

    const pointsRelToRoot = buildPointsRelToRoot({
        verticesRelToSpineAssetInstance,
        spineAssetInstance,
        root,
    });

    drawLines({ pointsRelToRoot, graphics });

    graphics.zIndex = 1;

    return graphics;
}
