import getGlobalSize from 'src/pixi/utils/getGlobalSize';

export default function buildPosition({
    displayObject,
    refDisplayObject,
    positionDiffToSizeRatio,
}) {
    const refDisplayObjectGlobalSize = getGlobalSize(refDisplayObject);

    const displayObjGlobalPosRelToRef = {
        x: positionDiffToSizeRatio.x * refDisplayObjectGlobalSize.width,
        y: positionDiffToSizeRatio.y * refDisplayObjectGlobalSize.height,
    };
    const refDisplayObjectGlobalPos = refDisplayObject.getGlobalPosition();
    const displayObjectGlobalPosition = {
        x: refDisplayObjectGlobalPos.x + displayObjGlobalPosRelToRef.x,
        y: refDisplayObjectGlobalPos.y + displayObjGlobalPosRelToRef.y,
    };

    let position = displayObjectGlobalPosition;
    if (displayObject.parent) {
        const displayObjLocalPosRelToItsOwnParent = displayObject.parent.toLocal(
            displayObjectGlobalPosition,
        );
        position = displayObjLocalPosRelToItsOwnParent;
    }

    return position;
}
