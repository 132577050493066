import buildSizeRatio from './buildSizeRatio';
import buildPositionDiffRatio from './buildPositionDiffRatio';
import buildRotationDiff from './buildRotationDiff';

export default function buildRelativeTransform({ displayObject, refDisplayObject }) {
    const relativeTransform = {};

    relativeTransform.sizeRatio = buildSizeRatio({
        displayObject,
        refDisplayObject,
    });

    relativeTransform.positionDiffToSizeRatio = buildPositionDiffRatio({
        displayObject,
        refDisplayObject,
    });

    relativeTransform.rotationDiff = buildRotationDiff({
        displayObject,
        refDisplayObject,
    });

    relativeTransform.pivot = {
        x: displayObject.pivot.x,
        y: displayObject.pivot.y,
    };

    return relativeTransform;
}
