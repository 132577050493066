import React, { useCallback, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import NestedList from 'src/components/shared/NestedList';
import { nestedListItemDataLike } from 'src/components/shared/NestedList/propTypes';

import './styles.scss';

export default function NestedListItem(props) {
    const { className, itemData, onClick, isSelected, ...attributes } = props;

    const itemContentRef = useRef();
    const handleItemClick = useCallback(
        function _handleItemClick(event) {
            if (event.target !== itemContentRef.current) {
                return;
            }
            onClick(event, itemData);
        },
        [itemContentRef, onClick, itemData],
    );
    const handleItemContentClick = useCallback(
        function _handleItemContentClick(event) {
            itemData?.contentElemAttributes?.onClick(event, itemData);
        },
        [itemData],
    );
    const style = itemData.placementStyle || {
        left: '100%',
        top: 0,
    };

    return (
        <li
            className={classnames(
                'NestedListItem',
                { 'NestedListItem--selected': isSelected },
                className,
            )}
            onClick={handleItemClick}
            role='none'
            {...attributes}
        >
            <div
                ref={itemContentRef}
                role='none'
                {...(itemData.contentElemAttributes || {})}
                className={classnames(
                    'NestedListItem__content',
                    itemData?.contentElemAttributes?.className,
                )}
                onClick={handleItemContentClick}
            >
                {itemData.content}
            </div>
            {isSelected && itemData.childrenData && (
                <NestedList isChild listData={itemData.childrenData} style={style} />
            )}
        </li>
    );
}

NestedListItem.propTypes = {
    className: PropTypes.string,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    itemData: nestedListItemDataLike.isRequired,
};

NestedListItem.defaultProps = {
    className: '',
};
