import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Select from 'src/components/shared/selectFactory/Select';

import './styles.scss';

export default function StyledSelect(props) {
    const { className, ...attributes } = props;

    return <Select className={classnames('StyledSelect', className)} {...attributes} />;
}

StyledSelect.propTypes = {
    className: PropTypes.string,
};

StyledSelect.defaultProps = {
    className: '',
};
