/**
 * returns an array of non-negative numbers between [0,1]
 */
export default function normalizeWaveformData(dataArray) {
    const maxValue = Math.max(...dataArray);

    const normalizedDataArray = dataArray.map((value) => {
        return value / maxValue;
    });

    return normalizedDataArray;
}
