import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import fillAreaWithRepeatingClipsDeterministically from 'src/utils/businessLogic/clipUtils/fillAreaWithRepeatingClipsDeterministically';

export default function fillGapsWithIdleAnimations({
    positionToScheduleLayersFrom,
    sortedClips,
    spineInstanceIdleAnimation,
    timeWidth,
}) {
    let sortedClipsWithIdle = [];

    const firstClip = sortedClips[0];
    if (firstClip && firstClip.position > positionToScheduleLayersFrom) {
        const idleSequenceForGap = fillAreaWithRepeatingClipsDeterministically({
            endPosition: sortedClips[0].position,
            startPosition: positionToScheduleLayersFrom,
            spineInstanceAnimation: spineInstanceIdleAnimation,
            timeWidth,
        });

        sortedClipsWithIdle = [...idleSequenceForGap];
    }

    sortedClips.forEach((clip, index) => {
        sortedClipsWithIdle.push(clip);
        const nextClip = sortedClips[index + 1];
        const currentClipEnd = getClipEnd({ clip, timeWidth });
        if (nextClip && currentClipEnd < nextClip.position) {
            const idleSequenceForGap = fillAreaWithRepeatingClipsDeterministically({
                endPosition: nextClip.position,
                startPosition: currentClipEnd,
                spineInstanceAnimation: spineInstanceIdleAnimation,
                timeWidth,
            });
            sortedClipsWithIdle = sortedClipsWithIdle.concat(idleSequenceForGap);
        }
    });

    return sortedClipsWithIdle;
}
