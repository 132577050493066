// todo - backend and frontend should share these types, so we have a single source of truth.
export const audioTrackTypes = Object.freeze({
    MUSIC: 'MUSIC',
    SPEECH: 'SPEECH',
});

export const CLOSED_MOUTH_CHARACTER = 'neutral_mouth';

// seconds
export const NEUTRAL_SPEECH_DURATION_THRESHOLD = 0.1;

export const languages = Object.freeze({
    ENG: 'English',
    ARM: 'Armenian',
});
