import reduxStore from 'src/reduxStore';

export default function getExistingLayersNotGeneratedFromExpressions({
    isSkipEmptyLayers = false,
    selectedSceneId,
}) {
    const reduxState = reduxStore.getState();
    const { selectedFoSpineAssetTrackId } = reduxState.sceneEditor;
    const selectedTrack =
        reduxState.sceneSelector.scenes.byId[selectedSceneId].tracks.byId[
            selectedFoSpineAssetTrackId
        ];
    const { byId: existingLayerById, allIds: allExistingLayerIds } = selectedTrack.layers;

    const allFilteredLayerIds = [];
    const filteredLayersById = {};
    allExistingLayerIds.forEach((layerId) => {
        const {
            clipsById,
            settings: { isGeneratedFromExpressions },
        } = existingLayerById[layerId];
        const isEmpty = !Object.keys(clipsById).length;
        const isSkipLayerBecauseEmpty = isSkipEmptyLayers && isEmpty;
        if (!isSkipLayerBecauseEmpty && !isGeneratedFromExpressions) {
            allFilteredLayerIds.push(layerId);
            filteredLayersById[layerId] = existingLayerById[layerId];
        }
    });

    return { layersById: filteredLayersById, allIds: allFilteredLayerIds };
}
