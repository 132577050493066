import * as eventNames from 'src/pixi/tools/TransformTool/constants/eventNames';

export function handleGhostLayerDragStart(event) {
    this.data = event.data;
    this.alpha = 0.5;
    this.isDragging = true;
    const { target } = this.transformTool;
    this.lastDragPosition = this.data.getLocalPosition(target.parent);
}

export function handleGhostLayerDragMove() {
    if (!this.isDragging) {
        return;
    }
    const { transformTool } = this;
    const { target } = transformTool;

    const newPosition = this.data.getLocalPosition(target.parent);
    const diff = {
        x: newPosition.x - this.lastDragPosition.x,
        y: newPosition.y - this.lastDragPosition.y,
    };
    this.lastDragPosition = newPosition;
    target.position.x += diff.x;
    target.position.y += diff.y;
    transformTool.positionKnobs();
}

export function handleGhostLayerDragEnd() {
    this.alpha = 1;
    this.isDragging = false;
    this.data = null;
    this.lastDragPosition = null;

    const { transformTool } = this;
    transformTool.runEventHandlersFor(eventNames.MOVE_END);
    transformTool.runEventHandlersFor(eventNames.ANY_DRAG_END);
}
