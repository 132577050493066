import {
    handleStageDragStart,
    handleStageDragMove,
    handleStageDragEnd,
    handleEscDown,
    handleWindowResize,
} from 'src/pixi/tools/MaskTool/makeBgGhostLayer/bgGhostLayerInteractionHandlers';

export default function destroyMaskTool(maskTool) {
    maskTool.clear();
    window.removeEventListener('keydown', handleEscDown);
    window.removeEventListener('resize', handleWindowResize);
    delete window.maskTool;

    maskTool.app.stage
        .off('pointerdown', handleStageDragStart)
        .off('pointermove', handleStageDragMove)
        .off('pointerup', handleStageDragEnd)
        .off('pointerupoutside', handleStageDragEnd);
    delete maskTool.app.stage.maskTool;
    delete maskTool.app;

    const isMaskEverAppliedToTarget = !!maskTool.maskOutline;
    if (isMaskEverAppliedToTarget) {
        delete maskTool.maskOutline.content;
        delete maskTool.maskOutline.interactionArea;
        delete maskTool.maskOutline;

        delete maskTool.pointControlsById;

        delete maskTool.target.maskTool;
        delete maskTool.target;

        delete maskTool.bgGhostLayer.maskTool;
        delete maskTool.bgGhostLayer;
    }
}
