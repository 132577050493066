import React, { useRef, useContext } from 'react';
import { useDragLayer } from 'react-dnd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import dndTypes from 'src/components/views/SceneEditor/constants/dndTypes';
import Clip from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/TrackLayer/ClipContainerDnD/ClipContainer/Clip';
import getGrabPositionOffsetFromItemsLeftEdge from 'src/utils/dndUtils/getGrabPositionOffsetFromItemsLeftEdge';
import useIsDrawing from 'src/components/shared/hooks/useIsDrawing';
import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import hasObjectTruthyProp from 'src/utils/objectUtils/hasObjectTruthyProp';

import styles from './styles.module.scss';

function collect(monitor) {
    const isDragging = monitor.isDragging();

    const grabPositionOffsetFromItemsLeftEdge = getGrabPositionOffsetFromItemsLeftEdge(monitor);

    return {
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging,
        grabPositionOffsetFromItemsLeftEdge,
    };
}

function getLayerStyle({ currentOffset, grabPositionOffsetFromItemsLeftEdge, item, timeWidth }) {
    let { width } = item;
    if (item.type === dndTypes.ANIMATION) {
        width = getClipWidth({ clipData: item.data, timeWidth });
    }
    const xCorrection = Math.max(grabPositionOffsetFromItemsLeftEdge - width, 0);

    const style = {
        transform: `translate(${currentOffset.x + xCorrection}px, ${currentOffset.y}px)`,
    };

    return style;
}

function renderDragLayerContent({ itemType, item, timeWidth }) {
    let dragLayerContent = null;

    if (itemType === dndTypes.ANIMATION || itemType === dndTypes.CLIP) {
        let { width } = item;
        const { clipId = '', data } = item;
        if (itemType === dndTypes.ANIMATION) {
            width = getClipWidth({ clipData: item.data, timeWidth });
        }

        dragLayerContent = (
            <Clip
                clipId={clipId}
                left={0}
                style={{
                    width,
                }}
                data={data}
                className={styles.Clip}
            />
        );
    }

    return dragLayerContent;
}

function CustomDragLayer({ timeWidth }) {
    const {
        isDragging,
        itemType,
        currentOffset,
        item,
        grabPositionOffsetFromItemsLeftEdge,
    } = useDragLayer(collect);

    const { isDrawingRef, setIsDrawing } = useIsDrawing();
    const styleRef = useRef(null);
    const dragLayerContentRef = useRef(null);

    const { isHoveringMapRef } = useContext(SceneEditorContext);
    const isHovering = hasObjectTruthyProp(isHoveringMapRef.current, undefined, true);

    if (!isDragging || !currentOffset || isHovering) {
        return null;
    }

    if (!isDrawingRef.current) {
        setIsDrawing();
        styleRef.current = getLayerStyle({
            currentOffset,
            grabPositionOffsetFromItemsLeftEdge,
            item,
            timeWidth,
        });

        dragLayerContentRef.current = renderDragLayerContent({
            itemType,
            item,
            timeWidth,
        });
    }

    const style = styleRef.current;

    const dragLayerContent = dragLayerContentRef.current;

    return (
        dragLayerContent && (
            <div className={styles.CustomDragLayer} style={style}>
                {dragLayerContent}
            </div>
        )
    );
}

CustomDragLayer.propTypes = {
    timeWidth: PropTypes.number.isRequired,
};

function mapState(state) {
    const { timeWidth } = state.timeline;

    return {
        timeWidth,
    };
}

export default connect(mapState)(CustomDragLayer);
