import { useCallback, useState, useRef, useEffect } from 'react';

import clipTypes from 'src/components/constants/clipTypes';
import generateClipId from 'src/utils/businessLogic/clipUtils/generateClip/generateClipId';

export default function useGroupNameDialogue() {
    const [isNameDialogueVisible, setIsNameDialogueVisible] = useState(false);
    const [groupName, setGroupName] = useState('');

    const handleGroupNameChange = useCallback(
        function _handleGroupNameChange(event) {
            setGroupName(event.target.value);
        },
        [setGroupName],
    );

    const clipGroupIdRef = useRef('');

    useEffect(
        function _refillNameWithNewClipGroupId() {
            const clipGroupId = generateClipId(clipTypes.CLIP_GROUP);
            if (isNameDialogueVisible) {
                setGroupName(clipGroupId);
                clipGroupIdRef.current = clipGroupId;
            } else {
                setGroupName('');
                clipGroupIdRef.current = '';
            }
        },
        [isNameDialogueVisible, setGroupName, clipGroupIdRef],
    );

    const toggleGroupNameDialogue = useCallback(
        function _toggleGroupNameDialogue() {
            setIsNameDialogueVisible(!isNameDialogueVisible);
        },
        [isNameDialogueVisible, setIsNameDialogueVisible],
    );

    return {
        groupName,
        handleGroupNameChange,
        toggleGroupNameDialogue,
        isNameDialogueVisible,
        clipGroupId: clipGroupIdRef.current,
    };
}
