import {
    KNOB_INTERACTION_SCALE,
    KNOB_DEFAULT_SCALE,
} from 'src/pixi/tools/TransformTool/constants/transformKnobs';
import * as eventNames from 'src/pixi/tools/TransformTool/constants/eventNames';
import { calcAngleRadBetween2PointsAndAxisX } from 'src/utils/mathUtils/geoMathUtils';

export function handleRotateKnobDragStart(event) {
    // event.stopPropagation();
    this.data = event.data;
    this.isDragging = true;
    this.scale.set(KNOB_INTERACTION_SCALE);

    const localPosition = this.data.getLocalPosition(this.transformTool.translateLayer);
    this.initialKnobAngle = calcAngleRadBetween2PointsAndAxisX({
        point1: localPosition,
        point2: { x: 0, y: 0 },
    });
    this.initialRotation = this.transformTool.rotateLayer.rotation;
    this.initialTargetRotation = this.transformTool.target.rotation;
}

export function handleRotateKnobDragEnd() {
    this.isDragging = false;
    this.data = null;
    this.scale.set(KNOB_DEFAULT_SCALE);

    this.initialKnobAngle = null;
    this.initialRotation = null;
    this.initialTargetRotation = null;

    const { transformTool } = this;
    transformTool.runEventHandlersFor(eventNames.ROTATE_END);
    transformTool.runEventHandlersFor(eventNames.ANY_DRAG_END);
}

export function handleRotateKnobDragMove() {
    if (this.isDragging) {
        const { transformTool } = this;
        const { target, rotateLayer } = transformTool;

        const localPosition = this.data.getLocalPosition(this.transformTool.translateLayer);
        const currentAngle = calcAngleRadBetween2PointsAndAxisX({
            point1: localPosition,
            point2: { x: 0, y: 0 },
        });
        const angleDiff = currentAngle - this.initialKnobAngle;
        rotateLayer.rotation = this.initialRotation + angleDiff;
        target.rotation = this.initialTargetRotation + angleDiff;
    }
}
