export default function buildProbabilityRanges({ collection, rankPropName = 'rank' }) {
    const probabilityRanges = Object.keys(collection).reduce((probabilityRanges, itemKey) => {
        const currItem = collection[itemKey];
        const rankValue = currItem[rankPropName];
        const rank = Number(rankValue);

        if (Number.isNaN(rank)) {
            console.error(
                `${rankPropName} at key: ${itemKey} cannot be converted to a number. Please review it:`,
                rankValue,
            );
            return probabilityRanges;
        }

        if (!rank) {
            return probabilityRanges;
        }

        const previousRangeItem = probabilityRanges[probabilityRanges.length - 1];
        const { max: prevMax } = previousRangeItem || {
            max: 0,
        };

        const min = prevMax;
        const max = min + rank;

        const rangeItem = { itemKey, min, max };

        probabilityRanges.push(rangeItem);

        return probabilityRanges;
    }, []);

    return probabilityRanges;
}
