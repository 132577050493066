export default function getGraphemeToAnimationMapByFoSpineAssetId({ tracks, foSpineAssets }) {
    const graphemeToAnimationMapByFoSpineAssetId = {};
    Object.values(tracks.byId).forEach((track) => {
        const { graphemeToAnimationMap } = foSpineAssets.byId[track.foSpineAsset.id];
        if (graphemeToAnimationMap) {
            graphemeToAnimationMapByFoSpineAssetId[track.foSpineAsset.id] = graphemeToAnimationMap;
        }
    });

    return graphemeToAnimationMapByFoSpineAssetId;
}
