import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';

/**
 * @headTrimAmount > 0 => trim right, otherwise, trim left
 * @tailTrimAmount > 0 => trim right, otherwise, trim left
 * @clipData
 * @trimMode
 * @timeWidth
 */
export default function contentTrimClipGroupData({
    headTrimAmount = 0,
    tailTrimAmount = 0,
    clipData,
    timeWidth,
}) {
    if (!headTrimAmount && !tailTrimAmount) {
        return clipData;
    }
    if (!timeWidth) {
        console.error(`can't trim a clip's data without a valid timeWidth. 
        Received: ${timeWidth}`);
    }

    let trimmedClipData = clipData;

    const untrimmedClipGroupWidth = getClipWidth({
        clipData,
        timeWidth,
        isGetUntrimmed: true,
    });

    trimmedClipData = { ...clipData };

    let newTailTrimPercent = clipData.tailTrimPercent;
    let newHeadTrimPercent = clipData.headTrimPercent;

    if (tailTrimAmount) {
        const tailTrimPercentChange = tailTrimAmount / untrimmedClipGroupWidth;
        newTailTrimPercent -= tailTrimPercentChange;
        newTailTrimPercent = Math.max(newTailTrimPercent, 0);
        const maxAllowedTailTrimPercent = 100 - newHeadTrimPercent;
        newTailTrimPercent = Math.min(maxAllowedTailTrimPercent, newTailTrimPercent);
    }

    if (headTrimAmount) {
        const headTrimPercentChange = headTrimAmount / untrimmedClipGroupWidth;
        newHeadTrimPercent += headTrimPercentChange;
        newHeadTrimPercent = Math.max(newHeadTrimPercent, 0);
        const maxAllowedHeadTrimPercent = 100 - newTailTrimPercent;
        newHeadTrimPercent = Math.min(maxAllowedHeadTrimPercent, newHeadTrimPercent);
    }

    trimmedClipData.headTrimPercent = newHeadTrimPercent;
    trimmedClipData.tailTrimPercent = newTailTrimPercent;

    return trimmedClipData;
}
