import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

export default function Select(props) {
    const { className, ...attributes } = props;

    return <select className={classnames('Select', className)} {...attributes} />;
}

Select.propTypes = {
    className: PropTypes.string,
};

Select.defaultProps = {
    className: '',
};
