import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import getPositionRelativeToLeftNeighbout from 'src/utils/businessLogic/clipUtils/insertClip/insertShift/getPositionRelativeToLeftNeighbout';

export default function moveClipToAvoidLeftConflict({ clip, leftNeighbour, timeWidth }) {
    if (!clip || !leftNeighbour) {
        return clip;
    }

    const {
        isInLeftNeighboursFirstHalf,
        isInLeftNeighboursSecondHalf,
    } = getPositionRelativeToLeftNeighbout({ clip, leftNeighbour, timeWidth });

    const adjustedClip = { ...clip };
    if (isInLeftNeighboursFirstHalf) {
        adjustedClip.position = leftNeighbour.position;
    } else if (isInLeftNeighboursSecondHalf) {
        const leftNeighbourEnd = getClipEnd({ clip: leftNeighbour, timeWidth });
        adjustedClip.position = leftNeighbourEnd;
    }

    return adjustedClip;
}
