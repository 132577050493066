import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import generateTrackLayer from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayer';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';

export default function useHandleAddTrackLayerButtonClick({ setTrackLayer, trackId }) {
    const { sceneId: selectedSceneId } = useParams();

    const handleAddTrackLayerButtonClick = useCallback(
        function _handleAddTrackLayerButtonClick() {
            const trackLayer = generateTrackLayer();
            setTrackLayer({
                sceneId: selectedSceneId,
                trackId,
                trackLayer,
            });

            updateTracksOnBackend({ selectedSceneId });
        },
        [setTrackLayer, trackId, selectedSceneId],
    );

    return handleAddTrackLayerButtonClick;
}
