import { Graphics } from 'pixi.js';

import { rotateLayerZIndices } from 'src/pixi/tools/TransformTool/constants/zIndices';

import {
    handleGhostLayerDragStart,
    handleGhostLayerDragMove,
    handleGhostLayerDragEnd,
} from './ghostLayerDragHandlers';

export default function makeGhostLayer(transformTool) {
    const ghostLayer = new Graphics();

    ghostLayer.zIndex = rotateLayerZIndices.GHOST_LAYER;
    ghostLayer.interactive = true;
    ghostLayer.buttonMode = true;
    ghostLayer
        .on('pointerdown', handleGhostLayerDragStart)
        .on('pointermove', handleGhostLayerDragMove)
        .on('pointerup', handleGhostLayerDragEnd)
        .on('pointerupoutside', handleGhostLayerDragEnd);

    transformTool.ghostLayer = ghostLayer;
    ghostLayer.transformTool = transformTool;

    transformTool.rotateLayer.addChild(ghostLayer);
}
