import useSetupPreviewContainer from './useSetupPreviewContainer';
import useShowSelectedCompPreview from './useShowSelectedCompPreview';

export default function useFoTextureCompPreview({
    foTextureComps,
    selectedSlotName,
    foSpineAsset,
}) {
    const { previewContainer, previewFrameGraphics } = useSetupPreviewContainer();

    useShowSelectedCompPreview({
        foTextureComps,
        previewContainer,
        previewFrameGraphics,
        selectedSlotName,
        foSpineAsset,
    });
}
