import { combineReducers } from '@reduxjs/toolkit';
import animationsReducer from 'src/components/views/SceneEditor/Bins/Animations/animationsSlice';
import timelineReducer from 'src/components/views/SceneEditor/Timeline/timelineSlice';
import sceneSelectorReducer from 'src/components/views/SceneSelector/sceneSelectorSlice';
import sceneEditorReducer from 'src/components/views/SceneEditor/sceneEditorSlice';
import assetSelectorReducer from 'src/components/views/AssetSelector/assetSelectorSlice';
import audioSelectorReducer from 'src/components/views/AudioSelector/audioSelectorSlice';
import textureManagerReducer from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice';
import textureEditorSliceReducer from 'src/components/views/TextureRouteManager/TextureEditor/textureEditorSlice';
import userProfileSliceReducer from 'src/components/views/UserProfile/userProfileSlice';
import appReducer from 'src/components/App/appSlice';
import videoDownloadFormSliceReducer from 'src/components/views/SceneEditor/Monitor/VideoDownloadButton/VideoDownloadForm/videoDownloadFormSlice';
import transcriptWritingFormSlice from 'src/components/views/SceneEditor/Timeline/TrackControllers/AudioTrackController/AudioTrackControllerButtonWithMenu/TranscriptWritingForm/transcriptWritingFormSlice';

export default combineReducers({
    app: appReducer,
    animations: animationsReducer,
    assetSelector: assetSelectorReducer,
    audioSelector: audioSelectorReducer,
    sceneEditor: sceneEditorReducer,
    sceneSelector: sceneSelectorReducer,
    timeline: timelineReducer,
    // todo - probably will need to rename this assetEditor
    textureManager: textureManagerReducer,
    textureEditor: textureEditorSliceReducer,
    userProfile: userProfileSliceReducer,
    videoDownloadForm: videoDownloadFormSliceReducer,
    transcriptWritingForm: transcriptWritingFormSlice,
});
