import { handleGhostLayerDragStart } from 'src/pixi/tools/TransformTool/makeGhostLayer/ghostLayerDragHandlers';
import {
    TRANSFORM_TOOL,
    MASK_TOOL,
} from 'src/components/views/TextureRouteManager/TextureEditor/constants/toolTypes';
import { getTextureTools } from 'src/components/views/TextureRouteManager/TextureEditor/pixiAssets/textureTools';

export default function makeToolTargetSelectable({ toolTarget }) {
    toolTarget.content.interactive = true;
    toolTarget.content.buttonMode = true;
    toolTarget.content.on('pointerdown', function _handleApplyTransformTool(event) {
        const { transformTool, maskTool, selectedToolType } = getTextureTools();

        if (selectedToolType === TRANSFORM_TOOL) {
            transformTool.applyTo(toolTarget);
            handleGhostLayerDragStart.call(transformTool.ghostLayer, event);
        } else if (selectedToolType === MASK_TOOL) {
            maskTool.applyTo(toolTarget);
        }
    });
}
