import makeFoTextureCompId from 'src/utils/businessLogic/foTextureComp/makeFoTextureCompId';

export default function makeFoTextureComp({
    id,
    name,
    foTextures = [],
    bakedUrl,
    linkedFoSpineAssetIds = [],
} = {}) {
    const foTextureComp = {};

    foTextureComp.name = name || '';
    foTextureComp.id = id || makeFoTextureCompId();
    foTextureComp.bakedUrl = bakedUrl || '';
    foTextureComp.foTextures = foTextures;
    foTextureComp.linkedFoSpineAssetIds = linkedFoSpineAssetIds;

    return foTextureComp;
}
