import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';

import DeleteButton from './DeleteButton';

import styles from './styles.module.scss';

export default function Buttons(props) {
    const { className, foSpineAssetId, toggleRenameMode, isEditingName, ...attributes } = props;

    return (
        <div className={classnames(styles.Buttons, className)} {...attributes}>
            <DeleteButton foSpineAssetId={foSpineAssetId} />
            <ToolButton onClick={toggleRenameMode} isActive={isEditingName}>
                rename
            </ToolButton>
        </div>
    );
}

Buttons.propTypes = {
    className: PropTypes.string,
    foSpineAssetId: PropTypes.string.isRequired,
    toggleRenameMode: PropTypes.func.isRequired,
    isEditingName: PropTypes.bool.isRequired,
};

Buttons.defaultProps = {
    className: '',
};
