import { useState, useCallback } from 'react';

import { getUsersEndpoint } from 'src/utils/backendUtils/apiEndpoints';

export default function useSignupFormSubmit({ email, password, username, setUser }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSignupFormSubmit = useCallback(
        async function _handleSignupFormSubmit(event) {
            event.preventDefault();
            setIsSubmitting(true);

            try {
                const usersEndpoint = getUsersEndpoint();

                const response = await fetch(usersEndpoint, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        username: username.trim(),
                        email: email.trim(),
                        password: password.trim(),
                    }),
                });

                if (response.ok) {
                    const userData = await response.json();
                    const token = response.headers.get('x-auth');
                    localStorage.setItem('token', token);
                    setUser(userData);
                } else {
                    const errorData = await response.json();

                    const formErrors = {};
                    const otherErrors = [];
                    errorData.errors.forEach((error) => {
                        if (error.field) {
                            formErrors[error.field] = error;
                        } else {
                            otherErrors.push(error);
                        }
                    });

                    if (Object.keys(formErrors).length) {
                        setErrors(formErrors);
                    }
                    if (otherErrors.length) {
                        const errorString = otherErrors.reduce((errorString, currentError) => {
                            const newErrorString = `${errorString}\n ${currentError.message}`;
                            return newErrorString;
                        }, '');
                        throw new Error(errorString);
                    }
                }
            } catch (err) {
                console.log(err);
            }
            setIsSubmitting(false);
        },
        [setIsSubmitting, email, password, username, setUser, setErrors],
    );

    return { isSubmitting, handleSignupFormSubmit, errors };
}
