import loadFoTextureComp from 'src/utils/businessLogic/foTextureComp/loadFoTextureComp';
import fitDisplayObjectIntoBounds from 'src/pixi/utils/fitDisplayObjectIntoBounds';
import pixiStore from 'src/pixi/pixiStore';

export default async function showSelectedDisplayObject({
    previewContainer,
    previewFrameGraphics,
    selectedFoTextureComp,
    displayObjMap,
}) {
    let foTextureCompDisplayObject = displayObjMap[selectedFoTextureComp.id];

    if (!foTextureCompDisplayObject) {
        const app = pixiStore.pixiAppManager.getPixiApp();
        foTextureCompDisplayObject = await loadFoTextureComp({
            loader: app.loader,
            foTextureComp: selectedFoTextureComp,
        });
        foTextureCompDisplayObject.interactive = true;
    }

    const bounds = previewContainer.getBounds();

    displayObjMap[selectedFoTextureComp.id] = foTextureCompDisplayObject;
    previewContainer.removeChildren();
    previewContainer.addChild(previewFrameGraphics);
    previewContainer.addChild(foTextureCompDisplayObject);

    fitDisplayObjectIntoBounds({
        bounds,
        displayObject: foTextureCompDisplayObject,
        paddingPercent: 0.1,
    });
}
