import React from 'react';
import ReactDOM from 'react-dom';

import App from 'src/components/App';
import './styles.scss';

window.AudioContext = window.AudioContext || window.webkitAudioContext;

const rootNode = document.getElementById('root');

ReactDOM.render(<App />, rootNode);
