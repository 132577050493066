import observablePointToJSON from 'src/pixi/utils/observablePointToJSON';

export default function maskDataToJSON(maskData) {
    const pointsJSON = maskData.points.map((point) => {
        const { main, tangent1, tangent2 } = point;

        const pointJSON = {
            ...point,
            main: observablePointToJSON(main),
        };
        if (tangent1) {
            pointJSON.tangent1 = observablePointToJSON(tangent1);
        }
        if (tangent2) {
            pointJSON.tangent2 = observablePointToJSON(tangent2);
        }

        return pointJSON;
    });

    const maskDataJSON = { ...maskData, points: pointsJSON };

    return maskDataJSON;
}
