import { useCallback } from 'react';

import getActiveTextureTool from 'src/components/views/TextureRouteManager/TextureEditor/utils/getActiveTextureTool';
import pixiStore from 'src/pixi/pixiStore';
import getChildById from 'src/pixi/utils/getChildById';

export default function useHandleFoTextureSelectionChange() {
    return useCallback(function _handleFoTextureSelectionChange({ foTextureId }) {
        const activeTool = getActiveTextureTool();

        if (foTextureId) {
            const app = pixiStore.pixiAppManager.getPixiApp();
            const toolTarget = getChildById({
                parent: app.stage,
                id: foTextureId,
            });

            activeTool.applyTo(toolTarget);
        } else {
            activeTool.clear();
        }
    }, []);
}
