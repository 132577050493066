import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import formatTimeToSeconds from 'src/utils/stringUtils/formatTimeToSeconds';

import styles from './styles.module.scss';

export default function TimeDisplay(props) {
    const { className, time, ...attributes } = props;

    const formattedTime = formatTimeToSeconds({ time, accuracy: 2 });
    const width = formattedTime.length * 9 + 8;

    return (
        <div
            className={classnames(styles.TimeDisplay, className)}
            style={{ width }}
            {...attributes}
        >
            <div className={styles.TimeDisplay__value}>{formattedTime}</div>
            <div className={styles.TimeDisplay__unit}>ss</div>
        </div>
    );
}

TimeDisplay.propTypes = {
    className: PropTypes.string,
    time: PropTypes.number.isRequired,
};

TimeDisplay.defaultProps = {
    className: '',
};
