import { useCallback } from 'react';

export default function useSelectSlotByName({ setSelectedSlotName }) {
    const selectSlotByName = useCallback(
        function _selectSlot({ slotName }) {
            setSelectedSlotName(slotName);
        },
        [setSelectedSlotName],
    );

    return { selectSlotByName };
}
