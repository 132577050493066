import { useCallback } from 'react';

import calcDeleteTextureModificationAtomsByCompId from 'src/utils/businessLogic/foSpineAsset/calcDeleteTextureModificationAtomsByCompId';

export default function useHandleDeleteFoTextureComp({
    removeFoTextureCompFromStore,
    foTextureCompId,
    foSpineAssets,
    setFoSpineAssetTextureModifications,
    linkedFoSpineAssetIds,
}) {
    return useCallback(
        function _handleDeleteFoTextureComp() {
            linkedFoSpineAssetIds.forEach((foSpineAssetId) => {
                const foSpineAsset = foSpineAssets.byId[foSpineAssetId];
                const filteredTextureModifications = calcDeleteTextureModificationAtomsByCompId({
                    foSpineAsset,
                    foTextureCompId,
                });
                setFoSpineAssetTextureModifications({
                    textureModifications: filteredTextureModifications,
                    foSpineAssetId: foSpineAsset.id,
                });
            });

            removeFoTextureCompFromStore(foTextureCompId);
        },
        [
            foTextureCompId,
            removeFoTextureCompFromStore,
            foSpineAssets,
            setFoSpineAssetTextureModifications,
            linkedFoSpineAssetIds,
        ],
    );
}
