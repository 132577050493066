import { CONTENT } from 'src/components/views/SceneEditor/Timeline/constants/trimModes';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';

import getClipGroupCutoffPositions from './getClipGroupCutoffPositions';

export default function calcUngrouppedClip({ parentClipGroup, clip, timeWidth }) {
    if (!parentClipGroup) {
        return clip;
    }

    const { cutoffStartPosition, cutoffEndPosition } = getClipGroupCutoffPositions({
        clipGroup: parentClipGroup,
        timeWidth,
    });

    const clipEndPosition = getClipEnd({
        clip,
        timeWidth,
    });

    const isClipOutsideCutoffRange =
        clip.position >= cutoffEndPosition || clipEndPosition <= cutoffStartPosition;
    if (isClipOutsideCutoffRange) {
        return null;
    }

    // build clip position on Timeline
    const parentClipGroupUntrimmedPosition = parentClipGroup.position - cutoffStartPosition;
    const clipCutoffPosition = Math.max(clip.position, cutoffStartPosition);
    const clipPositionOnTimeline = clipCutoffPosition + parentClipGroupUntrimmedPosition;

    // build trimmed clip data
    const headTrimAmount = Math.max(cutoffStartPosition - clip.position, 0);
    const tailTrimAmount = Math.min(cutoffEndPosition - clipEndPosition, 0);
    const trimmedClipData = trimClipData({
        headTrimAmount,
        tailTrimAmount,
        clipData: clip.data,
        trimMode: CONTENT,
        timeWidth,
    });

    // assemble changes
    const clipOnTimeline = {
        ...clip,
        position: clipPositionOnTimeline,
        data: trimmedClipData,
    };

    return clipOnTimeline;
}
