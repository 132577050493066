import loadFoTextureComp from 'src/utils/businessLogic/foTextureComp/loadFoTextureComp';

export default async function loadFoTextureComps({ loader, foTextureComps }) {
    const foTextureCompsLoads = foTextureComps.allIds.map((foTextureCompId) => {
        const foTextureComp = foTextureComps.byId[foTextureCompId];
        const compDisplayObject = loadFoTextureComp({ loader, foTextureComp });

        return compDisplayObject;
    });

    return Promise.all(foTextureCompsLoads);
}
