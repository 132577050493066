import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';

import Line from 'src/components/shared/Line';
import calcZoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcZoomedValue';

import styles from './styles.module.scss';

function renderDividers(totalWidth, distance) {
    const dividers = [];
    let nextDividerPosition = 0;
    for (let index = 0; nextDividerPosition <= totalWidth; index++) {
        const divider = (
            <Line
                isVertical
                style={{ position: 'absolute', top: 0, left: nextDividerPosition }}
                key={index}
            />
        );
        dividers.push(divider);
        nextDividerPosition += distance;
    }
    return dividers;
}

export default function SnapGuideOverlay(props) {
    const { className, snapDistance, zoomLevel, ...otherProps } = props;
    const zoomedSnapDistance = calcZoomedValue({ value: snapDistance, zoomLevel });

    return (
        <div className={classnames(styles.SnapGuideOverlay, className)} {...otherProps}>
            <AutoSizer>
                {({ width }) => {
                    return renderDividers(width, zoomedSnapDistance);
                }}
            </AutoSizer>
        </div>
    );
}

SnapGuideOverlay.propTypes = {
    className: PropTypes.string,
    snapDistance: PropTypes.number.isRequired,
};

SnapGuideOverlay.defaultProps = {
    className: '',
};
