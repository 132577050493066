// todo - remove when the tool is completed.
// use an example like this in readme
const mockPointsData = [
    {
        main: { x: 3.703978164027383, y: 38.345923007119445 },
        isTangentsLinked: true,
        id: '1',
        tangent1: { x: -4.675979382109084, y: 56.166045297754984 },
        tangent2: { x: 13.42713923038136, y: 18.66302437045974 },
    },
    {
        id: 'maskToolPoint_kg92l0kj',
        isTangentsLinked: true,
        main: { x: 10.660851716589079, y: 88.35320106323135 },
        tangent1: { x: 11.711630946476696, y: 98.1747393622208 },
        tangent2: { x: 9.401023187085496, y: 76.57769789306985 },
    },
    {
        id: 'maskToolPoint_9',
        isTangentsLinked: true,
        main: { x: 21.85854289546843, y: 122.20699497879332 },
        tangent1: { x: 27.200080568714654, y: 127.02053798643593 },
        tangent2: { x: 16.53390168753494, y: 117.40867827253336 },
    },
    {
        main: { x: 42.40494299132916, y: 143.9023934365587 },
        isTangentsLinked: true,
        id: '2',
        tangent1: { x: 48.55932174526803, y: 148.34589550258008 },
        tangent2: { x: 32.550686579991016, y: 136.78755529065128 },
    },
    {
        id: 'maskToolPoint_10',
        isTangentsLinked: true,
        main: { x: 68.14222239221272, y: 144.06433018514215 },
        tangent1: { x: 78.03233363450728, y: 140.33035024559595 },
        tangent2: { x: 57.89532928455386, y: 147.93301197944285 },
    },
    {
        id: 'maskToolPoint_8',
        isTangentsLinked: true,
        main: { x: 86.3976308349564, y: 130.06680105170392 },
        tangent1: { x: 93.79339001464376, y: 125.199523438781 },
        tangent2: { x: 78.0041450432639, y: 135.59069988287408 },
    },
    {
        main: { x: 104.8240851313891, y: 99.72521159898926 },
        isTangentsLinked: false,
        id: '3',
        tangent1: { x: 113.26405315325698, y: 93.47199682731386 },
        tangent2: { x: 99.20858695032081, y: 117.73910171435344 },
    },
    {
        id: 'maskToolPoint_12',
        isTangentsLinked: true,
        main: { x: 116.2907998859734, y: 82.53764203780713 },
        tangent1: { x: 117.99032345193605, y: 73.66053335500786 },
        tangent2: { x: 114.99698766130558, y: 89.29560171752783 },
    },
    {
        id: 'maskToolPoint_6',
        isTangentsLinked: false,
        main: { x: 111.68916822993384, y: 58.95822381907542 },
        tangent1: { x: 110.75773665345304, y: 47.979868850264786 },
        tangent2: { x: 123.08428838985222, y: 66.645191583891 },
    },
    {
        id: 'maskToolPoint_7',
        isTangentsLinked: true,
        main: { x: 102.94258074081071, y: 20.585830215284005 },
        tangent1: { x: 97.43408957397534, y: 11.918061824151687 },
        tangent2: { x: 115.39568806589287, y: 40.181150743996476 },
    },
    {
        id: 'maskToolPoint_11',
        isTangentsLinked: true,
        main: { x: 89.33862313479261, y: 11.425743526466206 },
        tangent1: { x: 81.23102740583181, y: 6.070268003618182 },
        tangent2: { x: 93.5124982398288, y: 14.182798356000905 },
    },
    {
        main: { x: 69.21909838684181, y: 3.225122568326263 },
        isTangentsLinked: true,
        id: '4',
        tangent1: { x: 56.64251628737372, y: -1.3472063084296622 },
        tangent2: { x: 74.5708503001392, y: 5.1707998295146185 },
    },
    {
        id: 'maskToolPoint_1',
        isTangentsLinked: true,
        main: { x: 35.65532643394966, y: 5.500999907524893 },
        tangent1: { x: 23.104236396666135, y: 10.325292137317106 },
        tangent2: { x: 47.83896436050057, y: 0.8179461165744284 },
    },
];

// const mockPointsData = [
//     {
//         main: { x: -36, y: -5 },
//         isTangentsLinked: true,
//         id: '1',
//         tangent1: { x: -52, y: 41 },
//         tangent2: { x: -19, y: -51 },
//     },
//     {
//         main: { x: 100, y: 150 },
//         isTangentsLinked: true,
//         id: '2',
//         tangent1: { x: 146, y: 167 },
//         tangent2: { x: 53, y: 132 },
//     },
//     {
//         main: { x: 240, y: 100 },
//         isTangentsLinked: true,
//         id: '3',
//         tangent1: { x: 258, y: 53 },
//         tangent2: { x: 221, y: 146 },
//     },
//     {
//         main: { x: 147, y: -64 },
//         isTangentsLinked: true,
//         id: '4',
//         tangent1: { x: 101, y: -84 },
//         tangent2: { x: 194, y: -45 },
//     },
// ];

// const mockPointsData = [
//     {
//         main: { x: 0, y: 0 },
//         tangent1: { x: 20, y: 50 },
//         // tangent2: { x: 0, y: 0 },
//         isTangentsLinked: false,
//         id: '1',
//     },
//     {
//         main: { x: 100, y: 150 },
//         // tangent1: { x: 100, y: 200 },
//         // tangent2: { x: 0, y: 0 },
//         isTangentsLinked: false,
//         id: '2',
//     },
//     {
//         main: { x: 240, y: 100 },
//         // tangent1: { x: 0, y: 0 },
//         tangent2: { x: 200, y: 200 },
//         isTangentsLinked: false,
//         id: '3',
//     },
//     {
//         main: { x: 140, y: 50 },
//         tangent1: { x: 140, y: 30 },
//         tangent2: { x: 160, y: 75 },
//         id: '4',
//     },
// ];

export default mockPointsData;
