import PropTypes from 'prop-types';

const locationLikeShape = {
    pathname: PropTypes.string.isRequired,
};
export const locationLike = PropTypes.shape(locationLikeShape);
locationLikeShape.state = PropTypes.shape({ fromLocation: locationLike });

export default PropTypes.shape({
    location: locationLike.isRequired,
    navigate: PropTypes.func.isRequired,
    params: PropTypes.objectOf(PropTypes.string).isRequired,
});
