import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';

import './styles.scss';

export default function CloseToolButton(props) {
    const { className, ...attributes } = props;

    return (
        <ToolButton className={classnames('CloseToolButton', className)} {...attributes}>
            &#10005;
        </ToolButton>
    );
}

CloseToolButton.propTypes = {
    ...ToolButton.propTypes,
    className: PropTypes.string,
};

CloseToolButton.defaultProps = {
    ...ToolButton.defaultProps,
    className: '',
};
