import { Graphics, Container } from 'pixi.js';
import {
    handleMaskOutlineDragStart,
    handleMaskOutlineDragMove,
    handleMaskOutlineDragEnd,
} from './maskOutlineDragHandlers';

export default function makeMaskOutline({ maskTool }) {
    const maskOutline = new Container();
    const content = new Graphics();
    const interactionArea = new Graphics();

    maskOutline.maskTool = maskTool;
    maskOutline.content = content;
    maskOutline.interactionArea = interactionArea;
    maskOutline.addChild(content);
    maskOutline.addChild(interactionArea);

    maskOutline.interactive = true;
    maskOutline.buttonMode = true;

    maskOutline
        .on('pointerdown', handleMaskOutlineDragStart)
        .on('pointermove', handleMaskOutlineDragMove)
        .on('pointerup', handleMaskOutlineDragEnd)
        .on('pointerupoutside', handleMaskOutlineDragEnd);

    return maskOutline;
}
