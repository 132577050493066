import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

export default function HiddenTrackLayer(props) {
    const { className, ...attributes } = props;

    return (
        <div className={classnames(styles.HiddenTrackLayer, className)} {...attributes}>
            <div className={styles.HiddenTrackLayer__label}>
                This layer content is currently hidden
            </div>
        </div>
    );
}

HiddenTrackLayer.propTypes = {
    className: PropTypes.string,
};

HiddenTrackLayer.defaultProps = {
    className: '',
};
