import {
    calcAngleRadBetween2PointsAndAxisX,
    calculateRotatedPosition,
} from 'src/utils/mathUtils/geoMathUtils';
import getOtherTangentName from 'src/pixi/tools/MaskTool/utils/getOtherTangentName';

export default function adjustOtherTangent({ tangentNewPosition, point, tangentName }) {
    const tangent = point[tangentName];
    const tangentCurrentAngle = calcAngleRadBetween2PointsAndAxisX({
        point1: tangent,
        point2: point.main,
    });
    const tangentNewAngle = calcAngleRadBetween2PointsAndAxisX({
        point1: tangentNewPosition,
        point2: point.main,
    });
    const angleChange = tangentNewAngle - tangentCurrentAngle;

    if (angleChange === 0) {
        return;
    }

    const otherTangentName = getOtherTangentName(tangentName);
    const otherTangent = point[otherTangentName];

    if (!otherTangent || !point.isTangentsLinked) {
        return;
    }
    const otherTangentNewPosition = calculateRotatedPosition({
        point: otherTangent,
        anchor: point.main,
        angleChangeRad: angleChange,
    });

    otherTangent.x = otherTangentNewPosition.x;
    otherTangent.y = otherTangentNewPosition.y;
}
