import getSlotContainerBySlotName from 'src/utils/businessLogic/foSpineAsset/getSlotContainerBySlotName';
import ColorOverlayFilterWithAlpha from 'src/pixi/filters/ColorOverlayFilterWithAlpha';

export default function greyOutSelectedSlot({ spineAssetInstance, selectedSlotName }) {
    const slotContainer = getSlotContainerBySlotName({
        spineAssetInstance,
        slotName: selectedSlotName,
    });

    const spineDisplayObject = slotContainer.children[0];

    const overlayFilter = new ColorOverlayFilterWithAlpha([0.6, 0.6, 0.6, 0.6]);
    const colorMatrix = new PIXI.filters.ColorMatrixFilter();
    colorMatrix.desaturate();
    spineDisplayObject.filters = [colorMatrix, overlayFilter];
}
