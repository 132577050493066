import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

export default function getTotalGapBetweenIndecies({
    sortedClips,
    startIndex: startIndexReceived,
    endIndex: endIndexReceived,
    timeWidth,
}) {
    let endIndex = endIndexReceived;
    if (endIndexReceived === undefined || endIndexReceived >= sortedClips.length) {
        endIndex = sortedClips.length - 1;
    }
    let startIndex = startIndexReceived;
    if (startIndexReceived === undefined || startIndexReceived < 0) {
        startIndex = 0;
    }

    let totalGaps = startIndexReceived < 0 ? sortedClips[0].position : 0;

    for (let i = startIndex; i < endIndex; i++) {
        const currentClip = sortedClips[i];
        const nextClip = sortedClips[i + 1];

        const currentClipEnd = getClipEnd({ clip: currentClip, timeWidth });
        const nextGap = nextClip.position - currentClipEnd;

        totalGaps += nextGap;
    }

    return totalGaps;
}
