import { BLINKING_MIX_DURATION } from 'src/components/views/SceneEditor/Timeline/constants/defaultValues';

import makeExpressionLayersForBodyParts from './makeExpressionLayersForBodyParts';
import generateBlikingClipsById from './generateBlikingClipsById';
import filterEmptyTrackLayers from './filterEmptyTrackLayers';
import mergeExpressionLayersIntoTrack from './mergeExpressionLayersIntoTrack';

export default function generateTrackLayersFromExpressions({
    expressionTimelineKeys,
    foSpineAssetId,
    timeWidth,
}) {
    const allLayerIds = [];
    const layersById = {};
    if (!expressionTimelineKeys.length) {
        return { layersById, allLayerIds };
    }

    expressionTimelineKeys.forEach((expressionTimelineKey) => {
        const expressionLayers = makeExpressionLayersForBodyParts({
            expressionTimelineKey,
            timeWidth,
            foSpineAssetId,
        });

        const blinkingClipsById = generateBlikingClipsById({
            expressionTimelineKey,
            foSpineAssetId,
            timeWidth,
        });

        expressionLayers.push({
            clipsById: blinkingClipsById,
            mixDuration: BLINKING_MIX_DURATION,
        });

        mergeExpressionLayersIntoTrack({
            expressionLayers,
            allLayerIds,
            layersById,
        });
    });

    const {
        layersById: filteredLayersById,
        allLayerIds: filteredAllLayerIds,
    } = filterEmptyTrackLayers({ layersById, allLayerIds });

    return { layersById: filteredLayersById, allLayerIds: filteredAllLayerIds };
}
