import clipTypes from 'src/components/constants/clipTypes';
import traverseClipDepthFirst from 'src/utils/businessLogic/clipUtils/traverseClipDepthFirst';

export default function removeUngraphemeClips(clipsById) {
    const filteredClipsById = {};

    const fakeRootClip = {
        position: 0,
        isRoot: true,
        data: {
            clipsById,
            type: clipTypes.CLIP_GROUP,
            headTrimPercent: 0,
            tailTrimPercent: 0,
        },
    };

    traverseClipDepthFirst({
        clip: fakeRootClip,
        clipCallback({ clip, context }) {
            if (clip.data.graphemeChar) {
                context.filteredClipsById[clip.clipId] = clip;
            }
        },
        clipGroupCallback({ clipGroup, context }) {
            if (clipGroup.isRoot) {
                return context;
            }

            const nestedFilteredClipsById = {};
            context.filteredClipsById[clipGroup.clipId] = {
                ...clipGroup,
                data: {
                    ...clipGroup.data,
                    clipsById: nestedFilteredClipsById,
                },
            };

            const updatedContext = {
                ...context,
                filteredClipsById: nestedFilteredClipsById,
            };

            return updatedContext;
        },
        clipGroupPostTraversalCallback({ clipGroup, context }) {
            const filteredClip = context.filteredClipsById[clipGroup.clipId];
            if (filteredClip && !Object.keys(filteredClip.data.clipsById).length) {
                delete context.filteredClipsById[clipGroup.clipId];
            }
        },
        context: {
            filteredClipsById,
        },
    });

    return filteredClipsById;
}
