import { useRef, useEffect } from 'react';

import useScrollTimelineToTimeIndicatorAfterZooming from './useScrollTimelineToTimeIndicatorAfterZooming';

export default function useKeepTimeIndicatorInPlaceAfterZoom({
    zoomLevel,
    horizontalScrollElemRef,
    timeWidth,
}) {
    const {
        scrollTimelineToTimeIndicatorAfterZooming,
    } = useScrollTimelineToTimeIndicatorAfterZooming({
        horizontalScrollElemRef,
        zoomLevel,
        timeWidth,
    });

    const previousZoomLevelRef = useRef(zoomLevel);
    useEffect(
        function _scrollTimelineIfNeeded() {
            if (previousZoomLevelRef.current !== zoomLevel) {
                previousZoomLevelRef.current = zoomLevel;
                scrollTimelineToTimeIndicatorAfterZooming();
            }
        },
        [zoomLevel, scrollTimelineToTimeIndicatorAfterZooming],
    );
}
