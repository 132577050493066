/**
 * @time Number
 * assumes that time is in seconds
 * @accuracy Number
 */
export default function formatTimeToSeconds({ time, accuracy = 3 }) {
    const convertedTime = Number(time);
    const roundedTime = Math.round(convertedTime * 10 ** accuracy) / 10 ** accuracy;
    const timeStr = String(roundedTime);

    let regexStr = '(?<int>\\d+).?';
    for (let i = 0; i < accuracy; i++) {
        const groupName = `dec${i + 1}`;
        regexStr = `${regexStr}(?<${groupName}>\\d)?`;
    }
    const regex = new RegExp(regexStr);
    const match = regex.exec(timeStr);

    let timeStrFormatted = '';
    if (match) {
        const { int } = match.groups;
        timeStrFormatted = accuracy ? `${int}.` : `${int}`;
        for (let i = 0; i < accuracy; i++) {
            const groupName = `dec${i + 1}`;
            const groupValue = match.groups[groupName];
            timeStrFormatted = `${timeStrFormatted}${groupValue || 0}`;
        }
    }

    return timeStrFormatted;
}
