import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as routes from 'src/components/constants/routes';
import withRouter from 'src/reduxStore/utils/withRouter';
import routerLike from 'src/components/shared/propTypes/routerLike';

function RequireAuth({ isAuthenticated, fallbackPath, router: { location }, children }) {
    if (!isAuthenticated) {
        return <Navigate to={fallbackPath} state={{ fromLocation: location }} replace />;
    }

    return children;
}

RequireAuth.propTypes = {
    router: routerLike.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    fallbackPath: PropTypes.string,
};

RequireAuth.defaultProps = {
    fallbackPath: `/${routes.SIGN_IN}`,
};

function mapState(state) {
    const { userData } = state.userProfile;

    return { isAuthenticated: !!userData };
}

export default withRouter(connect(mapState)(RequireAuth));
