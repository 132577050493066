import calcTotalRank from './calcTotalRank';
import buildProbabilityRanges from './buildProbabilityRanges';

export default function selectRandomlyByLikelihood({ collection, rankPropName = 'rank' }) {
    if (!Object.keys(collection).length) {
        return null;
    }

    const totalRank = calcTotalRank({ collection, rankPropName });
    const probabilityRanges = buildProbabilityRanges({ collection, rankPropName });

    const randomNumber = Math.random() * totalRank;
    const probabilityRange = probabilityRanges.find((probabilityRange) => {
        return probabilityRange.min <= randomNumber && randomNumber < probabilityRange.max;
    });

    return probabilityRange.itemKey;
}
