import { useCallback } from 'react';
import { cloneDeep } from 'lodash';

import cloneFoTextureComp from 'src/utils/businessLogic/foTextureComp/cloneFoTextureComp';
import getTextureModificationAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getTextureModificationAssignedToSlot';
import putTextureModificationAtomOnFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/putTextureModificationAtomOnFoSpineAsset';

export default function useHandleCloneButtonClick({
    foTextureComp,
    selectedSlotName,
    foSpineAsset,
    setFoSpineAssetTextureModifications,
    setFoSpineAssetLinkOnFoTextureComp,
    setFoTextureComp,
}) {
    return useCallback(
        function _handleCloneButtonClick() {
            const clonedFoTextureComp = cloneFoTextureComp(foTextureComp);
            setFoTextureComp(clonedFoTextureComp);

            const textureModification = getTextureModificationAssignedToSlot({
                foSpineAsset,
                slotName: selectedSlotName,
            });
            const textureModificationAtom = textureModification.atomsByCompId[foTextureComp.id];
            const newAtom = cloneDeep(textureModificationAtom);
            newAtom.foTextureCompId = clonedFoTextureComp.id;

            putTextureModificationAtomOnFoSpineAsset({
                foSpineAsset,
                setFoSpineAssetTextureModifications,
                textureModificationAtom: newAtom,
                setFoSpineAssetLinkOnFoTextureComp,
            });
        },
        [
            foTextureComp,
            selectedSlotName,
            foSpineAsset,
            setFoSpineAssetTextureModifications,
            setFoSpineAssetLinkOnFoTextureComp,
            setFoTextureComp,
        ],
    );
}
