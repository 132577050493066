export default function getGrabPositionOffsetFromItemsLeftEdge(monitor) {
    const initialClientOffset = monitor.getInitialClientOffset();
    const initialSourceClientOffset = monitor.getInitialSourceClientOffset();

    if (!initialClientOffset || !initialSourceClientOffset) {
        return null;
    }

    const getGrabPositionOffsetFromItemsLeftEdge =
        initialClientOffset.x - initialSourceClientOffset.x;

    return getGrabPositionOffsetFromItemsLeftEdge;
}
