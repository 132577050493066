import makeFoTextureId from 'src/utils/businessLogic/foTexture/makeFoTextureId';
import { FO_TEXTURE_BASE_Z_INDEX } from 'src/components/views/TextureRouteManager/TextureEditor/constants/foTexture';

export default function makeFoTexture({
    id,
    name,
    url,
    zIndex = FO_TEXTURE_BASE_Z_INDEX,
    transform,
    maskData,
} = {}) {
    if (!url) {
        throw new Error('url is necessary to make a foTexture');
    }

    const foTexture = {};

    foTexture.id = id || makeFoTextureId();
    foTexture.name = name || '';
    foTexture.url = url;
    foTexture.zIndex = zIndex;

    if (transform) {
        foTexture.transform = transform;
    }

    if (maskData) {
        foTexture.maskData = maskData;
    }

    return foTexture;
}
