export default function getGlobalScale(displayObject) {
    let current = displayObject;
    let {
        parent,
        scale: { x, y },
    } = displayObject;

    while (parent) {
        current = parent;
        x *= current.scale.x;
        y *= current.scale.y;
        parent = current.parent;
    }

    return { x, y };
}
