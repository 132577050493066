import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';

export default function getPositionRelativeToLeftNeighbout({ clip, leftNeighbour, timeWidth }) {
    const leftNeighbourWidth = getClipWidth({ clipData: leftNeighbour.data, timeWidth });
    const leftNeighbourMidPoint = leftNeighbour.position + leftNeighbourWidth / 2;
    const leftNeighbourEnd = getClipEnd({ clip: leftNeighbour, timeWidth });

    const isInLeftNeighboursFirstHalf =
        clip.position < leftNeighbourMidPoint && clip.position > leftNeighbour.position;
    const isInLeftNeighboursSecondHalf =
        clip.position > leftNeighbourMidPoint && clip.position < leftNeighbourEnd;

    return { isInLeftNeighboursFirstHalf, isInLeftNeighboursSecondHalf };
}
