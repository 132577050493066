import buildClipForGraphemeChar from 'src/utils/businessLogic/clipUtils/convertFoTranscriptWordsToClips/buildClipForGraphemeChar';
import { NEUTRAL_SPEECH_DURATION_THRESHOLD } from 'src/components/constants/audio';

export default function fillSilenceWithClip({
    nextFoTranscriptWord,
    currClipEndTime,
    timeWidth,
    foSpineAssetId,
    clipsById,
    graphemeToAnimationMap,
}) {
    const nextWordStartTime = nextFoTranscriptWord.startTime;
    const gapDuration = nextWordStartTime - currClipEndTime;
    if (gapDuration > NEUTRAL_SPEECH_DURATION_THRESHOLD) {
        const clip = buildClipForGraphemeChar({
            graphemeChar: null,
            duration: gapDuration,
            position: currClipEndTime * timeWidth,
            foSpineAssetId,
            graphemeToAnimationMap,
        });
        clipsById[clip.clipId] = clip;
    }
}
