import { useCallback } from 'react';

export default function useHandleNameInputChange({ foSpineAsset, setFoSpineAsset }) {
    return useCallback(
        function _handleNameInputChange(event) {
            const name = event.target.value;
            const newForSpineAsset = { ...foSpineAsset, name };
            setFoSpineAsset(newForSpineAsset);
        },
        [foSpineAsset, setFoSpineAsset],
    );
}
