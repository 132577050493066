import { Container } from 'pixi.js';

export default class PixiAppManager {
    app = null;

    zoomContainer = null;

    getPixiApp() {
        return this.app;
    }

    setPixiApp(pixiApp) {
        this.app = pixiApp;
        const zoomContainer = pixiApp.stage.zoomContainer || pixiApp.stage;
        this.setZoomContainer(zoomContainer);
    }

    setZoomContainer(zoomContainer) {
        this.zoomContainer = zoomContainer;
        this.app.stage.zoomContainer = zoomContainer;
    }

    getZoomContainer() {
        return this.zoomContainer;
    }

    setPixiAppToDefault() {
        this.setPixiApp(null);
    }

    clearPixiApp() {
        const app = this.getPixiApp();
        this.setZoomContainer(null);

        if (app) {
            delete app.stage.zoomContainer;
            app.stage.destroy({ children: true });
            app.stage = new Container();
        }
    }
}
