export default function getFirstSequentialClipsFromSortedSelection({
    allSortedClips,
    sortedSelectedClips,
}) {
    const matchIndex = allSortedClips.findIndex(function _checkSelectedClipsForMatch(clip) {
        return sortedSelectedClips.includes(clip);
    });

    const isAnySelectedClipAmongAllClips = matchIndex !== -1;

    const firstSequentialSelectedClips = [];
    if (isAnySelectedClipAmongAllClips) {
        sortedSelectedClips.every((selectedClip, index) => {
            const isSortedSelectedClipMatchSortedTrackClip =
                selectedClip === allSortedClips[index + matchIndex];

            if (isSortedSelectedClipMatchSortedTrackClip) {
                firstSequentialSelectedClips.push(selectedClip);
            }
            return isSortedSelectedClipMatchSortedTrackClip;
        });
    }
    return firstSequentialSelectedClips;
}
