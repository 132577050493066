export default function getSingleClipDuration({ clipData, isGetUntrimmed = false }) {
    const { animationStart, animationEnd, originalDuration, timeScale = 1 } = clipData;

    let clipDuration = animationEnd - animationStart;
    if (isGetUntrimmed) {
        clipDuration = originalDuration;
    }

    const clipDurationOnTimeline = clipDuration / timeScale;

    return clipDurationOnTimeline;
}
