import getFoGraphemeCharEndTime from 'src/utils/businessLogic/transcript/getFoGraphemeCharEndTime';

export default function getFoTranscriptWordEndTime(foTranscriptWord) {
    const { startTime, foGraphemeChars } = foTranscriptWord;

    const lastFoGraphemeChar = foGraphemeChars[foGraphemeChars.length - 1];

    const endTime = startTime + getFoGraphemeCharEndTime(lastFoGraphemeChar);

    return endTime;
}
