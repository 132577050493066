export default function getIfSelectedClipsAreOnSingleLayer({ clipSelectionByTrackId }) {
    let selectedTrackId = '';
    let selectedLayerId = '';
    let isSelectedClipsOnSingleLayer = false;

    const isSelectedClipsOnSingleTrack = Object.keys(clipSelectionByTrackId).length === 1;
    if (isSelectedClipsOnSingleTrack) {
        [selectedTrackId] = Object.keys(clipSelectionByTrackId);

        const clipSelectionOnTrackByLayerId = clipSelectionByTrackId[selectedTrackId];
        isSelectedClipsOnSingleLayer = Object.keys(clipSelectionOnTrackByLayerId).length === 1;
        if (isSelectedClipsOnSingleLayer) {
            [selectedLayerId] = Object.keys(clipSelectionOnTrackByLayerId);
        }
    }

    return { isSelectedClipsOnSingleLayer, selectedTrackId, selectedLayerId };
}
