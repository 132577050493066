import { sortBy } from 'lodash';

import convertClipToExpressionTimelineKey from 'src/components/views/SceneEditor/Timeline/utils/convertClipToExpressionTimelineKey';

export default function convertClipsByIdToExpressionTimelineKeys({ clipsById, timeWidth }) {
    const sortedClips = sortBy(Object.values(clipsById), 'position');

    const expressionTimelineKeys = sortedClips.map(function _convertClipToExpressionTimelineKey(
        clip,
    ) {
        const expressionTimelineKey = convertClipToExpressionTimelineKey({ clip, timeWidth });
        return expressionTimelineKey;
    });

    return expressionTimelineKeys;
}
