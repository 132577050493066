import { ADD_POINT, MOVE_MASK_CONTROLS } from 'src/pixi/tools/MaskTool/constants/maskToolTypes';
import * as eventNames from 'src/pixi/tools/MaskTool/constants/eventNames';

import addPointToMaskBezier from './addPointToMaskBezier';

export function handleMaskOutlineDragStart(event) {
    const { maskTool } = this;

    if (maskTool.type === MOVE_MASK_CONTROLS) {
        this.data = event.data;
        this.isDragging = true;

        this.dragStartPosition = this.data.getLocalPosition(maskTool.target);
    } else if (maskTool.type === ADD_POINT) {
        addPointToMaskBezier({ maskTool, pointerEventData: event.data });
    }
}

export function handleMaskOutlineDragEnd() {
    this.isDragging = false;
    this.data = null;
    this.dragStartPosition = null;

    const { maskTool } = this;
    maskTool.runEventHandlersFor(eventNames.OUTLINE_DRAG_END);
    maskTool.runEventHandlersFor(eventNames.ANY_DRAG_END);
}

export function handleMaskOutlineDragMove() {
    if (this.isDragging) {
        const { maskTool } = this;
        const currentPointerPosition = this.data.getLocalPosition(maskTool.target);

        const diff = {
            x: currentPointerPosition.x - this.dragStartPosition.x,
            y: currentPointerPosition.y - this.dragStartPosition.y,
        };
        this.dragStartPosition = currentPointerPosition;

        const points = maskTool.getPoints();
        points.forEach((point) => {
            point.main.x += diff.x;
            point.main.y += diff.y;
            if (point.tangent1) {
                point.tangent1.x += diff.x;
                point.tangent1.y += diff.y;
            }
            if (point.tangent2) {
                point.tangent2.x += diff.x;
                point.tangent2.y += diff.y;
            }
        });
        maskTool.positionControls();
    }
}
