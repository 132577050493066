import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import TrackController from 'src/components/views/SceneEditor/Timeline/TrackControllers/TrackController';
import { trackLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';

import TrackLayerControllers from './TrackLayerControllers';
import AssetTrackControllerButtonWithMenu from './AssetTrackControllerButtonWithMenu';

import styles from './styles.module.scss';

export default function AssetTrackController(props) {
    const { className, height, track } = props;

    return (
        <TrackController
            className={classnames(styles.AssetTrackController, className)}
            style={{ height }}
        >
            <div className={styles.AssetTrackController__items}>
                <div className={styles.AssetTrackController__name}>{track.foSpineAsset.id}</div>
                <AssetTrackControllerButtonWithMenu
                    trackId={track.trackId}
                    foSpineAssetId={track.foSpineAsset.id}
                    expressionTimelineKeys={track.expressionTimelineKeys}
                />
            </div>
            <TrackLayerControllers
                className={styles.AssetTrackController__trackLayerControllers}
                layers={track.layers}
                trackId={track.trackId}
            />
        </TrackController>
    );
}

AssetTrackController.propTypes = {
    className: PropTypes.string,
    height: PropTypes.number.isRequired,
    track: trackLike.isRequired,
};

AssetTrackController.defaultProps = {
    className: '',
};
