export const MAIN_KNOB_SIZE = 10;
export const MAIN_KNOB_COLOR = 0xffffff;
export const MAIN_KNOB_OUTLINE_SIZE = 2;
export const MAIN_KNOB_OUTLINE_COLOR = 0x000000;

export const TANGENT_KNOB_SIZE = 10;
export const TANGENT_KNOB_COLOR = 0x000000;

export const TANGENT_LINE_WIDTH = MAIN_KNOB_OUTLINE_SIZE;
export const LINKED_TANGENT_LINE_COLOR = MAIN_KNOB_OUTLINE_COLOR;
export const UNLINKED_TANGENT1_LINE_COLOR = 0x00ff00;
export const UNLINKED_TANGENT2_LINE_COLOR = 0x0000ff;

export const KNOB_INTERACTION_SCALE = 1.3;
export const KNOB_DEFAULT_SCALE = 1;

export const MASK_OUTLINE_COLOR = 0xff0000;
export const MASK_OUTLINE_WIDTH = 2;

export const IS_HANDLES_LINKED_BY_DEFAULT = true;

export const DEFAULT_TANGENT_DISTANCE = 50;

export const BEZIER_APPROXIMATION_SCALE_LENGTH = 100;
