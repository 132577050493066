import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import SelectableList from 'src/components/shared/SelectableList';
import Spinner from 'src/components/shared/Spinner';
import Layover from 'src/components/shared/Layover';
import { setScenes, deleteScene } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import { scenesLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import useInitializeScenes from 'src/components/views/SceneSelector/hooks/useInitializeScenes';
import ToolContainer from 'src/components/shared/ToolContainer';
import * as routes from 'src/components/constants/routes';
import Modal from 'src/components/shared/Modal';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import PlusIcon from 'src/components/shared/iconFactory/PlusIcon';

import SceneSelectorListItem from './SceneSelectorListItem';
import SelectedLabel from './SelectedLabel';
import SceneEditorForm from './SceneEditorForm';

import styles from './styles.module.scss';

function SceneSelector(props) {
    const { className, scenes, setScenes, deleteScene } = props;

    const { isFetchingScenes } = useInitializeScenes({ setScenes });

    const navigate = useNavigate();
    const clearSceneSelection = useCallback(
        function _clearSceneSelection() {
            navigate(`/${routes.SCENES}`);
        },
        [navigate],
    );

    const selectableListRef = useRef();
    const handleSelectableListClick = useCallback(
        function _handleSelectableListClick(event) {
            const isDirectClick = event.target === selectableListRef.current;
            if (isDirectClick) {
                clearSceneSelection();
            }
        },
        [clearSceneSelection, selectableListRef],
    );

    const { sceneId: selectedSceneId } = useParams();
    const selectedScene = scenes.byId[selectedSceneId];

    let sceneName = '';
    if (selectedScene) {
        sceneName = selectedScene.name;
    } else if (selectedSceneId) {
        sceneName = 'scene not found';
    }

    const [isModalShown, setIsModalShown] = useState(false);

    const idOfSceneToEditRef = useRef('');
    const openEditSceneForm = useCallback(
        function _openEditSceneForm(sceneId = '') {
            setIsModalShown(true);
            idOfSceneToEditRef.current = sceneId;
        },
        [setIsModalShown, idOfSceneToEditRef],
    );
    const closeEditSceneForm = useCallback(
        function _closeEditSceneForm() {
            setIsModalShown(false);
            idOfSceneToEditRef.current = '';
        },
        [setIsModalShown],
    );
    const handleClickOutsideModal = useCallback(
        function _handleClickOutsideModal() {
            closeEditSceneForm();
        },
        [closeEditSceneForm],
    );

    return (
        <div className={classnames(styles.SceneSelector, className)}>
            <ToolContainer>
                <SelectedLabel label={sceneName} clearSceneSelection={clearSceneSelection} />
                <ToolButton
                    className={styles.SceneSelector__createSceneButton}
                    title='Create new scene'
                    onClick={openEditSceneForm}
                >
                    <PlusIcon /> Scene
                </ToolButton>
            </ToolContainer>
            {isFetchingScenes && (
                <Layover>
                    <Spinner />
                </Layover>
            )}
            <SelectableList onClick={handleSelectableListClick} nodeRef={selectableListRef}>
                {scenes.allIds.map((sceneId) => {
                    const { name } = scenes.byId[sceneId];
                    const isSelected = sceneId === selectedSceneId;

                    return (
                        <SceneSelectorListItem
                            key={sceneId}
                            isSelected={isSelected}
                            name={name}
                            sceneId={sceneId}
                            deleteScene={deleteScene}
                            openEditSceneForm={openEditSceneForm}
                        />
                    );
                })}
            </SelectableList>
            {isModalShown && (
                <Modal onClickOutside={handleClickOutsideModal} isLayoverVisible>
                    <SceneEditorForm
                        sceneId={idOfSceneToEditRef.current}
                        closeEditSceneForm={closeEditSceneForm}
                    />
                </Modal>
            )}
        </div>
    );
}

SceneSelector.propTypes = {
    className: PropTypes.string,
    scenes: scenesLike.isRequired,
    setScenes: PropTypes.func.isRequired,
    deleteScene: PropTypes.func.isRequired,
};

SceneSelector.defaultProps = {
    className: '',
};

function mapState(state) {
    const { scenes } = state.sceneSelector;

    return {
        scenes,
    };
}

export default connect(mapState, {
    setScenes,
    deleteScene,
})(SceneSelector);
