import fillAreaWithRepeatingClips from 'src/utils/businessLogic/clipUtils/fillAreaWithRepeatingClips';
import getAnimationOriginalDuration from 'src/spine/utils/getAnimationOriginalDuration';

export default function generateIdleClipsById({
    idleAnimationName,
    expressionTimelineKey,
    foSpineAssetId,
    timeWidth,
}) {
    const startPosition = expressionTimelineKey.start * timeWidth;
    const endPosition = expressionTimelineKey.end * timeWidth;

    const clipOriginalDuration = getAnimationOriginalDuration({
        animationId: idleAnimationName,
        foSpineAssetId,
    });

    const idleClipsById = fillAreaWithRepeatingClips({
        animationId: idleAnimationName,
        clipOriginalDuration,
        timeWidth,
        startPosition,
        endPosition,
        isTrimLastClipToFit: true,
    });

    return idleClipsById;
}
