export default function drawLines({ pointsRelToRoot, graphics }) {
    const lastPoint = pointsRelToRoot[pointsRelToRoot.length - 1];
    graphics.moveTo(lastPoint.x, lastPoint.y);
    pointsRelToRoot.forEach((globalPoint) => {
        const { x, y } = globalPoint;
        graphics.lineTo(x, y);
    });

    return graphics;
}
