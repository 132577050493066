import calcUnAssignFoTextureCompFromSlot from './calcUnAssignFoTextureCompFromSlot';

export default function unAssignFoTextureCompFromSlot({
    slotName,
    foSpineAsset,
    setFoSpineAssetTextureModifications,
}) {
    const newTextureModifications = calcUnAssignFoTextureCompFromSlot({ slotName, foSpineAsset });

    setFoSpineAssetTextureModifications({
        textureModifications: newTextureModifications,
        foSpineAssetId: foSpineAsset.id,
    });
}
