import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import ToolContainer from 'src/components/shared/ToolContainer';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import useHandleSaveFoTextureComp from 'src/components/views/TextureRouteManager/TextureEditor/PersistenceMenu/hooks/useHandleSaveFoTextureComp';
import { setFoSpineAssetTextureModifications } from 'src/components/views/AssetSelector/assetSelectorSlice';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { foTextureCompLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import {
    setFoTextureComp,
    setFoSpineAssetLinkOnFoTextureComp,
} from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice';
import getSelectedTrackFoSpineAsset from 'src/reduxStore/utils/getSelectedTrackFoSpineAsset';
import withRouter from 'src/reduxStore/utils/withRouter';

import styles from './styles.module.scss';

function PersistenceMenu(props) {
    const {
        className,
        foTextureComp,
        foSpineAsset,
        selectedSlotName,
        setFoTextureComp,
        setFoSpineAssetTextureModifications,
        setFoSpineAssetLinkOnFoTextureComp,
        ...attributes
    } = props;

    const handleSaveFoTextureComp = useHandleSaveFoTextureComp({
        foTextureComp,
        foSpineAsset,
        selectedSlotName,
        setFoTextureComp,
        setFoSpineAssetTextureModifications,
        setFoSpineAssetLinkOnFoTextureComp,
    });

    return (
        <ToolContainer className={classnames(styles.PersistenceMenu, className)} {...attributes}>
            <ToolButton onClick={handleSaveFoTextureComp} className='ToolContainer__tool'>
                Save texture
            </ToolButton>
        </ToolContainer>
    );
}

PersistenceMenu.propTypes = {
    className: PropTypes.string,
    foTextureComp: foTextureCompLike.isRequired,
    foSpineAsset: foSpineAssetLike.isRequired,
    selectedSlotName: PropTypes.string.isRequired,
    setFoTextureComp: PropTypes.func.isRequired,
    setFoSpineAssetTextureModifications: PropTypes.func.isRequired,
    setFoSpineAssetLinkOnFoTextureComp: PropTypes.func.isRequired,
};

PersistenceMenu.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { sceneId: selectedSceneId } = ownProps.router.params;

    const foSpineAsset = getSelectedTrackFoSpineAsset(state, { selectedSceneId });

    const { textureEditor } = state;

    const { selectedSlotName } = textureEditor;

    return {
        foSpineAsset,
        selectedSlotName,
    };
}

export default withRouter(
    connect(mapState, {
        setFoTextureComp,
        setFoSpineAssetTextureModifications,
        setFoSpineAssetLinkOnFoTextureComp,
    })(PersistenceMenu),
);
