import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CloseToolButton from 'src/components/shared/buttonFactory/CloseToolButton';

import styles from './styles.module.scss';

export default function SelectedLabel(props) {
    const { className, label, clearSceneSelection } = props;

    return (
        <div className={classnames(styles.SelectedLabel, className)}>
            <span className={styles.SelectedLabel__explanation}>Selected scene:</span>
            <span className={styles.SelectedLabel__name}>{label}</span>
            {label && <CloseToolButton onClick={clearSceneSelection} />}
        </div>
    );
}

SelectedLabel.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    clearSceneSelection: PropTypes.func.isRequired,
};

SelectedLabel.defaultProps = {
    className: '',
    label: '',
};
