import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';

export default function useHandleClearButtonClick({ trackId, trackLayerId, setClipsById }) {
    const { sceneId: selectedSceneId } = useParams();

    return useCallback(
        function _handleClearButtonClick() {
            setClipsById({ sceneId: selectedSceneId, trackId, trackLayerId, newClipsById: {} });
            updateTracksOnBackend({ selectedSceneId });
        },
        [trackId, trackLayerId, setClipsById, selectedSceneId],
    );
}
