import expressionsByAnimation from 'src/utils/businessLogic/expressions/constants/expressionsByAnimation';
import idleAnimationsByBodyPart from 'src/utils/businessLogic/expressions/constants/idleAnimationsByBodyPart';
import bodyParts from 'src/utils/businessLogic/expressions/constants/bodyParts';

import buildClipsByIdForExpression from './buildClipsByIdForExpression';
import generateIdleClipsById from './generateIdleClipsById';
import getAnimationsByExpressionForFoSpineAsset from './getAnimationsByExpressionForFoSpineAsset';

const bodyPartsByLayerOrder = [
    bodyParts.BODY,
    bodyParts.BODY_SHOULDERS,
    bodyParts.BODY_WINGS,
    bodyParts.ARM_RIGHT,
    bodyParts.ARM_LEFT,
    bodyParts.HAND_RIGHT,
    bodyParts.HAND_LEFT,
    bodyParts.HEAD,
    bodyParts.HEAD_HAIR,
    bodyParts.FACE,
    bodyParts.FACE_MOUTH,
    bodyParts.FACE_EYES,
    bodyParts.FACE_EYES_PUPILS,
];

export default function addExpressionLayersForBodyParts({
    expressionTimelineKey,
    timeWidth,
    foSpineAssetId,
}) {
    let bodyPartsAlreadyUsedForThisKey = new Set();
    const expressionLayers = [];

    const position = expressionTimelineKey.start * timeWidth;
    const expressionDuration = expressionTimelineKey.end - expressionTimelineKey.start;

    bodyPartsByLayerOrder.forEach(function _addExpressionLayersForBodyPart(bodyPart) {
        const idleAnimationName = idleAnimationsByBodyPart[bodyPart];
        if (idleAnimationName) {
            const idleClipsById = generateIdleClipsById({
                idleAnimationName,
                expressionTimelineKey,
                foSpineAssetId,
                timeWidth,
            });
            expressionLayers.push({
                clipsById: idleClipsById,
            });
        }

        const animationsByExpressionForFoSpineAsset = getAnimationsByExpressionForFoSpineAsset({
            foSpineAssetId,
        });
        const clipsById = buildClipsByIdForExpression({
            bodyPart,
            expressionTimelineKey,
            position,
            expressionDuration,
            foSpineAssetId,
            animationsByExpression: animationsByExpressionForFoSpineAsset,
            bodyPartsAlreadyUsedForThisKey,
            timeWidth,
        });

        expressionLayers.push({
            clipsById,
        });

        Object.values(clipsById).forEach(function _registerBodyPartsUsedByClip(clip) {
            const { animationId } = clip.data;
            const bodyPartsUsedByAnimation = expressionsByAnimation[animationId].affectedBodyParts;
            bodyPartsAlreadyUsedForThisKey = new Set([
                ...bodyPartsAlreadyUsedForThisKey,
                ...bodyPartsUsedByAnimation,
            ]);
        });
    });

    return expressionLayers;
}
