import { createSlice } from '@reduxjs/toolkit';
import { languages } from 'src/components/constants/audio';

const initialState = {
    transcriptText: '',
    startTime: 0,
    endTime: 0,
    isEstimateEndTime: true,
    language: languages.ENG,
};

const transcriptWritingFormSlice = createSlice({
    name: 'videoDownloadForm',
    initialState,
    reducers: {
        setTranscriptText(state, action) {
            state.transcriptText = action.payload;
        },
        setStartTime(state, action) {
            state.startTime = action.payload;
        },
        setEndTime(state, action) {
            state.endTime = action.payload;
        },
        setIsEstimateEndTime(state, action) {
            state.isEstimateEndTime = action.payload;
        },
        setLanguage(state, action) {
            state.language = action.payload;
        },
    },
});

export const {
    setTranscriptText,
    setStartTime,
    setEndTime,
    setIsEstimateEndTime,
    setLanguage,
} = transcriptWritingFormSlice.actions;

export default transcriptWritingFormSlice.reducer;
