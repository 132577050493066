import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import ToolContainer from 'src/components/shared/ToolContainer';
import * as routes from 'src/components/constants/routes';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';

import SlotControls from './SlotControls';

import styles from './styles.module.scss';

export default function TextureManagerToolContainer(props) {
    const {
        className,
        foSpineAsset,
        selectedFoTextureCompId,
        selectedSlotName,
        selectSlotByName,
        setEditingFoTextureCompId,
        ...otherProps
    } = props;

    const navigate = useNavigate();

    const handleAddTextureClick = useCallback(
        function _handleAddTextureClick() {
            if (selectedFoTextureCompId) {
                setEditingFoTextureCompId('');
            }
            navigate(`../${routes.EDIT_TEXTURE}`);
        },
        [navigate, setEditingFoTextureCompId, selectedFoTextureCompId],
    );

    return (
        <ToolContainer
            className={classnames(styles.TextureManagerToolContainer, className)}
            {...otherProps}
        >
            <SlotControls
                foSpineAsset={foSpineAsset}
                selectedFoTextureCompId={selectedFoTextureCompId}
                selectedSlotName={selectedSlotName}
                selectSlotByName={selectSlotByName}
            />
            {selectedSlotName && (
                <ToolButton onClick={handleAddTextureClick} className='ToolContainer__tool'>
                    Add Texture
                </ToolButton>
            )}
        </ToolContainer>
    );
}

TextureManagerToolContainer.propTypes = {
    className: PropTypes.string,
    foSpineAsset: foSpineAssetLike.isRequired,
    selectedFoTextureCompId: PropTypes.string.isRequired,
    selectedSlotName: PropTypes.string.isRequired,
    selectSlotByName: PropTypes.func.isRequired,
    setEditingFoTextureCompId: PropTypes.func.isRequired,
};

TextureManagerToolContainer.defaultProps = {
    className: '',
};
