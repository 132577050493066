import React, { useCallback, useContext, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StyledForm from 'src/components/shared/formFactory/StyledForm';
import StyledSelect from 'src/components/shared/selectFactory/StyledSelect';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import LoadingToolButton from 'src/components/shared/buttonFactory/LoadingToolButton';
import useInputHandlers from 'src/components/views/SceneEditor/Monitor/VideoDownloadButton/VideoDownloadForm/hooks/useInputHandlers';
import ProgressBar from 'src/components/shared/ProgressBar';
import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import useDownloadVideo, {
    downloadPhases,
} from 'src/components/views/SceneEditor/Monitor/VideoDownloadButton/VideoDownloadForm/hooks/useDownloadVideo';
import {
    setFileName,
    setFormat,
    setFrameRate,
    setQuality,
    setStartTime,
    setEndTime,
    setIsIncludeAudio,
    setIsDownloadFrames,
    setVideoDownloadScale,
    setVideoDownloadPaddingPercentX,
    setVideoDownloadPaddingPercentY,
} from 'src/components/views/SceneEditor/Monitor/VideoDownloadButton/VideoDownloadForm/videoDownloadFormSlice';
import { videoExportFormats } from 'src/components/views/SceneEditor/Monitor/VideoDownloadButton/constants';
import FormElementGroup from 'src/components/shared/FormElementGroup';

import styles from './styles.module.scss';

function VideoDownloadForm(props) {
    const {
        className,
        selectedAudioTrackId,
        // these are formStates
        fileName,
        setFileName,
        format,
        setFormat,
        frameRate,
        setFrameRate,
        quality,
        setQuality,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        isIncludeAudio,
        setIsIncludeAudio,
        isDownloadFrames,
        setIsDownloadFrames,
        videoDownloadScale,
        setVideoDownloadScale,
        videoDownloadPaddingPercentX,
        setVideoDownloadPaddingPercentX,
        videoDownloadPaddingPercentY,
        setVideoDownloadPaddingPercentY,
    } = props;

    const videoDownloadFormStateRef = useRef();
    videoDownloadFormStateRef.current = {
        fileName,
        setFileName,
        format,
        setFormat,
        frameRate,
        setFrameRate,
        quality,
        setQuality,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        isIncludeAudio,
        setIsIncludeAudio,
        isDownloadFrames,
        setIsDownloadFrames,
        videoDownloadScale,
        setVideoDownloadScale,
        videoDownloadPaddingPercentX,
        setVideoDownloadPaddingPercentX,
        videoDownloadPaddingPercentY,
        setVideoDownloadPaddingPercentY,
    };

    const { currentTimeManager, jumpAppToTime } = useContext(SceneEditorContext);
    const {
        downloadPhase,
        downloadVideo,
        markVideoDownloadStopped,
        capturedFramesPercent,
        videoConversionPercent,
        videoDownloadPercent,
    } = useDownloadVideo({
        videoDownloadFormStateRef,
        currentTimeManager,
        jumpAppToTime,
        selectedAudioTrackId,
    });

    const {
        handleFileNameChange,
        handleFormatChange,
        handleFrameRateChange,
        handleQualityChange,
        handleStartTimeChange,
        handleEndTimeChange,
        handleAudioInclusionSwitchChange,
        handleDownloadFramesSwitchChange,
        handleVideoDownloadScaleChange,
        handleVideoDownloadPaddingPercentXChange,
        handleVideoDownloadPaddingPercentYChange,
    } = useInputHandlers(videoDownloadFormStateRef);

    const handleFormSubmit = useCallback(
        function _handleFormSubmit(event) {
            event.preventDefault();
            downloadVideo();
        },
        [downloadVideo],
    );

    const handleCancelButtonClick = useCallback(
        function _handleFormSubmit() {
            markVideoDownloadStopped();
        },
        [markVideoDownloadStopped],
    );

    const isAnyAudioAvailable = !!selectedAudioTrackId;
    const isCapturing = downloadPhase === downloadPhases.CAPTURING;

    let label = 'video conversion';
    let percent = 0;
    if (downloadPhase === downloadPhases.CAPTURING) {
        label = 'capturing frames';
        percent = capturedFramesPercent;
    } else if (downloadPhase === downloadPhases.PROCESSING) {
        label = 'converting to video';
        percent = videoConversionPercent;
    } else if (downloadPhase === downloadPhases.DOWNLOADING) {
        label = 'downloading video';
        percent = videoDownloadPercent;
    }

    return (
        <StyledForm
            headerContent='Download video'
            className={classnames(styles.VideoDownloadForm, className)}
            onSubmit={handleFormSubmit}
        >
            <FormElementGroup
                label='File name'
                elementProps={{
                    value: fileName,
                    onChange: handleFileNameChange,
                }}
            />

            <FormElementGroup
                label='Format'
                FormElement={StyledSelect}
                elementProps={{
                    value: format,
                    onChange: handleFormatChange,
                }}
            >
                <option value={videoExportFormats.MP4}>{videoExportFormats.MP4}</option>
                <option value={videoExportFormats.MOV}>{videoExportFormats.MOV}</option>
            </FormElementGroup>
            {format === videoExportFormats.MP4 && (
                <FormElementGroup
                    label={`Quality: ${quality}`}
                    elementProps={{
                        type: 'range',
                        min: '0',
                        max: '100',
                        value: quality,
                        onChange: handleQualityChange,
                    }}
                />
            )}

            {isAnyAudioAvailable && (
                <FormElementGroup
                    label='Include Audio'
                    elementProps={{
                        type: 'checkbox',
                        checked: isIncludeAudio,
                        onChange: handleAudioInclusionSwitchChange,
                    }}
                />
            )}

            <FormElementGroup
                label='Frame Rate'
                elementProps={{
                    className: styles.VideoDownloadForm__frameRateInput,
                    type: 'number',
                    value: frameRate,
                    onChange: handleFrameRateChange,
                }}
            />

            <FormElementGroup
                label='Start Time (secs)'
                elementProps={{
                    type: 'number',
                    value: startTime,
                    onChange: handleStartTimeChange,
                }}
            />

            <FormElementGroup
                label='End Time (secs)'
                elementProps={{
                    type: 'number',
                    value: endTime,
                    onChange: handleEndTimeChange,
                }}
            />

            <FormElementGroup
                label='Download Frames'
                elementProps={{
                    // className: styles.VideoDownloadForm__downloadFramesSwitch,
                    type: 'checkbox',
                    checked: isDownloadFrames,
                    onChange: handleDownloadFramesSwitchChange,
                }}
            />

            <FormElementGroup
                label={`Scale: ${videoDownloadScale}`}
                elementProps={{
                    type: 'range',
                    min: '0',
                    max: '1',
                    step: 0.05,
                    value: videoDownloadScale,
                    onChange: handleVideoDownloadScaleChange,
                }}
            />

            <FormElementGroup
                className={styles.VideoDownloadForm__paddingFormElementGroup}
                label={`Padding X: ${videoDownloadPaddingPercentX * 100}%`}
                elementProps={{
                    type: 'range',
                    min: '0',
                    max: '2',
                    step: 0.05,
                    value: videoDownloadPaddingPercentX,
                    onChange: handleVideoDownloadPaddingPercentXChange,
                }}
            />

            <FormElementGroup
                className={styles.VideoDownloadForm__paddingFormElementGroup}
                label={`Padding Y: ${videoDownloadPaddingPercentY * 100}%`}
                elementProps={{
                    type: 'range',
                    min: '0',
                    max: '1',
                    step: 0.05,
                    value: videoDownloadPaddingPercentY,
                    onChange: handleVideoDownloadPaddingPercentYChange,
                }}
            />

            <ProgressBar label={label} percent={percent} />

            {downloadPhase ? (
                <LoadingToolButton
                    onClick={handleCancelButtonClick}
                    isLoading
                    isDisabled={!isCapturing}
                >
                    {isCapturing ? 'Cancel' : 'Processing'}
                </LoadingToolButton>
            ) : (
                <ToolButton type='submit'>Submit</ToolButton>
            )}
        </StyledForm>
    );
}

VideoDownloadForm.propTypes = {
    className: PropTypes.string,
    selectedAudioTrackId: PropTypes.string.isRequired,

    fileName: PropTypes.string.isRequired,
    setFileName: PropTypes.func.isRequired,

    format: PropTypes.string.isRequired,
    setFormat: PropTypes.func.isRequired,

    frameRate: PropTypes.number.isRequired,
    setFrameRate: PropTypes.func.isRequired,

    quality: PropTypes.number.isRequired,
    setQuality: PropTypes.func.isRequired,

    startTime: PropTypes.number.isRequired,
    setStartTime: PropTypes.func.isRequired,

    endTime: PropTypes.number.isRequired,
    setEndTime: PropTypes.func.isRequired,

    isIncludeAudio: PropTypes.bool.isRequired,
    setIsIncludeAudio: PropTypes.func.isRequired,

    isDownloadFrames: PropTypes.bool.isRequired,
    setIsDownloadFrames: PropTypes.func.isRequired,

    videoDownloadScale: PropTypes.number.isRequired,
    setVideoDownloadScale: PropTypes.func.isRequired,
    videoDownloadPaddingPercentX: PropTypes.number.isRequired,
    setVideoDownloadPaddingPercentX: PropTypes.func.isRequired,
    videoDownloadPaddingPercentY: PropTypes.number.isRequired,
    setVideoDownloadPaddingPercentY: PropTypes.func.isRequired,
};

VideoDownloadForm.defaultProps = {
    className: '',
};

function mapState(state) {
    const {
        videoDownloadForm: {
            fileName,
            format,
            frameRate,
            quality,
            startTime,
            endTime,
            isIncludeAudio,
            isDownloadFrames,
            videoDownloadScale,
            videoDownloadPaddingPercentX,
            videoDownloadPaddingPercentY,
        },
    } = state;

    return {
        fileName,
        format,
        frameRate,
        quality,
        startTime,
        endTime,
        isIncludeAudio,
        isDownloadFrames,
        videoDownloadScale,
        videoDownloadPaddingPercentX,
        videoDownloadPaddingPercentY,
    };
}

export default connect(mapState, {
    setFileName,
    setFormat,
    setFrameRate,
    setQuality,
    setStartTime,
    setEndTime,
    setIsIncludeAudio,
    setIsDownloadFrames,
    setVideoDownloadScale,
    setVideoDownloadPaddingPercentX,
    setVideoDownloadPaddingPercentY,
})(VideoDownloadForm);
