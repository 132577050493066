import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import StyledForm from 'src/components/shared/formFactory/StyledForm';
import FormElementGroup, {
    formElementGroupMessageTypes,
} from 'src/components/shared/FormElementGroup';
import LoadingToolButton from 'src/components/shared/buttonFactory/LoadingToolButton';
import { setUser } from 'src/components/views/UserProfile/userProfileSlice';
import useSignupFormSubmit from 'src/components/views/Signup/hooks/useSignupFormSubmit';
import useSignupFormUsername from 'src/components/views/Signup/hooks/useSignupFormUsername';
import useSignupFormPassword from 'src/components/views/Signup/hooks/useSignupFormPassword';
import useSignupFormEmail from 'src/components/views/Signup/hooks/useSignupFormEmail';

import styles from './styles.module.scss';

function Signup(props) {
    const { className, isAuthenticated, setUser } = props;

    const { username, handleUsernameChange } = useSignupFormUsername();
    const { email, handleEmailChange } = useSignupFormEmail();
    const { password, handlePasswordChange } = useSignupFormPassword();
    const { isSubmitting, handleSignupFormSubmit, errors } = useSignupFormSubmit({
        email,
        password,
        username,
        setUser,
    });

    if (isAuthenticated) {
        return <Navigate to='/' />;
    }

    const usernameMessages = [];
    if (errors.username) {
        usernameMessages.push({
            type: formElementGroupMessageTypes.ALERT,
            content: errors.username.message,
        });
    }

    const emailMessages = [];
    if (errors.email) {
        emailMessages.push({
            type: formElementGroupMessageTypes.ALERT,
            content: errors.email.message,
        });
    }

    const passwordMessages = [
        {
            content: 'should containt at least 6 characters.',
        },
        { content: '1 lower case, 1 upper case, 1 number, 1 symbol' },
    ];
    if (errors.password) {
        passwordMessages.push({
            type: formElementGroupMessageTypes.ALERT,
            content: errors.password.message,
        });
    }

    return (
        <div className={classnames(styles.Signup, className)}>
            <StyledForm onSubmit={handleSignupFormSubmit} headerContent='Sign Up'>
                <FormElementGroup
                    label='Username'
                    elementProps={{
                        value: username,
                        onChange: handleUsernameChange,
                    }}
                    messages={usernameMessages}
                />
                <FormElementGroup
                    label='Email'
                    elementProps={{
                        value: email,
                        onChange: handleEmailChange,
                    }}
                    messages={emailMessages}
                />
                <FormElementGroup
                    label='Password'
                    elementProps={{
                        value: password,
                        type: 'password',
                        onChange: handlePasswordChange,
                    }}
                    messages={passwordMessages}
                />
                <LoadingToolButton type='submit' isLoading={isSubmitting} isDisabled={isSubmitting}>
                    Submit
                </LoadingToolButton>
            </StyledForm>
        </div>
    );
}

Signup.propTypes = {
    className: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    setUser: PropTypes.func.isRequired,
};

Signup.defaultProps = {
    className: '',
};

function mapState(state) {
    const { userData } = state.userProfile;
    return {
        isAuthenticated: !!userData,
    };
}

export default connect(mapState, {
    setUser,
})(Signup);
