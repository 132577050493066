export default function prepareCanvasForDrawing({ canvas, padding = 0 }) {
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, -canvas.height, canvas.width, 2 * canvas.height);

    const dpr = window.devicePixelRatio || 1;

    canvas.width = canvas.offsetWidth * dpr;
    canvas.height = (canvas.offsetHeight + padding * 2) * dpr;
    ctx.scale(dpr, dpr);

    ctx.translate(0, canvas.offsetHeight / 2 + padding);

    return ctx;
}
