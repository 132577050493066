import { Container } from 'pixi.js';

export default function makeRotateLayer(transformTool) {
    const rotateLayer = new Container();
    transformTool.rotateLayer = rotateLayer;
    rotateLayer.transformTool = transformTool;
    rotateLayer.sortableChildren = true;

    transformTool.translateLayer.addChild(rotateLayer);
}
