import extractTransformValuesFromDisplayObject from 'src/pixi/utils/extractTransformValuesFromDisplayObject';
import removeUndefinedProps from 'src/utils/objectUtils/removeUndefinedProps';
import maskDataToJSON from 'src/pixi/tools/MaskTool/utils/maskDataToJSON';

export default function updateFoTextureWithDisplayObjectProps({ foTexture, displayObject }) {
    const { zIndex } = displayObject;

    const transform = extractTransformValuesFromDisplayObject(displayObject);

    let { maskData } = displayObject;
    if (maskData) {
        maskData = maskDataToJSON(displayObject.maskData);
    }

    const newFoTexture = removeUndefinedProps({
        ...foTexture,
        transform,
        maskData,
        zIndex,
    });

    return newFoTexture;
}
