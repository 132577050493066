import clipTypes from 'src/components/constants/clipTypes';
import { NEUTRAL_SPEECH_DURATION_THRESHOLD } from 'src/components/constants/audio';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import generateClipGroupFromClips from 'src/utils/businessLogic/clipUtils/generateClipGroupFromClips';

export default function groupSingleClips({ flattenedClips, timeWidth }) {
    const clipsGroups = [];
    let groupableSingleClips = [];
    for (let i = 0; i < flattenedClips.length; i++) {
        const clip = flattenedClips[i];

        if (clip.data.type === clipTypes.CLIP_SINGLE) {
            groupableSingleClips.push(clip);

            let isGroupReady = false;

            const nextClip = flattenedClips[i + 1];
            if (!nextClip || nextClip.data.type === clipTypes.CLIP_GROUP) {
                isGroupReady = true;
            } else if (nextClip?.data.type === clipTypes.CLIP_SINGLE) {
                const clipEnd = getClipEnd({ clip, timeWidth });
                const gapDuration = (clipEnd - nextClip.position) / timeWidth;
                const isNextGapTooLarge = gapDuration > NEUTRAL_SPEECH_DURATION_THRESHOLD;
                isGroupReady = isNextGapTooLarge;
            }

            if (isGroupReady) {
                let title = '';
                groupableSingleClips.forEach((clip) => {
                    const { graphemeChar } = clip.data;
                    if (graphemeChar) {
                        title = `${title}${graphemeChar}`;
                    }
                });

                const clipGroup = generateClipGroupFromClips({
                    clips: groupableSingleClips,
                    isSequentialClips: true,
                    data: { title },
                });

                clipsGroups.push(clipGroup);
                groupableSingleClips = [];
            }
        } else {
            clipsGroups.push(clip);
        }
    }

    return clipsGroups;
}
