import React from 'react';
import classnames from 'classnames';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PlusIcon(props) {
    const { className, ...otherProps } = props;

    return (
        <FontAwesomeIcon
            icon={faPlus}
            className={classnames('PlusIcon', className)}
            {...otherProps}
        />
    );
}
