import TimeTicker from 'src/utils/timeUtils/TimeTicker';
import loadAudioBuffer from 'src/utils/audioUtils/loadAudioBuffer';

const sharedAudioContext = new AudioContext();

export default class AudioController {
    constructor(url = '') {
        this.currentUrl = url;
    }

    _audioContext = sharedAudioContext;

    _isPlaying = false;

    get isPlaying() {
        return this._isPlaying;
    }

    set isPlaying(isPlaying) {
        this._isPlaying = isPlaying;
    }

    timeTicker = new TimeTicker();

    get currentTime() {
        return this.timeTicker.currentTime;
    }

    set currentTime(time) {
        this.timeTicker.currentTime = time;
        if (this.isPlaying) {
            this.pause();
            this.play();
        }
    }

    _sourceNode = null;

    play() {
        if (!this._buffer) {
            console.warn('Tried to play audio without setting the buffer on the controller.');
            return;
        }

        this.isPlaying = true;
        const sourceNode = this._audioContext.createBufferSource();
        sourceNode.buffer = this._buffer;
        sourceNode.connect(this._audioContext.destination);

        sourceNode.start(0, this.currentTime);

        this._sourceNode = sourceNode;

        this.timeTicker.start();
    }

    pause() {
        if (!this._sourceNode) {
            return;
        }
        this.isPlaying = false;
        this._sourceNode.stop();
        this.timeTicker.stop();
    }

    stop() {
        this.pause();
        this.timeTicker.reset();
    }

    _buffer = null;

    _setBuffer(audioBuffer) {
        if (audioBuffer !== this._buffer) {
            this.stop();
            this._buffer = audioBuffer;
        }
    }

    getBuffer() {
        return this._buffer;
    }

    _loadingPromise = null;

    get isLoading() {
        const isLoading = !!this._loadingPromise;
        return isLoading;
    }

    // todo - it seems we reload the buffer even for the urls that already have their buffers loaded.
    // Either pass a second options param {isForce: Boolean}, which will determine if reload is needed.
    // Set it to false by default.
    // Or make sure the consumer, doesn't call load if the buffer is already loaded.
    // e.g. AudioSelector list
    async loadBuffer(url) {
        const { isLoading } = this;
        if (isLoading && (!url || url === this.currentUrl)) {
            return this._loadingPromise;
        }

        if (url) {
            this.currentUrl = url;
        }

        if (!this.currentUrl) {
            console.error('Cannot load audio buffer without valid url.');
            return null;
        }

        let audioBuffer;

        try {
            this._loadingPromise = loadAudioBuffer({
                url: this.currentUrl,
                audioContext: this._audioContext,
            });
            audioBuffer = await this._loadingPromise;
            const isThisStillMostRecentLoadRequest = url && url !== this.currentUrl;
            if (!isThisStillMostRecentLoadRequest) {
                this._setBuffer(audioBuffer);
            }
        } catch (err) {
            console.error(`Couldn't load audio at url: ${url}`, err);
        }

        this._loadingPromise = null;

        return audioBuffer;
    }
}
