import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import useHandleAudioFileChange from 'src/components/views/AudioSelector/AudioUploader/hooks/useHandleAudioFileChange';
import useHandleUploadButtonClick from 'src/components/views/AudioSelector/AudioUploader/hooks/useHandleUploadButtonClick';
import useHandleTranscribeButtonClick from 'src/components/views/AudioSelector/AudioUploader/hooks/useHandleTranscribeButtonClick';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import Spinner from 'src/components/shared/Spinner';
import {
    addAudioTrack,
    updateAudioTrackTranscript,
} from 'src/components/views/AudioSelector/audioSelectorSlice';
import { audioTrackLike } from 'src/components/views/AudioSelector/audioSelectorSlice/propTypes';

import styles from './styles.module.scss';

function AudioUploader(props) {
    const {
        className,
        addAudioTrack,
        onAudioAdded,
        updateAudioTrackTranscript,
        selectedAudioTrack,
    } = props;

    const inputElemRef = useRef(null);
    const [audioFile, setAudioFile] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const handleAudioFileChange = useHandleAudioFileChange({
        setAudioFile,
    });
    const handleUploadButtonClick = useHandleUploadButtonClick({
        audioFile,
        setAudioFile,
        inputElemRef,
        setIsFetching,
        addAudioTrack,
        onAudioAdded,
    });
    const handleTranscribeButtonClick = useHandleTranscribeButtonClick({
        setIsFetching,
        selectedAudioTrack,
        updateAudioTrackTranscript,
    });

    return (
        <div className={classnames(styles.AudioUploader, className)}>
            <span>Upload audio: </span>
            <input
                type='file'
                accept='audio/*'
                onChange={handleAudioFileChange}
                ref={inputElemRef}
                disabled={isFetching}
            />
            <ToolButton onClick={handleUploadButtonClick} isDisabled={!audioFile || isFetching}>
                Upload
            </ToolButton>
            <ToolButton
                onClick={handleTranscribeButtonClick}
                isDisabled={!selectedAudioTrack || isFetching}
            >
                {selectedAudioTrack?.hasTranscriptWords ? 'Reset Transcript' : 'Transcribe'}
            </ToolButton>
            {isFetching && <Spinner className={styles.AudioUploader__spinner} />}
        </div>
    );
}

AudioUploader.propTypes = {
    className: PropTypes.string,
    addAudioTrack: PropTypes.func.isRequired,
    onAudioAdded: PropTypes.func.isRequired,
    updateAudioTrackTranscript: PropTypes.func.isRequired,
    selectedAudioTrack: audioTrackLike,
};

AudioUploader.defaultProps = {
    className: '',
    selectedAudioTrack: null,
};

export default connect(undefined, {
    addAudioTrack,
    updateAudioTrackTranscript,
})(AudioUploader);
