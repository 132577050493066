import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import getCaller from 'src/utils/functionUtils/getCaller';

/**
 * @selectorFn Function - returns an object that contains the selected values,
 * this is similar to mapStateToProps
 * @selectionTypes Object - is an object of prop types
 * that will be checked against the object returned by selectorFn
 * @defaultValues Object - is an object of default props that are not found on selectedValues
 * @prop Any - additional props that will be passed to selectorFn as 2nd arg.
 * @componentName String? - if not provided,
 * will use the name of the function that called useTypedSelector
 * @selectionName String? - describes what is being type checked
 */
export default function useTypedSelector({
    selectorFn,
    selectionTypes,
    props,
    defaultValues = {},
    componentName = '',
    selectionName = 'selected state',
}) {
    const selectedValue = useSelector((state) => {
        return selectorFn(state, props);
    }, shallowEqual);

    Object.keys(defaultValues).forEach((propName) => {
        if (selectedValue[propName] === undefined && defaultValues[propName]) {
            selectedValue[propName] = defaultValues[propName];
        }
    });

    const derivedComponentName = componentName || getCaller();

    PropTypes.checkPropTypes(selectionTypes, selectedValue, selectionName, derivedComponentName);

    return selectedValue;
}
