export default function getPositionToScheduleLayersFrom({
    layersWithPaddedClips,
    timeIndicatorPosition,
    timeWidth,
    track,
    globalMixDuration,
}) {
    let leftMostClip = null;
    let mixDuration = globalMixDuration;
    layersWithPaddedClips.forEach(function _iterateOverLayersToFindLeftMostCLip(
        paddedClips,
        layerIndex,
    ) {
        const trackLayerId = track.layers.allIds[layerIndex];
        const layerMixDuration = track.layers.byId[trackLayerId].settings.mixDuration;
        paddedClips.forEach(function _iterateOverPaddedClipsToFindLeftMostClip(clip) {
            if (!leftMostClip || clip.position < leftMostClip.position) {
                leftMostClip = clip;
                if (layerMixDuration) {
                    mixDuration = layerMixDuration;
                }
            }
        });
    });

    let positionToScheduleLayersFrom = 0;
    if (leftMostClip) {
        const arbitraryPositionToTheLeft = leftMostClip.position - mixDuration * timeWidth;
        positionToScheduleLayersFrom = Math.min(
            timeIndicatorPosition,
            Math.max(arbitraryPositionToTheLeft, 0),
        );
    } else {
        positionToScheduleLayersFrom = timeIndicatorPosition;
    }

    return positionToScheduleLayersFrom;
}
