import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import useHandleDeleteFoTextureComp from 'src/components/views/TextureRouteManager/TextureManager/FoTextureCompList/FoTextureCompListItem/Buttons/DeleteButton/hooks/useHandleDeleteFoTextureComp';
import { removeFoTextureCompFromStore } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice';
import { linkedFoSpineAssetIdsLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import { setFoSpineAssetTextureModifications } from 'src/components/views/AssetSelector/assetSelectorSlice';
import { foSpineAssetsLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import getFoTextureCompById from 'src/reduxStore/utils/getFoTextureCompById';

import styles from './styles.module.scss';

function DeleteButton(props) {
    const {
        className,
        foTextureCompId,
        removeFoTextureCompFromStore,
        foSpineAssets,
        setFoSpineAssetTextureModifications,
        linkedFoSpineAssetIds,
        ...attributes
    } = props;

    const handleDeleteFoTexture = useHandleDeleteFoTextureComp({
        foTextureCompId,
        removeFoTextureCompFromStore,
        foSpineAssets,
        setFoSpineAssetTextureModifications,
        linkedFoSpineAssetIds,
    });

    return (
        <ToolButton
            className={classnames(styles.DeleteButton, className)}
            onClick={handleDeleteFoTexture}
            {...attributes}
        >
            delete
        </ToolButton>
    );
}

DeleteButton.propTypes = {
    className: PropTypes.string,
    foTextureCompId: PropTypes.string.isRequired,
    removeFoTextureCompFromStore: PropTypes.func.isRequired,
    setFoSpineAssetTextureModifications: PropTypes.func.isRequired,
    foSpineAssets: foSpineAssetsLike.isRequired,
    linkedFoSpineAssetIds: linkedFoSpineAssetIdsLike.isRequired,
};

DeleteButton.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { foTextureCompId } = ownProps;
    const foTextureComp = getFoTextureCompById({ state, foTextureCompId });
    const { linkedFoSpineAssetIds } = foTextureComp;
    const { foSpineAssets } = state.assetSelector;
    return {
        foSpineAssets,
        linkedFoSpineAssetIds,
    };
}

export default connect(mapState, {
    removeFoTextureCompFromStore,
    setFoSpineAssetTextureModifications,
})(DeleteButton);
