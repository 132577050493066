import { useCallback } from 'react';

import { getUserSignoutEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default function useHandleSignoutClick({ setUser }) {
    return useCallback(
        async function _handleSignoutClick() {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }

            try {
                const userSignoutEndpoint = getUserSignoutEndpoint();

                const response = await fetchWithAuth(userSignoutEndpoint, {
                    method: 'post',
                });

                if (response.ok) {
                    setUser(null);
                    localStorage.removeItem('token');
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.errors[0].message);
                }
            } catch (err) {
                console.log(err);
            }
        },
        [setUser],
    );
}
