import getChildById from 'src/pixi/utils/getChildById';
import destroyFoTextureDisplayObject from 'src/utils/businessLogic/foTexture/destroyFoTextureDisplayObject';

export default function removeFromPixi({ foTextureId, app }) {
    const foTextureDisplayObject = getChildById({
        parent: app.stage,
        id: foTextureId,
    });
    destroyFoTextureDisplayObject(foTextureDisplayObject);
}
