import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import PlusIcon from 'src/components/shared/iconFactory/PlusIcon';
import Select from 'src/components/shared/selectFactory/Select';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import expressions from 'src/utils/businessLogic/expressions/constants/expressions';

import styles from './styles.module.scss';

export default function InfluenceSelectorSet(props) {
    const {
        className,
        handleInfluenceSelectionChange,
        selectedExpression,
        unusedExpressions,
        addSelectedInfluence,
    } = props;

    return (
        <div className={classnames(styles.InfluenceSelectorSet, className)}>
            <Select
                name='expression-selector'
                id='expression-selector'
                onChange={handleInfluenceSelectionChange}
                value={selectedExpression}
            >
                {unusedExpressions.map((expression) => {
                    return (
                        <option key={expression} value={expression}>
                            {expression}
                        </option>
                    );
                })}
            </Select>
            <ToolButton onClick={addSelectedInfluence}>
                <PlusIcon />
            </ToolButton>
        </div>
    );
}

InfluenceSelectorSet.propTypes = {
    className: PropTypes.string,
    handleInfluenceSelectionChange: PropTypes.func.isRequired,
    selectedExpression: PropTypes.oneOf(Object.values(expressions)).isRequired,
    unusedExpressions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(expressions))).isRequired,
    addSelectedInfluence: PropTypes.func.isRequired,
};

InfluenceSelectorSet.defaultProps = {
    className: '',
};
