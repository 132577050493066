import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectableListItem from 'src/components/shared/SelectableListItem';
import LoadingToolButton from 'src/components/shared/buttonFactory/LoadingToolButton';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import TrashCanIcon from 'src/components/shared/iconFactory/TrashCanIcon';
import PencilIcon from 'src/components/shared/iconFactory/PencilIcon';
import useHandleDeleteScene from 'src/components/views/SceneSelector/SceneSelectorListItem/hooks/useHandleDeleteScene';
import useHandleSelectableListItemClick from 'src/components/views/SceneSelector/SceneSelectorListItem/hooks/useHandleSelectableListItemClick';

import CloneSceneButton from './CloneSceneButton';

import styles from './styles.module.scss';

export default function SceneSelectorListItem(props) {
    const {
        className,
        isSelected,
        sceneId,
        deleteScene,
        name,
        openEditSceneForm,
        ...attributes
    } = props;

    const handleSelectableListItemClick = useHandleSelectableListItemClick({ sceneId });
    const handleDeleteScene = useHandleDeleteScene({ sceneId, deleteScene });
    const handleEditSceneClick = useCallback(
        function _handleEditSceneClick() {
            openEditSceneForm(sceneId);
        },
        [openEditSceneForm, sceneId],
    );

    return (
        <SelectableListItem
            className={classnames(styles.SceneSelectorListItem, className)}
            onClick={handleSelectableListItemClick}
            isSelected={isSelected}
            {...attributes}
        >
            <div className={styles.SceneSelectorListItem__labelControls}>
                <div className={styles.SceneSelectorListItem__label}>{name}</div>
            </div>

            <div className={styles.SceneSelectorListItem__tools}>
                <CloneSceneButton sceneId={sceneId} />
                <ToolButton onClick={handleEditSceneClick}>
                    <PencilIcon title='Edit Scene' />
                </ToolButton>
                <LoadingToolButton onClick={handleDeleteScene}>
                    <TrashCanIcon title='Delete Scene' />
                </LoadingToolButton>
            </div>
        </SelectableListItem>
    );
}

SceneSelectorListItem.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    sceneId: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    openEditSceneForm: PropTypes.func.isRequired,
};

SceneSelectorListItem.defaultProps = {
    className: '',
    name: '',
};
