/**
 * @displayObject container, sprite, graphics or other pixi display object
 * @position {x: Number, y: Number}
 */
export default function movePivotToGlobalPositionWithoutMovingParent({
    displayObject,
    position: targetGlobalPosition,
}) {
    const parent = displayObject.parent || displayObject;
    const newPivotRelativeToParent = parent.toLocal(targetGlobalPosition);

    const newPivotRelativeToDisplayObject = displayObject.toLocal(targetGlobalPosition);

    displayObject.position.set(newPivotRelativeToParent.x, newPivotRelativeToParent.y);
    displayObject.pivot.set(newPivotRelativeToDisplayObject.x, newPivotRelativeToDisplayObject.y);
}
