import { Container } from 'pixi.js';

import {
    addEventHandler,
    removeEventHandler,
    runEventHandlers,
} from 'src/pixi/tools/utils/manageEventHandlers';
import * as eventNames from 'src/pixi/tools/TransformTool/constants/eventNames';

import applyTransformToolToTarget from './applyTransformToolToTarget';
import clearTransformTool from './clearTransformTool';
import destroyTransformTool from './destroyTransformTool';
import makeTranslateLayer from './makeTranslateLayer';
import makeRotateLayer from './makeRotateLayer';
import makeGhostLayer from './makeGhostLayer';
import makeBgGhostLayer from './makeBgGhostLayer';
import makePivotKnob from './makePivotKnob';
import makeResizeKnobs from './makeResizeKnobs';
import makeRotateKnobs from './makeRotateKnobs';
import positionKnobs from './positionKnobs';

export default class TransformTool extends Container {
    constructor(props) {
        super(props);
        const { app } = props;
        const transformTool = this;

        transformTool.eventHandlersByName = {};
        transformTool.app = app;

        transformTool.visible = false;

        makeBgGhostLayer({ transformTool, app });

        makeTranslateLayer(transformTool);

        makeRotateLayer(transformTool);

        makePivotKnob(transformTool);

        makeResizeKnobs(transformTool);

        makeRotateKnobs(transformTool);

        makeGhostLayer(transformTool);
    }

    addEventHandler(eventName, handler) {
        const transformTool = this;

        addEventHandler({ tool: transformTool, eventName, handler });

        return transformTool;
    }

    removeEventHandler(eventName, handler) {
        const transformTool = this;

        removeEventHandler({ tool: transformTool, eventName, handler });

        return transformTool;
    }

    runEventHandlersFor(eventName) {
        const transformTool = this;

        runEventHandlers({ eventName, tool: transformTool });
    }

    applyTo(target) {
        const transformTool = this;
        applyTransformToolToTarget({ transformTool, target });

        this.runEventHandlersFor(eventNames.APPLY_TO);
    }

    clear() {
        const transformTool = this;
        clearTransformTool(transformTool);

        this.runEventHandlersFor(eventNames.CLEAR);
    }

    positionKnobs() {
        const transformTool = this;
        positionKnobs(transformTool);
    }

    destroy(options) {
        const transformTool = this;
        destroyTransformTool(transformTool);
        super.destroy(options);
    }
}
