import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import TextureEditorContext from 'src/components/views/TextureRouteManager/TextureEditor/context/TextureEditorContext';
import useHandleDeleteFoTexture from 'src/components/views/TextureRouteManager/TextureEditor/SortableFoTextureList/FoTextureList/SortableFoTextureListItem/FoTextureListItem/Buttons/DeleteButton/hooks/useHandleDeleteFoTexture';

import styles from './styles.module.scss';

export default function DeleteButton(props) {
    const { className, foTextureId, ...attributes } = props;

    const { updateFoTextures, foTextureComp } = useContext(TextureEditorContext);

    const handleDeleteFoTexture = useHandleDeleteFoTexture({
        updateFoTextures,
        foTextures: foTextureComp.foTextures,
        foTextureId,
    });

    return (
        <ToolButton
            className={classnames(styles.DeleteButton, className)}
            onClick={handleDeleteFoTexture}
            {...attributes}
        >
            delete
        </ToolButton>
    );
}

DeleteButton.propTypes = {
    className: PropTypes.string,
    foTextureId: PropTypes.string.isRequired,
};

DeleteButton.defaultProps = {
    className: '',
};
