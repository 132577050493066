import { useEffect } from 'react';
import { Sprite, Container } from 'pixi.js';

import mountPixi from 'src/pixi/utils/mountPixi';
import pixiStore from 'src/pixi/pixiStore';
import { setTextureToolsToDefault } from 'src/components/views/TextureRouteManager/TextureEditor/pixiAssets/textureTools';

import useHandleToolClear from './useHandleToolClear';
import useHandleToolApply from './useHandleToolApply';
import setupFoSpineAssetInEditor from './setupFoSpineAssetInEditor';
import setupFoTextureCompInEditor from './setupFoTextureCompInEditor';
import setupToolsInEditor from './setupToolsInEditor';

export default function useSetupTextureEditorApp({
    setSelectedFoTextureId,
    foTextureComp,
    pixiContainerElemRef,
    foSpineAsset,
    selectedSlotName,
}) {
    const handleToolClear = useHandleToolClear({ setSelectedFoTextureId });
    const handleToolApply = useHandleToolApply({
        setSelectedFoTextureId,
        foTextures: foTextureComp.foTextures,
    });

    useEffect(() => {
        const app = mountPixi(pixiContainerElemRef.current);

        const zoomContainer = new Container();
        app.stage.addChild(zoomContainer);
        pixiStore.pixiAppManager.setZoomContainer(zoomContainer);

        const bgSprite = Sprite.from('/images/checkerboard.png');
        zoomContainer.addChild(bgSprite);

        setupFoSpineAssetInEditor({ foSpineAsset, selectedSlotName }).then((spineAssetInstance) => {
            setupFoTextureCompInEditor({
                foSpineAsset,
                foTextureComp,
                selectedSlotName,
                spineAssetInstance,
            });
        });
        setupToolsInEditor({ app, handleToolApply, handleToolClear });

        zoomContainer.sortableChildren = true;

        return function _dismantleTextureEditor() {
            setTextureToolsToDefault();
            pixiStore.pixiAppManager.clearPixiApp();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
