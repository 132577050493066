import { useMemo, useRef } from 'react';

export default function useAnimationList({ animationsById, searchTerm }) {
    const sortedAnimations = useMemo(() => {
        const sortedAnimations = Object.values(animationsById).sort(function _compareAnimationIds(
            animation1,
            animation2,
        ) {
            const lowercaseId1 = animation1.animationId.toUpperCase();
            const lowercaseId2 = animation2.animationId.toUpperCase();

            const isId2BeforeId1 = lowercaseId2 < lowercaseId1;
            if (isId2BeforeId1) {
                return 1;
            }

            return -1;
        });

        return sortedAnimations;
    }, [animationsById]);

    const prevSearchTermRef = useRef(searchTerm);
    const prevFilteredAnimationsRef = useRef(sortedAnimations);
    const prevSortedAnimationsRef = useRef(sortedAnimations);

    if (
        prevSearchTermRef.current !== searchTerm ||
        prevSortedAnimationsRef.current !== sortedAnimations
    ) {
        prevSortedAnimationsRef.current = sortedAnimations;
        prevSearchTermRef.current = searchTerm;
        prevFilteredAnimationsRef.current = sortedAnimations.filter((animation) => {
            return animation.title.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }

    return prevFilteredAnimationsRef.current;
}
