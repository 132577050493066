import { getScenesEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import generateScene from 'src/utils/businessLogic/sceneUtils/generateScene';
import generateTrack from 'src/utils/businessLogic/trackUtils/generateTrack';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function fetchCreateScene({ trackFoSpineAsset, sceneName }) {
    const track = generateTrack({ foSpineAsset: trackFoSpineAsset });
    const tracks = {
        byId: { [track.trackId]: track },
        allIds: [track.trackId],
    };
    const emptyScene = generateScene({ name: sceneName, tracks });

    const scenesEndpoint = getScenesEndpoint();
    const response = await fetchWithAuth(scenesEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(emptyScene),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.errors[0].message);
    }
    const fetchedScene = await response.json();
    return fetchedScene;
}
