import { getVideoConversionEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function uploadFrames({ setVideoConversionPercent, formData }) {
    const videoConversionEndpoint = getVideoConversionEndpoint();
    const response = await fetchWithAuth(videoConversionEndpoint, {
        method: 'POST',
        'Content-Type': 'multipart/form-data',
        body: formData,
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
            `there was a problem with converting the video: ${errorData.errors[0].message}`,
        );
    }

    const reader = response.body.getReader();
    let { done, value } = await reader.read();
    const textDecoder = new TextDecoder('utf-8');

    let videoFileName;
    while (!done) {
        const chunksStr = textDecoder.decode(value);
        const chunksArr = chunksStr.trim().split('\n');
        const lastChunk = chunksArr[chunksArr.length - 1];
        const [type, chunkValue] = lastChunk.split(':');
        if (type === '%') {
            const percent = Number(chunkValue);
            setVideoConversionPercent(percent);
        } else if (type === 'name') {
            videoFileName = chunkValue;
        } else {
            console.log('something unexpected was received: ', type, chunkValue);
        }

        // eslint-disable-next-line no-await-in-loop
        ({ done, value } = await reader.read());
    }

    return videoFileName;
}
