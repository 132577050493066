import movePivotToGlobalPositionWithoutMovingParent from 'src/pixi/utils/pivotUtils/movePivotToGlobalPositionWithoutMovingParent';

/**
 * @displayObject container, sprite, graphics or other pixi display object
 * @amount {x: Number, y: Number}
 * The amount should be relative to display object context.
 * Not parent, not global, not any of it's children.
 */
export default function movePivotByAmountWithoutMovingParent({ displayObject, amount }) {
    const pivotChange = {
        x: amount.x / displayObject.scale.x,
        y: amount.y / displayObject.scale.y,
    };
    const newPivot = {
        x: displayObject.pivot.x + pivotChange.x,
        y: displayObject.pivot.y + pivotChange.y,
    };
    const newPivotGlobal = displayObject.toGlobal(newPivot);

    movePivotToGlobalPositionWithoutMovingParent({ displayObject, position: newPivotGlobal });
}
