import insertClip from 'src/utils/businessLogic/clipUtils/insertClip';
import generateClipGroupFromClips from 'src/utils/businessLogic/clipUtils/generateClipGroupFromClips';
import ungroupClipGroup from 'src/utils/businessLogic/clipUtils/ungroupClipGroup';

export default function insertClips({
    insertableClipsById,
    targetClipsById,
    editModes,
    snapDistance,
    timeWidth,
}) {
    const insertableClipGroup = generateClipGroupFromClips({ clips: insertableClipsById });

    const { conflictResolution, isSnap, trimMode } = editModes;
    const speechClipsByIdAfterInsertion = insertClip({
        newClip: insertableClipGroup,
        clipsById: targetClipsById,
        conflictResolution,
        snapDistance: isSnap ? snapDistance : undefined,
        trimMode,
        timeWidth,
    });

    const insertedClipGroup = speechClipsByIdAfterInsertion[insertableClipGroup.clipId];
    delete speechClipsByIdAfterInsertion[insertableClipGroup.clipId];
    const ungrouppedclipsById = ungroupClipGroup({
        clipGroup: insertedClipGroup,
        timeWidth,
    });
    const speechClipsByIdWithNewClips = {
        ...speechClipsByIdAfterInsertion,
        ...ungrouppedclipsById,
    };

    return speechClipsByIdWithNewClips;
}
