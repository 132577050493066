import React, { useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useSetupMonitorApp from 'src/components/views/SceneEditor/Monitor/hooks/useSetupMonitorApp';
import playbackStatuses from 'src/components/views/SceneEditor/constants/playbackStatuses';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { foTextureCompsLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import getFoTextureCompsOfFoSpineAssets from 'src/reduxStore/utils/getFoTextureCompsOfFoSpineAssets';
import getFoSpineAssetsByTrackId from 'src/reduxStore/utils/getFoSpineAssetsByTrackId';
import { setIsPixiMounted } from 'src/components/views/SceneEditor/sceneEditorSlice';
import { foTranscriptWordsLike } from 'src/components/views/AudioSelector/audioSelectorSlice/propTypes';
import withRouter from 'src/reduxStore/utils/withRouter';

import VideoDownloadButton from './VideoDownloadButton';
import MonitorOverlaysVisibilityButton from './MonitorOverlaysVisibilityButton';
import PlaybackControls from './PlaybackControls';

import styles from './styles.module.scss';

function Monitor(props) {
    const {
        className,
        jumpAppToTime,
        foTextureComps,
        setIsPixiMounted,
        foSpineAssetsByTrackId,
        spineAssetInstancesByTrackId,
        foTranscriptWords,
        selectedAudioTrackId,
        handlePlayButtonClick,
        handlePauseButtonClick,
        handleStopButtonClick,
        playbackStatus,
        isMonitorOverlaysShown,
    } = props;
    const monitorRef = useRef(null);

    const { setMonitorOverlaysVisibility } = useSetupMonitorApp({
        monitorRef,
        jumpAppToTime,
        foTextureComps,
        setIsPixiMounted,
        foSpineAssetsByTrackId,
        spineAssetInstancesByTrackId,
        foTranscriptWords,
        isMonitorOverlaysShown,
    });

    return (
        <div className={classnames(styles.Monitor, className)}>
            <div ref={monitorRef} className={styles.Monitor__canvasContainer} />
            <div className={styles.Monitor__tools}>
                <div className={styles.Monitor__leftTools} />
                <div className={styles.Monitor__centerTools}>
                    <PlaybackControls
                        className={styles.Monitor__playbackControls}
                        onStopButtonClick={handleStopButtonClick}
                        onPlayButtonClick={handlePlayButtonClick}
                        onPauseButtonClick={handlePauseButtonClick}
                        playbackStatus={playbackStatus}
                    />
                </div>
                <div className={styles.Monitor__rightTools}>
                    <MonitorOverlaysVisibilityButton
                        setMonitorOverlaysVisibility={setMonitorOverlaysVisibility}
                    />
                    <VideoDownloadButton selectedAudioTrackId={selectedAudioTrackId} />
                </div>
            </div>
        </div>
    );
}

Monitor.propTypes = {
    className: PropTypes.string,
    jumpAppToTime: PropTypes.func.isRequired,
    foTextureComps: foTextureCompsLike.isRequired,
    setIsPixiMounted: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    spineAssetInstancesByTrackId: PropTypes.objectOf(PropTypes.object).isRequired,
    foSpineAssetsByTrackId: PropTypes.objectOf(foSpineAssetLike).isRequired,
    foTranscriptWords: foTranscriptWordsLike,
    selectedAudioTrackId: PropTypes.string,
    handlePlayButtonClick: PropTypes.func.isRequired,
    handlePauseButtonClick: PropTypes.func.isRequired,
    handleStopButtonClick: PropTypes.func.isRequired,
    playbackStatus: PropTypes.oneOf(Object.values(playbackStatuses)).isRequired,
    isMonitorOverlaysShown: PropTypes.bool.isRequired,
};

Monitor.defaultProps = {
    className: '',
    foTranscriptWords: null,
    selectedAudioTrackId: '',
};

function mapState(state, ownProps) {
    const {
        textureManager: { foTextureComps },
        audioSelector: { audioTracks },
    } = state;

    const { sceneId: selectedSceneId } = ownProps.router.params;
    const { selectedAudioTrackId } = state.sceneSelector.scenes.byId[selectedSceneId];
    const { isMonitorOverlaysShown } = state.sceneEditor;

    const selectedAudioTrack = audioTracks.find(
        (audioTrack) => audioTrack.id === selectedAudioTrackId,
    );
    const { foTranscriptWords } = selectedAudioTrack || {};

    const { foSpineAssets } = ownProps;
    const relatedFoTextureComps = getFoTextureCompsOfFoSpineAssets({
        foSpineAssets,
        foTextureComps,
    });

    const foSpineAssetsByTrackId = getFoSpineAssetsByTrackId(state, { selectedSceneId });

    return {
        foTextureComps: relatedFoTextureComps,
        foSpineAssetsByTrackId,
        foTranscriptWords,
        selectedAudioTrackId,
        isMonitorOverlaysShown,
    };
}

export default withRouter(connect(mapState, { setIsPixiMounted })(Monitor));
