import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import dndTypes from 'src/components/views/SceneEditor/constants/dndTypes';

import { animationLike } from 'src/components/views/SceneEditor/Bins/Animations/animationsSlice/propTypes';
import HiddenDragPreview from 'src/components/shared/HiddenDragPreview';

import Animation from './Animation';
import styles from './styles.module.scss';

export default function AnimationDnD(props) {
    const { animation, className, ...otherProps } = props;

    const [, drag, previewRef] = useDrag({
        item: { type: dndTypes.ANIMATION, data: animation },
        // collect(monitor) {
        //     return { isDragging: monitor.isDragging() };
        // },
    });

    return (
        <div ref={drag} className={classnames(styles.AnimationDnD, className)}>
            <Animation animation={animation} {...otherProps} />
            <HiddenDragPreview ref={previewRef} />
        </div>
    );
}

Animation.propTypes = {
    animation: animationLike.isRequired,
    className: PropTypes.string,
};

Animation.defaultProps = {
    className: '',
};
