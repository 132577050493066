import pixiStore from 'src/pixi/pixiStore';
import setupSpineInstancePlayback from 'src/spine/utils/setupSpineInstancePlayback';

import calcFastForwardAmount from './calcFastForwardAmount';
import flattenAndSortLayerClips from './flattenAndSortLayerClips';
import getLayersWithClipsInRange from './getLayersWithClipsInRange';
import getLayersWithPaddedClips from './getLayersWithPaddedClips';
import getPositionToScheduleLayersFrom from './getPositionToScheduleLayersFrom';

export default function setupTrackLayersForPlayback({
    track,
    foSpineAsset,
    spineAssetInstance,
    time,
    timeWidth,
    globalMixDuration,
    isPreparingForPlayback,
}) {
    const { ticker } = pixiStore.pixiAppManager.getPixiApp();

    spineAssetInstance.state.setEmptyAnimations(0);
    spineAssetInstance.lastTime = Date.now() - 1;
    ticker && ticker.update();

    const timeIndicatorPosition = time * timeWidth;

    const layersWithSortedClips = flattenAndSortLayerClips({ track, timeWidth });

    const layersWithClipsInRange = getLayersWithClipsInRange({
        layersWithSortedClips,
        timeIndicatorPosition,
        timeWidth,
        isPreparingForPlayback,
    });

    const layersWithPaddedClips = getLayersWithPaddedClips({
        layersWithClipsInRange,
        layersWithSortedClips,
        timeWidth,
        globalMixDuration,
    });

    const positionToScheduleLayersFrom = getPositionToScheduleLayersFrom({
        layersWithPaddedClips,
        timeIndicatorPosition,
        timeWidth,
        track,
        globalMixDuration,
    });

    layersWithPaddedClips.forEach(function _setupTrackLayerForPlayback(paddedClips, layerIndex) {
        const trackLayerId = track.layers.allIds[layerIndex];
        const {
            speechTrackId,
            settings: { isDisabled: isLayerDisabled, mixDuration: layerMixDuration },
        } = track.layers.byId[trackLayerId];

        if (!isLayerDisabled) {
            const isFirstLayer = layerIndex === 0;
            const idleAnimationId = isFirstLayer ? foSpineAsset.idleAnimationId : '';

            setupSpineInstancePlayback({
                positionToScheduleLayersFrom,
                spineAssetInstance,
                sortedClips: paddedClips,
                idleAnimationId,
                timeWidth,
                layerAlpha: 1,
                layerIndex,
                isHoldLastFrameForGaps: !!speechTrackId,
                mixDuration: layerMixDuration || globalMixDuration,
            });
        }
    });

    const fastForwardAmount = calcFastForwardAmount({
        timeIndicatorPosition,
        positionToScheduleLayersFrom,
        timeWidth,
    });

    return fastForwardAmount;
}
