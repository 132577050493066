export default class TimeTicker {
    _currentTime = 0;

    get currentTime() {
        return this._currentTime;
    }

    set currentTime(time) {
        this._currentTime = time;
    }

    _requestedFrame = null;

    _lastTime = 0;

    get lastTime() {
        return this._lastTime;
    }

    set lastTime(time) {
        this._lastTime = time;
    }

    _updateTime = () => {
        const now = Date.now();
        if (!this.lastTime) {
            this.lastTime = now;
        }
        const timeSincePreviousTick = now - this.lastTime;
        this.lastTime = Date.now();
        this.currentTime += timeSincePreviousTick / 1000;
    };

    handleTimeTick = () => {
        this._updateTime();
        // tick handlers' calls can be added here later
    };

    _frameRequestHandler = () => {
        this.handleTimeTick();
        this._requestedFrame = requestAnimationFrame(this._frameRequestHandler);
    };

    start() {
        this._requestedFrame = requestAnimationFrame(this._frameRequestHandler);
    }

    stop() {
        cancelAnimationFrame(this._requestedFrame);
        this._requestedFrame = null;
        this._lastTime = 0;
    }

    reset() {
        this.currentTime = 0;
    }
}
