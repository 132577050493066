import { useCallback } from 'react';

import { getAudioTrackTranscriptEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default function useHandleTranscribeButtonClick({
    setIsFetching,
    selectedAudioTrack,
    updateAudioTrackTranscript,
}) {
    return useCallback(
        async function _handleAudioFileChange(event) {
            try {
                event.preventDefault();

                if (!selectedAudioTrack) {
                    return;
                }

                setIsFetching(true);

                const audioTracksTranscriptEndpoint = getAudioTrackTranscriptEndpoint(
                    selectedAudioTrack.id,
                );
                let response;
                if (selectedAudioTrack.hasTranscriptWords) {
                    response = await fetchWithAuth(audioTracksTranscriptEndpoint, {
                        method: 'PUT',
                    });
                } else {
                    response = await fetchWithAuth(audioTracksTranscriptEndpoint, {
                        method: 'POST',
                    });
                }
                if (response.ok) {
                    const foTranscriptWords = await response.json();
                    updateAudioTrackTranscript({
                        audioTrackId: selectedAudioTrack.id,
                        foTranscriptWords,
                    });
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.errors[0].message);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsFetching(false);
            }
        },
        [
            //
            setIsFetching,
            updateAudioTrackTranscript,
            selectedAudioTrack,
        ],
    );
}
