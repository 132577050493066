import pixiStore from 'src/pixi/pixiStore';
import zoomToObject from 'src/pixi/utils/zoomUtils/zoomToObject';
import loadFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/loadFoSpineAsset';
import placeSpineAssetInstanceOnScreen from 'src/spine/utils/placeSpineAssetInstanceOnScreen';
import highlightFoSpineAssetSlotByName from 'src/utils/businessLogic/foSpineAsset/highlightFoSpineAssetSlotByName';
import getSlotContainerBySlotName from 'src/utils/businessLogic/foSpineAsset/getSlotContainerBySlotName';

import greyOutSelectedSlot from './greyOutSelectedSlot';

export default async function setupFoSpineAssetInEditor({ foSpineAsset, selectedSlotName }) {
    const app = pixiStore.pixiAppManager.getPixiApp();
    const zoomContainer = pixiStore.pixiAppManager.getZoomContainer();

    if (!foSpineAsset) {
        return;
    }

    try {
        const spineAssetInstance = await loadFoSpineAsset({
            app,
            foSpineAsset,
            isSkipTextureModifications: true,
        });

        placeSpineAssetInstanceOnScreen({
            spineAssetInstance,
            app,
        });

        zoomContainer.addChild(spineAssetInstance);

        const slotContainer = getSlotContainerBySlotName({
            spineAssetInstance,
            slotName: selectedSlotName,
        });

        if (slotContainer) {
            greyOutSelectedSlot({ spineAssetInstance, selectedSlotName });

            const screenBounds = { x: 0, y: 0, width: app.screen.width, height: app.screen.height };
            zoomToObject({
                zoomContainer,
                focalObject: slotContainer,
                screenBounds,
                paddingPercent: 0.2,
            });
        }

        highlightFoSpineAssetSlotByName({
            spineAssetInstance,
            slotName: selectedSlotName,
            root: app.stage,
        });

        return spineAssetInstance;
    } catch (error) {
        console.log('error: ', error);
    }
}
