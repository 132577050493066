import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import useHandleImageChange from 'src/components/views/TextureRouteManager/TextureEditor/hooks/useHandleImageChange';
import useSetupTextureEditorApp from 'src/components/views/TextureRouteManager/TextureEditor/hooks/useSetupTextureEditorApp';
import useHandleSortEnd from 'src/components/views/TextureRouteManager/TextureEditor/hooks/useHandleSortEnd';
import useLocalFoTextureComp from 'src/components/views/TextureRouteManager/TextureEditor/hooks/useLocalFoTextureComp';
import TextureEditorContext from 'src/components/views/TextureRouteManager/TextureEditor/context/TextureEditorContext';
import { foTextureCompLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import getSelectedTrackFoSpineAsset from 'src/reduxStore/utils/getSelectedTrackFoSpineAsset';
import withRouter from 'src/reduxStore/utils/withRouter';

import PersistenceMenu from './PersistenceMenu';
import SortableFoTextureList from './SortableFoTextureList';
import ToolSelector from './ToolSelector';
import FoTextureCompName from './FoTextureCompName';

import styles from './styles.module.scss';

function TextureEditor(props) {
    const { history, location, match, staticContext, dispatch, ...relevantProps } = props;
    const {
        className,
        existingFoTextureComp,
        foSpineAsset,
        selectedSlotName,
        ...attributes
    } = relevantProps;

    const pixiContainerElemRef = useRef(null);

    const { foTextureComp, updateFoTextures, setFoTextureCompName } = useLocalFoTextureComp({
        existingFoTextureComp,
        initialName: selectedSlotName,
    });

    const [selectedFoTextureId, setSelectedFoTextureId] = useState('');

    useSetupTextureEditorApp({
        setSelectedFoTextureId,
        foTextureComp,
        pixiContainerElemRef,
        foSpineAsset,
        selectedSlotName,
    });

    const handleImageChange = useHandleImageChange({
        foTextures: foTextureComp.foTextures,
        updateFoTextures,
        foTextureCompId: foTextureComp.id,
        selectedSlotName,
        foSpineAssetId: foSpineAsset.id,
    });

    const handleSortEnd = useHandleSortEnd({
        foTextures: foTextureComp.foTextures,
        updateFoTextures,
    });

    const textureEditorContextValue = useMemo(() => {
        return {
            selectedFoTextureId,
            setSelectedFoTextureId,
            foTextureComp,
            updateFoTextures,
        };
    }, [selectedFoTextureId, setSelectedFoTextureId, foTextureComp, updateFoTextures]);

    return (
        <TextureEditorContext.Provider value={textureEditorContextValue}>
            <div className={classnames(styles.TextureEditor, className)} {...attributes}>
                <input type='file' accept='image/*' onChange={handleImageChange} />
                <ToolSelector />
                {/* <img src={textureUrl} alt='texture' /> */}
                <div className={styles.TextureEditor__frameRow}>
                    <div
                        className={styles.TextureEditor__pixiContainerElem}
                        ref={pixiContainerElemRef}
                    />
                    <div className={styles.TextureEditor__rightPanel}>
                        <FoTextureCompName
                            foTextureCompName={foTextureComp.name}
                            setFoTextureCompName={setFoTextureCompName}
                        />
                        <SortableFoTextureList
                            className={styles.TextureEditor__textureList}
                            foTextures={foTextureComp.foTextures}
                            // sortableProps
                            distance={5}
                            lockAxis='y'
                            onSortEnd={handleSortEnd}
                        />
                    </div>
                </div>
                <PersistenceMenu foTextureComp={foTextureComp} />
            </div>
        </TextureEditorContext.Provider>
    );
}

TextureEditor.propTypes = {
    className: PropTypes.string,
    existingFoTextureComp: foTextureCompLike,
    selectedSlotName: PropTypes.string.isRequired,
    foSpineAsset: foSpineAssetLike.isRequired,
};

TextureEditor.defaultProps = {
    className: '',
    existingFoTextureComp: null,
};

function mapState(state, ownProps) {
    const { sceneId: selectedSceneId } = ownProps.router.params;

    const foSpineAsset = getSelectedTrackFoSpineAsset(state, { selectedSceneId });

    const { textureManager, textureEditor } = state;
    const { editingFoTextureCompId, selectedSlotName } = textureEditor;
    const { foTextureComps } = textureManager;
    const existingFoTextureComp = foTextureComps.byId[editingFoTextureCompId];

    return {
        existingFoTextureComp,
        selectedSlotName,
        foSpineAsset,
    };
}

export default withRouter(connect(mapState)(TextureEditor));
