import produce from 'immer';

import calcDragItemPosRelToNode from 'src/utils/dndUtils/calcDragItemPosRelToNode';
import insertClip from 'src/utils/businessLogic/clipUtils/insertClip';
import calcUnzoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcUnzoomedValue';

export default function moveClip({
    monitor,
    clipId,
    clipsById,
    editModes,
    trackNode,
    snapDistance,
    timeWidth,
    zoomLevel = 1,
}) {
    const initialPosition = calcDragItemPosRelToNode({
        monitor,
        domNode: trackNode,
    });
    const initialUnzoomedPosition = calcUnzoomedValue({
        value: initialPosition,
        zoomLevel,
    });

    const clip = clipsById[clipId];
    const newClip = {
        ...clip,
        position: initialUnzoomedPosition,
    };

    const newClipsById = produce(clipsById, (draftClipsById) => {
        delete draftClipsById[clipId];
    });

    const { conflictResolution, trimMode } = editModes;
    const newClipsByIdAfterInsert = insertClip({
        newClip,
        clipsById: newClipsById,
        conflictResolution,
        snapDistance,
        timeWidth,
        trimMode,
    });

    if (newClipsByIdAfterInsert === newClipsById) {
        return clipsById;
    }

    return newClipsByIdAfterInsert;
}
