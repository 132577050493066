import getPivotPercent from 'src/pixi/utils/pivotUtils/getPivotPercent';

export default function calcResize({ target, gridPosition, originalDiff }) {
    const isKnobOnVerticallEdge = gridPosition.x === 0 || gridPosition.x === 1;
    const isKnobOnHorizontalEdge = gridPosition.y === 0 || gridPosition.y === 1;
    const isKnobOnCorner = isKnobOnHorizontalEdge && isKnobOnVerticallEdge;

    const diff = {
        ...originalDiff,
    };

    const pivotPercent = getPivotPercent(target);

    const pivotToEdgeDistance = {
        x: Math.abs(gridPosition.x - pivotPercent.x),
        y: Math.abs(gridPosition.y - pivotPercent.y),
    };
    const scaleSign = {
        x: Math.sign(target.scale.x),
        y: Math.sign(target.scale.y),
    };
    const knobPositionSigns = {
        x: Math.sign(gridPosition.x - pivotPercent.x),
        y: Math.sign(gridPosition.y - pivotPercent.y),
    };
    const scaleAmplifier = {
        x: 1 / pivotToEdgeDistance.x,
        y: 1 / pivotToEdgeDistance.y,
    };

    if (isKnobOnCorner) {
        scaleAmplifier.y = scaleAmplifier.x;
        knobPositionSigns.y = knobPositionSigns.x * scaleSign.x * scaleSign.y;
        const consistencyRatio = Math.abs(target.height) / Math.abs(target.width);
        diff.y = diff.x * consistencyRatio;
    }

    const calculatedDiff = { x: 0, y: 0 };
    if (isKnobOnVerticallEdge) {
        calculatedDiff.x = diff.x * scaleAmplifier.x * knobPositionSigns.x;
    }
    if (isKnobOnHorizontalEdge) {
        calculatedDiff.y = diff.y * scaleAmplifier.y * knobPositionSigns.y;
    }

    return calculatedDiff;
}
