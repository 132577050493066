import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import generateExpressionTimelineKey from 'src/components/views/SceneEditor/Timeline/utils/expressionTimelineKeyUtils/generateExpressionTimelineKey';
import convertExpressionTimelineKeyToClip from 'src/components/views/SceneEditor/Timeline/utils/convertExpressionTimelineKeyToClip';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import insertClip from 'src/utils/businessLogic/clipUtils/insertClip';

export default function useHandleFormSubmit({
    selectedClipId,
    influences,
    trackId,
    trackLayerId,
    timeWidth,
    editModes,
    snapDistance,
    startTime,
    duration,
    onSubmit,
    setClipsById,
    clipsById: receivedClipsById,
    setClipSelection,
}) {
    const { sceneId: selectedSceneId } = useParams();

    return useCallback(
        function _handleFormSubmit(event) {
            event.preventDefault();

            const expressionTimelineKey = generateExpressionTimelineKey({
                id: selectedClipId,
                start: startTime,
                end: startTime + duration,
                influences,
            });
            const clip = convertExpressionTimelineKeyToClip({
                expressionTimelineKey,
                timeWidth,
            });

            const clipsById = { ...receivedClipsById };
            if (selectedClipId) {
                delete clipsById[selectedClipId];
            }
            const { conflictResolution, trimMode, isSnap } = editModes;
            const newClipsById = insertClip({
                newClip: clip,
                clipsById,
                conflictResolution,
                snapDistance: isSnap ? snapDistance : undefined,
                timeWidth,
                trimMode,
            });

            setClipsById({
                sceneId: selectedSceneId,
                trackId,
                trackLayerId,
                newClipsById,
            });

            if (clip.clipId !== selectedClipId) {
                setClipSelection({
                    [trackId]: { [trackLayerId]: [clip.clipId] },
                });
            }

            updateTracksOnBackend({ selectedSceneId });

            if (onSubmit) {
                onSubmit(event);
            }
        },
        [
            startTime,
            duration,
            influences,
            selectedClipId,
            selectedSceneId,
            trackId,
            trackLayerId,
            receivedClipsById,
            setClipsById,
            timeWidth,
            editModes,
            snapDistance,
            onSubmit,
            setClipSelection,
        ],
    );
}
