import { BEZIER_APPROXIMATION_SCALE_LENGTH } from 'src/pixi/tools/MaskTool/constants/maskControls';
import iterateOverBezierCurves from 'src/pixi/tools/MaskTool/utils/iterateOverBezierCurves';

function drawCircleAtPoint({ point, contextProps }) {
    const { graphics, radius } = contextProps;
    graphics.drawCircle(point.x, point.y, radius);
}

const MIN_INTERACTIVE_OUTLINE_WIDTH = 5;

export default function drawBezierOutlineInteractionArea({ graphics, points, width }) {
    const approximationScaleLength = BEZIER_APPROXIMATION_SCALE_LENGTH;
    const approximatetionPointRadius = Math.max(width, MIN_INTERACTIVE_OUTLINE_WIDTH);
    const approximationPointsDistance = approximatetionPointRadius / 2;
    const approximationDensityPerScaleUnit = approximationScaleLength / approximationPointsDistance;

    graphics.clear();
    graphics.beginFill(0xffffff, 0.00001);
    iterateOverBezierCurves({
        points,
        approximationScaleLength,
        approximationDensityPerScaleUnit,
        callback: drawCircleAtPoint,
        contextProps: { radius: approximatetionPointRadius, graphics },
    });
    graphics.endFill();
}
