import React, { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CustomDragLayer from 'src/components/views/SceneEditor/CustomDragLayer';
import useReactToTimelineChange from 'src/components/views/SceneEditor/hooks/useReactToTimelineChange';
import useJumpAppToTime from 'src/components/views/SceneEditor/hooks/useJumpAppToTime';
import useSpineAssetInstancesByTrackId from 'src/components/views/SceneEditor/hooks/useSpineAssetInstancesByTrackId';
import useSceneEditorAudio from 'src/components/views/SceneEditor/hooks/useSceneEditorAudio';
import useCurrentTimeManager from 'src/components/views/SceneEditor/hooks/useCurrentTimeManager';
import usePlaybackControls from 'src/components/views/SceneEditor/hooks/usePlaybackControls';
import useInitializeSceneEditorAssets from 'src/components/views/SceneEditor/hooks/useInitializeSceneEditorAssets';
import { updatingTrackLayersLike } from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';
import { tracksLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import { audioTrackLike } from 'src/components/views/AudioSelector/audioSelectorSlice/propTypes';
import Spinner from 'src/components/shared/Spinner';
import Layover from 'src/components/shared/Layover';
import getFoSpineAssetsForTracks from 'src/reduxStore/utils/getFoSpineAssetsForTracks';
import { foSpineAssetsLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { addAudioTrack } from 'src/components/views/AudioSelector/audioSelectorSlice';
import withRouter from 'src/reduxStore/utils/withRouter';

import Timeline from './Timeline';
import Bins from './Bins';
import Monitor from './Monitor';
import styles from './styles.module.scss';

function SceneEditor(props) {
    const {
        className,
        tracks,
        timeWidth,
        selectedAudioTrack,
        selectedAudioTrackId,
        updatingTracksById,
        foSpineAssets,
        isPixiMounted,
        globalMixDuration,
        addAudioTrack,
    } = props;

    const currentTimeManager = useCurrentTimeManager();

    const isLoadingAnyAssets = useInitializeSceneEditorAssets({
        selectedAudioTrack,
        selectedAudioTrackId,
        addAudioTrack,
    });
    const { waveformData, isLoadingAudio } = useSceneEditorAudio({
        selectedAudioTrack,
    });

    const spineAssetInstancesByTrackId = useSpineAssetInstancesByTrackId();

    const jumpAppToTime = useJumpAppToTime({
        tracks,
        timeWidth,
        selectedAudioTrackId,
        foSpineAssets,
        spineAssetInstancesByTrackId,
        currentTimeManager,
        globalMixDuration,
    });

    const isHoveringMapRef = useRef({});
    const isDroppedRef = useRef(true);
    const lastApprovedTracksByIdRef = useRef(null);

    const {
        handlePlayButtonClick,
        handlePauseButtonClick,
        handleStopButtonClick,
        playbackStatus,
    } = usePlaybackControls({ jumpAppToTime, selectedAudioTrackId, currentTimeManager });

    useReactToTimelineChange({
        isPixiMounted,
        jumpAppToTime,
        updatingTracksById,
        currentTimeManager,
        handlePauseButtonClick,
    });

    const isAnythingLoading = isLoadingAnyAssets || isLoadingAudio;

    const sceneEditorContextValue = useMemo(() => {
        return {
            jumpAppToTime,
            handlePauseButtonClick,
            waveformData,
            currentTimeManager,
            spineAssetInstancesByTrackId,
            isHoveringMapRef,
            isDroppedRef,
            lastApprovedTracksByIdRef,
        };
    }, [
        jumpAppToTime,
        handlePauseButtonClick,
        waveformData,
        currentTimeManager,
        spineAssetInstancesByTrackId,
        isHoveringMapRef,
        isDroppedRef,
        lastApprovedTracksByIdRef,
    ]);

    return (
        <SceneEditorContext.Provider value={sceneEditorContextValue}>
            <DndProvider backend={Backend}>
                <div className={classnames(styles.SceneEditor, className)}>
                    {isAnythingLoading && (
                        <Layover>
                            <Spinner />
                        </Layover>
                    )}
                    <Monitor
                        className={styles.Monitor}
                        jumpAppToTime={jumpAppToTime}
                        foSpineAssets={foSpineAssets}
                        spineAssetInstancesByTrackId={spineAssetInstancesByTrackId}
                        handlePlayButtonClick={handlePlayButtonClick}
                        handlePauseButtonClick={handlePauseButtonClick}
                        handleStopButtonClick={handleStopButtonClick}
                        playbackStatus={playbackStatus}
                    />
                    <Bins className={styles.Bins} />
                    <Timeline
                        className={styles.Timeline}
                        handlePauseButtonClick={handlePauseButtonClick}
                    />
                </div>
                <CustomDragLayer />
            </DndProvider>
        </SceneEditorContext.Provider>
    );
}

SceneEditor.propTypes = {
    className: PropTypes.string,
    tracks: tracksLike.isRequired,
    timeWidth: PropTypes.number.isRequired,
    selectedAudioTrack: audioTrackLike,
    selectedAudioTrackId: PropTypes.string,
    updatingTracksById: updatingTrackLayersLike.isRequired,
    foSpineAssets: foSpineAssetsLike.isRequired,
    isPixiMounted: PropTypes.bool.isRequired,
    globalMixDuration: PropTypes.number.isRequired,
    addAudioTrack: PropTypes.func.isRequired,
};

SceneEditor.defaultProps = {
    className: '',
    selectedAudioTrack: null,
    selectedAudioTrackId: '',
};

function mapState(state, ownProps) {
    const {
        audioSelector: { audioTracks },
        sceneEditor: { isPixiMounted },
        timeline: { timeWidth, updatingTracksById, globalMixDuration },
    } = state;
    const { sceneId: selectedSceneId } = ownProps.router.params;

    const { tracks, selectedAudioTrackId } = state.sceneSelector.scenes.byId[selectedSceneId];

    const selectedAudioTrack = audioTracks.find(
        (audioTrack) => audioTrack.id === selectedAudioTrackId,
    );

    const foSpineAssetsForTracks = getFoSpineAssetsForTracks(state, { selectedSceneId });

    return {
        tracks,
        selectedAudioTrack,
        selectedAudioTrackId,
        timeWidth,
        updatingTracksById,
        foSpineAssets: foSpineAssetsForTracks,
        isPixiMounted,
        globalMixDuration,
    };
}

export default withRouter(connect(mapState, { addAudioTrack })(SceneEditor));
