import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Outlet, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as routes from 'src/components/constants/routes';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import { setUser } from 'src/components/views/UserProfile/userProfileSlice';
import useHandleSignoutClick from 'src/components/App/NavBar/hooks/useHandleSignoutClick';

import styles from './styles.module.scss';

function NavBar(props) {
    const { className, username, dispatch, setUser, ...attributes } = props;

    const handleSignoutClick = useHandleSignoutClick({ setUser });

    let leftGroupElems = null;
    let rightGroupElems = (
        <>
            <NavLink to={routes.SIGN_UP}>
                {({ isActive }) => {
                    return <ToolButton isActive={isActive}>Sign Up</ToolButton>;
                }}
            </NavLink>
            <NavLink to={routes.SIGN_IN}>
                {({ isActive }) => {
                    return <ToolButton isActive={isActive}>Sign In</ToolButton>;
                }}
            </NavLink>
        </>
    );

    if (username) {
        leftGroupElems = <span className={styles.NavBar__greeting}>Hello {username}</span>;
        rightGroupElems = (
            <>
                <NavLink to={routes.SCENES}>
                    {({ isActive }) => {
                        return <ToolButton isActive={isActive}>Editor</ToolButton>;
                    }}
                </NavLink>
                <NavLink to={routes.USER_PROFILE}>
                    {({ isActive }) => {
                        return <ToolButton isActive={isActive}>Profile</ToolButton>;
                    }}
                </NavLink>
                <ToolButton onClick={handleSignoutClick}>Sign Out</ToolButton>
            </>
        );
    }

    return (
        <>
            <nav className={classnames(styles.NavBar, className)} {...attributes}>
                <div className={classnames(styles.NavBar__leftGroup, styles.NavBar__elemGroup)}>
                    {leftGroupElems}
                </div>
                <div className={classnames(styles.NavBar__rightGroup, styles.NavBar__elemGroup)}>
                    {rightGroupElems}
                </div>
            </nav>
            <Outlet />
        </>
    );
}

NavBar.propTypes = {
    className: PropTypes.string,
    username: PropTypes.string,
    setUser: PropTypes.func.isRequired,
};

NavBar.defaultProps = {
    className: '',
    username: '',
};

function mapState(state) {
    const { userData } = state.userProfile;

    return { username: userData?.username };
}
export default connect(mapState, { setUser })(NavBar);
