import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import CloneButton from './CloneButton';
import DeleteButton from './DeleteButton';

import styles from './styles.module.scss';

export default function Buttons(props) {
    const {
        className,
        onFoTextureSelectionChange,
        foTextureId,
        toggleRenameMode,
        isEditingName,
        ...attributes
    } = props;

    return (
        <div className={classnames(styles.Buttons, className)} {...attributes}>
            <CloneButton
                foTextureId={foTextureId}
                onFoTextureSelectionChange={onFoTextureSelectionChange}
            />
            <DeleteButton foTextureId={foTextureId} />
            <ToolButton onClick={toggleRenameMode} isActive={isEditingName}>
                rename
            </ToolButton>
        </div>
    );
}

Buttons.propTypes = {
    className: PropTypes.string,
    foTextureId: PropTypes.string.isRequired,
    onFoTextureSelectionChange: PropTypes.func.isRequired,
    toggleRenameMode: PropTypes.func.isRequired,
    isEditingName: PropTypes.bool.isRequired,
};

Buttons.defaultProps = {
    className: '',
};
