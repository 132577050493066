import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Form from 'src/components/shared/formFactory/Form';

import './styles.scss';

export default function StyledForm(props) {
    const { className, headerContent, body, children, ...attributes } = props;

    return (
        <Form className={classnames('StyledForm', className)} {...attributes}>
            {headerContent && <h1 className='StyledForm__header'>{headerContent}</h1>}
            {children && <div className='StyledForm__body'>{children}</div>}
        </Form>
    );
}

StyledForm.propTypes = {
    className: PropTypes.string,
    headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

StyledForm.defaultProps = {
    className: '',
    headerContent: null,
};
