import { useCallback } from 'react';

import assignFoTextureCompToSlot from 'src/utils/businessLogic/foSpineAsset/assignFoTextureCompToSlot';

export default function useHandleLabelClick({
    foTextureComp,
    isSelected,
    selectedSlotName,
    foSpineAsset,
    setFoSpineAssetTextureModifications,
    setFoSpineAssetLinkOnFoTextureComp,
}) {
    return useCallback(
        function _handleLabelClick() {
            if (!isSelected && selectedSlotName) {
                assignFoTextureCompToSlot({
                    foTextureComp,
                    slotName: selectedSlotName,
                    foSpineAsset,
                    setFoSpineAssetTextureModifications,
                    setFoSpineAssetLinkOnFoTextureComp,
                });
            }
        },
        [
            foTextureComp,
            isSelected,
            selectedSlotName,
            foSpineAsset,
            setFoSpineAssetTextureModifications,
            setFoSpineAssetLinkOnFoTextureComp,
        ],
    );
}
