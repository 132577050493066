export default function destroyPointControl(pointControl) {
    delete pointControl.mainKnob.maskTool;
    pointControl.mainKnob.destroy();
    delete pointControl.mainKnob;

    delete pointControl.tangent1Knob.maskTool;
    delete pointControl.tangent1Knob.handleName;
    pointControl.tangent1Knob.destroy();
    delete pointControl.tangent1Knob;

    pointControl.line1.destroy();
    delete pointControl.line1;

    delete pointControl.tangent2Knob.maskTool;
    delete pointControl.tangent2Knob.handleName;
    pointControl.tangent2Knob.destroy();
    delete pointControl.tangent2Knob;

    pointControl.line2.destroy();
    delete pointControl.line2;
}
