import loadFoTexture from 'src/utils/businessLogic/foTexture/loadFoTexture';

import FoTextureCompDisplayObject from './FoTextureCompDisplayObject';

export default async function loadFoTextureComp({ loader, foTextureComp }) {
    const { id, foTextures } = foTextureComp;

    const foTextureDisplayObjects = await Promise.all(
        foTextures.map((foTexture) => {
            return loadFoTexture({ loader, foTexture });
        }),
    );

    const compDisplayObject = new FoTextureCompDisplayObject({ id });

    foTextureDisplayObjects.forEach((foTextureDisplayObject) => {
        compDisplayObject.addChild(foTextureDisplayObject);
    });

    return compDisplayObject;
}
