import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ButtonWithMenu from 'src/components/shared/ButtonWithMenu';
import ToolIcon from 'src/components/shared/iconFactory/ToolIcon';
import TrashCanIcon from 'src/components/shared/iconFactory/TrashCanIcon';
import useHandleClearButtonClick from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackExpressionLayerController/TrackExpressionLayerControllerButtonWithMenu/hooks/useHandleClearButtonClick';

import styles from './styles.module.scss';

export default function TrackExpressionLayerControllerButtonWithMenu(props) {
    const { trackId, trackLayerId, className, setClipsById } = props;

    const handleClearButtonClick = useHandleClearButtonClick({
        trackId,
        trackLayerId,
        setClipsById,
    });

    const listData = [
        {
            key: 'clear_layer',
            content: (
                <>
                    <TrashCanIcon /> Clear
                </>
            ),
            contentElemAttributes: {
                onClick: handleClearButtonClick,
            },
        },
    ];

    return (
        <ButtonWithMenu
            buttonProps={{
                className: classnames(
                    className,
                    'ToolContainer__tool',
                    styles.TrackExpressionLayerControllerButtonWithMenu,
                ),
                children: <ToolIcon />,
            }}
            nestedListProps={{
                listData,
            }}
        />
    );
}

TrackExpressionLayerControllerButtonWithMenu.propTypes = {
    className: PropTypes.string,
    trackId: PropTypes.string.isRequired,
    trackLayerId: PropTypes.string.isRequired,
    setClipsById: PropTypes.func.isRequired,
};

TrackExpressionLayerControllerButtonWithMenu.defaultProps = {
    className: '',
};
