import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import classnames from 'classnames';

import dndTypes from 'src/components/views/SceneEditor/constants/dndTypes';
import HiddenDragPreview from 'src/components/shared/HiddenDragPreview';

import ClipResizer from './ClipResizer';

import styles from './styles.module.scss';

export default function ClipResizerDND(props) {
    const { className, clipId, type, style, ...attributes } = props;

    const [, drag, previewRef] = useDrag({
        item: { type, clipId },
        // collect(monitor) {
        //     return { isDragging: monitor.isDragging() };
        // },
        // begin() {
        //     console.log('dragging reizer');
        // },
    });

    return (
        <div ref={drag} className={classnames(styles.ClipResizerDND, className)} style={style}>
            <HiddenDragPreview ref={previewRef} />
            <ClipResizer {...attributes} />
        </div>
    );
}

ClipResizerDND.propTypes = {
    className: PropTypes.string,
    clipId: PropTypes.string.isRequired,
    type: PropTypes.oneOf([dndTypes.CLIP_HEAD, dndTypes.CLIP_TAIL]).isRequired,
};

ClipResizerDND.defaultProps = {
    className: '',
};
