export default function fetchWithAuth(url, { headers, ...otherConfigs } = {}) {
    const token = localStorage.getItem('token');

    return fetch(url, {
        headers: {
            'x-auth': token,
            ...(headers || {}),
        },
        ...otherConfigs,
    });
}
