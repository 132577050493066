import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from 'src/components/constants/routes';

import fetchSceneUpdate from './fetchSceneUpdate';
import fetchCreateScene from './fetchCreateScene';

export default function useHandleSubmitSceneEditorForm({
    trackFoSpineAsset,
    setScene,
    setIsLoading,
    sceneId,
    closeEditSceneForm,
    sceneName,
}) {
    const navigate = useNavigate();

    return useCallback(
        async function _handleSubmitSceneEditorForm(event) {
            try {
                event.preventDefault();

                setIsLoading(true);

                if (sceneId) {
                    const fetchedScene = await fetchSceneUpdate({
                        sceneId,
                        updateProps: { name: sceneName },
                    });
                    setScene(fetchedScene);
                } else {
                    const fetchedScene = await fetchCreateScene({
                        trackFoSpineAsset,
                        sceneName,
                    });
                    setScene(fetchedScene);
                    navigate(`/${routes.SCENES}/${fetchedScene.id}`);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false);
                closeEditSceneForm();
            }
        },
        [
            setScene,
            navigate,
            trackFoSpineAsset,
            setIsLoading,
            sceneId,
            closeEditSceneForm,
            sceneName,
        ],
    );
}
