import pixiStore from 'src/pixi/pixiStore';
import loadFoTextureComp from 'src/utils/businessLogic/foTextureComp/loadFoTextureComp';
import placeFoTextureCompDisplayObjectRelativeToSlot from 'src/utils/businessLogic/foSpineAsset/placeFoTextureCompDisplayObjectRelativeToSlot';
import getTextureModificationAtomCurrentlyAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getTextureModificationAtomCurrentlyAssignedToSlot';
import { FO_TEXTURE_COMP_Z_INDEX } from 'src/components/views/TextureRouteManager/TextureEditor/constants/foTexture';

import makeFoTexturesSelectable from './makeFoTexturesSelectable';

export default async function setupFoTextureCompInEditor({
    foSpineAsset,
    foTextureComp,
    spineAssetInstance,
    selectedSlotName,
}) {
    const app = pixiStore.pixiAppManager.getPixiApp();

    try {
        const foTextureCompDisplayObject = await loadFoTextureComp({
            loader: app.loader,
            foTextureComp,
        });
        foTextureCompDisplayObject.zIndex = FO_TEXTURE_COMP_Z_INDEX;

        makeFoTexturesSelectable({ foTextureComp, foTextureCompDisplayObject });

        const zoomContainer = pixiStore.pixiAppManager.getZoomContainer();
        zoomContainer.addChild(foTextureCompDisplayObject);

        const textureModificationAtom = getTextureModificationAtomCurrentlyAssignedToSlot({
            foSpineAsset,
            slotName: selectedSlotName,
        });
        const isNewFoTextureComp =
            !textureModificationAtom ||
            textureModificationAtom.foTextureCompId !== foTextureComp.id;
        if (!isNewFoTextureComp) {
            placeFoTextureCompDisplayObjectRelativeToSlot({
                foTextureCompDisplayObject,
                spineAssetInstance,
                textureModificationAtom,
            });
        }
    } catch (err) {
        console.log('error: ', err);
    }
}
