import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PauseToolButton from 'src/components/shared/buttonFactory/PauseToolButton';
import PlayToolButton from 'src/components/shared/buttonFactory/PlayToolButton';

import styles from './styles.module.scss';

export default function PlaybackButton(props) {
    const { className, isPlaying, handlePlayButtonClick, ...otherProps } = props;

    if (isPlaying) {
        return (
            <PauseToolButton
                className={classnames(styles.PlaybackButton, className)}
                onClick={handlePlayButtonClick}
                isActive
                {...otherProps}
            />
        );
    }

    return (
        <PlayToolButton
            className={classnames(styles.PlaybackButton, className)}
            onClick={handlePlayButtonClick}
            {...otherProps}
        />
    );
}

PlaybackButton.propTypes = {
    ...PlayToolButton.propTypes,
    className: PropTypes.string,
    isPlaying: PropTypes.bool.isRequired,
};

PlaybackButton.defaultProps = {
    className: '',
};
