export default function findBlinkingAnimationName(expressionsByAnimation) {
    const blinkingAnims = Object.keys(expressionsByAnimation).filter((animationName) => {
        const { isBlinking } = expressionsByAnimation[animationName];
        return isBlinking;
    });

    const blinkingAnimationName = blinkingAnims[0];

    if (blinkingAnims.length === 0) {
        console.warn('expressionsByAnimation does not have an animation flagged as blinking.');
    } else if (blinkingAnims.length > 1) {
        console.warn(
            'expressionsByAnimation has more than one animation flagged as blinking:\n',
            blinkingAnims.join(',\n'),
        );
    }

    return blinkingAnimationName;
}
