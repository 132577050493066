import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';

export default function resolveRightConflicSubmissively({
    newClip,
    rightNeighbour,
    timeWidth,
    trimMode,
}) {
    const newClipEnd = getClipEnd({ clip: newClip, timeWidth });
    const newClipWidth = getClipWidth({ clipData: newClip.data, timeWidth });

    const rightOverlap = Math.max(newClipEnd - rightNeighbour.position, 0);
    const isRightNeighbourFullyCoveringNewClip = rightOverlap >= newClipWidth;

    if (isRightNeighbourFullyCoveringNewClip) {
        return null;
    }

    const adjustedNewClipTrimmedData = trimClipData({
        tailTrimAmount: -rightOverlap,
        clipData: newClip.data,
        trimMode,
        timeWidth,
    });
    const adjustedNewClip = {
        ...newClip,
        data: adjustedNewClipTrimmedData,
    };

    return adjustedNewClip;
}
