import { calcAngleRadBetween2PointsAndAxisX } from 'src/utils/mathUtils/geoMathUtils';
import {
    getPreviousItemInLoopedIteration,
    getNextItemInLoopedIteration,
} from 'src/utils/arrayUtils';

export default function calcNeighborPointsAngleWithAxisX({ points, point }) {
    const pointIndex = points.findIndex((currentPoint) => currentPoint.id === point.id);

    const previousPoint = getPreviousItemInLoopedIteration({
        array: points,
        currentIndex: pointIndex,
    });

    const nextPoint = getNextItemInLoopedIteration({
        array: points,
        currentIndex: pointIndex,
    });

    const neighbourPointsAngleWithAxisX = calcAngleRadBetween2PointsAndAxisX({
        point1: previousPoint.main,
        point2: nextPoint.main,
    });

    return neighbourPointsAngleWithAxisX;
}
