import getPivotPercent from 'src/pixi/utils/pivotUtils/getPivotPercent';
import getGlobalScale from 'src/pixi/utils/getGlobalScale';

export default function positionResizeKnobs(transformTool) {
    const { target } = transformTool;

    const pivotPercent = getPivotPercent(target);
    const targetGlobalScale = getGlobalScale(target);

    Object.values(transformTool.resizeKnobs).forEach((resizeKnob) => {
        const knobToPivotDistancePercent = {
            x: resizeKnob.gridPosition.x - pivotPercent.x,
            y: resizeKnob.gridPosition.y - pivotPercent.y,
        };
        const pivotToKnobDistance = {
            x: knobToPivotDistancePercent.x * target.originalSize.width * targetGlobalScale.x,
            y: knobToPivotDistancePercent.y * target.originalSize.height * targetGlobalScale.y,
        };
        resizeKnob.position.set(pivotToKnobDistance.x, pivotToKnobDistance.y);
    });
}
