import calcClipSelectionForLayers from './calcClipSelectionForLayers';

export default function calcClipSelectionWithNewClips({
    currentClipSelectionByTrackId: currentSelectionObj,
    newClipSelectionByTrackId: newSelectionObj,
    isShiftKeyDown,
}) {
    if (!isShiftKeyDown) {
        return newSelectionObj;
    }

    const trackIds = Object.keys(newSelectionObj);
    const calculatedClipSelectionByTrackId = trackIds.reduce(
        function _calcCurrentTrackSelectionsMerger(
            calculatedClipSelectionByTrackId,
            currentTrackId,
        ) {
            const currentClipSelectionOnTrackByLayerId = currentSelectionObj[currentTrackId] || {};
            const newClipSelectionOnTrackByLayerId = newSelectionObj[currentTrackId] || {};

            const calculatedClipSelectionOnTrackByLayerId = calcClipSelectionForLayers({
                newClipSelectionOnTrackByLayerId,
                currentClipSelectionOnTrackByLayerId,
            });

            const newCalculatedClipSelectionByTrackId = {
                ...calculatedClipSelectionByTrackId,
                [currentTrackId]: calculatedClipSelectionOnTrackByLayerId,
            };

            const trackLayersWithSelection = Object.keys(calculatedClipSelectionOnTrackByLayerId);
            const isCurrentTrackSelectionEmpty = !trackLayersWithSelection.length;
            if (isCurrentTrackSelectionEmpty) {
                delete newCalculatedClipSelectionByTrackId[currentTrackId];
            }

            return newCalculatedClipSelectionByTrackId;
        },
        {},
    );

    return calculatedClipSelectionByTrackId;
}
