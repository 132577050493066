export default function buildPointsRelToRoot({
    verticesRelToSpineAssetInstance,
    spineAssetInstance,
    root,
}) {
    const pointsRelToRoot = [];
    for (let xIndex = 0; xIndex < verticesRelToSpineAssetInstance.length - 1; xIndex += 2) {
        const x = verticesRelToSpineAssetInstance[xIndex];
        const y = verticesRelToSpineAssetInstance[xIndex + 1];
        const pointGlobalPosition = spineAssetInstance.toGlobal({ x, y });
        const pointRelToRoot = root.toLocal(pointGlobalPosition);
        pointsRelToRoot.push(pointRelToRoot);
    }
    return pointsRelToRoot;
}
