import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import ToolContainer from 'src/components/shared/ToolContainer';
import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';

import styles from './styles.module.scss';

export default function AssetTrackModes(props) {
    const { className, activeAssetTrackMode, setAssetTrackMode, ...attributes } = props;

    return (
        <ToolContainer
            className={classnames(styles.AssetTrackModes, className)}
            isSubContainer
            {...attributes}
        >
            {Object.values(assetTrackModes).map((assetTrackMode) => {
                const isCurrentActive = activeAssetTrackMode === assetTrackMode;
                return (
                    <ToolButton
                        key={assetTrackMode}
                        isActive={isCurrentActive}
                        onClick={() => setAssetTrackMode(assetTrackMode)}
                        className='ToolContainer__tool'
                    >
                        {assetTrackMode}
                    </ToolButton>
                );
            })}
        </ToolContainer>
    );
}

AssetTrackModes.propTypes = {
    className: PropTypes.string,
    activeAssetTrackMode: PropTypes.oneOf([...Object.values(assetTrackModes)]).isRequired,
    setAssetTrackMode: PropTypes.func.isRequired,
};

AssetTrackModes.defaultProps = {
    className: '',
};
