import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { setScene } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import LoadingToolButton from 'src/components/shared/buttonFactory/LoadingToolButton';
import CloneIcon from 'src/components/shared/iconFactory/CloneIcon';
import useHandleCloneSceneButtonClick from 'src/components/views/SceneSelector/SceneSelectorListItem/CloneSceneButton/hooks/useHandleCloneSceneButtonClick';

import styles from './styles.module.scss';

function CloneSceneButton(props) {
    const { className, sceneId, setScene } = props;

    const [isLoading, setIsLoading] = useState(false);

    const handleCreateNewSceneButtonClick = useHandleCloneSceneButtonClick({
        setScene,
        setIsLoading,
        sceneId,
    });

    return (
        <LoadingToolButton
            className={classnames(styles.CloneSceneButton, className)}
            title='Clone scene'
            onClick={handleCreateNewSceneButtonClick}
            isLoading={isLoading}
        >
            <CloneIcon />
        </LoadingToolButton>
    );
}

CloneSceneButton.propTypes = {
    className: PropTypes.string,
    setScene: PropTypes.func.isRequired,
    sceneId: PropTypes.string.isRequired,
};

CloneSceneButton.defaultProps = {
    className: '',
};

export default connect(undefined, { setScene })(CloneSceneButton);
