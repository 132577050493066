export default function calcFastForwardAmount({
    timeIndicatorPosition,
    positionToScheduleLayersFrom,
    timeWidth,
}) {
    const fastForwardWidth = timeIndicatorPosition - positionToScheduleLayersFrom;
    const fastForwardAmount = fastForwardWidth / timeWidth;

    return fastForwardAmount;
}
