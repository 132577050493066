export default function getSelectedTrackFoSpineAsset(state, { selectedSceneId }) {
    const { assetSelector, sceneSelector, sceneEditor } = state;
    const { foSpineAssets } = assetSelector;
    const { selectedFoSpineAssetTrackId } = sceneEditor;
    const { tracks } = sceneSelector.scenes.byId[selectedSceneId];

    const selectedTrack = tracks.byId[selectedFoSpineAssetTrackId];
    const { id: foSpineAssetId } = selectedTrack.foSpineAsset;
    const foSpineAsset = foSpineAssets.byId[foSpineAssetId];

    return foSpineAsset;
}
