import React, { useCallback, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'src/components/shared/buttonFactory/Button';

import './styles.scss';

const refireInterval = 200;

export default function ToolButton(props) {
    const { className, onMouseDown, onMouseUp, isHoldable, ...attributes } = props;

    const isMouseStillDownRef = useRef(false);

    const handleMouseDown = useCallback(
        function _handleMouseDown(event) {
            if (onMouseDown) {
                onMouseDown(event);
            }
            isMouseStillDownRef.current = true;
            if (isHoldable) {
                setTimeout(function _refireMouseDown() {
                    if (isMouseStillDownRef.current && onMouseDown) {
                        onMouseDown(event);
                        setTimeout(_refireMouseDown, refireInterval);
                    }
                }, refireInterval);
            }
        },
        [isHoldable, isMouseStillDownRef, onMouseDown],
    );

    const handleMouseUp = useCallback(
        function _handleMouseUp(event) {
            if (onMouseUp) {
                onMouseUp(event);
            }
            isMouseStillDownRef.current = false;
        },
        [onMouseUp, isMouseStillDownRef],
    );

    return (
        <Button
            className={classnames('ToolButton', className)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            {...attributes}
        />
    );
}

ToolButton.propTypes = {
    ...Button.propTypes,
    className: PropTypes.string,
    onMouseDown: PropTypes.func,
    isHoldable: PropTypes.bool,
};

ToolButton.defaultProps = {
    ...Button.defaultProps,
    className: '',
    onMouseDown: null,
    isHoldable: false,
};
