import { createSlice } from '@reduxjs/toolkit';

import ashotFoTextureComp1 from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/mockData/ashotFoTextureComp1';
import vahramFoTextureComp1 from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/mockData/vahramFoTextureComp1';

const initialState = {
    foTextureComps: {
        byId: {
            [ashotFoTextureComp1.id]: ashotFoTextureComp1,
            [vahramFoTextureComp1.id]: vahramFoTextureComp1,
        },
        allIds: [vahramFoTextureComp1.id, ashotFoTextureComp1.id],
    },
};

const textureManagerSlice = createSlice({
    name: 'textureManager',
    initialState,
    reducers: {
        setEditingFoSpineAssetId(state, action) {
            state.editingFoSpineAssetId = action.payload;
        },
        setFoTextureComp(state, action) {
            const foTextureComp = action.payload;
            const { id } = foTextureComp;
            const { foTextureComps } = state;

            foTextureComps.byId[id] = foTextureComp;

            const isAlreadyExisting = foTextureComps.allIds.some((currentId) => {
                return currentId === id;
            });
            if (!isAlreadyExisting) {
                foTextureComps.allIds.push(id);
            }
        },
        setFoSpineAssetLinkOnFoTextureComp(state, action) {
            const { foTextureCompId, foSpineAssetId } = action.payload;
            const foTextureComp = state.foTextureComps.byId[foTextureCompId];
            const isLinkFound = foTextureComp.linkedFoSpineAssetIds.some((currFoSpineAssetId) => {
                return foSpineAssetId === currFoSpineAssetId;
            });
            if (isLinkFound) {
                return;
            }
            foTextureComp.linkedFoSpineAssetIds.push(foSpineAssetId);
        },
        removeFoTextureCompFromStore(state, action) {
            const { foTextureComps } = state;
            const foTextureCompId = action.payload;
            const foTexture = foTextureComps.byId[foTextureCompId];
            if (foTexture) {
                delete foTextureComps.byId[foTextureCompId];
                const filteredIds = foTextureComps.allIds.filter((id) => {
                    return id !== foTextureCompId;
                });
                foTextureComps.allIds = filteredIds;
            }
        },
    },
});

export const {
    setEditingFoSpineAssetId,
    setFoTextureComp,
    removeFoTextureCompFromStore,
    setFoSpineAssetLinkOnFoTextureComp,
} = textureManagerSlice.actions;

export default textureManagerSlice.reducer;
