import zoomToObject from 'src/pixi/utils/zoomUtils/zoomToObject';

export default function fitDisplayObjectIntoBounds({
    displayObject,
    bounds,
    paddingPercent = 0.1,
    isShrinkOnly = false,
}) {
    zoomToObject({
        zoomContainer: displayObject,
        focalObject: displayObject,
        screenBounds: bounds,
        paddingPercent,
        isShrinkOnly,
    });
}
