export default function calcTotalRank({ collection, rankPropName = 'rank' }) {
    const totalRank = Object.keys(collection).reduce((totalRank, itemKey) => {
        const item = collection[itemKey];
        const rankValue = item[rankPropName];
        const rank = Number(rankValue);

        if (Number.isNaN(rank)) {
            console.error(
                `${rankPropName} at key: ${itemKey} cannot be converted to a number. Please review it:`,
                rankValue,
            );
            return totalRank;
        }

        return totalRank + rank;
    }, 0);

    return totalRank;
}
