import getDisplayObjectGlobalCenter from 'src/pixi/utils/getDisplayObjectGlobalCenter';
import movePivotToGlobalPositionWithoutMovingParent from 'src/pixi/utils/pivotUtils/movePivotToGlobalPositionWithoutMovingParent';

export default function centerFocalObjectInScreenCenter({
    zoomContainer,
    focalObject,
    screenBounds,
}) {
    const focalPoint = getDisplayObjectGlobalCenter(focalObject);
    movePivotToGlobalPositionWithoutMovingParent({
        displayObject: zoomContainer,
        position: focalPoint,
    });

    const destinationPoint = {
        x: screenBounds.x + screenBounds.width / 2,
        y: screenBounds.y + screenBounds.height / 2,
    };
    const destinationPointRelToZoomContainerParent = zoomContainer.parent.toLocal(destinationPoint);
    zoomContainer.position.set(
        destinationPointRelToZoomContainerParent.x,
        destinationPointRelToZoomContainerParent.y,
    );
}
