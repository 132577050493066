import pixiStore from 'src/pixi/pixiStore';
import getChildById from 'src/pixi/utils/getChildById';
import { FO_TEXTURE_BASE_Z_INDEX } from 'src/components/views/TextureRouteManager/TextureEditor/constants/foTexture';

export default function updateFoTexturesZIndices(foTextures) {
    const newFoTextures = foTextures.map((foTexture, currentIndex) => {
        const app = pixiStore.pixiAppManager.getPixiApp();

        const reverseIndex = foTextures.length - 1 - currentIndex;

        const displayObject = getChildById({ parent: app.stage, id: foTexture.id });
        displayObject.zIndex = FO_TEXTURE_BASE_Z_INDEX + reverseIndex;

        return { ...foTexture, zIndex: displayObject.zIndex };
    });

    return newFoTextures;
}
