import loadFoTextureComp from 'src/utils/businessLogic/foTextureComp/loadFoTextureComp';
import fitDisplayObjectIntoBounds from 'src/pixi/utils/fitDisplayObjectIntoBounds';
import getSlotContainerBySlotName from 'src/utils/businessLogic/foSpineAsset/getSlotContainerBySlotName';
import getChildById from 'src/pixi/utils/getChildById';
import buildRelativeTransform from 'src/pixi/utils/buildRelativeTransform';
import pixiStore from 'src/pixi/pixiStore';
import destroyFoTextureCompDisplayObject from 'src/utils/businessLogic/foTextureComp/destroyFoTextureCompDisplayObject';

export default async function buildTextureModificationAtomThatFitsIntoSlot({
    foTextureComp,
    slotName,
    foSpineAsset,
}) {
    const app = pixiStore.pixiAppManager.getPixiApp();
    const foTextureCompDisplayObject = await loadFoTextureComp({
        loader: app.loader,
        foTextureComp,
    });

    app.stage.addChild(foTextureCompDisplayObject);
    const spineAssetInstance = getChildById({
        parent: app.stage,
        id: foSpineAsset.id,
    });
    const slotContainer = getSlotContainerBySlotName({
        spineAssetInstance,
        slotName,
    });

    fitDisplayObjectIntoBounds({
        displayObject: foTextureCompDisplayObject,
        bounds: slotContainer.getBounds(),
        paddingPercent: 0,
    });

    const relativeTransform = buildRelativeTransform({
        displayObject: foTextureCompDisplayObject,
        refDisplayObject: slotContainer,
    });

    destroyFoTextureCompDisplayObject({
        foTextureComp,
        displayObject: foTextureCompDisplayObject,
    });

    const textureModificationAtom = {
        slotName,
        foTextureCompId: foTextureComp.id,
        relativeTransform,
    };

    return textureModificationAtom;
}
