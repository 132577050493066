export default function selectClipsByIdsBySpeechAudioTrackIds({ tracks }) {
    const clipsByIdsBySpeechAudioTrackId = {};
    tracks.allIds.forEach(function _traverseTracks(trackId) {
        const track = tracks.byId[trackId];
        track.layers.allIds.forEach(function _selectEnabledSpeechTrack(trackLayerId) {
            const trackLayer = track.layers.byId[trackLayerId];
            const { speechTrackId, clipsById } = trackLayer;

            if (speechTrackId) {
                clipsByIdsBySpeechAudioTrackId[speechTrackId] = clipsById;
            }
        });
    });

    return clipsByIdsBySpeechAudioTrackId;
}
