import getSingleClipDuration from 'src/utils/businessLogic/clipUtils/getSingleClipDuration';
import getLastSpineTrackEntry from 'src/spine/utils/getLastSpineTrackEntry';

import addAnimation from './addAnimation';
import getMatchingInstanceAnimation from './getMatchingInstanceAnimation';

/**
 * @isHoldLastFrameForGaps bool: determines if over the gap between two clips,
 * the last frame of the first animation should be held or reset to setup pose.
 * Applies only if spineAssetInstance is missing animation,
 * e.g. can happens when foSpineAsset doesn't have idle animation.
 */
export default function scheduleAnimationsAndGaps({
    sortedClipsWithIdle,
    spineInstanceAnimations,
    isLoop,
    mixDuration,
    spineAssetInstance,
    layerAlpha,
    layerIndex,
    isHoldLastFrameForGaps = false,
}) {
    let delay = 0;
    let lastClipTimescale = 1;
    let lastClipDuration = 0;

    sortedClipsWithIdle.forEach((clip, index) => {
        const instanceAnimation = getMatchingInstanceAnimation({
            clip,
            spineInstanceAnimations,
        });

        const isFirst = index === 0;

        if (instanceAnimation) {
            const { animationStart, animationEnd, timeScale = 1 } = clip.data;
            const trimmedDuration = animationEnd - animationStart;
            lastClipDuration = trimmedDuration;

            // todo - when leftMixDuration & rightMixDuration are implimented,
            // this calculation will need to account for leftMixDuration,
            // which will be used as negative delay.
            // Try:
            // timeScale = trimmedDuration / (clipDurationOnTimeline + mixDuration);
            lastClipTimescale = timeScale;
            addAnimation({
                spineAssetInstance,
                clip,
                isLoop,
                delay,
                timeScale,
                mixDuration,
                isFirst,
                alpha: layerAlpha,
                layerIndex,
            });
            delay = 0;
        } else {
            const clipDurationOnTimeline = getSingleClipDuration({
                clipData: clip.data,
            });
            delay += clipDurationOnTimeline;

            const nextClipInstanceAnimation = getMatchingInstanceAnimation({
                clip: sortedClipsWithIdle[index + 1],
                spineInstanceAnimations,
            });

            const isNextClipPlayable = !!nextClipInstanceAnimation;

            if (isHoldLastFrameForGaps && isNextClipPlayable) {
                delay = delay * lastClipTimescale + lastClipDuration;
            }

            if (isFirst) {
                spineAssetInstance.state.setEmptyAnimation(layerIndex, 0);
            } else if (!isHoldLastFrameForGaps) {
                const spineTrack = spineAssetInstance.state.tracks[layerIndex];
                const lastSpineTrackEntry = getLastSpineTrackEntry(spineTrack);
                const isLastTrackEntryEmpty =
                    lastSpineTrackEntry.animation === PIXI.spine.core.AnimationState.emptyAnimation;

                if (!isLastTrackEntryEmpty) {
                    spineAssetInstance.state.addEmptyAnimation(
                        layerIndex,
                        mixDuration,
                        0,
                        lastClipTimescale,
                    );
                    delay += mixDuration;
                }
            }
        }
    });

    // reset spineAssetInstancePose
    if (sortedClipsWithIdle.length > 0) {
        spineAssetInstance.state.addEmptyAnimation(layerIndex, mixDuration, 0, lastClipTimescale);
    } else {
        spineAssetInstance.state.setEmptyAnimation(layerIndex, 0);
    }
}
