import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Line from 'src/components/shared/Line';
import useRenameModeManager from 'src/components/views/AssetSelector/FoSpineAssetList/FoSpineAssetListItem/hooks/useRenameModeManager';
import useHandleLabelClick from 'src/components/views/AssetSelector/FoSpineAssetList/FoSpineAssetListItem/hooks/useHandleLabelClick';
import useHandleNameInputChange from 'src/components/views/AssetSelector/FoSpineAssetList/FoSpineAssetListItem/hooks/useHandleNameInputChange';
import EditableTextBox from 'src/components/shared/EditableTextBox';
import SelectableListItem from 'src/components/shared/SelectableListItem';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { accessTypes } from 'src/components/constants/foSpineAsset';

import Buttons from './Buttons';

import styles from './styles.module.scss';

export default function FoSpineAssetListItem(props) {
    const {
        className,
        foSpineAsset,
        selectedFoSpineAssetId,
        setFoSpineAsset,
        setFoSpineAssetOnTrack,
        ...attributes
    } = props;

    const { name } = foSpineAsset;

    const isSelected = foSpineAsset.id === selectedFoSpineAssetId;
    const { toggleRenameMode, isEditingName } = useRenameModeManager({
        isSelected,
    });
    const handleNameInputChange = useHandleNameInputChange({
        foSpineAsset,
        setFoSpineAsset,
    });

    const handleLabelClick = useHandleLabelClick({
        foSpineAsset,
        setFoSpineAssetOnTrack,
    });

    const isEditingAllowed = foSpineAsset.accessType === accessTypes.READ_AND_WRITE;

    return (
        <SelectableListItem
            className={classnames(styles.FoSpineAssetListItem, className)}
            isSelected={isSelected}
            {...attributes}
        >
            <EditableTextBox
                isEditing={isEditingName}
                inputProps={{
                    value: name,
                    onChange: handleNameInputChange,
                }}
                labelProps={{
                    onClick: handleLabelClick,
                }}
            />
            {isSelected && isEditingAllowed && <Line />}
            {isSelected && isEditingAllowed && (
                <Buttons
                    foSpineAssetId={foSpineAsset.id}
                    toggleRenameMode={toggleRenameMode}
                    isEditingName={isEditingName}
                />
            )}
        </SelectableListItem>
    );
}

FoSpineAssetListItem.propTypes = {
    className: PropTypes.string,
    selectedFoSpineAssetId: PropTypes.string.isRequired,
    foSpineAsset: foSpineAssetLike.isRequired,
    setFoSpineAsset: PropTypes.func.isRequired,
    setFoSpineAssetOnTrack: PropTypes.func.isRequired,
};

FoSpineAssetListItem.defaultProps = {
    className: '',
};
