import { useCallback, useRef } from 'react';

import convertClipsByIdToExpressionTimelineKeys from 'src/components/views/SceneEditor/Timeline/hooks/useAdaptTimelinePropsToAssetTrackMode/convertClipsByIdToExpressionTimelineKeys';

export default function useAdaptedSetClipsByIdForExpressions({
    timeWidth,
    setExpressionTimelineKeys,
}) {
    const dependencyRef = useRef({});
    dependencyRef.current = {
        timeWidth,
        setExpressionTimelineKeys,
    };

    const adaptedSetClipsByIdForExpressions = useCallback(
        function _adaptedSetClipsByIdForExpressions({ sceneId, trackId, newClipsById }) {
            const { timeWidth, setExpressionTimelineKeys } = dependencyRef.current;
            const newExpressionTimelineKeys = convertClipsByIdToExpressionTimelineKeys({
                sceneId,
                trackId,
                clipsById: newClipsById,
                timeWidth,
            });
            setExpressionTimelineKeys({
                sceneId,
                trackId,
                expressionTimelineKeys: newExpressionTimelineKeys,
            });
        },
        [dependencyRef],
    );

    return adaptedSetClipsByIdForExpressions;
}
