import { getScenesEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function fetchScenes() {
    try {
        const scenesEndpoint = getScenesEndpoint();

        const response = await fetchWithAuth(scenesEndpoint);

        if (response.ok) {
            const fetchedScenes = await response.json();
            const scenesById = {};
            const allSceneIds = [];
            fetchedScenes.forEach((scene) => {
                scenesById[scene.id] = scene;
                allSceneIds.push(scene.id);
            });
            return { scenesById, allSceneIds };
        }

        const errorData = await response.json();
        throw new Error(errorData.errors[0].message);
    } catch (err) {
        console.log(err);
    }
}
