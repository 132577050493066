import { sortBy, last } from 'lodash';

import calcZoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcZoomedValue';
import getSingleClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth/getSingleClipWidth';
import clipTypes from 'src/components/constants/clipTypes';

/**
 *@clipData
 *@timeWidth
 *@zoomLevel
 *@isGetUntrimmed
 */
export default function getClipGroupWidth({
    clipData,
    timeWidth,
    zoomLevel = 1,
    isGetUntrimmed = false,
}) {
    const sortedClips = sortBy(clipData.clipsById, 'position');
    const lastClip = last(sortedClips);

    if (!lastClip) {
        console.error('Clip group was empty.');
        return 0;
    }

    let lastClipWidth = 0;
    if (lastClip.data.type === clipTypes.CLIP_SINGLE) {
        lastClipWidth = getSingleClipWidth({ clipData: lastClip.data, timeWidth, zoomLevel });
    } else if (lastClip.data.type === clipTypes.CLIP_GROUP) {
        lastClipWidth = getClipGroupWidth({
            clipData: lastClip.data,
            timeWidth,
            zoomLevel,
        });
    }
    const zoomedLastClipPosition = calcZoomedValue({
        value: lastClip.position,
        zoomLevel,
    });

    const untrimmedClipWidth = zoomedLastClipPosition + lastClipWidth;

    if (isGetUntrimmed) {
        return untrimmedClipWidth;
    }

    const headTrim = untrimmedClipWidth * clipData.headTrimPercent;
    const tailTrim = untrimmedClipWidth * clipData.tailTrimPercent;
    const trimmedClipWidth = untrimmedClipWidth - headTrim - tailTrim;

    return trimmedClipWidth;
}
