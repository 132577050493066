import convertCornerPointToTangentPoint from 'src/pixi/tools/MaskTool/utils/convertCornerPointToTangentPoint';
import makeMaskPoint from 'src/pixi/tools/MaskTool/utils/makeMaskPoint';

export default function makeOvalPoints({ mainCoordinates, points = [] }) {
    const newPoints = mainCoordinates.map((mainCoordinate, index) => {
        const basePoint = points[index] || makeMaskPoint();
        const newPoint = {
            ...basePoint,
            main: mainCoordinate,
        };

        return newPoint;
    });

    newPoints.forEach((newPoint) => {
        convertCornerPointToTangentPoint({
            points: newPoints,
            point: newPoint,
        });
    });

    return newPoints;
}
