import { createSlice } from '@reduxjs/toolkit';

const userProfileSlice = createSlice({
    name: 'user',
    initialState: { userData: null },
    reducers: {
        setUser(state, action) {
            state.userData = action.payload;
        },
    },
});

export const { setUser } = userProfileSlice.actions;

export default userProfileSlice.reducer;
