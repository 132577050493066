import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

export default function SelectableListItem(props) {
    const { className, isSelected, children, ...attributes } = props;

    return (
        <li
            className={classnames(
                'SelectableListItem',
                { 'SelectableListItem--selected': isSelected },
                className,
            )}
            {...attributes}
        >
            {children}
        </li>
    );
}

SelectableListItem.propTypes = {
    className: PropTypes.string,
    isSelected: PropTypes.bool,
};

SelectableListItem.defaultProps = {
    className: '',
    isSelected: false,
};
