import getFirstSequentialClipsFromSelection from 'src/components/views/SceneEditor/Timeline/utils/getFirstSequentialClipsFromSelection';

import getIfSelectedClipsAreOnSingleLayer from './getIfSelectedClipsAreOnSingleLayer';

export default function getSelectedSequentialClips({ tracks, clipSelectionByTrackId }) {
    let selectedSequentialClips = [];

    const {
        isSelectedClipsOnSingleLayer,
        selectedTrackId,
        selectedLayerId,
    } = getIfSelectedClipsAreOnSingleLayer({
        clipSelectionByTrackId,
    });

    if (isSelectedClipsOnSingleLayer) {
        const firstSequentialSelectedClips = getFirstSequentialClipsFromSelection({
            tracks,
            selectedTrackId,
            selectedLayerId,
            clipSelectionByTrackId,
        });
        const selectedClipsOnTrackLayer = clipSelectionByTrackId[selectedTrackId][selectedLayerId];
        const isSelectedClipsSequential =
            firstSequentialSelectedClips.length &&
            firstSequentialSelectedClips.length === selectedClipsOnTrackLayer.length;

        if (isSelectedClipsSequential) {
            selectedSequentialClips = firstSequentialSelectedClips;
        }
    }

    return { selectedSequentialClips, selectedTrackId, selectedLayerId };
}
