import getChildById from 'src/pixi/utils/getChildById';
import loadFoTextureComp from 'src/utils/businessLogic/foTextureComp/loadFoTextureComp';
import placeFoTextureCompDisplayObjectRelativeToSlot from 'src/utils/businessLogic/foSpineAsset/placeFoTextureCompDisplayObjectRelativeToSlot';

export default async function placeFoTextureCompOfSlot({
    app,
    foTextureCompRepMap,
    foSpineAsset,
    foTextureComp,
    textureModificationAtom,
}) {
    const foTextureCompRep = foTextureCompRepMap[foTextureComp.id] || {};
    if (foTextureCompRep.isLoading) {
        return;
    }
    let foTextureCompDisplayObject = foTextureCompRep.displayObject;
    if (!foTextureCompDisplayObject) {
        foTextureCompRepMap[foTextureComp.id] = {
            isLoading: true,
        };
        foTextureCompDisplayObject = await loadFoTextureComp({
            loader: app.loader,
            foTextureComp,
        });
        foTextureCompRepMap[foTextureComp.id] = {
            displayObject: foTextureCompDisplayObject,
            isLoading: false,
        };
    }

    app.stage.addChild(foTextureCompDisplayObject);

    const spineAssetInstance = getChildById({
        parent: app.stage,
        id: foSpineAsset.id,
    });
    placeFoTextureCompDisplayObjectRelativeToSlot({
        foTextureCompDisplayObject,
        spineAssetInstance,
        textureModificationAtom,
    });

    return foTextureCompDisplayObject;
}
