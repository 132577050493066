import { useCallback, useRef } from 'react';

export default function useHandleEmptyListAreaClick({ handleFoTextureSelectionChange }) {
    const isItemPointerDownRef = useRef(false);

    const handleListItemPointerDown = useCallback(
        function _handleLabelClick() {
            isItemPointerDownRef.current = true;
        },
        [isItemPointerDownRef],
    );

    const handleEmptyListAreaClick = useCallback(
        function _handleEmptyListAreaClick(event) {
            const isItemPointerDown = isItemPointerDownRef.current;
            const isNotItemDrop = !isItemPointerDown;
            if (isNotItemDrop && event.target === event.currentTarget) {
                handleFoTextureSelectionChange({
                    foTextureId: '',
                });
            }
            isItemPointerDownRef.current = false;
        },
        [handleFoTextureSelectionChange, isItemPointerDownRef],
    );

    return { handleEmptyListAreaClick, handleListItemPointerDown };
}
