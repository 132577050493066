import getOtherTangentName from 'src/pixi/tools/MaskTool/utils/getOtherTangentName';
import generateOtherTangent from 'src/pixi/tools/MaskTool/PointControl/makeTangentKnob/tangentKnobDragHandlers/generateOtherTangent';
import makeTangentsOpposites from 'src/pixi/tools/MaskTool/PointControl/makeTangentKnob/tangentKnobDragHandlers/makeTangentsOpposites';

export default function toggleTangent(tangentKnob) {
    const { maskTool, pointId, tangentName } = tangentKnob;
    const points = maskTool.getPoints();
    const point = points.find((currentPoint) => {
        return currentPoint.id === pointId;
    });

    const otherTangentName = getOtherTangentName(tangentName);
    if (!point[otherTangentName]) {
        generateOtherTangent({ point, tangentName });
        point.isTangentsLinked = true;
    } else if (point.isTangentsLinked) {
        point.isTangentsLinked = false;
    } else {
        point.isTangentsLinked = true;
        makeTangentsOpposites({ point, baseTangentName: tangentName });
    }

    maskTool.positionControls();
}
