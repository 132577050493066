import getMaskedDisplayObjectContent from 'src/pixi/tools/MaskTool/utils/getMaskedDisplayObjectContent';

export default function disableMaskOnMaskToolTarget(maskTool) {
    const { target } = maskTool;

    if (target) {
        const content = getMaskedDisplayObjectContent(target);

        if (content.mask) {
            target.removeChild(content.mask);
            content.mask = null;
        }
    }
}
