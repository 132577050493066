import { useCallback } from 'react';

export default function useHandleZoomInputChange({ updateZoom }) {
    return useCallback(
        function _handleZoomInputChange(event) {
            const zoomLevelPercent = Math.round(Number(event.target.value));
            if (Number.isNaN(zoomLevelPercent)) {
                return;
            }
            const zoomLevel = Number(zoomLevelPercent / 100);

            updateZoom(zoomLevel);
        },
        [updateZoom],
    );
}
