import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import SuperTooltip from 'src/components/shared/SuperTooltip';
import NestedList from 'src/components/shared/NestedList';
import { nestedListDataLike } from 'src/components/shared/NestedList/propTypes';
import pointLike from 'src/components/shared/propTypes/pointLike';

import './styles.scss';

export default function ButtonWithMenu(props) {
    const { buttonProps, nestedListProps, percentOnTargetElem, percentOnContentElem } = props;
    const { listData, ...otherNestedListProps } = nestedListProps;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = useCallback(
        function _toggleMenu() {
            setIsMenuOpen(!isMenuOpen);
        },
        [isMenuOpen, setIsMenuOpen],
    );
    const buttonRef = useRef();

    const updatedListData = listData.map(function _addToggleMenuOnLeafItemClick(listItemData) {
        const { childrenData } = listItemData;
        let updatedItemData;
        if (childrenData) {
            const updatedChildrenData = childrenData.map(_addToggleMenuOnLeafItemClick);
            updatedItemData = { ...listItemData, childrenData: updatedChildrenData };
        } else {
            const { contentElemAttributes } = listItemData;
            const updatedContentElemAttributes = {
                ...contentElemAttributes,
                onClick(event) {
                    contentElemAttributes.onClick &&
                        contentElemAttributes.onClick(event, listItemData);
                    toggleMenu();
                },
            };
            updatedItemData = {
                ...listItemData,
                contentElemAttributes: updatedContentElemAttributes,
            };
        }
        return updatedItemData;
    });

    return (
        <>
            <ToolButton
                onClick={toggleMenu}
                nodeRef={buttonRef}
                isActive={isMenuOpen}
                {...buttonProps}
            />
            {isMenuOpen && (
                <SuperTooltip
                    targetElemRef={buttonRef}
                    onClickOutside={toggleMenu}
                    percentOnTargetElem={percentOnTargetElem}
                    percentOnContentElem={percentOnContentElem}
                >
                    <NestedList listData={updatedListData} {...otherNestedListProps} />
                </SuperTooltip>
            )}
        </>
    );
}

ButtonWithMenu.propTypes = {
    buttonProps: PropTypes.shape({
        className: PropTypes.string,
        children: PropTypes.node.isRequired,
    }),
    nestedListProps: PropTypes.shape({
        className: PropTypes.string,
        listData: nestedListDataLike.isRequired,
    }).isRequired,
    percentOnTargetElem: pointLike,
    percentOnContentElem: pointLike,
};

ButtonWithMenu.defaultProps = {
    buttonProps: {
        className: '',
    },
    percentOnTargetElem: {
        x: 1,
        y: 0.5,
    },
    percentOnContentElem: {
        x: 0,
        y: 1,
    },
};
