import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import useSetupTextureManagerApp from 'src/components/views/TextureRouteManager/TextureManager/hooks/useSetupTextureManagerApp';
import useSelectSlotByName from 'src/components/views/TextureRouteManager/TextureManager/hooks/useSelectSlotByName';
import useUpdatePixiWithStateChanges from 'src/components/views/TextureRouteManager/TextureManager/hooks/useUpdatePixiWithStateChanges';
import { setFoSpineAssetTextureModifications } from 'src/components/views/AssetSelector/assetSelectorSlice';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { setFoSpineAssetLinkOnFoTextureComp } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice';
import { foTextureCompsLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import {
    setSelectedSlotName,
    setEditingFoTextureCompId,
} from 'src/components/views/TextureRouteManager/TextureEditor/textureEditorSlice';
import getSelectedTrackFoSpineAsset from 'src/reduxStore/utils/getSelectedTrackFoSpineAsset';
import useFoTextureCompPreview from 'src/components/views/TextureRouteManager/TextureManager/hooks/useFoTextureCompPreview';
import TextureManagerContext from 'src/components/views/TextureRouteManager/TextureManager/context/TextureManagerContext';
import withRouter from 'src/reduxStore/utils/withRouter';

import FoTextureCompList from './FoTextureCompList';
import TextureManagerToolContainer from './TextureManagerToolContainer';

import styles from './styles.module.scss';

// todo - consider renaming this to AssetEditor
function TextureManager(props) {
    const { history, location, match, staticContext, dispatch, ...relevantProps } = props;
    const {
        className,
        setSelectedSlotName,
        setEditingFoTextureCompId,
        setFoSpineAssetTextureModifications,
        foSpineAsset,
        selectedSlotName,
        foTextureComps,
        editingFoTextureCompId,
        setFoSpineAssetLinkOnFoTextureComp,
        ...attributes
    } = relevantProps;

    const pixiContainerElemRef = useRef(null);

    const { selectSlotByName } = useSelectSlotByName({
        setSelectedSlotName,
    });

    const isFoSpineAssetLoaded = useSetupTextureManagerApp({
        pixiContainerElemRef,
        foSpineAsset,
        selectedSlotName,
        selectSlotByName,
        foTextureComps,
    });

    useFoTextureCompPreview({
        foTextureComps,
        selectedSlotName,
        foSpineAsset,
    });
    useUpdatePixiWithStateChanges({
        foTextureComps,
        foSpineAsset,
        selectedSlotName,
        setFoSpineAssetTextureModifications,
        isFoSpineAssetLoaded,
        setFoSpineAssetLinkOnFoTextureComp,
    });

    const textureManagerContextValue = useMemo(() => {
        return {
            selectSlotByName,
        };
    }, [selectSlotByName]);

    return (
        <TextureManagerContext.Provider value={textureManagerContextValue}>
            <div className={classnames(styles.TextureManager, className)} {...attributes}>
                <TextureManagerToolContainer
                    foSpineAsset={foSpineAsset}
                    selectedFoTextureCompId={editingFoTextureCompId}
                    selectedSlotName={selectedSlotName}
                    selectSlotByName={selectSlotByName}
                    setEditingFoTextureCompId={setEditingFoTextureCompId}
                />
                <div className={styles.TextureManager__frameRow}>
                    <div
                        ref={pixiContainerElemRef}
                        className={styles.TextureManager__pixiContainerElem}
                    />

                    <FoTextureCompList
                        className={styles.TextureManager__foTextureCompList}
                        foTextureComps={foTextureComps}
                        selectedSlotName={selectedSlotName}
                        foSpineAsset={foSpineAsset}
                        setFoSpineAssetTextureModifications={setFoSpineAssetTextureModifications}
                    />
                </div>
            </div>
        </TextureManagerContext.Provider>
    );
}

TextureManager.propTypes = {
    className: PropTypes.string,
    foSpineAsset: foSpineAssetLike.isRequired,
    selectedSlotName: PropTypes.string.isRequired,
    setSelectedSlotName: PropTypes.func.isRequired,
    foTextureComps: foTextureCompsLike.isRequired,
    editingFoTextureCompId: PropTypes.string.isRequired,
    setEditingFoTextureCompId: PropTypes.func.isRequired,
    setFoSpineAssetTextureModifications: PropTypes.func.isRequired,
    setFoSpineAssetLinkOnFoTextureComp: PropTypes.func.isRequired,
};

TextureManager.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { textureManager, textureEditor } = state;
    const { foTextureComps } = textureManager;

    const { selectedSlotName, editingFoTextureCompId } = textureEditor;

    const { sceneId: selectedSceneId } = ownProps.router.params;
    const foSpineAsset = getSelectedTrackFoSpineAsset(state, { selectedSceneId });

    return {
        foSpineAsset,
        foTextureComps,
        selectedSlotName,
        editingFoTextureCompId,
        setFoSpineAssetLinkOnFoTextureComp,
    };
}

export default withRouter(
    connect(mapState, {
        setSelectedSlotName,
        setEditingFoTextureCompId,
        setFoSpineAssetTextureModifications,
        setFoSpineAssetLinkOnFoTextureComp,
    })(TextureManager),
);
