import applyBezierMaskToDisplayObject from 'src/pixi/tools/MaskTool/utils/applyBezierMaskToDisplayObject';

export default function enableMaskOnMaskToolTarget(maskTool) {
    const { target } = maskTool;

    if (target) {
        applyBezierMaskToDisplayObject({
            bezierPoints: target.maskData.points,
            displayObject: target,
        });
    }
}
