import { useState, useCallback } from 'react';

export default function useSearchTerm() {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchInputChange = useCallback(
        function _handleSearchInputChange(event) {
            setSearchTerm(event.target.value);
        },
        [setSearchTerm],
    );

    const handleCloseButonClick = useCallback(
        function _handleCloseButonClick() {
            setSearchTerm('');
        },
        [setSearchTerm],
    );

    return {
        searchTerm,
        handleSearchInputChange,
        handleCloseButonClick,
    };
}
