import drawBgGhostLayer from 'src/pixi/tools/TransformTool/makeBgGhostLayer/drawBgGhostLayer';

export function handleStageClick(event) {
    if (event.currentTarget === event.target && this.transformTool.target) {
        this.transformTool.clear();
    }
}

export function handleEscDown(event) {
    if (event.key === 'Escape' && this.transformTool.target) {
        this.transformTool.clear();
    }
}

export function handleWindowResize() {
    if (this.transformTool.target) {
        const { bgGhostLayer, app } = this.transformTool;
        const { width, height } = app.screen;
        drawBgGhostLayer({ bgGhostLayer, width, height });
    }
}
