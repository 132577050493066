import { useEffect } from 'react';
import pixiStore from 'src/pixi/pixiStore';
import useAudio from 'src/components/shared/hooks/useAudio';

export default function useSceneEditorAudio({ selectedAudioTrack }) {
    const { id: selectedAudioTrackId } = selectedAudioTrack || {
        id: '',
    };
    const { waveformData, loadAudio, isLoading: isLoadingAudio } = useAudio({
        id: selectedAudioTrackId,
    });

    useEffect(
        function _setupAudioController() {
            if (selectedAudioTrackId) {
                loadAudio();
            }

            return function _cleanup() {
                if (selectedAudioTrackId) {
                    const audioController = pixiStore.audioManager.getAudioControllerById(
                        selectedAudioTrackId,
                    );
                    audioController.stop();
                }
            };
        },
        [selectedAudioTrackId, loadAudio],
    );

    return {
        waveformData,
        isLoadingAudio,
    };
}
