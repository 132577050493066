import { useRef, useCallback, useContext } from 'react';
import useIsDrawing from 'src/components/shared/hooks/useIsDrawing';

import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import calcZoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcZoomedValue';

const THRESHOLD = 0.05;

export default function useScrollTimelineToTimeIndicatorEfficiently({
    timeWidth,
    zoomLevel,
    horizontalScrollElemRef,
}) {
    const paramsRef = useRef();
    const { currentTimeManager } = useContext(SceneEditorContext);
    const { currentTime } = currentTimeManager;
    paramsRef.current = {
        currentTime,
        zoomLevel,
        timeWidth,
    };

    const scrollTimelineToTimeIndicator = useCallback(
        function _scrollTimellineToTimeIndicator() {
            const horizontalScrollElem = horizontalScrollElemRef.current;
            const visibleWidth = horizontalScrollElem.clientWidth;
            const currentScroll = horizontalScrollElem.scrollLeft;

            const leftThresholdPos = visibleWidth * THRESHOLD;
            const rightThresholdPos = visibleWidth * (1 - THRESHOLD);

            const { currentTime, zoomLevel, timeWidth } = paramsRef.current;
            const timeIndicatorPos = currentTime * timeWidth;
            const zoomedTimeIndicatorPos = calcZoomedValue({
                value: timeIndicatorPos,
                zoomLevel,
            });

            const timeIndicatorPosFromVisibleStart = zoomedTimeIndicatorPos - currentScroll;
            const timeIndicatorDistanceFromRightThresholdPos =
                timeIndicatorPosFromVisibleStart - rightThresholdPos;
            const timeIndicatorDistanceFromLeftThresholdPos =
                timeIndicatorPosFromVisibleStart - leftThresholdPos;

            let scrollAdjustment = 0;
            if (timeIndicatorDistanceFromRightThresholdPos > 0) {
                scrollAdjustment = timeIndicatorDistanceFromRightThresholdPos;
            }
            if (timeIndicatorDistanceFromLeftThresholdPos < 0 && currentScroll) {
                scrollAdjustment = timeIndicatorDistanceFromLeftThresholdPos;
            }
            if (scrollAdjustment) {
                horizontalScrollElem.scrollLeft = currentScroll + scrollAdjustment;
            }
        },
        [horizontalScrollElemRef, paramsRef],
    );

    const { isDrawingRef, setIsDrawing } = useIsDrawing();
    const scrollTimelineToTimeIndicatorEfficiently = useCallback(() => {
        if (!isDrawingRef.current) {
            setIsDrawing();
            scrollTimelineToTimeIndicator();
        }
    }, [isDrawingRef, setIsDrawing, scrollTimelineToTimeIndicator]);

    return {
        scrollTimelineToTimeIndicatorEfficiently,
        horizontalScrollElemRef,
    };
}
