import getChildById from 'src/pixi/utils/getChildById';
import updateFoTextureWithDisplayObjectProps from 'src/utils/businessLogic/foTexture/updateFoTextureWithDisplayObjectProps';

export default function buildUpdatedFoTextureComp({ app, foTextureComp }) {
    const newFoTextures = foTextureComp.foTextures.map(
        function _updateFoTextureWithDisplayObjectProps(foTexture) {
            const displayObject = getChildById({
                parent: app.stage,
                id: foTexture.id,
            });
            const newFoTexture = updateFoTextureWithDisplayObjectProps({
                foTexture,
                displayObject,
            });
            return newFoTexture;
        },
    );

    const newFoTextureComp = {
        ...foTextureComp,
        foTextures: newFoTextures,
    };

    return newFoTextureComp;
}
