import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './styles.module.scss';

export default function ClipResizer(props) {
    const { className, ...attributes } = props;

    return (
        <div className={classnames(styles.ClipResizer, className)} {...attributes}>
            ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ... ...
        </div>
    );
}

ClipResizer.propTypes = {
    className: PropTypes.string,
};

ClipResizer.defaultProps = {
    className: '',
};
