import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import useHandleCloneFoTextureComp from 'src/components/views/TextureRouteManager/TextureManager/FoTextureCompList/FoTextureCompListItem/Buttons/CloneButton/hooks/useHandleCloneFoTextureComp';
import {
    setFoSpineAssetLinkOnFoTextureComp,
    setFoTextureComp,
} from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice';
import { foTextureCompLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import { setFoSpineAssetTextureModifications } from 'src/components/views/AssetSelector/assetSelectorSlice';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import getFoTextureCompById from 'src/reduxStore/utils/getFoTextureCompById';
import getSelectedTrackFoSpineAsset from 'src/reduxStore/utils/getSelectedTrackFoSpineAsset';
import withRouter from 'src/reduxStore/utils/withRouter';

import styles from './styles.module.scss';

function CloneButton(props) {
    const {
        className,
        foTextureComp,
        selectedSlotName,
        setFoSpineAssetLinkOnFoTextureComp,
        foSpineAsset,
        setFoSpineAssetTextureModifications,
        setFoTextureComp,
        ...attributes
    } = props;

    const handleCloneFoTexture = useHandleCloneFoTextureComp({
        foTextureComp,
        selectedSlotName,
        foSpineAsset,
        setFoSpineAssetTextureModifications,
        setFoSpineAssetLinkOnFoTextureComp,
        setFoTextureComp,
    });

    return (
        <ToolButton
            className={classnames(styles.CloneButton, className)}
            onClick={handleCloneFoTexture}
            {...attributes}
        >
            clone
        </ToolButton>
    );
}

CloneButton.propTypes = {
    className: PropTypes.string,
    foSpineAsset: foSpineAssetLike.isRequired,
    foTextureComp: foTextureCompLike.isRequired,
    setFoTextureComp: PropTypes.func.isRequired,
    selectedSlotName: PropTypes.string.isRequired,
    setFoSpineAssetLinkOnFoTextureComp: PropTypes.func.isRequired,
    setFoSpineAssetTextureModifications: PropTypes.func.isRequired,
};

CloneButton.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { textureEditor } = state;
    const { selectedSlotName } = textureEditor;

    const { foTextureCompId } = ownProps;
    const foTextureComp = getFoTextureCompById({ state, foTextureCompId });

    const { sceneId: selectedSceneId } = ownProps.router.params;
    const foSpineAsset = getSelectedTrackFoSpineAsset(state, { selectedSceneId });

    return {
        foSpineAsset,
        foTextureComp,
        selectedSlotName,
    };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    const finalProps = { ...stateProps, ...dispatchProps, ...ownProps };

    delete finalProps.foTextureCompId;
    delete finalProps.router;

    return finalProps;
}

export default withRouter(
    connect(
        mapState,
        {
            setFoSpineAssetLinkOnFoTextureComp,
            setFoSpineAssetTextureModifications,
            setFoTextureComp,
        },
        mergeProps,
    )(CloneButton),
);
