import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import ungroupClipGroup from 'src/utils/businessLogic/clipUtils/ungroupClipGroup';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';

export default function useHandleUngroupButtonClick({
    selectedTrackLayerClipsById,
    selectedTrackId,
    selectedLayerId,
    setClipsById,
    selectedClipGroup,
    setClipSelection,
    timeWidth,
}) {
    const { sceneId: selectedSceneId } = useParams();

    return useCallback(
        function _handleUngroupButtonClick() {
            const adjustedGroupedClipsById = ungroupClipGroup({
                clipGroup: selectedClipGroup,
                timeWidth,
            });

            const newClipsById = {
                ...selectedTrackLayerClipsById,
                ...adjustedGroupedClipsById,
            };

            delete newClipsById[selectedClipGroup.clipId];

            setClipsById({
                sceneId: selectedSceneId,
                trackId: selectedTrackId,
                trackLayerId: selectedLayerId,
                newClipsById,
            });
            updateTracksOnBackend({ selectedSceneId });

            const clipIdsOfTheSelectedGroup = Object.keys(adjustedGroupedClipsById);
            setClipSelection({
                [selectedTrackId]: { [selectedLayerId]: clipIdsOfTheSelectedGroup },
            });
        },
        [
            selectedTrackLayerClipsById,
            selectedClipGroup,
            selectedTrackId,
            selectedLayerId,
            setClipsById,
            setClipSelection,
            timeWidth,
            selectedSceneId,
        ],
    );
}
