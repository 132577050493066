import produce from 'immer';

export default function calcSetTextureModificationAtom({ foSpineAsset, textureModificationAtom }) {
    const { textures: textureModifications } = foSpineAsset.modifications;

    const foundModificationIndex = textureModifications.findIndex((currentTextureModification) => {
        return currentTextureModification.slotName === textureModificationAtom.slotName;
    });

    const newTextureModifications = produce(textureModifications, (draftTextureModifications) => {
        const { slotName, foTextureCompId } = textureModificationAtom;
        if (foundModificationIndex >= 0) {
            const foundModification = draftTextureModifications[foundModificationIndex];

            foundModification.current = foTextureCompId;
            foundModification.atomsByCompId[foTextureCompId] = textureModificationAtom;
        } else {
            draftTextureModifications.push({
                slotName,
                current: foTextureCompId,
                atomsByCompId: {
                    [foTextureCompId]: textureModificationAtom,
                },
            });
        }
    });

    return newTextureModifications;
}
