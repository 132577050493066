import calcUnzoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcUnzoomedValue';
import calcTimeIndicatorHeadWidth from 'src/components/views/SceneEditor/Timeline/TimeTracker/utils/calcTimeIndicatorHeadWidth';

const timeIndicatorHeadWidth = calcTimeIndicatorHeadWidth();
const timeIndicatorHeadHalfWidth = timeIndicatorHeadWidth / 2;

export default function getIfTimeIndicatorIsAtTimelineEnd({
    currentTime,
    timelineWidth,
    timeWidth,
    zoomLevel,
}) {
    const timelineUnzoomedWidth = calcUnzoomedValue({
        value: timelineWidth,
        zoomLevel,
    });
    const timelineDuration = timelineUnzoomedWidth / timeWidth;

    const unzoomedTimeIndicatorHeadHalfWidth = calcUnzoomedValue({
        value: timeIndicatorHeadHalfWidth,
        zoomLevel,
    });
    const timeIndicatorHeadHalfWidthDuration = unzoomedTimeIndicatorHeadHalfWidth / timeWidth;

    const isTimeIndicatorAtTimelineEnd =
        currentTime > timelineDuration - timeIndicatorHeadHalfWidthDuration;

    return isTimeIndicatorAtTimelineEnd;
}
