import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    audioTracks: [],
};

const audioSelectorSlice = createSlice({
    name: 'audioSelector',
    initialState,
    reducers: {
        setAudioTracks(state, action) {
            state.audioTracks = action.payload;
        },
        addAudioTrack(state, action) {
            state.audioTracks = [action.payload, ...state.audioTracks];
        },
        removeAudioTrack(state, action) {
            const audioTrackId = action.payload;
            state.audioTracks = state.audioTracks.filter((currentTrack) => {
                return currentTrack.id !== audioTrackId;
            });
        },
        updateAudioTrack(state, action) {
            const audioTrack = action.payload;

            const audioTrackIndex = state.audioTracks.findIndex((currentAudioTrack) => {
                return currentAudioTrack.id === audioTrack.id;
            });

            state.audioTracks[audioTrackIndex] = audioTrack;
        },
        updateAudioTrackTranscript(state, action) {
            const { audioTrackId, foTranscriptWords } = action.payload;

            const audioTrackIndex = state.audioTracks.findIndex((currentAudioTrack) => {
                return currentAudioTrack.id === audioTrackId;
            });

            state.audioTracks[audioTrackIndex].foTranscriptWords = foTranscriptWords;
        },
    },
});

export const {
    addAudioTrack,
    removeAudioTrack,
    updateAudioTrack,
    updateAudioTrackTranscript,
    setAudioTracks,
} = audioSelectorSlice.actions;

export default audioSelectorSlice.reducer;
