import { useCallback } from 'react';

export default function useInputHandlers(transcriptWritingFormStateRef) {
    const {
        setTranscriptText,
        setStartTime,
        setEndTime,
        setIsEstimateEndTime,
        setLanguage,
    } = transcriptWritingFormStateRef.current;

    const handleTranscriptTextChange = useCallback(
        function _handleTranscriptTextChange(event) {
            setTranscriptText(event.target.value);
        },
        [setTranscriptText],
    );

    const handleStartTimeChange = useCallback(
        function _handleStartTimeChange(event) {
            setStartTime(Number(event.target.value));
        },
        [setStartTime],
    );

    const handleEndTimeChange = useCallback(
        function _handleEndTimeChange(event) {
            setEndTime(Number(event.target.value));
        },
        [setEndTime],
    );

    const handleEstimateEndTimeSwitchChange = useCallback(
        function _handleEstimateEndTimeSwitchChange(event) {
            const isEstimateEndTime = event.target.checked;

            setIsEstimateEndTime(isEstimateEndTime);
        },
        [setIsEstimateEndTime],
    );

    const handleLanguageChange = useCallback(
        function _handleLanguageChange(event) {
            setLanguage(event.target.value);
        },
        [setLanguage],
    );

    return {
        handleTranscriptTextChange,
        handleStartTimeChange,
        handleEndTimeChange,
        handleEstimateEndTimeSwitchChange,
        handleLanguageChange,
    };
}
