import { useCallback } from 'react';

import pixiStore from 'src/pixi/pixiStore';

import removeSelectionFromTextureTools from './removeSelectionFromTextureTools';
import removeFromFoTexturesState from './removeFromFoTexturesState';
import removeFromPixi from './removeFromPixi';

export default function useHandleDeleteFoTexture({ updateFoTextures, foTextures, foTextureId }) {
    return useCallback(
        function _handleDeleteFoTexture() {
            removeSelectionFromTextureTools();

            removeFromFoTexturesState({
                foTextureId,
                foTextures,
                updateFoTextures,
            });

            const app = pixiStore.pixiAppManager.getPixiApp();
            removeFromPixi({ foTextures, foTextureId, app });
        },
        [updateFoTextures, foTextures, foTextureId],
    );
}
