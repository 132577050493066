import generateTrackLayer from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayer';

import generateTrackId from './generateTrackId';

export default function generateTrack({
    trackId: receivedTrackId,
    foSpineAsset,
    transformation: receivedTransformation,
    layers: receivedLayers,
    expressionTimelineKeys = [],
} = {}) {
    if (!foSpineAsset) {
        throw new Error('Cannot make a track without a foSpineAsset');
    }

    let layers = receivedLayers;
    if (!layers) {
        const trackLayer = generateTrackLayer();
        layers = {
            byId: { [trackLayer.trackLayerId]: trackLayer },
            allIds: [trackLayer.trackLayerId],
        };
    }

    let transformation = receivedTransformation;
    if (!transformation) {
        transformation = {
            position: [
                {
                    time: 0,
                    value: { x: 0, y: 0 },
                },
            ],
            pivot: [
                {
                    time: 0,
                    value: { x: 0, y: 0 },
                },
            ],
            rotation: [
                {
                    time: 0,
                    value: 0,
                },
            ],
            scale: [
                {
                    time: 0,
                    value: { x: 1, y: 1 },
                },
            ],
        };
    }

    const scene = {
        trackId: receivedTrackId || generateTrackId(),
        foSpineAsset,
        transformation,
        layers,
        expressionTimelineKeys,
    };

    return scene;
}
