export default function buildAnimationsByExpression(expressionsByAnimation) {
    const animationsByExpression = {};

    if (!expressionsByAnimation) {
        return animationsByExpression;
    }

    Object.keys(expressionsByAnimation).forEach((animationName) => {
        const { expressions, ...otherAnimationData } = expressionsByAnimation[animationName];

        Object.keys(expressions).forEach((expressionName) => {
            const expressionImpactData = expressions[expressionName];

            if (!animationsByExpression[expressionName]) {
                animationsByExpression[expressionName] = {};
            }

            animationsByExpression[expressionName][animationName] = {
                ...expressionImpactData,
                ...otherAnimationData,
            };
        });
    });

    return animationsByExpression;
}
