import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import getFoTextureCompsOfFoSpineAsset from 'src/reduxStore/utils/getFoTextureCompsOfFoSpineAsset';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { foTextureCompsLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import useSetupAssetSelectorApp from 'src/components/views/AssetSelector/hooks/useSetupAssetSelectorApp';
import useDisplayFoSpineAsset from 'src/components/views/AssetSelector/hooks/useDisplayFoSpineAsset';
import getSelectedTrackFoSpineAsset from 'src/reduxStore/utils/getSelectedTrackFoSpineAsset';
import withRouter from 'src/reduxStore/utils/withRouter';

import FoSpineAssetList from './FoSpineAssetList';
import AssetSelectorToolContainer from './AssetSelectorToolContainer';

import styles from './styles.module.scss';

function AssetSelector(props) {
    const { className, foSpineAsset, foTextureComps } = props;

    const pixiContainerElemRef = useRef(null);

    useSetupAssetSelectorApp({
        pixiContainerElemRef,
    });

    useDisplayFoSpineAsset({
        foSpineAsset,
        foTextureComps,
    });

    return (
        <div className={classnames(styles.AssetSelector, className)}>
            <AssetSelectorToolContainer selectedFoSpineAsset={foSpineAsset} />
            <div className={styles.AssetSelector__frameRow}>
                <div
                    ref={pixiContainerElemRef}
                    className={styles.AssetSelector__pixiContainerElem}
                />
                <FoSpineAssetList
                    className={styles.AssetSelector__foTextureCompList}
                    selectedFoSpineAssetId={foSpineAsset ? foSpineAsset.id : ''}
                />
            </div>
        </div>
    );
}

AssetSelector.propTypes = {
    className: PropTypes.string,
    foSpineAsset: foSpineAssetLike.isRequired,
    foTextureComps: foTextureCompsLike.isRequired,
};

AssetSelector.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { sceneId: selectedSceneId } = ownProps.router.params;
    const selectedFoSpineAsset = getSelectedTrackFoSpineAsset(state, { selectedSceneId });

    const { textureManager } = state;
    const { foTextureComps } = textureManager;

    const relatedFoTextureComps = getFoTextureCompsOfFoSpineAsset({
        foSpineAsset: selectedFoSpineAsset,
        foTextureComps,
    });

    return {
        foSpineAsset: selectedFoSpineAsset,
        foTextureComps: relatedFoTextureComps,
    };
}

export default withRouter(connect(mapState)(AssetSelector));
