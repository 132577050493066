import drawBgGhostLayer from 'src/pixi/utils/drawBgGhostLayer';
import pixiStore from 'src/pixi/pixiStore';

export default function handleTextureManagerWindowResize() {
    const app = pixiStore.pixiAppManager.getPixiApp();
    const { textureManagerBgGhostLayer: bgGhostLayer } = app.stage;

    const { width, height } = app.screen;
    drawBgGhostLayer({ bgGhostLayer, width, height });
}
