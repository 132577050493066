import { useCallback, useRef } from 'react';

export default function useHandleToolApply({ setSelectedFoTextureId, foTextures }) {
    const paramsRef = useRef();
    paramsRef.current = { setSelectedFoTextureId, foTextures };

    const handleToolApply = useCallback(
        function _handleToolApply({ tool }) {
            const { setSelectedFoTextureId, foTextures } = paramsRef.current;

            const selectedFoTexture = foTextures.find((foTexture) => {
                return tool.target.id === foTexture.id;
            });

            if (selectedFoTexture) {
                setSelectedFoTextureId(selectedFoTexture.id);
            }
        },
        [paramsRef],
    );

    return handleToolApply;
}
