export default function getTextureModificationAtomCurrentlyAssignedToSlot({
    foSpineAsset,
    slotName,
}) {
    const textureModification = foSpineAsset.modifications.textures.find((textureModification) => {
        return textureModification.slotName === slotName;
    });

    let textureModificationAtom;
    if (textureModification) {
        textureModificationAtom = textureModification.atomsByCompId[textureModification.current];
    }
    return textureModificationAtom;
}
