import hackChildSlots from 'src/spine/utils/hackChildSlots';
import getBoneBySlotName from 'src/utils/businessLogic/foSpineAsset/getBoneBySlotName';
import getSlotContainerBySlotName from 'src/utils/businessLogic/foSpineAsset/getSlotContainerBySlotName';
import renderFoTextureComp from 'src/utils/businessLogic/foTextureComp/renderFoTextureComp';
import placeFoTextureCompDisplayObjectRelativeToSlot from 'src/utils/businessLogic/foSpineAsset/placeFoTextureCompDisplayObjectRelativeToSlot';

export default function applyTextureModificationToSpineAssetInstance({
    app,
    spineAssetInstance,
    foTextureCompDisplayObject,
    textureModificationAtom,
}) {
    const { renderer } = app;

    if (!spineAssetInstance) {
        throw new Error('Cannot applyTextureModification without spineAssetInstance.');
    }

    const { slotName, foTextureCompId } = textureModificationAtom;

    if (!foTextureCompDisplayObject) {
        throw new Error(
            `Couldn't find foTextureComp for id: ${foTextureCompId} in store.` +
                "Can't apply foSpineAsset texture modification",
        );
    }

    placeFoTextureCompDisplayObjectRelativeToSlot({
        foTextureCompDisplayObject,
        spineAssetInstance,
        textureModificationAtom,
    });

    const bone = getBoneBySlotName({
        spineAssetInstance,
        slotName,
    });

    const slotContainer = getSlotContainerBySlotName({
        spineAssetInstance,
        slotName,
    });

    const renderedTexture = renderFoTextureComp({
        renderer,
        foTextureCompDisplayObject,
        bone,
        slotContainer,
    });

    spineAssetInstance.hackTextureBySlotName(slotName, renderedTexture);

    hackChildSlots({
        spineAssetInstance,
        slotName,
        texture: PIXI.Texture.EMPTY,
    });
}
