import getConflictInfo from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getConflictInfo';

import resolveRightConflictSubmissively from './resolveRightConflictSubmissively';
import resolveLeftConflictSubmissively from './resolveLeftConflictSubmissively';

export default function insertSubmissively({
    newClip,
    clipsById,
    conflictInfo,
    timeWidth,
    trimMode,
}) {
    const {
        //
        isConflictToRight,
        rightNeighbour,
        isConflictToLeft,
        leftNeighbour,
    } = conflictInfo || getConflictInfo({ newClip, clipsById });

    let adjustedNewClip = { ...newClip };

    if (isConflictToRight) {
        adjustedNewClip = resolveRightConflictSubmissively({
            newClip: adjustedNewClip,
            rightNeighbour,
            timeWidth,
            trimMode,
        });

        if (!adjustedNewClip) {
            return clipsById;
        }
    }

    if (isConflictToLeft) {
        adjustedNewClip = resolveLeftConflictSubmissively({
            newClip: adjustedNewClip,
            leftNeighbour,
            timeWidth,
            trimMode,
        });

        if (!adjustedNewClip) {
            return clipsById;
        }
    }

    const newClipsById = { ...clipsById };
    newClipsById[adjustedNewClip.clipId] = adjustedNewClip;

    return newClipsById;
}
