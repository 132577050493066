import getTextureModificationAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getTextureModificationAssignedToSlot';

export default function getFoTextureCompIdCurrentlyAssignedToSlot({ foSpineAsset, slotName }) {
    const textureModification = getTextureModificationAssignedToSlot({
        foSpineAsset,
        slotName,
    });
    let foTextureCompId = '';
    if (textureModification) {
        foTextureCompId = textureModification.current;
    }

    return foTextureCompId;
}
