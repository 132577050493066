import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import NestedListItem from './NestedListItem';
import { nestedListDataLike } from './propTypes';

import './styles.scss';

export default function NestedList(props) {
    const { className, isChild, listData, ...attributes } = props;

    const [selectedItemKey, setSelectedItemKey] = useState('');
    const handleItemClick = useCallback(
        function _handleItemClick(event, itemData) {
            if (itemData.key === selectedItemKey) {
                setSelectedItemKey('');
            } else {
                setSelectedItemKey(itemData.key);
            }
        },
        [selectedItemKey, setSelectedItemKey],
    );

    return (
        <ul
            className={classnames('NestedList', { 'NestedList--child': isChild }, className)}
            {...attributes}
        >
            {listData.map((listItemData) => {
                const isSelected = selectedItemKey === listItemData.key;
                return (
                    <NestedListItem
                        key={listItemData.key}
                        itemData={listItemData}
                        onClick={handleItemClick}
                        isSelected={isSelected}
                    />
                );
            })}
        </ul>
    );
}

NestedList.propTypes = {
    className: PropTypes.string,
    isChild: PropTypes.bool,
    listData: nestedListDataLike.isRequired,
};

NestedList.defaultProps = {
    className: '',
    isChild: false,
};
