import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';
import { graphemeConsolidationMap } from 'src/utils/stringUtils/grapheme/graphemeCharacterMap';
import { CLOSED_MOUTH_CHARACTER } from 'src/components/constants/audio';
import getAnimationOriginalDuration from 'src/spine/utils/getAnimationOriginalDuration';

export default function buildClipForGraphemeChar({
    graphemeChar,
    duration,
    position,
    foSpineAssetId,
    graphemeToAnimationMap,
}) {
    const consolidatedGraphemeChar =
        graphemeConsolidationMap[graphemeChar] || CLOSED_MOUTH_CHARACTER;
    const animationId = graphemeToAnimationMap[consolidatedGraphemeChar];

    const originalDuration = getAnimationOriginalDuration({
        animationId,
        foSpineAssetId,
    });

    let animationEnd = duration;
    let timeScale = 1;
    if (duration > originalDuration) {
        animationEnd = originalDuration;
        timeScale = originalDuration / duration;
    }

    const data = {
        animationId,
        title: `${graphemeChar}(${consolidatedGraphemeChar})(${animationId})`,
        originalDuration,
        animationStart: 0,
        animationEnd,
        timeScale,
        graphemeChar,
    };

    const clip = generateClip({ data, position });

    return clip;
}
