export default function addAnimation({
    spineAssetInstance,
    clip,
    isLoop,
    delay,
    timeScale,
    mixDuration,
    isFirst,
    alpha = 1,
    layerIndex = 0,
}) {
    const { animationId, animationStart, animationEnd } = clip.data;
    const method = isFirst ? 'setAnimation' : 'addAnimation';
    let trackEntry = spineAssetInstance.state[method](layerIndex, animationId, isLoop, delay);
    trackEntry.timeScale = timeScale;
    trackEntry.mixDuration = isFirst ? 0 : mixDuration;
    if (animationStart) {
        trackEntry.animationStart = animationStart;
    }
    if (animationEnd) {
        trackEntry.animationEnd = animationEnd;
    }

    trackEntry.alpha = alpha;
    trackEntry = null;
}
