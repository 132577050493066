import { useCallback } from 'react';
import arrayMove from 'array-move';

export default function useHandleSortEnd({ updateFoTextures, foTextures }) {
    return useCallback(
        function _handleSortEnd({ oldIndex, newIndex }) {
            const updatedFoTextures = arrayMove(foTextures, oldIndex, newIndex);

            updateFoTextures(updatedFoTextures);
        },
        [foTextures, updateFoTextures],
    );
}
