import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import getChildById from 'src/pixi/utils/getChildById';
import pixiStore from 'src/pixi/pixiStore';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import reduxStore from 'src/reduxStore';

export default function useHandleDeleteFoSpineAsset({
    foSpineAssetId,
    removeFoSpineAssetFromStore,
    setFoSpineAssetOnTrack,
    fallbackFoSpineAssetId,
    fallbackFoSpineAssetHistory,
}) {
    const { sceneId: selectedSceneId } = useParams();

    return useCallback(
        function _useHandleDeleteFoSpineAsset() {
            const app = pixiStore.pixiAppManager.getPixiApp();

            if (!fallbackFoSpineAssetId) {
                throw new Error(
                    `foSpineAsset: ${foSpineAssetId} cannot be removed ` +
                        `because it doesn't have a fallback foSpineAsset.`,
                );
            }

            const spineAssetInstance = getChildById({
                parent: app.stage,
                id: foSpineAssetId,
            });
            spineAssetInstance.destroy();

            const reduxState = reduxStore.getState();
            const { selectedFoSpineAssetTrackId } = reduxState.sceneEditor;
            setFoSpineAssetOnTrack({
                sceneId: selectedSceneId,
                trackId: selectedFoSpineAssetTrackId,
                foSpineAssetId: fallbackFoSpineAssetId,
                fallbackHistory: fallbackFoSpineAssetHistory,
            });
            updateTracksOnBackend({ selectedSceneId });
            removeFoSpineAssetFromStore(foSpineAssetId);
        },
        [
            foSpineAssetId,
            removeFoSpineAssetFromStore,
            setFoSpineAssetOnTrack,
            fallbackFoSpineAssetId,
            fallbackFoSpineAssetHistory,
            selectedSceneId,
        ],
    );
}
