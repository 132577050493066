import {
    FRAME_NAME_BASE,
    REQUIRED_NUMBER_OF_FRAME_DIGITS,
    videoExportFormats,
} from 'src/components/views/SceneEditor/Monitor/VideoDownloadButton/constants';

import convertVideoQualityToCrf from './convertVideoQualityToCrf';

export default function prepareFormData({
    allImageFiles,
    outputName,
    format,
    ext,
    quality,
    startTime,
    endTime,
    isIncludeAudio,
    selectedAudioTrackId,
    frameRate,
}) {
    const formData = new FormData();
    allImageFiles.forEach((imageFile) => {
        formData.append('frames', imageFile);
    });

    const sequenceName = `${FRAME_NAME_BASE}_%${REQUIRED_NUMBER_OF_FRAME_DIGITS}d.${ext}`;
    formData.append('sequenceName', sequenceName);

    formData.append('outputName', outputName);
    formData.append('outputFormat', format);
    formData.append('startTime', startTime);
    formData.append('endTime', endTime);

    if (format === videoExportFormats.MP4) {
        const crf = convertVideoQualityToCrf(quality);
        formData.append('crf', crf);
    }

    if (isIncludeAudio && selectedAudioTrackId) {
        formData.append('audioTrackId', selectedAudioTrackId);
    }

    formData.append('frameRate', frameRate);

    return formData;
}
