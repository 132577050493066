import { isArray, isNumber } from 'lodash';

export default function generateFoTranscriptWord({
    startTime,
    foGraphemeChars,
    word: receivedWord,
}) {
    if (!isNumber(startTime) || startTime < 0) {
        throw new Error(
            `Cannot make a foTranscriptWord without valid startTime. Received: ${startTime}`,
        );
    }
    if (!isArray(foGraphemeChars) || !foGraphemeChars.length) {
        throw new Error(
            `Cannot make a foTranscriptWord without valid foGraphemeChars. Received: ${foGraphemeChars}`,
        );
    }

    let word = receivedWord;
    if (!word) {
        const missingWord = foGraphemeChars
            .map((foGraphemeChar) => foGraphemeChar.graphemeChar)
            .join('');
        word = missingWord;
    }

    return { startTime, foGraphemeChars, word };
}
