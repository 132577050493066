import PointControl from 'src/pixi/tools/MaskTool/PointControl';

import getPointsAndControlsDiff from './getPointsAndControlsDiff';

export default function updatePointControls(maskTool) {
    const { missingPoints, obsoleteControls } = getPointsAndControlsDiff(maskTool);
    const { pointControlsById } = maskTool;

    obsoleteControls.forEach((pointControl) => {
        delete pointControlsById[pointControl.id];
        pointControl.destroy();
    });

    missingPoints.forEach((point) => {
        const pointControl = new PointControl({ point, maskTool });

        pointControlsById[point.id] = pointControl;

        maskTool.addChild(pointControl.line1);
        maskTool.addChild(pointControl.tangent1Knob);
        maskTool.addChild(pointControl.line2);
        maskTool.addChild(pointControl.tangent2Knob);
        maskTool.addChild(pointControl.mainKnob);
    });
}
