import produce from 'immer';
import reduceOverlapByNextGap from 'src/utils/businessLogic/clipUtils/insertClip/insertShift/reduceOverlapByNextGap';

export default function shiftClipsToRight({
    startIndex,
    clipsById,
    sortedClips,
    shiftAmount,
    isCompactShift,
    timeWidth,
}) {
    let newShiftAmount = shiftAmount;
    let newClipsById = clipsById;

    for (let i = startIndex; i < sortedClips.length && newShiftAmount > 0; i++) {
        const currentClip = sortedClips[i];

        const currentShiftAmount = newShiftAmount;
        newClipsById = produce(newClipsById, (draftNewClipsById) => {
            draftNewClipsById[currentClip.clipId].position += currentShiftAmount;
        });

        if (isCompactShift) {
            const nextClip = sortedClips[i + 1];
            newShiftAmount = reduceOverlapByNextGap({
                currentClip,
                nextClip,
                overlapWidth: newShiftAmount,
                timeWidth,
            });
        }
    }

    return newClipsById;
}
