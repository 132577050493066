export default function downloadFile({ blob, url: receivedUrl, filename }) {
    if (!receivedUrl && !blob) {
        throw new Error('Need either url or blob to download a file');
    }
    const url = receivedUrl || URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.append(a);
    a.download = filename;
    a.href = url;
    a.click();
    a.remove();
}
