import { Graphics } from 'pixi.js';

import {
    TANGENT_KNOB_SIZE,
    TANGENT_KNOB_COLOR,
} from 'src/pixi/tools/MaskTool/constants/maskControls';

export default function makeTangentKnobGraphics() {
    const handle = new Graphics();
    handle.beginFill(TANGENT_KNOB_COLOR);
    handle.drawRect(0, 0, TANGENT_KNOB_SIZE, TANGENT_KNOB_SIZE);
    handle.endFill();
    handle.pivot.set(TANGENT_KNOB_SIZE / 2, TANGENT_KNOB_SIZE / 2);

    return handle;
}
