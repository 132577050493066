import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

import dndTypes from 'src/components/views/SceneEditor/constants/dndTypes';
import moveClip from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/moveClip';
import addClip from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/addClip';
import resizeClip from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/resizeClip';
import {
    INSERT_COMPACT_SHIFT,
    INSERT_SHIFT,
} from 'src/utils/businessLogic/clipUtils/insertClip/constants/conflictModes';
import useIsDrawing from 'src/components/shared/hooks/useIsDrawing';
import calcDeleteClipFromTracks from 'src/components/views/SceneEditor/Timeline/utils/calcDeleteClipFromTracks';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';

export default function useTrackLayerDrop({
    lastApprovedTracksByIdRef,
    isDroppedRef,
    trackNodeRef,
    clipsById,
    setTracks,
    editModes,
    snapDistance,
    trackId,
    trackLayerId,
    setClipsById,
    setIsTrackLayerUpdating,
    isTrackLayerUpdating,
    timeWidth,
    zoomLevel,
}) {
    const { sceneId: selectedSceneId } = useParams();

    const lastHoverPositionRef = useRef(null);
    const { isDrawingRef, setIsDrawing } = useIsDrawing();
    const debouncedIsUpdatingSetterRef = useRef(
        debounce(() => {
            setIsTrackLayerUpdating({
                trackId,
                trackLayerId,
                isTrackLayerUpdating: false,
            });
        }, 100),
    );

    return useDrop({
        accept: [dndTypes.CLIP, dndTypes.ANIMATION, dndTypes.CLIP_HEAD, dndTypes.CLIP_TAIL],
        drop() {
            lastApprovedTracksByIdRef.current = null;
            lastHoverPositionRef.current = null;
            isDroppedRef.current = true;

            updateTracksOnBackend({ selectedSceneId });
        },
        hover(item, monitor) {
            if (isDrawingRef.current) {
                return;
            }
            setIsDrawing();

            if (!isTrackLayerUpdating) {
                setIsTrackLayerUpdating({
                    trackId,
                    trackLayerId,
                    isTrackLayerUpdating: true,
                });
            }
            debouncedIsUpdatingSetterRef.current();

            isDroppedRef.current = false;
            const newHoverPosition = monitor.getClientOffset().x;
            const isHoverLocationSame = lastHoverPositionRef.current === newHoverPosition;
            if (isHoverLocationSame) {
                return;
            }
            lastHoverPositionRef.current = newHoverPosition;

            const itemType = monitor.getItemType();
            const trackNode = trackNodeRef.current;
            const lastApprovedClipsById =
                lastApprovedTracksByIdRef.current?.[trackId].layers.byId[trackLayerId].clipsById;
            const paramsObj = {
                monitor,
                clipsById: lastApprovedClipsById || clipsById,
                editModes,
                timeWidth,
                trackNode,
                zoomLevel,
            };

            if (editModes.isSnap) {
                paramsObj.snapDistance = snapDistance;
            }

            let newClipsById;
            if (itemType === dndTypes.CLIP) {
                const { clipId } = item;
                const isClipOnThisLayer = !!lastApprovedClipsById[clipId];
                if (isClipOnThisLayer) {
                    newClipsById = moveClip({ ...paramsObj, clipId });
                } else {
                    const { tracksByIdAfterDeletion, foundClip } = calcDeleteClipFromTracks({
                        tracksById: lastApprovedTracksByIdRef.current,
                        clipId,
                    });

                    setTracks({ sceneId: selectedSceneId, tracksById: tracksByIdAfterDeletion });

                    newClipsById = addClip({ ...paramsObj, clip: foundClip });
                }
            } else if (itemType === dndTypes.ANIMATION) {
                newClipsById = addClip(paramsObj);
            } else if (itemType === dndTypes.CLIP_TAIL || itemType === dndTypes.CLIP_HEAD) {
                const isInsertShift = editModes.conflictResolution === INSERT_SHIFT;
                const isInsertCompactShift = editModes.conflictResolution === INSERT_COMPACT_SHIFT;
                if (isInsertShift || isInsertCompactShift) {
                    paramsObj.clipsById = clipsById;
                }

                const { clipId } = item;
                newClipsById = resizeClip({
                    ...paramsObj,
                    clipId,
                    resizeSource: itemType,
                });
            }

            setClipsById({ sceneId: selectedSceneId, trackId, trackLayerId, newClipsById });
        },
        collect(monitor) {
            return { isHovering: monitor.isOver() };
        },
    });
}
