export default function addPaddingToString({
    string,
    padCharacter,
    paddedLength,
    isPre,
    isPost,
    delimiter = '',
    prefix = '',
    suffix = '',
}) {
    if (!isPost && !isPre) {
        return string;
    }

    let padding = '';
    for (let padIndex = 0; padIndex < paddedLength; padIndex++) {
        padding += padCharacter;
    }

    let paddedString = string;
    if (isPre) {
        paddedString = `${padding}${delimiter}${paddedString}`;
    }
    if (isPost) {
        paddedString = `${paddedString}${delimiter}${padding}`;
    }

    paddedString = `${prefix}${paddedString}${suffix}`;

    return paddedString;
}
