import getGlobalSize from 'src/pixi/utils/getGlobalSize';

export default function buildSizeRatio({ displayObject, refDisplayObject }) {
    const globalDisplayObjectSize = getGlobalSize(displayObject);
    const globalRefDisplayObjectSize = getGlobalSize(refDisplayObject);
    const sizeRatio = {
        x: globalDisplayObjectSize.width / globalRefDisplayObjectSize.width,
        y: globalDisplayObjectSize.height / globalRefDisplayObjectSize.height,
    };

    return sizeRatio;
}
