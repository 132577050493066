import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from 'src/components/constants/routes';
import { getSceneEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default function useHandleDeleteScene({ sceneId, deleteScene }) {
    const navigate = useNavigate();

    return useCallback(
        async function _handleDeleteScene(event) {
            event.stopPropagation();
            try {
                const sceneEndpoint = getSceneEndpoint(sceneId);

                const response = await fetchWithAuth(sceneEndpoint, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    await response.json();
                    deleteScene(sceneId);
                    navigate(`/${routes.SCENES}`);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.errors[0].message);
                }
            } catch (err) {
                console.log(err);
            }
        },
        [sceneId, deleteScene, navigate],
    );
}
