import selectRandomlyByLikelihood from 'src/utils/objectUtils/selectRandomlyByLikelihood';

export default function selectExpressionInflucenceByLikeleyhood({ expressionTimelineKey }) {
    const selectedInfluenceIndex = selectRandomlyByLikelihood({
        collection: expressionTimelineKey.influences,
        rankPropName: 'amount',
    });
    const selectedInfluence = expressionTimelineKey.influences[selectedInfluenceIndex];

    return selectedInfluence;
}
