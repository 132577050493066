import calcLocalDirectinalScale from './calcLocalDirectinalScale';
import calcZoomFlags from './calcZoomFlags';
import centerFocalObjectInScreenCenter from './centerFocalObjectInScreenCenter';

/**
 * @zoomContainer pixi container, but sprite, graphics or other pixi display object could work too
 * @focalObject pixi display object.
 * @paddingPercent which percent of the screen width/height should be used as padding,
 * [0, 1) range, not [0, 100) range, to add to the zoom scale
 * optional. Defaults to 0.1
 */
export default function zoomToObject({
    zoomContainer,
    focalObject,
    screenBounds,
    isShrinkOnly,
    paddingPercent = 0.1,
}) {
    centerFocalObjectInScreenCenter({
        zoomContainer,
        screenBounds,
        focalObject,
    });

    const bounds = focalObject.getBounds();

    const { isHeightShouldToBeFitted, isWidthShouldToBeFitted } = calcZoomFlags({
        bounds,
        screenBounds,
        paddingPercent,
        isShrinkOnly,
    });

    const consistencyRatio = zoomContainer.scale.x / zoomContainer.scale.y;

    const expectedScale = {
        x: zoomContainer.scale.x,
        y: zoomContainer.scale.y,
    };

    if (isHeightShouldToBeFitted) {
        const expectedHeight = screenBounds.height * (1 - paddingPercent * 2);

        const localDirectionalScale = calcLocalDirectinalScale({
            currentDistance: bounds.height,
            newDistance: expectedHeight,
            direction: 'y',
            zoomContainer,
        });

        expectedScale.y = localDirectionalScale * zoomContainer.scale.y;
        expectedScale.x = expectedScale.y * consistencyRatio;
    } else if (isWidthShouldToBeFitted) {
        const expectedWidth = screenBounds.width * (1 - paddingPercent * 2);

        const localDirectionalScale = calcLocalDirectinalScale({
            currentDistance: bounds.width,
            newDistance: expectedWidth,
            direction: 'x',
            zoomContainer,
        });

        expectedScale.x = localDirectionalScale * zoomContainer.scale.x;
        expectedScale.y = expectedScale.x / consistencyRatio;
    }

    zoomContainer.scale.set(expectedScale.x, expectedScale.y);
}
