import putTextureModificationAtomOnFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/putTextureModificationAtomOnFoSpineAsset';
import getTextureModificationAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getTextureModificationAssignedToSlot';

import buildTextureModificationAtomThatFitsIntoSlot from './buildTextureModificationAtomThatFitsIntoSlot';

export default async function assignFoTextureCompToSlot({
    foTextureComp,
    slotName,
    foSpineAsset,
    setFoSpineAssetTextureModifications,
    setFoSpineAssetLinkOnFoTextureComp,
}) {
    let textureModificationAtom;
    const textureModification = getTextureModificationAssignedToSlot({ foSpineAsset, slotName });
    if (textureModification) {
        textureModificationAtom = textureModification.atomsByCompId[foTextureComp.id];
    }
    if (!textureModificationAtom) {
        textureModificationAtom = await buildTextureModificationAtomThatFitsIntoSlot({
            foTextureComp,
            slotName,
            foSpineAsset,
        });
    }

    putTextureModificationAtomOnFoSpineAsset({
        foSpineAsset,
        setFoSpineAssetTextureModifications,
        textureModificationAtom,
        setFoSpineAssetLinkOnFoTextureComp,
    });
}
