import { useState, useCallback } from 'react';

import { getUserSigninEndpoint } from 'src/utils/backendUtils/apiEndpoints';

export default function useSigninFormSubmit({ email, password, setUser }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [authorizationErrorMessage, setAuthorizationErrorMessage] = useState('');

    const handleSigninFormSubmit = useCallback(
        async function _handleSigninFormSubmit(event) {
            event.preventDefault();
            setIsSubmitting(true);
            setErrors({});
            setAuthorizationErrorMessage('');

            try {
                const userSignoutEndpoint = getUserSigninEndpoint();

                const response = await fetch(userSignoutEndpoint, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        email: email.trim(),
                        password: password.trim(),
                    }),
                });

                if (response.ok) {
                    const userData = await response.json();
                    const token = response.headers.get('x-auth');
                    localStorage.setItem('token', token);
                    setUser(userData);
                } else {
                    const errorData = await response.json();

                    const formErrors = {};
                    const otherErrors = [];
                    errorData.errors.forEach((error) => {
                        if (error.field) {
                            formErrors[error.field] = error;
                        } else {
                            otherErrors.push(error);
                        }
                    });

                    if (Object.keys(formErrors).length) {
                        setErrors(formErrors);
                    }

                    if (response.status === 401) {
                        setAuthorizationErrorMessage(errorData.errors[0].message);
                    }
                    if (otherErrors.length) {
                        const errorString = otherErrors.reduce((errorString, currentError) => {
                            const newErrorString = `${errorString}\n ${currentError.message}`;
                            return newErrorString;
                        }, '');
                        throw new Error(errorString);
                    }
                }
            } catch (err) {
                console.log(err);
            }
            setIsSubmitting(false);
        },
        [setIsSubmitting, email, password, setUser, setErrors, setAuthorizationErrorMessage],
    );

    return { isSubmitting, handleSigninFormSubmit, errors, authorizationErrorMessage };
}
