import { SPEED, CONTENT } from 'src/components/views/SceneEditor/Timeline/constants/trimModes';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';

/**
 * @headTrimAmount > 0 => trim right, otherwise, trim left
 * @tailTrimAmount > 0 => trim right, otherwise, trim left
 * @clipData
 * @trimMode
 * @timeWidth
 */
export default function trimSingleClipData({
    headTrimAmount = 0,
    tailTrimAmount = 0,
    clipData,
    trimMode,
    timeWidth,
}) {
    if (!headTrimAmount && !tailTrimAmount) {
        return clipData;
    }
    if (!timeWidth) {
        console.error(`can't trim a clip's data without a valid timeWidth. 
        Received: ${timeWidth}`);
    }

    const trimmedClipData = { ...clipData };

    const currentTrimmedWidth = getClipWidth({ clipData, timeWidth });

    if (trimMode === SPEED) {
        const totalTrimAmount = tailTrimAmount - headTrimAmount;
        const newTrimmedWidth = currentTrimmedWidth + totalTrimAmount;

        const currentTrimmedWidthAtNormalSpeed = currentTrimmedWidth * clipData.timeScale;

        const newTimeScale = currentTrimmedWidthAtNormalSpeed / newTrimmedWidth;
        trimmedClipData.timeScale = newTimeScale;
    }
    if (trimMode === CONTENT && headTrimAmount) {
        const headTrimAmountAtNormalSpeed = headTrimAmount * clipData.timeScale;
        const headTrimDuration = headTrimAmountAtNormalSpeed / timeWidth;
        const newAnimationStart = trimmedClipData.animationStart + headTrimDuration;
        trimmedClipData.animationStart = Math.max(newAnimationStart, 0);
    }
    if (trimMode === CONTENT && tailTrimAmount) {
        const tailTrimAmountAtNormalSpeed = tailTrimAmount * clipData.timeScale;
        const tailTrimDuration = tailTrimAmountAtNormalSpeed / timeWidth;
        const newAnimationEnd = trimmedClipData.animationEnd + tailTrimDuration;
        trimmedClipData.animationEnd = Math.min(newAnimationEnd, trimmedClipData.originalDuration);
    }

    return trimmedClipData;
}
