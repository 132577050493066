import handleApplyTranscriptClick from './handleApplyTranscriptClick';

export default function buildTranscriptApplicationMenuConfig({
    tracks,
    audioTrack,
    graphemeToAnimationMapByFoSpineAssetId,
    setTrackLayer,
    selectedSceneId,
    timeWidth,
}) {
    const transcriptApplicationMenuChildrenConfig = tracks.allIds.map((trackId) => {
        const track = tracks.byId[trackId];

        const foSpineAssetId = track.foSpineAsset.id;
        const graphemeToAnimationMap = graphemeToAnimationMapByFoSpineAssetId[foSpineAssetId];
        return {
            infoForApplyingTranscript: {
                audioTrack,
                track,
                graphemeToAnimationMap,
                timeWidth,
                selectedSceneId,
                setTrackLayer,
            },
            key: track.foSpineAsset.id,
            content: track.foSpineAsset.id,
            contentElemAttributes: {
                onClick: handleApplyTranscriptClick,
            },
        };
    });

    const transcriptApplicationMenuConfig = {
        key: 'apply transcript to',
        content: 'Apply transcript to',
        placementStyle: { left: '100%', bottom: 0 },
        childrenData: transcriptApplicationMenuChildrenConfig,
    };

    return transcriptApplicationMenuConfig;
}
