import traverseClipDepthFirst from 'src/utils/businessLogic/clipUtils/traverseClipDepthFirst';

import buildErrorMessage from './buildErrorMessage';

export default function buildAnimationClipError({
    clipData,
    spineAssetInstancesByTrackId,
    trackId,
}) {
    let clipError = null;
    const spineAssetInstance = spineAssetInstancesByTrackId[trackId];

    if (!spineAssetInstance) {
        return clipError;
    }

    const missingAnimations = {};
    function getMissingAnimationToCollection({ clip }) {
        const { animationId, title } = clip.data;
        const animation = spineAssetInstance.spineData.findAnimation(animationId);

        if (!animation) {
            missingAnimations[animationId] = title;
        }
    }

    const fakeRootClip = { data: clipData };
    traverseClipDepthFirst({
        clip: fakeRootClip,
        clipCallback: getMissingAnimationToCollection,
    });

    const errorMessage = buildErrorMessage(missingAnimations);
    if (errorMessage) {
        clipError = { message: errorMessage };
    }

    return clipError;
}
