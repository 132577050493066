import { BEZIER } from 'src/pixi/tools/MaskTool/constants/maskTypes';
import drawBgGhostLayer from 'src/pixi/tools/MaskTool/makeBgGhostLayer/drawBgGhostLayer';
import vahramMaskPointsData from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/mockData/vahramFoTextureComp1/vahramMaskPointsData';
// import makeFittedOvalPoints from 'src/pixi/tools/MaskTool/utils/makeFittedOvalPoints';

import makeMaskOutline from './makeMaskOutline';

export default function applyMaskToolToTarget({ maskTool, target }) {
    maskTool.clear();
    maskTool.visible = true;

    if (!target.maskData) {
        target.maskData = {
            type: BEZIER,
            points: JSON.parse(JSON.stringify(vahramMaskPointsData)),
            // points: [],
            // points: makeFittedOvalPoints({ target }),
        };
    }

    const maskOutline = makeMaskOutline({ maskTool });
    maskTool.maskOutline = maskOutline;

    maskTool.pointControlsById = {};

    maskTool.target = target;
    target.maskTool = maskTool;

    maskTool.addChild(maskOutline);

    const { bgGhostLayer, app } = maskTool;
    const { width, height } = app.screen;
    drawBgGhostLayer({ bgGhostLayer, width, height });

    maskTool.positionControls();
}
