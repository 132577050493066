import iterateOverBezierCurves from 'src/pixi/tools/MaskTool/utils/iterateOverBezierCurves';
import { calcDistanceBetweenTwoPoints } from 'src/utils/mathUtils/geoMathUtils';

function overrideClosestBezierInfoIfPointCloser({ point, contextProps, bezierIndex }) {
    const { closestBezierInfo, targetPosition } = contextProps;
    const distance = calcDistanceBetweenTwoPoints({ point1: point, point2: targetPosition });

    if (distance < closestBezierInfo.minWidth) {
        closestBezierInfo.minWidth = distance;
        closestBezierInfo.index = bezierIndex;
    }
}

export default function calcClosestBezierIndex({ points, targetPosition }) {
    const closestBezierInfo = { minWidth: Number.POSITIVE_INFINITY, index: 0 };

    iterateOverBezierCurves({
        points,
        callback: overrideClosestBezierInfoIfPointCloser,
        contextProps: { closestBezierInfo, targetPosition },
    });

    return closestBezierInfo.index;
}
