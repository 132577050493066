import convertCornerPointToTangentPoint from 'src/pixi/tools/MaskTool/utils/convertCornerPointToTangentPoint';
import convertTangetPointToCornerPoint from 'src/pixi/tools/MaskTool/utils/convertTangetPointToCornerPoint';

export default function togglePoint({ point, points }) {
    const isCornerPoint = !point.tangent1 && !point.tangent2;
    if (!isCornerPoint) {
        convertTangetPointToCornerPoint(point);
        return;
    }

    if (!points) {
        throw new Error('points is required for toggleing a corner point into a tangented one');
    }
    if (points.length > 1) {
        // figure out how may points is needed to care about neighbouring points
    }
    convertCornerPointToTangentPoint({
        points,
        point,
    });
}
