import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { tracksLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import refLike from 'src/components/shared/propTypes/refLike';
import { audioTracksLike } from 'src/components/views/AudioSelector/audioSelectorSlice/propTypes';
import calcTrackControllerHeight from 'src/components/views/SceneEditor/Timeline/TrackControllers/utils/calcTrackControllerHeight';
import withRouter from 'src/reduxStore/utils/withRouter';

import AudioTrackController from './AudioTrackController';
import AssetTrackController from './AssetTrackController';

import styles from './styles.module.scss';

function TrackControllers(props) {
    const { className, tracks, audioTracks, timelineLabelElemRef } = props;

    return (
        <div className={classnames(styles.TrackControllers, className)}>
            <div className={styles.TrackControllers__timelineLabel} ref={timelineLabelElemRef}>
                timeline
            </div>
            {tracks.allIds.map((trackId) => {
                const track = tracks.byId[trackId];
                const trackControllerHeight = calcTrackControllerHeight(track.layers);
                return (
                    <AssetTrackController
                        className={styles.TrackControllers__trackController}
                        track={track}
                        key={track.trackId}
                        height={trackControllerHeight}
                    />
                );
            })}
            {audioTracks.map((audioTrack) => {
                return (
                    <AudioTrackController
                        className={styles.TrackControllers__trackController}
                        audioTrack={audioTrack}
                        key={audioTrack.id}
                    />
                );
            })}
        </div>
    );
}

TrackControllers.propTypes = {
    className: PropTypes.string,
    tracks: tracksLike.isRequired,
    audioTracks: audioTracksLike.isRequired,
    timelineLabelElemRef: refLike.isRequired,
};

TrackControllers.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const {
        audioSelector: { audioTracks: allAudioTracks },
    } = state;

    const { sceneId: selectedSceneId } = ownProps.router.params;
    const { selectedAudioTrackId } = state.sceneSelector.scenes.byId[selectedSceneId];

    // todo - this needs to happen in Timeline and be used here and in AudioTracks.
    // Do it when AudioTracks is implemented.
    // audioTrackIds will probably be comprised of speech track ids of each character and maybe music.
    const audioTrackIds = selectedAudioTrackId ? [selectedAudioTrackId] : [];
    const audioTracks = audioTrackIds
        .map((audioTrackId) => {
            const audioTrack = allAudioTracks.find((audioTrack) => {
                return audioTrack.id === audioTrackId;
            });
            return audioTrack;
        })
        .filter((audioTrack) => audioTrack);

    return { audioTracks };
}

export default withRouter(connect(mapState)(TrackControllers));
