export default function calcPointsRelativeToMaskTool({ maskTool, points }) {
    const { target } = maskTool;
    if (!target) {
        console.error(
            "cannont calculate pointsRelativeToMaskTool if the mask tool doesn't have a target",
        );
        return points;
    }

    const pointsRelativeToMaskTool = points.map((point) => {
        const pointRelativeToMaskTool = { ...point };

        const mainGlobalPosition = target.toGlobal(point.main);
        const mainPositionRelativeToMaskTool = maskTool.toLocal(mainGlobalPosition);
        pointRelativeToMaskTool.main = mainPositionRelativeToMaskTool;

        if (point.tangent1) {
            const tangent1GlobalPosition = target.toGlobal(point.tangent1);
            const tangent1PositionRelativeToMaskTool = maskTool.toLocal(tangent1GlobalPosition);
            pointRelativeToMaskTool.tangent1 = tangent1PositionRelativeToMaskTool;
        }

        if (point.tangent2) {
            const tangent1GlobalPosition = target.toGlobal(point.tangent2);
            const tangent1PositionRelativeToMaskTool = maskTool.toLocal(tangent1GlobalPosition);
            pointRelativeToMaskTool.tangent2 = tangent1PositionRelativeToMaskTool;
        }

        return pointRelativeToMaskTool;
    });

    return pointsRelativeToMaskTool;
}
