import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import StyledForm from 'src/components/shared/formFactory/StyledForm';
import FormElementGroup, {
    formElementGroupMessageTypes,
} from 'src/components/shared/FormElementGroup';
import LoadingToolButton from 'src/components/shared/buttonFactory/LoadingToolButton';
import DangerMessage from 'src/components/shared/DangerMessage';
import { setUser } from 'src/components/views/UserProfile/userProfileSlice';
import useSigninFormSubmit from 'src/components/views/Signin/hooks/useSigninFormSubmit';
import useSigninFormPassword from 'src/components/views/Signin/hooks/useSigninFormPassword';
import useSigninFormEmail from 'src/components/views/Signin/hooks/useSigninFormEmail';
import withRouter from 'src/reduxStore/utils/withRouter';
import { locationLike } from 'src/components/shared/propTypes/routerLike';

import styles from './styles.module.scss';

function Signin(props) {
    const { className, isAuthenticated, setUser, fromLocation } = props;

    const { email, handleEmailChange } = useSigninFormEmail();
    const { password, handlePasswordChange } = useSigninFormPassword();
    const {
        isSubmitting,
        handleSigninFormSubmit,
        errors,
        authorizationErrorMessage,
    } = useSigninFormSubmit({
        email,
        password,
        setUser,
    });

    if (isAuthenticated) {
        const props = fromLocation ? { to: fromLocation, replace: true } : { to: '/' };
        return <Navigate {...props} />;
    }

    const emailMessages = [];
    if (errors.email) {
        emailMessages.push({
            type: formElementGroupMessageTypes.ALERT,
            content: errors.email.message,
        });
    }

    const passwordMessages = [];
    if (errors.password) {
        passwordMessages.push({
            type: formElementGroupMessageTypes.ALERT,
            content: errors.password.message,
        });
    }

    return (
        <div className={classnames(styles.Signin, className)}>
            <StyledForm onSubmit={handleSigninFormSubmit} headerContent='Sign In'>
                <FormElementGroup
                    label='Email'
                    elementProps={{
                        value: email,
                        onChange: handleEmailChange,
                    }}
                    messages={emailMessages}
                />
                <FormElementGroup
                    label='Password'
                    elementProps={{
                        value: password,
                        type: 'password',
                        onChange: handlePasswordChange,
                    }}
                    messages={passwordMessages}
                />
                {authorizationErrorMessage && (
                    <DangerMessage>{authorizationErrorMessage}</DangerMessage>
                )}
                <LoadingToolButton type='submit' isLoading={isSubmitting} isDisabled={isSubmitting}>
                    Submit
                </LoadingToolButton>
            </StyledForm>
        </div>
    );
}

Signin.propTypes = {
    className: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    setUser: PropTypes.func.isRequired,
    fromLocation: locationLike,
};

Signin.defaultProps = {
    className: '',
    fromLocation: null,
};

function mapState(state) {
    const { userData } = state.userProfile;
    return {
        isAuthenticated: !!userData,
    };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    const { router, ...otherOwnProps } = ownProps;

    return {
        ...stateProps,
        ...dispatchProps,
        fromLocation: router.location.state?.fromLocation,
        ...otherOwnProps,
    };
}
export default withRouter(
    connect(
        mapState,
        {
            setUser,
        },
        mergeProps,
    )(Signin),
);
