import expressions from 'src/utils/businessLogic/expressions/constants/expressions';

export const fist = {
    isStatic: true,
    expressions: {
        [expressions.ANGRY]: { rank: 50 },
        [expressions.ASSERT]: { rank: 50 },
        [expressions.THREAT]: { rank: 50 },
    },
};
export const handFanning = {
    expressions: {
        [expressions.NERVOUS]: { rank: 70 },
        [expressions.DISGUST]: { rank: 50 },
        [expressions.INSECURE]: { rank: 20 },
        [expressions.RELIEF]: { rank: 30 },
        [expressions.UNCERTAIN]: { rank: 10 },
    },
};
export const handFidgeting = {
    expressions: {
        [expressions.NERVOUS]: { rank: 70 },
        [expressions.BORED]: { rank: 40 },
        [expressions.FRAUSTRATED]: { rank: 30 },
        [expressions.UNCERTAIN]: { rank: 30 },
        [expressions.ANNOYED]: { rank: 10 },
    },
};
export const armToFaceLevel = {
    isStatic: true,
    expressions: {
        [expressions.INDIFFERENT]: { rank: 50 },
        [expressions.UNCERTAIN]: { rank: 50 },
        [expressions.FRAUSTRATED]: { rank: 50 },
    },
};
export const handBackPalmOut = {
    isStatic: true,
    expressions: {
        [expressions.THINK]: { rank: 50 },
    },
};
export const handFrontPalmIn = {
    isStatic: true,
    expressions: {
        [expressions.THINK]: { rank: 40 },
        [expressions.OPEN]: { rank: 70 },
    },
};
export const handOkIn = {
    isStatic: true,
    expressions: {
        [expressions.APPROVE]: { rank: 50 },
    },
};
export const handOpenTenseIn = {
    isStatic: true,
    expressions: {
        [expressions.ANGRY]: { rank: 50 },
        [expressions.FRAUSTRATED]: { rank: 50 },
        [expressions.ASSERT]: { rank: 20 },
    },
};
export const handPalmUpIn = {
    isStatic: true,
    expressions: {
        [expressions.OPEN]: { rank: 50 },
        [expressions.INDIFFERENT]: { rank: 50 },
        [expressions.UNCERTAIN]: { rank: 50 },
    },
};
export const handQuoteIn = {
    expressions: {
        [expressions.ALTERNATE_MEANING]: { rank: 50 },
    },
};
export const handShakeIndexFingerIn = {
    isStatic: true,
    expressions: {
        [expressions.ANGRY]: { rank: 50 },
        [expressions.ACCENT]: { rank: 50 },
    },
};
export const handShake2FingersIn = {
    isStatic: true,
    expressions: {
        [expressions.APPROVE]: { rank: 10 },
        [expressions.OPEN]: { rank: 25 },
    },
};
export const handMiddleFinger = {
    isStatic: true,
    expressions: {
        [expressions.ANGRY]: { rank: 90 },
        [expressions.DISAPPROVE]: { rank: 20 },
        [expressions.DISGUST]: { rank: 5 },
        [expressions.FRAUSTRATED]: { rank: 15 },
    },
};
export const handThumbDownIn = {
    isStatic: true,
    expressions: {
        [expressions.DISAPPROVE]: { rank: 50 },
    },
};
export const handThumbUpOut = {
    isStatic: true,
    expressions: {
        [expressions.APPROVE]: { rank: 50 },
    },
};
export const handMoveAround = {
    isStatic: true,
    expressions: {
        [expressions.THINK]: { rank: 50 },
    },
};
export const handRaiseToChestLevel = {
    isStatic: true,
    expressions: {
        [expressions.THINK]: { rank: 50 },
    },
};
export const armsBent = {
    isStatic: true,
    expressions: {
        [expressions.FEAR]: { rank: 45 },
        [expressions.ACCENT]: { rank: 15 },
        [expressions.INSECURE]: { rank: 30 },
        [expressions.ASSERT]: { rank: 10 },
        [expressions.ASHAMED]: { rank: 10 },
        [expressions.UNCERTAIN]: { rank: 20 },
    },
};
export const armsExpanded = {
    isStatic: true,
    expressions: {
        [expressions.OPEN]: { rank: 75 },
        [expressions.ACCENT]: { rank: 50 },
        [expressions.CONFUSED]: { rank: 30 },
        [expressions.ASSERT]: { rank: 10 },
    },
};
export const donkey = {
    expressions: {
        [expressions.SILLY]: { rank: 30 },
        [expressions.ANNOYED]: { rank: 10 },
        [expressions.ALTERNATE_MEANING]: { rank: 10 },
    },
};
export const threeHops = {
    expressions: {
        [expressions.ACCENT]: { rank: 50 },
        [expressions.THINK]: { rank: 30 },
        [expressions.ASSERT]: { rank: 20 },
    },
};
export const handThumbAcrossThroat = {
    expressions: {
        [expressions.THREAT]: { rank: 70 },
        [expressions.ANGRY]: { rank: 20 },
    },
};
export const handStrokingChin = {
    isStatic: true,
    expressions: {
        [expressions.THINK]: { rank: 70 },
        [expressions.CONFUSED]: { rank: 20 },
        [expressions.SURPRISE]: { rank: 20 },
    },
};
export const handToMounth = {
    isStatic: true,
    expressions: {
        [expressions.ASHAMED]: { rank: 50 },
        [expressions.ANNOYED]: { rank: 10 },
        [expressions.BORED]: { rank: 20 },
        [expressions.NERVOUS]: { rank: 40 },
        [expressions.TIRED]: { rank: 10 },
        [expressions.UNCERTAIN]: { rank: 30 },
        [expressions.FEAR]: { rank: 20 },
        [expressions.SURPRISE]: { rank: 40 },
    },
};
export const handToEye = {
    isStatic: true,
    expressions: {
        [expressions.ASHAMED]: { rank: 50 },
        [expressions.ANNOYED]: { rank: 10 },
        [expressions.NERVOUS]: { rank: 40 },
        [expressions.TIRED]: { rank: 10 },
        [expressions.UNCERTAIN]: { rank: 30 },
        [expressions.FEAR]: { rank: 40 },
        [expressions.SURPRISE]: { rank: 20 },
    },
};
export const handToEar = {
    isStatic: true,
    expressions: {
        [expressions.ASHAMED]: { rank: 50 },
        [expressions.ANNOYED]: { rank: 20 },
        [expressions.NERVOUS]: { rank: 40 },
        [expressions.TIRED]: { rank: 20 },
        [expressions.UNCERTAIN]: { rank: 30 },
        [expressions.FEAR]: { rank: 20 },
    },
};
