import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import TextureEditorContext from 'src/components/views/TextureRouteManager/TextureEditor/context/TextureEditorContext';
import useHandleCloneFoTextureOntoStage from 'src/components/views/TextureRouteManager/TextureEditor/SortableFoTextureList/FoTextureList/SortableFoTextureListItem/FoTextureListItem/Buttons/CloneButton/hooks/useHandleCloneFoTextureOntoStage';

import styles from './styles.module.scss';

export default function CloneButton(props) {
    const { className, foTextureId, onFoTextureSelectionChange, ...attributes } = props;

    const { updateFoTextures, foTextureComp } = useContext(TextureEditorContext);

    const handleCloneFoTextureOntoStage = useHandleCloneFoTextureOntoStage({
        onFoTextureSelectionChange,
        updateFoTextures,
        foTextures: foTextureComp.foTextures,
        foTextureId,
    });

    return (
        <ToolButton
            className={classnames(styles.CloneButton, className)}
            onClick={handleCloneFoTextureOntoStage}
            {...attributes}
        >
            clone
        </ToolButton>
    );
}

CloneButton.propTypes = {
    className: PropTypes.string,
    foTextureId: PropTypes.string.isRequired,
};

CloneButton.defaultProps = {
    className: '',
};
