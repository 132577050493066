import { isObject } from 'lodash';

export default function hasObjectTruthyProp(obj, callback = (f) => !!f, isDeep) {
    const isAnyValueTruthy = Object.values(obj).some((value) => {
        if (isObject(value) && isDeep) {
            return hasObjectTruthyProp(value, callback, isDeep);
        }
        return callback(value);
    });

    return isAnyValueTruthy;
}
