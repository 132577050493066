import { useCallback } from 'react';

export default function useHandleNameInputChange({ foTextureId, foTextures, updateFoTextures }) {
    return useCallback(
        function _handleNameInputChange(event) {
            const name = event.target.value;

            const updatedFoTextures = foTextures.map((foTexture) => {
                if (foTexture.id !== foTextureId) {
                    return foTexture;
                }

                const newFoTexture = { ...foTexture, name };
                return newFoTexture;
            });

            updateFoTextures(updatedFoTextures);
        },
        [foTextures, updateFoTextures, foTextureId],
    );
}
