import getFoTranscriptWordEndTime from 'src/utils/businessLogic/transcript/getFoTranscriptWordEndTime';

import buildWordClipGroup from './buildWordClipGroup';
import fillSilenceWithClip from './fillSilenceWithClip';

export default function convertFoTranscriptWordsToClips({
    foTranscriptWords,
    timeWidth,
    foSpineAssetId,
    graphemeToAnimationMap,
}) {
    const clipsById = {};
    foTranscriptWords.forEach((foTranscriptWord, index) => {
        const wordClipGroup = buildWordClipGroup({
            foTranscriptWord,
            timeWidth,
            foSpineAssetId,
            graphemeToAnimationMap,
        });

        clipsById[wordClipGroup.clipId] = wordClipGroup;

        const nextFoTranscriptWord = foTranscriptWords[index + 1];
        if (nextFoTranscriptWord) {
            const currClipEndTime = getFoTranscriptWordEndTime(foTranscriptWord);
            fillSilenceWithClip({
                nextFoTranscriptWord,
                currClipEndTime,
                timeWidth,
                foSpineAssetId,
                clipsById,
                graphemeToAnimationMap,
            });
        }
    });

    return clipsById;
}
