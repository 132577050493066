import { useRef, useCallback, useContext } from 'react';

import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import calcZoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcZoomedValue';

export default function useScrollTimelineToTimeIndicatorAfterZooming({
    timeWidth,
    zoomLevel,
    horizontalScrollElemRef,
}) {
    const { currentTimeManager } = useContext(SceneEditorContext);

    const previousZoomLevelRef = useRef(zoomLevel);

    const scrollTimelineToTimeIndicatorAfterZooming = useCallback(
        function _scrollTimellineToTimeIndicator() {
            const horizontalScrollElem = horizontalScrollElemRef.current;
            const currentScroll = horizontalScrollElem.scrollLeft;

            const { currentTime } = currentTimeManager;
            const timeIndicatorPos = currentTime * timeWidth;
            const zoomedTimeIndicatorPos = calcZoomedValue({
                value: timeIndicatorPos,
                zoomLevel,
            });

            const previousZoomLevel = previousZoomLevelRef.current;
            const zoomedTimeIndicatorPreviousPos = calcZoomedValue({
                value: timeIndicatorPos,
                zoomLevel: previousZoomLevel,
            });
            previousZoomLevelRef.current = zoomLevel;

            const timeIndicatorPosChange = zoomedTimeIndicatorPos - zoomedTimeIndicatorPreviousPos;
            if (timeIndicatorPosChange) {
                horizontalScrollElem.scrollLeft = currentScroll + timeIndicatorPosChange;
            }
        },
        [horizontalScrollElemRef, timeWidth, currentTimeManager, zoomLevel],
    );

    return { scrollTimelineToTimeIndicatorAfterZooming };
}
