import getConflictInfo from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getConflictInfo';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

import resolveRightConflictAggressively from './resolveRightConflictAggressively';
import resolveLeftConflictAggressively from './resolveLeftConflictAggressively';

export default function insertAggressively({
    newClip,
    clipsById,
    conflictInfo,
    timeWidth,
    trimMode,
}) {
    const {
        //
        sortedClips,
        isConflictToRight,
        rightNeighbourIndex,
        isConflictToLeft,
        leftNeighbour,
    } = conflictInfo || getConflictInfo({ newClip, clipsById, timeWidth });

    const newClipEnd = getClipEnd({ clip: newClip, timeWidth });

    let newClipsById = clipsById;

    if (isConflictToRight) {
        newClipsById = resolveRightConflictAggressively({
            sortedClips,
            rightNeighbourIndex,
            newClipEnd,
            clipsById: newClipsById,
            timeWidth,
            trimMode,
        });
    }

    if (isConflictToLeft) {
        newClipsById = resolveLeftConflictAggressively({
            leftNeighbour,
            newClip,
            clipsById: newClipsById,
            timeWidth,
            trimMode,
        });
    }

    newClipsById = { ...newClipsById };
    newClipsById[newClip.clipId] = newClip;

    return newClipsById;
}
