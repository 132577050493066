import { useEffect, useContext, useRef } from 'react';

import useHandleCurrentTimeMonitorTextUpdate from 'src/components/views/SceneEditor/Monitor/hooks/useSetupMonitorApp/useHandleCurrentTimeMonitorTextUpdate';
import useHandleSpeechOverlayUpdate from 'src/components/views/SceneEditor/Monitor/hooks/useSetupMonitorApp/useHandleSpeechOverlayUpdate';
import useSetMonitorOverlaysVisibility from 'src/components/views/SceneEditor/Monitor/hooks/useSetupMonitorApp/useSetMonitorOverlaysVisibility';
import placeSpineAssetInstanceOnScreen from 'src/spine/utils/placeSpineAssetInstanceOnScreen';
import mountPixi from 'src/pixi/utils/mountPixi';
import pixiStore from 'src/pixi/pixiStore';
import loadFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/loadFoSpineAsset';
import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';

import setupMonitorOverlays from './setupMonitorOverlays';

export default function useSetupMonitorApp({
    monitorRef,
    jumpAppToTime,
    foTextureComps,
    setIsPixiMounted,
    foSpineAssetsByTrackId,
    spineAssetInstancesByTrackId,
    foTranscriptWords,
    isMonitorOverlaysShown,
}) {
    const { currentTimeManager } = useContext(SceneEditorContext);

    const monitorOverlaysDispalyObjsRef = useRef();
    const handleCurrentTimeMonitorTextUpdate = useHandleCurrentTimeMonitorTextUpdate({
        currentTimeManager,
        monitorOverlaysDispalyObjsRef,
    });
    const handleSpeechOverlayUpdate = useHandleSpeechOverlayUpdate({
        currentTimeManager,
        monitorOverlaysDispalyObjsRef,
        foTranscriptWords,
    });

    const setMonitorOverlaysVisibility = useSetMonitorOverlaysVisibility({
        monitorOverlaysDispalyObjsRef,
        jumpAppToTime,
        pixiStore,
        currentTimeManager,
    });

    useEffect(function _useSetupMonitorApp() {
        const app = mountPixi(monitorRef.current);

        setupMonitorOverlays({
            handleCurrentTimeMonitorTextUpdate,
            handleSpeechOverlayUpdate,
            monitorOverlaysDispalyObjsRef,
            isMonitorOverlaysShown,
        });

        app.ticker.stop();

        const spineInstancesLoadPromises = Object.keys(foSpineAssetsByTrackId).map(
            async function _setupFoSpineAssetsInMonitor(trackId) {
                try {
                    const foSpineAsset = foSpineAssetsByTrackId[trackId];

                    const spineAssetInstance = await loadFoSpineAsset({
                        app,
                        foSpineAsset,
                        foTextureComps,
                    });

                    spineAssetInstancesByTrackId[trackId] = spineAssetInstance;

                    placeSpineAssetInstanceOnScreen({
                        spineAssetInstance,
                        app,
                    });

                    app.stage.addChild(spineAssetInstance);
                } catch (error) {
                    console.log('error: ', error);
                }
            },
        );

        Promise.all(spineInstancesLoadPromises).then(function _completPixiLoad() {
            setIsPixiMounted(true);
            jumpAppToTime(0);
        });

        return function _cleanup() {
            app.ticker.start();
            pixiStore.pixiAppManager.clearPixiApp();

            app.ticker.remove(handleCurrentTimeMonitorTextUpdate);
            app.ticker.remove(handleSpeechOverlayUpdate);
            setIsPixiMounted(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { setMonitorOverlaysVisibility };
}
