import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

import Layover from 'src/components/shared/Layover';

import './styles.scss';

export default function Modal(props) {
    const { className, onClickOutside, children, isLayoverVisible, ...attributes } = props;

    const handleLayoverClick = useCallback(
        function _handleLayoverClick(event) {
            if (event.target === event.currentTarget) {
                onClickOutside(event);
            }
        },
        [onClickOutside],
    );

    const modal = (
        <Layover
            isTransparent={!isLayoverVisible}
            onMouseDown={handleLayoverClick}
            className='ModalLayover'
        >
            <div className={classnames('Modal', className)} {...attributes}>
                {children}
            </div>
        </Layover>
    );

    return createPortal(modal, document.getElementById('root'));
}

Modal.propTypes = {
    className: PropTypes.string,
    isLayoverVisible: PropTypes.bool,
    onClickOutside: PropTypes.func,
};

Modal.defaultProps = {
    className: '',
    isLayoverVisible: false,
    onClickOutside: noop,
};
