export default function makeKnobGrid({ gridSize, makeKnob, transformTool }) {
    const grid = {};

    for (let y = 0; y < gridSize.y; y++) {
        for (let x = 0; x < gridSize.x; x++) {
            const isOnEdge = x === 0 || x === gridSize.x - 1 || y === 0 || y === gridSize.y - 1;
            if (isOnEdge) {
                const knob = makeKnob(transformTool);

                const gridPosition = {
                    x: x / (gridSize.x - 1),
                    y: y / (gridSize.y - 1),
                };
                knob.gridPosition = gridPosition;

                grid[`${x}${y}`] = knob;
            }
        }
    }

    return grid;
}
