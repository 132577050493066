import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from 'src/components/constants/routes';

export default function useHandleSelectableListItemClick({ sceneId }) {
    const navigate = useNavigate();

    return useCallback(
        function _handleSceneClick() {
            navigate(`/${routes.SCENES}/${sceneId}`);
        },
        [sceneId, navigate],
    );
}
