import { useEffect } from 'react';

import pixiStore from 'src/pixi/pixiStore';
import getChildById from 'src/pixi/utils/getChildById';
import applyTextureModificationsToFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/applyTextureModificationsToFoSpineAsset';
import getTextureModificationAtomCurrentlyAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getTextureModificationAtomCurrentlyAssignedToSlot';

export default function useUpdateTextureModificationsInPixi({
    foTextureComps,
    foSpineAsset,
    selectedSlotName,
    isFoSpineAssetLoaded,
}) {
    useEffect(
        function _updateTextureModificationsInPixi() {
            if (!isFoSpineAssetLoaded) {
                return;
            }
            const app = pixiStore.pixiAppManager.getPixiApp();
            const textureModificationAtom = getTextureModificationAtomCurrentlyAssignedToSlot({
                foSpineAsset,
                slotName: selectedSlotName,
            });

            const spineAssetInstance = getChildById({
                parent: app.stage,
                id: foSpineAsset.id,
            });

            applyTextureModificationsToFoSpineAsset({
                app,
                foSpineAsset,
                foTextureComps,
                spineAssetInstance,
            }).then(() => {
                if (selectedSlotName && textureModificationAtom) {
                    spineAssetInstance.hackTextureBySlotName(selectedSlotName, PIXI.Texture.EMPTY);
                }
            });
        },
        [foSpineAsset, selectedSlotName, foTextureComps, isFoSpineAssetLoaded],
    );
}
