import PropTypes from 'prop-types';

export const animationLike = PropTypes.shape({
    animationId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
});

export const animationsByIdLike = PropTypes.objectOf(animationLike);

const animationTreeBranchShape = {
    title: PropTypes.string.isRequired,
};
export const animationTreeBranchLike = PropTypes.shape(animationTreeBranchShape);

export const animationTreeLeafLike = PropTypes.shape({
    title: PropTypes.string.isRequired,
    animation: animationLike.isRequired,
});

export const animationTreeNodeLike = PropTypes.oneOfType([
    animationTreeBranchLike,
    animationTreeLeafLike,
]);

export const animationTreeNodesLike = PropTypes.arrayOf(animationTreeNodeLike);

animationTreeBranchShape.children = animationTreeNodesLike.isRequired;
