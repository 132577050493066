export const rotateLayerZIndices = Object.freeze({
    GHOST_LAYER: 1,
    RESIZE_KNOB: 2,
    ROTATE_KNOB: 3,
    PIVOT_KNOB: 4,
});

export const transformToolZIndices = Object.freeze({
    BG_GHOST_LAYER: 0,
    TRANSLATE_LAYER: 1,
});
