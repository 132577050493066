import positionResizeKnobs from './positionResizeKnobs';
import positionRotateKnobs from './positionRotateKnobs';
import positionGhostLayer from './positionGhostLayer';
import positionTranslateLayer from './positionTranslateLayer';
import positionRotateLayer from './positionRotateLayer';

export default function positionKnobs(transformTool) {
    positionTranslateLayer(transformTool);

    positionRotateLayer(transformTool);

    positionGhostLayer(transformTool);

    transformTool.pivotKnob.position.set(0, 0);

    positionResizeKnobs(transformTool);

    positionRotateKnobs(transformTool);
}
