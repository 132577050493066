import { useCallback, useRef } from 'react';

export default function useHandleToolClear({ setSelectedFoTextureId }) {
    const paramsRef = useRef();
    paramsRef.current = { setSelectedFoTextureId };

    const handleToolClear = useCallback(
        function _handleToolClear() {
            const { setSelectedFoTextureId } = paramsRef.current;
            setSelectedFoTextureId('');
        },
        [paramsRef],
    );

    return handleToolClear;
}
