import { Graphics } from 'pixi.js';

export default function drawBgGhostLayerGraphics({
    bgGhostLayer: receivedBgGhostLayer,
    width,
    height,
    isDebug = false,
}) {
    const bgGhostLayer = receivedBgGhostLayer || new Graphics();
    const alpha = isDebug ? 0.1 : 0.0001;

    bgGhostLayer.clear();
    bgGhostLayer.beginFill(0x00ff00, alpha);
    bgGhostLayer.drawRect(0, 0, width, height);
    bgGhostLayer.endFill();

    return bgGhostLayer;
}
