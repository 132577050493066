import {
    KNOB_INTERACTION_SCALE,
    KNOB_DEFAULT_SCALE,
} from 'src/pixi/tools/TransformTool/constants/transformKnobs';
import calcGlobalScaleRatioOf2DisplayObject from 'src/pixi/utils/calcGlobalScaleRatioOf2DisplayObject';
import * as eventNames from 'src/pixi/tools/TransformTool/constants/eventNames';

import calcResize from './calcResize';

export function handleResizeKnobDragStart(event) {
    this.data = event.data;
    this.isDragging = true;
    this.scale.set(KNOB_INTERACTION_SCALE);
    this.lastDragPosition = this.data.getLocalPosition(this.parent);
}

export function handleResizeKnobDragEnd() {
    this.isDragging = false;
    this.data = null;
    this.scale.set(KNOB_DEFAULT_SCALE);
    this.lastDragPosition = null;

    const { transformTool } = this;
    transformTool.runEventHandlersFor(eventNames.RESIZE_END);
    transformTool.runEventHandlersFor(eventNames.ANY_DRAG_END);
}

export function handleResizeKnobDragMove() {
    if (this.isDragging) {
        const { transformTool, gridPosition } = this;
        const { target } = transformTool;
        const newPosition = this.data.getLocalPosition(this.parent);

        const scaleCorrection = calcGlobalScaleRatioOf2DisplayObject({
            displayObject1: target.parent,
            displayObject2: transformTool,
        });

        const originalDiff = {
            x: (newPosition.x - this.lastDragPosition.x) / scaleCorrection.x,
            y: (newPosition.y - this.lastDragPosition.y) / scaleCorrection.y,
        };

        this.lastDragPosition = newPosition;

        const calculatedDiff = calcResize({ target, gridPosition, originalDiff });
        const newWidth = target.originalSize.width * target.scale.x + calculatedDiff.x;
        const newHeight = target.originalSize.height * target.scale.y + calculatedDiff.y;
        const newScale = {
            x: newWidth / target.originalSize.width,
            y: newHeight / target.originalSize.height,
        };
        target.scale.set(newScale.x, newScale.y);

        transformTool.positionKnobs();
    }
}
