import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

export default function Line(props) {
    const { className, isVertical, ...attributes } = props;

    return (
        <div
            className={classnames('Line', { 'Line--vertical': isVertical }, className)}
            {...attributes}
        />
    );
}

Line.propTypes = {
    className: PropTypes.string,
    isVertical: PropTypes.bool,
};

Line.defaultProps = {
    className: '',
    isVertical: false,
};
