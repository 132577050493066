import { getNextItemInLoopedIteration } from 'src/utils/arrayUtils';
import calcBezierCurveLength from 'src/utils/mathUtils/bezierUtils/calcBezierCurveLength';
import iterateOverBezierCurve from 'src/utils/mathUtils/bezierUtils/iterateOverBezierCurve';

export default function iterateOverBezierCurves({
    points,
    approximationDensityPerScaleUnit = 20,
    approximationScaleLength = 100,
    callback,
    contextProps,
}) {
    for (let i = 0; i < points.length; i++) {
        const currentPoint = points[i];
        const nextPoint = getNextItemInLoopedIteration({ array: points, currentIndex: i });

        const destinationPoint = nextPoint.main;
        const controlPoint1 = currentPoint.tangent1 || currentPoint.main;
        const controlPoint2 = nextPoint.tangent2 || nextPoint.main;
        const bezierLength = calcBezierCurveLength({
            startPoint: currentPoint.main,
            controlPoint1,
            controlPoint2,
            destinationPoint,
        });

        const numberOfDensityUnits = bezierLength / approximationScaleLength;
        const numberOfApproximationSegments =
            numberOfDensityUnits * approximationDensityPerScaleUnit;

        const updatedCallback = function _updatedCallback(props) {
            callback({ ...props, bezierIndex: i });
        };
        iterateOverBezierCurve({
            startPoint: currentPoint.main,
            controlPoint1,
            controlPoint2,
            destinationPoint,
            numberOfApproximationSegments,
            callback: updatedCallback,
            contextProps,
        });
    }
}
