import ashotMaskPointsData from './ashotMaskPointsData';

export default {
    bakedUrl: '',
    id: 'fo_texture_comp_ashot-1',
    name: 'Ashot',
    foTextures: [
        {
            id: 'fo_texture_ashot-1',
            maskData: {
                points: ashotMaskPointsData,
                type: 'bezier',
            },
            name: 'ashot.jpg',
            transform: {
                pivot: {
                    x: 187.34534816345013,
                    y: 258.33736761553945,
                },
                position: {
                    x: 348.420537110929,
                    y: 152.0770660891912,
                },
                rotation: 0,
                scale: {
                    x: 0.2585973848903451,
                    y: 0.2585973848903456,
                },
            },
            url: '/images/ashot.jpg',
            zIndex: 1,
        },
    ],
    linkedFoSpineAssetIds: ['zombie_01'],
};
