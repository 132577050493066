export default Object.freeze({
    HAPPY: 'show happiness',
    SAD: 'show sadness',
    DISGUST: 'show disguast',
    SILLY: 'be silly',
    ANGRY: 'show anger',
    CONFUSED: 'show confusion',
    FEAR: 'show fear',
    NERVOUS: 'show nervousness',
    SURPRISE: 'show surprise',
    ANNOYED: 'show annoyance',
    INDIFFERENT: 'show indifference',
    BORED: 'show boredom',
    INSECURE: 'show insecurity',
    UNCERTAIN: 'show uncertainty / doubt',
    OPEN: 'show openness',
    FRAUSTRATED: 'show fraustration',
    TIRED: 'show tiredness',
    ALTERNATE_MEANING: 'imply alternate meaning',
    ACCENT: 'accent a thought',
    RELIEF: 'show relief',
    THINK: 'think',
    ASSERT: 'assert',
    THREAT: 'threaten',
    CRINGE: 'cringe',
    APPROVE: 'approve',
    DISAPPROVE: 'disapprove',
    ASHAMED: 'ashamed',
    CONFIDENT: 'confident',
});
