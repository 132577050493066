export default function getGlobalRotation(displayObject) {
    let current = displayObject;
    let { parent, rotation } = displayObject;

    while (parent) {
        current = parent;
        rotation += current.rotation;
        parent = current.parent;
    }

    return rotation;
}
