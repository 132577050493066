import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    MASK_TOOL,
    TRANSFORM_TOOL,
    DEFAULT_SELECTED_TOOL_TYPE,
} from 'src/components/views/TextureRouteManager/TextureEditor/constants/toolTypes';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import handleToolTypeSelectionChange from 'src/components/views/TextureRouteManager/TextureEditor/ToolSelector/utils/handleToolTypeSelectionChange';
import ToolContainer from 'src/components/shared/ToolContainer';
import Line from 'src/components/shared/Line';

import MaskToolTypeSelector from './MaskToolTypeSelector';

import styles from './styles.module.scss';

export default function ToolSelector(props) {
    const { className, onToolTypeSelectionChange, ...attributes } = props;

    const [toolTypeSelection, setToolTypeSelection] = useState(DEFAULT_SELECTED_TOOL_TYPE);

    const handleTransformToolButtonClicked = useCallback(() => {
        setToolTypeSelection(TRANSFORM_TOOL);
        handleToolTypeSelectionChange(TRANSFORM_TOOL);
    }, [setToolTypeSelection]);

    const handleMaskToolButtonClicked = useCallback(() => {
        setToolTypeSelection(MASK_TOOL);
        handleToolTypeSelectionChange(MASK_TOOL);
    }, [setToolTypeSelection]);

    return (
        <ToolContainer className={classnames(styles.ToolSelector, className)} {...attributes}>
            <ToolButton
                onClick={handleTransformToolButtonClicked}
                isActive={toolTypeSelection === TRANSFORM_TOOL}
                className='ToolContainer__tool'
            >
                Transform
            </ToolButton>
            <ToolButton
                onClick={handleMaskToolButtonClicked}
                isActive={toolTypeSelection === MASK_TOOL}
                className='ToolContainer__tool'
            >
                Mask
            </ToolButton>
            {toolTypeSelection === MASK_TOOL && <Line isVertical className='ToolContainer__tool' />}
            {toolTypeSelection === MASK_TOOL && (
                <MaskToolTypeSelector className='ToolContainer__tool' />
            )}
        </ToolContainer>
    );
}

ToolSelector.propTypes = {
    className: PropTypes.string,
};

ToolSelector.defaultProps = {
    className: '',
};
