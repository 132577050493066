export function getPreviousItemInLoopedIteration({ array, currentIndex }) {
    const previousPointIndex = (currentIndex - 1 + array.length) % array.length;
    const previousPoint = array[previousPointIndex];

    return previousPoint;
}

export function getNextItemInLoopedIteration({ array, currentIndex }) {
    const previousPointIndex = (currentIndex + 1 + array.length) % array.length;
    const previousPoint = array[previousPointIndex];

    return previousPoint;
}

/**
 * @endIndex is not included in the median
 * */

export function getMedian({
    array: receivedArray,
    valueGetter = (f) => f,
    startIndex = 0,
    endIndex = receivedArray.length,
}) {
    const array = receivedArray.slice(startIndex, endIndex);
    if (array.length === 0) return null;

    const midIndex = Math.floor(array.length / 2);

    const sortedValues = [...array].sort((a, b) => {
        return valueGetter(a) - valueGetter(b);
    });

    const isEvenQuantity = array.length % 2 === 0;

    let median = sortedValues[midIndex];
    if (isEvenQuantity) {
        median = (sortedValues[midIndex - 1] + sortedValues[midIndex]) / 2;
    }

    return median;
}
