import fillGapsWithIdleAnimations from './fillGapsWithIdleAnimations';
import scheduleAnimationsAndGaps from './scheduleAnimationsAndGaps';

const IDLE_ID = 'idle';

export default function setupSpineInstancePlayback({
    positionToScheduleLayersFrom = 0,
    sortedClips,
    idleAnimationId = IDLE_ID,
    timeWidth,
    spineAssetInstance,
    layerAlpha,
    layerIndex,
    mixDuration,
    isHoldLastFrameForGaps,
}) {
    const isLoop = false;

    const { animations: spineInstanceAnimations } = spineAssetInstance.spineData;

    const spineInstanceIdleAnimation = spineInstanceAnimations.find((animation) => {
        return animation.name === idleAnimationId;
    });

    const sortedClipsWithIdle = fillGapsWithIdleAnimations({
        positionToScheduleLayersFrom,
        sortedClips,
        timeWidth,
        spineInstanceIdleAnimation,
    });

    scheduleAnimationsAndGaps({
        sortedClipsWithIdle,
        spineInstanceAnimations,
        isLoop,
        mixDuration,
        spineAssetInstance,
        layerAlpha,
        layerIndex,
        isHoldLastFrameForGaps,
    });

    return spineAssetInstance;
}
