import getGlobalSize from 'src/pixi/utils/getGlobalSize';

export default function buildPositionDiffRatio({ displayObject, refDisplayObject }) {
    const globalDisplayObjectPos = displayObject.getGlobalPosition();
    const globalRefDisplayObjectPos = refDisplayObject.getGlobalPosition();

    const globalPositonDiff = {
        x: globalDisplayObjectPos.x - globalRefDisplayObjectPos.x,
        y: globalDisplayObjectPos.y - globalRefDisplayObjectPos.y,
    };

    const globalRefDisplayObjectSize = getGlobalSize(refDisplayObject);
    const positionDiffToSizeRatio = {
        x: globalPositonDiff.x / globalRefDisplayObjectSize.width,
        y: globalPositonDiff.y / globalRefDisplayObjectSize.height,
    };

    return positionDiffToSizeRatio;
}
