import { useCallback } from 'react';

export default function useInputHandlers(videoDownloadFormStateRef) {
    const {
        setFileName,
        setFormat,
        setFrameRate,
        setQuality,
        setVideoDownloadScale,
        setVideoDownloadPaddingPercentX,
        setVideoDownloadPaddingPercentY,
        setStartTime,
        setEndTime,
        setIsIncludeAudio,
        setIsDownloadFrames,
    } = videoDownloadFormStateRef.current;

    const handleFileNameChange = useCallback(
        function _handleFileNameChange(event) {
            setFileName(event.target.value);
        },
        [setFileName],
    );

    const handleFormatChange = useCallback(
        function _handleFormatChange(event) {
            setFormat(event.target.value);
        },
        [setFormat],
    );

    const handleFrameRateChange = useCallback(
        function _handleFrameRateChange(event) {
            setFrameRate(Number(event.target.value));
        },
        [setFrameRate],
    );

    const handleQualityChange = useCallback(
        function _handleQualityChange(event) {
            setQuality(Number(event.target.value));
        },
        [setQuality],
    );

    const handleStartTimeChange = useCallback(
        function _handleStartTimeChange(event) {
            setStartTime(Number(event.target.value));
        },
        [setStartTime],
    );

    const handleEndTimeChange = useCallback(
        function _handleEndTimeChange(event) {
            setEndTime(Number(event.target.value));
        },
        [setEndTime],
    );

    const handleAudioInclusionSwitchChange = useCallback(
        function _handleAudioInclusionSwitchChange(event) {
            const isIncluded = event.target.checked;

            setIsIncludeAudio(isIncluded);
        },
        [setIsIncludeAudio],
    );

    const handleDownloadFramesSwitchChange = useCallback(
        function _handleDownloadFramesSwitchChange(event) {
            const isDownloadFrames = event.target.checked;

            setIsDownloadFrames(isDownloadFrames);
        },
        [setIsDownloadFrames],
    );

    const handleVideoDownloadScaleChange = useCallback(
        function _handleVideoDownloadScaleChange(event) {
            setVideoDownloadScale(Number(event.target.value));
        },
        [setVideoDownloadScale],
    );

    const handleVideoDownloadPaddingPercentXChange = useCallback(
        function _handleVideoDownloadPaddingPercentXChange(event) {
            setVideoDownloadPaddingPercentX(Number(event.target.value));
        },
        [setVideoDownloadPaddingPercentX],
    );

    const handleVideoDownloadPaddingPercentYChange = useCallback(
        function _handleVideoDownloadPaddingPercentYChange(event) {
            setVideoDownloadPaddingPercentY(Number(event.target.value));
        },
        [setVideoDownloadPaddingPercentY],
    );

    return {
        handleFileNameChange,
        handleFormatChange,
        handleFrameRateChange,
        handleQualityChange,
        handleStartTimeChange,
        handleEndTimeChange,
        handleAudioInclusionSwitchChange,
        handleDownloadFramesSwitchChange,
        handleVideoDownloadScaleChange,
        handleVideoDownloadPaddingPercentXChange,
        handleVideoDownloadPaddingPercentYChange,
    };
}
