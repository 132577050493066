import React from 'react';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import dndTypes from 'src/components/views/SceneEditor/constants/dndTypes';
import HiddenDragPreview from 'src/components/shared/HiddenDragPreview';

import ClipContainer from './ClipContainer';

import styles from './styles.module.scss';

export default function ClipContainerDnD(props) {
    const { clipId, left, width, className, data, isSelected, ...otherProps } = props;

    const [, dragRef, previewRef] = useDrag({
        item: { type: dndTypes.CLIP, clipId, width, data },
        // collect(monitor) {
        //     return { isDragging: monitor.isDragging() };
        // },
        // begin() {
        //     // console.log('dragging reizer');
        // },
    });

    return (
        <div
            ref={dragRef}
            style={{
                transform: `translateX(${left}px)`,
                width,
            }}
            className={classnames(
                styles.ClipContainerDnD,
                { [styles['ClipContainerDnD--selected']]: isSelected },
                className,
            )}
        >
            <HiddenDragPreview ref={previewRef} />
            <ClipContainer isSelected={isSelected} clipId={clipId} data={data} {...otherProps} />
        </div>
    );
}

ClipContainerDnD.propTypes = {
    className: PropTypes.string,
    clipId: PropTypes.string.isRequired,
    left: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    ...ClipContainer.propTypes,
};

ClipContainerDnD.defaultProps = {
    className: '',
};
