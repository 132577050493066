import { useState, useCallback } from 'react';

export default function useSignupFormUsername() {
    const [username, setUsername] = useState('');
    const handleUsernameChange = useCallback(
        function _handleUsernameChange(event) {
            setUsername(event.target.value);
        },
        [setUsername],
    );

    return { username, handleUsernameChange };
}
