import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import { useNavigate } from 'react-router-dom';
import * as routes from 'src/components/constants/routes';

import DeleteButton from './DeleteButton';
import CloneButton from './CloneButton';

import styles from './styles.module.scss';

export default function Buttons(props) {
    const {
        className,
        foTextureCompId,
        toggleRenameMode,
        isEditingName,
        setEditingFoTextureCompId,
        ...attributes
    } = props;

    const navigate = useNavigate();

    const handleEditTextureClick = useCallback(
        function _handleAddTextureClick() {
            setEditingFoTextureCompId(foTextureCompId);
            navigate(`../${routes.EDIT_TEXTURE}`);
        },
        [navigate, setEditingFoTextureCompId, foTextureCompId],
    );

    return (
        <div className={classnames(styles.Buttons, className)} {...attributes}>
            <DeleteButton foTextureCompId={foTextureCompId} />
            <ToolButton onClick={toggleRenameMode} isActive={isEditingName}>
                rename
            </ToolButton>
            <ToolButton onClick={handleEditTextureClick}>edit</ToolButton>
            <CloneButton foTextureCompId={foTextureCompId} />
        </div>
    );
}

Buttons.propTypes = {
    className: PropTypes.string,
    foTextureCompId: PropTypes.string.isRequired,
    toggleRenameMode: PropTypes.func.isRequired,
    isEditingName: PropTypes.bool.isRequired,
    setEditingFoTextureCompId: PropTypes.func.isRequired,
};

Buttons.defaultProps = {
    className: '',
};
