export default function removeUndefinedProps(obj) {
    const filteredObj = {};

    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (value !== undefined) {
            filteredObj[key] = value;
        }
    });

    return filteredObj;
}
