import dndTypes from 'src/components/views/SceneEditor/constants/dndTypes';
import {
    INSERT_COMPACT_SHIFT,
    INSERT_SHIFT,
} from 'src/utils/businessLogic/clipUtils/insertClip/constants/conflictModes';
import parseRemStrToNumber from 'src/utils/numberUtils/parseRemStrToNumber';
import sassVars from 'src/styles/_variables.scss';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';
import calcUnzoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcUnzoomedValue';

import { CONTENT } from 'src/components/views/SceneEditor/Timeline/constants/trimModes';

import getMinAllowedClipWidth from './getMinAllowedClipWidth';
import limitLeftExpansionIfNoRoom from './limitLeftExpansionIfNoRoom';

const clipResizerWidth = parseRemStrToNumber(sassVars.clipResizerWidth);

export default function buildNewClipAfterResize({
    clip,
    clipsById,
    snapDistance,
    resizeSource,
    clipResizerPosition,
    conflictResolution,
    timeWidth,
    trimMode,
    zoomLevel,
}) {
    const clipResizerUnzoomedWidth = calcUnzoomedValue({ value: clipResizerWidth, zoomLevel });
    const minAllowedClipWidth = getMinAllowedClipWidth({
        snapDistance,
    });

    let clipData = clip.data;
    const startingWidth = getClipWidth({ clipData, timeWidth });
    let newPosition = clip.position;
    if (resizeSource === dndTypes.CLIP_TAIL) {
        const clipEnd = getClipEnd({ clip, timeWidth });
        const widthChange = clipResizerPosition + clipResizerUnzoomedWidth - clipEnd;
        const newWidth = Math.max(minAllowedClipWidth, startingWidth + widthChange);
        const allowedTrim = newWidth - startingWidth;
        clipData = trimClipData({
            tailTrimAmount: allowedTrim,
            clipData,
            trimMode,
            timeWidth,
        });
    }

    if (resizeSource === dndTypes.CLIP_HEAD) {
        const clipEnd = getClipEnd({ clip, timeWidth });

        newPosition = Math.min(clipResizerPosition, clipEnd - minAllowedClipWidth);
        const widthChange = newPosition - clip.position;
        clipData = trimClipData({
            headTrimAmount: widthChange,
            clipData,
            trimMode,
            timeWidth,
        });
        if (trimMode === CONTENT && !clipData.animationStart) {
            const clipWidth = getClipWidth({ clipData, timeWidth });
            newPosition = clipEnd - clipWidth;
        }
    }

    let newClip = {
        ...clip,
        position: newPosition,
        data: clipData,
    };

    const isCompactShift = conflictResolution === INSERT_COMPACT_SHIFT;
    const isShift = conflictResolution === INSERT_SHIFT;
    if (isShift || isCompactShift) {
        newClip = limitLeftExpansionIfNoRoom({
            newClip,
            clipsById,
            isCompactShift,
            timeWidth,
            trimMode,
        });
    }

    return newClip;
}
