import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInteractions: {
        isShiftKeyDown: false,
    },
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setIsShiftKeyDown(state, action) {
            state.userInteractions.isShiftKeyDown = action.payload;
        },
    },
});

export const { setIsShiftKeyDown } = appSlice.actions;

export default appSlice.reducer;
