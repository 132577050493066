import getConflictInfo from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getConflictInfo';

import moveClipToAvoidLeftConflict from './moveClipToAvoidLeftConflict';
import shiftClipsForLeftConflict from './shiftClipsForLeftConflict';
import shiftClipsToRightIfConflict from './shiftClipsToRightIfConflict';

export default function insertShift({
    newClip,
    clipsById,
    conflictInfo: initialConflictInfo,
    isCompactShift = false,
    timeWidth,
}) {
    let newClipsById = shiftClipsForLeftConflict({
        newClip,
        clipsById,
        conflictInfo: initialConflictInfo,
        isCompactShift,
        timeWidth,
    });

    const { isConflictToLeft, leftNeighbour } = getConflictInfo({
        newClip,
        clipsById: newClipsById,
    });
    let adjustedNewClip = newClip;
    if (isConflictToLeft) {
        adjustedNewClip = moveClipToAvoidLeftConflict({
            clip: adjustedNewClip,
            leftNeighbour,
            timeWidth,
        });
    }

    newClipsById = shiftClipsToRightIfConflict({
        newClip: adjustedNewClip,
        clipsById: newClipsById,
        isCompactShift,
        timeWidth,
    });

    newClipsById = { ...newClipsById };
    newClipsById[adjustedNewClip.clipId] = adjustedNewClip;

    return newClipsById;
}
