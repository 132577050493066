import addPaddingToString from 'src/utils/stringUtils/addPaddingToString';

/** turns "some name" to "some name1" and "other name02" to "other name03"  */
export default function incrementName(name) {
    const match = name.match(/(.*\D)*(\d*)$/);
    const root = match[1] || '';

    const postfixStr = match[2] || '';
    const postfixNum = Number(postfixStr);

    const newPostfixNum = postfixNum + 1;
    const lengthDiff = postfixStr.length - `${newPostfixNum}`.length;

    const newName = addPaddingToString({
        string: root,
        padCharacter: '0',
        isPost: true,
        paddedLength: lengthDiff,
        suffix: `${newPostfixNum}`,
    });

    return newName;
}
