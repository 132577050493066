import generateTrackLayerId from './generateTrackLayerId';

export default function generateTrackLayer({
    trackLayerId: receivedTrackLayerId,
    clipsById = {},
    speechTrackId,
    mixDuration,
    isHidden = false,
    isDisabled = false,
    isGeneratedFromExpressions = false,
} = {}) {
    const trackLayer = {
        trackLayerId: receivedTrackLayerId || generateTrackLayerId(),
        clipsById,
        settings: {
            isDisabled,
            isHidden,
            isGeneratedFromExpressions,
        },
    };
    if (speechTrackId) {
        trackLayer.speechTrackId = speechTrackId;
    }
    if (mixDuration) {
        trackLayer.settings.mixDuration = mixDuration;
    }

    return trackLayer;
}
