import { cloneDeep } from 'lodash';

import incrementName from 'src/utils/stringUtils/incrementName';
import makeFoTextureId from 'src/utils/businessLogic/foTexture/makeFoTextureId';

export default function cloneFoTexture({ foTexture, isUseSameContent }) {
    const clonedFoTexture = cloneDeep(foTexture);

    if (!isUseSameContent) {
        clonedFoTexture.id = makeFoTextureId();
        clonedFoTexture.name = incrementName(foTexture.name);
    }

    return clonedFoTexture;
}
