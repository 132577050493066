import observablePointToJSON from 'src/pixi/utils/observablePointToJSON';

export default function extractTransformValuesFromDisplayObject(displayObject) {
    const position = observablePointToJSON(displayObject.position);
    const pivot = observablePointToJSON(displayObject.pivot);
    const scale = observablePointToJSON(displayObject.scale);
    const { rotation, anchor } = displayObject;

    const transformValues = { position, rotation, pivot, scale };
    if (anchor) {
        transformValues.anchor = observablePointToJSON(anchor);
    }

    return transformValues;
}
