import React from 'react';
import PropTypes from 'prop-types';

import { Routes, Route, Navigate } from 'react-router-dom';

import * as routes from 'src/components/constants/routes';

import TextureEditor from './TextureEditor';
import TextureManager from './TextureManager';

export default function TextureRouteManager() {
    return (
        <Routes>
            <Route path={`${routes.MANAGE_TEXTURE}`} element={<TextureManager />} />
            <Route path={`${routes.EDIT_TEXTURE}`} element={<TextureEditor />} />
            <Route path='*' element={<Navigate replace to={`${routes.MANAGE_TEXTURE}`} />} />
        </Routes>
    );
}

TextureEditor.propTypes = {
    className: PropTypes.string,
};

TextureEditor.defaultProps = {
    className: '',
};
