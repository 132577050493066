import getGlobalRotation from 'src/pixi/utils/getGlobalRotation';
import getLocalRotation from 'src/pixi/utils/getLocalRotation';

export default function buildRotation({ refDisplayObject, displayObject, rotationDiff }) {
    const refDisplayObjectGlobalRotation = getGlobalRotation(refDisplayObject);
    const displayObjectGlobalRotation = refDisplayObjectGlobalRotation + rotationDiff;

    let rotation = displayObjectGlobalRotation;
    if (displayObject.parent) {
        const displayObjectLocalRotationRelToOwnParent = getLocalRotation({
            globalRotation: displayObjectGlobalRotation,
            refDisplayObject: displayObject.parent,
        });
        rotation = displayObjectLocalRotationRelToOwnParent;
    }

    return rotation;
}
