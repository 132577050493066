export default function setupSlotContainerTouch({ spineAssetInstance, changeableParamsRef }) {
    spineAssetInstance.slotContainers.forEach((slotContainer, index) => {
        slotContainer.interactive = true;
        slotContainer.buttonMode = true;
        slotContainer.on('pointerdown', function _handleSlotPointerDown() {
            const { selectSlotByName } = changeableParamsRef.current;

            const slotContainer = this;
            const slotName = slotContainer.parent.skeleton.slots[index].data.name;

            selectSlotByName({ slotName });
        });
    });
}
