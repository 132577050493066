import PropTypes from 'prop-types';

const nestedListItemDataShape = {
    key: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    contentElemAttributes: PropTypes.shape({
        onClick: PropTypes.func,
    }),
    placementStyle: PropTypes.shape({
        left: PropTypes.oneOf([0, '100%']),
        right: PropTypes.oneOf([0, '100%']),
        top: PropTypes.oneOf([0, '100%']),
        bottom: PropTypes.oneOf([0, '100%']),
    }),
};
export const nestedListItemDataLike = PropTypes.shape(nestedListItemDataShape);

export const nestedListDataLike = PropTypes.arrayOf(nestedListItemDataLike);
nestedListItemDataShape.childrenData = nestedListDataLike;
