import incrementName from 'src/utils/stringUtils/incrementName';
import cloneFoTexture from 'src/utils/businessLogic/foTexture/cloneFoTexture';
import makeFoTextureComp from 'src/utils/businessLogic/foTextureComp/makeFoTextureComp';

export default function cloneFoTextureComp(foTextureComp) {
    const incrementedName = incrementName(foTextureComp.name);

    const clonedFoTextures = foTextureComp.foTextures.map((foTexture) => {
        const clonedFoTexture = cloneFoTexture({ foTexture });
        return clonedFoTexture;
    });

    const newFoTextureComp = makeFoTextureComp({
        name: incrementedName,
        foTextures: clonedFoTextures,
    });

    return newFoTextureComp;
}
