import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

import dndTypes from 'src/components/views/SceneEditor/constants/dndTypes';
import clipTypes from 'src/components/constants/clipTypes';

import ClipResizerDnD from './ClipResizerDnD';
import ClipErrorMessage from './ClipErrorMessage';

import styles from './styles.module.scss';

export default function Clip(props) {
    const { className, data, clipId, isSelected, isInPreview, clipError, ...attributes } = props;
    const { title, type } = data;
    const isClipGroup = type === clipTypes.CLIP_GROUP;

    return (
        <div
            className={classnames(
                styles.Clip,
                {
                    [styles['Clip--selected']]: isSelected,
                    [styles['Clip--preview']]: isInPreview,
                    [styles['Clip--error']]: !!clipError,
                    [styles['Clip--group']]: isClipGroup,
                },
                className,
            )}
            title={title}
            {...attributes}
        >
            {isSelected && (
                <ClipResizerDnD
                    className={classnames(styles.Clip__head, styles.ClipResizerDnD)}
                    clipId={clipId}
                    type={dndTypes.CLIP_HEAD}
                />
            )}
            <div className={styles.Clip__content}>
                <div className={styles.Clip__contentTitle}>{title}</div>
                {clipError && <ClipErrorMessage clipError={clipError} />}
            </div>
            {isSelected && (
                <ClipResizerDnD
                    className={classnames(styles.Clip__tail, styles.ClipResizerDnD)}
                    clipId={clipId}
                    type={dndTypes.CLIP_TAIL}
                />
            )}
        </div>
    );
}

Clip.propTypes = {
    className: PropTypes.string,
    clipId: PropTypes.string.isRequired,
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }),
    isSelected: PropTypes.bool,
    isInPreview: PropTypes.bool,
    onMouseDown: PropTypes.func,
    clipError: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }),
};

Clip.defaultProps = {
    className: '',
    data: {},
    isSelected: false,
    isInPreview: false,
    onMouseDown: noop,
    clipError: null,
};
