import { Container } from 'pixi.js';

import assertTransformValuesAreValid from 'src/pixi/utils/assertions/assertTransformValuesAreValid';
import matchTwoDisplayObjectTransforms from 'src/pixi/utils/matchTwoDisplayObjectTransforms';
import applyBezierMaskToDisplayObject from 'src/pixi/tools/MaskTool/utils/applyBezierMaskToDisplayObject';

export default class FoTextureDisplayObj extends Container {
    constructor(props) {
        super(props);

        const { id, texture, transform, maskData, zIndex } = props;

        const foTextureDisplayObjectContent = new PIXI.Sprite(texture);

        this.addChild(foTextureDisplayObjectContent);
        this.content = foTextureDisplayObjectContent;

        if (transform) {
            assertTransformValuesAreValid(transform);
            matchTwoDisplayObjectTransforms({
                source: transform,
                target: this,
            });
        }

        if (maskData) {
            this.maskData = maskData;
            applyBezierMaskToDisplayObject({
                bezierPoints: maskData.points,
                displayObject: this,
            });
        }

        this.zIndex = zIndex;
        this.id = id;
    }
}
