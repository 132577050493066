import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ToolContainer from 'src/components/shared/ToolContainer';
import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';

import styles from './styles.module.scss';

export default function AssetSelectorToolContainer(props) {
    const { className, selectedFoSpineAsset, ...otherProps } = props;

    let assetNameText = 'No character is selected.';
    if (selectedFoSpineAsset) {
        assetNameText = `${selectedFoSpineAsset.name} is selected.`;
    }
    return (
        <ToolContainer
            className={classnames(styles.AssetSelectorToolContainer, className)}
            {...otherProps}
        >
            <div className='ToolContainer__tool'>{assetNameText}</div>
        </ToolContainer>
    );
}

AssetSelectorToolContainer.propTypes = {
    className: PropTypes.string,
    selectedFoSpineAsset: foSpineAssetLike.isRequired,
};

AssetSelectorToolContainer.defaultProps = {
    className: '',
};
