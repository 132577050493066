import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

export default function reduceOverlapByNextGap({ currentClip, nextClip, overlapWidth, timeWidth }) {
    const currentClipEnd = getClipEnd({ clip: currentClip, timeWidth });
    const nextGapWidth = nextClip
        ? Math.max(nextClip.position - currentClipEnd, 0)
        : Number.POSITIVE_INFINITY;
    const reducedOverlapWidth = Math.max(overlapWidth - nextGapWidth, 0);

    return reducedOverlapWidth;
}
