export default function convertAnimationListToTree(filteredAnimations) {
    const children = [];

    filteredAnimations.forEach((filteredAnimation) => {
        const titleArr = filteredAnimation.title.split('/');
        let filteredAnimationTreeNodes = children;

        titleArr.forEach((title, index) => {
            let filteredAnimationTreeNode = filteredAnimationTreeNodes.find(
                (filteredAnimationTreeNode) => {
                    return filteredAnimationTreeNode.title === title;
                },
            );

            const isBranch = index !== titleArr.length - 1;

            if (!filteredAnimationTreeNode) {
                filteredAnimationTreeNode = { title };
                filteredAnimationTreeNodes.push(filteredAnimationTreeNode);
                if (isBranch) {
                    filteredAnimationTreeNodes = [];
                    filteredAnimationTreeNode.children = filteredAnimationTreeNodes;
                } else {
                    filteredAnimationTreeNode.animation = filteredAnimation;
                }
            } else {
                filteredAnimationTreeNodes = filteredAnimationTreeNode.children;
            }
        });
    });

    return children;
}
