import setPivotByPercentWithoutMovingParent from 'src/pixi/utils/pivotUtils/setPivotByPercentWithoutMovingParent';
import getOriginalSize from 'src/pixi/utils/getOriginalSize';
import drawBgGhostLayer from 'src/pixi/tools/TransformTool/makeBgGhostLayer/drawBgGhostLayer';

export default function applyTransformToolToTarget({ transformTool, target }) {
    transformTool.clear();
    transformTool.visible = true;
    transformTool.target = target;
    target.originalSize = getOriginalSize(target);

    setPivotByPercentWithoutMovingParent({
        displayObject: target,
        pivotPercent: { x: 0.5, y: 0.5 },
    });

    const { bgGhostLayer, app } = transformTool;
    const { width, height } = app.screen;
    drawBgGhostLayer({ bgGhostLayer, width, height });

    transformTool.positionKnobs();
}
