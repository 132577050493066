import downloadFile from 'src/utils/browser/downloadFile';

export default function convertDispalyObjectToImage({
    renderer,
    displayObject,
    displayFrame,
    fileName,
    ext = 'png',
    isDownloadImage = false,
}) {
    const format = `format/${ext}`;
    return new Promise(function _executor(resolve, reject) {
        let canvas;
        if (displayFrame) {
            const renderTarget = PIXI.RenderTexture.create({
                width: displayFrame.width,
                height: displayFrame.height,
            });

            renderer.render(displayObject, renderTarget);
            canvas = renderer.extract.canvas(renderTarget);
        } else {
            canvas = renderer.extract.canvas(displayObject);
        }

        canvas.toBlob(function _handleBlobGeneration(blob) {
            if (blob) {
                const filenameWithExt = `${fileName}.${ext}`;
                if (isDownloadImage) {
                    downloadFile({ blob, filename: filenameWithExt });
                }

                const imageFile = new File([blob], filenameWithExt, {
                    lastModified: new Date().getTime(),
                    type: blob.type,
                });

                resolve(imageFile);
            } else {
                reject(Error(`couldn't convert ${fileName} to blob`));
            }
        }, format);
    });
}
