export function addEventHandler({ tool, eventName, handler }) {
    const handlersOfGiventEvent = tool.eventHandlersByName[eventName] || [];

    tool.eventHandlersByName[eventName] = handlersOfGiventEvent;

    handlersOfGiventEvent.push(handler);
}

export function removeEventHandler({ tool, eventName, handler }) {
    const handlersOfGiventEvent = tool.eventHandlersByName[eventName] || [];

    const filteredHandlersOfGivenEvent = handlersOfGiventEvent.filter((currentHandler) => {
        return currentHandler !== handler;
    });

    tool.eventHandlersByName[eventName] = filteredHandlersOfGivenEvent;
}

export function runEventHandlers({ eventName, tool }) {
    const handlersOfGiventEvent = tool.eventHandlersByName[eventName];
    if (handlersOfGiventEvent) {
        handlersOfGiventEvent.forEach((handler) => {
            handler({ tool });
        });
    }
}
