import sassVars from 'src/styles/_variables.scss';
import parseRemStrToNumber from 'src/utils/numberUtils/parseRemStrToNumber';

export default function calcTrackControllerHeight(layers) {
    const numberOfLayers = layers.allIds.length;
    const trackHeight = parseRemStrToNumber(sassVars.trackHeight);
    const containerPadding = parseRemStrToNumber(sassVars.trackLayerDistance);

    const totalLayerMargin = containerPadding * Math.max(numberOfLayers - 1, 0);
    const controllerHeight = numberOfLayers * trackHeight + totalLayerMargin;

    return controllerHeight;
}
