import { sortBy } from 'lodash';

import getSelectedClipsUsingIds from './getSelectedClipsUsingIds';
import getFirstSequentialClipsFromSortedSelection from './getFirstSequentialClipsFromSortedSelection';

export default function getFirstSequentialClipsFromSelection({
    tracks,
    clipSelectionByTrackId,
    selectedTrackId,
    selectedLayerId,
}) {
    const selectedClips = getSelectedClipsUsingIds({
        tracks,
        clipSelectionByTrackId,
        selectedTrackId,
        selectedLayerId,
    });

    let sequentialSelectedClips = [];
    const isAnySelectedClipFoundOnTrackLayer = !!selectedClips.length;
    if (isAnySelectedClipFoundOnTrackLayer) {
        const sortedSelectedClips = sortBy(selectedClips, 'position');
        const allClipsById = tracks.byId[selectedTrackId].layers.byId[selectedLayerId].clipsById;
        const allSortedClips = sortBy(allClipsById, 'position');

        sequentialSelectedClips = getFirstSequentialClipsFromSortedSelection({
            allSortedClips,
            sortedSelectedClips,
        });
    }

    return sequentialSelectedClips;
}
