import getGlobalRotation from 'src/pixi/utils/getGlobalRotation';

export default function positionRotateLayer(transformTool) {
    const { target } = transformTool;

    const targetGlobalRotation = getGlobalRotation(target);
    const transformToolGlobalRotation = getGlobalRotation(transformTool);

    const targetRotationRelToTransformToolParent =
        targetGlobalRotation - transformToolGlobalRotation;

    transformTool.rotateLayer.rotation = targetRotationRelToTransformToolParent;
}
