import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';

export default function resolveLeftConflicSubmissively({
    newClip,
    leftNeighbour,
    timeWidth,
    trimMode,
}) {
    const leftNeighbourEnd = getClipEnd({ clip: leftNeighbour, timeWidth });
    const leftOverlap = Math.max(leftNeighbourEnd - newClip.position, 0);

    const newClipWidth = getClipWidth({ clipData: newClip.data, timeWidth });

    const isLeftNeighbourFullyCoveringNewClip = leftOverlap >= newClipWidth;

    if (isLeftNeighbourFullyCoveringNewClip) {
        return null;
    }

    const newClipTrimmedData = trimClipData({
        headTrimAmount: leftOverlap,
        clipData: newClip.data,
        trimMode,
        timeWidth,
    });
    const newClipAdjustedPosition = newClip.position + leftOverlap;
    const adjustedNewClip = {
        ...newClip,
        data: newClipTrimmedData,
        position: newClipAdjustedPosition,
    };

    return adjustedNewClip;
}
