import getSlotContainerBySlotName from 'src/utils/businessLogic/foSpineAsset/getSlotContainerBySlotName';
import matchTwoDisplayObjectTransforms from 'src/pixi/utils/matchTwoDisplayObjectTransforms';
import buildTransformFromRelativeTransform from 'src/pixi/utils/buildTransformFromRelativeTransform';

export default function placeFoTextureCompDisplayObjectRelativeToSlot({
    foTextureCompDisplayObject,
    spineAssetInstance,
    textureModificationAtom,
}) {
    const { slotName, relativeTransform } = textureModificationAtom;
    const slotContainer = getSlotContainerBySlotName({
        spineAssetInstance,
        slotName,
    });

    const transform = buildTransformFromRelativeTransform({
        refDisplayObject: slotContainer,
        displayObject: foTextureCompDisplayObject,
        relativeTransform,
    });
    matchTwoDisplayObjectTransforms({
        source: transform,
        target: foTextureCompDisplayObject,
    });
}
