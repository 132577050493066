import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function loadAudioBuffer({ url, audioContext: receivedAudioContext }) {
    const response = await fetchWithAuth(url);
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`there was a problem with fetching audio: ${errorData.errors[0].message}`);
    }

    const arrayBuffer = await response.arrayBuffer();

    const audioContext = receivedAudioContext || new AudioContext();

    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    return audioBuffer;
}
