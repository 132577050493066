import { useCallback, useRef } from 'react';

import pixiStore from 'src/pixi/pixiStore';
import getFoTranscriptWordEndTime from 'src/utils/businessLogic/transcript/getFoTranscriptWordEndTime';

function calcTextPosition({ screen, speechOverlayDisplayObj }) {
    const pos = {
        x: (screen.width - speechOverlayDisplayObj.width) / 2,
        y: screen.height - speechOverlayDisplayObj.height - 10,
    };

    return pos;
}

export default function useHandleSpeechOverlayUpdate({
    currentTimeManager,
    monitorOverlaysDispalyObjsRef,
    foTranscriptWords,
}) {
    const otherParamsRef = useRef({});
    otherParamsRef.current.foTranscriptWords = foTranscriptWords;

    return useCallback(function handleSpeechOverlayUpdate() {
        const app = pixiStore.pixiAppManager.getPixiApp();
        const { speechOverlayDisplayObj } = monitorOverlaysDispalyObjsRef.current;
        const { foTranscriptWords } = otherParamsRef.current;

        if (!foTranscriptWords) {
            return;
        }
        const { currentTime } = currentTimeManager;

        speechOverlayDisplayObj.text = ``;

        const matchedFoTranscriptWord = foTranscriptWords.find((foTranscriptWord) => {
            const { startTime } = foTranscriptWord;

            let isMatch = false;
            if (currentTime >= startTime) {
                const endTime = getFoTranscriptWordEndTime(foTranscriptWord);
                isMatch = currentTime <= endTime;
            }

            return isMatch;
        });
        if (matchedFoTranscriptWord) {
            speechOverlayDisplayObj.text = matchedFoTranscriptWord.word;
        }

        const pos = calcTextPosition({
            screen: app.screen,
            speechOverlayDisplayObj,
        });
        speechOverlayDisplayObj.position.set(pos.x, pos.y);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
