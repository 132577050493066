import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';

import generateEmptyClip from './generateEmptyClip';

export default function fillAreaWithRepeatingClipsDeterministically({
    startPosition,
    endPosition,
    spineInstanceAnimation,
    timeWidth,
}) {
    const gapBetweenClips = endPosition - startPosition;

    if (!spineInstanceAnimation) {
        const duration = gapBetweenClips / timeWidth;
        const emptyClip = generateEmptyClip({ position: startPosition, duration });
        const emptyClips = [emptyClip];
        return emptyClips;
    }

    const repeatingAnimId = spineInstanceAnimation.name;
    const repeatingClips = [];
    const originalDuration = spineInstanceAnimation.duration;
    const repeatingClipWidth = originalDuration * timeWidth;

    const numberOfRepeatingAnimsFromTimelineStart = Math.ceil(endPosition / repeatingClipWidth);
    const numberOfRepeatingAnimsBeforeGap = Math.floor(startPosition / repeatingClipWidth);
    const numberOfNeededRepeatingAnims =
        numberOfRepeatingAnimsFromTimelineStart - numberOfRepeatingAnimsBeforeGap;

    for (let i = 0; i < numberOfNeededRepeatingAnims; i++) {
        const position = (numberOfRepeatingAnimsBeforeGap + i) * repeatingClipWidth;

        const repeatingClip = generateClip({
            data: {
                animationId: repeatingAnimId,
                title: repeatingAnimId,
                animationStart: 0,
                animationEnd: originalDuration,
                originalDuration,
                timeScale: 1,
            },
            position,
        });

        repeatingClips.push(repeatingClip);
    }

    const firstClip = repeatingClips[0];
    if (firstClip) {
        const firstAnimUnusedWidth = startPosition % repeatingClipWidth;
        const firstAnimStartTime = firstAnimUnusedWidth / timeWidth;

        firstClip.data.animationStart = firstAnimStartTime;
        firstClip.position = startPosition;
    }

    const lastClip = repeatingClips[repeatingClips.length - 1];
    if (lastClip) {
        const lastAnimUsableWidth = endPosition % repeatingClipWidth;

        const lastAnimEndTime = lastAnimUsableWidth / timeWidth;

        lastClip.data.animationEnd = lastAnimEndTime;
    }

    return repeatingClips;
}
