import { useEffect, useRef } from 'react';

import getFoTextureCompIdCurrentlyAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getFoTextureCompIdCurrentlyAssignedToSlot';

import showSelectedDisplayObject from './showSelectedDisplayObject';

export default function useShowSelectedCompPreview({
    foTextureComps,
    previewContainer,
    previewFrameGraphics,
    selectedSlotName,
    foSpineAsset,
}) {
    const selectedFoTextureCompId = getFoTextureCompIdCurrentlyAssignedToSlot({
        foSpineAsset,
        slotName: selectedSlotName,
    });
    const selectedFoTextureComp = foTextureComps.byId[selectedFoTextureCompId];

    const displayObjMapRef = useRef({});
    const displayObjMap = displayObjMapRef.current;

    useEffect(
        function _showSelectedCompPreview() {
            if (!selectedFoTextureComp) {
                previewContainer.removeChildren();
                previewContainer.addChild(previewFrameGraphics);
                return;
            }

            showSelectedDisplayObject({
                previewContainer,
                previewFrameGraphics,
                selectedFoTextureComp,
                displayObjMap,
            });
        },
        [selectedFoTextureComp, displayObjMap, previewContainer, previewFrameGraphics],
    );
}
