import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';

export default function buildClipForAnimation({
    animationId,
    duration,
    originalDuration,
    position,
}) {
    let animationEnd = duration;
    let timeScale = 1;
    if (duration > originalDuration) {
        animationEnd = originalDuration;
        timeScale = originalDuration / duration;
    }

    const data = {
        animationId,
        title: animationId,
        originalDuration,
        animationStart: 0,
        animationEnd,
        timeScale,
    };

    const clip = generateClip({ data, position });

    return clip;
}
