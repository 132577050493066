import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function TrackLayerController(props) {
    const { className, children } = props;

    return <div className={classnames(styles.TrackLayerController, className)}>{children}</div>;
}

TrackLayerController.propTypes = {
    className: PropTypes.string,
};

TrackLayerController.defaultProps = {
    className: '',
};
