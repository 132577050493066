import produce from 'immer';

import calcDragItemPosRelToNode from 'src/utils/dndUtils/calcDragItemPosRelToNode';
import insertClip from 'src/utils/businessLogic/clipUtils/insertClip';
import buildNewClipAfterResize from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/buildNewClipAfterResize';
import calcUnzoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcUnzoomedValue';

export default function resizeClip({
    monitor,
    clipId,
    clipsById,
    editModes,
    trackNode,
    snapDistance,
    resizeSource,
    timeWidth,
    zoomLevel = 1,
}) {
    const clipResizerPosition = calcDragItemPosRelToNode({
        monitor,
        domNode: trackNode,
    });
    const clipResizerUnzoomedPosition = calcUnzoomedValue({
        value: clipResizerPosition,
        zoomLevel,
    });

    const clip = clipsById[clipId];

    const newClipsById = produce(clipsById, (draftClipsById) => {
        delete draftClipsById[clipId];
    });

    const { conflictResolution, trimMode } = editModes;

    const newClip = buildNewClipAfterResize({
        clipsById: newClipsById,
        clip,
        snapDistance,
        resizeSource,
        clipResizerPosition: clipResizerUnzoomedPosition,
        conflictResolution,
        timeWidth,
        trimMode,
        zoomLevel,
    });

    const newClipsByIdAfterInsert = insertClip({
        newClip,
        clipsById: newClipsById,
        conflictResolution,
        snapDistance,
        timeWidth,
        trimMode,
    });

    if (newClipsByIdAfterInsert === newClipsById) {
        return clipsById;
    }

    return newClipsByIdAfterInsert;
}
