let apiRoot = '/api/v1';
if (process.env.NODE_ENV === 'development') {
    apiRoot = 'http://localhost:3000/api/v1';
}
export const AUDIO_FILES_ENDPOINT = `${apiRoot}/audio/files`;
export const AUDIO_TRACKS_ENDPOINT = `${apiRoot}/audio/tracks`;
export const VIDEO_CONVERSION_ENDPOINT = `${apiRoot}/image-sequences`;
export const SCENES_ENDPOINT = `${apiRoot}/scenes`;
export const USER_ENDPOINT = `${apiRoot}/users`;
export const UTIL_APIS = `${apiRoot}/utils`;
