import React, { useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
    deselectClips,
    setClipSelection,
} from 'src/components/views/SceneEditor/Timeline/timelineSlice';
import {
    setTracks,
    setClipsById,
    setExpressionTimelineKeys,
} from 'src/components/views/SceneSelector/sceneSelectorSlice';
import calcTimelineWidth from 'src/components/views/SceneEditor/Timeline/utils/calcTimelineWidth';
import {
    assetTrackModeLike,
    clipSelectionByTrackIdLike,
} from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';
import { tracksLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import useCurrentTimeState from 'src/components/views/SceneEditor/Timeline/hooks/useCurrentTimeState';
import useDeletePress from 'src/components/views/SceneEditor/Timeline/hooks/useDeletePress';
import usePinTimeRulerRowToTop from 'src/components/views/SceneEditor/Timeline/hooks/usePinTimeRulerRowToTop';
import useDeselectMissingClips from 'src/components/views/SceneEditor/Timeline/hooks/useDeselectMissingClips';
import useAdaptTimelinePropsToAssetTrackMode from 'src/components/views/SceneEditor/Timeline/hooks/useAdaptTimelinePropsToAssetTrackMode';
import buildAnimationClipError from 'src/components/views/SceneEditor/Timeline/utils/buildAnimationClipError';
import TimelineContext from 'src/components/views/SceneEditor/Timeline/context/TimelineContext';
import withRouter from 'src/reduxStore/utils/withRouter';

import TimeTracker from './TimeTracker';
import Toolbar from './Toolbar';
import Track from './Track';
import AudioTrack from './AudioTrack';
import TrackControllers from './TrackControllers';

import styles from './styles.module.scss';

function Timeline(props) {
    const {
        className,
        deselectClips,
        timelineWidth,
        clipSelectionByTrackId,
        setClipSelection,
        tracks,
        setTracks,
        setClipsById,
        setExpressionTimelineKeys,
        timeWidth,
        zoomLevel,
        handlePauseButtonClick,
        assetTrackMode,
    } = props;

    const handleTimelineClick = useCallback(
        function _handleTimelineClick(event) {
            if (event.target === event.currentTarget) {
                deselectClips();
            }
        },
        [deselectClips],
    );

    useDeletePress({ clipSelectionByTrackId, tracks, setTracks, assetTrackMode });

    useDeselectMissingClips({
        tracks,
        clipSelectionByTrackId,
        setClipSelection,
        assetTrackMode,
    });

    const {
        timeTrackerElemRef,
        verticalScrollElemRef,
        timelineLabelElemRef,
    } = usePinTimeRulerRowToTop();

    const horizontalScrollElemRef = useRef();
    const currentTime = useCurrentTimeState({
        horizontalScrollElemRef,
        handlePauseButtonClick,
        timelineWidth,
        timeWidth,
        zoomLevel,
    });

    const {
        adaptedTracks,
        adaptedSetClipsById,
        adaptedSetTracks,
        adaptedBuildClipError,
    } = useAdaptTimelinePropsToAssetTrackMode({
        tracks,
        setClipsById,
        setTracks,
        buildAnimationClipError,
        assetTrackMode,
        timeWidth,
        setExpressionTimelineKeys,
    });

    const timelineContextValue = useMemo(() => {
        return {
            setClipsById: adaptedSetClipsById,
            setTracks: adaptedSetTracks,
            tracks: adaptedTracks,
            buildClipError: adaptedBuildClipError,
        };
    }, [adaptedSetClipsById, adaptedSetTracks, adaptedTracks, adaptedBuildClipError]);
    return (
        <TimelineContext.Provider value={timelineContextValue}>
            <div
                className={classnames(styles.Timeline, className)}
                onClick={handleTimelineClick}
                role='none'
            >
                <Toolbar
                    className={styles.Toolbar}
                    currentTime={currentTime}
                    horizontalScrollElemRef={horizontalScrollElemRef}
                />
                <div
                    className={styles.Timeline__tracksContainerScrollY}
                    ref={verticalScrollElemRef}
                >
                    <div className={styles.Timeline__tracksContainerY}>
                        <TrackControllers
                            timelineLabelElemRef={timelineLabelElemRef}
                            tracks={adaptedTracks}
                        />
                        <div
                            className={styles.Timeline__tracksContainerScrollX}
                            ref={horizontalScrollElemRef}
                        >
                            <div
                                className={styles.Timeline__tracksContainerX}
                                style={{ width: timelineWidth }}
                            >
                                <TimeTracker
                                    className={styles.Timeline__timeTracker}
                                    currentTime={currentTime}
                                    nodeRef={timeTrackerElemRef}
                                />
                                {[...tracks.allIds].reverse().map((trackId) => {
                                    const track = tracks.byId[trackId];
                                    return (
                                        <Track
                                            className={styles.Timeline__track}
                                            // track={track}
                                            trackId={trackId}
                                            tracksById={adaptedTracks.byId}
                                            setClipsById={adaptedSetClipsById}
                                            setTracks={adaptedSetTracks}
                                            buildClipError={adaptedBuildClipError}
                                            key={track.trackId}
                                        />
                                    );
                                })}
                                <AudioTrack />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TimelineContext.Provider>
    );
}

Timeline.propTypes = {
    className: PropTypes.string,
    assetTrackMode: assetTrackModeLike.isRequired,
    deselectClips: PropTypes.func.isRequired,
    setClipSelection: PropTypes.func.isRequired,
    setTracks: PropTypes.func.isRequired,
    setClipsById: PropTypes.func.isRequired,
    timelineWidth: PropTypes.number.isRequired,
    tracks: tracksLike.isRequired,
    clipSelectionByTrackId: clipSelectionByTrackIdLike.isRequired,
    timeWidth: PropTypes.number.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    handlePauseButtonClick: PropTypes.func.isRequired,
};

Timeline.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const {
        timeline: { clipSelectionByTrackId, timeWidth, zoomLevel, assetTrackMode },
        audioSelector: { audioTracks },
    } = state;

    const { sceneId: selectedSceneId } = ownProps.router.params;
    const { tracks, selectedAudioTrackId } = state.sceneSelector.scenes.byId[selectedSceneId];

    const timelineWidth = calcTimelineWidth({
        audioTracks,
        selectedAudioTrackId,
        tracksById: tracks.byId,
        timeWidth,
        zoomLevel,
    });

    return {
        clipSelectionByTrackId,
        timelineWidth,
        tracks,
        timeWidth,
        zoomLevel,
        assetTrackMode,
    };
}

export default withRouter(
    connect(mapState, {
        deselectClips,
        setTracks,
        setClipSelection,
        setClipsById,
        setExpressionTimelineKeys,
    })(Timeline),
);
