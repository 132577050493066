import { SPEED, CONTENT } from 'src/components/views/SceneEditor/Timeline/constants/trimModes';

import contentTrimClipGroupData from './contentTrimClipGroupData';
// eslint-disable-next-line import/no-cycle
import speedTrimClipGroupData from './speedTrimClipGroupData';

/**
 * @headTrimAmount > 0 => trim right, otherwise, trim left
 * @tailTrimAmount > 0 => trim right, otherwise, trim left
 * @clipData
 * @trimMode
 * @timeWidth
 */
export default function trimClipGroupData({
    headTrimAmount = 0,
    tailTrimAmount = 0,
    clipData,
    trimMode,
    timeWidth,
}) {
    if (!headTrimAmount && !tailTrimAmount) {
        return clipData;
    }
    if (!timeWidth) {
        console.error(`can't trim a clip's data without a valid timeWidth. 
        Received: ${timeWidth}`);
    }

    let trimmedClipData = clipData;

    if (trimMode === SPEED) {
        trimmedClipData = speedTrimClipGroupData({
            headTrimAmount,
            tailTrimAmount,
            clipData,
            trimMode,
            timeWidth,
        });
    }

    if (trimMode === CONTENT) {
        trimmedClipData = contentTrimClipGroupData({
            headTrimAmount,
            tailTrimAmount,
            clipData,
            timeWidth,
        });
    }

    return trimmedClipData;
}
