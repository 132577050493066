import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import refLike from 'src/components/shared/propTypes/refLike';
import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import calcUnzoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcUnzoomedValue';

import styles from './styles.module.scss';

export default function TimeRuler(props) {
    const { className, timeWidth, rulerNodeRef, zoomLevel } = props;

    const { jumpAppToTime, handlePauseButtonClick } = useContext(SceneEditorContext);

    const handleClick = useCallback(
        function _handleClick(event) {
            handlePauseButtonClick();

            const clickPosRelToScreen = event.clientX;
            const rulerPosRelToScreen = event.target.getBoundingClientRect().x;
            const clickPositionOnRuler = clickPosRelToScreen - rulerPosRelToScreen;
            const unzoomedClickPositonOnRuler = calcUnzoomedValue({
                value: clickPositionOnRuler,
                zoomLevel,
            });
            const time = unzoomedClickPositonOnRuler / timeWidth;
            jumpAppToTime(time);
        },
        [timeWidth, jumpAppToTime, handlePauseButtonClick, zoomLevel],
    );

    return (
        <div
            ref={rulerNodeRef}
            className={classnames(styles.TimeRuler, className)}
            onClick={handleClick}
            role='none'
        />
    );
}

TimeRuler.propTypes = {
    className: PropTypes.string,
    timeWidth: PropTypes.number.isRequired,
    rulerNodeRef: refLike.isRequired,
    zoomLevel: PropTypes.number.isRequired,
};

TimeRuler.defaultProps = {
    className: '',
};
