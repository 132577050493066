import { KNOB_DEFAULT_SCALE } from 'src/pixi/tools/MaskTool/constants/maskControls';
import {
    DELETE_POINT,
    TOGGLE_KNOB,
    MOVE_MASK_CONTROLS,
    ADD_POINT,
} from 'src/pixi/tools/MaskTool/constants/maskToolTypes';
import * as eventNames from 'src/pixi/tools/MaskTool/constants/eventNames';

import adjustOtherTangent from './adjustOtherTangent';
import toggleTangent from './toggleTangent';
import prepareTangentKnobForDragging from './prepareTangentKnobForDragging';
import removeTangent from './removeTangent';

export function handleTangentKnobDragStart(event) {
    const { maskTool } = this;

    if (maskTool.type === MOVE_MASK_CONTROLS || maskTool.type === ADD_POINT) {
        prepareTangentKnobForDragging({ tangentKnob: this, eventData: event.data });
    } else if (maskTool.type === DELETE_POINT) {
        removeTangent(this);
    } else if (maskTool.type === TOGGLE_KNOB) {
        toggleTangent(this);
    }
}

export function handleTangentKnobDragEnd() {
    this.isDragging = false;
    this.data = null;
    this.scale.set(KNOB_DEFAULT_SCALE);

    const { maskTool } = this;
    maskTool.runEventHandlersFor(eventNames.TANGENT_KNOB_DRAG_END);
    maskTool.runEventHandlersFor(eventNames.ANY_DRAG_END);
}

export function handleTangentKnobDragMove() {
    if (this.isDragging) {
        const { maskTool, pointId, tangentName } = this;
        const points = maskTool.getPoints();
        const point = points.find((currentPoint) => {
            return currentPoint.id === pointId;
        });

        const tangent = point[tangentName];

        const currentPointerPosition = this.data.getLocalPosition(maskTool.target);
        const tangentNewPosition = {
            x: currentPointerPosition.x - this.dragStartPointerOffset.x,
            y: currentPointerPosition.y - this.dragStartPointerOffset.y,
        };

        adjustOtherTangent({ point, tangentName, tangentNewPosition });
        tangent.x = tangentNewPosition.x;
        tangent.y = tangentNewPosition.y;

        maskTool.positionControls();
    }
}
