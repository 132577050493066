export default function positionTranslateLayer(transformTool) {
    const { target } = transformTool;

    const targetGlobalPos = target.getGlobalPosition();
    const targetPosRelToTransformTool = transformTool.toLocal(targetGlobalPos);

    transformTool.translateLayer.position.set(
        targetPosRelToTransformTool.x,
        targetPosRelToTransformTool.y,
    );
}
