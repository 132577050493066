export default function getFoTextureCompsOfFoSpineAsset({
    foSpineAsset,
    foTextureComps,
    relatedFoTextureComps = { byId: {}, allIds: [] },
}) {
    if (!foSpineAsset) {
        console.error(
            'getFoTextureCompsOfFoSpineAsset requres a valid foSpineAsset. Received: ',
            foSpineAsset,
        );
        return relatedFoTextureComps;
    }
    if (!foTextureComps) {
        console.error(
            'getFoTextureCompsOfFoSpineAsset requres valid foTextureComps. Received: ',
            foTextureComps,
        );
        return relatedFoTextureComps;
    }

    const { modifications } = foSpineAsset;
    modifications.textures.forEach((textureModification) => {
        const { current: foTextureCompId } = textureModification;
        if (!foTextureCompId) {
            return;
        }

        const foTextureComp = foTextureComps.byId[foTextureCompId];
        if (!foTextureComp) {
            console.error(
                `foSpineAsset: ${foSpineAsset.id} has a texture modification ` +
                    `with foTextureCompId: ${foTextureCompId}. ` +
                    `However, a matching foTextureComp with that id wan't found.`,
            );
            return;
        }
        relatedFoTextureComps.byId[foTextureCompId] = foTextureComp;
        relatedFoTextureComps.allIds.push(foTextureCompId);
    });

    return relatedFoTextureComps;
}
