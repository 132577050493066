import makeKnobGrid from 'src/pixi/tools/TransformTool/makeKnobGrid';

import makeRotateKnob from './makeRotateKnob';

export default function makeRotateKnobs(transformTool) {
    const rotateKnobs = makeKnobGrid({
        gridSize: { x: 2, y: 2 },
        makeKnob: makeRotateKnob,
        transformTool,
    });
    transformTool.rotateKnobs = rotateKnobs;
    Object.values(rotateKnobs).forEach((rotateKnob) => {
        transformTool.rotateLayer.addChild(rotateKnob);
    });
}
