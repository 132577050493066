import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

export default function getLayersWithPaddedClips({
    layersWithClipsInRange,
    layersWithSortedClips,
    timeWidth,
    globalMixDuration,
}) {
    const layersWithPaddedClips = layersWithClipsInRange.map(function _padClipsIfPossible(
        clipsInRange,
        layerIndex,
    ) {
        const firstClipInRange = clipsInRange[0];
        const lastClipInRange = clipsInRange[clipsInRange.length - 1];
        const arbitraryThresholdDistance = globalMixDuration * timeWidth;

        let paddedClips = clipsInRange;
        if (firstClipInRange && firstClipInRange.position !== 0) {
            const sortedClips = layersWithSortedClips[layerIndex];

            const indexOfFirstClipInRange = sortedClips.findIndex(function getIfMatch(clip) {
                return clip === firstClipInRange;
            });

            const headPadClip = sortedClips[indexOfFirstClipInRange - 1];
            if (headPadClip) {
                const headPadClipEnd = getClipEnd({ clip: headPadClip, timeWidth });
                const isFirstClipCloseToHeadPadClip =
                    headPadClipEnd + arbitraryThresholdDistance >= firstClipInRange.position;
                if (isFirstClipCloseToHeadPadClip) {
                    paddedClips = [headPadClip, ...clipsInRange];
                }
            }

            const indexOfLastClipInRange = sortedClips.findIndex(function getIfMatch(clip) {
                return clip === lastClipInRange;
            });

            const tailPadClip = sortedClips[indexOfLastClipInRange + 1];
            if (tailPadClip) {
                const lastClipInRangeEnd = getClipEnd({
                    clip: lastClipInRange,
                    timeWidth,
                });
                const isLastClipCloseToTailPadClip =
                    lastClipInRangeEnd + arbitraryThresholdDistance >= tailPadClip.position;
                if (isLastClipCloseToTailPadClip) {
                    paddedClips.push(tailPadClip);
                }
            }
        }
        return paddedClips;
    });

    return layersWithPaddedClips;
}
