import getTextureModificationAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getTextureModificationAssignedToSlot';

export default function calcUnAssignFoTextureCompFromSlot({ slotName, foSpineAsset }) {
    const textureModification = getTextureModificationAssignedToSlot({
        foSpineAsset,
        slotName,
    });
    const newTextureModification = { ...textureModification, current: '' };
    const newTextureModifications = foSpineAsset.modifications.textures.map(
        (currTextureModification) => {
            if (currTextureModification.slotName === newTextureModification.slotName) {
                return newTextureModification;
            }
            return currTextureModification;
        },
    );

    return newTextureModifications;
}
