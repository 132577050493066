import expressions from 'src/utils/businessLogic/expressions/constants/expressions';
import bodyParts from 'src/utils/businessLogic/expressions/constants/bodyParts';

import {
    fist,
    handFanning,
    handFidgeting,
    armToFaceLevel,
    handBackPalmOut,
    handFrontPalmIn,
    handOkIn,
    handOpenTenseIn,
    handPalmUpIn,
    handQuoteIn,
    handShakeIndexFingerIn,
    handShake2FingersIn,
    handMiddleFinger,
    handThumbDownIn,
    handThumbUpOut,
    handMoveAround,
    handRaiseToChestLevel,
    armsExpanded,
    armsBent,
    handThumbAcrossThroat,
    threeHops,
    handStrokingChin,
    handToMounth,
    handToEye,
    handToEar,
    donkey,
} from './sharedExpressionInfo';

const armLeftFamily = [
    'hand/left/move_back_neutral',
    'hand/left/move_down_neutral',
    'hand/left/move_forward_neutral',
    'hand/left/move_left_neutral',
    'hand/left/move_right_neutral',
    'hand/left/move_up_neutral',
];
const armRightFamily = [
    'hand/right/move_back_neutral',
    'hand/right/move_down_neutral',
    'hand/right/move_forward_neutral',
    'hand/right/move_left_neutral',
    'hand/right/move_right_neutral',
    'hand/right/move_up_neutral',
];
// rank is between [0,100], 0 will be used for blinking and idle anims
// that we don't want autopulating expressions.

// repetitionGapRatio (optional) [0,1]
// 0 - repeat without gaps, 1 - place only one at the end of the expression.
const expressionsByAnimation = Object.freeze({
    'body/heavy_breath': {
        affectedBodyParts: [bodyParts.BODY],
        expressions: {
            [expressions.NERVOUS]: { rank: 50 },
            [expressions.RELIEF]: { rank: 50 },
            [expressions.ANNOYED]: { rank: 50 },
            [expressions.ANGRY]: { rank: 50 },
            [expressions.FEAR]: { rank: 50 },
        },
    },
    'body/dance': {
        affectedBodyParts: [
            bodyParts.HEAD,
            bodyParts.BODY,
            bodyParts.ARM_LEFT,
            bodyParts.ARM_RIGHT,
            bodyParts.HAND_RIGHT,
            bodyParts.HAND_LEFT,
        ],
        expressions: {
            [expressions.HAPPY]: { rank: 50 },
            [expressions.RELIEF]: { rank: 50 },
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'body/idle': {
        affectedBodyParts: [bodyParts.BODY],
        isStatic: true,
        isIdle: true,
        expressions: {},
    },
    'body/floating_idle': {
        affectedBodyParts: [bodyParts.BODY, bodyParts.BODY_WINGS],
        isStatic: true,
        // todo - uncomment when it's conflicts with body/heavy_breath and body/shake are fixed
        // isIdle: true,
        expressions: {},
    },
    'body/lean_forward': {
        affectedBodyParts: [bodyParts.BODY],
        isStatic: true,
        expressions: {
            [expressions.ASSERT]: { rank: 50 },
        },
    },
    'body/shake': {
        affectedBodyParts: [bodyParts.BODY],
        expressions: {
            [expressions.HAPPY]: { rank: 50 },
            [expressions.SURPRISE]: { rank: 50 },
        },
    },
    'body/shoulder_down': {
        affectedBodyParts: [bodyParts.BODY_SHOULDERS],
        isStatic: true,
        expressions: {
            [expressions.SAD]: { rank: 50 },
            [expressions.INSECURE]: { rank: 50 },
        },
    },
    'body/shoulder_shrug': {
        affectedBodyParts: [bodyParts.BODY_SHOULDERS],
        isStatic: true,
        expressions: {
            [expressions.INDIFFERENT]: { rank: 60 },
            [expressions.UNCERTAIN]: { rank: 40 },
            [expressions.BORED]: { rank: 10 },
            [expressions.DISGUST]: { rank: 20 },
            [expressions.DISAPPROVE]: { rank: 10 },
        },
    },

    'body/wings/contract': {
        affectedBodyParts: [bodyParts.BODY_WINGS],
        isStatic: true,
        expressions: {
            [expressions.FEAR]: { rank: 50 },
            [expressions.INSECURE]: { rank: 70 },
            [expressions.DISAPPROVE]: { rank: 40 },
            [expressions.CRINGE]: { rank: 50 },
            [expressions.SURPRISE]: { rank: 40 },
            [expressions.DISGUST]: { rank: 25 },
        },
    },
    'body/wings/drop': {
        affectedBodyParts: [bodyParts.BODY_WINGS],
        isStatic: true,
        expressions: {
            [expressions.SAD]: { rank: 70 },
            [expressions.FEAR]: { rank: 50 },
            [expressions.INSECURE]: { rank: 40 },
            [expressions.ASHAMED]: { rank: 20 },
        },
    },
    'body/wings/extend': {
        affectedBodyParts: [bodyParts.BODY_WINGS],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 60 },
            [expressions.HAPPY]: { rank: 60 },
            [expressions.ASSERT]: { rank: 30 },
            [expressions.OPEN]: { rank: 20 },
            [expressions.THREAT]: { rank: 50 },
        },
    },
    'body/wings/shrug': {
        affectedBodyParts: [bodyParts.BODY_WINGS],
        isStatic: true,
        expressions: {
            [expressions.INDIFFERENT]: { rank: 60 },
            [expressions.UNCERTAIN]: { rank: 40 },
            [expressions.BORED]: { rank: 10 },
            [expressions.DISGUST]: { rank: 20 },
            [expressions.DISAPPROVE]: { rank: 10 },
        },
    },
    'body/wings/flap': {
        affectedBodyParts: [bodyParts.BODY_WINGS],
        expressions: {
            [expressions.HAPPY]: { rank: 70 },
            [expressions.ANGRY]: { rank: 40 },
            [expressions.ASSERT]: { rank: 40 },
            [expressions.FRAUSTRATED]: { rank: 10 },
        },
    },
    'body/wings/shake': {
        affectedBodyParts: [bodyParts.BODY_WINGS],
        expressions: {
            [expressions.HAPPY]: { rank: 50 },
            [expressions.SURPRISE]: { rank: 50 },
        },
    },

    'hand/applause': {
        affectedBodyParts: [
            bodyParts.ARM_LEFT,
            bodyParts.ARM_RIGHT,
            bodyParts.HAND_LEFT,
            bodyParts.HAND_RIGHT,
        ],
        expressions: {
            [expressions.HAPPY]: { rank: 80 },
            [expressions.SILLY]: { rank: 10 },
            [expressions.ALTERNATE_MEANING]: { rank: 10 },
        },
    },
    'hand/finger_counting': {
        affectedBodyParts: [
            bodyParts.ARM_LEFT,
            bodyParts.ARM_RIGHT,
            bodyParts.HAND_LEFT,
            bodyParts.HAND_RIGHT,
        ],
        expressions: {
            [expressions.ASSERT]: { rank: 80 },
            [expressions.ANGRY]: { rank: 60 },
            [expressions.ANNOYED]: { rank: 30 },
            [expressions.CONFIDENT]: { rank: 10 },
            [expressions.DISAPPROVE]: { rank: 10 },
            [expressions.THREAT]: { rank: 20 },
        },
    },

    'hand/right/arrow_poke': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        expressions: {
            [expressions.ACCENT]: { rank: 80 },
            [expressions.ANGRY]: { rank: 40 },
            [expressions.ASSERT]: { rank: 40 },
            [expressions.ANNOYED]: { rank: 10 },
            [expressions.DISAPPROVE]: { rank: 20 },
            [expressions.FRAUSTRATED]: { rank: 20 },
        },
    },

    'hand/right/fanning': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...handFanning,
    },
    'hand/left/fanning': {
        affectedBodyParts: [bodyParts.ARM_LEFT, bodyParts.HAND_LEFT],
        ...handFanning,
    },

    'hand/left/fidgeting': {
        affectedBodyParts: [bodyParts.ARM_LEFT, bodyParts.HAND_LEFT],
        ...handFidgeting,
    },
    'hand/right/fidgeting': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...handFidgeting,
    },

    'hand/fold_arms': {
        affectedBodyParts: [
            bodyParts.ARM_LEFT,
            bodyParts.HAND_LEFT,
            bodyParts.ARM_RIGHT,
            bodyParts.HAND_RIGHT,
        ],
        expressions: {
            [expressions.INSECURE]: { rank: 60 },
            [expressions.ANGRY]: { rank: 10 },
            [expressions.ANNOYED]: { rank: 10 },
            [expressions.DISAPPROVE]: { rank: 40 },
            [expressions.UNCERTAIN]: { rank: 15 },
            [expressions.FRAUSTRATED]: { rank: 50 },
        },
    },

    'hand/left/thumb_across_throat': {
        affectedBodyParts: [bodyParts.ARM_LEFT, bodyParts.HAND_LEFT],
        ...handThumbAcrossThroat,
    },
    'hand/right/thumb_across_throat': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...handThumbAcrossThroat,
    },

    'hand/left/stroking_chin': {
        affectedBodyParts: [bodyParts.ARM_LEFT, bodyParts.HAND_LEFT],
        ...handStrokingChin,
    },
    'hand/right/stroking_chin': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...handStrokingChin,
    },

    'hand/left/to_mouth': {
        affectedBodyParts: [bodyParts.ARM_LEFT, bodyParts.HAND_LEFT],
        ...handToMounth,
    },
    'hand/right/to_mouth': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...handToMounth,
    },
    'hand/left/to_eye': {
        affectedBodyParts: [bodyParts.ARM_LEFT, bodyParts.HAND_LEFT],
        ...handToEye,
    },
    'hand/right/to_eye': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...handToEye,
    },
    'hand/left/to_ear': {
        affectedBodyParts: [bodyParts.ARM_LEFT, bodyParts.HAND_LEFT],
        ...handToEar,
    },
    'hand/right/to_ear': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...handToEar,
    },
    'hand/right/move_right_3_hops': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...threeHops,
    },
    // todo - update when the anim is renamed hand/left/move_left_3_hops
    'hand/left/move_right_3_hops': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...threeHops,
    },

    'hand/right/move_to_face_level': {
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...armToFaceLevel,
    },
    'hand/left/move_to_face_level': {
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...armToFaceLevel,
    },
    'hand/right/out/back_palm': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handBackPalmOut,
    },
    'hand/left/out/back_palm': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handBackPalmOut,
    },
    'hand/right/in/front_palm': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handFrontPalmIn,
    },
    'hand/left/in/front_palm': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handFrontPalmIn,
    },

    'hand/right/out/fist': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...fist,
    },
    'hand/left/out/fist': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...fist,
    },

    'hand/right/in/fist': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...fist,
    },
    'hand/left/in/fist': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...fist,
    },

    'hand/right/in/ok': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handOkIn,
    },
    'hand/left/in/ok': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handOkIn,
    },

    'hand/right/in/open_tense': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handOpenTenseIn,
    },
    'hand/left/in/open_tense': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handOpenTenseIn,
    },

    'hand/right/in/palm_up': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handPalmUpIn,
    },
    'hand/left/in/palm_up': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handPalmUpIn,
    },

    'hand/right/in/quote': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handQuoteIn,
    },
    'hand/left/in/quote': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handQuoteIn,
    },

    'hand/right/in/shake_index_finger': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handShakeIndexFingerIn,
    },
    'hand/left/in/shake_index_finger': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handShakeIndexFingerIn,
    },
    'hand/right/in/2_fingers': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handShake2FingersIn,
    },
    'hand/left/in/2_fingers': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handShake2FingersIn,
    },
    // hand/right/in/3_fingers
    // hand/left/in/3_fingers
    // hand/right/in/4_fingers
    // hand/left/in/4_fingers
    // hand/right/in/5_fingers
    // hand/left/in/5_fingers
    'hand/right/in/middle_finger': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handMiddleFinger,
    },
    'hand/left/in/middle_finger': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handMiddleFinger,
    },
    'hand/right/out/middle_finger': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handMiddleFinger,
    },
    'hand/left/out/middle_finger': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handMiddleFinger,
    },

    'hand/right/in/thumb_down': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handThumbDownIn,
    },
    'hand/left/in/thumb_down': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handThumbDownIn,
    },

    'hand/right/out/thumb_up': {
        affectedBodyParts: [bodyParts.HAND_RIGHT],
        ...handThumbUpOut,
    },
    'hand/left/out/thumb_up': {
        affectedBodyParts: [bodyParts.HAND_LEFT],
        ...handThumbUpOut,
    },

    // hand move around
    'hand/left/move_back_neutral': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...handMoveAround,
    },
    'hand/right/move_back_neutral': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...handMoveAround,
    },

    'hand/left/move_down_neutral': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...handMoveAround,
    },
    'hand/right/move_down_neutral': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...handMoveAround,
    },

    'hand/left/move_forward_neutral': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...handMoveAround,
    },
    'hand/right/move_forward_neutral': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...handMoveAround,
    },

    'hand/left/move_left_neutral': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...handMoveAround,
    },
    'hand/right/move_left_neutral': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...handMoveAround,
    },

    'hand/left/move_right_neutral': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...handMoveAround,
    },
    'hand/right/move_right_neutral': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...handMoveAround,
    },

    'hand/left/move_up_neutral': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...handMoveAround,
    },
    'hand/right/move_up_neutral': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...handMoveAround,
    },
    'hand/right/bend_in': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...handMoveAround,
    },
    'hand/left/bend_in': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...handMoveAround,
    },
    'hand/right/bend_out': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...armsBent,
    },
    'hand/left/bend_out': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...armsBent,
    },
    'hand/right/diagonal_down': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...armsExpanded,
    },
    'hand/left/diagonal_down': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...armsExpanded,
    },
    'hand/right/diagonal_up': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...armsExpanded,
    },
    'hand/left/diagonal_up': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...armsExpanded,
    },
    'hand/right/horizontal': {
        animationFamily: armRightFamily,
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...armsExpanded,
    },
    'hand/left/horizontal': {
        animationFamily: armLeftFamily,
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...armsExpanded,
    },

    'hand/left/raise_to_chest_level': {
        affectedBodyParts: [bodyParts.ARM_LEFT],
        ...handRaiseToChestLevel,
    },
    'hand/right/raise_to_chest_level': {
        affectedBodyParts: [bodyParts.ARM_RIGHT],
        ...handRaiseToChestLevel,
    },
    'hand/left/donkey': {
        affectedBodyParts: [bodyParts.ARM_LEFT, bodyParts.HAND_LEFT],
        ...donkey,
    },
    'hand/right/donkey': {
        affectedBodyParts: [bodyParts.ARM_RIGHT, bodyParts.HAND_RIGHT],
        ...donkey,
    },

    'head/idle_fire': {
        affectedBodyParts: [bodyParts.HEAD_HAIR],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 90 },
            [expressions.FRAUSTRATED]: { rank: 10 },
            [expressions.SURPRISE]: { rank: 20 },
        },
    },
    // 'head/fidgeting': {
    //     affectedBodyParts: [bodyParts.HEAD, bodyParts.FACE_MOUTH, bodyParts.FACE_EYES],
    //     expressions: {
    //         [expressions.INDIFFERENT]: { rank: 50 },
    //         [expressions.DISAPPROVE]: { rank: 40 },
    //         [expressions.DISGUST]: { rank: 20 },
    //         [expressions.UNCERTAIN]: { rank: 50 },
    //     },
    // },
    'head/fidgeting_plain': {
        affectedBodyParts: [bodyParts.HEAD],
        isIdle: true,
        expressions: {
            // [expressions.INDIFFERENT]: { rank: 50 },
            // [expressions.DISAPPROVE]: { rank: 40 },
            // [expressions.DISGUST]: { rank: 20 },
            // [expressions.UNCERTAIN]: { rank: 50 },
        },
    },
    // 'head/idle': {
    //     affectedBodyParts: [bodyParts.HEAD],
    //     isIdle: true,
    //     expressions: {
    //         // [expressions.INDIFFERENT]: { rank: 50 },
    //     },
    // },
    'head/diamond': {
        affectedBodyParts: [bodyParts.HEAD_HAIR],
        expressions: {
            [expressions.ASSERT]: { rank: 20 },
            [expressions.CONFIDENT]: { rank: 70 },
        },
    },
    'head/down': {
        affectedBodyParts: [bodyParts.HEAD],
        isStatic: true,
        expressions: {
            [expressions.SAD]: { rank: 50 },
            [expressions.INSECURE]: { rank: 50 },
            [expressions.ASHAMED]: { rank: 50 },
            [expressions.SURPRISE]: { rank: 20 },
        },
    },
    'head/up': {
        affectedBodyParts: [bodyParts.HEAD],
        isStatic: true,
        expressions: {
            [expressions.ANNOYED]: { rank: 50 },
            [expressions.THINK]: { rank: 50 },
        },
    },
    'head/pan_left': {
        affectedBodyParts: [bodyParts.HEAD],
        isStatic: true,
        expressions: {
            [expressions.CONFUSED]: { rank: 50 },
            [expressions.ACCENT]: { rank: 50 },
            [expressions.DISAPPROVE]: { rank: 50 },
        },
    },
    'head/pan_right': {
        affectedBodyParts: [bodyParts.HEAD],
        isStatic: true,
        expressions: {
            [expressions.CONFUSED]: { rank: 50 },
            [expressions.ACCENT]: { rank: 50 },
            [expressions.DISAPPROVE]: { rank: 50 },
        },
    },
    'head/tilt_left': {
        affectedBodyParts: [bodyParts.HEAD],
        isStatic: true,
        expressions: {
            [expressions.ACCENT]: { rank: 50 },
        },
    },
    'head/tilt_right': {
        affectedBodyParts: [bodyParts.HEAD],
        isStatic: true,
        expressions: {
            [expressions.SAD]: { rank: 50 },
            [expressions.BORED]: { rank: 50 },
            [expressions.ACCENT]: { rank: 50 },
        },
    },
    'face/clenched': {
        affectedBodyParts: [bodyParts.FACE, bodyParts.FACE_MOUTH, bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 50 },
            [expressions.NERVOUS]: { rank: 50 },
        },
    },
    'face/sad': {
        affectedBodyParts: [bodyParts.FACE, bodyParts.FACE_MOUTH, bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.SAD]: { rank: 80 },
        },
    },
    'face/scream': {
        affectedBodyParts: [
            bodyParts.FACE,
            bodyParts.FACE_MOUTH,
            bodyParts.FACE_EYES,
            bodyParts.FACE_EYES_PUPILS,
        ],
        expressions: {
            [expressions.ANGRY]: { rank: 80 },
            [expressions.FRAUSTRATED]: { rank: 50 },
        },
    },
    'face/swallow': {
        affectedBodyParts: [bodyParts.FACE, bodyParts.FACE_MOUTH, bodyParts.FACE_EYES],
        expressions: {
            [expressions.FEAR]: { rank: 60 },
            [expressions.NERVOUS]: { rank: 50 },
        },
    },
    'face/tongue_out': {
        affectedBodyParts: [bodyParts.FACE, bodyParts.FACE_MOUTH, bodyParts.FACE_EYES],
        expressions: {
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'face/vomit': {
        affectedBodyParts: [
            bodyParts.FACE,
            bodyParts.FACE_MOUTH,
            bodyParts.FACE_EYES,
            bodyParts.FACE_EYES_PUPILS,
        ],
        expressions: {
            [expressions.DISGUST]: { rank: 70 },
        },
    },
    'face/wink': {
        affectedBodyParts: [bodyParts.FACE, bodyParts.FACE_MOUTH, bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.SILLY]: { rank: 40 },
            [expressions.ALTERNATE_MEANING]: { rank: 50 },
        },
    },
    'face/yawn': {
        affectedBodyParts: [bodyParts.FACE, bodyParts.FACE_MOUTH, bodyParts.FACE_EYES],
        expressions: {
            [expressions.TIRED]: { rank: 70 },
            [expressions.BORED]: { rank: 50 },
        },
    },
    'face/eyes/blinking': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        // isComplement: true,
        isBlinking: true,
        expressions: {
            [expressions.CONFUSED]: {
                rank: 0,
                repetitionGapRatio: 0.1,
            },
            [expressions.ANNOYED]: { rank: 0, repetitionGapRatio: 0.5 },
            [expressions.BORED]: { rank: 0, repetitionGapRatio: 0.2 },
        },
    },
    'face/eyes/brows_frowned': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 60 },
            [expressions.THINK]: { rank: 60 },
            [expressions.THREAT]: { rank: 30 },
            [expressions.NERVOUS]: { rank: 20 },
            [expressions.CONFUSED]: { rank: 20 },
        },
    },
    'face/eyes/brows_raised': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.SURPRISE]: { rank: 60 },
            [expressions.FEAR]: { rank: 20 },
        },
    },
    'face/eyes/clenched': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 50 },
            [expressions.NERVOUS]: { rank: 50 },
        },
    },
    'face/eyes/closed': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.BORED]: { rank: 10 },
            [expressions.TIRED]: { rank: 10 },
            [expressions.FRAUSTRATED]: { rank: 10 },
            [expressions.CONFUSED]: { rank: 10 },
        },
    },
    'face/eyes/heavy_eyelids': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.TIRED]: { rank: 10 },
        },
    },
    'face/eyes/lazy': {
        affectedBodyParts: [bodyParts.FACE_EYES, bodyParts.FACE_EYES_PUPILS],
        isStatic: true,
        expressions: {
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'face/eyes/open': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {},
    },
    'face/eyes/pressed_shut': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'face/eyes/rolling': {
        affectedBodyParts: [bodyParts.FACE_EYES, bodyParts.FACE_EYES_PUPILS],
        expressions: {
            [expressions.ANNOYED]: { rank: 70 },
        },
    },
    'face/eyes/down': {
        affectedBodyParts: [bodyParts.FACE_EYES_PUPILS],
        isStatic: true,
        expressions: {
            [expressions.THINK]: { rank: 50 },
            [expressions.ASHAMED]: { rank: 20 },
        },
    },
    'face/eyes/up': {
        affectedBodyParts: [bodyParts.FACE_EYES_PUPILS],
        isStatic: true,
        expressions: {
            [expressions.THINK]: { rank: 50 },
        },
    },
    'face/eyes/left': {
        affectedBodyParts: [bodyParts.FACE_EYES_PUPILS],
        isStatic: true,
        expressions: {
            [expressions.THINK]: { rank: 50 },
        },
    },
    'face/eyes/right': {
        affectedBodyParts: [bodyParts.FACE_EYES_PUPILS],
        isStatic: true,
        expressions: {
            [expressions.THINK]: { rank: 50 },
        },
    },
    'face/eyes/sad': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.SAD]: { rank: 80 },
        },
    },
    'face/eyes/sly_smile': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 50 },
            [expressions.UNCERTAIN]: { rank: 30 },
            [expressions.THREAT]: { rank: 30 },
        },
    },
    'face/eyes/smile_closed_lips': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.HAPPY]: { rank: 50 },
            [expressions.APPROVE]: { rank: 50 },
        },
    },
    'face/eyes/smile_teeth_shown': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.HAPPY]: { rank: 70 },
            [expressions.APPROVE]: { rank: 70 },
        },
    },
    'face/eyes/squint': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 60 },
            [expressions.CRINGE]: { rank: 30 },
            [expressions.UNCERTAIN]: { rank: 50 },
        },
    },
    'face/eyes/tongue_out': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        expressions: {
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'face/eyes/tongue_out_to_side': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        expressions: {
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'face/eyes/vomit': {
        affectedBodyParts: [bodyParts.FACE_EYES, bodyParts.FACE_EYES_PUPILS],
        expressions: {
            [expressions.DISGUST]: { rank: 70 },
        },
    },
    'face/eyes/wide_open': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.FEAR]: { rank: 70 },
            [expressions.INSECURE]: { rank: 40 },
            [expressions.SURPRISE]: { rank: 50 },
        },
    },
    'face/eyes/wink': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.SILLY]: { rank: 40 },
            [expressions.ALTERNATE_MEANING]: { rank: 50 },
            [expressions.UNCERTAIN]: { rank: 20 },
        },
    },
    'face/eyes/wrinkled': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        isStatic: true,
        expressions: {
            [expressions.DISGUST]: { rank: 50 },
            [expressions.CRINGE]: { rank: 50 },
        },
    },
    'face/eyes/yawn': {
        affectedBodyParts: [bodyParts.FACE_EYES],
        expressions: {
            [expressions.TIRED]: { rank: 70 },
            [expressions.UNCERTAIN]: { rank: 10 },
            [expressions.THINK]: { rank: 50 },
        },
    },
    'face/mouth/bite_lower_lip': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 10 },
            [expressions.NERVOUS]: { rank: 50 },
        },
    },
    'face/mouth/blow_air': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        expressions: {
            [expressions.ANNOYED]: { rank: 50 },
            [expressions.RELIEF]: { rank: 70 },
        },
    },
    'face/mouth/chenched_nostrils': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 50 },
            [expressions.DISGUST]: { rank: 50 },
        },
    },
    'face/mouth/clenched': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 50 },
            [expressions.NERVOUS]: { rank: 50 },
        },
    },
    'face/mouth/clenched_nostrils': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 50 },
            [expressions.DISGUST]: { rank: 50 },
        },
    },
    'face/mouth/closed': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {},
    },
    'face/mouth/lips_tight': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.ANNOYED]: { rank: 50 },
            [expressions.NERVOUS]: { rank: 50 },
            [expressions.THINK]: { rank: 40 },
            [expressions.INDIFFERENT]: { rank: 50 },
            [expressions.ASSERT]: { rank: 50 },
            [expressions.THREAT]: { rank: 50 },
            [expressions.DISAPPROVE]: { rank: 60 },
        },
    },
    'face/mouth/open': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.FEAR]: { rank: 60 },
            [expressions.INSECURE]: { rank: 60 },
        },
    },
    'face/mouth/sad': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.SAD]: { rank: 80 },
        },
    },
    'face/mouth/sly_smile': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.ANGRY]: { rank: 50 },
            [expressions.UNCERTAIN]: { rank: 50 },
            [expressions.HAPPY]: { rank: 50 },
        },
    },
    'face/mouth/smile_closed_lips': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.HAPPY]: { rank: 50 },
            [expressions.APPROVE]: { rank: 50 },
            [expressions.ANNOYED]: { rank: 50 },
        },
    },
    'face/mouth/smile_teeth_shown': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.HAPPY]: { rank: 70 },
            [expressions.APPROVE]: { rank: 70 },
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'face/mouth/tongue_out': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        expressions: {
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'face/mouth/tongue_out_to_side': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        expressions: {
            [expressions.SILLY]: { rank: 50 },
        },
    },
    'face/mouth/vomit': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        expressions: {
            [expressions.DISGUST]: { rank: 70 },
            [expressions.SURPRISE]: { rank: 30 },
        },
    },
    'face/mouth/wink': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.SILLY]: { rank: 40 },
            [expressions.ALTERNATE_MEANING]: { rank: 50 },
            [expressions.SURPRISE]: { rank: 30 },
            [expressions.HAPPY]: { rank: 30 },
        },
    },
    'face/mouth/wrinkled': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.DISGUST]: { rank: 50 },
            [expressions.CRINGE]: { rank: 50 },
        },
    },
    'face/mouth/wrinkled_right_side_only': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        isStatic: true,
        expressions: {
            [expressions.UNCERTAIN]: { rank: 70 },
        },
    },
    'face/mouth/yawn': {
        affectedBodyParts: [bodyParts.FACE_MOUTH],
        expressions: {
            [expressions.TIRED]: { rank: 70 },
        },
    },
    // neutral: {
    //     isStatic: true,
    //     expressions: {},
    // },
});

Object.keys(expressionsByAnimation).forEach(function _logAnimationIfItHasNoBodyParts(
    animationName,
) {
    const expression = expressionsByAnimation[animationName];
    if (!expression.affectedBodyParts?.length) {
        console.warn(
            `Animation ${animationName} in expressionsByAnimation map does not have any affected body parts.`,
        );
    }
});

export default expressionsByAnimation;
