import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

export default function ToolContainer(props) {
    const { className, isVertical, isSubContainer, ...attributes } = props;

    return (
        <div
            className={classnames(
                'ToolContainer',
                { 'ToolContainer--vertical': isVertical },
                { 'ToolContainer--subContainer': isSubContainer },
                className,
            )}
            {...attributes}
        />
    );
}

ToolContainer.propTypes = {
    className: PropTypes.string,
    isVertical: PropTypes.bool,
    isSubContainer: PropTypes.bool,
};

ToolContainer.defaultProps = {
    className: '',
    isVertical: false,
    isSubContainer: false,
};
