import calcSetTextureModificationAtom from 'src/utils/businessLogic/foSpineAsset/calcSetTextureModificationAtom';

export default function putTextureModificationAtomOnFoSpineAsset({
    textureModificationAtom,
    foSpineAsset,
    setFoSpineAssetTextureModifications,
    setFoSpineAssetLinkOnFoTextureComp,
}) {
    const newTextureModifications = calcSetTextureModificationAtom({
        foSpineAsset,
        textureModificationAtom,
    });

    setFoSpineAssetTextureModifications({
        textureModifications: newTextureModifications,
        foSpineAssetId: foSpineAsset.id,
    });

    if (setFoSpineAssetLinkOnFoTextureComp) {
        const { foTextureCompId } = textureModificationAtom;
        setFoSpineAssetLinkOnFoTextureComp({ foTextureCompId, foSpineAssetId: foSpineAsset.id });
    }
}
