import { Text, TextStyle, Container } from 'pixi.js';

import pixiStore from 'src/pixi/pixiStore';

export default function setupMonitorOvelays({
    handleCurrentTimeMonitorTextUpdate,
    handleSpeechOverlayUpdate,
    monitorOverlaysDispalyObjsRef,
    isMonitorOverlaysShown,
}) {
    const app = pixiStore.pixiAppManager.getPixiApp();
    const monitorOverlaysContainer = new Container();
    monitorOverlaysContainer.visible = isMonitorOverlaysShown;
    const timeTextStyle = new TextStyle({
        fontFamily: 'Arial',
        fontSize: 15,
        letterSpacing: 2,
        fill: 'green',
    });
    const timeDisplayObj = new Text('', timeTextStyle);
    monitorOverlaysContainer.addChild(timeDisplayObj);
    app.ticker.add(handleCurrentTimeMonitorTextUpdate);

    const speechOverlayStyle = new TextStyle({
        fontFamily: 'Arial',
        fontSize: 15,
        letterSpacing: 2,
        fill: 'white',
    });
    const speechOverlayDisplayObj = new Text('', speechOverlayStyle);
    monitorOverlaysContainer.addChild(speechOverlayDisplayObj);
    app.ticker.add(handleSpeechOverlayUpdate);

    app.stage.addChild(monitorOverlaysContainer);

    monitorOverlaysDispalyObjsRef.current = {
        monitorOverlaysContainer,
        timeDisplayObj,
        speechOverlayDisplayObj,
    };
}
