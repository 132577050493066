import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import Line from 'src/components/shared/Line';
import {
    assetTrackModeLike,
    editModesLike,
} from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';
import refLike from 'src/components/shared/propTypes/refLike';
import {
    toggleIsSnap,
    deselectClips,
    setTrimMode,
    setConflictResolution,
    setAssetTrackMode,
} from 'src/components/views/SceneEditor/Timeline/timelineSlice';
import ToolContainer from 'src/components/shared/ToolContainer';
import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';

import TimeDisplay from './TimeDisplay';
import SnapButton from './SnapButton';
import ConflictResolutionModes from './ConflictResolutionModes';
import TrimModes from './TrimModes';
import AssetTrackModes from './AssetTrackModes';
import ZoomControl from './ZoomControl';
import GroupClipsButton from './GroupClipsButton';

import styles from './styles.module.scss';

function Toolbar(props) {
    const {
        className,
        currentTime,
        editModes,
        toggleIsSnap,
        deselectClips,
        setTrimMode,
        horizontalScrollElemRef,
        setConflictResolution,
        activeAssetTrackMode,
        setAssetTrackMode,
        ...attributes
    } = props;
    const { conflictResolution, isSnap, trimMode } = editModes;

    const handleToolbarClick = useCallback(
        (event) => {
            if (event.target === event.currentTarget) {
                deselectClips();
            }
        },
        [deselectClips],
    );

    return (
        <ToolContainer
            className={classnames(styles.Toolbar, className)}
            onClick={handleToolbarClick}
            role='none'
            {...attributes}
        >
            <div className={styles.Toolbar_rightItems}>
                <AssetTrackModes
                    activeAssetTrackMode={activeAssetTrackMode}
                    setAssetTrackMode={setAssetTrackMode}
                    className='ToolContainer__tool'
                />
                <Line isVertical className='ToolContainer__tool' />
                <SnapButton
                    isActive={isSnap}
                    onClick={() => toggleIsSnap()}
                    className='ToolContainer__tool'
                />
                <Line isVertical className='ToolContainer__tool' />
                <ConflictResolutionModes
                    activeMode={conflictResolution}
                    className='ToolContainer__tool'
                    setConflictResolution={setConflictResolution}
                />
                <Line isVertical className='ToolContainer__tool' />
                <TrimModes
                    activeMode={trimMode}
                    setTrimMode={setTrimMode}
                    className='ToolContainer__tool'
                    isDisabled={activeAssetTrackMode === assetTrackModes.EXPRESSION}
                />
                <Line isVertical className='ToolContainer__tool' />
                <GroupClipsButton className='ToolContainer__tool' />
            </div>
            <div className={styles.Toolbar_leftItems}>
                <ZoomControl
                    className='ToolContainer__tool'
                    horizontalScrollElemRef={horizontalScrollElemRef}
                />
                <TimeDisplay time={currentTime} />
            </div>
        </ToolContainer>
    );
}

Toolbar.propTypes = {
    className: PropTypes.string,
    activeAssetTrackMode: assetTrackModeLike.isRequired,
    deselectClips: PropTypes.func.isRequired,
    editModes: editModesLike.isRequired,
    toggleIsSnap: PropTypes.func.isRequired,
    setTrimMode: PropTypes.func.isRequired,
    setAssetTrackMode: PropTypes.func.isRequired,
    setConflictResolution: PropTypes.func.isRequired,
    currentTime: PropTypes.number.isRequired,
    horizontalScrollElemRef: refLike.isRequired,
};

Toolbar.defaultProps = {
    className: '',
};

function mapState(state) {
    const { editModes, assetTrackMode: activeAssetTrackMode } = state.timeline;
    return { editModes, activeAssetTrackMode };
}

export default connect(mapState, {
    toggleIsSnap,
    deselectClips,
    setTrimMode,
    setConflictResolution,
    setAssetTrackMode,
})(Toolbar);
