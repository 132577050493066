import {
    AGGRESSIVE,
    SUBMISSIVE,
    INSERT_COMPACT_SHIFT,
    INSERT_SHIFT,
} from 'src/utils/businessLogic/clipUtils/insertClip/constants/conflictModes';
import getConflictInfo from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getConflictInfo';

import insertAggressively from './insertAggressively';
import insertSubmissively from './insertSubmissively';
import insertShift from './insertShift';
import snapClipToGrid from './snapClipToGrid';

export default function insertClip({
    newClip: clip,
    clipsById,
    conflictResolution,
    snapDistance,
    timeWidth,
    trimMode,
}) {
    const newClip = snapDistance
        ? snapClipToGrid({ clip, snapDistance, timeWidth, trimMode })
        : clip;

    const conflictInfo = getConflictInfo({ newClip, clipsById, timeWidth });
    const { isConflictToRight, isConflictToLeft } = conflictInfo;

    if (!isConflictToRight && !isConflictToLeft) {
        const newClipsById = {
            ...clipsById,
            [newClip.clipId]: newClip,
        };
        return newClipsById;
    }

    if (conflictResolution === AGGRESSIVE) {
        const newClipsById = insertAggressively({
            newClip,
            clipsById,
            conflictInfo,
            timeWidth,
            trimMode,
        });
        return newClipsById;
    }

    if (conflictResolution === SUBMISSIVE) {
        const newClipsById = insertSubmissively({
            newClip,
            clipsById,
            conflictInfo,
            timeWidth,
            trimMode,
        });
        return newClipsById;
    }

    if (conflictResolution === INSERT_SHIFT) {
        const newClipsById = insertShift({
            newClip,
            clipsById,
            conflictInfo,
            timeWidth,
        });
        return newClipsById;
    }

    if (conflictResolution === INSERT_COMPACT_SHIFT) {
        const newClipsById = insertShift({
            newClip,
            clipsById,
            conflictInfo,
            timeWidth,
            isCompactShift: true,
        });
        return newClipsById;
    }

    return clipsById;
}
