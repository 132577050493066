import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useParams, Outlet } from 'react-router-dom';

import useTypedSelector from 'src/reduxStore/utils/useTypedSelector';
import * as routes from 'src/components/constants/routes';

import StepsNavLink from './StepsNavLink';

import styles from './styles.module.scss';

export default function StepsNav(props) {
    const { className, ...attributes } = props;

    const { sceneId: selectedSceneId } = useParams();

    const { isValidSceneSelected } = useTypedSelector({
        selectorFn(state) {
            const scene = state.sceneSelector.scenes.byId[selectedSceneId];
            const isValidSceneSelected = !!scene;
            return { isValidSceneSelected };
        },
        selectionTypes: { isValidSceneSelected: PropTypes.bool.isRequired },
    });

    return (
        <>
            <nav className={classnames(styles.StepsNav, className)} {...attributes}>
                <StepsNavLink
                    //
                    to=''
                    title='Choose Scene'
                    subTitle='Step 1'
                    end
                />
                <StepsNavLink
                    to={routes.ASSETS}
                    title='Choose the body'
                    subTitle='Step 2'
                    isDisabled={!isValidSceneSelected}
                />
                <StepsNavLink
                    to={routes.TEXTURES}
                    title='Make the face'
                    subTitle='Step 3'
                    isDisabled={!isValidSceneSelected}
                />
                <StepsNavLink
                    to={routes.AUDIO}
                    title='Choose audio'
                    subTitle='Step 4'
                    isDisabled={!isValidSceneSelected}
                />
                <StepsNavLink
                    to={routes.EDIT_SCENE}
                    title='FUNIMATE!'
                    isDisabled={!isValidSceneSelected}
                />
            </nav>
            <Outlet />
        </>
    );
}

StepsNav.propTypes = {
    className: PropTypes.string,
};

StepsNav.defaultProps = {
    className: '',
};
