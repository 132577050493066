import PropTypes from 'prop-types';

import pointLike from 'src/components/shared/propTypes/pointLike';

export const foTextureTransformLike = PropTypes.shape({
    anchor: pointLike,
    position: pointLike.isRequired,
    rotation: PropTypes.number.isRequired,
    pivot: pointLike.isRequired,
    scale: pointLike.isRequired,
});

export const maskPointDataLike = PropTypes.shape({
    id: PropTypes.string.isRequired,
    isTangentsLinked: PropTypes.bool.isRequired,
    main: pointLike.isRequired,
    tangent1: pointLike,
    tangent2: pointLike,
});
export const maskPointsDataLike = PropTypes.arrayOf(maskPointDataLike);
export const maskDataLike = PropTypes.shape({
    points: maskPointsDataLike.isRequired,
    type: PropTypes.string.isRequired,
});

export const foTextureLike = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    url: PropTypes.string.isRequired,
    transform: foTextureTransformLike,
    maskData: maskDataLike,
    zIndex: PropTypes.number.isRequired,
});
