import React from 'react';
import classnames from 'classnames';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function TrashCanIcon(props) {
    const { className, ...otherProps } = props;

    return (
        <FontAwesomeIcon
            icon={faTrashAlt}
            className={classnames('TrashCanIcon', className)}
            {...otherProps}
        />
    );
}
