import {
    TRANSFORM_TOOL,
    MASK_TOOL,
} from 'src/components/views/TextureRouteManager/TextureEditor/constants/toolTypes';
import {
    getTextureTools,
    setTextureTools,
} from 'src/components/views/TextureRouteManager/TextureEditor/pixiAssets/textureTools';

export default function handleToolTypeSelectionChange(newSelectedToolType) {
    const {
        transformTool,
        maskTool,
        selectedToolType: currentSelectedToolType,
    } = getTextureTools();

    if (newSelectedToolType === TRANSFORM_TOOL && currentSelectedToolType !== TRANSFORM_TOOL) {
        const { target } = maskTool;
        if (target) {
            maskTool.clear();
            transformTool.applyTo(target);
        }
    } else if (newSelectedToolType === MASK_TOOL && currentSelectedToolType !== MASK_TOOL) {
        const { target } = transformTool;
        if (target) {
            transformTool.clear();
            maskTool.applyTo(target);
        }
    }
    setTextureTools({ selectedToolType: newSelectedToolType });
}
