import makeMaskPoint from 'src/pixi/tools/MaskTool/utils/makeMaskPoint';
import convertCornerPointToTangentPoint from 'src/pixi/tools/MaskTool/utils/convertCornerPointToTangentPoint';
import calcClosestBezierIndex from 'src/pixi/tools/MaskTool/utils/calcClosestBezierIndex';

export default function addPointToMaskBezier({ maskTool, pointerEventData }) {
    const points = maskTool.getPoints();

    const pointerPosition = pointerEventData.getLocalPosition(maskTool.target);
    const addedPointIndex = calcClosestBezierIndex({
        points,
        targetPosition: pointerPosition,
    });
    const newPoint = makeMaskPoint({ main: pointerPosition });
    points.splice(addedPointIndex + 1, 0, newPoint);
    convertCornerPointToTangentPoint({ points, point: newPoint });

    maskTool.positionControls();
}
