import React, { useRef, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { set } from 'lodash';

import { setIsTrackLayerUpdating } from 'src/components/views/SceneEditor/Timeline/timelineSlice';
import { tracksByIdLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import { editModesLike } from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';
import useTrackLayerDrop from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/hooks/useTrackLayerDrop';
import useLastApprovedClipsRestoration from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/hooks/useLastApprovedClipsRestoration';
import getIsTrackLayerUpdating from 'src/components/views/SceneEditor/Timeline/utils/getIsTrackLayerUpdating';
import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';

import TrackLayer from './TrackLayer';
import SnapGuideOverlay from './SnapGuideOverlay';

import styles from './styles.module.scss';

function TrackLayerDnD(props) {
    const {
        className,
        tracksById,
        editModes,
        snapDistance,
        setClipsById,
        setIsTrackLayerUpdating,
        isTrackLayerUpdating,
        trackId,
        timeWidth,
        zoomLevel,
        trackLayerId,
        setTracks,
        isDisabled,
        ...otherProps
    } = props;

    const { clipsById } = tracksById[trackId].layers.byId[trackLayerId];
    const { isHoveringMapRef, isDroppedRef, lastApprovedTracksByIdRef } = useContext(
        SceneEditorContext,
    );

    const trackNodeRef = useRef(null);
    const [{ isHovering }, dropRef] = useTrackLayerDrop({
        lastApprovedTracksByIdRef,
        isDroppedRef,
        trackNodeRef,
        clipsById,
        setTracks,
        editModes,
        snapDistance,
        trackId,
        trackLayerId,
        setClipsById,
        setIsTrackLayerUpdating,
        isTrackLayerUpdating,
        timeWidth,
        zoomLevel,
    });

    set(isHoveringMapRef.current, [trackId, trackLayerId], isHovering);

    useLastApprovedClipsRestoration({
        isDroppedRef,
        isHoveringMapRef,
        lastApprovedTracksByIdRef,
        tracksById,
        setTracks,
    });

    dropRef(trackNodeRef);

    return (
        <div ref={trackNodeRef} className={classnames(styles.TrackLayerDnD, className)}>
            <TrackLayer
                trackId={trackId}
                trackLayerId={trackLayerId}
                isInPreview={isHovering}
                clipsById={clipsById}
                isDisabled={isDisabled}
                {...otherProps}
            />
            {editModes.isSnap && (
                <SnapGuideOverlay snapDistance={snapDistance} zoomLevel={zoomLevel} />
            )}
        </div>
    );
}

TrackLayerDnD.propTypes = {
    className: PropTypes.string,
    tracksById: tracksByIdLike.isRequired,
    setTracks: PropTypes.func.isRequired,
    editModes: editModesLike.isRequired,
    trackId: PropTypes.string.isRequired,
    trackLayerId: PropTypes.string.isRequired,
    setClipsById: PropTypes.func.isRequired,
    isTrackLayerUpdating: PropTypes.bool.isRequired,
    timeWidth: PropTypes.number.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    isDisabled: PropTypes.bool,
};

TrackLayerDnD.defaultProps = {
    className: '',
    isDisabled: false,
};

function mapState(state, ownProps) {
    const { trackId, trackLayerId } = ownProps;

    const { editModes, snapDistance, updatingTracksById, timeWidth, zoomLevel } = state.timeline;
    const isTrackLayerUpdating = getIsTrackLayerUpdating({
        updatingTracksById,
        trackId,
        trackLayerId,
    });

    return {
        editModes,
        snapDistance,
        isTrackLayerUpdating,
        timeWidth,
        zoomLevel,
    };
}

export default connect(mapState, { setIsTrackLayerUpdating })(TrackLayerDnD);
