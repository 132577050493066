import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';

import './styles.scss';

export default function StopToolButton(props) {
    const { className, ...attributes } = props;

    return (
        <ToolButton className={classnames('StopToolButton', className)} {...attributes}>
            &#10074;
        </ToolButton>
    );
}

StopToolButton.propTypes = {
    ...ToolButton.propTypes,
    className: PropTypes.string,
};

StopToolButton.defaultProps = {
    ...ToolButton.defaultProps,
    className: '',
};
