import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Input from 'src/components/shared/inputFactory/Input';
import refLike from 'src/components/shared/propTypes/refLike';

import './styles.scss';

export default function EditableTextBox(props) {
    const { className, isEditing, labelProps, inputProps, nodeRef, ...attributes } = props;

    return (
        <div className={classnames('EditableTextBox', className)} ref={nodeRef} {...attributes}>
            {isEditing ? (
                <Input type='text' {...inputProps} />
            ) : (
                <div className='EditableTextBox__label' {...labelProps} role='none'>
                    <span className='EditableTextBox__text'>{inputProps.value}</span>
                </div>
            )}
        </div>
    );
}

EditableTextBox.propTypes = {
    className: PropTypes.string,
    isEditing: PropTypes.bool.isRequired,
    inputProps: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
    labelProps: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
    }).isRequired,
    nodeRef: refLike,
};

EditableTextBox.defaultProps = {
    className: '',
    nodeRef: { current: null },
};
