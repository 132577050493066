import buildClipForGraphemeChar from 'src/utils/businessLogic/clipUtils/convertFoTranscriptWordsToClips/buildClipForGraphemeChar';
import generateClipGroup from 'src/utils/businessLogic/clipUtils/generateClipGroup';

export default function buildWordClipGroup({
    foTranscriptWord,
    timeWidth,
    foSpineAssetId,
    graphemeToAnimationMap,
}) {
    const graphemeClipsById = {};
    const { foGraphemeChars, startTime, word } = foTranscriptWord;
    foGraphemeChars.forEach(function _buildClipFromFoGraphemeChar(foGraphemeChar) {
        const { graphemeChar, duration, startTime } = foGraphemeChar;
        const charPos = startTime * timeWidth;
        const clip = buildClipForGraphemeChar({
            graphemeChar,
            duration,
            position: charPos,
            foSpineAssetId,
            graphemeToAnimationMap,
        });
        graphemeClipsById[clip.clipId] = clip;
    });

    const wordClipGroup = generateClipGroup({
        position: startTime * timeWidth,
        data: {
            clipsById: graphemeClipsById,
            title: word,
        },
    });

    return wordClipGroup;
}
