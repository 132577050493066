export default function filterEmptyTrackLayers({ allLayerIds = [], layersById = {} }) {
    const filteredLayersById = {};
    const filteredAllLayerIds = [];
    allLayerIds.forEach(function _includeNonEmptyLayer(trackLayerId) {
        const trackLayer = layersById[trackLayerId];
        const isLayerEmpty = !Object.keys(trackLayer.clipsById).length;
        if (!isLayerEmpty) {
            filteredLayersById[trackLayerId] = trackLayer;
            filteredAllLayerIds.push(trackLayerId);
        }
    });

    return { layersById: filteredLayersById, allLayerIds: filteredAllLayerIds };
}
