import generateTrack from 'src/utils/businessLogic/trackUtils/generateTrack';

export default function generateScene({ tracks: receivedTracks, name, selectedAudioTrackId } = {}) {
    let tracks = receivedTracks;
    if (!tracks) {
        const track = generateTrack();
        tracks = {
            byId: { [track.trackId]: track },
            allIds: [track.trackId],
        };
    }

    const scene = {
        name,
        selectedAudioTrackId,
        tracks,
    };

    return scene;
}
