// import clipTypes from 'src/components/constants/clipTypes';

// import moveLikeJagger01ClipsById from './moveLikeJagger01';
// import groupedClipsById from './groupedClipsById';
import mockExpressionTimelineKeys from './mockExpressionTimelineKeys';

export default {
    byId: {
        track01: {
            trackId: 'track01',
            foSpineAsset: {
                // id: 'zombie',
                // id: 'zombie talking',
                id: 'cupid3d',
                fallbackHistory: [],
                // id: 'zombie_01',
                // // We need this in case foSpineAsset got deleted,
                // // and the track needs another foSpineAsset to fallback on.
                // fallbackHistory: ['zombie'],
            },
            transformation: {
                position: [
                    {
                        time: 0,
                        value: { x: 0, y: 0 },
                    },
                ],
                pivot: [
                    {
                        time: 0,
                        value: { x: 0, y: 0 },
                    },
                ],
                rotation: [
                    {
                        time: 0,
                        value: 0,
                    },
                ],
                scale: [
                    {
                        time: 0,
                        value: { x: 1, y: 1 },
                    },
                ],
            },
            layers: {
                byId: {
                    layer01: {
                        trackLayerId: 'layer01',
                        clipsById: {
                            // ...moveLikeJagger01ClipsById,
                            // ...groupedClipsById,
                        },
                        settings: {
                            isDisabled: false,
                            isHidden: false,
                        },
                    },
                    layer02: {
                        trackLayerId: 'layer02',
                        clipsById: {
                            // chris_taucker01: {
                            //     data: {
                            //         type: clipTypes.CLIP_SINGLE,
                            //         animationId: 'chris_taucker',
                            //         title: 'Chris Tucker',
                            //         originalDuration: 1.916700005531311,
                            //         animationStart: 0,
                            //         animationEnd: 1.916700005531311,
                            //         timeScale: 1,
                            //     },
                            //     position: 100,
                            //     clipId: 'chris_taucker01',
                            // },
                        },
                        settings: {
                            isDisabled: false,
                            isHidden: false,
                        },
                    },
                },
                allIds: ['layer01', 'layer02'],
            },
            expressionTimelineKeys: mockExpressionTimelineKeys,
        },
    },
    allIds: ['track01'],
};
