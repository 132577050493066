import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ToggleToolButton from 'src/components/shared/buttonFactory/ToggleToolButton';
import AnimationDnD from 'src/components/views/SceneEditor/Bins/Animations/AnimationList/AnimationListItem/AnimationDnD';
import { animationTreeNodeLike } from 'src/components/views/SceneEditor/Bins/Animations/animationsSlice/propTypes';

import styles from './styles.module.scss';

export default function AnimationListItem(props) {
    const { className, animationTreeNode, ...attributes } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleToggleButtonClick = useCallback(
        function _handleToggleButtonClick() {
            setIsOpen(!isOpen);
        },
        [isOpen, setIsOpen],
    );

    return (
        <li className={classnames(styles.AnimationListItem, className)} {...attributes}>
            {animationTreeNode.children && (
                <div className={styles.AnimationListItem__title}>
                    <ToggleToolButton
                        isOpen={isOpen}
                        onClick={handleToggleButtonClick}
                        className={styles.AnimationListItem__toggleLayerToolButton}
                    >
                        <span className={styles.AnimationListItem__content}>
                            {animationTreeNode.title}
                        </span>
                    </ToggleToolButton>
                </div>
            )}

            {animationTreeNode.children && isOpen && (
                <ul className={styles.AnimationListItem__animations}>
                    {animationTreeNode.children.map((childAnimationTreeNode) => {
                        return (
                            <AnimationListItem
                                className={styles.AnimationListItem__animationListItem}
                                key={childAnimationTreeNode.title}
                                animationTreeNode={childAnimationTreeNode}
                            />
                        );
                    })}
                </ul>
            )}

            {!animationTreeNode.children && (
                <AnimationDnD
                    key={animationTreeNode.animation.animationId}
                    title={animationTreeNode.title}
                    className={styles.AnimationDnD}
                    animation={animationTreeNode.animation}
                />
            )}
        </li>
    );
}

AnimationListItem.propTypes = {
    className: PropTypes.string,
    animationTreeNode: animationTreeNodeLike.isRequired,
};

AnimationListItem.defaultProps = {
    className: '',
};
