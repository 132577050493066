//       __        ___
// \  / /  \ |  | |__  |       |
//  \/  \__/ |/\| |___ |___    |
// vowel 1
const vowelGroup1 = {
    a: [
        { word: 'about', grapheme: 'ə-ˈbau̇t' },
        { word: 'have', grapheme: 'ˈhav' },
        { word: 'that', grapheme: 'ˈt͟hat' },
        { word: 'how', grapheme: 'ˈhau̇' },
    ],
    ä: [
        { word: 'what', grapheme: 'ˈ(h)wät' },
        { word: 'not', grapheme: 'ˈnät' },
        { word: 'part', grapheme: 'ˈpärt' },
        { word: 'our', grapheme: 'är' },
    ],
    ā: [
        { word: 'make', grapheme: 'ˈmāk' },
        { word: 'they', grapheme: 'ˈt͟hā' },
        { word: 'made', grapheme: 'ˈmād' },
        { word: 'may', grapheme: 'ˈmā' },
    ],
    e: [
        { word: "let's", grapheme: 'ˈlets' },
        { word: 'success', grapheme: 'sək-ˈses' },
        { word: 'said', grapheme: 'ˈsed' },
        { word: 'when', grapheme: 'ˈ(h)wen' },
    ],
    ē: [
        { word: 'people', grapheme: 'ˈpē-pəl' },
        { word: 'we', grapheme: 'ˈwē' },
        { word: 'she', grapheme: 'ˈshē' },
        { word: 'see', grapheme: 'ˈsē' },
    ],
    ә: [{ word: 'of', grapheme: 'əәv' }],
    ə: [
        { word: 'about', grapheme: 'ə-ˈbau̇t' },
        { word: 'success', grapheme: 'sək-ˈses' },
        { word: 'people', grapheme: 'ˈpē-pəl' },
        { word: 'your', grapheme: 'yər' },
    ],
    ᵊ: [
        { word: 'sentence', grapheme: 'ˈsen-tᵊn(t)s' },
        { word: 'little', grapheme: 'ˈli-tᵊl' },
        { word: 'important', grapheme: 'im-ˈpȯr-tᵊnt' },
        { word: 'mountain', grapheme: 'ˈmau̇n-tᵊn' },
    ],
    ī: [
        { word: 'write', grapheme: 'ˈrīt' },
        { word: 'find', grapheme: 'ˈfīnd' },
        { word: 'my', grapheme: 'ˈmī' },
        { word: 'by', grapheme: 'ˈbī' },
    ],
    i: [
        { word: 'it', grapheme: 'ˈit' },
        { word: 'his', grapheme: '(h)iz' },
        { word: 'him', grapheme: 'im' },
        { word: 'with', grapheme: 'ˈwit͟h' },
    ],
};
const vowelGroup1ConsolidationMap = {
    a: 'a',
    ä: 'a',
    ā: 'a',
    e: 'a',
    ē: 'a',
    ә: 'a',
    ə: 'a',
    ᵊ: 'a',
    ī: 'a',
    i: 'a',
};

//       __        ___
// \  / /  \ |  | |__  |       ||
//  \/  \__/ |/\| |___ |___    ||
// vowel 2
const vowelGroup2 = {
    u̇: [
        { word: 'about', grapheme: 'ə-ˈbau̇t' },
        { word: 'look', grapheme: 'ˈlu̇k' },
        { word: 'how', grapheme: 'ˈhau̇' },
        { word: 'now', grapheme: 'ˈnau̇' },
    ],
    ȯ: [
        { word: 'talk', grapheme: 'ˈtȯk' },
        { word: 'water', grapheme: 'ˈwȯ-tər' },
        { word: 'all', grapheme: 'ˈȯl' },
        { word: 'oil', grapheme: 'ˈȯi(-ə)l' },
    ],
    ō: [
        { word: 'so', grapheme: 'ˈsō' },
        { word: 'no', grapheme: 'ˈnō' },
        { word: 'go', grapheme: 'ˈgō' },
        { word: 'over', grapheme: 'ˈō-vər' },
    ],
    ü: [
        { word: 'do', grapheme: 'ˈdü' },
        { word: 'you', grapheme: 'ˈyü' },
        { word: 'who', grapheme: 'ˈhü' },
        { word: 'into', grapheme: 'ˈin-(ˌ)tü' },
    ],
};
const vowelGroup2ConsolidationMap = {
    u̇: 'ō',
    ȯ: 'ō',
    ō: 'ō',
    ü: 'ō',
};

//       __        ___       __
// \  / /  \ |  | |__  |    /__`
//  \/  \__/ |/\| |___ |___ .__/
// vowels
export const vowels = {
    ...vowelGroup1,
    ...vowelGroup2,
};

//  __   __        __   __                 ___
// /  ` /  \ |\ | /__` /  \ |\ |  /\  |\ |  |     |
// \__, \__/ | \| .__/ \__/ | \| /~~\ | \|  |     |
// consonant1
const consonantGroup1 = {
    l: [
        { word: "let's", grapheme: 'ˈlets' },
        { word: 'people', grapheme: 'ˈpē-pəl' },
        { word: 'look', grapheme: 'ˈlu̇k' },
        { word: 'all', grapheme: 'ˈȯl' },
    ],
    t: [
        { word: "let's", grapheme: 'ˈlets' },
        { word: 'about', grapheme: 'ə-ˈbau̇t' },
        { word: 'talk', grapheme: 'ˈtȯk' },
        { word: 'what', grapheme: 'ˈ(h)wät' },
    ],
    d: [
        { word: 'do', grapheme: 'ˈdü' },
        { word: 'and', grapheme: 'ən(d)' },
        { word: 'said', grapheme: 'ˈsed' },
        { word: 'word', grapheme: 'ˈwərd' },
    ],
    r: [
        { word: 'your', grapheme: 'yər' },
        { word: 'for', grapheme: 'fər' },
        { word: 'word', grapheme: 'ˈwərd' },
        { word: 'or', grapheme: 'ər' },
    ],
    n: [
        { word: 'and', grapheme: 'ən(d)' },
        { word: 'one', grapheme: 'ˈwən' },
        { word: 'an', grapheme: 'ən' },
        { word: 'when', grapheme: 'ˈ(h)wen' },
    ],
    ŋ: [
        { word: 'long', grapheme: 'ˈlȯŋ' },
        { word: 'thing', grapheme: 'ˈthiŋ' },
        { word: 'think', grapheme: 'ˈthiŋk' },
        { word: 'along', grapheme: 'ə-ˈlȯŋ' },
    ],
    t͟h: [
        { word: 'they', grapheme: 'ˈt͟hā' },
        { word: 'with', grapheme: 'ˈwit͟h' },
        { word: 'this', grapheme: 'ˈt͟his' },
        { word: 'that', grapheme: 'ˈt͟hat' },
    ],
};

const consonantGroup1ConsolidationMap = {
    l: 'l',
    t: 'l',
    d: 'l',
    r: 'l',
    n: 'l',
    ŋ: 'l',
    t͟h: 'l',
};

//  __   __        __   __                 ___
// /  ` /  \ |\ | /__` /  \ |\ |  /\  |\ |  |     ||
// \__, \__/ | \| .__/ \__/ | \| /~~\ | \|  |     ||
const consonantGroup2 = {
    s: [
        { word: "let's", grapheme: 'ˈlets' },
        { word: 'success', grapheme: 'sək-ˈses' },
        { word: 'success', grapheme: 'sək-ˈses' },
        { word: 'success', grapheme: 'sək-ˈses' },
    ],
    sh: [
        { word: 'she', grapheme: 'ˈshē' },
        { word: 'show', grapheme: 'ˈshō' },
        { word: 'should', grapheme: 'shəd' },
        { word: 'abortion', grapheme: 'ə-ˈbȯr-shən' },
    ],
    z: [
        { word: 'his', grapheme: '(h)iz' },
        { word: 'as', grapheme: 'əz' },
        { word: 'use', grapheme: 'ˈyüz' },
        { word: 'has', grapheme: 'ˈhaz' },
    ],
};

const consonantGroup2ConsolidationMap = {
    s: 's',
    sh: 's',
    z: 's',
};

//  __   __        __   __                 ___
// /  ` /  \ |\ | /__` /  \ |\ |  /\  |\ |  |     |||
// \__, \__/ | \| .__/ \__/ | \| /~~\ | \|  |     |||
const consonantGroup3 = {
    k: [
        { word: 'success', grapheme: 'sək-ˈses' },
        { word: 'talk', grapheme: 'ˈtȯk' },
        { word: 'make', grapheme: 'ˈmāk' },
        { word: 'look', grapheme: 'ˈlu̇k' },
    ],
    h: [
        { word: 'what', grapheme: 'ˈ(h)wät' },
        { word: 'have', grapheme: 'ˈhav' },
        { word: 'his', grapheme: '(h)iz' },
        { word: 'when', grapheme: 'ˈ(h)wen' },
    ],

    y: [
        { word: 'your', grapheme: 'yər' },
        { word: 'you', grapheme: 'ˈyü' },
        { word: 'use', grapheme: 'ˈyüz' },
        { word: 'year', grapheme: 'ˈyir' },
    ],

    ch: [
        { word: 'each', grapheme: 'ˈēch' },
        { word: 'which', grapheme: 'ˈ(h)wich' },
        { word: 'much', grapheme: 'ˈməch' },
        { word: 'picture', grapheme: 'ˈpik-chər' },
    ],
    g: [
        { word: 'go', grapheme: 'ˈgō' },
        { word: 'get', grapheme: 'ˈget' },
        { word: 'good', grapheme: 'ˈgu̇d' },
        { word: 'great', grapheme: 'ˈgrāt' },
    ],
    j: [
        { word: 'large', grapheme: 'ˈlärj' },
        { word: 'just', grapheme: 'ˈjəst' },
        { word: 'change', grapheme: 'ˈchānj' },
        { word: 'page', grapheme: 'ˈpāj' },
    ],
};

const consonantGroup3ConsolidationMap = {
    k: 'k',
    h: 'k',
    y: 'k',
    ch: 'k',
    g: 'k',
    j: 'k',
};

//  __   __        __   __                 ___
// /  ` /  \ |\ | /__` /  \ |\ |  /\  |\ |  |     | \  /
// \__, \__/ | \| .__/ \__/ | \| /~~\ | \|  |     |  \/
// consonant4
const consonantGroup4 = {
    w: [
        { word: 'what', grapheme: 'ˈ(h)wät' },
        { word: 'we', grapheme: 'ˈwē' },
        { word: 'word', grapheme: 'ˈwərd' },
        { word: 'one', grapheme: 'ˈwən' },
    ],
    v: [
        { word: 'have', grapheme: 'ˈhav' },
        { word: 'of', grapheme: 'əәv' },
        { word: 'over', grapheme: 'ˈō-vər' },
        { word: 'live', grapheme: 'ˈliv' },
    ],
    f: [
        { word: 'for', grapheme: 'fər' },
        { word: 'from', grapheme: 'ˈfrəm' },
        { word: 'if', grapheme: 'ˈif' },
        { word: 'find', grapheme: 'ˈfīnd' },
    ],
};
const consonantGroup4ConsolidationMap = {
    w: 'v',
    v: 'v',
    f: 'v',
};

//  __   __        __   __                 ___
// /  ` /  \ |\ | /__` /  \ |\ |  /\  |\ |  |     \  /
// \__, \__/ | \| .__/ \__/ | \| /~~\ | \|  |      \/
// consonant5

const consonantGroup5 = {
    b: [
        { word: 'about', grapheme: 'ə-ˈbau̇t' },
        { word: 'but', grapheme: 'ˈbət' },
        { word: 'be', grapheme: 'ˈbē' },
        { word: 'number', grapheme: 'ˈnəm-bər' },
    ],
    p: [
        { word: 'people', grapheme: 'ˈpē-pəl' },
        { word: 'people', grapheme: 'ˈpē-pəl' },
        { word: 'up', grapheme: 'ˈəp' },
        { word: 'part', grapheme: 'ˈpärt' },
    ],
    m: [
        { word: 'make', grapheme: 'ˈmāk' },
        { word: 'from', grapheme: 'ˈfrəm' },
        { word: 'him', grapheme: 'im' },
        { word: 'made', grapheme: 'ˈmād' },
    ],
};
const consonantGroup5ConsolidationMap = {
    b: 'b',
    p: 'b',
    m: 'b',
};

//  __   __        __   __                 ___  __
// /  ` /  \ |\ | /__` /  \ |\ |  /\  |\ |  |  /__`
// \__, \__/ | \| .__/ \__/ | \| /~~\ | \|  |  .__/
// consonants
export const consonants = {
    ...consonantGroup1,
    ...consonantGroup2,
    ...consonantGroup3,
    ...consonantGroup4,
    ...consonantGroup5,
};

export const all = {
    ...vowels,
    ...consonants,
};
export const graphemeConsolidationMap = {
    ...vowelGroup1ConsolidationMap,
    ...vowelGroup2ConsolidationMap,
    ...consonantGroup1ConsolidationMap,
    ...consonantGroup2ConsolidationMap,
    ...consonantGroup3ConsolidationMap,
    ...consonantGroup4ConsolidationMap,
    ...consonantGroup5ConsolidationMap,
};

export default {
    all,
    consonants,
    consonantGroup1,
    consonantGroup2,
    consonantGroup3,
    consonantGroup4,
    consonantGroup5,
    vowels,
    vowelGroup1,
    vowelGroup2,
    graphemeConsolidationMap,
};
