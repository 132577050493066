import uniqid from 'uniqid';

export default function makeMaskPoint(receivedPointData) {
    const baseData = receivedPointData || { main: { x: 0, y: 0 } };

    const maskPoint = {
        id: uniqid('maskToolPoint-'),
        isTangentsLinked: true,
        ...baseData,
    };

    return maskPoint;
}
