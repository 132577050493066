import { cloneDeep } from 'lodash';

import { defaultTransform } from 'src/pixi/constants';

import buildPosition from './buildPosition';
import buildScale from './buildScale';
import buildRotation from './buildRotation';

export default function buildTransformFromRelativeTransform({
    relativeTransform,
    displayObject,
    refDisplayObject,
}) {
    if (!relativeTransform) {
        throw new Error('cannot build transform without relative transform');
    }
    const transform = cloneDeep(defaultTransform);

    transform.position = buildPosition({
        refDisplayObject,
        displayObject,
        positionDiffToSizeRatio: relativeTransform.positionDiffToSizeRatio,
    });

    transform.pivot = relativeTransform.pivot;

    transform.scale = buildScale({
        sizeRatio: relativeTransform.sizeRatio,
        displayObject,
        refDisplayObject,
    });

    transform.rotation = buildRotation({
        refDisplayObject,
        displayObject,
        rotationDiff: relativeTransform.rotationDiff,
    });

    return transform;
}
