import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectableListItem from 'src/components/shared/SelectableListItem';
import AudioWaveform from 'src/components/shared/AudioWaveform';
import { audioTrackLike } from 'src/components/views/AudioSelector/audioSelectorSlice/propTypes';
import useAudio from 'src/components/shared/hooks/useAudio';
import Spinner from 'src/components/shared/Spinner';
import pixiStore from 'src/pixi/pixiStore';

import PlaybackButton from './PlaybackButton';

import styles from './styles.module.scss';

export default function AudioListItem(props) {
    const {
        className,
        audioTrack,
        isSelected,
        isPlaying,
        onPlayButtonClick,
        onAudioTrackClick,
        ...attributes
    } = props;

    const { id: audioTrackId, label, foTranscriptWords } = audioTrack;
    const { waveformData, loadAudio, isLoading } = useAudio({ id: audioTrackId });

    useEffect(function _handleInitialLoadIfSelected() {
        if (isSelected) {
            loadAudio();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectableListItemClick = useCallback(
        async function _handleAudioTrackClick() {
            onAudioTrackClick(audioTrackId);
            await loadAudio();
        },
        [audioTrackId, onAudioTrackClick, loadAudio],
    );

    const handlePlayButtonClick = useCallback(
        async function _handlePlayButtonClick(event) {
            event.stopPropagation();
            await handleSelectableListItemClick();
            const audioController = pixiStore.audioManager.getAudioControllerById(audioTrackId);
            if (audioController.isPlaying) {
                audioController.pause();
            } else {
                audioController.play();
            }
            onPlayButtonClick(audioTrackId);
        },
        [audioTrackId, onPlayButtonClick, handleSelectableListItemClick],
    );

    return (
        <div className={classnames(styles.AudioListItem, className)} {...attributes}>
            <div className={styles.AudioListItem__label}>
                <span>{label}</span>
                {foTranscriptWords && <span> transcript</span>}
            </div>

            <SelectableListItem onClick={handleSelectableListItemClick} isSelected={isSelected}>
                <PlaybackButton
                    isPlaying={isPlaying}
                    handlePlayButtonClick={handlePlayButtonClick}
                    isDisabled={isLoading}
                />
                {isLoading ? (
                    <Spinner className={styles.AudioListItem__spinner} />
                ) : (
                    <AudioWaveform waveformData={waveformData} paddingPercent={0.1} />
                )}
            </SelectableListItem>
        </div>
    );
}

AudioListItem.propTypes = {
    className: PropTypes.string,
    audioTrack: audioTrackLike.isRequired,
    onAudioTrackClick: PropTypes.func.isRequired,
    onPlayButtonClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
};

AudioListItem.defaultProps = {
    className: '',
};
