import loadSpineAsset from 'src/spine/utils/loadSpineAsset';
import createSpineAssetInstance from 'src/spine/utils/createSpineAssetInstance';
import applyTextureModificationsToFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/applyTextureModificationsToFoSpineAsset';

export default async function loadFoSpineAsset({
    app,
    isSkipTextureModifications,
    foSpineAsset,
    foTextureComps,
}) {
    const { id, name, url, preLoadedSkeletonData } = foSpineAsset;
    const { loader } = app;

    // todo - we probably want to use url for skeletonName, not name or id
    const { resources } = await loadSpineAsset({
        skeletonName: name,
        skeletonPath: url,
        loader,
        preLoadedSkeletonData,
    });

    const spineAssetInstance = createSpineAssetInstance({
        resources,
        name,
        id,
    });

    const { skins } = spineAssetInstance.skeleton.data;
    if (skins.length > 1) {
        const firstNonDefaultSkinName = skins[1].name;
        spineAssetInstance.skeleton.setSkinByName(firstNonDefaultSkinName);
        spineAssetInstance.update(0);
    }

    if (!isSkipTextureModifications) {
        app.stage.addChild(spineAssetInstance);
        const startingPosition = spineAssetInstance.position;
        spineAssetInstance.position.set(-10000, -10000);
        app.ticker.update();
        await applyTextureModificationsToFoSpineAsset({
            app,
            spineAssetInstance,
            foTextureComps,
            foSpineAsset,
        });
        spineAssetInstance.position.set(startingPosition.x, startingPosition.y);
        app.stage.removeChild(spineAssetInstance);
    }

    return spineAssetInstance;
}
