import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';

export default function useHandleDeleteButtonClick({
    layers,
    trackId,
    trackLayerId,
    setTrackLayers,
}) {
    const { sceneId: selectedSceneId } = useParams();

    return useCallback(
        function _handleDeleteButtonClick() {
            const newLayersById = { ...layers.byId };
            delete newLayersById[trackLayerId];
            const newAllLayerIds = layers.allIds.filter((currentTrackLayerId) => {
                return currentTrackLayerId !== trackLayerId;
            });

            setTrackLayers({
                sceneId: selectedSceneId,
                trackId,
                trackLayerId,
                layersById: newLayersById,
                allLayerIds: newAllLayerIds,
            });
            updateTracksOnBackend({ selectedSceneId });
        },
        [trackId, trackLayerId, setTrackLayers, layers, selectedSceneId],
    );
}
