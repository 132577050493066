import pixiStore from 'src/pixi/pixiStore';
import getChildById from 'src/pixi/utils/getChildById';

export default function getAnimationOriginalDuration({ animationId, foSpineAssetId }) {
    const app = pixiStore.pixiAppManager.getPixiApp();
    const spineAssetInstance = getChildById({
        parent: app.stage,
        id: foSpineAssetId,
    });
    const { animations } = spineAssetInstance.spineData;
    const currAnimation = animations.find((animation) => {
        return animation.name === animationId;
    });
    const originalDuration = currAnimation.duration;

    return originalDuration;
}
