import { createContext } from 'react';

export default createContext({
    jumpAppToTime: (f) => f,
    handlePauseButtonClick: (f) => f,
    waveform: null,
    currentTimeManager: null,
    spineAssetInstancesByTrackId: {},
    isHoveringMapRef: null,
    isDroppedRef: null,
    lastApprovedTracksByIdRef: null,
});
