import generateTrackLayer from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayer';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import { SPEECH_LAYER_DEFAULT_MIX_DURATION } from 'src/components/views/SceneEditor/Timeline/constants/defaultValues';
import convertFoTranscriptWordsToClips from 'src/utils/businessLogic/clipUtils/convertFoTranscriptWordsToClips';

export default function handleApplyTranscriptClick(event, itemData) {
    const {
        audioTrack,
        track,
        graphemeToAnimationMap,
        timeWidth,
        selectedSceneId,
        setTrackLayer,
    } = itemData.infoForApplyingTranscript;

    const trackSpeechLayerId = track.layers.allIds.find((layerId) => {
        return track.layers.byId[layerId].speechTrackId;
    });

    const { foTranscriptWords } = audioTrack;
    setTimeout(function _updateClipsAfterMenuHasClosed() {
        const foSpineAssetId = track.foSpineAsset.id;
        const clipsById = convertFoTranscriptWordsToClips({
            foTranscriptWords,
            timeWidth,
            foSpineAssetId,
            graphemeToAnimationMap,
        });

        const trackLayer = generateTrackLayer({
            trackLayerId: trackSpeechLayerId,
            clipsById,
            speechTrackId: audioTrack.id,
            mixDuration: SPEECH_LAYER_DEFAULT_MIX_DURATION,
        });

        setTrackLayer({
            sceneId: selectedSceneId,
            trackId: track.trackId,
            trackLayer,
        });
        updateTracksOnBackend({
            selectedSceneId,
        });
    }, 10);
}
