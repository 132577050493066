import { useEffect, useState } from 'react';

import buildDistanceBetweenTargetAndContent from './buildDistanceBetweenTargetAndContent';

export default function useStyle({
    targetElemRef,
    contentElemRef,
    percentOnTargetElem,
    percentOnContentElem,
}) {
    const [contentStyle, setContentStyle] = useState({ left: -10000, top: -10000 });
    useEffect(
        function _updateMenuStyle() {
            const targetElem = targetElemRef.current;
            const targetElemRect = targetElem.getBoundingClientRect();

            const contentElem = contentElemRef.current;
            const contentElemRect = contentElem.getBoundingClientRect();

            const distance = buildDistanceBetweenTargetAndContent({
                percentOnContentElem,
                percentOnTargetElem,
            });
            const left =
                targetElemRect.x +
                targetElemRect.width * percentOnTargetElem.x -
                contentElemRect.width * percentOnContentElem.x +
                distance.x;
            const top =
                targetElemRect.y +
                targetElemRect.height * percentOnTargetElem.y -
                contentElemRect.height * percentOnContentElem.y +
                distance.y;

            setContentStyle({ left, top });
        },
        [targetElemRef, contentElemRef, percentOnTargetElem, percentOnContentElem],
    );

    return contentStyle;
}
