import { sortBy } from 'lodash';

import generateFoTranscriptWord from 'src/utils/businessLogic/transcript/generateFoTranscriptWord';

import flattenClipsToOneGroupDeep from './flattenClipsToOneGroupDeep';
import removeUngraphemeClips from './removeUngraphemeClips';
import buildFoGraphemeCharsFromSortedClips from './buildFoGraphemeCharsFromSortedClips';
import groupSingleClips from './groupSingleClips';

export default function convertClipsToFoTranscriptWords({ clipsById, timeWidth }) {
    const filteredClipsById = removeUngraphemeClips(clipsById);
    const flattenedClips = flattenClipsToOneGroupDeep({ clipsById: filteredClipsById, timeWidth });
    const clipGroups = groupSingleClips({ flattenedClips, timeWidth });

    const foTranscriptWords = [];
    for (let i = 0; i < clipGroups.length; i++) {
        const clip = clipGroups[i];

        const sortedChildClips = sortBy(clip.data.clipsById, 'position');

        const startTimeOffset = -sortedChildClips[0].position / timeWidth;
        const foGraphemeChars = buildFoGraphemeCharsFromSortedClips({
            sortedClips: sortedChildClips,
            timeWidth,
            startTimeOffset,
        });

        const startTime = clip.position / timeWidth - startTimeOffset;
        const foTranscriptWord = generateFoTranscriptWord({
            startTime,
            foGraphemeChars,
            word: clip.data.title,
        });

        foTranscriptWords.push(foTranscriptWord);
    }

    return foTranscriptWords;
}
