import calcZoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcZoomedValue';
import getSingleClipDuration from 'src/utils/businessLogic/clipUtils/getSingleClipDuration';

/**
 *@clipData
 *@timeWidth
 *@zoomLevel
 *@isGetUntrimmed
 */
export default function getSingleClipWidth({
    clipData,
    timeWidth,
    zoomLevel = 1,
    isGetUntrimmed = false,
}) {
    const clipDuration = getSingleClipDuration({ clipData, isGetUntrimmed });
    const clipWidth = clipDuration * timeWidth;
    const zoomedClipWidth = calcZoomedValue({ value: clipWidth, zoomLevel });

    return zoomedClipWidth;
}
