import { degToRad } from 'src/utils/mathUtils/geoMathUtils';
import movePivotToGlobalPositionWithoutMovingParent from 'src/pixi/utils/pivotUtils/movePivotToGlobalPositionWithoutMovingParent';

export default function positionFoTextureCompForRender({
    foTextureCompDisplayObject: displayObject,
    bone,
    slotContainer,
}) {
    const spineDisplayObject = slotContainer.children[0];
    const { attachment } = spineDisplayObject;

    const originalPivotGlobal = displayObject.toGlobal(displayObject.pivot);
    let correctionAngle = 0;
    const isMesh = attachment instanceof PIXI.spine.core.MeshAttachment;
    if (!isMesh) {
        const attachmentAngle = degToRad(attachment.rotation);
        const boneAngle = degToRad(bone.getWorldRotationX());
        correctionAngle = attachmentAngle - boneAngle;

        const desiredGlobalPivot = spineDisplayObject.toGlobal(spineDisplayObject.pivot);

        movePivotToGlobalPositionWithoutMovingParent({
            displayObject,
            position: desiredGlobalPivot,
        });
        spineDisplayObject.rotation -= correctionAngle;
        displayObject.rotation += correctionAngle;
    }

    return {
        correctionAngle,
        originalPivotGlobal,
    };
}
