import { useRef } from 'react';

import convertClipToExpressionTimelineKey from 'src/components/views/SceneEditor/Timeline/utils/convertClipToExpressionTimelineKey';

export default function useInitialValues({ selectedClipId, clipsById, timeWidth }) {
    const initialValuesRef = useRef({});
    const isFirstRenderRef = useRef(true);

    if (selectedClipId && isFirstRenderRef.current) {
        const selectedClip = clipsById[selectedClipId];
        const existingExpressionTimelineKey = convertClipToExpressionTimelineKey({
            clip: selectedClip,
            timeWidth,
        });
        const initialInfluences = existingExpressionTimelineKey.influences;
        const initialStartTime = existingExpressionTimelineKey.start;
        const initialDuration = existingExpressionTimelineKey.end - initialStartTime;

        initialValuesRef.current = { initialInfluences, initialStartTime, initialDuration };
    }

    isFirstRenderRef.current = false;

    return initialValuesRef.current;
}
