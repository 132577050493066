import downloadFile from 'src/utils/browser/downloadFile';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function downloadVideoFile({
    videoUrl,
    outputName,
    format,
    setVideoDownloadPercent,
}) {
    const response = await fetchWithAuth(videoUrl);

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
            `there was a problem with retrieving converted video: ${errorData.errors[0].message}`,
        );
    }

    const contentLength = response.headers.get('Content-Length');
    const chunks = [];
    let downloadedBytes = 0;

    const reader = response.body.getReader();
    let { done, value } = await reader.read();

    while (!done) {
        downloadedBytes += value.length;
        const percent = (downloadedBytes / contentLength) * 100;
        setVideoDownloadPercent(percent);
        chunks.push(value);

        // eslint-disable-next-line no-await-in-loop
        ({ done, value } = await reader.read());
    }

    const blob = new Blob(chunks);
    const filename = `${outputName}.${format}`;
    downloadFile({ blob, filename });
}
