import produce from 'immer';

export default function calcDeleteAnimationClipSelection({ clipSelectionByTrackId, tracksById }) {
    let newTracksById = tracksById;

    const trackIdsWithSelection = Object.keys(clipSelectionByTrackId);

    trackIdsWithSelection.forEach((trackId) => {
        const clipSelectionOnTrackByLayerId = clipSelectionByTrackId[trackId];
        const layerIdsWithSelection = Object.keys(clipSelectionOnTrackByLayerId);
        layerIdsWithSelection.forEach((trackLayerId) => {
            const selectedClipIds = clipSelectionOnTrackByLayerId[trackLayerId];

            selectedClipIds.forEach((selectedClipId) => {
                newTracksById = produce(newTracksById, (draftNewTracksById) => {
                    delete draftNewTracksById[trackId].layers.byId[trackLayerId].clipsById[
                        selectedClipId
                    ];
                });
            });
        });
    });

    return newTracksById;
}
