function removeClipById(clips, clipId) {
    const filteredClips = clips.filter((currentClipId) => {
        return currentClipId !== clipId;
    });

    return filteredClips;
}

function calcClipSelectionForSingleLayer({ currentClips, newClips }) {
    let calculatedClips = [...currentClips];

    newClips.forEach((clipId) => {
        const isAlreadySelected = currentClips.includes(clipId);

        if (isAlreadySelected) {
            calculatedClips = removeClipById(calculatedClips, clipId);
        } else {
            calculatedClips.push(clipId);
        }
    });

    return calculatedClips;
}

export default function calcClipSelectionForLayers({
    newClipSelectionOnTrackByLayerId,
    currentClipSelectionOnTrackByLayerId,
}) {
    const layerIds = Object.keys({
        ...currentClipSelectionOnTrackByLayerId,
        ...newClipSelectionOnTrackByLayerId,
    });

    const calculatedClipSelectionOnTrackByLayerId = layerIds.reduce(
        function _calcCurrentLayerSelectionsMerger(
            calculatedClipSelectionOnTrackByLayerId,
            currentLayerId,
        ) {
            const currentClips = currentClipSelectionOnTrackByLayerId[currentLayerId] || [];
            const newClips = newClipSelectionOnTrackByLayerId[currentLayerId] || [];

            const calculatedClips = calcClipSelectionForSingleLayer({
                currentClips,
                newClips,
            });
            const newCalculatedClipSelectionOnTrackByLayerIds = {
                ...calculatedClipSelectionOnTrackByLayerId,
                [currentLayerId]: calculatedClips,
            };

            if (!calculatedClips.length) {
                delete newCalculatedClipSelectionOnTrackByLayerIds[currentLayerId];
            }

            return newCalculatedClipSelectionOnTrackByLayerIds;
        },
        {},
    );

    return calculatedClipSelectionOnTrackByLayerId;
}
