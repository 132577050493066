import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import DownloadIcon from 'src/components/shared/iconFactory/DownloadIcon';
import Modal from 'src/components/shared/Modal';

import VideoDownloadForm from './VideoDownloadForm';

import styles from './styles.module.scss';

export default function VideoDownloadButton(props) {
    const { className, selectedAudioTrackId } = props;

    const [isModalShown, setIsModalShown] = useState(false);
    const handleVideoDownloadButtonClick = useCallback(
        function _handleVideoDownloadButtonClick() {
            setIsModalShown(true);
        },
        [setIsModalShown],
    );
    const handleClickOutsideModal = useCallback(
        function _handleClickOutsideModal() {
            setIsModalShown(false);
        },
        [setIsModalShown],
    );

    return (
        <>
            <ToolButton
                className={classnames(styles.VideoDownloadButton, className)}
                onClick={handleVideoDownloadButtonClick}
                title='Download as video'
            >
                <DownloadIcon />
            </ToolButton>
            {isModalShown && (
                <Modal onClickOutside={handleClickOutsideModal} isLayoverVisible>
                    <VideoDownloadForm selectedAudioTrackId={selectedAudioTrackId} />
                </Modal>
            )}
        </>
    );
}

VideoDownloadButton.propTypes = {
    className: PropTypes.string,
    selectedAudioTrackId: PropTypes.string.isRequired,
};

VideoDownloadButton.defaultProps = {
    className: '',
};
