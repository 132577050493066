import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';

import styles from './styles.module.scss';

export default function SnapButton(props) {
    const { className, ...attributes } = props;

    return (
        <ToolButton
            className={classnames(styles.SnapButton, className)}
            title='Makes the clip snap onto a grid'
            {...attributes}
        >
            Snap
        </ToolButton>
    );
}

SnapButton.propTypes = {
    ...ToolButton.propTypes,
    className: PropTypes.string,
};

SnapButton.defaultProps = {
    className: '',
};
