import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import generateClipGroupFromClips from 'src/utils/businessLogic/clipUtils/generateClipGroupFromClips';

export default function useGroupSelectedClips({
    selectedSequentialClips,
    selectedTrackLayerClipsById,
    selectedTrackId,
    selectedLayerId,
    setClipsById,
    setClipSelection,
    groupName,
    clipGroupId,
}) {
    const { sceneId: selectedSceneId } = useParams();

    return useCallback(
        function _groupSelectedClips() {
            const newClipGroup = generateClipGroupFromClips({
                clipId: clipGroupId,
                clips: selectedSequentialClips,
                isSequentialClips: true,
                data: { title: groupName },
            });

            const newClipsById = {
                ...selectedTrackLayerClipsById,
                [newClipGroup.clipId]: newClipGroup,
            };
            selectedSequentialClips.forEach(function _removeSelectedClipsFromNewClipsById(clip) {
                delete newClipsById[clip.clipId];
            });

            setClipsById({
                sceneId: selectedSceneId,
                trackId: selectedTrackId,
                trackLayerId: selectedLayerId,
                newClipsById,
            });
            updateTracksOnBackend({ selectedSceneId });
            setClipSelection({ [selectedTrackId]: { [selectedLayerId]: [newClipGroup.clipId] } });
        },
        [
            selectedTrackLayerClipsById,
            selectedSequentialClips,
            selectedTrackId,
            selectedLayerId,
            setClipsById,
            setClipSelection,
            groupName,
            clipGroupId,
            selectedSceneId,
        ],
    );
}
