import buildRelativeTransform from 'src/pixi/utils/buildRelativeTransform';
import getSlotContainerBySlotName from 'src/utils/businessLogic/foSpineAsset/getSlotContainerBySlotName';
import getChildById from 'src/pixi/utils/getChildById';

export default function buildTextureModificationAtomFromStagedAssets({
    app,
    foTextureCompId,
    foSpineAssetId,
    slotName,
}) {
    const spineAssetInstance = getChildById({
        parent: app.stage,
        id: foSpineAssetId,
    });
    const foTextureCompDisplayObject = getChildById({
        parent: app.stage,
        id: foTextureCompId,
    });
    const slotContainer = getSlotContainerBySlotName({
        spineAssetInstance,
        slotName,
    });

    const relativeTransform = buildRelativeTransform({
        displayObject: foTextureCompDisplayObject,
        refDisplayObject: slotContainer,
    });

    const textureModificationAtom = {
        slotName,
        foTextureCompId,
        relativeTransform,
    };

    return textureModificationAtom;
}
