import PixiAppManager from './PixiAppManager';
import AudioManager from './AudioManager';

class PixiStore {
    pixiAppManager = new PixiAppManager();

    // todo - consider implementing the following stores for tracking pixi assets.
    // probably track most/all byId
    // * renderedTexturesManager - generated by renderFoTextureComp
    // * foTextureDisplayObjectsManager
    // * foTextureCompDisplayObjectsManager
    // * foSpineAssetInstancesManager

    audioManager = new AudioManager();
}

const pixiStore = new PixiStore();

if (process.env.NODE_ENV === 'development') {
    window.pixiStore = pixiStore;
    window.PIXI = PIXI;
}
export default pixiStore;
