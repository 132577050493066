import makeKnobGrid from 'src/pixi/tools/TransformTool/makeKnobGrid';

import makeResizeKnob from './makeResizeKnob';

export default function makeResizeKnobs(transformTool) {
    const resizeKnobs = makeKnobGrid({
        gridSize: { x: 3, y: 3 },
        makeKnob: makeResizeKnob,
        transformTool,
    });

    Object.values(resizeKnobs).forEach((resizeKnob) => {
        transformTool.rotateLayer.addChild(resizeKnob);
    });

    transformTool.resizeKnobs = resizeKnobs;
}
