import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import SelectableList from 'src/components/shared/SelectableList';
import { setFoSpineAsset } from 'src/components/views/AssetSelector/assetSelectorSlice';
import { foSpineAssetsLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { setFoSpineAssetOnTrack } from 'src/components/views/SceneSelector/sceneSelectorSlice';

import FoSpineAssetListItem from './FoSpineAssetListItem';

import styles from './styles.module.scss';

function FoSpineAssetList(props) {
    const {
        className,
        foSpineAssets,
        setFoSpineAssetOnTrack,
        selectedFoSpineAssetId,
        setFoSpineAsset,
        ...attributes
    } = props;

    return (
        <SelectableList className={classnames(styles.FoSpineAssetList, className)} {...attributes}>
            {foSpineAssets.allIds.map((foSpineAssetId) => {
                const foSpineAsset = foSpineAssets.byId[foSpineAssetId];

                return (
                    <FoSpineAssetListItem
                        key={foSpineAsset.id}
                        foSpineAsset={foSpineAsset}
                        selectedFoSpineAssetId={selectedFoSpineAssetId}
                        setFoSpineAssetOnTrack={setFoSpineAssetOnTrack}
                        setFoSpineAsset={setFoSpineAsset}
                    />
                );
            })}
        </SelectableList>
    );
}

FoSpineAssetList.propTypes = {
    className: PropTypes.string,
    foSpineAssets: foSpineAssetsLike.isRequired,
    selectedFoSpineAssetId: PropTypes.string.isRequired,
    setFoSpineAssetOnTrack: PropTypes.func.isRequired,
    setFoSpineAsset: PropTypes.func.isRequired,
};

FoSpineAssetList.defaultProps = {
    className: '',
};

function mapState(state) {
    const { assetSelector } = state;
    const { foSpineAssets } = assetSelector;

    return { foSpineAssets };
}

export default connect(mapState, {
    setFoSpineAssetOnTrack,
    setFoSpineAsset,
})(FoSpineAssetList);
