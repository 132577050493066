export default function drawPreviewFrame({ screen, graphics: frameGraphics }) {
    const x = (screen.width * 4) / 5 - 1;
    const y = 1;
    const width = screen.width / 5;
    const height = screen.height / 5;

    frameGraphics.clear();
    frameGraphics.lineStyle(2, 0xffffff, 1);
    frameGraphics.beginFill(0x00ff00, 0.02);
    frameGraphics.drawRect(x, y, width, height);
    frameGraphics.endFill();

    return frameGraphics;
}
