import { useEffect } from 'react';

import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';

import filterDeletedAnimationClipsFromSelection from './filterDeletedAnimationClipsFromSelection';
import filterDeletedExpressionClipsFromSelection from './filterDeletedExpressionClipsFromSelection';

export default function useDeselectMissingClips({
    tracks,
    clipSelectionByTrackId,
    setClipSelection,
    assetTrackMode,
}) {
    useEffect(
        function _deselectMissingClips() {
            let updatedClipSelectionByTrackId;
            if (assetTrackMode === assetTrackModes.ANIMATION) {
                updatedClipSelectionByTrackId = filterDeletedAnimationClipsFromSelection(
                    tracks,
                    clipSelectionByTrackId,
                );
            } else if (assetTrackMode === assetTrackModes.EXPRESSION) {
                filterDeletedExpressionClipsFromSelection(tracks, clipSelectionByTrackId);
            }

            if (updatedClipSelectionByTrackId) {
                setClipSelection(updatedClipSelectionByTrackId);
            }
        },
        [tracks, clipSelectionByTrackId, setClipSelection, assetTrackMode],
    );
}
