import getOriginalSize from 'src/pixi/utils/getOriginalSize';
import movePivotToGlobalPositionWithoutMovingParent from 'src/pixi/utils/pivotUtils/movePivotToGlobalPositionWithoutMovingParent';

/**
 * @displayObject container, sprite, graphics or other pixi display object
 * @pivotPercent {x: Number [0;1], y: Number [0;1]}
 */
export default function setPivotByPercentWithoutMovingParent({ displayObject, pivotPercent }) {
    const originalSize = displayObject.originalSize || getOriginalSize(displayObject);

    const localBounds = displayObject.getLocalBounds();
    const newPivot = {
        x: originalSize.width * pivotPercent.x + localBounds.x,
        y: originalSize.height * pivotPercent.y + localBounds.y,
    };

    const newPivotGlobalPosition = displayObject.toGlobal(newPivot);

    movePivotToGlobalPositionWithoutMovingParent({
        displayObject,
        position: newPivotGlobalPosition,
    });
}
