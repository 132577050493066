import { calcDistanceBetweenTwoPoints } from 'src/utils/mathUtils/geoMathUtils';
import {
    getPreviousItemInLoopedIteration,
    getNextItemInLoopedIteration,
} from 'src/utils/arrayUtils';

export default function calcDistancesFromNeighbors({ point, points }) {
    const pointIndex = points.findIndex((currentPoint) => currentPoint.id === point.id);

    const previousPoint = getPreviousItemInLoopedIteration({
        array: points,
        currentIndex: pointIndex,
    });

    const nextPoint = getNextItemInLoopedIteration({
        array: points,
        currentIndex: pointIndex,
    });

    const distanceFromPointMainToPreviousMain = calcDistanceBetweenTwoPoints({
        point1: previousPoint.main,
        point2: point.main,
    });
    const distanceFromPointMainToNextMain = calcDistanceBetweenTwoPoints({
        point1: nextPoint.main,
        point2: point.main,
    });

    return { distanceFromPointMainToPreviousMain, distanceFromPointMainToNextMain };
}
