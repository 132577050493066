import { useEffect } from 'react';

import mountPixi from 'src/pixi/utils/mountPixi';
import pixiStore from 'src/pixi/pixiStore';

export default function useSetupAssetSelectorApp({ pixiContainerElemRef }) {
    useEffect(() => {
        mountPixi(pixiContainerElemRef.current);

        return function _dismantleTexureManagerApp() {
            pixiStore.pixiAppManager.clearPixiApp();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
