import {
    // DELETE_POINT,
    // DEFAULT_MASK_TOOL_TYPE,
    DRAW_MASK,
    // MOVE_MASK_CONTROLS,
    // ADD_POINT,
    // DELETE_POINT,
    // TOGGLE_KNOB,
} from 'src/pixi/tools/MaskTool/constants/maskToolTypes';
import makeOvalPoints from 'src/pixi/tools/MaskTool/utils/makeOvalPoints';
import drawBgGhostLayer from 'src/pixi/tools/MaskTool/makeBgGhostLayer/drawBgGhostLayer';

export function handleStageDragStart(event) {
    const { maskTool } = this;
    if (maskTool && maskTool.target && maskTool.type === DRAW_MASK) {
        this.data = event.data;
        this.isDragging = true;
        this.initialPointerPosition = this.data.getLocalPosition(maskTool.target);
    } else if (event.currentTarget === event.target && maskTool.target) {
        maskTool.clear();
    }
}

export function handleStageDragMove() {
    if (this.isDragging) {
        const { maskTool, initialPointerPosition } = this;
        const points = maskTool.getPoints();

        const currentPointerPosition = this.data.getLocalPosition(maskTool.target);

        const mainCoordinates = [
            initialPointerPosition,
            {
                x: initialPointerPosition.x,
                y: currentPointerPosition.y,
            },
            currentPointerPosition,
            {
                x: currentPointerPosition.x,
                y: initialPointerPosition.y,
            },
        ];

        const ovalPoints = makeOvalPoints({ mainCoordinates, points });

        maskTool.setPoints(ovalPoints);

        maskTool.positionControls();
    }
}

export function handleStageDragEnd() {
    this.isDragging = false;
    this.data = null;
    this.initialPointerPosition = null;
}

export function handleEscDown(event) {
    if (event.key === 'Escape' && this.maskTool.target) {
        this.maskTool.clear();
    }
}

export function handleWindowResize() {
    if (this.maskTool.target) {
        const { bgGhostLayer, app } = this.maskTool;
        const { width, height } = app.screen;
        drawBgGhostLayer({ bgGhostLayer, width, height });
    }
}
