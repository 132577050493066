import PropTypes from 'prop-types';

import MODES from 'src/utils/businessLogic/clipUtils/insertClip/constants/conflictModes';
import trimModes from 'src/components/views/SceneEditor/Timeline/constants/trimModes';
import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';

export const conflictModeLike = PropTypes.oneOf([...Object.values(MODES)]);
export const trimModeLike = PropTypes.oneOf([...Object.values(trimModes)]);

export const editModesLike = PropTypes.shape({
    isSnap: PropTypes.bool.isRequired,
    conflictResolution: conflictModeLike.isRequired,
    trimMode: trimModeLike.isRequired,
});

export const clipsSelectionOnTrackLayerLike = PropTypes.arrayOf(PropTypes.string);
export const clipsSelectionOnTrackLike = PropTypes.objectOf(clipsSelectionOnTrackLayerLike);

// {
//     track01: {
//         layer01: ['clip01', 'clip02'],
//     },
// };
export const clipSelectionByTrackIdLike = PropTypes.objectOf(clipsSelectionOnTrackLike);

export const updatingTrackLayersLike = PropTypes.objectOf(PropTypes.objectOf(PropTypes.bool));

export const assetTrackModeLike = PropTypes.oneOf(Object.values(assetTrackModes));
