import traverseBreadthFirst from 'src/utils/treeUtils/traverseBreadthFirst';

export default function getChildById({ parent, id, isShallow }) {
    let foundChild;

    if (!parent) {
        console.error('cannot find a child if parent node is not specified');
        return null;
    }
    if (!id) {
        console.error('cannot find a child without id');
        return null;
    }
    function checkNode(node) {
        if (node.id === id) {
            foundChild = node;
            return node;
        }
        return null;
    }

    if (isShallow) {
        foundChild = parent.children.find((child) => {
            return child.id === id;
        });
    } else {
        traverseBreadthFirst({
            root: parent,
            callback: checkNode,
            getIsStop: checkNode,
        });
    }

    return foundChild;
}
