import React, { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import { clipSelectionByTrackIdLike } from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';
import { clipsByIdLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import {
    setClipSelection,
    deselectClips,
} from 'src/components/views/SceneEditor/Timeline/timelineSlice';
import getIsClipSelected from 'src/components/views/SceneEditor/Timeline/utils/getIsClipSelected';
import calcClipSelectionWithNewClips from 'src/components/views/SceneEditor/Timeline/utils/calcClipSelectionWithNewClips';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import calcZoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcZoomedValue';

import ClipContainerDnD from './ClipContainerDnD';

import styles from './styles.module.scss';

function TrackLayer(props) {
    const {
        className,
        clipsById,
        clipSelectionByTrackId,
        setClipSelection,
        trackId,
        trackLayerId,
        deselectClips,
        isShiftKeyDown,
        isInPreview,
        timeWidth,
        zoomLevel,
        isDisabled,
        buildClipError,
        ...attributes
    } = props;

    const handleTrackClick = useCallback(
        (event) => {
            if (event.target === event.currentTarget) {
                deselectClips();
            }
        },
        [deselectClips],
    );

    const { spineAssetInstancesByTrackId } = useContext(SceneEditorContext);

    return (
        <div
            className={classnames(
                styles.TrackLayer,
                { [styles['TrackLayer--preview']]: isInPreview },
                className,
            )}
            {...attributes}
            onClick={handleTrackClick}
            role='none'
        >
            {isDisabled && <div className={styles.TrackLayer__disabilityTint} />}

            {Object.keys(clipsById).map((clipId) => {
                const clip = clipsById[clipId];
                const isSelected = getIsClipSelected({
                    clipSelectionByTrackId,
                    clipId,
                    trackLayerId,
                    trackId,
                });
                const { position, data } = clip;
                const zoomedPosition = calcZoomedValue({ value: position, zoomLevel });
                const width = getClipWidth({ clipData: data, timeWidth, zoomLevel });

                const clipError = buildClipError({
                    clipData: data,
                    spineAssetInstancesByTrackId,
                    trackId,
                });

                return (
                    <ClipContainerDnD
                        onMouseDown={() => {
                            const newClipSelection = calcClipSelectionWithNewClips({
                                currentClipSelectionByTrackId: clipSelectionByTrackId,
                                newClipSelectionByTrackId: {
                                    [trackId]: {
                                        [trackLayerId]: [clipId],
                                    },
                                },
                                isShiftKeyDown,
                            });
                            setClipSelection(newClipSelection);
                        }}
                        left={zoomedPosition}
                        width={width}
                        data={data}
                        clipId={clipId}
                        key={clipId}
                        isSelected={isSelected}
                        isInPreview={isInPreview}
                        clipError={clipError}
                    />
                );
            })}
        </div>
    );
}

TrackLayer.propTypes = {
    className: PropTypes.string,
    clipsById: clipsByIdLike.isRequired,
    deselectClips: PropTypes.func.isRequired,
    setClipSelection: PropTypes.func.isRequired,
    clipSelectionByTrackId: clipSelectionByTrackIdLike,
    trackId: PropTypes.string.isRequired,
    trackLayerId: PropTypes.string.isRequired,
    isShiftKeyDown: PropTypes.bool.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    timeWidth: PropTypes.number.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    buildClipError: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
};

TrackLayer.defaultProps = {
    className: '',
    clipSelectionByTrackId: [],
};

function mapState(state) {
    const { app, timeline } = state;
    const { isShiftKeyDown } = app.userInteractions;
    const { clipSelectionByTrackId, timeWidth, zoomLevel } = timeline;

    return {
        clipSelectionByTrackId,
        isShiftKeyDown,
        timeWidth,
        zoomLevel,
    };
}

export default connect(mapState, { setClipSelection, deselectClips })(TrackLayer);
