import { Container } from 'pixi.js';

import positionFoTextureCompForRender from './positionFoTextureCompForRender';
import restoreFoTextureCompAfterRender from './restoreFoTextureCompAfterRender';

export default function renderFoTextureComp({
    renderer,
    foTextureCompDisplayObject,
    bone,
    slotContainer,
}) {
    const { parent } = foTextureCompDisplayObject;

    const tempParent = new Container();
    tempParent.addChild(foTextureCompDisplayObject);

    const { correctionAngle, originalPivotGlobal } = positionFoTextureCompForRender({
        foTextureCompDisplayObject,
        bone,
        slotContainer,
    });

    const frame = slotContainer.getBounds();
    const renderedTexture = renderer.generateTexture(tempParent, undefined, undefined, frame);

    restoreFoTextureCompAfterRender({
        foTextureCompDisplayObject,
        slotContainer,
        correctionAngle,
        originalPivotGlobal,
    });

    if (parent) {
        parent.addChild(foTextureCompDisplayObject);
    } else {
        tempParent.removeChild(foTextureCompDisplayObject);
    }
    tempParent.destroy();

    return renderedTexture;
}
