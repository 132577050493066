import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    byId: {},
    // allIds: [],
};

const animationsSlice = createSlice({
    name: 'animations',
    initialState,
    reducers: {
        setAnimations(state, action) {
            const { animationsById } = action.payload;

            state.byId = animationsById;
        },
    },
});

export const { setAnimations } = animationsSlice.actions;

export default animationsSlice.reducer;
