import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import useHandleDeleteFoSpineAsset from 'src/components/views/AssetSelector/FoSpineAssetList/FoSpineAssetListItem/Buttons/DeleteButton/hooks/useHandleDeleteFoSpineAsset';
import { removeFoSpineAssetFromStore } from 'src/components/views/AssetSelector/assetSelectorSlice';
import { setFoSpineAssetOnTrack } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import { fallbackHistoryLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import getLatestFallbackFoSpineAsset from 'src/reduxStore/utils/getLatestFallbackFoSpineAsset';

import styles from './styles.module.scss';

function DeleteButton(props) {
    const {
        className,
        foSpineAssetId,
        fallbackFoSpineAssetId,
        fallbackFoSpineAssetHistory,
        removeFoSpineAssetFromStore,
        setFoSpineAssetOnTrack,
        ...attributes
    } = props;

    const handleDeleteFoSpineAsset = useHandleDeleteFoSpineAsset({
        foSpineAssetId,
        removeFoSpineAssetFromStore,
        setFoSpineAssetOnTrack,
        fallbackFoSpineAssetId,
        fallbackFoSpineAssetHistory,
    });

    return (
        <ToolButton
            className={classnames(styles.DeleteButton, className)}
            onClick={handleDeleteFoSpineAsset}
            {...attributes}
        >
            delete
        </ToolButton>
    );
}

DeleteButton.propTypes = {
    className: PropTypes.string,
    foSpineAssetId: PropTypes.string.isRequired,
    fallbackFoSpineAssetId: PropTypes.string.isRequired,
    fallbackFoSpineAssetHistory: fallbackHistoryLike.isRequired,
    removeFoSpineAssetFromStore: PropTypes.func.isRequired,
    setFoSpineAssetOnTrack: PropTypes.func.isRequired,
};

DeleteButton.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { foSpineAssetId } = ownProps;
    const { assetSelector } = state;
    const foSpineAsset = assetSelector.foSpineAssets.byId[foSpineAssetId];
    const fallbackFoSpineAsset = getLatestFallbackFoSpineAsset({
        fallbackHistory: foSpineAsset.fallbackHistory,
        assetSelector,
    });

    return {
        fallbackFoSpineAssetId: fallbackFoSpineAsset.id,
        fallbackFoSpineAssetHistory: fallbackFoSpineAsset.fallbackHistory,
    };
}

export default connect(mapState, {
    removeFoSpineAssetFromStore,
    setFoSpineAssetOnTrack,
})(DeleteButton);
