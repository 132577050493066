import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import ChevronRightIcon from 'src/components/shared/iconFactory/ChevronDownIcon';
import ChevronDownIcon from 'src/components/shared/iconFactory/ChevronRightIcon';

export default function ToggleToolButton(props) {
    const { className, isOpen, children, ...attributes } = props;

    return (
        <ToolButton className={classnames('ToggleToolButton', className)} {...attributes}>
            {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            {children}
        </ToolButton>
    );
}

ToggleToolButton.propTypes = {
    ...ToolButton.propTypes,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
};

ToggleToolButton.defaultProps = {
    ...ToolButton.defaultProps,
    className: '',
};
