import { useCallback } from 'react';

import fitDisplayObjectIntoBounds from 'src/pixi/utils/fitDisplayObjectIntoBounds';
import makeToolTargetSelectable from 'src/components/views/TextureRouteManager/TextureEditor/utils/makeToolTargetSelectable';
import pixiStore from 'src/pixi/pixiStore';
import loadFoTexture from 'src/utils/businessLogic/foTexture/loadFoTexture';
import makeFoTexture from 'src/utils/businessLogic/foTexture/makeFoTexture';
import getChildById from 'src/pixi/utils/getChildById';
import getSlotContainerBySlotName from 'src/utils/businessLogic/foSpineAsset/getSlotContainerBySlotName';

export default function useHandleImageChange({
    foTextures,
    foTextureCompId,
    updateFoTextures,
    selectedSlotName,
    foSpineAssetId,
}) {
    return useCallback(
        function _handleImageChange(event) {
            const files = [...event.target.files];
            const file = files.find((currentFile) => {
                return currentFile.type.match(/^image\//);
            });
            event.target.value = '';
            if (!file) {
                return;
            }

            // alternative to using the loader below
            // const generatedUrl = URL.createObjectURL(file);
            // app.stage.addChild(PIXI.Sprite.from(generatedUrl));

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', async () => {
                const app = pixiStore.pixiAppManager.getPixiApp();

                // todo - once image persistance is implemented on the backend,
                // use that url instead of the one generated here.
                const url = reader.result;
                const newFoTexture = makeFoTexture({
                    name: file.name,
                    url,
                });

                const newFoTextureDisplayObject = await loadFoTexture({
                    loader: app.loader,
                    foTexture: newFoTexture,
                });

                const foTextureCompDisplayObject = getChildById({
                    parent: app.stage,
                    id: foTextureCompId,
                });

                foTextureCompDisplayObject.addChild(newFoTextureDisplayObject);

                const spineAssetInstance = getChildById({
                    parent: app.stage,
                    id: foSpineAssetId,
                });
                const slotContainer = getSlotContainerBySlotName({
                    spineAssetInstance,
                    slotName: selectedSlotName,
                });
                const bounds = slotContainer.getBounds();
                fitDisplayObjectIntoBounds({
                    displayObject: newFoTextureDisplayObject,
                    bounds,
                    paddingPercent: 0.03,
                    isShrinkOnly: true,
                });

                makeToolTargetSelectable({
                    toolTarget: newFoTextureDisplayObject,
                });

                const newFoTextures = [newFoTexture, ...foTextures];
                updateFoTextures(newFoTextures);
            });
        },
        [foTextures, updateFoTextures, foTextureCompId, selectedSlotName, foSpineAssetId],
    );
}
