export default function buildTranscriptWritingMenuConfig({
    tracks,
    audioTrack,
    graphemeToAnimationMapByFoSpineAssetId,
    handleWriteTranscriptClick,
}) {
    const transcriptWritingMenuChildrenConfig = tracks.allIds.map((trackId) => {
        const track = tracks.byId[trackId];
        const trackSpeechLayerId = track.layers.allIds.find((layerId) => {
            return track.layers.byId[layerId].speechTrackId;
        });
        const trackSpeechLayer = track.layers.byId[trackSpeechLayerId];

        const foSpineAssetId = track.foSpineAsset.id;
        const graphemeToAnimationMap = graphemeToAnimationMapByFoSpineAssetId[foSpineAssetId];
        return {
            infoForWritingTranscript: {
                trackId,
                trackSpeechLayer,
                foSpineAssetId,
                graphemeToAnimationMap,
                audioTrackId: audioTrack.id,
            },
            key: track.foSpineAsset.id,
            content: track.foSpineAsset.id,
            contentElemAttributes: {
                onClick: handleWriteTranscriptClick,
            },
        };
    });

    const transcriptWritingMenuConfig = {
        key: 'write transcript for',
        content: 'Write transcript for',
        placementStyle: { left: '100%', bottom: 0 },
        childrenData: transcriptWritingMenuChildrenConfig,
    };

    return transcriptWritingMenuConfig;
}
