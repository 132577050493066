import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';

const reduxStore = configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         // immutableCheck: false,
    //         // serializableCheck: false,
    //         immutableCheck: { ignoredPaths: ['scene.tracks.byId'] },
    //         serializableCheck: { ignoredPaths: ['scene.tracks.byId'] },
    //     }),
});

export default reduxStore;
