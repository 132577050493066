import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

export default function Input(props) {
    const { className, ...attributes } = props;

    return <input className={classnames('Input', className)} {...attributes} />;
}

Input.propTypes = {
    className: PropTypes.string,
};

Input.defaultProps = {
    className: '',
};
