import { useRef, useEffect } from 'react';

export default function usePinTimeRulerRowToTop() {
    const timeTrackerElemRef = useRef();
    const verticalScrollElemRef = useRef();
    const timelineLabelElemRef = useRef();

    useEffect(() => {
        function moveTimeTrackerElemToTheTop() {
            const verticalScrollElem = verticalScrollElemRef.current;
            const timeTrackerElem = timeTrackerElemRef.current;
            const timelineLabelElem = timelineLabelElemRef.current;

            const scrollAmount = verticalScrollElem.scrollTop;
            const transform = `translate(0px, ${scrollAmount}px)`;
            timeTrackerElem.style.transform = transform;
            timelineLabelElem.style.transform = transform;
        }

        const verticalScrollElem = verticalScrollElemRef.current;
        verticalScrollElem.addEventListener('scroll', moveTimeTrackerElemToTheTop);
        return () => {
            verticalScrollElem.removeEventListener('scroll', moveTimeTrackerElemToTheTop);
        };
    }, []);
    return { timeTrackerElemRef, verticalScrollElemRef, timelineLabelElemRef };
}
