export default function snapPositionToGrid({ snapDistance, position }) {
    if (!snapDistance) {
        return position;
    }

    const snapStart = Math.floor(position / snapDistance) * snapDistance;
    const snapEnd = Math.floor(position / snapDistance) * snapDistance + snapDistance;
    const snapMidPoint = (snapStart + snapEnd) / 2;
    const snapPosition = position < snapMidPoint ? snapStart : snapEnd;

    return snapPosition;
}
