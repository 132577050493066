import { useCallback } from 'react';

import { getAudioFilesEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default function useHandleUploadButtonClick({
    audioFile,
    setAudioFile,
    inputElemRef,
    setIsFetching,
    addAudioTrack,
    onAudioAdded,
}) {
    return useCallback(
        async function _handleAudioFileChange(event) {
            try {
                event.preventDefault();

                if (!audioFile) {
                    return;
                }

                setIsFetching(true);

                const formData = new FormData();
                formData.append('audioFile', audioFile);

                const audioFilesEndpoint = getAudioFilesEndpoint();
                const response = await fetchWithAuth(audioFilesEndpoint, {
                    method: 'POST',
                    'Content-Type': 'multipart/form-data',
                    body: formData,
                });

                if (response.ok) {
                    const newAudioTrack = await response.json();

                    addAudioTrack(newAudioTrack);
                    onAudioAdded(newAudioTrack.id);

                    inputElemRef.current.value = '';
                    setAudioFile(null);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.errors[0].message);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsFetching(false);
            }
        },
        [
            //
            audioFile,
            setAudioFile,
            inputElemRef,
            setIsFetching,
            addAudioTrack,
            onAudioAdded,
        ],
    );
}
