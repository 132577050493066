import fitDisplayObjectIntoBounds from 'src/pixi/utils/fitDisplayObjectIntoBounds';

export default function placeSpineAssetInstanceOnScreen({ spineAssetInstance, app }) {
    spineAssetInstance.drawDebug = true;
    // spineAssetInstance.drawBones = true;
    // spineAssetInstance.drawRegionAttachments = true;
    // spineAssetInstance.drawMeshHull = true;
    // spineAssetInstance.drawMeshTriangles = true;
    spineAssetInstance.stateData.defaultMix = 0;
    app.stage.addChild(spineAssetInstance);

    const screenBounds = { x: 0, y: 0, width: app.screen.width, height: app.screen.height };
    fitDisplayObjectIntoBounds({
        displayObject: spineAssetInstance,
        bounds: screenBounds,
    });
}
