import { useCallback } from 'react';

import pixiStore from 'src/pixi/pixiStore';

import {
    IS_PLAYING,
    IS_STOPPED,
    IS_PAUSED,
} from 'src/components/views/SceneEditor/constants/playbackStatuses';

export function useHandlePlayClick({
    playbackStatus,
    setPlaybackStatus,
    jumpAppToTime,
    selectedAudioTrackId,
    currentTimeManager,
}) {
    return useCallback(
        function _handlePlaybackClick() {
            const { ticker } = pixiStore.pixiAppManager.getPixiApp();

            if (playbackStatus !== IS_PLAYING) {
                setPlaybackStatus(IS_PLAYING);

                const { currentTime } = currentTimeManager;
                jumpAppToTime(currentTime, { isPreparingForPlayback: true });

                ticker.start();

                const audioController = pixiStore.audioManager.getAudioControllerById(
                    selectedAudioTrackId,
                );
                if (audioController) {
                    audioController.play();
                }
            }
        },
        [
            playbackStatus,
            setPlaybackStatus,
            jumpAppToTime,
            selectedAudioTrackId,
            currentTimeManager,
        ],
    );
}

export function useHandleStopClick({
    playbackStatus,
    setPlaybackStatus,
    jumpAppToTime,
    selectedAudioTrackId,
}) {
    return useCallback(
        function _handleStopClick() {
            const { ticker } = pixiStore.pixiAppManager.getPixiApp();
            if (playbackStatus !== IS_STOPPED) {
                ticker && ticker.stop();
                setPlaybackStatus(IS_STOPPED);

                const audioController = pixiStore.audioManager.getAudioControllerById(
                    selectedAudioTrackId,
                );
                if (audioController) {
                    audioController.stop();
                }

                jumpAppToTime(0);
            }
        },
        [playbackStatus, setPlaybackStatus, jumpAppToTime, selectedAudioTrackId],
    );
}

export function useHandlePauseClick({ setPlaybackStatus, selectedAudioTrackId }) {
    return useCallback(
        function _handlePauseClick() {
            const { ticker } = pixiStore.pixiAppManager.getPixiApp();
            setPlaybackStatus(IS_PAUSED);

            const audioController = pixiStore.audioManager.getAudioControllerById(
                selectedAudioTrackId,
            );
            if (audioController) {
                audioController.pause();
            }

            ticker && ticker.stop();
        },
        [setPlaybackStatus, selectedAudioTrackId],
    );
}
