import { useEffect, useState } from 'react';

import { getAudioTrackEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default function useInitializeSceneEditorAssets({
    selectedAudioTrack,
    selectedAudioTrackId,
    addAudioTrack,
}) {
    const [isLoadingAudioAssets, setIsLoadingAudioAssets] = useState(false);

    useEffect(function _loadMissingAudioTrack() {
        async function fetchTrack() {
            if (selectedAudioTrackId && !selectedAudioTrack) {
                setIsLoadingAudioAssets(true);
                try {
                    const audioTrackEndpoint = getAudioTrackEndpoint(selectedAudioTrackId);
                    const response = await fetchWithAuth(audioTrackEndpoint);
                    if (response.ok) {
                        const audioTrack = await response.json();
                        addAudioTrack(audioTrack);
                    } else {
                        const errorData = await response.json();
                        throw new Error(
                            `Couldn't fetch audio track ${selectedAudioTrackId}: ${errorData.errors[0].message}`,
                        );
                    }
                } catch (err) {
                    console.error(err);
                }
                setIsLoadingAudioAssets(false);
            }
        }
        fetchTrack();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLoadingAnyAssets = isLoadingAudioAssets;

    return isLoadingAnyAssets;
}
