import { useCallback } from 'react';

import pixiStore from 'src/pixi/pixiStore';
import fastForwardSpineInstance from 'src/spine/utils/fastForwardSpineInstance';

import setupTrackLayersForPlayback from './setupTrackLayersForPlayback';

export default function useJumpAppToTime({
    tracks,
    timeWidth,
    selectedAudioTrackId,
    foSpineAssets,
    spineAssetInstancesByTrackId,
    currentTimeManager,
    globalMixDuration,
}) {
    return useCallback(
        function _jumpAppToTime(time = 0, { isPreparingForPlayback = false } = {}) {
            const { ticker } = pixiStore.pixiAppManager.getPixiApp();

            tracks.allIds.forEach(function _jumpSpineAssetSeqToTimeOnTrack(trackId) {
                const track = tracks.byId[trackId];
                const foSpineAssetId = track.foSpineAsset.id;
                const foSpineAsset = foSpineAssets.byId[foSpineAssetId];

                if (!foSpineAsset) {
                    console.error(
                        `track with id ${trackId} doesn't have a corresponding foSpineAsset ${foSpineAssetId}`,
                    );
                    return;
                }
                const spineAssetInstance = spineAssetInstancesByTrackId[trackId];

                const fastForwardAmount = setupTrackLayersForPlayback({
                    track,
                    foSpineAsset,
                    spineAssetInstance,
                    time,
                    timeWidth,
                    globalMixDuration,
                    isPreparingForPlayback,
                });

                fastForwardSpineInstance({ amount: fastForwardAmount, spineAssetInstance });
            });

            Object.values(spineAssetInstancesByTrackId).forEach(
                function _resetSpineAssetInstanceLastTime(spineAssetInstance) {
                    spineAssetInstance.lastTime = Date.now();
                },
            );

            currentTimeManager.currentTime = time;
            currentTimeManager.lastTime = Date.now();
            ticker && ticker.update();

            const audioController = pixiStore.audioManager.getAudioControllerById(
                selectedAudioTrackId,
            );
            if (audioController) {
                audioController.currentTime = time;
            }
        },
        [
            tracks,
            timeWidth,
            selectedAudioTrackId,
            foSpineAssets,
            spineAssetInstancesByTrackId,
            currentTimeManager,
            globalMixDuration,
        ],
    );
}
