import { KNOB_INTERACTION_SCALE } from 'src/pixi/tools/MaskTool/constants/maskControls';
import calcPointerOffesetFromDisplayObject from 'src/pixi/utils/calcPointerOffesetFromDisplayObject';

export default function prepareTangentKnobForDragging({ tangentKnob, eventData }) {
    const { maskTool } = tangentKnob;
    tangentKnob.data = eventData;
    tangentKnob.isDragging = true;
    tangentKnob.scale.set(KNOB_INTERACTION_SCALE);

    tangentKnob.dragStartPointerOffset = calcPointerOffesetFromDisplayObject({
        displayObject: tangentKnob,
        refDisplayObject: maskTool.target,
        pointerEventData: eventData,
    });
}
