import produce from 'immer';

import getAllowedLeftShiftAmount from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getAllowedLeftShiftAmount';
import reduceOverlapByNextGap from 'src/utils/businessLogic/clipUtils/insertClip/insertShift/reduceOverlapByNextGap';

export default function shiftClipsToLeft({
    startIndex,
    clipsById,
    sortedClips,
    shiftAmount,
    isCompactShift,
    timeWidth,
}) {
    let newShiftAmount = getAllowedLeftShiftAmount({
        shiftAmount,
        sortedClips,
        startIndex,
        isCompactShift,
        timeWidth,
    });

    let newClipsById = clipsById;

    for (let i = startIndex; i >= 0 && newShiftAmount > 0; i--) {
        const currentClip = sortedClips[i];

        const currentShiftAmount = newShiftAmount;
        newClipsById = produce(newClipsById, (draftNewClipsById) => {
            draftNewClipsById[currentClip.clipId].position -= currentShiftAmount;
        });

        if (isCompactShift) {
            const previousClip = sortedClips[i - 1];
            if (previousClip) {
                newShiftAmount = reduceOverlapByNextGap({
                    currentClip: previousClip,
                    nextClip: currentClip,
                    overlapWidth: newShiftAmount,
                    timeWidth,
                });
            }
        }
    }

    return newClipsById;
}
