import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

export default function Form(props) {
    const { className, ...attributes } = props;

    return <form className={classnames('Form', className)} {...attributes} />;
}

Form.propTypes = {
    className: PropTypes.string,
};

Form.defaultProps = {
    className: '',
};
