import clipTypes from 'src/components/constants/clipTypes';

import getSingleClipWidth from './getSingleClipWidth';
import getClipGroupWidth from './getClipGroupWidth';

export default function getClipWidth({
    clipData,
    timeWidth,
    zoomLevel = 1,
    isGetUntrimmed = false,
}) {
    let clipWidth = 0;
    if (clipData.type === clipTypes.CLIP_SINGLE) {
        clipWidth = getSingleClipWidth({ clipData, timeWidth, zoomLevel, isGetUntrimmed });
    } else if (clipData.type === clipTypes.CLIP_GROUP) {
        clipWidth = getClipGroupWidth({ clipData, timeWidth, zoomLevel, isGetUntrimmed });
    } else {
        console.warn("can't find clip type in", clipData);
    }

    return clipWidth;
}
