import getTotalGapBetweenIndecies from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getTotalGapBetweenIndecies';

export default function getAllowedLeftShiftAmount({
    shiftAmount,
    sortedClips,
    startIndex,
    isCompactShift,
    timeWidth,
}) {
    const firstGapFromTrackStart = sortedClips[0].position;
    const totalAvailableGapWidth = getTotalGapBetweenIndecies({
        sortedClips,
        startIndex: -1,
        endIndex: startIndex,
        timeWidth,
    });
    const allowedLeftShiftAmount = isCompactShift
        ? Math.min(shiftAmount, totalAvailableGapWidth)
        : Math.min(shiftAmount, firstGapFromTrackStart);

    return allowedLeftShiftAmount;
}
