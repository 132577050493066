export default function logMissingAndExtraAnimationNames({
    animationsById,
    foSpineAssetId,
    expressionsByAnimation,
}) {
    const extraAnimations = Object.keys(expressionsByAnimation).filter(
        (animationName) => !animationsById[animationName],
    );
    if (extraAnimations.length) {
        console.warn(
            `These animations from expressionsByAnimation were not present on foSpineAsset ${foSpineAssetId}`,
            extraAnimations.join(' '),
        );
    }
    const missingAnimations = Object.keys(animationsById).filter(
        (animationName) => !expressionsByAnimation[animationName],
    );
    if (missingAnimations.length) {
        console.groupCollapsed(
            `These animations from foSpineAsset ${foSpineAssetId} were missing on expressionsByAnimation`,
        );
        console.log(missingAnimations.join('\n'));
        console.groupEnd();
    }
}
