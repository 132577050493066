import { useCallback, useRef } from 'react';

import convertClipsByIdToExpressionTimelineKeys from 'src/components/views/SceneEditor/Timeline/hooks/useAdaptTimelinePropsToAssetTrackMode/convertClipsByIdToExpressionTimelineKeys';

export default function useAdaptedSetTracksForExpressions({ tracks, setTracks, timeWidth }) {
    const dependencyRef = useRef({});
    dependencyRef.current = {
        timeWidth,
        setTracks,
        tracks,
    };

    const adaptedSetTracksForExpressions = useCallback(
        function _adaptedSetTracksForExpressions({ sceneId, tracksById: adaptedTracksById }) {
            const { timeWidth, setTracks, tracks } = dependencyRef.current;

            const reAdaptedTracksById = Object.keys(tracks.byId).reduce(
                function _convertAdaptedClipsByIdToExpressionTimelineKeys(
                    reAdaptedTracksById,
                    trackId,
                ) {
                    const track = tracks.byId[trackId];
                    const { layers: adaptedLayers } = adaptedTracksById[trackId];
                    const { clipsById: adaptedClipsById } = adaptedLayers.byId[
                        adaptedLayers.allIds[0]
                    ];
                    const newExpressionTimelineKeys = convertClipsByIdToExpressionTimelineKeys({
                        sceneId,
                        trackId,
                        clipsById: adaptedClipsById,
                        timeWidth,
                    });
                    const reAdaptedTrack = {
                        ...track,
                        expressionTimelineKeys: newExpressionTimelineKeys,
                    };
                    reAdaptedTracksById[trackId] = reAdaptedTrack;
                    return reAdaptedTracksById;
                },
                {},
            );

            setTracks({ sceneId, tracksById: reAdaptedTracksById });
        },
        [dependencyRef],
    );

    return adaptedSetTracksForExpressions;
}
