function getIsClipSelectedOnTrackLayer({
    clipSelectionOnTrackByLayerId = {},
    clipId,
    trackLayerId,
}) {
    const clips = clipSelectionOnTrackByLayerId[trackLayerId] || [];

    return clips.includes(clipId);
}

function getIsClipSelectedOnTrack({ clipSelectionOnTrackByLayerId = {}, clipId }) {
    const isSelectedOnTrack = Object.keys(clipSelectionOnTrackByLayerId).some((currentLayerId) => {
        const isSelectedOnLayer = getIsClipSelectedOnTrackLayer({
            clipSelectionOnTrackByLayerId,
            clipId,
            trackLayerId: currentLayerId,
        });

        return isSelectedOnLayer;
    });

    return isSelectedOnTrack;
}

export default function getIsClipSelected({
    clipSelectionByTrackId,
    clipId,
    trackLayerId,
    trackId,
}) {
    const clipSelectionOnTrackByLayerId = clipSelectionByTrackId[trackId];
    if (trackId && trackLayerId) {
        return getIsClipSelectedOnTrackLayer({
            clipSelectionOnTrackByLayerId,
            clipId,
            trackLayerId,
        });
    }

    if (trackId) {
        return getIsClipSelectedOnTrack({ clipSelectionOnTrackByLayerId, clipId });
    }

    const isSelected = Object.keys(clipSelectionByTrackId).some((currentTrackId) => {
        const clipSelectionOnTrackByLayerId = clipSelectionByTrackId[currentTrackId];

        const isSelectedOnTrack = getIsClipSelectedOnTrack({
            clipSelectionOnTrackByLayerId,
            clipId,
        });

        return isSelectedOnTrack;
    });

    return isSelected;
}
