import PropTypes from 'prop-types';

import { CLOSED_MOUTH_CHARACTER } from 'src/components/constants/audio';

import pointLike from 'src/components/shared/propTypes/pointLike';

export const relativeTransformLike = PropTypes.shape({
    positionDiffToSizeRatio: pointLike.isRequired,
    pivot: pointLike.isRequired,
    rotationDiff: PropTypes.number.isRequired,
    sizeRatio: pointLike.isRequired,
});
export const textureModificationAtomLike = PropTypes.shape({
    slotName: PropTypes.string.isRequired,
    foTextureCompId: PropTypes.string.isRequired,
    relativeTransform: relativeTransformLike.isRequired,
});
export const textureModificationLike = PropTypes.shape({
    slotName: PropTypes.string.isRequired,
    current: PropTypes.string.isRequired,
    atomsByCompId: PropTypes.objectOf(textureModificationAtomLike).isRequired,
});
export const textureModificationsLike = PropTypes.arrayOf(textureModificationLike);
export const modificationsLike = PropTypes.shape({
    textures: textureModificationsLike.isRequired,
});
export const fallbackHistoryLike = PropTypes.arrayOf(PropTypes.string);
export const foSpineAssetLike = PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    idleAnimationId: PropTypes.string,
    modifications: modificationsLike.isRequired,
    fallbackHistory: fallbackHistoryLike.isRequired,
});

export const foSpineAssetsByIdLike = PropTypes.objectOf(foSpineAssetLike).isRequired;
export const foSpineAssetsAllIdsLike = PropTypes.arrayOf(PropTypes.string).isRequired;
export const foSpineAssetsLike = PropTypes.shape({
    byId: foSpineAssetsByIdLike,
    allIds: foSpineAssetsAllIdsLike,
});

export const graphemeToAnimationMapLike = PropTypes.shape({
    a: PropTypes.string.isRequired,
    l: PropTypes.string.isRequired,
    v: PropTypes.string.isRequired,
    b: PropTypes.string.isRequired,
    ō: PropTypes.string.isRequired,
    s: PropTypes.string.isRequired,
    k: PropTypes.string.isRequired,
    [CLOSED_MOUTH_CHARACTER]: PropTypes.string.isRequired,
});
