import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Input from 'src/components/shared/inputFactory/Input';

import './styles.scss';

export default function StyledInput(props) {
    const { className, ...attributes } = props;

    return <Input className={classnames('StyledInput', className)} {...attributes} />;
}

StyledInput.propTypes = {
    className: PropTypes.string,
};

StyledInput.defaultProps = {
    className: '',
};
