import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

export default function getClipsInRange({
    sortedClips,
    rangeStart = 0,
    rangeEnd = Number.POSITIVE_INFINITY,
    isIncludeIntersections = false,
    timeWidth,
}) {
    const clipsInRange = sortedClips.filter((clip) => {
        const clipEnd = getClipEnd({ clip, timeWidth });
        let isInside = clip.position > rangeStart && clipEnd < rangeEnd;

        if (isIncludeIntersections) {
            isInside = clipEnd > rangeStart && clip.position <= rangeEnd;
        }

        return isInside;
    });

    return clipsInRange;
}
