import {
    KNOB_INTERACTION_SCALE,
    KNOB_DEFAULT_SCALE,
} from 'src/pixi/tools/MaskTool/constants/maskControls';
import {
    DELETE_POINT,
    // DEFAULT_MASK_TOOL_TYPE,
    // DRAW_MASK,
    MOVE_MASK_CONTROLS,
    ADD_POINT,
    // DELETE_POINT,
    TOGGLE_KNOB,
} from 'src/pixi/tools/MaskTool/constants/maskToolTypes';
import calcPointerOffesetFromDisplayObject from 'src/pixi/utils/calcPointerOffesetFromDisplayObject';
import togglePoint from 'src/pixi/tools/MaskTool/utils/togglePoint';
import * as eventNames from 'src/pixi/tools/MaskTool/constants/eventNames';

export function handleMainKnobDragStart(event) {
    const { maskTool, pointId } = this;
    const points = maskTool.getPoints();
    const point = points.find((point) => point.id === pointId);

    if (maskTool.type === MOVE_MASK_CONTROLS || maskTool.type === ADD_POINT) {
        this.data = event.data;
        this.isDragging = true;
        this.scale.set(KNOB_INTERACTION_SCALE);

        this.dragStartPointerOffset = calcPointerOffesetFromDisplayObject({
            displayObject: this,
            refDisplayObject: maskTool.target,
            pointerEventData: this.data,
        });
    } else if (maskTool.type === DELETE_POINT) {
        const { id } = point;
        const filteredPoints = points.filter((currentPoint) => currentPoint.id !== id);
        maskTool.setPoints(filteredPoints);

        maskTool.positionControls();
    } else if (maskTool.type === TOGGLE_KNOB) {
        togglePoint({ points, point });

        maskTool.positionControls();
    }
}

export function handleMainKnobDragEnd() {
    this.isDragging = false;
    this.data = null;
    this.scale.set(KNOB_DEFAULT_SCALE);
    this.dragStartPointerOffset = null;

    const { maskTool } = this;
    maskTool.runEventHandlersFor(eventNames.MAIN_KNOB_DRAG_END);
    maskTool.runEventHandlersFor(eventNames.ANY_DRAG_END);
}

export function handleMainKnobDragMove() {
    if (this.isDragging) {
        const { maskTool, pointId } = this;
        const points = maskTool.getPoints();
        const point = points.find((currentPoint) => {
            return currentPoint.id === pointId;
        });

        const currentPointerPosition = this.data.getLocalPosition(maskTool.target);
        const newMainPoint = {
            x: currentPointerPosition.x - this.dragStartPointerOffset.x,
            y: currentPointerPosition.y - this.dragStartPointerOffset.y,
        };

        const diff = {
            x: newMainPoint.x - point.main.x,
            y: newMainPoint.y - point.main.y,
        };
        point.main = newMainPoint;

        if (point.tangent1) {
            point.tangent1.x += diff.x;
            point.tangent1.y += diff.y;
        }
        if (point.tangent2) {
            point.tangent2.x += diff.x;
            point.tangent2.y += diff.y;
        }

        maskTool.positionControls();
    }
}
