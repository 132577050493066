import {
    TRANSFORM_TOOL,
    MASK_TOOL,
} from 'src/components/views/TextureRouteManager/TextureEditor/constants/toolTypes';
import { getTextureTools } from 'src/components/views/TextureRouteManager/TextureEditor/pixiAssets/textureTools';

export default function getActiveTextureTool() {
    const { transformTool, maskTool, selectedToolType } = getTextureTools();

    let activeTool;
    if (selectedToolType === TRANSFORM_TOOL) {
        activeTool = transformTool;
    } else if (selectedToolType === MASK_TOOL) {
        activeTool = maskTool;
    }

    return activeTool;
}
