import clipNumber from 'src/utils/numberUtils/clipNumber';

export default function drawWaveform({ waveformData, canvas, padding = 0 }) {
    const ctx = canvas.getContext('2d');

    const topClipValue = canvas.offsetHeight / 2 - padding;
    const multiplier = canvas.offsetHeight / 2 - padding;
    const bottomClipValue = 0;
    ctx.fillStyle = '#fff';
    const segmentWidth = canvas.offsetWidth / waveformData.length;

    for (let i = 0; i < waveformData.length; i++) {
        const x = segmentWidth * i;

        const waveformDataPoint = waveformData[i];
        const fittedValue = waveformDataPoint * multiplier;

        const clippedValue = clipNumber({
            number: fittedValue,
            topClip: topClipValue,
            bottomClip: bottomClipValue,
        });

        const height = clippedValue;
        ctx.fillRect(x, -height, 1, 2 * height);
    }
}
