import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import {
    clipLike,
    clipsByIdLike,
} from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import getIfClipIsGroup from 'src/utils/businessLogic/clipUtils/getIfClipIsGroup';
import getSelectedSequentialClips from 'src/components/views/SceneEditor/Timeline/utils/getSelectedSequentialClips';
import { setClipsById } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import { setClipSelection } from 'src/components/views/SceneEditor/Timeline/timelineSlice';
import SuperTooltip from 'src/components/shared/SuperTooltip';
import useHandleUngroupButtonClick from 'src/components/views/SceneEditor/Timeline/Toolbar/GroupClipsButton/hooks/useHandleUngroupButtonClick';
import useGroupNameDialogue from 'src/components/views/SceneEditor/Timeline/Toolbar/GroupClipsButton/hooks/useGroupNameDialogue';
import useNewGroupingActions from 'src/components/views/SceneEditor/Timeline/Toolbar/GroupClipsButton/hooks/useNewGroupingActions';
import withRouter from 'src/reduxStore/utils/withRouter';

import GroupClipsDialogue from './GroupClipsDialogue';

import styles from './styles.module.scss';

function GroupClipsButton(props) {
    const {
        className,
        selectedSequentialClips,
        dispatch,
        selectedTrackLayerClipsById,
        selectedTrackId,
        selectedLayerId,
        setClipsById,
        setClipSelection,
        timeWidth,
        ...attributes
    } = props;

    const isAnyClipsSelected = !!selectedSequentialClips.length;
    const isSingleClipSelected = selectedSequentialClips.length === 1;

    const selectedClipGroup = selectedSequentialClips[0];
    const isGroupSelected = isSingleClipSelected && getIfClipIsGroup(selectedClipGroup);

    const groupButtonRef = useRef();

    const {
        isNameDialogueVisible,
        toggleGroupNameDialogue,
        groupName,
        handleGroupNameChange,
        clipGroupId,
    } = useGroupNameDialogue();

    const handleGroupButtonClick = useCallback(
        function _handleGroupButtonClick() {
            toggleGroupNameDialogue();
        },
        [toggleGroupNameDialogue],
    );

    const handleUngroupButtonClick = useHandleUngroupButtonClick({
        selectedTrackLayerClipsById,
        selectedTrackId,
        selectedLayerId,
        setClipsById,
        selectedClipGroup,
        setClipSelection,
        timeWidth,
    });

    const { cancelNewGrouping, confirmNewGrouping } = useNewGroupingActions({
        selectedSequentialClips,
        selectedTrackLayerClipsById,
        selectedTrackId,
        selectedLayerId,
        setClipsById,
        setClipSelection,
        groupName,
        clipGroupId,
        toggleGroupNameDialogue,
    });

    let button = (
        <ToolButton
            className={classnames(styles.GroupClipsButton, className)}
            title='Ungroup selected clips'
            onClick={handleUngroupButtonClick}
            {...attributes}
        >
            ungroup clips
        </ToolButton>
    );
    if (!isGroupSelected) {
        button = (
            <>
                {isNameDialogueVisible && (
                    <SuperTooltip
                        targetElemRef={groupButtonRef}
                        onClickOutside={cancelNewGrouping}
                        percentOnTargetElem={{ x: 1, y: 0.5 }}
                        percentOnContentElem={{ x: 0, y: 1 }}
                    >
                        <GroupClipsDialogue
                            toggleGroupNameDialogue={toggleGroupNameDialogue}
                            confirmNewGrouping={confirmNewGrouping}
                            handleGroupNameChange={handleGroupNameChange}
                            groupName={groupName}
                        />
                    </SuperTooltip>
                )}
                <ToolButton
                    className={classnames(styles.GroupClipsButton, className)}
                    title='Group selected sequential clips'
                    isDisabled={!isAnyClipsSelected}
                    onClick={handleGroupButtonClick}
                    nodeRef={groupButtonRef}
                    {...attributes}
                >
                    group clips
                </ToolButton>
            </>
        );
    }

    return button;
}

GroupClipsButton.propTypes = {
    ...ToolButton.propTypes,
    className: PropTypes.string,
    selectedSequentialClips: PropTypes.arrayOf(clipLike).isRequired,
    setClipsById: PropTypes.func.isRequired,
    setClipSelection: PropTypes.func.isRequired,
    selectedTrackId: PropTypes.string.isRequired,
    selectedLayerId: PropTypes.string.isRequired,
    selectedTrackLayerClipsById: clipsByIdLike.isRequired,
    timeWidth: PropTypes.number.isRequired,
};

GroupClipsButton.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { clipSelectionByTrackId, timeWidth } = state.timeline;

    const { sceneId: selectedSceneId } = ownProps.router.params;
    const { tracks } = state.sceneSelector.scenes.byId[selectedSceneId];

    const {
        selectedSequentialClips,
        selectedTrackId,
        selectedLayerId,
    } = getSelectedSequentialClips({
        tracks,
        clipSelectionByTrackId,
    });

    const selectedTrackLayerClipsById = get(
        tracks,
        ['byId', selectedTrackId, 'layers', 'byId', selectedLayerId, 'clipsById'],
        {},
    );

    return {
        selectedSequentialClips,
        selectedTrackId,
        selectedLayerId,
        selectedTrackLayerClipsById,
        timeWidth,
    };
}

export default withRouter(connect(mapState, { setClipsById, setClipSelection })(GroupClipsButton));
