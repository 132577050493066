import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import * as routes from 'src/components/constants/routes';

import SceneEditor from 'src/components/views/SceneEditor';
import AssetSelector from 'src/components/views/AssetSelector';
import AudioSelector from 'src/components/views/AudioSelector';
import SceneSelector from 'src/components/views/SceneSelector';
import TextureRouteManager from 'src/components/views/TextureRouteManager';
import StepsNav from './StepsNav';
import RequireSceneLoad from './RequireSceneLoad';

export default function ScenesManager() {
    return (
        <Routes>
            <Route path={`:${routes.SCENE_ID}?`} element={<StepsNav />}>
                <Route index element={<SceneSelector />} />
                <Route path='*' element={<Navigate replace to='..' />} />
                <Route
                    path={`${routes.ASSETS}/*`}
                    element={
                        <RequireSceneLoad>
                            <AssetSelector />
                        </RequireSceneLoad>
                    }
                />
                <Route
                    path={`${routes.TEXTURES}/*`}
                    element={
                        <RequireSceneLoad>
                            <TextureRouteManager />
                        </RequireSceneLoad>
                    }
                />
                <Route
                    path={`${routes.AUDIO}`}
                    element={
                        <RequireSceneLoad>
                            <AudioSelector />
                        </RequireSceneLoad>
                    }
                />
                <Route
                    path={`${routes.EDIT_SCENE}/*`}
                    element={
                        <RequireSceneLoad>
                            <SceneEditor />
                        </RequireSceneLoad>
                    }
                />
            </Route>
        </Routes>
    );
}
