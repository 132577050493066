export default function buildErrorMessage(missingAnimations) {
    let errorMessage = '';
    Object.values(missingAnimations).forEach((title) => {
        if (!errorMessage) {
            errorMessage = `This track's asset is missing animation "${title}".`;
        } else {
            const errorMessageWithoutLastPanctuation = errorMessage.substr(
                0,
                errorMessage.length - 1,
            );
            errorMessage = `${errorMessageWithoutLastPanctuation}, "${title}".`;
        }
    });

    return errorMessage;
}
