import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';

import snapPositionToGrid from './snapPositionToGrid';

export default function snapClipToGrid({ snapDistance, clip, timeWidth, trimMode }) {
    if (!snapDistance) {
        return clip;
    }

    const { position } = clip;
    const clipEnd = getClipEnd({ clip, timeWidth });

    const snappedPosition = snapPositionToGrid({ position, snapDistance });
    const snappedClipEnd = snapPositionToGrid({ position: clipEnd, snapDistance });

    let snappedWidth = snappedClipEnd - snappedPosition;
    if (snappedWidth === 0) {
        snappedWidth = snapDistance;
    }

    const currentWidth = getClipWidth({ clipData: clip.data, timeWidth });
    const tailTrimAmount = snappedWidth - currentWidth;
    const newClipData = trimClipData({
        tailTrimAmount,
        clipData: clip.data,
        trimMode,
        timeWidth,
    });

    const snappedClip = {
        ...clip,
        position: snappedPosition,
        data: newClipData,
    };

    return snappedClip;
}
