import { isObject } from 'lodash';

import assertPositionIsValid from 'src/pixi/utils/assertions/assertPositionIsValid';
import assertScaleIsValid from 'src/pixi/utils/assertions/assertScaleIsValid';
import assertRotationIsValid from 'src/pixi/utils/assertions/assertRotationIsValid';
import assertPivotIsValid from 'src/pixi/utils/assertions/assertPivotIsValid';
import assertAnchorIsValid from 'src/pixi/utils/assertions/assertAnchorIsValid';

export default function assertTransformValuesAreValid(transformValues) {
    if (!isObject(transformValues)) {
        throw new Error('transformValues has to be an object, received: ', transformValues);
    }

    const { position, scale, rotation, pivot, anchor } = transformValues;

    assertPositionIsValid(position);
    assertScaleIsValid(scale);
    assertRotationIsValid(rotation);
    assertPivotIsValid(pivot);
    if (anchor) {
        assertAnchorIsValid(anchor);
    }
}
