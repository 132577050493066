import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Signup from 'src/components/views/Signup';
import Signin from 'src/components/views/Signin';
import ScenesManager from 'src/components/views/ScenesManager';
import reduxStore from 'src/reduxStore';
import { handleKeyDown, handleKeyUp } from 'src/components/App/utils/handleKeyIteractions';
import setCurrentUserIfTokenPresent from 'src/components/App/utils/setCurrentUserIfTokenPresent';
import * as routes from 'src/components/constants/routes';
import UserProfile from 'src/components/views/UserProfile';
import Home from 'src/components/views/Home';
import NavBar from 'src/components/App/NavBar';
import RequireAuth from 'src/components/App/RequireAuth';

import styles from './styles.module.scss';

setCurrentUserIfTokenPresent();

export default function App() {
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    return (
        <BrowserRouter>
            <Provider store={reduxStore}>
                <div className={styles.App}>
                    <Routes>
                        <Route path='/' element={<NavBar />}>
                            <Route index element={<Home />} />
                            <Route path={routes.SIGN_UP} element={<Signup />} />
                            <Route path={routes.SIGN_IN} element={<Signin />} />
                            <Route path={routes.USER_PROFILE} element={<UserProfile />} />
                            <Route
                                path={`${routes.SCENES}/*`}
                                element={
                                    <RequireAuth>
                                        <ScenesManager />
                                    </RequireAuth>
                                }
                            />
                        </Route>
                    </Routes>
                </div>
            </Provider>
        </BrowserRouter>
    );
}
