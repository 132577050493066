import {
    MASK_OUTLINE_COLOR,
    MASK_OUTLINE_WIDTH,
} from 'src/pixi/tools/MaskTool/constants/maskControls';

import calcPointsRelativeToMaskTool from './calcPointsRelativeToMaskTool';
import positionKnobs from './positionKnobs';
import drawBezierOutline from './drawBezierOutline';
import drawBezierOutlineInteractionArea from './drawBezierOutlineInteractionArea';
import updatePointControls from './updatePointControls';

export default function positionControls(maskTool) {
    const { maskOutline, pointControlsById } = maskTool;
    const points = maskTool.getPoints();

    const isMaskEmpty = !points.length;
    if (isMaskEmpty) {
        maskTool.disableMaskOnTarget();
    } else {
        maskTool.enableMaskOnTarget();
    }

    const pointsRelativeToMaskTool = calcPointsRelativeToMaskTool({ maskTool, points });
    drawBezierOutline({
        points: pointsRelativeToMaskTool,
        graphics: maskOutline.content,
        width: MASK_OUTLINE_WIDTH,
        color: MASK_OUTLINE_COLOR,
    });

    drawBezierOutlineInteractionArea({
        points: pointsRelativeToMaskTool,
        graphics: maskOutline.interactionArea,
        width: MASK_OUTLINE_WIDTH,
    });

    updatePointControls(maskTool);
    maskTool.setMaskControlsCursors();
    positionKnobs({ pointControlsById, points: pointsRelativeToMaskTool });
}
