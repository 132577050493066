import { Graphics } from 'pixi.js';

import {
    KNOB_SIZE,
    KNOB_OUTLINE_SIZE,
} from 'src/pixi/tools/TransformTool/constants/transformKnobs';

export default function makePivotKnobGraphics() {
    const pivotKnob = new Graphics();
    pivotKnob.lineStyle(KNOB_OUTLINE_SIZE, 0xffffff, 1);
    pivotKnob.beginFill(0x000000, 0.8);
    pivotKnob.drawCircle(0, 0, KNOB_SIZE / 2);
    pivotKnob.endFill();

    // a circle is drawn around the pivot, so it ends up being in the center of the graphics
    // => no need to center the pivot like we do for rect graphics.

    return pivotKnob;
}
