import buildClipForAnimation from 'src/utils/businessLogic/clipUtils/buildClipForAnimation';

export default function fillAreaWithRepeatingClips({
    animationId,
    clipOriginalDuration,
    duration: receivedDuration,
    timeWidth,
    startPosition,
    endPosition,
    repetitionGapRatio = 0,
    gapWidth: receivedGapWidth = 0,
    isTrimLastClipToFit = false,
}) {
    const duration = receivedDuration || clipOriginalDuration;
    const clipWidth = duration * timeWidth;
    const areaWidth = endPosition - startPosition;
    let gapWidth = receivedGapWidth;
    if (repetitionGapRatio) {
        gapWidth = areaWidth * repetitionGapRatio;
    }

    const clipsById = {};
    let currPosition = startPosition;
    while (currPosition < endPosition - clipWidth) {
        const clip = buildClipForAnimation({
            animationId,
            duration,
            originalDuration: clipOriginalDuration,
            position: currPosition,
        });

        clipsById[clip.clipId] = clip;

        currPosition += gapWidth + clipWidth;
    }

    if (isTrimLastClipToFit) {
        const remainingArea = endPosition - currPosition;
        const isClipSubjectToTrimming = remainingArea > 0 && remainingArea < clipWidth;

        if (isClipSubjectToTrimming) {
            const remainingDuration = remainingArea / timeWidth;
            const lastClip = buildClipForAnimation({
                animationId,
                duration: remainingDuration,
                originalDuration: clipOriginalDuration,
                position: currPosition,
            });
            clipsById[lastClip.clipId] = lastClip;
        }
    }

    return clipsById;
}
