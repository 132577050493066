import { useRef, useCallback } from 'react';

export default function useIsDrawing() {
    const isDrawingRef = useRef(false);

    const setIsDrawing = useCallback(() => {
        isDrawingRef.current = true;
        requestAnimationFrame(function _resetReqFrameRef() {
            isDrawingRef.current = false;
        });
    }, []);

    return { isDrawingRef, setIsDrawing };
}
