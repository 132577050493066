import convertExpressionTimelineKeyToClip from 'src/components/views/SceneEditor/Timeline/utils/convertExpressionTimelineKeyToClip';

export default function convertExpressionTimelineKeysToClipsById({
    expressionTimelineKeys,
    timeWidth,
}) {
    const clipsById = expressionTimelineKeys.reduce((clipsById, expressionTimelineKey) => {
        const clip = convertExpressionTimelineKeyToClip({ expressionTimelineKey, timeWidth });

        clipsById[clip.clipId] = clip;

        return clipsById;
    }, {});

    return clipsById;
}
