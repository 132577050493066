import drawBezierCurvesFromPoints from 'src/pixi/tools/MaskTool/utils/drawBezierCurvesFromPoints';

export default function drawBezierOutline({ graphics, points, width = 2, color = 0xffffff }) {
    graphics.clear();
    graphics.lineStyle(width, color);
    drawBezierCurvesFromPoints({
        points,
        graphics,
    });
}
