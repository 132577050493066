import produce from 'immer';

import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';
import { ERROR_THRESHOLD } from 'src/utils/businessLogic/clipUtils/insertClip/insertAggressively/constants';

export default function resolveRightConflictAggressively({
    sortedClips,
    rightNeighbourIndex,
    newClipEnd,
    clipsById,
    timeWidth,
    trimMode,
}) {
    let newClipsById = { ...clipsById };

    for (let i = rightNeighbourIndex; i < sortedClips.length; i++) {
        const rightNeighbour = sortedClips[i];
        const overlapWidth = newClipEnd - rightNeighbour.position;
        const isOverlap = overlapWidth > 0;
        const rightNeighbourWidth = getClipWidth({
            clipData: rightNeighbour.data,
            timeWidth,
        });
        const isNewClipFullyCoveringRightNeighbour =
            overlapWidth >= rightNeighbourWidth - ERROR_THRESHOLD;

        if (isNewClipFullyCoveringRightNeighbour) {
            delete newClipsById[rightNeighbour.clipId];
        } else if (isOverlap) {
            const rightNeighbourNewClipData = trimClipData({
                headTrimAmount: overlapWidth,
                clipData: rightNeighbour.data,
                trimMode,
                timeWidth,
            });
            newClipsById = produce(newClipsById, (draftNewClipsById) => {
                draftNewClipsById[rightNeighbour.clipId].position = newClipEnd;
                draftNewClipsById[rightNeighbour.clipId].data = rightNeighbourNewClipData;
            });
        } else {
            break;
        }
    }

    return newClipsById;
}
