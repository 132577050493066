import { KNOB_OUTLINE_SIZE } from 'src/pixi/tools/TransformTool/constants/transformKnobs';

export default function drawGhostLayer({ ghostLayer, width, height }) {
    if (typeof width !== 'number') {
        throw new Error(`ghostLayer.draw requires width to be a number, but ${width} was provided`);
    }
    if (typeof height !== 'number') {
        throw new Error(
            `ghostLayer.draw requires height to be a number, but ${height} was provided`,
        );
    }

    ghostLayer.clear();
    ghostLayer.lineStyle(KNOB_OUTLINE_SIZE, 0x000000, 0.8);
    ghostLayer.beginFill(0xff0000, 0.0001);
    ghostLayer.drawRect(0, 0, width, height);
    ghostLayer.endFill();
}
