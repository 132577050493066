import { useCallback, useState } from 'react';

import { getTextToTranscriptUtilEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import convertFoTranscriptWordsToClips from 'src/utils/businessLogic/clipUtils/convertFoTranscriptWordsToClips';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';
import generateTrackLayer from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayer';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import { SPEECH_LAYER_DEFAULT_MIX_DURATION } from 'src/components/views/SceneEditor/Timeline/constants/defaultValues';
import insertClips from 'src/utils/businessLogic/clipUtils/insertClips';

export default function useHandleFormSubmit({
    infoForWritingTranscriptRef,
    setTrackLayer,
    selectedSceneId,
    setIsModalShown,
    timeWidth,
    editModes,
    snapDistance,
    transcriptWritingFormStateRef,
}) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFormSubmit = useCallback(
        async function _handleFormSubmit(event) {
            event.preventDefault();
            setIsSubmitting(true);

            const {
                transcriptText,
                startTime,
                endTime,
                isEstimateEndTime,
                language,
            } = transcriptWritingFormStateRef.current;

            try {
                const textToTranscriptUtilEndpoint = getTextToTranscriptUtilEndpoint();
                const response = await fetchWithAuth(textToTranscriptUtilEndpoint, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        text: transcriptText,
                        startTime,
                        endTime: isEstimateEndTime ? undefined : endTime,
                        language,
                    }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.errors[0].message);
                }
                const foTranscriptWords = await response.json();

                const {
                    trackSpeechLayer,
                    trackId,
                    foSpineAssetId,
                    graphemeToAnimationMap,
                    audioTrackId,
                } = infoForWritingTranscriptRef.current;

                const generatedClipsById = convertFoTranscriptWordsToClips({
                    foTranscriptWords,
                    timeWidth,
                    foSpineAssetId,
                    graphemeToAnimationMap,
                });

                let trackLayer;
                if (trackSpeechLayer) {
                    const speechClipsByIdWithNewClips = insertClips({
                        insertableClipsById: generatedClipsById,
                        targetClipsById: trackSpeechLayer.clipsById,
                        editModes,
                        snapDistance,
                        timeWidth,
                    });

                    trackLayer = generateTrackLayer({
                        trackLayerId: trackSpeechLayer.trackLayerId,
                        clipsById: speechClipsByIdWithNewClips,
                        speechTrackId: audioTrackId,
                        mixDuration: SPEECH_LAYER_DEFAULT_MIX_DURATION,
                    });
                } else {
                    trackLayer = generateTrackLayer({
                        clipsById: generatedClipsById,
                        speechTrackId: audioTrackId,
                        mixDuration: SPEECH_LAYER_DEFAULT_MIX_DURATION,
                    });
                }

                setTrackLayer({
                    sceneId: selectedSceneId,
                    trackId,
                    trackLayer,
                });
                updateTracksOnBackend({
                    selectedSceneId,
                });
            } catch (err) {
                console.log(err);
            }

            setIsSubmitting(false);
            setIsModalShown(false);
        },
        [
            setTrackLayer,
            selectedSceneId,
            infoForWritingTranscriptRef,
            setIsSubmitting,
            setIsModalShown,
            timeWidth,
            editModes,
            snapDistance,
            transcriptWritingFormStateRef,
        ],
    );

    return { isSubmitting, handleFormSubmit };
}
