import generateTrackLayer from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayer';

export default function ensureHasMinOneLayer({ allLayerIds, layersById }) {
    if (!allLayerIds.length) {
        const baseLayer = generateTrackLayer();
        layersById[baseLayer.trackLayerId] = baseLayer;
        allLayerIds.push(baseLayer.trackLayerId);
    }

    return { allLayerIds, layersById };
}
