import TransformTool from 'src/pixi/tools/TransformTool';
import MaskTool from 'src/pixi/tools/MaskTool';
import TOOL_Z_INDICES from 'src/components/views/TextureRouteManager/TextureEditor/constants/toolZIndices';
import { setTextureTools } from 'src/components/views/TextureRouteManager/TextureEditor/pixiAssets/textureTools';
import * as transfromToolEventNames from 'src/pixi/tools/TransformTool/constants/eventNames';
import * as maskToolEventNames from 'src/pixi/tools/MaskTool/constants/eventNames';

export default function setupToolsInEditor({ app, handleToolApply, handleToolClear }) {
    // setup transform tool
    const transformTool = new TransformTool({
        app,
    });
    transformTool
        .addEventHandler(transfromToolEventNames.APPLY_TO, handleToolApply)
        .addEventHandler(transfromToolEventNames.CLEAR, handleToolClear);
    transformTool.zIndex = TOOL_Z_INDICES.TRANSFORM_TOOL;
    app.stage.addChild(transformTool);

    // setup mask tool
    const maskTool = new MaskTool({
        app,
    });
    maskTool
        .addEventHandler(maskToolEventNames.APPLY_TO, handleToolApply)
        .addEventHandler(maskToolEventNames.CLEAR, handleToolClear);
    maskTool.zIndex = TOOL_Z_INDICES.MASK_TOOL;
    app.stage.addChild(maskTool);

    setTextureTools({
        transformTool,
        maskTool,
    });
}
