import FoTextureDisplayObj from './FoTextureDisplayObj';

export default async function loadFoTexture({ loader, foTexture }) {
    const { id, url, name, transform, zIndex, maskData } = foTexture;

    const existingResource = Object.values(loader.resources).find((resource) => {
        return resource.url === url || resource.name === name;
    });

    let texture;
    if (existingResource) {
        texture = existingResource.texture;
    } else {
        await loader.addPr(name, url);
        texture = loader.resources[name].texture;
    }

    const foTextureDisplayObject = new FoTextureDisplayObj({
        id,
        texture,
        transform,
        maskData,
        zIndex,
    });

    return foTextureDisplayObject;
}
