import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import Input from 'src/components/shared/inputFactory/Input';
import { setZoomLevel } from 'src/components/views/SceneEditor/Timeline/timelineSlice';
import refLike from 'src/components/shared/propTypes/refLike';
import useKeepTimeIndicatorInPlaceAfterZoom from 'src/components/views/SceneEditor/Timeline/Toolbar/ZoomControl/hooks/useKeepTimeIndicatorInPlaceAfterZoom';
import useZoomButtonInteractions from 'src/components/views/SceneEditor/Timeline/Toolbar/ZoomControl/hooks/useZoomButtonInteractions';
import useHandleZoomInputChange from 'src/components/views/SceneEditor/Timeline/Toolbar/ZoomControl/hooks/useHandleZoomInputChange';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';

import styles from './styles.module.scss';

const ALLOWED_MIN_ZOOM = 0.1;

function ZoomControl(props) {
    const { className, zoomLevel, setZoomLevel, horizontalScrollElemRef, timeWidth } = props;

    const updateZoom = useCallback(
        function _updateZoom(newZoomLevel) {
            const cappedZoomLevel = Math.max(newZoomLevel, ALLOWED_MIN_ZOOM);
            setZoomLevel(cappedZoomLevel);
        },
        [setZoomLevel],
    );

    const { handleMinusButtonDown, handlePlusButtonDown } = useZoomButtonInteractions({
        updateZoom,
        zoomLevel,
    });

    const handleZoomInputChange = useHandleZoomInputChange({ updateZoom });

    useKeepTimeIndicatorInPlaceAfterZoom({
        zoomLevel,
        horizontalScrollElemRef,
        timeWidth,
    });

    const zoomLevelPercent = Math.round(zoomLevel * 100);

    return (
        <div className={classnames(styles.ZoomControl, className)}>
            <ToolButton isHoldable onMouseDown={handleMinusButtonDown}>
                -
            </ToolButton>
            <Input value={zoomLevelPercent} onChange={handleZoomInputChange} />
            <ToolButton isHoldable onMouseDown={handlePlusButtonDown}>
                +
            </ToolButton>
        </div>
    );
}

ZoomControl.propTypes = {
    zoomLevel: PropTypes.number.isRequired,
    setZoomLevel: PropTypes.func.isRequired,
    timeWidth: PropTypes.number.isRequired,
    className: PropTypes.string,
    horizontalScrollElemRef: refLike.isRequired,
};

ZoomControl.defaultProps = {
    className: '',
};

function mapState(state) {
    const { zoomLevel, timeWidth } = state.timeline;
    return { zoomLevel, timeWidth };
}

export default connect(mapState, { setZoomLevel })(ZoomControl);
