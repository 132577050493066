import { useCallback, useRef } from 'react';

import pixiStore from 'src/pixi/pixiStore';
import putTextureModificationAtomOnFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/putTextureModificationAtomOnFoSpineAsset';
import buildTextureModificationAtomFromStagedAssets from 'src/utils/businessLogic/foSpineAsset/buildTextureModificationAtomFromStagedAssets';

export default function useHandleTransformToolDragEnd({
    foSpineAsset,
    selectedSlotName,
    setFoSpineAssetTextureModifications,
    transformToolRef,
    setFoSpineAssetLinkOnFoTextureComp,
}) {
    const paramsRef = useRef();
    paramsRef.current = {
        foSpineAsset,
        selectedSlotName,
        setFoSpineAssetTextureModifications,
        setFoSpineAssetLinkOnFoTextureComp,
    };
    const handleTransformToolDragEnd = useCallback(
        function _handleTransformToolDragEnd() {
            const app = pixiStore.pixiAppManager.getPixiApp();
            const foTextureCompId = transformToolRef.current.target.id;
            const {
                foSpineAsset,
                selectedSlotName,
                setFoSpineAssetTextureModifications,
                setFoSpineAssetLinkOnFoTextureComp,
            } = paramsRef.current;

            const textureModificationAtom = buildTextureModificationAtomFromStagedAssets({
                app,
                foTextureCompId,
                foSpineAssetId: foSpineAsset.id,
                slotName: selectedSlotName,
            });

            putTextureModificationAtomOnFoSpineAsset({
                foSpineAsset,
                setFoSpineAssetTextureModifications,
                textureModificationAtom,
                setFoSpineAssetLinkOnFoTextureComp,
            });
        },
        [paramsRef, transformToolRef],
    );

    return handleTransformToolDragEnd;
}
