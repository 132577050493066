import { rotateLayerZIndices } from 'src/pixi/tools/TransformTool/constants/zIndices';

import makeResizeKnobGraphics from './makeResizeKnobGraphics';
import {
    handleResizeKnobDragStart,
    handleResizeKnobDragEnd,
    handleResizeKnobDragMove,
} from './resizeKnobDragHandlers';

export default function makeResizeKnob(transformTool) {
    const resizeKnob = makeResizeKnobGraphics();

    resizeKnob.zIndex = rotateLayerZIndices.RESIZE_KNOB;
    resizeKnob.interactive = true;
    resizeKnob.buttonMode = true;
    resizeKnob
        .on('pointerdown', handleResizeKnobDragStart)
        .on('pointermove', handleResizeKnobDragMove)
        .on('pointerup', handleResizeKnobDragEnd)
        .on('pointerupoutside', handleResizeKnobDragEnd);

    resizeKnob.transformTool = transformTool;

    return resizeKnob;
}
