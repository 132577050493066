import PropTypes from 'prop-types';

import clipTypes from 'src/components/constants/clipTypes';
import expressions from 'src/utils/businessLogic/expressions/constants/expressions';
import { fallbackHistoryLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';

const singleClipDataLike = PropTypes.shape({
    type: PropTypes.oneOf([clipTypes.CLIP_SINGLE]).isRequired,
    animationId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    originalDuration: PropTypes.number.isRequired,
    animationStart: PropTypes.number.isRequired,
    animationEnd: PropTypes.number.isRequired,
    timeScale: PropTypes.number.isRequired,
});
const groupClipDataShape = {
    type: PropTypes.oneOf([clipTypes.CLIP_GROUP]).isRequired,
    title: PropTypes.string.isRequired,
    headTrimPercent: PropTypes.number.isRequired,
    tailTrimPercent: PropTypes.number.isRequired,
};
export const groupClipDataLike = PropTypes.shape(groupClipDataShape);
export const clipLike = PropTypes.shape({
    clipId: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    data: PropTypes.oneOfType([singleClipDataLike, groupClipDataLike]).isRequired,
});

export const clipsByIdLike = PropTypes.objectOf(clipLike);
groupClipDataShape.clipsById = clipsByIdLike;

export const trackFoSpineAssetLike = PropTypes.shape({
    // name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    // url: PropTypes.string.isRequired,
    fallbackHistory: fallbackHistoryLike.isRequired,
});

export const trackLayerLike = PropTypes.shape({
    trackLayerId: PropTypes.string.isRequired,
    speechTrackId: PropTypes.string,
    clipsById: clipsByIdLike.isRequired,
    settings: PropTypes.shape({
        isDisabled: PropTypes.bool.isRequired,
        isHidden: PropTypes.bool.isRequired,
        isGeneratedFromExpressions: PropTypes.bool.isRequired,
        mixDuration: PropTypes.number,
    }),
});
export const trackLayersByIdLike = PropTypes.objectOf(trackLayerLike);
export const trackLayersLike = PropTypes.shape({
    byId: trackLayersByIdLike.isRequired,
    allIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const influenceLike = PropTypes.shape({
    expression: PropTypes.oneOf(Object.values(expressions)).isRequired,
    amount: PropTypes.number.isRequired,
});
export const influencesLike = PropTypes.arrayOf(influenceLike);
export const expressionTimelineKeyLike = PropTypes.shape({
    id: PropTypes.string.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    influences: influencesLike.isRequired,
});
export const expressionTimelineKeysLike = PropTypes.arrayOf(expressionTimelineKeyLike);

export const trackLike = PropTypes.shape({
    trackId: PropTypes.string.isRequired,
    foSpineAsset: trackFoSpineAssetLike.isRequired,
    layers: trackLayersLike.isRequired,
    expressionTimelineKeys: expressionTimelineKeysLike.isRequired,
    // todo - add transformationLike when enabling foSpineAssets to be moved around in monitor.
});
export const tracksByIdLike = PropTypes.objectOf(trackLike);
export const tracksLike = PropTypes.shape({
    byId: tracksByIdLike.isRequired,
    allIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const sceneLike = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    selectedAudioTrackId: PropTypes.string,
    tracks: tracksLike,
});
export const scenesByIdLike = PropTypes.objectOf(sceneLike);
export const scenesLike = PropTypes.shape({
    byId: scenesByIdLike.isRequired,
    allIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});
