import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import pixiStore from 'src/pixi/pixiStore';
import * as routes from 'src/components/constants/routes';
import putTextureModificationAtomOnFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/putTextureModificationAtomOnFoSpineAsset';
import buildTextureModificationAtomFromStagedAssets from 'src/utils/businessLogic/foSpineAsset/buildTextureModificationAtomFromStagedAssets';

import buildUpdatedFoTextureComp from './buildUpdatedFoTextureComp';

export default function useHandleSaveFoTextureComp({
    foTextureComp,
    foSpineAsset,
    selectedSlotName,
    setFoTextureComp,
    setFoSpineAssetTextureModifications,
    setFoSpineAssetLinkOnFoTextureComp,
}) {
    const navigate = useNavigate();

    return useCallback(
        function _handleSaveFoTextureComp() {
            const app = pixiStore.pixiAppManager.getPixiApp();

            const newFoTextureComp = buildUpdatedFoTextureComp({
                app,
                foTextureComp,
            });

            setFoTextureComp(newFoTextureComp);

            if (selectedSlotName) {
                const textureModificationAtom = buildTextureModificationAtomFromStagedAssets({
                    app,
                    foTextureCompId: newFoTextureComp.id,
                    foSpineAssetId: foSpineAsset.id,
                    slotName: selectedSlotName,
                });
                putTextureModificationAtomOnFoSpineAsset({
                    foSpineAsset,
                    setFoSpineAssetTextureModifications,
                    textureModificationAtom,
                    foTextureComp,
                    setFoSpineAssetLinkOnFoTextureComp,
                });
            }

            navigate(`../${routes.MANAGE_TEXTURE}`);
        },
        [
            foTextureComp,
            navigate,
            foSpineAsset,
            selectedSlotName,
            setFoTextureComp,
            setFoSpineAssetTextureModifications,
            setFoSpineAssetLinkOnFoTextureComp,
        ],
    );
}
