const DEFAULT_BLINKING_RATE_PER_SECOND = 0.5;

export default function getBlinkingGapWidth({ expressionTimelineKey, timeWidth, blinkDuration }) {
    const clipDuration = expressionTimelineKey.end - expressionTimelineKey.start;
    const clipWidth = clipDuration * timeWidth;
    const totalBlinkingWidth =
        blinkDuration * timeWidth * DEFAULT_BLINKING_RATE_PER_SECOND * clipDuration;
    const totalGapWidth = Math.max(clipWidth - totalBlinkingWidth, 0);
    const numberOfBlinks = clipDuration * DEFAULT_BLINKING_RATE_PER_SECOND;
    const gapWidth = totalGapWidth / numberOfBlinks;

    return gapWidth;
}
