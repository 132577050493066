import useUpdateSlotSelectionInPixi from './useUpdateSlotSelectionInPixi';
import useUpdateTextureModificationsInPixi from './useUpdateTextureModificationsInPixi';

export default function useUpdatePixiWithStateChanges({
    foTextureComps,
    foSpineAsset,
    selectedSlotName,
    setFoSpineAssetTextureModifications,
    isFoSpineAssetLoaded,
    setFoSpineAssetLinkOnFoTextureComp,
}) {
    useUpdateTextureModificationsInPixi({
        foTextureComps,
        foSpineAsset,
        selectedSlotName,
        isFoSpineAssetLoaded,
    });

    useUpdateSlotSelectionInPixi({
        foTextureComps,
        foSpineAsset,
        selectedSlotName,
        setFoSpineAssetTextureModifications,
        isFoSpineAssetLoaded,
        setFoSpineAssetLinkOnFoTextureComp,
    });
}
