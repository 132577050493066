import { useEffect, useRef } from 'react';

import pixiStore from 'src/pixi/pixiStore';

export default function useCleanup({ selectedAudioTrackId }) {
    const selectedIdRef = useRef();
    selectedIdRef.current = selectedAudioTrackId;
    useEffect(() => {
        return function _cleanup() {
            const selectedAudioTrackId = selectedIdRef.current;
            const audioController = pixiStore.audioManager.getAudioControllerById(
                selectedAudioTrackId,
            );
            if (audioController) {
                audioController.currentTime = 0;
                audioController.pause();
            }
        };
    }, []);
}
