import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PencilIcon from 'src/components/shared/iconFactory/PencilIcon';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import Modal from 'src/components/shared/Modal';
import EditExpressionTimelineKeyForm from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackExpressionLayerController/EditExpressionTimelineKeyForm';
import getIfSelectedClipsAreOnSingleLayer from 'src/components/views/SceneEditor/Timeline/utils/getSelectedSequentialClips/getIfSelectedClipsAreOnSingleLayer';

import styles from './styles.module.scss';

function EditExpressionTimelineKeyButton(props) {
    const { className, trackId, trackLayerId, selectedClipId } = props;

    const [isModalShown, setIsModalShown] = useState(false);
    const openModal = useCallback(
        function _openModal() {
            setIsModalShown(true);
        },
        [setIsModalShown],
    );
    const closeModal = useCallback(
        function _closeModal() {
            setIsModalShown(false);
        },
        [setIsModalShown],
    );

    return (
        <>
            <ToolButton
                className={classnames(styles.EditExpressionTimelineKeyButton, className)}
                onClick={openModal}
                title='Edit selected expression'
                isDisabled={!selectedClipId}
            >
                <PencilIcon />
            </ToolButton>
            {isModalShown && (
                <Modal onClickOutside={closeModal} isLayoverVisible>
                    <EditExpressionTimelineKeyForm
                        trackId={trackId}
                        trackLayerId={trackLayerId}
                        selectedClipId={selectedClipId}
                        onSubmit={closeModal}
                    />
                </Modal>
            )}
        </>
    );
}

EditExpressionTimelineKeyButton.propTypes = {
    className: PropTypes.string,
    trackId: PropTypes.string.isRequired,
    trackLayerId: PropTypes.string.isRequired,
    selectedClipId: PropTypes.string,
};

EditExpressionTimelineKeyButton.defaultProps = {
    className: '',
    selectedClipId: '',
};

function mapState(state) {
    const {
        timeline: { clipSelectionByTrackId },
    } = state;

    const {
        isSelectedClipsOnSingleLayer,
        selectedTrackId,
        selectedLayerId,
    } = getIfSelectedClipsAreOnSingleLayer({ clipSelectionByTrackId });

    let selectedClipId;
    if (isSelectedClipsOnSingleLayer) {
        const selectedClipIds = clipSelectionByTrackId[selectedTrackId][selectedLayerId];
        if (selectedClipIds.length === 1) {
            [selectedClipId] = selectedClipIds;
        }
    }

    return { selectedClipId };
}

export default connect(mapState)(EditExpressionTimelineKeyButton);
