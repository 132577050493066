import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectableList from 'src/components/shared/SelectableList';
import { foTextureCompsLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import unAssignFoTextureCompFromSlot from 'src/utils/businessLogic/foSpineAsset/unAssignFoTextureCompFromSlot';

import FoTextureCompListItem from './FoTextureCompListItem';

import styles from './styles.module.scss';

export default function FoTextureCompList(props) {
    const {
        className,
        foTextureComps,
        selectedSlotName,
        foSpineAsset,
        setFoSpineAssetTextureModifications,
        ...attributes
    } = props;

    const handleEmptyListAreaClick = useCallback(
        function _handleEmptyListAreaClick(event) {
            if (event.target === event.currentTarget) {
                unAssignFoTextureCompFromSlot({
                    slotName: selectedSlotName,
                    foSpineAsset,
                    setFoSpineAssetTextureModifications,
                });
            }
        },
        [selectedSlotName, foSpineAsset, setFoSpineAssetTextureModifications],
    );

    let listContent = null;
    if (selectedSlotName) {
        listContent = foTextureComps.allIds.map((foTextureCompId) => {
            const foTextureComp = foTextureComps.byId[foTextureCompId];

            return (
                <FoTextureCompListItem
                    className={styles.FoTextureCompListItem}
                    key={foTextureComp.id}
                    foTextureComp={foTextureComp}
                />
            );
        });
    }
    return (
        <SelectableList
            className={classnames(styles.FoTextureCompList, className)}
            onClick={handleEmptyListAreaClick}
            role='none'
            {...attributes}
        >
            {listContent}
        </SelectableList>
    );
}

FoTextureCompList.propTypes = {
    className: PropTypes.string,
    foTextureComps: foTextureCompsLike.isRequired,
};

FoTextureCompList.defaultProps = {
    className: '',
};
