import { useCallback } from 'react';

import pixiStore from 'src/pixi/pixiStore';
import updateSceneOnBackend from 'src/utils/backendUtils/updateSceneOnBackend';

export default function useClearAudioListItemSelection({
    selectedAudioTrackId,
    setPlayingId,
    setAudioTrackSelection,
    selectedSceneId,
}) {
    return useCallback(
        function _clearAudioListItemSelection({ isSkipBackendUpdate = false } = {}) {
            const audioController = pixiStore.audioManager.getAudioControllerById(
                selectedAudioTrackId,
            );
            if (audioController) {
                audioController.stop();
            }

            setPlayingId('');

            setAudioTrackSelection({
                sceneId: selectedSceneId,
                selectedAudioTrackId: '',
            });

            if (!isSkipBackendUpdate) {
                updateSceneOnBackend({
                    sceneChanges: { selectedAudioTrackId: '' },
                    selectedSceneId,
                });
            }
        },
        [selectedAudioTrackId, setPlayingId, setAudioTrackSelection, selectedSceneId],
    );
}
