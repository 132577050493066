import { useState, useCallback } from 'react';
import useIsComponentMountedRef from 'src/components/shared/hooks/useIsComponentMountedRef';
import { getAudioFileEndpoint } from 'src/utils/backendUtils/apiEndpoints';

import pixiStore from 'src/pixi/pixiStore';

export default function useAudio({ id, url: receivedUrl }) {
    const cachedAudioBuffer = pixiStore.audioManager.getAudioBufferById(id);
    const storedWaveformData = pixiStore.audioManager.getWaveformDataById(id);

    const isComponentMountedRef = useIsComponentMountedRef();

    const [waveformData, setWaveformData] = useState(storedWaveformData);

    const [isLoading, setIsLoading] = useState(false);

    const loadAudio = useCallback(
        async function _loadAudio() {
            if (cachedAudioBuffer) {
                return;
            }
            const url = receivedUrl || getAudioFileEndpoint(id);
            const audioController = pixiStore.audioManager.forceGetAudioController({ id, url });

            setIsLoading(true);
            const fetchedAudioBuffer = await audioController.loadBuffer();

            const isComponentMounted = isComponentMountedRef.current;
            if (isComponentMounted) {
                setIsLoading(false);
            }

            if (isComponentMounted && fetchedAudioBuffer) {
                const fetchedWaveformData = fetchedAudioBuffer.getChannelData(0);
                setWaveformData(fetchedWaveformData);
            }
        },
        [isComponentMountedRef, receivedUrl, id, cachedAudioBuffer],
    );

    return { waveformData, loadAudio, isLoading };
}
