import {
    AUDIO_FILES_ENDPOINT,
    AUDIO_TRACKS_ENDPOINT,
    VIDEO_CONVERSION_ENDPOINT,
    SCENES_ENDPOINT,
    USER_ENDPOINT,
    UTIL_APIS,
} from 'src/utils/backendUtils/apiEndpoints/constants/apiUrls';

export function getAudioFilesEndpoint() {
    return AUDIO_FILES_ENDPOINT;
}

export function getAudioFileEndpoint(audioTrackId) {
    return `${AUDIO_FILES_ENDPOINT}/${audioTrackId}`;
}

export function getAudioTracksEndpoint() {
    return AUDIO_TRACKS_ENDPOINT;
}

export function getAudioTrackEndpoint(audioTrackId) {
    return `${AUDIO_TRACKS_ENDPOINT}/${audioTrackId}`;
}

export function getAudioTrackTranscriptEndpoint(audioTrackId) {
    return `${AUDIO_TRACKS_ENDPOINT}/${audioTrackId}/transcript`;
}

export function getVideoConversionEndpoint() {
    return VIDEO_CONVERSION_ENDPOINT;
}
export function getConvertedVideoEndpoint(videoFileName) {
    return `${VIDEO_CONVERSION_ENDPOINT}/${videoFileName}`;
}

export function getVideoConversionProgressEndpoint(videoConversionId) {
    return `${VIDEO_CONVERSION_ENDPOINT}/${videoConversionId}/progress`;
}

export function getScenesEndpoint() {
    return SCENES_ENDPOINT;
}

export function getSceneEndpoint(sceneId) {
    return `${SCENES_ENDPOINT}/${sceneId}`;
}

export function getSceneTracksEndpoint(sceneId) {
    return `${SCENES_ENDPOINT}/${sceneId}/tracks`;
}

export function getSceneTrackEndpoint({ sceneId, trackId }) {
    return `${SCENES_ENDPOINT}/${sceneId}/tracks/${trackId}`;
}

export function getUsersEndpoint() {
    return `${USER_ENDPOINT}`;
}

export function getCurrentUserEndpoint() {
    return `${USER_ENDPOINT}/me`;
}

export function getUserSignoutEndpoint() {
    return `${USER_ENDPOINT}/signout`;
}

export function getUserSigninEndpoint() {
    return `${USER_ENDPOINT}/signin`;
}

export function getTextToTranscriptUtilEndpoint() {
    return `${UTIL_APIS}/text-to-transcript`;
}
