import clipTypes from 'src/components/constants/clipTypes';
import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';

export default function generateClipGroup({ data = {}, ...otherProps }) {
    const clipGroupData = {
        headTrimPercent: 0,
        tailTrimPercent: 0,
        ...data,
        type: clipTypes.CLIP_GROUP,
    };
    const clipGroup = generateClip({
        ...otherProps,
        data: clipGroupData,
    });

    return clipGroup;
}
