export default function drawBezierCurvesFromPoints({ points, graphics }) {
    for (let i = 0; i < points.length; i++) {
        const currentPoint = points[i];
        const nextPoint = points[i + 1] || points[0];

        const isFirstPoint = i === 0;
        if (isFirstPoint) {
            graphics.moveTo(currentPoint.main.x, currentPoint.main.y);
        }

        const destinationPoint = nextPoint.main;
        const controlPointA = currentPoint.tangent1 || currentPoint.main;
        const controlPointB = nextPoint.tangent2 || nextPoint.main;
        graphics.bezierCurveTo(
            controlPointA.x,
            controlPointA.y,
            controlPointB.x,
            controlPointB.y,
            destinationPoint.x,
            destinationPoint.y,
        );
    }
}
