import { sortBy } from 'lodash';
import generateClipGroup from 'src/utils/businessLogic/clipUtils/generateClipGroup';

function convertClipsArrayToClipsById({ clipsArray, positionOffset = 0 }) {
    const clipsById = {};
    clipsArray.forEach(function _addSelectedClipToGroup(clip) {
        const adjustedClip = {
            ...clip,
            position: clip.position + positionOffset,
        };
        clipsById[adjustedClip.clipId] = adjustedClip;
    });

    return clipsById;
}

export default function generateClipGroupFromClips({
    clips,
    isSequentialClips = false,
    data = {},
    ...otherGroupProps
}) {
    let sequentialClips = clips;
    if (!isSequentialClips) {
        sequentialClips = sortBy(clips, 'position');
    }
    const groupPosition = sequentialClips[0].position;
    const clipsById = convertClipsArrayToClipsById({
        clipsArray: sequentialClips,
        positionOffset: -groupPosition,
    });

    const newData = {
        ...data,
        clipsById,
    };

    const newClipGroup = generateClipGroup({
        ...otherGroupProps,
        position: groupPosition,
        data: newData,
    });

    return newClipGroup;
}
