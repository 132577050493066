import { useCallback, useState, useMemo } from 'react';

import expressions from 'src/utils/businessLogic/expressions/constants/expressions';

const allExpressionValues = Object.values(expressions);

export default function useInfluences({ initialInfluences }) {
    const [influences, setInfluences] = useState(
        initialInfluences || [
            {
                expression: allExpressionValues[0],
                amount: 50,
            },
        ],
    );

    const changeInfluenceAmount = useCallback(
        function _changeInfluenceAmount({ expression, amount }) {
            const updatedInfluences = influences.map((influence) => {
                if (influence.expression === expression) {
                    return {
                        ...influence,
                        amount,
                    };
                }
                return influence;
            });

            return setInfluences(updatedInfluences);
        },
        [influences, setInfluences],
    );

    const deleteInfluence = useCallback(
        function _deleteInfluence({ expression }) {
            const filteredInfluences = influences.filter((influence) => {
                const isMatch = influence.expression === expression;
                return !isMatch;
            });

            return setInfluences(filteredInfluences);
        },
        [influences, setInfluences],
    );

    const unusedExpressions = useMemo(() => {
        const unusedExpressions = allExpressionValues.filter((expression) => {
            const isExpressionUsed = influences.some((influence) => {
                return influence.expression === expression;
            });
            return !isExpressionUsed;
        });
        return unusedExpressions;
    }, [influences]);

    const [selectedExpression, setSelectedExpression] = useState(unusedExpressions[0]);
    const handleInfluenceSelectionChange = useCallback(
        function _handleInfluenceSelectionChange(event) {
            setSelectedExpression(event.target.value);
        },

        [setSelectedExpression],
    );

    const addSelectedInfluence = useCallback(
        function _addSelectedInfluence() {
            const newInfluence = {
                expression: selectedExpression,
                amount: 50,
            };
            setInfluences([...influences, newInfluence]);

            const unusedUnselectedExpressions = unusedExpressions.filter((expression) => {
                return expression !== selectedExpression;
            });
            setSelectedExpression(unusedUnselectedExpressions[0]);
        },
        [selectedExpression, influences, setInfluences, unusedExpressions, setSelectedExpression],
    );

    return {
        influences,
        changeInfluenceAmount,
        deleteInfluence,

        unusedExpressions,
        selectedExpression,
        handleInfluenceSelectionChange,
        addSelectedInfluence,
    };
}
