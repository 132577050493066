import React from 'react';
import classnames from 'classnames';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InfoIcon(props) {
    const { className, ...otherProps } = props;

    return (
        <FontAwesomeIcon
            icon={faInfo}
            className={classnames('InfoIcon', className)}
            {...otherProps}
        />
    );
}
