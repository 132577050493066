import fillAreaWithRepeatingClips from 'src/utils/businessLogic/clipUtils/fillAreaWithRepeatingClips';
import { BLINKING_ANIMATION_NAME } from 'src/utils/businessLogic/expressions/constants/specialAnimationNames';
import getAnimationOriginalDuration from 'src/spine/utils/getAnimationOriginalDuration';
import selectExpressionInflucenceByLikeleyhood from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayersFromExpressions/selectExpressionInflucenceByLikeleyhood';
import expressionsByAnimation from 'src/utils/businessLogic/expressions/constants/expressionsByAnimation';

import getBlinkingGapWidth from './getBlinkingGapWidth';

export default function generateBlikingClipsById({
    expressionTimelineKey,
    foSpineAssetId,
    timeWidth,
}) {
    const startPosition = expressionTimelineKey.start * timeWidth;
    const endPosition = expressionTimelineKey.end * timeWidth;

    const animationId = BLINKING_ANIMATION_NAME;
    const originalDuration = getAnimationOriginalDuration({
        animationId,
        foSpineAssetId,
    });

    const blinkDuration = 0.3;

    const gapWidth = getBlinkingGapWidth({
        expressionTimelineKey,
        timeWidth,
        blinkDuration,
    });

    const selectedInfluence = selectExpressionInflucenceByLikeleyhood({
        expressionTimelineKey,
    });

    const { expression } = selectedInfluence;
    const expressionsForAnimation = expressionsByAnimation[BLINKING_ANIMATION_NAME].expressions;
    const repetitionGapRatio = expressionsForAnimation[expression]?.repetitionGapRatio;
    const clipsById = fillAreaWithRepeatingClips({
        animationId,
        clipOriginalDuration: originalDuration,
        duration: blinkDuration,
        timeWidth,
        startPosition,
        endPosition,
        repetitionGapRatio,
        gapWidth,
    });

    return clipsById;
}
