import { rotateLayerZIndices } from 'src/pixi/tools/TransformTool/constants/zIndices';

import makeRotateKnobGraphics from './makeRotateKnobGraphics';
import {
    handleRotateKnobDragStart,
    handleRotateKnobDragEnd,
    handleRotateKnobDragMove,
} from './rotateKnobDragHandlers';

export default function makeRotateKnob(transformTool) {
    const rotateKnob = makeRotateKnobGraphics();

    rotateKnob.zIndex = rotateLayerZIndices.ROTATE_KNOB;
    rotateKnob.interactive = true;
    rotateKnob.buttonMode = true;
    rotateKnob
        .on('pointerdown', handleRotateKnobDragStart)
        .on('pointermove', handleRotateKnobDragMove)
        .on('pointerup', handleRotateKnobDragEnd)
        .on('pointerupoutside', handleRotateKnobDragEnd);

    rotateKnob.transformTool = transformTool;

    return rotateKnob;
}
