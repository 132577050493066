import calcUngrouppedClip from 'src/utils/businessLogic/clipUtils/calcUngrouppedClip';

export default function ungroupClipGroup({ clipGroup, timeWidth }) {
    const ungroupedClipsById = {};

    const clipIdsOfTheSelectedGroup = Object.keys(clipGroup.data.clipsById);
    clipIdsOfTheSelectedGroup.forEach(function _addClipsFromGroupToTrack(clipId) {
        const clip = clipGroup.data.clipsById[clipId];
        const adjustedClip = calcUngrouppedClip({
            parentClipGroup: clipGroup,
            clip,
            timeWidth,
        });
        if (adjustedClip) {
            ungroupedClipsById[adjustedClip.clipId] = adjustedClip;
        }
    });

    return ungroupedClipsById;
}
