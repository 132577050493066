import calcZoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcZoomedValue';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

const DEFAULT_TIMELINE_WIDTH = 500;
const END_MARGIN = 300;

export default function calcTimelineWidth({
    audioTracks,
    selectedAudioTrackId,
    tracksById,
    timeWidth,
    zoomLevel,
}) {
    const selectedAudioTrack = audioTracks.find(
        (audioTrack) => audioTrack.id === selectedAudioTrackId,
    );
    const audioDuration = selectedAudioTrack ? selectedAudioTrack.duration : 0;
    const audioTrackWidth = timeWidth * audioDuration;

    const tracksArr = Object.values(tracksById);
    let maxTrackWidth = 0;
    tracksArr.forEach(function _updateMaxWidthIfLargerTrackWidthIsLarger(track) {
        let currentTrackWidth = 0;

        track.layers.allIds.forEach(function _updateCurrentTrackWidthIfLayerWidthIsLarger(
            trackLayerId,
        ) {
            const trackLayer = track.layers.byId[trackLayerId];
            let currentLayerWidth = 0;

            const clips = Object.values(trackLayer.clipsById);
            clips.forEach((clip) => {
                const currentClipEnd = getClipEnd({ clip, timeWidth });
                currentLayerWidth = Math.max(currentClipEnd, currentLayerWidth);
            });

            currentTrackWidth = Math.max(currentLayerWidth, currentTrackWidth);
        });

        maxTrackWidth = Math.max(maxTrackWidth, currentTrackWidth);
    });

    const timelineWidth =
        Math.max(audioTrackWidth, maxTrackWidth, DEFAULT_TIMELINE_WIDTH) + END_MARGIN;

    const zoomedTimlineWidth = calcZoomedValue({ value: timelineWidth, zoomLevel });

    return zoomedTimlineWidth;
}
