import React, { useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StyledForm from 'src/components/shared/formFactory/StyledForm';
import Textarea from 'src/components/shared/textAreaFactory/Textarea';
import LoadingToolButton from 'src/components/shared/buttonFactory/LoadingToolButton';
import useInitializeSomeFormFields from 'src/components/views/SceneEditor/Timeline/TrackControllers/AudioTrackController/AudioTrackControllerButtonWithMenu/TranscriptWritingForm/hooks/useInitializeSomeFormFields';
import useInputHandlers from 'src/components/views/SceneEditor/Timeline/TrackControllers/AudioTrackController/AudioTrackControllerButtonWithMenu/TranscriptWritingForm/hooks/useInputHandlers';
import { graphemeToAnimationMapLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { editModesLike } from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';
import { trackLayerLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import useHandleFormSubmit from 'src/components/views/SceneEditor/Timeline/TrackControllers/AudioTrackController/AudioTrackControllerButtonWithMenu/TranscriptWritingForm/hooks/useHandleFormSubmit';
import FormElementGroup, {
    formElementGroupMessageTypes,
} from 'src/components/shared/FormElementGroup';
import StyledSelect from 'src/components/shared/selectFactory/StyledSelect';
import { languages } from 'src/components/constants/audio';
import {
    setTranscriptText,
    setStartTime,
    setEndTime,
    setIsEstimateEndTime,
    setLanguage,
} from 'src/components/views/SceneEditor/Timeline/TrackControllers/AudioTrackController/AudioTrackControllerButtonWithMenu/TranscriptWritingForm/transcriptWritingFormSlice';

import styles from './styles.module.scss';

function TranscriptWritingForm(props) {
    const {
        className,
        infoForWritingTranscriptRef,
        setTrackLayer,
        selectedSceneId,
        setIsModalShown,
        timeWidth,
        editModes,
        snapDistance,
        // these are formStates
        transcriptText,
        setTranscriptText,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        isEstimateEndTime,
        setIsEstimateEndTime,
        language,
        setLanguage,
    } = props;

    const transcriptWritingFormStateRef = useRef();
    transcriptWritingFormStateRef.current = {
        transcriptText,
        setTranscriptText,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        isEstimateEndTime,
        setIsEstimateEndTime,
        language,
        setLanguage,
    };

    useInitializeSomeFormFields({
        selectedSceneId,
        infoForWritingTranscriptRef,
        transcriptWritingFormStateRef,
    });

    const {
        handleTranscriptTextChange,
        handleStartTimeChange,
        handleEndTimeChange,
        handleEstimateEndTimeSwitchChange,
        handleLanguageChange,
    } = useInputHandlers(transcriptWritingFormStateRef);

    const { isSubmitting, handleFormSubmit } = useHandleFormSubmit({
        infoForWritingTranscriptRef,
        setTrackLayer,
        selectedSceneId,
        setIsModalShown,
        timeWidth,
        editModes,
        snapDistance,
        transcriptWritingFormStateRef,
    });

    const isValidEndtime = isEstimateEndTime || endTime > startTime;
    const endTimeMessages = [];
    if (!isValidEndtime) {
        endTimeMessages.push({
            type: formElementGroupMessageTypes.ALERT,
            content: 'End time cannot be less than start time',
        });
    }
    const textareaMessages = [];
    if (!transcriptText.length) {
        textareaMessages.push({
            type: formElementGroupMessageTypes.ALERT,
            content: 'Transcript text cannot be empty',
        });
    }
    return (
        <StyledForm
            headerContent='Write transcript'
            className={classnames(styles.TranscriptWritingForm, className)}
            onSubmit={handleFormSubmit}
        >
            <FormElementGroup
                label='Transcript text'
                FormElement={Textarea}
                elementProps={{
                    type: 'textarea',
                    rows: '10',
                    cols: '50',
                    value: transcriptText,
                    onChange: handleTranscriptTextChange,
                }}
                messages={textareaMessages}
            />

            <FormElementGroup
                label='Start Time (secs)'
                elementProps={{
                    type: 'number',
                    value: startTime,
                    onChange: handleStartTimeChange,
                }}
            />
            <FormElementGroup
                label='Estimate end time'
                elementProps={{
                    type: 'checkbox',
                    checked: isEstimateEndTime,
                    onChange: handleEstimateEndTimeSwitchChange,
                }}
            />

            <FormElementGroup
                label='End Time (secs)'
                elementProps={{
                    type: 'number',
                    value: endTime,
                    onChange: handleEndTimeChange,
                    disabled: isEstimateEndTime,
                }}
                messages={endTimeMessages}
            />

            <FormElementGroup
                label='Language'
                FormElement={StyledSelect}
                elementProps={{
                    value: language,
                    onChange: handleLanguageChange,
                }}
            >
                <option value={languages.ENG}>{languages.ENG}</option>
                <option value={languages.ARM}>{languages.ARM}</option>
            </FormElementGroup>

            <LoadingToolButton
                type='submit'
                isDisabled={isSubmitting || !transcriptText.length || !isValidEndtime}
                isLoading={isSubmitting}
            >
                Generate
            </LoadingToolButton>
        </StyledForm>
    );
}

TranscriptWritingForm.propTypes = {
    className: PropTypes.string,
    infoForWritingTranscriptRef: PropTypes.shape({
        current: PropTypes.shape({
            trackId: PropTypes.string.isRequired,
            trackSpeechLayer: trackLayerLike,
            foSpineAssetId: PropTypes.string.isRequired,
            audioTrackId: PropTypes.string.isRequired,
            graphemeToAnimationMap: graphemeToAnimationMapLike.isRequired,
        }).isRequired,
    }).isRequired,
    setTrackLayer: PropTypes.func.isRequired,
    setIsModalShown: PropTypes.func.isRequired,
    selectedSceneId: PropTypes.string.isRequired,
    timeWidth: PropTypes.number.isRequired,
    editModes: editModesLike.isRequired,
    snapDistance: PropTypes.number.isRequired,

    transcriptText: PropTypes.string.isRequired,
    setTranscriptText: PropTypes.func.isRequired,
    startTime: PropTypes.number.isRequired,
    setStartTime: PropTypes.func.isRequired,
    endTime: PropTypes.number.isRequired,
    setEndTime: PropTypes.func.isRequired,
    isEstimateEndTime: PropTypes.bool.isRequired,
    setIsEstimateEndTime: PropTypes.func.isRequired,
    language: PropTypes.oneOf(Object.values(languages)).isRequired,
    setLanguage: PropTypes.func.isRequired,
};

TranscriptWritingForm.defaultProps = {
    className: '',
};

function mapState(state) {
    const {
        timeline: { timeWidth, editModes, snapDistance },
        transcriptWritingForm: { transcriptText, startTime, endTime, isEstimateEndTime, language },
    } = state;

    return {
        timeWidth,
        editModes,
        snapDistance,

        transcriptText,
        startTime,
        endTime,
        isEstimateEndTime,
        language,
    };
}

export default connect(mapState, {
    setTranscriptText,
    setStartTime,
    setEndTime,
    setIsEstimateEndTime,
    setLanguage,
})(TranscriptWritingForm);
