import { isSceneSelectorUsingLocalMocks } from 'src/config/localMocksFlags';

import mockScenes from './mockScenes';

const emptyScenes = {
    byId: {},
    allIds: [],
};
const scenes = isSceneSelectorUsingLocalMocks ? mockScenes : emptyScenes;

export default {
    scenes,
};
