export default function calcZoomFlags({ bounds, screenBounds, paddingPercent = 0, isShrinkOnly }) {
    const expectedHeight = screenBounds.height * (1 - paddingPercent * 2);
    const expectedWidth = screenBounds.width * (1 - paddingPercent * 2);

    const widthRatio = Math.abs(bounds.width / expectedWidth);
    const heightRatio = Math.abs(bounds.height / expectedHeight);

    const isNeedToShrink = Math.max(widthRatio, heightRatio) > 1;
    const isExpandAllowed = !isShrinkOnly;

    const isHeightNeedsToBeFitted = heightRatio >= widthRatio;
    const isHeightShouldToBeFitted = (isNeedToShrink || isExpandAllowed) && isHeightNeedsToBeFitted;

    const isWidthNeedsToBeFitted = heightRatio < widthRatio;
    const isWidthShouldToBeFitted = (isNeedToShrink || isExpandAllowed) && isWidthNeedsToBeFitted;

    return { isHeightShouldToBeFitted, isWidthShouldToBeFitted };
}
