import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { foSpineAssetLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import CloseToolButton from 'src/components/shared/buttonFactory/CloseToolButton';

import styles from './styles.module.scss';

export default function SlotControls(props) {
    const {
        className,
        foSpineAsset,
        selectedFoTextureCompId,
        selectedSlotName,
        selectSlotByName,
        ...otherProps
    } = props;

    const handleCloseToolButtonClicked = useCallback(
        function _handleCloseToolButtonClicked() {
            selectSlotByName({ slotName: '' });
        },
        [selectSlotByName],
    );

    let slotNameText = 'No slot is selected.';
    if (selectedSlotName) {
        slotNameText = `Slot "${selectedSlotName}" is selected.`;
    }

    return (
        <div className={classnames(styles.SlotControls, className)} {...otherProps}>
            <div className='ToolContainer__tool'>{slotNameText}</div>
            {selectedSlotName && (
                <CloseToolButton
                    className='ToolContainer__tool'
                    onClick={handleCloseToolButtonClicked}
                />
            )}
        </div>
    );
}

SlotControls.propTypes = {
    className: PropTypes.string,
    foSpineAsset: foSpineAssetLike.isRequired,
    selectedFoTextureCompId: PropTypes.string.isRequired,
    selectedSlotName: PropTypes.string.isRequired,
    selectSlotByName: PropTypes.func.isRequired,
};

SlotControls.defaultProps = {
    className: '',
};
