import { noop } from 'lodash';

import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';

import adaptTracks from './adaptTracks';
import useAdaptedSetClipsByIdForExpressions from './useAdaptedSetClipsByIdForExpressions';
import useAdaptedSetTracksForExpressions from './useAdaptedSetTracksForExpressions';

export default function useAdaptTimelinePropsToAssetTrackMode({
    tracks,
    setClipsById,
    setTracks,
    buildAnimationClipError,
    assetTrackMode,
    timeWidth,
    setExpressionTimelineKeys,
}) {
    const adaptedSetClipsByIdForExpressions = useAdaptedSetClipsByIdForExpressions({
        timeWidth,
        setExpressionTimelineKeys,
    });

    const adaptedSetTracksForExpressions = useAdaptedSetTracksForExpressions({
        tracks,
        setTracks,
        timeWidth,
    });

    let adaptedTracks = tracks;
    let adaptedSetClipsById = setClipsById;
    let adaptedSetTracks = setTracks;
    let adaptedBuildClipError = buildAnimationClipError;

    if (assetTrackMode === assetTrackModes.EXPRESSION) {
        adaptedTracks = adaptTracks({
            tracks,
            // tracksById: tracks.byId,
            assetTrackMode,
            timeWidth,
        });

        adaptedSetClipsById = adaptedSetClipsByIdForExpressions;
        adaptedSetTracks = adaptedSetTracksForExpressions;
        adaptedBuildClipError = noop;
    }

    return {
        adaptedTracks,
        adaptedSetClipsById,
        adaptedSetTracks,
        adaptedBuildClipError,
    };
}
