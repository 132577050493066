import { TANGENT_LINE_WIDTH } from 'src/pixi/tools/MaskTool/constants/maskControls';
import getTangentLineColor from 'src/pixi/tools/MaskTool/positionMaskToolControls/positionKnobs/getTangentLineColor';

export default function positionKnobs({ points, pointControlsById }) {
    points.forEach((point) => {
        const pointControl = pointControlsById[point.id];
        const { mainKnob, tangent1Knob, tangent2Knob, line1, line2 } = pointControl;

        mainKnob.position.set(point.main.x, point.main.y);

        if (point.tangent1) {
            tangent1Knob.position.set(point.tangent1.x, point.tangent1.y);
            tangent1Knob.visible = true;
            line1.visible = true;
            line1.clear();
            const tangent1LineColor = getTangentLineColor({ point, tangentName: 'tangent1' });
            line1.lineStyle(TANGENT_LINE_WIDTH, tangent1LineColor);
            line1.moveTo(point.main.x, point.main.y);
            line1.lineTo(point.tangent1.x, point.tangent1.y);
        } else {
            tangent1Knob.visible = false;
            line1.visible = false;
        }

        if (point.tangent2) {
            tangent2Knob.position.set(point.tangent2.x, point.tangent2.y);
            tangent2Knob.visible = true;
            line2.visible = true;
            line2.clear();
            const tangent2LineColor = getTangentLineColor({ point, tangentName: 'tangent2' });
            line2.lineStyle(TANGENT_LINE_WIDTH, tangent2LineColor);
            line2.moveTo(point.main.x, point.main.y);
            line2.lineTo(point.tangent2.x, point.tangent2.y);
        } else {
            tangent2Knob.visible = false;
            line2.visible = false;
        }
    });
}
