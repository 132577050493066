import produce from 'immer';

export default function calcDeleteExpressionClipSelection({ clipSelectionByTrackId, tracksById }) {
    let newTracksById = tracksById;

    const trackIdsWithSelection = Object.keys(clipSelectionByTrackId);

    trackIdsWithSelection.forEach((trackId) => {
        const clipSelectionOnTrackByLayerId = clipSelectionByTrackId[trackId];
        const layerIdsWithSelection = Object.keys(clipSelectionOnTrackByLayerId);
        layerIdsWithSelection.forEach((trackLayerId) => {
            const selectedClipIds = clipSelectionOnTrackByLayerId[trackLayerId];

            selectedClipIds.forEach((selectedClipId) => {
                newTracksById = produce(newTracksById, (draftNewTracksById) => {
                    const track = draftNewTracksById[trackId];
                    // if (assetTrackMode === assetTrackModes.ANIMATION) {
                    //     delete track.layers.byId[trackLayerId].clipsById[selectedClipId];
                    // } else if (assetTrackMode === assetTrackModes.EXPRESSION) {
                    const { expressionTimelineKeys } = track;
                    const filteredExpressionTimelinekeys = expressionTimelineKeys.filter(
                        (expressionTimelineKey) => {
                            return expressionTimelineKey.id !== selectedClipId;
                        },
                    );
                    track.expressionTimelineKeys = filteredExpressionTimelinekeys;
                    // }
                    // delete draftNewTracksById[trackId].layers.byId[trackLayerId].clipsById[
                    //     selectedClipId
                    // ];
                });
            });
        });
    });

    return newTracksById;
}
