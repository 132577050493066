import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { getScenesEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import * as routes from 'src/components/constants/routes';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';
import fetchScene from 'src/utils/backendUtils/fetchScene';

export default function useHandleCloneSceneButtonClick({ setScene, setIsLoading, sceneId }) {
    const navigate = useNavigate();

    return useCallback(
        async function _handleCloneSceneButtonClick() {
            try {
                setIsLoading(true);
                const fetchedScene = await fetchScene(sceneId);
                setScene(fetchedScene);

                const clonedScene = { ...fetchedScene, name: `${fetchedScene.name} clone` };
                delete clonedScene.id;

                const scenesEndpoint = getScenesEndpoint();
                const response = await fetchWithAuth(scenesEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(clonedScene),
                });

                if (response.ok) {
                    const fetchedClonedScene = await response.json();

                    setScene(fetchedClonedScene);
                    navigate(`/${routes.SCENES}/${fetchedClonedScene.id}`);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.errors[0].message);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false);
            }
        },
        [setScene, navigate, setIsLoading, sceneId],
    );
}
