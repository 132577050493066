export function degToRad(deg) {
    return (deg * Math.PI) / 180;
}

export function radToDeg(rad) {
    return (rad * 180) / Math.PI;
}

export function calcDistanceBetweenTwoPoints({ point1, point2 }) {
    const x1 = point1.x;
    const y1 = point1.y;

    const x2 = point2.x;
    const y2 = point2.y;

    const distance = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));

    return distance;
}

export function calcPointWithAngleAndRadius({ angle, radius }) {
    const point = { x: 0, y: 0 };

    point.x = radius * Math.cos(angle);
    point.y = radius * Math.sin(angle);

    return point;
}

export function calcAngleRadBetween2PointsAndAxisX({ point1, point2 }) {
    if (!point1) {
        throw new Error(
            'calcAngleRadBetween2PointsAndAxisX requires both points. point1 is missing',
        );
    }
    if (!point2) {
        throw new Error(
            'calcAngleRadBetween2PointsAndAxisX requires both points. point2 is missing',
        );
    }
    const angle = Math.atan2(point2.y - point1.y, point2.x - point1.x);

    return angle;
}

export function calcAngleRadBetween3Points({ pointA, pointB, pointC }) {
    const distanceAB = calcDistanceBetweenTwoPoints({ point1: pointA, point2: pointB });
    const distanceBC = calcDistanceBetweenTwoPoints({ point1: pointB, point2: pointC });
    const distanceAC = calcDistanceBetweenTwoPoints({ point1: pointA, point2: pointC });

    const angle = Math.acos(
        (distanceBC * distanceBC + distanceAB * distanceAB - distanceAC * distanceAC) /
            (2 * distanceBC * distanceAB),
    );

    return angle;
}

export function calculateRotatedPosition({ point, anchor = { x: 0, y: 0 }, angleChangeRad }) {
    const currentAngle = calcAngleRadBetween2PointsAndAxisX({ point1: point, point2: anchor });
    const newAngle = currentAngle + angleChangeRad;
    const distanceBetweenPointAndAnchor = calcDistanceBetweenTwoPoints({
        point1: point,
        point2: anchor,
    });

    const positionRelativeToAnchor = calcPointWithAngleAndRadius({
        angle: newAngle,
        radius: distanceBetweenPointAndAnchor,
    });

    const pointNewPosition = {
        x: anchor.x - positionRelativeToAnchor.x,
        y: anchor.y - positionRelativeToAnchor.y,
    };

    return pointNewPosition;
}

export function calculateIntersectionPointOfTwoLines({
    line1Point1,
    line1Point2,
    line2Point1,
    line2Point2,
}) {
    const line1Angle = (line1Point2.y - line1Point1.y) / (line1Point2.x - line1Point1.x);
    const line1Offset = line1Point1.y - line1Angle * line1Point1.x;

    const line2Angle = (line2Point2.y - line2Point1.y) / (line2Point2.x - line2Point1.x);
    const line2Offset = line2Point1.y - line2Angle * line2Point1.x;

    if (line1Angle === line2Angle) {
        return null;
    }
    const convergedPointX = (line2Offset - line1Offset) / (line1Angle - line2Angle);
    const convergedPointY = convergedPointX * line1Angle + line1Offset;
    const convergedPoint = { x: convergedPointX, y: convergedPointY };

    return convergedPoint;
}

// var GMath = {
//     random: function (number) {
//         return number * Math.random();
//     },
//     randomInt: function (number) {
//         return Math.floor(GMath.random(number));
//     },
//     randomPlusMinus: function (number) {
//         return number * (Math.random() - Math.random());
//     },
//     randomIntPlusMinus: function (number) {
//         return Math.round(GMath.randomPlusMinus(number));
//     },
//     randomFromTo: function (from, to) {
//         return from + (to - from) * Math.random();
//     },
//     randomIntFromTo: function (from, to) {
//         return Math.floor(GMath.randomFromTo(from, to));
//     },

//     angleDegBetween2Points: function (p1, p2) {
//         return GMath.radToDeg(GMath.angleRadBetween2Points(p1, p2));
//     },
// };
