import drawWaveform from 'src/components/shared/AudioWaveform/utils/drawWaveform';
import smoothenWaveformData from 'src/utils/audioUtils/smoothenWaveformData';
import normalizeWaveformData from 'src/utils/audioUtils/normalizeWaveformData';
import prepareCanvasForDrawing from 'src/components/shared/AudioWaveform/utils/prepareCanvasForDrawing';

export default function buildWaveform({
    waveformData,
    lineDensityPerTenPixels,
    canvas,
    paddingPercent = 0,
}) {
    const numberOfSamples = Math.floor((canvas.offsetWidth / 10) * lineDensityPerTenPixels);
    const filteredWaveformData = smoothenWaveformData({ waveformData, numberOfSamples });

    const normalizedWaveformData = normalizeWaveformData(filteredWaveformData);

    const padding = canvas.offsetHeight * paddingPercent;

    prepareCanvasForDrawing({ canvas, padding });
    drawWaveform({ canvas, waveformData: normalizedWaveformData, padding });
}
