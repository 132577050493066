import { useState } from 'react';

import { IS_STOPPED } from 'src/components/views/SceneEditor/constants/playbackStatuses';
import {
    useHandleStopClick,
    useHandlePauseClick,
    useHandlePlayClick,
} from './playbackHandlerHooks';

export default function usePlaybackControls({
    jumpAppToTime,
    selectedAudioTrackId,
    currentTimeManager,
}) {
    const [playbackStatus, setPlaybackStatus] = useState(IS_STOPPED);

    const handlePlayButtonClick = useHandlePlayClick({
        playbackStatus,
        setPlaybackStatus,
        jumpAppToTime,
        selectedAudioTrackId,
        currentTimeManager,
    });

    const handlePauseButtonClick = useHandlePauseClick({
        playbackStatus,
        setPlaybackStatus,
        jumpAppToTime,
        selectedAudioTrackId,
    });

    const handleStopButtonClick = useHandleStopClick({
        playbackStatus,
        setPlaybackStatus,
        jumpAppToTime,
        selectedAudioTrackId,
    });

    return {
        handlePlayButtonClick,
        handlePauseButtonClick,
        handleStopButtonClick,
        playbackStatus,
    };
}
