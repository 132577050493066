import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uniqid from 'uniqid';

import StyledInput from 'src/components/shared/inputFactory/StyledInput';
import DangerMessage from 'src/components/shared/DangerMessage';

import './styles.scss';

export const formElementGroupMessageTypes = Object.freeze({
    NEUTRAL: 'neutral',
    ALERT: 'alert',
    SUCCESS: 'success',
});

export default function FormElementGroup(props) {
    const {
        className,
        label,
        elementProps: {
            id: receivedInputId,
            className: formElementClassName,
            ...otherElementProps
        },
        messages,
        isVertical,
        FormElement,
        children,
    } = props;

    const inputIdRef = useRef(receivedInputId || uniqid());
    const inputId = inputIdRef.current;

    const renderedMessages = messages.map((message) => {
        if (message.type === formElementGroupMessageTypes.ALERT) {
            return (
                <DangerMessage key={message.content} className='FormElementGroup__message'>
                    {message.content}
                </DangerMessage>
            );
        }

        return (
            <div
                className={classnames('FormElementGroup__message', {
                    FormElementGroup__successMessage:
                        message.type === formElementGroupMessageTypes.SUCCESS,
                })}
                key={message.content}
            >
                {message.content}
            </div>
        );
    });

    const isAnyMessageAnAlert = messages.some((message) => {
        return message.type === formElementGroupMessageTypes.ALERT;
    });

    return (
        <div
            className={classnames(
                'FormElementGroup',
                {
                    'FormElementGroup--vetical': isVertical,
                    'FormElementGroup--alert': isAnyMessageAnAlert,
                },
                className,
            )}
        >
            {label && (
                <label htmlFor={inputId} className='FormElementGroup__label'>
                    {label}
                </label>
            )}
            <div className='FormElementGroup__inputWithMessages'>
                <FormElement
                    id={inputId}
                    className={classnames('FormElementGroup__formElement', formElementClassName)}
                    {...otherElementProps}
                >
                    {children}
                </FormElement>
                {renderedMessages}
            </div>
        </div>
    );
}

FormElementGroup.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    isVertical: PropTypes.bool,
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(Object.values(formElementGroupMessageTypes)),
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        }),
    ),
    FormElement: PropTypes.elementType,
};

FormElementGroup.defaultProps = {
    className: '',
    label: '',
    isVertical: false,
    messages: [],
    FormElement: StyledInput,
};
