import handleTextureManagerWindowResize from 'src/components/views/TextureRouteManager/TextureManager/hooks/useSetupTextureManagerApp/handleTextureManagerWindowResize';
import setupSlotContainersTouch from './setupSlotContainersTouch';
import setupBgGhostLayerTouch from './setupBgGhostLayerTouch';

export default function setupInteractions({ app, spineAssetInstance, changeableParamsRef }) {
    setupSlotContainersTouch({
        spineAssetInstance,
        changeableParamsRef,
    });

    setupBgGhostLayerTouch({
        app,
        changeableParamsRef,
    });

    window.addEventListener('resize', handleTextureManagerWindowResize);
}
