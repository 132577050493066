import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';

export default function convertExpressionTimelineKeyToClip({ expressionTimelineKey, timeWidth }) {
    const title = expressionTimelineKey.influences
        .map(({ expression, amount }) => {
            return `${expression}-${amount}`;
        })
        .join('/');

    const duration = expressionTimelineKey.end - expressionTimelineKey.start;

    const clip = generateClip({
        position: expressionTimelineKey.start * timeWidth,
        clipId: expressionTimelineKey.id,
        data: {
            animationId: 'n/a',
            title,
            originalDuration: duration,
            animationStart: 0,
            animationEnd: duration,
            timeScale: 1,
            influences: expressionTimelineKey.influences,
        },
    });

    return clip;
}
