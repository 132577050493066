import drawBgGhostLayer from 'src/pixi/utils/drawBgGhostLayer';

export default function setupBgGhostLayerTouch({ app, changeableParamsRef }) {
    const bgGhostLayer = drawBgGhostLayer({
        width: app.screen.width,
        height: app.screen.height,
    });
    app.stage.addChild(bgGhostLayer);
    app.stage.textureManagerBgGhostLayer = bgGhostLayer;

    app.stage.interactive = true;
    app.stage.on('pointerdown', function _removeSlotSelection(event) {
        const { selectSlotByName } = changeableParamsRef.current;

        if (event.currentTarget === event.target) {
            selectSlotByName({ slotName: '' });
        }
    });
}
