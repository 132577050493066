import 'pixi.js';

import pixiStore from 'src/pixi/pixiStore';

PIXI.settings.RESOLUTION = window.devicePixelRatio || 1;

export default function mountPixi(element) {
    let app = pixiStore.pixiAppManager.getPixiApp();

    if (app) {
        app.resizeTo = element;
    } else {
        app = new PIXI.Application({
            resizeTo: element,
            autoResize: true,
            // // wo the 2 lines below, the app will have it's own ticker and loader
            // sharedTicker: true,
            // sharedLoader: true,
            backgroundColor: 0x7f818e,
            antialias: true,
            // transparent: true,
        });
        pixiStore.pixiAppManager.setPixiApp(app);
    }
    window.app = app;
    element.appendChild(app.view);

    app.loader.addPr = function _addPr(...args) {
        return new Promise(function _executor(resolve, reject) {
            function onLoad(loader, resources) {
                resolve({ loader, resources, app });
            }

            app.loader.add(...args).load(onLoad);
            app.loader.onError.add((error) => {
                reject(error);
            });
        });
    };

    return app;
}
