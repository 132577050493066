const fragment = `
varying vec2 vTextureCoord;
uniform sampler2D uSampler;
uniform vec4 color;
void main(void) {
    vec4 currentColor = texture2D(uSampler, vTextureCoord);
    vec4 colorOverlay = color * currentColor.a;
    float alpha = color.a;
    float inverseAlpha = 1.0 - alpha;
    float red = colorOverlay.r * alpha + currentColor.r * inverseAlpha;
    float green = colorOverlay.g * alpha + currentColor.g * inverseAlpha;
    float blue = colorOverlay.b * alpha + currentColor.b * inverseAlpha;
    gl_FragColor = vec4(red, green, blue, currentColor.a);
}
`;

/**
 * @class
 * @extends PIXI.Filter
 *
 * @example
 *  // adds a transparent tint on top of the the existing pixels
 *  someSprite.filters = [new ColorOverlayFilterWithAlpha([0.7, 0.2, 0.5, 0.3])];
 */
export default class ColorOverlayFilterWithAlpha extends PIXI.Filter {
    _rgba = [];

    /**
     * @param {number|Array<number>} [color=[0.0, 0.0, 0.0, 1.0]]
     */
    constructor(color = [0.0, 0.0, 0.0, 1.0]) {
        super(PIXI.defaultFilterVertex, fragment);
        this.color = color;
    }

    /**
     * The resulting color, as a 4 component RGBA e.g. [1.0, 0.5, 1.0, 1.0]
     * @member {number|Array<number>|Float32Array}
     * @default 0x000000
     */
    set color(value) {
        let newRgbaArr = [];

        if (typeof value === 'number') {
            PIXI.utils.hex2rgb(value, newRgbaArr);
            newRgbaArr[3] = 1.0;
        } else {
            const [red, green, blue, alpha = 1] = value;
            newRgbaArr = [red, green, blue, alpha];
        }

        this.uniforms.color = newRgbaArr;
        this._rgba = newRgbaArr;
    }

    get color() {
        return this._rgba;
    }

    get colorHex() {
        const hexColor = PIXI.utils.rgb2hex(this._rgba);

        return hexColor;
    }

    get red() {
        return this._rgba[0];
    }

    set red(value) {
        const newRgba = [...this._rgba];
        newRgba[0] = value;
        this.color = newRgba;
    }

    get green() {
        return this._rgba[1];
    }

    set green(value) {
        const newRgba = [...this._rgba];
        newRgba[1] = value;
        this.color = newRgba;
    }

    get blue() {
        return this._rgba[2];
    }

    set blue(value) {
        const newRgba = [...this._rgba];
        newRgba[2] = value;
        this.color = newRgba;
    }

    get alpha() {
        return this._rgba[3];
    }

    set alpha(value) {
        const newRgba = [...this._rgba];
        newRgba[3] = value;
        this.color = newRgba;
    }
}
