import getOriginalSize from 'src/pixi/utils/getOriginalSize';

export default function getPivotPercent(displayObject) {
    let { originalSize } = displayObject;

    if (!originalSize) {
        originalSize = getOriginalSize(displayObject);
    }

    const localBounds = displayObject.getLocalBounds();
    const visibleRegionPivot = {
        x: displayObject.pivot.x - localBounds.x,
        y: displayObject.pivot.y - localBounds.y,
    };

    const pivotPercent = {
        x: visibleRegionPivot.x / originalSize.width,
        y: visibleRegionPivot.y / originalSize.height,
    };

    return pivotPercent;
}
