import { useCallback } from 'react';

import pixiStore from 'src/pixi/pixiStore';
import formatTimeToSeconds from 'src/utils/stringUtils/formatTimeToSeconds';

function calcCurrentTimePosition({ currentTimeStr, screenWidth }) {
    const x = currentTimeStr.length * 9;
    return { x: screenWidth - x, y: 5 };
}

export default function useHandleCurrentTimeMonitorTextUpdate({
    currentTimeManager,
    monitorOverlaysDispalyObjsRef,
}) {
    return useCallback(function handleCurrentTimeMonitorTextUpdate() {
        const app = pixiStore.pixiAppManager.getPixiApp();
        const { timeDisplayObj } = monitorOverlaysDispalyObjsRef.current;

        const { currentTime } = currentTimeManager;

        const formattedTime = formatTimeToSeconds({ time: currentTime });
        timeDisplayObj.text = `current time: ${formattedTime} ss`;

        const pos = calcCurrentTimePosition({
            currentTimeStr: timeDisplayObj.text,
            screenWidth: app.screen.width,
        });
        timeDisplayObj.position.set(pos.x, pos.y);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
