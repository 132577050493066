export const SCENES = 'scenes';
export const SCENE_ID = 'sceneId';

export const ASSETS = 'assets';

export const TEXTURES = 'textures';
export const EDIT_TEXTURE = 'edit';
export const MANAGE_TEXTURE = 'manage';

export const AUDIO = 'audio';

export const EDIT_SCENE = 'editor';

export const SIGN_UP = 'signup';
export const SIGN_IN = 'signin';
export const USER_PROFILE = 'profile';
