import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Animations from './Animations';

import styles from './styles.module.scss';

export default function Bins(props) {
    const { className } = props;

    return (
        <div className={classnames(styles.Bins, className)}>
            <Animations />
        </div>
    );
}

Bins.propTypes = {
    className: PropTypes.string,
};

Bins.defaultProps = {
    className: '',
};
