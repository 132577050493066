import { getAudioTrackEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';
import convertClipsToFoTranscriptWords from 'src/utils/businessLogic/transcript/convertClipsToFoTranscriptWords';

import selectClipsByIdsBySpeechAudioTrackIds from './selectClipsByIdsBySpeechAudioTrackIds';

let lastClipsByIdsBySpeechAudioTrackId = {};
export default async function fetchTransciptUpdates({ tracks, timeWidth }) {
    const clipsByIdsBySpeechAudioTrackId = selectClipsByIdsBySpeechAudioTrackIds({
        tracks,
    });

    const changedClipsByIdsBySpeechAudioTrackId = Object.keys(
        clipsByIdsBySpeechAudioTrackId,
    ).reduce(function _dropUnchangedClipsById(changedClipsByIdsBySpeechAudioTrackId, audioTrackId) {
        const clipsById = clipsByIdsBySpeechAudioTrackId[audioTrackId];
        const lastClipsById = lastClipsByIdsBySpeechAudioTrackId[audioTrackId];

        if (clipsById !== lastClipsById) {
            changedClipsByIdsBySpeechAudioTrackId[audioTrackId] = clipsById;
        }
        return changedClipsByIdsBySpeechAudioTrackId;
    }, {});

    const updatePromArr = [];
    Object.keys(changedClipsByIdsBySpeechAudioTrackId).forEach(
        function _generateAndUpdateChangedTranscripts(audioTrackId) {
            const clipsById = changedClipsByIdsBySpeechAudioTrackId[audioTrackId];

            const newFoTranscriptWords = convertClipsToFoTranscriptWords({
                clipsById,
                timeWidth,
            });

            const audioTrackEndpoint = getAudioTrackEndpoint(audioTrackId);

            const updateProm = fetchWithAuth(audioTrackEndpoint, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    foTranscriptWords: newFoTranscriptWords,
                }),
            });

            return updatePromArr.push(updateProm);
        },
    );
    lastClipsByIdsBySpeechAudioTrackId = clipsByIdsBySpeechAudioTrackId;

    const updateResponses = await Promise.all(updatePromArr);

    return updateResponses;
}
