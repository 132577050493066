import { useCallback, useState, useRef } from 'react';

export default function useCapturedFramesPercent() {
    const [capturedFramesPercent, setCapturedFramesPercent] = useState(0);
    const numberOfCapturedFramesRef = useRef(0);

    const resetCapturedFramesPercent = useCallback(
        function _resetCapturedFramesPercent() {
            setCapturedFramesPercent(0);
            numberOfCapturedFramesRef.current = 0;
        },
        [numberOfCapturedFramesRef, setCapturedFramesPercent],
    );

    const updateCapturedFramesPercent = useCallback(
        function _updateCapturedFramesPercent({ numberOfFrames }) {
            numberOfCapturedFramesRef.current++;
            const newCapturedFramesPercent = Math.floor(
                (numberOfCapturedFramesRef.current / numberOfFrames) * 100,
            );
            setCapturedFramesPercent(newCapturedFramesPercent);
        },
        [numberOfCapturedFramesRef, setCapturedFramesPercent],
    );

    return {
        capturedFramesPercent,
        resetCapturedFramesPercent,
        updateCapturedFramesPercent,
    };
}
