import {
    LINKED_TANGENT_LINE_COLOR,
    UNLINKED_TANGENT1_LINE_COLOR,
    UNLINKED_TANGENT2_LINE_COLOR,
} from 'src/pixi/tools/MaskTool/constants/maskControls';
import getOtherTangentName from 'src/pixi/tools/MaskTool/utils/getOtherTangentName';

const UNLINKED_TANGENT_LINE_COLOR_MAP = Object.freeze({
    tangent1: UNLINKED_TANGENT1_LINE_COLOR,
    tangent2: UNLINKED_TANGENT2_LINE_COLOR,
});

export default function getTangentLineColor({ tangentName, point }) {
    const otherTangentName = getOtherTangentName(tangentName);

    const tangentLineColor =
        point.isTangentsLinked && point[otherTangentName]
            ? LINKED_TANGENT_LINE_COLOR
            : UNLINKED_TANGENT_LINE_COLOR_MAP[tangentName];

    return tangentLineColor;
}
