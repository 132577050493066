import getGrabPositionOffsetFromItemsLeftEdge from 'src/utils/dndUtils/getGrabPositionOffsetFromItemsLeftEdge';

export default function calcDragItemPosRelToNode({
    monitor,
    domNode,
    width = Number.POSITIVE_INFINITY,
}) {
    const grabPositionOffsetFromItemsLeftEdge = getGrabPositionOffsetFromItemsLeftEdge(monitor);
    const dropPositionRelToViewport =
        monitor.getClientOffset().x - Math.min(grabPositionOffsetFromItemsLeftEdge, width);

    const dropNodePosRelToViewport = Math.round(domNode.getBoundingClientRect().left);
    const dropPosition = dropPositionRelToViewport - dropNodePosRelToViewport;
    const positiveDropPosition = Math.max(0, dropPosition);

    return positiveDropPosition;
}
