import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';
import generateTrackLayer from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayer';
import convertExpressionTimelineKeysToClipsById from 'src/components/views/SceneEditor/Timeline/hooks/useAdaptTimelinePropsToAssetTrackMode/convertExpressionTimelineKeysToClipsById';

export default function adaptTracks({ tracks, assetTrackMode, timeWidth }) {
    let adaptedTracks = tracks;

    if (assetTrackMode === assetTrackModes.EXPRESSION) {
        const adaptedTracksById = Object.keys(tracks.byId).reduce(
            function _adaptExpressionTimelineKeysToTrackLayer(adaptedTracksById, trackId) {
                const track = tracks.byId[trackId];
                const { expressionTimelineKeys } = track;
                const trackLayerId = 'fixed-layer-id';
                const clipsById = convertExpressionTimelineKeysToClipsById({
                    expressionTimelineKeys,
                    timeWidth,
                });
                const trackLayer = generateTrackLayer({ trackLayerId, clipsById });
                const adaptedLayers = {
                    byId: {
                        [trackLayerId]: trackLayer,
                    },
                    allIds: [trackLayerId],
                };
                const adaptedTrack = { ...track, layers: adaptedLayers };
                adaptedTracksById[trackId] = adaptedTrack;
                return adaptedTracksById;
            },
            {},
        );
        adaptedTracks = { ...tracks, byId: adaptedTracksById };
    }

    return adaptedTracks;
}
