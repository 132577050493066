export default function iterateOverBezierCurve({
    startPoint,
    controlPoint1,
    controlPoint2,
    destinationPoint,
    numberOfApproximationSegments = 10,
    callback,
    contextProps,
}) {
    for (let i = 1; i <= numberOfApproximationSegments; ++i) {
        const t = i / numberOfApproximationSegments;

        const dt = 1 - t;
        const dt2 = dt * dt;
        const dt3 = dt2 * dt;

        const t2 = t * t;
        const t3 = t2 * t;

        const x =
            dt3 * startPoint.x +
            3 * dt2 * t * controlPoint1.x +
            3 * dt * t2 * controlPoint2.x +
            t3 * destinationPoint.x;

        const y =
            dt3 * startPoint.y +
            3 * dt2 * t * controlPoint1.y +
            3 * dt * t2 * controlPoint2.y +
            t3 * destinationPoint.y;

        const approximatedPoint = { x, y };

        callback({ point: approximatedPoint, contextProps });
    }
}
