export default function getSelectedClipsUsingIds({
    tracks,
    clipSelectionByTrackId,
    selectedTrackId,
    selectedLayerId,
}) {
    const clipSelectionOnTrackByLayerId = clipSelectionByTrackId[selectedTrackId];
    const selectedClipIds = clipSelectionOnTrackByLayerId?.[selectedLayerId];

    const selectedClips = [];
    if (selectedClipIds) {
        selectedClipIds.forEach((selectedClipId) => {
            const foundTrack = tracks.byId[selectedTrackId];
            const foundLayer = foundTrack?.layers.byId[selectedLayerId];
            const foundClip = foundLayer?.clipsById[selectedClipId];
            if (foundClip) {
                selectedClips.push(foundClip);
            }
        });
    }

    return selectedClips;
}
