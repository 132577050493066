import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StyledForm from 'src/components/shared/formFactory/StyledForm';
import Input from 'src/components/shared/inputFactory/Input';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import Line from 'src/components/shared/Line';
import { editModesLike } from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';
import TimelineContext from 'src/components/views/SceneEditor/Timeline/context/TimelineContext';
import useInfluences from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackExpressionLayerController/EditExpressionTimelineKeyForm/hooks/useInfluences';
import useHandleFormSubmit from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackExpressionLayerController/EditExpressionTimelineKeyForm/hooks/useHandleFormSubmit';
import useTimeMarkers from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackExpressionLayerController/EditExpressionTimelineKeyForm/hooks/useTimeMarkers';
import useInitialValues from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackExpressionLayerController/EditExpressionTimelineKeyForm/hooks/useInitialValues';
import { setClipSelection } from 'src/components/views/SceneEditor/Timeline/timelineSlice';

import InfluenceInputSet from './InfluenceInputSet';
import InfluenceSelectorSet from './InfluenceSelectorSet';

import styles from './styles.module.scss';

function EditExpressionTimelineKeyForm(props) {
    const {
        className,
        trackId,
        trackLayerId,
        selectedClipId,
        timeWidth,
        zoomLevel,
        editModes,
        snapDistance,
        onSubmit,
        setClipSelection,
    } = props;

    const { setClipsById, tracks } = useContext(TimelineContext);

    const { clipsById } = tracks.byId[trackId].layers.byId[trackLayerId];

    const { initialInfluences, initialStartTime, initialDuration } = useInitialValues({
        selectedClipId,
        clipsById,
        timeWidth,
    });

    const {
        influences,
        changeInfluenceAmount,
        deleteInfluence,
        unusedExpressions,
        selectedExpression,
        handleInfluenceSelectionChange,
        addSelectedInfluence,
    } = useInfluences({ initialInfluences });

    const { startTime, duration, handleStartTimeChange, handleDurationChange } = useTimeMarkers({
        initialStartTime,
        initialDuration,
    });

    const handleFormSubmit = useHandleFormSubmit({
        influences,
        selectedClipId,
        trackId,
        trackLayerId,
        timeWidth,
        zoomLevel,
        editModes,
        snapDistance,
        startTime,
        duration,
        onSubmit,
        setClipsById,
        clipsById,
        setClipSelection,
    });

    return (
        <StyledForm
            headerContent={selectedClipId ? 'Edit Expression' : 'Add Expression'}
            className={classnames(styles.EditExpressionTimelineKeyForm, className)}
            onSubmit={handleFormSubmit}
        >
            <div className={styles.EditExpressionForm__inputSet}>
                <label htmlFor='startTime'> Start Time (secs) </label>
                <Input
                    id='startTime'
                    type='number'
                    className={styles.EditExpressionForm__numberInput}
                    value={startTime}
                    onChange={handleStartTimeChange}
                />
            </div>

            <div className={styles.EditExpressionForm__inputSet}>
                <label htmlFor='duration'> Duration (secs) </label>
                <Input
                    id='duration'
                    type='number'
                    className={styles.EditExpressionForm__numberInput}
                    value={duration}
                    onChange={handleDurationChange}
                />
            </div>

            <Line />

            {influences.length ? (
                influences.map((influence) => {
                    return (
                        <InfluenceInputSet
                            className={styles.EditExpressionForm__inputSet}
                            influence={influence}
                            key={influence.expression}
                            changeInfluenceAmount={changeInfluenceAmount}
                            deleteInfluence={deleteInfluence}
                        />
                    );
                })
            ) : (
                <div className={styles.EditExpressionForm__emptyMessage}>
                    select an emotion and click the + button
                </div>
            )}

            <Line />

            <InfluenceSelectorSet
                className={styles.EditExpressionForm__inputSet}
                handleInfluenceSelectionChange={handleInfluenceSelectionChange}
                selectedExpression={selectedExpression}
                unusedExpressions={unusedExpressions}
                addSelectedInfluence={addSelectedInfluence}
            />

            <div className={styles.EditExpressionForm__submitButtons}>
                <ToolButton type='submit' isDisabled={!influences.length || !duration}>
                    Save
                </ToolButton>
            </div>
        </StyledForm>
    );
}

EditExpressionTimelineKeyForm.propTypes = {
    className: PropTypes.string,
    selectedClipId: PropTypes.string,
    trackId: PropTypes.string.isRequired,
    trackLayerId: PropTypes.string.isRequired,
    timeWidth: PropTypes.number.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    editModes: editModesLike.isRequired,
    snapDistance: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setClipSelection: PropTypes.func.isRequired,
};

EditExpressionTimelineKeyForm.defaultProps = {
    selectedClipId: '',
    className: '',
};

function mapState(state) {
    const {
        timeline: { timeWidth, zoomLevel, editModes, snapDistance },
    } = state;

    return {
        timeWidth,
        zoomLevel,
        editModes,
        snapDistance,
    };
}

export default connect(mapState, { setClipSelection })(EditExpressionTimelineKeyForm);
