import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import PlusIcon from 'src/components/shared/iconFactory/PlusIcon';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import Modal from 'src/components/shared/Modal';
import EditExpressionTimelineKeyForm from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackExpressionLayerController/EditExpressionTimelineKeyForm';

import styles from './styles.module.scss';

export default function AddExpressionTimelineKeyButton(props) {
    const { className, trackId, trackLayerId } = props;

    const [isModalShown, setIsModalShown] = useState(false);
    const openModal = useCallback(
        function _openModal() {
            setIsModalShown(true);
        },
        [setIsModalShown],
    );
    const closeModal = useCallback(
        function _closeModal() {
            setIsModalShown(false);
        },
        [setIsModalShown],
    );

    return (
        <>
            <ToolButton
                className={classnames(styles.AddExpressionTimelineKeyButton, className)}
                onClick={openModal}
                title='Add expression'
            >
                <PlusIcon />
            </ToolButton>
            {isModalShown && (
                <Modal onClickOutside={closeModal} isLayoverVisible>
                    <EditExpressionTimelineKeyForm
                        trackId={trackId}
                        trackLayerId={trackLayerId}
                        onSubmit={closeModal}
                    />
                </Modal>
            )}
        </>
    );
}

AddExpressionTimelineKeyButton.propTypes = {
    className: PropTypes.string,
    trackId: PropTypes.string.isRequired,
    trackLayerId: PropTypes.string.isRequired,
};

AddExpressionTimelineKeyButton.defaultProps = {
    className: '',
};
