import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Input from 'src/components/shared/inputFactory/Input';
import CloseToolButton from 'src/components/shared/buttonFactory/CloseToolButton';
import SearchIcon from 'src/components/shared/iconFactory/SearchIcon';
import getSelectedTrackFoSpineAsset from 'src/reduxStore/utils/getSelectedTrackFoSpineAsset';
import { setAnimations } from 'src/components/views/SceneEditor/Bins/Animations/animationsSlice';
import { animationsByIdLike } from 'src/components/views/SceneEditor/Bins/Animations/animationsSlice/propTypes';
import useExtractAnimations from 'src/components/views/SceneEditor/Bins/Animations/hooks/useExtractAnimations';
import useAnimationList from 'src/components/views/SceneEditor/Bins/Animations/hooks/useAnimationList';
import useSearchTerm from 'src/components/views/SceneEditor/Bins/Animations/hooks/useSearchTerm';
import useAnimationTreeNodes from 'src/components/views/SceneEditor/Bins/Animations/hooks/useAnimationTreeNodes';
import withRouter from 'src/reduxStore/utils/withRouter';

import AnimationList from './AnimationList';

import styles from './styles.module.scss';

function Animations(props) {
    const { className, setAnimations, foSpineAssetId, animationsById, isPixiMounted } = props;

    const { searchTerm, handleSearchInputChange, handleCloseButonClick } = useSearchTerm();

    useExtractAnimations({ foSpineAssetId, setAnimations, isPixiMounted });

    const filteredAnimations = useAnimationList({ animationsById, searchTerm });
    const animationTreeNodes = useAnimationTreeNodes(filteredAnimations);

    return (
        <div className={classnames(styles.Animations, className)}>
            <div className={styles.Animations__searchArea}>
                <label htmlFor='searchTerm'>
                    <SearchIcon />
                </label>
                <Input id='searchTerm' value={searchTerm} onChange={handleSearchInputChange} />
                <CloseToolButton onClick={handleCloseButonClick} />
            </div>
            <AnimationList animationTreeNodes={animationTreeNodes} />
        </div>
    );
}

Animations.propTypes = {
    className: PropTypes.string,
    foSpineAssetId: PropTypes.string,
    isPixiMounted: PropTypes.bool.isRequired,
    animationsById: animationsByIdLike.isRequired,
};

Animations.defaultProps = {
    foSpineAssetId: '',
    className: '',
};

function mapState(state, ownProps) {
    const { sceneId: selectedSceneId } = ownProps.router.params;
    const selectedTrackFoSpineAsset = getSelectedTrackFoSpineAsset(state, { selectedSceneId });
    const { sceneEditor, animations } = state;
    const { isPixiMounted } = sceneEditor;
    const { byId: animationsById } = animations;

    return {
        foSpineAssetId: selectedTrackFoSpineAsset.id,
        animationsById,
        isPixiMounted,
    };
}
export default withRouter(connect(mapState, { setAnimations })(Animations));
