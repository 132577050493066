export default function calcPointerOffesetFromDisplayObject({
    displayObject,
    refDisplayObject,
    pointerEventData,
}) {
    const pointerOffsetFromRef = pointerEventData.getLocalPosition(refDisplayObject);
    const displayObjectGlobalPosition = displayObject.getGlobalPosition();
    const displayObjectOffsetFromRef = refDisplayObject.toLocal(displayObjectGlobalPosition);

    const localOffset = {
        x: pointerOffsetFromRef.x - displayObjectOffsetFromRef.x,
        y: pointerOffsetFromRef.y - displayObjectOffsetFromRef.y,
    };

    return localOffset;
}
