import { useCallback } from 'react';

export default function useHandleAudioFileChange({ setAudioFile }) {
    return useCallback(
        function _handleAudioFileChange(event) {
            const files = [...event.target.files];
            const audioFile = files.find((currentFile) => {
                return currentFile.type.match(/^audio\//);
            });

            if (audioFile) {
                setAudioFile(audioFile);
            }
        },
        [setAudioFile],
    );
}
