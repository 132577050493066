import { useState, useEffect, useCallback } from 'react';

import { isSceneSelectorUsingLocalMocks } from 'src/config/localMocksFlags';
import mockScenes from 'src/components/views/SceneSelector/sceneSelectorSlice/initialState/mockScenes';
import fetchScenes from 'src/utils/backendUtils/fetchScenes';

export default function useInitializeScenes({ setScenes }) {
    const [isFetchingScenes, setIsFetchingScenes] = useState(false);

    const fetchScenesForView = useCallback(
        async function _fetchScenes() {
            setIsFetchingScenes(true);
            const result = await fetchScenes();
            if (result) {
                const { scenesById, allSceneIds } = result;
                setScenes({ scenesById, allSceneIds });
            }

            setIsFetchingScenes(false);
        },
        [setScenes, setIsFetchingScenes],
    );

    useEffect(function _fetchScenesOnMount() {
        if (isSceneSelectorUsingLocalMocks) {
            const { byId: scenesById, allIds: allSceneIds } = mockScenes;
            setScenes({ scenesById, allSceneIds });
        } else {
            fetchScenesForView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isFetchingScenes;
}
