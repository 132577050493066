import { Graphics } from 'pixi.js';

import drawAttachmentBounds from 'src/spine/utils/drawAttachmentBounds';

export default function highlightFoSpineAssetSlotByName({
    spineAssetInstance,
    slotName,
    isSkipClear,
    root,
}) {
    if (!spineAssetInstance) {
        throw new Error('Cannot highlight slot without spineAssetInstance.');
    }

    const attachmentBoundsGraphics = spineAssetInstance.attachmentBoundsGraphics || new Graphics();

    if (!slotName) {
        if (!isSkipClear) {
            attachmentBoundsGraphics.clear();
        }
        return;
    }

    const slot = spineAssetInstance.skeleton.findSlot(slotName);
    drawAttachmentBounds({
        slot,
        graphics: attachmentBoundsGraphics,
        isSkipClear,
        root,
        spineAssetInstance,
    });
    spineAssetInstance.attachmentBoundsGraphics = attachmentBoundsGraphics;

    root.addChild(attachmentBoundsGraphics);
}
