import React, { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

import Layover from 'src/components/shared/Layover';
import refLike from 'src/components/shared/propTypes/refLike';
import useContentSyle from 'src/components/shared/SuperTooltip/hooks/useContentStyle';

import './styles.scss';

/**
 * SuperTooltip is allows positioning of a tooltip content in relation to a target elem.
 * positioning default to contents top left corner to the targets top right corner.
 */

export default function SuperTooltip(props) {
    const {
        className,
        targetElemRef,
        onClickOutside,
        children,
        percentOnTargetElem,
        percentOnContentElem,
        isLayoverVisible,
        ...attributes
    } = props;
    const contentElemRef = useRef();

    const contentStyle = useContentSyle({
        targetElemRef,
        contentElemRef,
        percentOnTargetElem,
        percentOnContentElem,
    });

    const handleLayoverClick = useCallback(
        function _handleLayoverClick(event) {
            if (event.target === event.currentTarget) {
                onClickOutside(event);
            }
        },
        [onClickOutside],
    );

    const superTooltip = (
        <Layover isTransparent={!isLayoverVisible} onMouseDown={handleLayoverClick}>
            <div
                className={classnames('SuperTooltip', className)}
                style={contentStyle}
                ref={contentElemRef}
                {...attributes}
            >
                {children}
            </div>
        </Layover>
    );

    return createPortal(superTooltip, document.getElementById('root'));
}

SuperTooltip.propTypes = {
    className: PropTypes.string,
    targetElemRef: refLike.isRequired,
    percentOnTargetElem: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }),
    percentOnContentElem: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }),
    isLayoverVisible: PropTypes.bool,
    onClickOutside: PropTypes.func,
};

SuperTooltip.defaultProps = {
    className: '',
    percentOnTargetElem: {
        x: 1,
        y: 0,
    },
    percentOnContentElem: {
        x: 0,
        y: 0,
    },
    isLayoverVisible: false,
    onClickOutside: noop,
};
