import getOriginalSize from 'src/pixi/utils/getOriginalSize';
import getGlobalScale from 'src/pixi/utils/getGlobalScale';

export default function getGlobalSize(displayObject) {
    const globalDisplayObjectScale = getGlobalScale(displayObject);
    const originalDisplayObjectSize = getOriginalSize(displayObject);
    const globalDisplayObjectSize = {
        width: originalDisplayObjectSize.width * globalDisplayObjectScale.x,
        height: originalDisplayObjectSize.height * globalDisplayObjectScale.y,
    };

    return globalDisplayObjectSize;
}
