import getAnimationOriginalDuration from 'src/spine/utils/getAnimationOriginalDuration';
import buildClipForAnimation from 'src/utils/businessLogic/clipUtils/buildClipForAnimation';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

export default function fillAreaWithClips({
    startPosition,
    areaDuration,
    foSpineAssetId,
    timeWidth,
    animationNames,
    firstAnimationName,
    clipDuration: expectedClipDuration,
}) {
    const clipsById = {};
    if (!animationNames.length) {
        return clipsById;
    }

    let nextAnimationName = firstAnimationName || animationNames[0];
    let remainingDuration = areaDuration;
    let currentposition = startPosition;

    while (remainingDuration > 0) {
        const originalDuration = getAnimationOriginalDuration({
            animationId: nextAnimationName,
            foSpineAssetId,
        });
        const clipDuration = expectedClipDuration || originalDuration;

        const allowedDuration = Math.min(remainingDuration, clipDuration);
        const clip = buildClipForAnimation({
            animationId: nextAnimationName,
            duration: allowedDuration,
            originalDuration,
            position: currentposition,
        });
        clipsById[clip.clipId] = clip;

        currentposition = getClipEnd({ clip, timeWidth });
        remainingDuration -= clipDuration;

        // eslint-disable-next-line no-loop-func
        const siblingAnimations = animationNames.filter((animationName) => {
            return animationName !== nextAnimationName;
        });
        const selectedSiblingAnimIndex = Math.floor(Math.random() * siblingAnimations.length);
        const selectedSiblingAnimName = siblingAnimations[selectedSiblingAnimIndex];
        nextAnimationName = selectedSiblingAnimName;
    }

    return clipsById;
}
