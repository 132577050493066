import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import hasObjectTruthyProp from 'src/utils/objectUtils/hasObjectTruthyProp';

export default function useLastApprovedClipsRestoration({
    isDroppedRef,
    isHoveringMapRef,
    lastApprovedTracksByIdRef,
    tracksById,
    setTracks,
}) {
    const isHoveringOnAnyTrack = hasObjectTruthyProp(isHoveringMapRef.current, undefined, true);

    const { sceneId: selectedSceneId } = useParams();

    useEffect(
        function _restoreToLastApprovedClipsWhenDraggingOffTrack() {
            const isDropped = isDroppedRef.current;
            const isHoveringInterruptedWithoutDropping =
                !isHoveringOnAnyTrack && !isDropped && lastApprovedTracksByIdRef.current;
            if (isHoveringInterruptedWithoutDropping) {
                setTracks({
                    sceneId: selectedSceneId,
                    tracksById: lastApprovedTracksByIdRef.current,
                });
            }
        },
        [isHoveringOnAnyTrack, isDroppedRef, lastApprovedTracksByIdRef, setTracks, selectedSceneId],
    );

    useEffect(
        function _recordLastApprovedClipsWhenNotHovering() {
            const isDropped = isDroppedRef.current;
            const isHoveringConcludedWithDrop = !isHoveringOnAnyTrack && isDropped;
            if (isHoveringConcludedWithDrop) {
                lastApprovedTracksByIdRef.current = tracksById;
            }
        },
        [isHoveringOnAnyTrack, tracksById, isDroppedRef, lastApprovedTracksByIdRef],
    );
}
