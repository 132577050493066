import { useState, useCallback } from 'react';

export default function useSignupFormEmail() {
    const [email, setEmail] = useState('');
    const handleEmailChange = useCallback(
        function _handleEmailChange(event) {
            setEmail(event.target.value);
        },
        [setEmail],
    );

    return { email, handleEmailChange };
}
