import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useHandleFoTextureSelectionChange from 'src/components/views/TextureRouteManager/TextureEditor/SortableFoTextureList/FoTextureList/hooks/useHandleFoTextureSelectionChange';
import useHandleEmptyListAreaClick from 'src/components/views/TextureRouteManager/TextureEditor/SortableFoTextureList/FoTextureList/hooks/useHandleEmptyListAreaClick';
import { foTexturesLike } from 'src/components/views/TextureRouteManager/TextureManager/textureManagerSlice/propTypes';
import SelectableList from 'src/components/shared/SelectableList';

import SortableFoTextureListItem from './SortableFoTextureListItem';

import styles from './styles.module.scss';

export default function FoTextureList(props) {
    const { className, foTextures, ...attributes } = props;

    const handleFoTextureSelectionChange = useHandleFoTextureSelectionChange();

    const { handleEmptyListAreaClick, handleListItemPointerDown } = useHandleEmptyListAreaClick({
        handleFoTextureSelectionChange,
    });

    return (
        <SelectableList
            className={classnames(styles.FoTextureList, className)}
            onClick={handleEmptyListAreaClick}
            role='none'
            {...attributes}
        >
            {foTextures.map((foTexture, index) => {
                const { name, id } = foTexture;

                return (
                    <SortableFoTextureListItem
                        key={id}
                        foTextureId={id}
                        className={styles.FoTextureListItem}
                        onFoTextureSelectionChange={handleFoTextureSelectionChange}
                        name={name}
                        onPointerDown={handleListItemPointerDown}
                        index={index}
                    />
                );
            })}
        </SelectableList>
    );
}

FoTextureList.propTypes = {
    className: PropTypes.string,
    foTextures: foTexturesLike.isRequired,
};

FoTextureList.defaultProps = {
    className: '',
};
