import { Graphics } from 'pixi.js';

import {
    KNOB_SIZE,
    KNOB_OUTLINE_SIZE,
} from 'src/pixi/tools/TransformTool/constants/transformKnobs';

export default function makeRotateKnobGraphics() {
    const rotateKnob = new Graphics();
    rotateKnob.lineStyle(KNOB_OUTLINE_SIZE, 0x00ffff, 1);
    rotateKnob.beginFill(0x00ff00, 0.8);
    rotateKnob.drawCircle(0, 0, KNOB_SIZE / 2);
    rotateKnob.endFill();

    // a circle is drawn around the pivot, so it ends up being in the center of the graphics
    // => no need to center the pivot like we do for rect graphics.

    return rotateKnob;
}
