import mockTracks from './mockSceneTracks';

export default {
    byId: {
        '6251238ee9418ec39497fdec': {
            id: '6251238ee9418ec39497fdec',
            name: 'miscivious cupid',
            // selectedAudioTrackId: '',
            // selectedAudioTrackId: 'music-track-1',
            // selectedAudioTrackId: 'speech_track-1-success',
            // selectedAudioTrackId: 'speech_track-2-stereo',
            selectedAudioTrackId: '63905b845caa81553ead3167',
            tracks: mockTracks,
        },
        '6251238ee9418ec39497fde1': {
            id: '6251238ee9418ec39497fde1',
            name: 'dummy scene',
            // selectedAudioTrackId: '',
            // selectedAudioTrackId: 'music-track-1',
            // selectedAudioTrackId: '',
            // selectedAudioTrackId: 'speech_track-2-stereo',
            selectedAudioTrackId: '63905b845caa81553ead3167',
            // tracks: mockTracks,
        },
    },
    allIds: ['6251238ee9418ec39497fdec', '6251238ee9418ec39497fde1'],
};
