import produce from 'immer';

import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';
import cloneClip from 'src/utils/businessLogic/clipUtils/cloneClip';
import { ERROR_THRESHOLD } from 'src/utils/businessLogic/clipUtils/insertClip/insertAggressively/constants';

export default function resolveLeftConflictAggressively({
    leftNeighbour,
    newClip,
    clipsById,
    timeWidth,
    trimMode,
}) {
    let newClipsById = { ...clipsById };

    const leftNeighbourEnd = getClipEnd({ clip: leftNeighbour, timeWidth });
    const overlapWidth = Math.max(leftNeighbourEnd - newClip.position, 0);
    const isOverlap = overlapWidth > 0;

    if (isOverlap) {
        const leftNeghbourTrimmedClipData = trimClipData({
            tailTrimAmount: -overlapWidth,
            clipData: leftNeighbour.data,
            trimMode,
            timeWidth,
        });

        newClipsById = produce(newClipsById, (draftNewClipsById) => {
            draftNewClipsById[leftNeighbour.clipId].data = leftNeghbourTrimmedClipData;
        });
    }

    const newClipWidth = getClipWidth({ clipData: newClip.data, timeWidth });
    const isLeftNeighbourFullyCoveringNewClip = overlapWidth > newClipWidth + ERROR_THRESHOLD;

    if (isLeftNeighbourFullyCoveringNewClip) {
        const newClipEnd = getClipEnd({
            clip: newClip,
            timeWidth,
        });
        const leftNeighbourWidthToTheLeftOfNewClipEnd = newClipEnd - leftNeighbour.position;
        const leftNeighbourSplitClipData = trimClipData({
            headTrimAmount: leftNeighbourWidthToTheLeftOfNewClipEnd,
            clipData: leftNeighbour.data,
            trimMode,
            timeWidth,
        });

        const initialLeftNeighbourSplitClip = generateClip({
            position: newClipEnd,
            data: leftNeighbourSplitClipData,
        });
        const leftNeighbourSplitClip = cloneClip({
            clip: initialLeftNeighbourSplitClip,
            isDeep: true,
        });

        newClipsById = produce(newClipsById, (draftNewClipsById) => {
            draftNewClipsById[leftNeighbourSplitClip.clipId] = leftNeighbourSplitClip;
        });
    }

    return newClipsById;
}
