export default function getFoSpineAssetsForTracks(state, { selectedSceneId }) {
    const {
        assetSelector: { foSpineAssets },
    } = state;

    const relatedFoSpineAssets = {
        byId: {},
        allIds: [],
    };

    const { tracks } = state.sceneSelector.scenes.byId[selectedSceneId];

    tracks.allIds.forEach((trackId) => {
        const track = tracks.byId[trackId];
        const foSpineAssetId = track.foSpineAsset.id;
        const foSpineAsset = foSpineAssets.byId[foSpineAssetId];
        if (!foSpineAsset) {
            console.error(
                `Track: ${trackId} has a foSpineAssetId: ${foSpineAssetId}, ` +
                    'but there was no matching foSpineAsset',
            );
            return;
        }
        relatedFoSpineAssets.byId[foSpineAssetId] = foSpineAsset;
        relatedFoSpineAssets.allIds.push(foSpineAssetId);
    });

    return relatedFoSpineAssets;
}
