import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import HiddenDragPreview from 'src/components/shared/HiddenDragPreview';
import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import useTimeIndicatorDrag from 'src/components/views/SceneEditor/Timeline/TimeTracker/TimeIndicatorHeadDnD/hooks/useTimeIndicatorHeadDrag';
import refLike from 'src/components/shared/propTypes/refLike';

import TimeIndicatorHead from './TimeIndicatorHead';

import styles from './styles.module.scss';

export default function TimeIndicatorHeadDnD(props) {
    const { className, position, timeWidth, rulerNodeRef, zoomLevel, ...otherProps } = props;

    const { jumpAppToTime, handlePauseButtonClick } = useContext(SceneEditorContext);

    const { dragRef, previewRef, handleDrag } = useTimeIndicatorDrag({
        timeWidth,
        rulerNodeRef,
        jumpAppToTime,
        handlePauseButtonClick,
        zoomLevel,
    });

    return (
        <div
            className={classnames(styles.TimeIndicatorHeadDnD, className)}
            ref={dragRef}
            style={{
                transform: `translate(${position}px,0)`,
            }}
            onDrag={handleDrag}
        >
            <HiddenDragPreview ref={previewRef} />
            <TimeIndicatorHead {...otherProps} />
        </div>
    );
}

TimeIndicatorHeadDnD.propTypes = {
    className: PropTypes.string,
    position: PropTypes.number.isRequired,
    timeWidth: PropTypes.number.isRequired,
    rulerNodeRef: refLike.isRequired,
};

TimeIndicatorHeadDnD.defaultProps = {
    className: '',
};
