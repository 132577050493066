import calcDeleteAnimationClipSelection from 'src/components/views/SceneEditor/Timeline/utils/calcDeleteAnimationClipSelection';

export default function calcDeleteClipFromTracks({ tracksById, clipId }) {
    let foundClip;
    let foundLayer;
    let foundTrack;

    Object.values(tracksById).some(function _findIfTrackContainsClip(track) {
        const isTrackContainsClip = Object.values(track.layers.byId).some(
            function _findIfLayerContainsClip(layer) {
                const isLayerContainsClip = Object.values(layer.clipsById).some(
                    function _findIfClipIsMatch(clip) {
                        const isClipMatch = clip.clipId === clipId;
                        if (isClipMatch) {
                            foundClip = clip;
                        }
                        return isClipMatch;
                    },
                );

                if (isLayerContainsClip) {
                    foundLayer = layer;
                }
                return isLayerContainsClip;
            },
        );

        if (isTrackContainsClip) {
            foundTrack = track;
        }
        return isTrackContainsClip;
    });

    const clipSelectionByTrackId = {
        [foundTrack.trackId]: {
            [foundLayer.trackLayerId]: [foundClip.clipId],
        },
    };
    const tracksByIdAfterDeletion = calcDeleteAnimationClipSelection({
        clipSelectionByTrackId,
        tracksById,
    });

    return { tracksByIdAfterDeletion, foundClip, foundLayer, foundTrack };
}
