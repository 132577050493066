import { Graphics } from 'pixi.js';

import makeMainKnob from './makeMainKnob';
import makeTangentKnob from './makeTangentKnob';
import destroyPointControl from './destroyPointControl';

export default class PointControl {
    constructor({ point, maskTool }) {
        const pointControl = this;
        pointControl.mainKnob = makeMainKnob({ point, maskTool });

        pointControl.tangent1Knob = makeTangentKnob({ point, maskTool, tangentName: 'tangent1' });
        pointControl.tangent2Knob = makeTangentKnob({ point, maskTool, tangentName: 'tangent2' });

        pointControl.line1 = new Graphics();
        pointControl.line2 = new Graphics();
        pointControl.id = point.id;
    }

    destroy() {
        const pointControl = this;
        destroyPointControl(pointControl);
    }
}
