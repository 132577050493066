import PropTypes from 'prop-types';
import { audioTrackTypes } from 'src/components/constants/audio';

const sharedAudioTrackProps = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string,
    label: PropTypes.string,
    duration: PropTypes.number.isRequired,
};

export const musicTrackLike = PropTypes.shape({
    ...sharedAudioTrackProps,
    type: PropTypes.oneOf([audioTrackTypes.MUSIC]).isRequired,
});

export const foGraphemeCharLike = PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    graphemeChar: PropTypes.string.isRequired,
});
export const foGraphemeCharsLike = PropTypes.arrayOf(foGraphemeCharLike);
export const foTranscriptWordLike = PropTypes.shape({
    startTime: PropTypes.number.isRequired,
    word: PropTypes.string.isRequired,
    foGraphemeChars: foGraphemeCharsLike.isRequired,
});
export const foTranscriptWordsLike = PropTypes.arrayOf(foTranscriptWordLike);

export const speechTrackLike = PropTypes.shape({
    ...sharedAudioTrackProps,
    type: PropTypes.oneOf([audioTrackTypes.SPEECH]).isRequired,
    foTranscriptWords: foTranscriptWordsLike,
    hasTranscriptWords: PropTypes.bool.isRequired,
});

export const audioTrackLike = PropTypes.oneOfType([speechTrackLike, musicTrackLike]);
export const audioTracksLike = PropTypes.arrayOf(audioTrackLike);
