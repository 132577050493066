import reduxStore from 'src/reduxStore';
import { setIsShiftKeyDown } from 'src/components/App/appSlice';

export function handleKeyDown(event) {
    if (event.key === 'Shift') {
        reduxStore.dispatch(setIsShiftKeyDown(true));
    }
}

export function handleKeyUp(event) {
    if (event.key === 'Shift') {
        reduxStore.dispatch(setIsShiftKeyDown(false));
    }
}
