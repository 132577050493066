import clipTypes from 'src/components/constants/clipTypes';
import { isNumber } from 'lodash';

import generateClipId from './generateClipId';

export default function generateClip({ position, clipId: receivedClipId, data }) {
    if (!isNumber(position)) {
        throw new Error("can't generate a clip without valid position");
    }
    if (!data) {
        throw new Error("can't generate a clip without data");
    }
    const clipId = receivedClipId ?? generateClipId(data.type || clipTypes.CLIP_SINGLE);

    return {
        clipId,
        data: {
            type: clipTypes.CLIP_SINGLE,
            title: clipId,
            ...data,
        },
        position,
    };
}
