import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import buildWaveform from 'src/components/shared/AudioWaveform/utils/buildWaveform';
import useWaveformResize from 'src/components/shared/AudioWaveform/hooks/useWaveformResize';

import './styles.scss';

// todo - waveformData ends up having millions of points(numbers).
// See if a better solution for rendering waveform exists.
export default function AudioWaveform(props) {
    const {
        className,
        url,
        waveformData,
        paddingPercent,
        lineDensityPerTenPixels,
        style,
        ...otherProps
    } = props;

    const canvasRef = useRef();
    const { width } = style;

    useEffect(
        function _visualizeAudio() {
            const canvas = canvasRef.current;
            if (!waveformData) {
                return;
            }

            buildWaveform({
                waveformData,
                canvas,
                paddingPercent,
                lineDensityPerTenPixels,
            });
        },
        [canvasRef, waveformData, paddingPercent, lineDensityPerTenPixels, width],
    );

    useWaveformResize({ canvasRef, waveformData, lineDensityPerTenPixels, paddingPercent });

    return (
        <canvas
            className={classnames('AudioWaveform', className)}
            ref={canvasRef}
            style={style}
            {...otherProps}
        />
    );
}

AudioWaveform.propTypes = {
    className: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    waveformData: PropTypes.object,
    paddingPercent: PropTypes.number,
    lineDensityPerTenPixels: PropTypes.number,
    style: PropTypes.shape({ width: PropTypes.number }),
};

AudioWaveform.defaultProps = {
    className: '',
    waveformData: null,
    paddingPercent: 0,
    lineDensityPerTenPixels: 4,
    style: {},
};
