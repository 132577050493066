import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import SelectableList from 'src/components/shared/SelectableList';
import Spinner from 'src/components/shared/Spinner';
import Layover from 'src/components/shared/Layover';
import {
    audioTrackLike,
    audioTracksLike,
} from 'src/components/views/AudioSelector/audioSelectorSlice/propTypes';
import { setAudioTrackSelection } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import { setAudioTracks } from 'src/components/views/AudioSelector/audioSelectorSlice';
import useHandleAudioTrackClick from 'src/components/views/AudioSelector/hooks/useHandleAudioTrackClick';
import useHandlePlayButtonClick from 'src/components/views/AudioSelector/hooks/useHandlePlayButtonClick';
import useCleanup from 'src/components/views/AudioSelector/hooks/useCleanup';
import useInitializeAudioTracks from 'src/components/views/AudioSelector/hooks/useInitializeAudioTracks';
import useClearAudioListItemSelection from 'src/components/views/AudioSelector/hooks/useClearAudioListItemSelection';
import withRouter from 'src/reduxStore/utils/withRouter';

import AudioListItem from './AudioListItem';
import SelectedLabel from './SelectedLabel';
import AudioUploader from './AudioUploader';

import styles from './styles.module.scss';

function AudioSelector(props) {
    const {
        className,
        audioTracks,
        setAudioTrackSelection,
        selectedAudioTrack,
        setAudioTracks,
        selectedSceneId,
    } = props;

    const isFetchingTracks = useInitializeAudioTracks({ setAudioTracks });

    const [playingId, setPlayingId] = useState('');

    const selectedAudioTrackId = selectedAudioTrack?.id || '';
    const clearAudioListItemSelection = useClearAudioListItemSelection({
        selectedAudioTrackId,
        setPlayingId,
        setAudioTrackSelection,
        selectedSceneId,
    });

    const handleAudioTrackClick = useHandleAudioTrackClick({
        selectedAudioTrackId,
        setAudioTrackSelection,
        clearAudioListItemSelection,
        selectedSceneId,
    });

    const handlePlayButtonClick = useHandlePlayButtonClick({
        playingId,
        setPlayingId,
    });

    const selectableListRef = useRef();
    const handleSelectableListClick = useCallback(
        function _handleSelectableListClick(event) {
            const isDirectClick = event.target === selectableListRef.current;
            if (isDirectClick) {
                clearAudioListItemSelection();
            }
        },
        [clearAudioListItemSelection, selectableListRef],
    );

    useCleanup({ selectedAudioTrackId });

    const selectedAudioTrackLabel = selectedAudioTrack?.label || '';
    return (
        <div className={classnames(styles.AudioSelector, className)}>
            <AudioUploader
                onAudioAdded={handleAudioTrackClick}
                selectedAudioTrack={selectedAudioTrack}
            />
            <SelectedLabel
                label={selectedAudioTrackLabel}
                clearAudioListItemSelection={clearAudioListItemSelection}
            />
            {isFetchingTracks && (
                <Layover>
                    <Spinner />
                </Layover>
            )}
            <SelectableList onClick={handleSelectableListClick} nodeRef={selectableListRef}>
                {audioTracks.map((audioTrack) => {
                    const { id } = audioTrack;
                    const isSelected = id === selectedAudioTrackId;
                    const isPlaying = id === playingId;

                    return (
                        <AudioListItem
                            key={id}
                            audioTrack={audioTrack}
                            onAudioTrackClick={handleAudioTrackClick}
                            onPlayButtonClick={handlePlayButtonClick}
                            isSelected={isSelected}
                            isPlaying={isPlaying}
                        />
                    );
                })}
            </SelectableList>
        </div>
    );
}

AudioSelector.propTypes = {
    className: PropTypes.string,
    selectedSceneId: PropTypes.string.isRequired,
    setAudioTrackSelection: PropTypes.func.isRequired,
    setAudioTracks: PropTypes.func.isRequired,
    audioTracks: audioTracksLike.isRequired,
    selectedAudioTrack: audioTrackLike,
};

AudioSelector.defaultProps = {
    className: '',
    selectedAudioTrack: null,
};

function mapState(state, ownProps) {
    const { sceneId: selectedSceneId } = ownProps.router.params;
    const { selectedAudioTrackId } = state.sceneSelector.scenes.byId[selectedSceneId];
    const { audioTracks } = state.audioSelector;

    const selectedAudioTrack = audioTracks.find((audioTrack) => {
        return audioTrack.id === selectedAudioTrackId;
    });

    return {
        audioTracks,
        selectedAudioTrack,
        selectedSceneId,
    };
}

export default withRouter(
    connect(mapState, { setAudioTrackSelection, setAudioTracks })(AudioSelector),
);
