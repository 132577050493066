import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

export default function StepsNavLink(props) {
    const { className, title, subTitle, isDisabled, ...otherProps } = props;

    return (
        <NavLink
            className={({ isActive }) => {
                return classnames(
                    styles.StepsNavLink,
                    {
                        [styles['StepsNavLink--disabled']]: isDisabled,
                        [styles['StepsNavLink--active']]: isActive,
                    },
                    className,
                );
            }}
            {...otherProps}
        >
            {subTitle && <div className={styles.StepsNavLink__subTitle}>{subTitle}</div>}
            {title && <div className={styles.StepsNavLink__title}>{title}</div>}
        </NavLink>
    );
}

StepsNavLink.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

StepsNavLink.defaultProps = {
    className: '',
    title: '',
    subTitle: '',
};
