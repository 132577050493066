import getGlobalScale from 'src/pixi/utils/getGlobalScale';

export default function getLocalSize({ displayObject, globalSize }) {
    const globalDisplayObjectScale = getGlobalScale(displayObject);
    const inheritedScale = {
        x: globalDisplayObjectScale.x / displayObject.scale.x,
        y: globalDisplayObjectScale.y / displayObject.scale.y,
    };
    const localSize = {
        width: globalSize.width / inheritedScale.x,
        height: globalSize.height / inheritedScale.y,
    };

    return localSize;
}
