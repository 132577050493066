import { createSlice } from '@reduxjs/toolkit';
import {
    AGGRESSIVE,
    // SUBMISSIVE,
    // INSERT_COMPACT_SHIFT,
    // INSERT_SHIFT,
} from 'src/utils/businessLogic/clipUtils/insertClip/constants/conflictModes';
import {
    // CONTENT,
    SPEED,
} from 'src/components/views/SceneEditor/Timeline/constants/trimModes';
import { LAYER_DEFAULT_MIX_DURATION } from 'src/components/views/SceneEditor/Timeline/constants/defaultValues';
import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';

const initialState = {
    zoomLevel: 1,
    // 50px per second
    timeWidth: 50,
    editModes: {
        conflictResolution: AGGRESSIVE,
        trimMode: SPEED,
        isSnap: false,
    },
    snapDistance: 100,
    globalMixDuration: LAYER_DEFAULT_MIX_DURATION,
    clipSelectionByTrackId: {},
    updatingTracksById: {},
    assetTrackMode: assetTrackModes.ANIMATION,
};

const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        setIsTrackLayerUpdating(state, action) {
            const { trackId, trackLayerId, isTrackLayerUpdating } = action.payload;

            const updatingLayersById = state.updatingTracksById[trackId] || {};
            updatingLayersById[trackLayerId] = isTrackLayerUpdating;

            state.updatingTracksById[trackId] = updatingLayersById;
        },
        toggleIsSnap(state) {
            state.editModes.isSnap = !state.editModes.isSnap;
        },
        setConflictResolution(state, action) {
            state.editModes.conflictResolution = action.payload;
        },
        setClipSelection(state, action) {
            state.clipSelectionByTrackId = action.payload;
        },
        deselectClips(state) {
            if (Object.values(state.clipSelectionByTrackId).length) {
                state.clipSelectionByTrackId = {};
            }
        },
        setTrimMode(state, action) {
            state.editModes.trimMode = action.payload;
        },
        setAssetTrackMode(state, action) {
            state.assetTrackMode = action.payload;
        },
        setZoomLevel(state, action) {
            state.zoomLevel = action.payload;
        },
    },
});

export const {
    setIsTrackLayerUpdating,
    toggleIsSnap,
    setConflictResolution,
    setClipSelection,
    deselectClips,
    setTrimMode,
    setZoomLevel,
    setAssetTrackMode,
} = timelineSlice.actions;

export default timelineSlice.reducer;
