import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    DRAW_MASK,
    MOVE_MASK_CONTROLS,
    ADD_POINT,
    DELETE_POINT,
    TOGGLE_KNOB,
} from 'src/pixi/tools/MaskTool/constants/maskToolTypes';
import { getTextureTools } from 'src/components/views/TextureRouteManager/TextureEditor/pixiAssets/textureTools';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import ToolContainer from 'src/components/shared/ToolContainer';

import styles from './styles.module.scss';

const maskToolLabels = Object.freeze({
    [DRAW_MASK]: 'Draw',
    [MOVE_MASK_CONTROLS]: 'Move',
    [ADD_POINT]: 'Add',
    [DELETE_POINT]: 'Delete',
    [TOGGLE_KNOB]: 'Toggle',
});

export default function MaskToolTypeSelector(props) {
    const { className, ...attributes } = props;

    const { maskTool } = getTextureTools();
    const [maskToolTypeSelection, setMaskToolTypeSelection] = useState(maskTool.getToolType());

    const handleMaskToolSelectionChange = useCallback(
        (maskToolType) => {
            setMaskToolTypeSelection(maskToolType);

            maskTool.setToolType(maskToolType);
        },
        [setMaskToolTypeSelection, maskTool],
    );

    return (
        <ToolContainer
            className={classnames(styles.MaskToolTypeSelector, className)}
            isSubContainer
            {...attributes}
        >
            {/* <div className={classnames(styles.MaskToolTypeSelector, className)} {...attributes}> */}
            {Object.keys(maskToolLabels).map((maskType) => {
                const maskTypeLabel = maskToolLabels[maskType];
                return (
                    <ToolButton
                        onClick={() => {
                            handleMaskToolSelectionChange(maskType);
                        }}
                        isActive={maskType === maskToolTypeSelection}
                        key={maskType}
                        className='ToolContainer__tool'
                    >
                        {maskTypeLabel}
                    </ToolButton>
                );
            })}
        </ToolContainer>
    );
}

MaskToolTypeSelector.propTypes = {
    className: PropTypes.string,
};

MaskToolTypeSelector.defaultProps = {
    className: '',
};
