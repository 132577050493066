import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { trackLayerLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import {
    toggleIsTrackLayerHidden,
    toggleIsTrackLayerDisabled,
} from 'src/components/views/SceneSelector/sceneSelectorSlice';
import ChevronDownIcon from 'src/components/shared/iconFactory/ChevronDownIcon';
import ChevronRightIcon from 'src/components/shared/iconFactory/ChevronRightIcon';
import EyeIcon from 'src/components/shared/iconFactory/EyeIcon';
import EyeIconSlash from 'src/components/shared/iconFactory/EyeIconSlash';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import TrackLayerController from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackLayerController';

import TrackAnimationLayerControllerButtonWithMenu from './TrackAnimationLayerControllerButtonWithMenu';

import styles from './styles.module.scss';

function TrackAnimationLayerController(props) {
    const {
        className,
        layer,
        trackId,
        toggleIsTrackLayerHidden,
        toggleIsTrackLayerDisabled,
    } = props;
    const { isDisabled, isHidden } = layer.settings;
    const { trackLayerId } = layer;

    const { sceneId: selectedSceneId } = useParams();

    const handleHideButtonClick = useCallback(
        function _handleHideButtonClick() {
            toggleIsTrackLayerHidden({ sceneId: selectedSceneId, trackId, trackLayerId });
            updateTracksOnBackend({ selectedSceneId });
        },
        [toggleIsTrackLayerHidden, trackLayerId, trackId, selectedSceneId],
    );

    const handleEnableButtonClick = useCallback(
        function _handleEnableButtonClick() {
            toggleIsTrackLayerDisabled({ sceneId: selectedSceneId, trackId, trackLayerId });
            updateTracksOnBackend({ selectedSceneId });
        },
        [toggleIsTrackLayerDisabled, trackLayerId, trackId, selectedSceneId],
    );

    return (
        <TrackLayerController
            className={classnames(styles.TrackAnimationLayerController, className)}
        >
            <ToolButton
                className={styles.TrackAnimationLayerController__hideLayerToolButton}
                onClick={handleHideButtonClick}
                title='hide clips'
            >
                {isHidden ? <ChevronRightIcon /> : <ChevronDownIcon />}
            </ToolButton>
            <ToolButton
                className={styles.TrackAnimationLayerController__enableLayerToolButton}
                onClick={handleEnableButtonClick}
                title='disable track'
            >
                {isDisabled ? <EyeIconSlash /> : <EyeIcon />}
            </ToolButton>
            <TrackAnimationLayerControllerButtonWithMenu
                trackId={trackId}
                trackLayerId={layer.trackLayerId}
            />
        </TrackLayerController>
    );
}

TrackAnimationLayerController.propTypes = {
    className: PropTypes.string,
    trackId: PropTypes.string.isRequired,
    layer: trackLayerLike.isRequired,
    toggleIsTrackLayerHidden: PropTypes.func.isRequired,
    toggleIsTrackLayerDisabled: PropTypes.func.isRequired,
};

TrackAnimationLayerController.defaultProps = {
    className: '',
};

export default connect(undefined, { toggleIsTrackLayerHidden, toggleIsTrackLayerDisabled })(
    TrackAnimationLayerController,
);
