import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import InfoIcon from 'src/components/shared/iconFactory/InfoIcon';
import { setIsMonitorOverlaysShown } from 'src/components/views/SceneEditor/sceneEditorSlice';

import styles from './styles.module.scss';

function MonitorOverlaysVisibilityButton(props) {
    const {
        className,
        setMonitorOverlaysVisibility,
        isMonitorOverlaysShown,
        setIsMonitorOverlaysShown,
    } = props;

    const handleMonitorOverlaysVisibilityButtonClick = useCallback(
        function _handleMonitorOverlaysVisibilityButtonClick() {
            setIsMonitorOverlaysShown(!isMonitorOverlaysShown);
            setMonitorOverlaysVisibility(!isMonitorOverlaysShown);
        },
        [setIsMonitorOverlaysShown, isMonitorOverlaysShown, setMonitorOverlaysVisibility],
    );

    return (
        <ToolButton
            className={classnames(styles.MonitorOverlaysVisibilityButton, className)}
            onClick={handleMonitorOverlaysVisibilityButtonClick}
            title='Show overlays'
            isActive={isMonitorOverlaysShown}
        >
            <InfoIcon />
        </ToolButton>
    );
}

MonitorOverlaysVisibilityButton.propTypes = {
    setMonitorOverlaysVisibility: PropTypes.func.isRequired,
    setIsMonitorOverlaysShown: PropTypes.func.isRequired,
    isMonitorOverlaysShown: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

MonitorOverlaysVisibilityButton.defaultProps = {
    className: '',
};

function mapState(state) {
    const { isMonitorOverlaysShown } = state.sceneEditor;

    return {
        isMonitorOverlaysShown,
    };
}

export default connect(mapState, { setIsMonitorOverlaysShown })(MonitorOverlaysVisibilityButton);
