import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
    setTrackLayer,
    setTrackLayers,
} from 'src/components/views/SceneSelector/sceneSelectorSlice';
import ButtonWithMenu from 'src/components/shared/ButtonWithMenu';
import ToolIcon from 'src/components/shared/iconFactory/ToolIcon';
import PlusIcon from 'src/components/shared/iconFactory/PlusIcon';
import FilmIcon from 'src/components/shared/iconFactory/FilmIcon';
import useHandleAddTrackLayerButtonClick from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/AssetTrackControllerButtonWithMenu/hooks/useHandleAddTrackLayerButtonClick';
import useHandleGenerateClipsFromExpressionsButtonClick from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/AssetTrackControllerButtonWithMenu/hooks/useHandleGenerateClipsFromExpressionsButtonClick';
import { expressionTimelineKeysLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';
import { assetTrackModeLike } from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';

import styles from './styles.module.scss';

function AssetTrackControllerButtonWithMenu(props) {
    const {
        trackId,
        foSpineAssetId,
        className,
        setTrackLayer,
        setTrackLayers,
        expressionTimelineKeys,
        activeAssetTrackMode,
    } = props;

    const handleAddTrackLayerButtonClick = useHandleAddTrackLayerButtonClick({
        setTrackLayer,
        trackId,
    });

    const handleGenerateClipsFromExpressionsButtonClick = useHandleGenerateClipsFromExpressionsButtonClick(
        {
            foSpineAssetId,
            setTrackLayers,
            trackId,
            expressionTimelineKeys,
        },
    );

    if (activeAssetTrackMode === assetTrackModes.EXPRESSION) {
        return null;
    }

    return (
        <ButtonWithMenu
            buttonProps={{
                className: classnames(
                    className,
                    'ToolContainer__tool',
                    styles.AssetTrackControllerButtonWithMenu,
                ),
                children: <ToolIcon />,
            }}
            nestedListProps={{
                listData: [
                    {
                        key: 'add_layer',
                        content: (
                            <>
                                <PlusIcon /> Add layer
                            </>
                        ),
                        contentElemAttributes: {
                            onClick: handleAddTrackLayerButtonClick,
                        },
                    },
                    {
                        key: 'generate_clips_from_expressions',
                        content: (
                            <>
                                <PlusIcon /> <FilmIcon /> Generate clips from expressions
                            </>
                        ),
                        contentElemAttributes: {
                            onClick: handleGenerateClipsFromExpressionsButtonClick,
                        },
                    },
                ],
            }}
        />
    );
}

AssetTrackControllerButtonWithMenu.propTypes = {
    className: PropTypes.string,
    trackId: PropTypes.string.isRequired,
    foSpineAssetId: PropTypes.string.isRequired,
    expressionTimelineKeys: expressionTimelineKeysLike.isRequired,
    setTrackLayer: PropTypes.func.isRequired,
    setTrackLayers: PropTypes.func.isRequired,
    activeAssetTrackMode: assetTrackModeLike.isRequired,
};

AssetTrackControllerButtonWithMenu.defaultProps = {
    className: '',
};

function mapState(state) {
    const { assetTrackMode: activeAssetTrackMode } = state.timeline;

    return { activeAssetTrackMode };
}

export default connect(mapState, { setTrackLayer, setTrackLayers })(
    AssetTrackControllerButtonWithMenu,
);
