import { sortBy } from 'lodash';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
// eslint-disable-next-line import/no-cycle
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';

/**
 * @headTrimAmount > 0 => trim right, otherwise, trim left
 * @tailTrimAmount > 0 => trim right, otherwise, trim left
 * @clipData
 * @trimMode
 * @timeWidth
 */
export default function speedTrimClipGroupData({
    headTrimAmount = 0,
    tailTrimAmount = 0,
    clipData,
    trimMode,
    timeWidth,
}) {
    if (!headTrimAmount && !tailTrimAmount) {
        return clipData;
    }
    if (!timeWidth) {
        console.error(`can't speed trim a clip's data without a valid timeWidth. 
        Received: ${timeWidth}`);
    }

    let trimmedClipData = clipData;

    const clipGroupWidth = getClipWidth({ clipData, timeWidth });
    const sortedClips = sortBy(clipData.clipsById, 'position');
    const totalTrimAmount = tailTrimAmount - headTrimAmount;
    const newGroupWidth = clipGroupWidth + totalTrimAmount;

    const newClipsById = {};
    sortedClips.forEach(function _trimContainedClipProportinally(clip) {
        const positionToGroupWidthRatio = clip.position / clipGroupWidth;
        const newPostion = newGroupWidth * positionToGroupWidthRatio;

        const clipWidth = getClipWidth({ clipData: clip.data, timeWidth });
        const widthToGroupWidthRatio = clipWidth / clipGroupWidth;
        const headTrimPortion = headTrimAmount * widthToGroupWidthRatio;
        const tailTrimPortion = tailTrimAmount * widthToGroupWidthRatio;

        const newClipData = trimClipData({
            headTrimAmount: headTrimPortion,
            tailTrimAmount: tailTrimPortion,
            clipData: clip.data,
            trimMode,
            timeWidth,
        });

        newClipsById[clip.clipId] = {
            ...clip,
            position: newPostion,
            data: newClipData,
        };
    });

    trimmedClipData = { ...clipData, clipsById: newClipsById };

    return trimmedClipData;
}
