import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.scss';

export default function ProgressBar(props) {
    const { className, label, percent, isHidePercent, ...attributes } = props;
    const roundedPercent = Math.floor(percent);

    return (
        <div className={classnames('ProgressBar', className)} {...attributes}>
            {label && <div>{label}</div>}
            <div className='ProgressBar__markerWrapper'>
                <div className='ProgressBar__marker' style={{ width: `${roundedPercent}%` }} />
            </div>
            {!isHidePercent && <div className='ProgressBar__percent'>{roundedPercent}%</div>}
        </div>
    );
}

ProgressBar.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    isHidePercent: PropTypes.bool,
    percent: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
    className: '',
    label: '',
    isHidePercent: false,
};
