import getChildById from 'src/pixi/utils/getChildById';
import destroyFoTextureDisplayObject from 'src/utils/businessLogic/foTexture/destroyFoTextureDisplayObject';

export default function destroyFoTextureCompDisplayObject({ foTextureComp, displayObject }) {
    if (!displayObject) {
        return;
    }

    foTextureComp.foTextures.forEach((foTexture) => {
        const foTextureDisplayObject = getChildById({
            parent: displayObject,
            id: foTexture.id,
        });
        destroyFoTextureDisplayObject(foTextureDisplayObject);
    });

    displayObject.destroy();
}
