import { useCallback, useRef } from 'react';
import { useDrag } from 'react-dnd';

import dndTypes from 'src/components/views/SceneEditor/constants/dndTypes';
import getGrabPositionOffsetFromItemsLeftEdge from 'src/utils/dndUtils/getGrabPositionOffsetFromItemsLeftEdge';
import calcTimeIndicatorHeadWidth from 'src/components/views/SceneEditor/Timeline/TimeTracker/utils/calcTimeIndicatorHeadWidth';
import useIsDrawing from 'src/components/shared/hooks/useIsDrawing';
import calcUnzoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcUnzoomedValue';

const timeIndicatorHeadWidth = calcTimeIndicatorHeadWidth();
const centerOffset = timeIndicatorHeadWidth / 2;

export default function useTimeIndicatorDrag({
    timeWidth,
    rulerNodeRef,
    jumpAppToTime,
    handlePauseButtonClick,
    zoomLevel,
}) {
    const grabOffsetRef = useRef(null);
    const lastPointerPositionRef = useRef(null);

    const { isDrawingRef, setIsDrawing } = useIsDrawing();

    const [, dragRef, previewRef] = useDrag({
        item: { type: dndTypes.TIME_INDICATOR },
        begin(monitor) {
            grabOffsetRef.current = getGrabPositionOffsetFromItemsLeftEdge(monitor);
        },
        end() {
            grabOffsetRef.current = null;
            lastPointerPositionRef.current = null;
        },
    });

    const handleDrag = useCallback(
        function _handleTimeIndicatorDrag(event) {
            const lastPointerPosition = lastPointerPositionRef.current;

            const pointerPosRelToViewport = event.clientX;
            const isPointerPositionSame = lastPointerPosition === pointerPosRelToViewport;

            const isDrawing = isDrawingRef.current;

            if (!pointerPosRelToViewport || isPointerPositionSame || isDrawing) {
                return;
            }

            lastPointerPositionRef.current = pointerPosRelToViewport;
            setIsDrawing();

            const grabOffset = grabOffsetRef.current;
            const timeIndicatorPosRelToViewport = event.clientX - grabOffset;

            const rulerNode = rulerNodeRef.current;
            const rulerNodePosRelToViewport = Math.round(rulerNode.getBoundingClientRect().left);

            const timeIndicatorPosition = timeIndicatorPosRelToViewport - rulerNodePosRelToViewport;

            const centerPosition = timeIndicatorPosition + centerOffset;
            const unzoomedCenteredPosition = calcUnzoomedValue({
                value: centerPosition,
                zoomLevel,
            });
            const dragTime = unzoomedCenteredPosition / timeWidth;
            const time = Math.max(dragTime, 0);
            handlePauseButtonClick();
            jumpAppToTime(time);
        },
        [
            rulerNodeRef,
            grabOffsetRef,
            timeWidth,
            jumpAppToTime,
            handlePauseButtonClick,
            setIsDrawing,
            isDrawingRef,
            zoomLevel,
        ],
    );

    return { handleDrag, dragRef, previewRef };
}
