export const AGGRESSIVE = 'Aggressive';
export const SUBMISSIVE = 'Submissive';
export const INSERT_COMPACT_SHIFT = 'Insert Compact Shift';
export const INSERT_SHIFT = 'Insert Shift';

export default Object.freeze({
    AGGRESSIVE,
    SUBMISSIVE,
    INSERT_COMPACT_SHIFT,
    INSERT_SHIFT,
});
