import pixiStore from 'src/pixi/pixiStore';
import getChildById from 'src/pixi/utils/getChildById';
import expressionsByAnimation from 'src/utils/businessLogic/expressions/constants/expressionsByAnimation';
import buildAnimationsByExpression from 'src/utils/businessLogic/expressions/buildAnimationsByExpression';

const animationsByExpressionByFoSpineAssetId = {};

export default function getAnimationsByExpressionForFoSpineAsset({
    foSpineAssetId,
    expressionsByAnimation: receivedExpressionsByAnimation = expressionsByAnimation,
}) {
    if (animationsByExpressionByFoSpineAssetId[foSpineAssetId]) {
        return animationsByExpressionByFoSpineAssetId[foSpineAssetId];
    }

    const app = pixiStore.pixiAppManager.getPixiApp();
    const spineAssetInstance = getChildById({
        parent: app.stage,
        id: foSpineAssetId,
    });

    if (!spineAssetInstance) {
        console.error(`No spine asset is found on the app with foSpineAssetId ${foSpineAssetId}`);
        return {};
    }

    const filteredExpressionsByAnimation = {};
    Object.keys(receivedExpressionsByAnimation).forEach((animationName) => {
        const isSpineAssetHaveAnimation = spineAssetInstance.spineData.animations.find(
            (animation) => {
                return animation.name === animationName;
            },
        );
        if (isSpineAssetHaveAnimation) {
            filteredExpressionsByAnimation[animationName] =
                receivedExpressionsByAnimation[animationName];
        }
    });
    const animationsByExpression = buildAnimationsByExpression(filteredExpressionsByAnimation);

    animationsByExpressionByFoSpineAssetId[foSpineAssetId] = animationsByExpression;

    return animationsByExpression;
}
