import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Input from 'src/components/shared/inputFactory/Input';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import TrashCanIcon from 'src/components/shared/iconFactory/TrashCanIcon';
import { influenceLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';

import styles from './styles.module.scss';

export default function InfluenceInputSet(props) {
    const { className, influence, changeInfluenceAmount, deleteInfluence } = props;
    const { expression } = influence;

    const handleAmountChange = useCallback(
        function _handleAmountChange(event) {
            changeInfluenceAmount({
                expression,
                amount: Number(event.target.value),
            });
        },
        [changeInfluenceAmount, expression],
    );

    const handleDeleteButtonClick = useCallback(
        function _handleAmountChange() {
            deleteInfluence({ expression });
        },
        [deleteInfluence, expression],
    );

    return (
        <div className={classnames(styles.InfluenceInputSet, className)}>
            <label htmlFor={influence.expression}>
                {influence.expression}: {influence.amount}
            </label>
            <Input
                id={influence.expression}
                type='range'
                min='1'
                max='100'
                value={influence.amount}
                onChange={handleAmountChange}
            />
            <ToolButton onClick={handleDeleteButtonClick}>
                <TrashCanIcon />
            </ToolButton>
        </div>
    );
}

InfluenceInputSet.propTypes = {
    className: PropTypes.string,
    influence: influenceLike.isRequired,
    changeInfluenceAmount: PropTypes.func.isRequired,
    deleteInfluence: PropTypes.func.isRequired,
};

InfluenceInputSet.defaultProps = {
    className: '',
};
