import React, { useContext } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';
import AudioWaveform from 'src/components/shared/AudioWaveform';
import withRouter from 'src/reduxStore/utils/withRouter';

import styles from './styles.module.scss';

function AudioTrack(props) {
    const { className, width, zoomLevel } = props;

    const { waveformData } = useContext(SceneEditorContext);
    const zoomedWidth = zoomLevel * width;
    const style = zoomLevel ? { width: zoomedWidth } : {};

    return (
        <div className={classnames(styles.AudioTrack, className)}>
            <AudioWaveform waveformData={waveformData} style={style} />
        </div>
    );
}

AudioTrack.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
    zoomLevel: PropTypes.number.isRequired,
};

AudioTrack.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const {
        audioSelector: { audioTracks },
        timeline: { timeWidth, zoomLevel },
    } = state;

    const { sceneId: selectedSceneId } = ownProps.router.params;
    const { selectedAudioTrackId } = state.sceneSelector.scenes.byId[selectedSceneId];

    const selectedAudioTrack = audioTracks.find(
        (audioTrack) => audioTrack.id === selectedAudioTrackId,
    );

    let width = 0;
    if (selectedAudioTrack) {
        width = selectedAudioTrack.duration * timeWidth;
    }

    return { width, zoomLevel };
}

export default withRouter(connect(mapState)(AudioTrack));
