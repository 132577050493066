import getOriginalSize from 'src/pixi/utils/getOriginalSize';
import getLocalSize from 'src/pixi/utils/getLocalSize';
import getGlobalSize from 'src/pixi/utils/getGlobalSize';

export default function buildScale({ refDisplayObject, sizeRatio, displayObject }) {
    const refDisplayObjectGlobalSize = getGlobalSize(refDisplayObject);

    const expectedGlobalSize = {
        width: refDisplayObjectGlobalSize.width * sizeRatio.x,
        height: refDisplayObjectGlobalSize.height * sizeRatio.y,
    };
    const expectedLocalSize = getLocalSize({
        displayObject,
        globalSize: expectedGlobalSize,
    });
    const originalSize = getOriginalSize(displayObject);
    const scale = {
        x: expectedLocalSize.width / originalSize.width,
        y: expectedLocalSize.height / originalSize.height,
    };

    return scale;
}
