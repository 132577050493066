import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import TrackController from 'src/components/views/SceneEditor/Timeline/TrackControllers/TrackController';
import { audioTrackLike } from 'src/components/views/AudioSelector/audioSelectorSlice/propTypes';

import AudioTrackControllerButtonWithMenu from './AudioTrackControllerButtonWithMenu';

import styles from './styles.module.scss';

export default function AudioTrackController(props) {
    const { className, audioTrack } = props;

    return (
        <TrackController
            className={classnames(styles.AudioTrackController, className)}
            name={audioTrack.label}
        >
            <AudioTrackControllerButtonWithMenu audioTrack={audioTrack} />
        </TrackController>
    );
}

AudioTrackController.propTypes = {
    className: PropTypes.string,
    audioTrack: audioTrackLike.isRequired,
};

AudioTrackController.defaultProps = {
    className: '',
};
