import PropTypes from 'prop-types';
import { foTextureLike } from 'src/components/views/TextureRouteManager/TextureEditor/textureEditorSlice/propTypes';

export const foTexturesLike = PropTypes.arrayOf(foTextureLike);

export const linkedFoSpineAssetIdsLike = PropTypes.arrayOf(PropTypes.string);

export const foTextureCompLike = PropTypes.shape({
    bakedUrl: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    foTextures: foTexturesLike.isRequired,
    linkedFoSpineAssetIds: linkedFoSpineAssetIdsLike.isRequired,
});

export const foTextureCompsByIdLike = PropTypes.objectOf(foTextureCompLike);
export const foTextureCompsLike = PropTypes.shape({
    byId: foTextureCompsByIdLike.isRequired,
    allIds: PropTypes.arrayOf(PropTypes.string).isRequired,
});
