import React from 'react';
import classnames from 'classnames';

import PropTypes from 'prop-types';
import { animationTreeNodesLike } from 'src/components/views/SceneEditor/Bins/Animations/animationsSlice/propTypes';

import AnimationListItem from './AnimationListItem';

import styles from './styles.module.scss';

function AnimationList(props) {
    const { className, animationTreeNodes } = props;

    return (
        <ul className={classnames(styles.AnimationList, className)}>
            {animationTreeNodes.map((animationTreeNode) => {
                return (
                    <AnimationListItem
                        className={styles.AnimationList__animationListItem}
                        key={animationTreeNode.title}
                        animationTreeNode={animationTreeNode}
                    />
                );
            })}
        </ul>
    );
}

AnimationList.propTypes = {
    className: PropTypes.string,
    animationTreeNodes: animationTreeNodesLike.isRequired,
};

AnimationList.defaultProps = {
    className: '',
};

export default AnimationList;
