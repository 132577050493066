/**
 * If slotName is provided, will use the bone that slot is linked to,
 * otherwise will use the boneName.
 * */
export default function hackChildSlots({
    spineAssetInstance,
    slotName,
    boneName: receivedBoneName,
    texture,
}) {
    if (!receivedBoneName && !slotName) {
        throw new Error('Either slotName for boneName should be provided.');
    }

    let boneName = receivedBoneName;
    if (slotName) {
        boneName = spineAssetInstance.skeleton.findSlot(slotName).bone.data.name;
    }
    const bone = spineAssetInstance.skeleton.findBone(boneName);

    bone.children.forEach((childBone) => {
        const childSlots = spineAssetInstance.skeleton.slots.filter((currentSlot) => {
            return currentSlot.bone === childBone;
        });

        childSlots.forEach((childSlot) => {
            const slotName = childSlot.data.name;
            const attachment = childSlot.getAttachment();

            if (childSlot && attachment) {
                spineAssetInstance.hackTextureBySlotName(slotName, texture);
            }
        });

        hackChildSlots({ spineAssetInstance, boneName: childBone.data.name, texture });
    });
}
