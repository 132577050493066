import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

export default function Spinner(props) {
    const { className } = props;

    return (
        <div className={classnames(className, 'Spinner')}>
            <div className='cubeWrapper'>
                <div className='cube cube1' />
                <div className='cube cube2' />
                <div className='cube cube3' />
                <div className='cube cube4' />
            </div>
        </div>
    );
}

Spinner.propTypes = {
    className: PropTypes.string,
};

Spinner.defaultProps = {
    className: '',
};
