import {
    DRAW_MASK,
    MOVE_MASK_CONTROLS,
    ADD_POINT,
    DELETE_POINT,
    TOGGLE_KNOB,
} from 'src/pixi/tools/MaskTool/constants/maskToolTypes';

export const OUTLINE = 'outline';
export const MAIN_KNOB = 'main knob';
export const TANGENT_KNOB = 'tangent knob';
export const BG_LAYER = 'background ghost layer';
export const TARGET = 'target';

export const MASK_TOOL_CONTROLS_CURSOR_MAP = Object.freeze({
    [DRAW_MASK]: {
        [OUTLINE]: 'pointer',
        [MAIN_KNOB]: 'pointer',
        [TANGENT_KNOB]: 'pointer',
        [BG_LAYER]: 'crosshair',
        [TARGET]: 'crosshair',
    },
    [MOVE_MASK_CONTROLS]: {
        [OUTLINE]: 'pointer',
        [MAIN_KNOB]: 'pointer',
        [TANGENT_KNOB]: 'pointer',
        [BG_LAYER]: 'default',
        [TARGET]: 'default',
    },
    [ADD_POINT]: {
        [OUTLINE]: 'crosshair',
        [MAIN_KNOB]: 'pointer',
        [TANGENT_KNOB]: 'pointer',
        [BG_LAYER]: 'default',
        [TARGET]: 'default',
    },
    [DELETE_POINT]: {
        [OUTLINE]: 'default',
        [MAIN_KNOB]: 'pointer',
        [TANGENT_KNOB]: 'pointer',
        [BG_LAYER]: 'default',
        [TARGET]: 'default',
    },
    [TOGGLE_KNOB]: {
        [OUTLINE]: 'default',
        [MAIN_KNOB]: 'pointer',
        [TANGENT_KNOB]: 'pointer',
        [BG_LAYER]: 'default',
        [TARGET]: 'default',
    },
});
