import { useCallback } from 'react';

export default function useSetMonitorOverlaysVisibility({
    monitorOverlaysDispalyObjsRef,
    jumpAppToTime,
    pixiStore,
    currentTimeManager,
}) {
    const setMonitorOverlaysVisibility = useCallback(
        function _setMonitorOverlaysVisibility(isVisible) {
            const { monitorOverlaysContainer } = monitorOverlaysDispalyObjsRef.current;

            monitorOverlaysContainer.visible = isVisible;
            const { ticker } = pixiStore.pixiAppManager.getPixiApp();

            if (!ticker.started) {
                const { currentTime } = currentTimeManager;
                jumpAppToTime(currentTime, { isPreparingForPlayback: false });
            }
        },
        [monitorOverlaysDispalyObjsRef, jumpAppToTime, currentTimeManager, pixiStore],
    );

    return setMonitorOverlaysVisibility;
}
