export default function buildIdleAnimationNamesByBodyPart(expressionsByAnimation) {
    const idleAnimationsByBodyPart = {};

    if (!expressionsByAnimation) {
        return idleAnimationsByBodyPart;
    }

    Object.keys(expressionsByAnimation).forEach((animationName) => {
        const { isIdle, affectedBodyParts } = expressionsByAnimation[animationName];

        if (!isIdle) {
            return;
        }

        if (affectedBodyParts.length > 1) {
            const bodyPartsStr = affectedBodyParts.join(' ');
            console.warn(
                `Idle animation ${animationName} is applied to multiple body parts: ${bodyPartsStr}`,
            );
        }
        const bodyPart = affectedBodyParts[0];
        idleAnimationsByBodyPart[bodyPart] = animationName;
    });

    return idleAnimationsByBodyPart;
}
