const DISTANCE_VALUE = 5;

export default function buildDistanceBetweenTargetAndContent({
    percentOnTargetElem,
    percentOnContentElem,
}) {
    const percentDiff = {
        x: percentOnTargetElem.x - percentOnContentElem.x,
        y: percentOnTargetElem.y - percentOnContentElem.y,
    };
    const sign = {
        x: Math.sign(Math.floor(percentDiff.x) * Math.floor(Math.abs(percentDiff.x))),
        y: Math.sign(Math.floor(percentDiff.y) * Math.floor(Math.abs(percentDiff.y))),
    };

    const distance = {
        x: sign.x * DISTANCE_VALUE,
        y: sign.y * DISTANCE_VALUE,
    };

    return distance;
}
