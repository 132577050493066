import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import generateTrackLayersFromExpressions from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayersFromExpressions';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import reduxStore from 'src/reduxStore';
import ensureHasMinOneLayer from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/AssetTrackControllerButtonWithMenu/hooks/useHandleGenerateClipsFromExpressionsButtonClick/ensureHasMinOneLayer';

import getExistingLayersNotGeneratedFromExpressions from './getExistingLayersNotGeneratedFromExpressions';
import moveSpeechTrackLayerToTop from './moveSpeechTrackLayerToTop';

export default function useHandleGenerateClipsFromExpressionsButtonClick({
    foSpineAssetId,
    setTrackLayers,
    trackId,
    expressionTimelineKeys,
}) {
    const { sceneId: selectedSceneId } = useParams();

    return useCallback(
        function _handleGenerateClipsFromExpressionsButtonClick() {
            const reduxState = reduxStore.getState();
            const { timeWidth } = reduxState.timeline;

            const {
                layersById: generatedLayersById,
                allLayerIds: allGeneratedLayerIds,
            } = generateTrackLayersFromExpressions({
                foSpineAssetId,
                timeWidth,
                expressionTimelineKeys,
            });

            const {
                layersById: filteredLayersById,
                allIds: allFilteredLayerIds,
            } = getExistingLayersNotGeneratedFromExpressions({
                isSkipEmptyLayers: true,
                selectedSceneId,
            });

            const layersById = {
                ...filteredLayersById,
                ...generatedLayersById,
            };
            const allLayerIds = [...allFilteredLayerIds, ...allGeneratedLayerIds];

            moveSpeechTrackLayerToTop({ allLayerIds, layersById });

            ensureHasMinOneLayer({ allLayerIds, layersById });

            setTrackLayers({
                sceneId: selectedSceneId,
                trackId,
                layersById,
                allLayerIds,
            });

            updateTracksOnBackend({ selectedSceneId });
        },
        [setTrackLayers, trackId, foSpineAssetId, expressionTimelineKeys, selectedSceneId],
    );
}
