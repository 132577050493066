import { useCallback, useEffect, useRef } from 'react';

import useIsDrawing from 'src/components/shared/hooks/useIsDrawing';
import buildWaveform from 'src/components/shared/AudioWaveform/utils/buildWaveform';

export default function useWaveformResize({
    canvasRef,
    waveformData,
    paddingPercent = 0.1,
    lineDensityPerTenPixels = 4,
}) {
    const { isDrawingRef, setIsDrawing } = useIsDrawing();

    const waveformDataRef = useRef();
    waveformDataRef.current = waveformData;
    const handleCanvasResize = useCallback(
        function _handleCanvasResize() {
            const isDrawing = isDrawingRef.current;
            const waveformData = waveformDataRef.current;

            if (isDrawing || !waveformData) {
                return;
            }
            setIsDrawing();

            const canvas = canvasRef.current;
            buildWaveform({
                waveformData,
                canvas,
                paddingPercent,
                lineDensityPerTenPixels,
            });
        },
        [
            canvasRef,
            waveformDataRef,
            isDrawingRef,
            setIsDrawing,
            paddingPercent,
            lineDensityPerTenPixels,
        ],
    );

    useEffect(
        function _setupCanvasResize() {
            window.addEventListener('resize', handleCanvasResize);

            return function _cleanupCanvasResize() {
                window.removeEventListener('resize', handleCanvasResize);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
}
