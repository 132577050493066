export const BODY = 'body';
export const BODY_SHOULDERS = 'body/shoulders';
export const BODY_WINGS = 'body/wings';
export const ARM_RIGHT = 'arm/right';
export const ARM_LEFT = 'arm/left';
export const HAND_RIGHT = 'hand/right';
export const HAND_LEFT = 'hand/left';
export const HEAD = 'head';
export const HEAD_HAIR = 'head/hair';
export const FACE = 'face';
export const FACE_MOUTH = 'face/mouth';
export const FACE_EYES = 'face/eyes';
export const FACE_EYES_PUPILS = 'face/eyes/pupils';

export default Object.freeze({
    BODY,
    BODY_WINGS,
    ARM_RIGHT,
    ARM_LEFT,
    HAND_RIGHT,
    HAND_LEFT,
    HEAD,
    HEAD_HAIR,
    FACE,
    FACE_MOUTH,
    FACE_EYES,
    FACE_EYES_PUPILS,
    BODY_SHOULDERS,
});
