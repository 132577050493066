import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import calcDeleteAnimationClipSelection from 'src/components/views/SceneEditor/Timeline/utils/calcDeleteAnimationClipSelection';
import calcDeleteExpressionClipSelection from 'src/components/views/SceneEditor/Timeline/utils/calcDeleteExpressionClipSelection';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';

export default function useDeletePress({
    clipSelectionByTrackId,
    tracks,
    setTracks,
    assetTrackMode,
}) {
    const { sceneId: selectedSceneId } = useParams();

    const tracksById = tracks.byId;
    const deleteClipSelection = useCallback(
        function _deleteClipSelection() {
            let tracksByIdAfterDeletion = tracksById;

            if (assetTrackMode === assetTrackModes.ANIMATION) {
                tracksByIdAfterDeletion = calcDeleteAnimationClipSelection({
                    clipSelectionByTrackId,
                    tracksById,
                });
            } else if (assetTrackMode === assetTrackModes.EXPRESSION) {
                tracksByIdAfterDeletion = calcDeleteExpressionClipSelection({
                    clipSelectionByTrackId,
                    tracksById,
                });
            }

            setTracks({
                sceneId: selectedSceneId,
                tracksById: tracksByIdAfterDeletion,
            });
            updateTracksOnBackend({ selectedSceneId });
        },
        [clipSelectionByTrackId, tracksById, setTracks, assetTrackMode, selectedSceneId],
    );

    const handleDeletePress = useCallback(
        (event) => {
            if (
                event.target.nodeName === 'BODY' &&
                (event.key === 'Delete' || event.key === 'Backspace')
            ) {
                deleteClipSelection();
            }
        },
        [deleteClipSelection],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleDeletePress);
        return () => {
            document.removeEventListener('keydown', handleDeletePress);
        };
    }, [handleDeletePress]);
}
