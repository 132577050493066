import getMaskedDisplayObjectContent from 'src/pixi/tools/MaskTool/utils/getMaskedDisplayObjectContent';
import makeMaskFill from './makeMaskFill';
import drawBezierFill from './drawBezierFill';

export default function applyBezierMaskToDisplayObject({ bezierPoints, displayObject }) {
    const isMaskEmpty = !bezierPoints.length;
    if (isMaskEmpty) {
        return;
    }
    const content = getMaskedDisplayObjectContent(displayObject);
    const maskFill = content.mask || makeMaskFill();
    content.mask = maskFill;

    displayObject.addChild(maskFill);

    drawBezierFill({
        points: bezierPoints,
        graphics: maskFill,
    });
}
