import { DEFAULT_SELECTED_TOOL_TYPE } from 'src/components/views/TextureRouteManager/TextureEditor/constants/toolTypes';

const textureToolsRef = {
    current: {
        transformTool: null,
        maskTool: null,
        selectedToolType: DEFAULT_SELECTED_TOOL_TYPE,
    },
};

export function getTextureTools() {
    return textureToolsRef.current;
}

export function setTextureTools(textureTools) {
    textureToolsRef.current = { ...textureToolsRef.current, ...textureTools };
}

export function setTextureToolsToDefault() {
    const { transformTool, maskTool } = textureToolsRef;
    if (transformTool) {
        transformTool.destroy();
    }

    if (maskTool) {
        maskTool.destroy();
    }

    setTextureTools({
        transformTool: null,
        maskTool: null,
        selectedToolType: DEFAULT_SELECTED_TOOL_TYPE,
    });
}
