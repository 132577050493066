import React, { useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import calcTimeIndicatorPositions from 'src/components/views/SceneEditor/Timeline/TimeTracker/utils/calcTimeIndicatorPositions';
import refLike from 'src/components/shared/propTypes/refLike';

import TimeRuler from './TimeRuler';
import TimeIndicatorHeadDnD from './TimeIndicatorHeadDnD';

import styles from './styles.module.scss';

function TimeTracker(props) {
    const { className, timeWidth, zoomLevel, currentTime, nodeRef } = props;

    const rulerNodeRef = useRef(null);
    const { timeIndicatorHeadPosition, timeIndicatorLinePosition } = calcTimeIndicatorPositions({
        currentTime,
        timeWidth,
        zoomLevel,
    });

    return (
        <div className={classnames(styles.TimeTracker, className)} ref={nodeRef}>
            <TimeRuler timeWidth={timeWidth} rulerNodeRef={rulerNodeRef} zoomLevel={zoomLevel} />
            <TimeIndicatorHeadDnD
                className={styles.TimeTracker__timeIndicatorHeadDnD}
                position={timeIndicatorHeadPosition}
                timeWidth={timeWidth}
                rulerNodeRef={rulerNodeRef}
                zoomLevel={zoomLevel}
            />
            <div
                className={styles.TimeTracker__timeIndicatorLine}
                style={{
                    transform: `translate(${timeIndicatorLinePosition}px,0)`,
                }}
            />
        </div>
    );
}

TimeTracker.propTypes = {
    className: PropTypes.string,
    timeWidth: PropTypes.number.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    currentTime: PropTypes.number.isRequired,
    nodeRef: refLike.isRequired,
};

TimeTracker.defaultProps = {
    className: '',
};

function mapState(state) {
    const { timeWidth, zoomLevel } = state.timeline;

    return {
        timeWidth,
        zoomLevel,
    };
}

export default connect(mapState)(TimeTracker);
