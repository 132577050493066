import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { animationLike } from 'src/components/views/SceneEditor/Bins/Animations/animationsSlice/propTypes';

import styles from './styles.module.scss';

export default function Animation(props) {
    const { animation, title, className } = props;

    return (
        <div className={classnames(styles.Animation, className)} title={title || animation.title}>
            <span className={styles.Animation__content}>{title || animation.title}</span>
        </div>
    );
}

Animation.propTypes = {
    animation: animationLike.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
};

Animation.defaultProps = {
    className: '',
    title: '',
};
