import clipTypes from 'src/components/constants/clipTypes';

import trimSingleClipData from './trimSingleClipData';
import trimClipGroupData from './trimClipGroupData';

/**
 * @headTrimAmount > 0 => trim right, otherwise, trim left
 * @tailTrimAmount > 0 => trim right, otherwise, trim left
 * @clipData
 * @trimMode
 * @timeWidth
 */
export default function trimClipData({
    headTrimAmount = 0,
    tailTrimAmount = 0,
    clipData,
    trimMode,
    timeWidth,
}) {
    if (!headTrimAmount && !tailTrimAmount) {
        return clipData;
    }
    if (!timeWidth) {
        console.error(`can't trim a clip's data without a valid timeWidth. 
        Received: ${timeWidth}`);
    }

    let trimmedClipData = clipData;
    if (clipData.type === clipTypes.CLIP_SINGLE) {
        trimmedClipData = trimSingleClipData({
            headTrimAmount,
            tailTrimAmount,
            clipData,
            trimMode,
            timeWidth,
        });
    } else if (clipData.type === clipTypes.CLIP_GROUP) {
        trimmedClipData = trimClipGroupData({
            headTrimAmount,
            tailTrimAmount,
            clipData,
            trimMode,
            timeWidth,
        });
    }

    return trimmedClipData;
}
