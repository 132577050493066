import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EditableTextBox from 'src/components/shared/EditableTextBox';

import styles from './styles.module.scss';

export default function FoTextureCompName(props) {
    const { className, foTextureCompName, setFoTextureCompName } = props;

    const [isEditing, setIsEditing] = useState(false);

    const handleNameInputChange = useCallback(
        function _handleNameInputChange(event) {
            const newName = event.target.value;

            setFoTextureCompName(newName);
        },
        [setFoTextureCompName],
    );

    const handleLabelClick = useCallback(
        function _handleLabelClick() {
            setIsEditing(true);
        },
        [setIsEditing],
    );

    const foTextureCompNameNodeRef = useRef();

    useEffect(function _disableNameEditModeChange() {
        function handleDocumentClickToExitEditMode(event) {
            const isFoTextureCompNameMounted = !!foTextureCompNameNodeRef.current;
            const isClickedOutside =
                isFoTextureCompNameMounted &&
                !foTextureCompNameNodeRef.current.contains(event.target);
            const isTargetNodeInDom = document.body.contains(event.target);
            if (isTargetNodeInDom && isClickedOutside) {
                setIsEditing(false);
            }
        }

        document.addEventListener('click', handleDocumentClickToExitEditMode);

        return function _cleanUpDisableNameEditModeChange() {
            document.removeEventListener('click', handleDocumentClickToExitEditMode);
        };
    }, []);

    return (
        <EditableTextBox
            className={classnames(styles.FoTextureCompName, className)}
            isEditing={isEditing}
            inputProps={{
                onChange: handleNameInputChange,
                value: foTextureCompName,
            }}
            labelProps={{
                onClick: handleLabelClick,
            }}
            nodeRef={foTextureCompNameNodeRef}
        />
    );
}

FoTextureCompName.propTypes = {
    className: PropTypes.string,
    setFoTextureCompName: PropTypes.func.isRequired,
};

FoTextureCompName.defaultProps = {
    className: '',
};
