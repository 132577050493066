import React, { useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { tracksLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import { audioTrackLike } from 'src/components/views/AudioSelector/audioSelectorSlice/propTypes';
import { graphemeToAnimationMapLike } from 'src/components/views/AssetSelector/assetSelectorSlice/propTypes';
import { setTrackLayer } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import ButtonWithMenu from 'src/components/shared/ButtonWithMenu';
import ToolIcon from 'src/components/shared/iconFactory/ToolIcon';
import getGraphemeToAnimationMapByFoSpineAssetId from 'src/reduxStore/utils/getGraphemeToAnimationMapByFoSpineAssetId';
import withRouter from 'src/reduxStore/utils/withRouter';
import Modal from 'src/components/shared/Modal';

import TranscriptWritingForm from './TranscriptWritingForm';
import buildTranscriptApplicationMenuConfig from './buildTranscriptApplicationMenuConfig';
import buildTranscriptWritingMenuConfig from './buildTranscriptWritingMenuConfig';

import styles from './styles.module.scss';

function AudioTrackControllerButtonWithMenu(props) {
    const {
        tracks,
        className,
        audioTrack,
        timeWidth,
        setTrackLayer,
        graphemeToAnimationMapByFoSpineAssetId,
        selectedSceneId,
    } = props;

    const infoForWritingTranscriptRef = useRef({});

    const [isModalShown, setIsModalShown] = useState(false);
    const handleWriteTranscriptClick = useCallback(
        function _handleWriteTranscriptClick(event, itemData) {
            setIsModalShown(true);
            infoForWritingTranscriptRef.current = itemData.infoForWritingTranscript;
        },
        [setIsModalShown],
    );
    const handleClickOutsideModal = useCallback(
        function _handleClickOutsideModal() {
            setIsModalShown(false);
        },
        [setIsModalShown],
    );

    const transcriptWritingMenuConfig = buildTranscriptWritingMenuConfig({
        tracks,
        audioTrack,
        graphemeToAnimationMapByFoSpineAssetId,
        handleWriteTranscriptClick,
    });
    const listData = [transcriptWritingMenuConfig];

    if (audioTrack.foTranscriptWords) {
        const transcriptApplicationMenuConfig = buildTranscriptApplicationMenuConfig({
            tracks,
            audioTrack,
            graphemeToAnimationMapByFoSpineAssetId,
            setTrackLayer,
            selectedSceneId,
            timeWidth,
        });

        listData.push(transcriptApplicationMenuConfig);
    }

    return (
        <>
            <ButtonWithMenu
                buttonProps={{
                    className: classnames(
                        className,
                        'ToolContainer__tool',
                        styles.AudioTrackControllerButtonWithMenu,
                    ),
                    children: <ToolIcon />,
                }}
                nestedListProps={{
                    listData,
                }}
            />
            {isModalShown && (
                <Modal onClickOutside={handleClickOutsideModal} isLayoverVisible>
                    <TranscriptWritingForm
                        infoForWritingTranscriptRef={infoForWritingTranscriptRef}
                        setTrackLayer={setTrackLayer}
                        selectedSceneId={selectedSceneId}
                        setIsModalShown={setIsModalShown}
                    />
                </Modal>
            )}
        </>
    );
}

AudioTrackControllerButtonWithMenu.propTypes = {
    className: PropTypes.string,
    selectedSceneId: PropTypes.string.isRequired,
    audioTrack: audioTrackLike.isRequired,
    setTrackLayer: PropTypes.func.isRequired,
    tracks: tracksLike.isRequired,
    timeWidth: PropTypes.number.isRequired,
    graphemeToAnimationMapByFoSpineAssetId: PropTypes.objectOf(graphemeToAnimationMapLike)
        .isRequired,
};

AudioTrackControllerButtonWithMenu.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const {
        assetSelector: { foSpineAssets },
        timeline: { timeWidth },
    } = state;
    const { sceneId: selectedSceneId } = ownProps.router.params;

    const { tracks } = state.sceneSelector.scenes.byId[selectedSceneId];

    const graphemeToAnimationMapByFoSpineAssetId = getGraphemeToAnimationMapByFoSpineAssetId({
        tracks,
        foSpineAssets,
    });

    return { tracks, timeWidth, graphemeToAnimationMapByFoSpineAssetId, selectedSceneId };
}

export default withRouter(connect(mapState, { setTrackLayer })(AudioTrackControllerButtonWithMenu));
