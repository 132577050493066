import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ToolButton from 'src/components/shared/buttonFactory/ToolButton';
import Input from 'src/components/shared/inputFactory/Input';
import CheckIcon from 'src/components/shared/iconFactory/CheckIcon';

import styles from './styles.module.scss';

export default function GroupClipsDialogue(props) {
    const {
        className,
        toggleGroupNameDialogue,
        confirmNewGrouping,
        handleGroupNameChange,
        groupName,
        ...attributes
    } = props;

    return (
        <div className={classnames(styles.GroupClipsDialogue, className)} {...attributes}>
            <div className={styles.GroupClipsDialogue__instructions}>name the new clip group</div>
            <div className={styles.GroupClipsDialogue__inputSet}>
                <Input value={groupName} onChange={handleGroupNameChange} />
                <ToolButton onClick={confirmNewGrouping}>
                    <CheckIcon />
                </ToolButton>
            </div>
        </div>
    );
}

GroupClipsDialogue.propTypes = {
    className: PropTypes.string,
    toggleGroupNameDialogue: PropTypes.func.isRequired,
    confirmNewGrouping: PropTypes.func.isRequired,
    handleGroupNameChange: PropTypes.func.isRequired,
    groupName: PropTypes.string.isRequired,
};

GroupClipsDialogue.defaultProps = {
    className: '',
};
