import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MODES from 'src/utils/businessLogic/clipUtils/insertClip/constants/conflictModes';
import ToolContainer from 'src/components/shared/ToolContainer';
import ButtonWithMenu from 'src/components/shared/ButtonWithMenu';

import styles from './styles.module.scss';

export default function ConflictResolutionModes(props) {
    const { className, activeMode, setConflictResolution, ...attributes } = props;

    const listData = Object.values(MODES).map((mode) => {
        return {
            key: mode,
            content: mode,
            contentElemAttributes: {
                onClick() {
                    setConflictResolution(mode);
                },
            },
        };
    });

    return (
        <ToolContainer
            className={classnames(styles.ConflictResolutionModes, className)}
            isSubContainer
            {...attributes}
        >
            <ButtonWithMenu
                buttonProps={{
                    className: 'ToolContainer__tool',
                    children: activeMode,
                }}
                nestedListProps={{
                    listData,
                }}
            />
        </ToolContainer>
    );
}

ConflictResolutionModes.propTypes = {
    className: PropTypes.string,
    activeMode: PropTypes.oneOf([...Object.values(MODES)]).isRequired,
    setConflictResolution: PropTypes.func.isRequired,
};

ConflictResolutionModes.defaultProps = {
    className: '',
};
