import convertDispalyObjectToImage from 'src/pixi/utils/convertDispalyObjectToImage';

export default function captureFrame({
    frameTime,
    frameName,
    jumpAppToTime,
    app,
    displayFrame,
    ext,
    isDownloadFrame,
}) {
    return new Promise(function _executor(resolve) {
        setTimeout(function _makeCallAsync() {
            jumpAppToTime(frameTime);

            const imageFileProm = convertDispalyObjectToImage({
                fileName: frameName,
                renderer: app.renderer,
                displayObject: app.stage,
                displayFrame: displayFrame || app.screen,
                ext,
                isDownloadImage: isDownloadFrame,
            });

            return resolve(imageFileProm);
        });
    });
}
