import { isObject, isNumber } from 'lodash';

export default function assertPositionIsValid(position) {
    if (!isObject(position)) {
        throw new Error('position has to be an object, received: ', position);
    }
    if (!isNumber(position.x)) {
        throw new Error('position.x has to be a number, received: ', position.x);
    }
    if (!isNumber(position.y)) {
        throw new Error('position.y has to be a number, received: ', position.y);
    }
}
