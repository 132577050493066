import { Container } from 'pixi.js';

import { DEFAULT_MASK_TOOL_TYPE } from 'src/pixi/tools/MaskTool/constants/maskToolTypes';
import * as eventNames from 'src/pixi/tools/MaskTool/constants/eventNames';
import setMaskControlsCursors from 'src/pixi/tools/MaskTool/setMaskControlsCursors';
import {
    addEventHandler,
    removeEventHandler,
    runEventHandlers,
} from 'src/pixi/tools/utils/manageEventHandlers';

import makeBgGhostLayer from './makeBgGhostLayer';
import clearMaskTool from './clearMaskTool';
import applyMaskToolToTarget from './applyMaskToolToTarget';
import positionMaskToolControls from './positionMaskToolControls';
import setMaskToolType from './setMaskToolType';
import disableMaskOnMaskToolTarget from './disableMaskOnMaskToolTarget';
import enableMaskOnMaskToolTarget from './enableMaskOnMaskToolTarget';
import destroyMaskTool from './destroyMaskTool';

export default class MaskTool extends Container {
    constructor(props) {
        super();
        const { app } = props;

        const maskTool = this;
        maskTool.app = app;
        maskTool.type = DEFAULT_MASK_TOOL_TYPE;
        maskTool.eventHandlersByName = {};

        maskTool.visible = false;

        makeBgGhostLayer(maskTool);
    }

    addEventHandler(eventName, handler) {
        const maskTool = this;

        addEventHandler({ tool: maskTool, eventName, handler });

        return maskTool;
    }

    removeEventHandler(eventName, handler) {
        const maskTool = this;

        removeEventHandler({ tool: maskTool, eventName, handler });

        return maskTool;
    }

    applyTo(target) {
        const maskTool = this;
        applyMaskToolToTarget({ maskTool, target });

        this.runEventHandlersFor(eventNames.APPLY_TO);
    }

    clear() {
        const maskTool = this;
        clearMaskTool(maskTool);

        this.runEventHandlersFor(eventNames.CLEAR);
    }

    runEventHandlersFor(eventName) {
        const maskTool = this;

        runEventHandlers({ eventName, tool: maskTool });
    }

    positionControls() {
        const maskTool = this;
        positionMaskToolControls(maskTool);
    }

    getToolType() {
        const maskTool = this;
        return maskTool.type;
    }

    setToolType(newMaskToolType) {
        const maskTool = this;
        setMaskToolType({ maskTool, newMaskToolType });
    }

    getPoints() {
        const maskTool = this;
        return maskTool.target.maskData.points;
    }

    setPoints(points) {
        const maskTool = this;
        maskTool.target.maskData.points = points;
    }

    setMaskControlsCursors() {
        const maskTool = this;
        setMaskControlsCursors(maskTool);
    }

    disableMaskOnTarget() {
        const maskTool = this;
        disableMaskOnMaskToolTarget(maskTool);
    }

    enableMaskOnTarget() {
        const maskTool = this;
        enableMaskOnMaskToolTarget(maskTool);
    }

    destroy(options) {
        const maskTool = this;
        destroyMaskTool(maskTool);
        super.destroy(options);
    }
}
