const CRF = Object.freeze({
    min: 0, // lossless
    max: 51,
});
export default function convertVideoQualityToCrf(quality) {
    const cappedQuality = Math.max(0, Math.min(quality, 100));

    const crf = (1 - cappedQuality / 100) * (CRF.max - CRF.min) + CRF.min;
    return crf;
}
