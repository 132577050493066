import generateTrackLayer from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayer';

export default function mergeExpressionLayersIntoTrack({
    expressionLayers,
    allLayerIds,
    layersById,
}) {
    expressionLayers.forEach(function _mergeExpressionLayerIntoTrack(expressionLayer, index) {
        const existingTrackLayerId = allLayerIds[index];
        if (!existingTrackLayerId) {
            const trackLayer = generateTrackLayer({
                isGeneratedFromExpressions: true,
                ...expressionLayer,
            });
            layersById[trackLayer.trackLayerId] = trackLayer;
            allLayerIds.push(trackLayer.trackLayerId);
        } else {
            const existingTrackLayer = layersById[existingTrackLayerId];
            existingTrackLayer.clipsById = {
                ...existingTrackLayer.clipsById,
                ...expressionLayer.clipsById,
            };
        }
    });
}
