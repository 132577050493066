import calcDragItemPosRelToNode from 'src/utils/dndUtils/calcDragItemPosRelToNode';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';
import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';
import insertClip from 'src/utils/businessLogic/clipUtils/insertClip';
import calcUnzoomedValue from 'src/components/views/SceneEditor/Timeline/utils/zoomUtils/calcUnzoomedValue';

export default function addClip({
    clip,
    clipsById,
    monitor,
    editModes,
    timeWidth,
    trackNode,
    snapDistance,
    zoomLevel = 1,
}) {
    const { data } = clip || monitor.getItem();
    const initialWidth = getClipWidth({ clipData: data, timeWidth });
    const initialPosition = calcDragItemPosRelToNode({
        monitor,
        domNode: trackNode,
        width: initialWidth,
    });

    const initialUnzoomedPosition = calcUnzoomedValue({
        value: initialPosition,
        zoomLevel,
    });

    let newClip;
    if (clip) {
        newClip = {
            ...clip,
            position: initialUnzoomedPosition,
        };
    } else {
        newClip = generateClip({ data, position: initialUnzoomedPosition });
    }

    const { conflictResolution, trimMode } = editModes;
    const newClipsById = insertClip({
        newClip,
        clipsById,
        conflictResolution,
        snapDistance,
        timeWidth,
        trimMode,
    });

    return newClipsById;
}
