import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { trackLayersLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import { setTrackLayers } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import ButtonWithMenu from 'src/components/shared/ButtonWithMenu';
import ToolIcon from 'src/components/shared/iconFactory/ToolIcon';
import TrashCanIcon from 'src/components/shared/iconFactory/TrashCanIcon';
import useHandleDeleteButtonClick from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackAnimationLayerController/TrackAnimationLayerControllerButtonWithMenu/hooks/useHandleDeleteButtonClick';
import withRouter from 'src/reduxStore/utils/withRouter';

import styles from './styles.module.scss';

function TrackAnimationLayerControllerButtonWithMenu(props) {
    const { layers, trackId, trackLayerId, className, setTrackLayers } = props;

    const handleTrackAnimationLayerDeleteButtonClick = useHandleDeleteButtonClick({
        layers,
        trackId,
        trackLayerId,
        setTrackLayers,
    });

    const listData = [
        {
            key: 'delete_layer',
            content: (
                <>
                    <TrashCanIcon /> Delete
                </>
            ),
            contentElemAttributes: {
                onClick: handleTrackAnimationLayerDeleteButtonClick,
                className: classnames({
                    [styles['TrackAnimationLayerControllerButtonWithMenu__deleteButton--disabled']]:
                        layers.allIds.length <= 1,
                }),
            },
        },
    ];

    return (
        <ButtonWithMenu
            buttonProps={{
                className: classnames(
                    className,
                    'ToolContainer__tool',
                    styles.TrackAnimationLayerControllerButtonWithMenu,
                ),
                children: <ToolIcon />,
            }}
            nestedListProps={{
                listData,
            }}
        />
    );
}

TrackAnimationLayerControllerButtonWithMenu.propTypes = {
    className: PropTypes.string,
    layers: trackLayersLike.isRequired,
    trackId: PropTypes.string.isRequired,
    trackLayerId: PropTypes.string.isRequired,
    setTrackLayers: PropTypes.func.isRequired,
};

TrackAnimationLayerControllerButtonWithMenu.defaultProps = {
    className: '',
};

function mapState(state, ownProps) {
    const { sceneId: selectedSceneId } = ownProps.router.params;
    const selectedScene = state.sceneSelector.scenes.byId[selectedSceneId];

    let layers;
    if (selectedScene) {
        layers = selectedScene.tracks.byId[ownProps.trackId].layers;
    }

    return { layers };
}

export default withRouter(
    connect(mapState, { setTrackLayers })(TrackAnimationLayerControllerButtonWithMenu),
);
