import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { trackLayersLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import { assetTrackModeLike } from 'src/components/views/SceneEditor/Timeline/timelineSlice/propTypes';
import assetTrackModes from 'src/components/views/SceneEditor/Timeline/constants/assetTrackModes';

import TrackAnimationLayerController from './TrackAnimationLayerController';
import TrackExpressionLayerController from './TrackExpressionLayerController';

import styles from './styles.module.scss';

function TrackLayerControllers(props) {
    const { className, trackId, layers, activeAssetTrackMode } = props;

    let ChosenTrackLayerController = TrackAnimationLayerController;
    if (activeAssetTrackMode === assetTrackModes.EXPRESSION) {
        ChosenTrackLayerController = TrackExpressionLayerController;
    }

    return (
        <div className={classnames(styles.TrackLayerControllers, className)}>
            {[...layers.allIds].reverse().map((trackLayerId) => {
                return (
                    <ChosenTrackLayerController
                        key={trackLayerId}
                        className={styles.TrackLayerControllers__trackLayerController}
                        layer={layers.byId[trackLayerId]}
                        trackId={trackId}
                    />
                );
            })}
        </div>
    );
}

TrackLayerControllers.propTypes = {
    className: PropTypes.string,
    layers: trackLayersLike.isRequired,
    trackId: PropTypes.string.isRequired,
    activeAssetTrackMode: assetTrackModeLike.isRequired,
};

TrackLayerControllers.defaultProps = {
    className: '',
};

function mapState(state) {
    const { assetTrackMode: activeAssetTrackMode } = state.timeline;

    return { activeAssetTrackMode };
}

export default connect(mapState)(TrackLayerControllers);
