import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import updateTracksOnBackend from 'src/utils/backendUtils/updateTracksOnBackend';
import reduxStore from 'src/reduxStore';

export default function useHandleLabelClick({ foSpineAsset, setFoSpineAssetOnTrack }) {
    const { sceneId: selectedSceneId } = useParams();

    return useCallback(
        function _handleLabelClick() {
            const reduxState = reduxStore.getState();
            const { selectedFoSpineAssetTrackId } = reduxState.sceneEditor;

            setFoSpineAssetOnTrack({
                sceneId: selectedSceneId,
                trackId: selectedFoSpineAssetTrackId,
                foSpineAssetId: foSpineAsset.id,
                fallbackHistory: foSpineAsset.fallbackHistory,
            });
            updateTracksOnBackend({ selectedSceneId });
        },
        [foSpineAsset, setFoSpineAssetOnTrack, selectedSceneId],
    );
}
