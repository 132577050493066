import { Graphics } from 'pixi.js';

import {
    MAIN_KNOB_SIZE,
    MAIN_KNOB_COLOR,
    MAIN_KNOB_OUTLINE_SIZE,
    MAIN_KNOB_OUTLINE_COLOR,
} from 'src/pixi/tools/MaskTool/constants/maskControls';

export default function makeMainKnobGraphics() {
    const main = new Graphics();
    main.lineStyle(MAIN_KNOB_OUTLINE_SIZE, MAIN_KNOB_OUTLINE_COLOR);
    main.beginFill(MAIN_KNOB_COLOR);
    main.drawRect(0, 0, MAIN_KNOB_SIZE, MAIN_KNOB_SIZE);
    main.endFill();
    main.pivot.set(
        (MAIN_KNOB_SIZE + MAIN_KNOB_OUTLINE_SIZE) / 2,
        (MAIN_KNOB_SIZE + MAIN_KNOB_OUTLINE_SIZE) / 2,
    );

    return main;
}
