import { calcPointWithAngleAndRadius } from 'src/utils/mathUtils/geoMathUtils';

import calcNeighborPointsAngleWithAxisX from './calcNeighborPointsAngleWithAxisX';
import calcDistancesFromNeighbors from './calcDistancesFromNeighbors';

const NEW_TANGENT_PORTION = 1 / 3;

export default function convertCornerPointToTangentPoint({ points, point }) {
    const tangent1AngleWithAxisX = calcNeighborPointsAngleWithAxisX({
        points,
        point,
    });

    const {
        distanceFromPointMainToPreviousMain,
        distanceFromPointMainToNextMain,
    } = calcDistancesFromNeighbors({ point, points });

    const tangent1RelativeToMain = calcPointWithAngleAndRadius({
        angle: tangent1AngleWithAxisX,
        radius: distanceFromPointMainToNextMain * NEW_TANGENT_PORTION,
    });
    const tangent2RelativeToMain = calcPointWithAngleAndRadius({
        angle: tangent1AngleWithAxisX + Math.PI,
        radius: distanceFromPointMainToPreviousMain * NEW_TANGENT_PORTION,
    });
    const tangent1 = {
        x: point.main.x + tangent1RelativeToMain.x,
        y: point.main.y + tangent1RelativeToMain.y,
    };
    const tangent2 = {
        x: point.main.x + tangent2RelativeToMain.x,
        y: point.main.y + tangent2RelativeToMain.y,
    };
    point.tangent1 = tangent1;
    point.tangent2 = tangent2;
    point.isTangentsLinked = true;
}
