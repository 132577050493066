import {
    OUTLINE,
    MAIN_KNOB,
    TANGENT_KNOB,
    BG_LAYER,
    MASK_TOOL_CONTROLS_CURSOR_MAP,
    TARGET,
} from 'src/pixi/tools/MaskTool/setMaskControlsCursors/constants/maskControlsCursors';
import getMaskedDisplayObjectContent from 'src/pixi/tools/MaskTool/utils/getMaskedDisplayObjectContent';

export default function setMaskControlsCursors(maskTool) {
    if (!maskTool.target) {
        return;
    }
    const { type: maskToolType, maskOutline, pointControlsById, app, target } = maskTool;

    maskOutline.cursor = MASK_TOOL_CONTROLS_CURSOR_MAP[maskToolType][OUTLINE];
    app.stage.cursor = MASK_TOOL_CONTROLS_CURSOR_MAP[maskToolType][BG_LAYER];

    const content = getMaskedDisplayObjectContent(target);
    content.cursor = MASK_TOOL_CONTROLS_CURSOR_MAP[maskToolType][TARGET];

    Object.values(pointControlsById).forEach((pointControl) => {
        const { mainKnob, tangent1Knob, tangent2Knob } = pointControl;

        mainKnob.cursor = MASK_TOOL_CONTROLS_CURSOR_MAP[maskToolType][MAIN_KNOB];
        tangent1Knob.cursor = MASK_TOOL_CONTROLS_CURSOR_MAP[maskToolType][TANGENT_KNOB];
        tangent2Knob.cursor = MASK_TOOL_CONTROLS_CURSOR_MAP[maskToolType][TANGENT_KNOB];
    });
}
