import { useCallback, useContext, useState } from 'react';

import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';

export default function useTimeMarkers({ initialStartTime, initialDuration }) {
    const { currentTimeManager } = useContext(SceneEditorContext);

    const [startTime, setStartTime] = useState(initialStartTime || currentTimeManager.currentTime);

    const [duration, setDuration] = useState(initialDuration || 2);

    const handleStartTimeChange = useCallback(
        function _handleStartTimeChange(event) {
            setStartTime(Math.max(0, Number(event.target.value)));
        },
        [setStartTime],
    );

    const handleDurationChange = useCallback(
        function _handleDurationChange(event) {
            setDuration(Math.max(0, Number(event.target.value)));
        },
        [setDuration],
    );

    return {
        startTime,
        duration,
        handleStartTimeChange,
        handleDurationChange,
    };
}
