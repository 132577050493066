import makeBgGhostLayerGraphics from './makeBgGhostLayerGraphics';
import {
    handleStageDragStart,
    handleStageDragMove,
    handleStageDragEnd,
    handleEscDown,
    handleWindowResize,
} from './bgGhostLayerInteractionHandlers';

export default function makeBgGhostLayer(maskTool) {
    const { app } = maskTool;

    const bgGhostLayer = makeBgGhostLayerGraphics();
    maskTool.bgGhostLayer = bgGhostLayer;
    bgGhostLayer.maskTool = maskTool;

    maskTool.addChild(bgGhostLayer);

    app.stage.interactive = true;
    app.stage.buttonMode = true;
    app.stage
        .on('pointerdown', handleStageDragStart)
        .on('pointermove', handleStageDragMove)
        .on('pointerup', handleStageDragEnd)
        .on('pointerupoutside', handleStageDragEnd);
    app.stage.maskTool = maskTool;

    window.addEventListener('keydown', handleEscDown);
    window.addEventListener('resize', handleWindowResize);
    window.maskTool = maskTool;

    return bgGhostLayer;
}
