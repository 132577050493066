import { isNumber, isArray, isString } from 'lodash';

import expressions from 'src/utils/businessLogic/expressions/constants/expressions';

import generateExpressionTimelineKeyId from './generateExpressionTimelineKeyId';

export default function generateExpressionTimelineKey({ id: receivedId, start, end, influences }) {
    if (!isNumber(start)) {
        throw new Error(
            `can't generate an expressionTimelineKey without valid start. Received ${start}`,
        );
    }
    if (!isNumber(end)) {
        throw new Error(
            `can't generate an expressionTimelineKey without valid end. Received ${end}`,
        );
    }
    if (!(isArray(influences) && influences.length)) {
        throw new Error("can't generate an expressionTimelineKey without at least one influence.");
    } else {
        influences.forEach((influence, index) => {
            if (!isString(influence.expression)) {
                throw new Error(
                    `received an invalid type influence.expression: ${influence.expression} at ${index}.`,
                );
            }

            const isExpressionValid =
                isString(influence.expression) &&
                Object.values(expressions).some((expression) => {
                    return expression === influence.expression;
                });
            if (!isExpressionValid) {
                throw new Error(
                    `received an influence.expression: ${influence.expression} at ${index}. It is not found among valid expressions.`,
                );
            }

            if (!isNumber(influence.amount)) {
                throw new Error(
                    `received an invalid type influence.expression: ${influence.expression} at ${index}`,
                );
            }
        });
    }
    const id = receivedId || generateExpressionTimelineKeyId();

    return {
        id,
        start,
        end,
        influences,
    };
}
