import { useEffect } from 'react';

// import getIsAnyOfTracksUpdating from 'src/components/views/SceneEditor/Timeline/utils/getIsAnyOfTracksUpdating';

function useReactToTimelineChange({
    // updatingTracksById,
    isPixiMounted,
    jumpAppToTime,
    currentTimeManager,
    handlePauseButtonClick,
}) {
    useEffect(
        function _useReactToTimelineChange() {
            // const isAnyTrackUpdating = getIsAnyOfTracksUpdating(updatingTracksById);
            // const isAllTracksStable = !isAnyTrackUpdating;

            if (
                isPixiMounted
                // // todo - Not sure why this check was implemented in the first place.
                // // This seems to be the only place where updatingTracksById is consumed,
                // // => if this is not not used any more, updatingTracksById is unnecessary.
                // // If after a while this check is still not necessary,
                // // come back and remove updatingTracksById from here, redux and everywhere else.
                // && isAllTracksStable
            ) {
                const { currentTime } = currentTimeManager;
                handlePauseButtonClick();
                jumpAppToTime(currentTime);
            }
        },
        [
            // updatingTracksById,
            isPixiMounted,
            jumpAppToTime,
            currentTimeManager,
            handlePauseButtonClick,
        ],
    );
}

export default useReactToTimelineChange;
