export default function getPointsAndControlsDiff(maskTool) {
    const { pointControlsById } = maskTool;
    const points = maskTool.getPoints();

    const missingPoints = points.filter((point) => {
        const isPointHasMatchingControl = !!pointControlsById[point.id];
        return !isPointHasMatchingControl;
    });
    const obsoleteControls = Object.values(pointControlsById).filter((pointControl) => {
        const isPointControlHasMatchingPoint = points.some((point) => point.id === pointControl.id);

        return !isPointControlHasMatchingPoint;
    });

    return { missingPoints, obsoleteControls };
}
