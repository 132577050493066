export const DRAW_MASK = 'Draw mask';
export const MOVE_MASK_CONTROLS = 'Move mask controls';
export const ADD_POINT = 'Add point';
export const DELETE_POINT = 'Delete point';
export const TOGGLE_KNOB = 'Toggle knob';

export const DEFAULT_MASK_TOOL_TYPE = MOVE_MASK_CONTROLS;

export default Object.freeze({
    DRAW_MASK,
    MOVE_MASK_CONTROLS,
    ADD_POINT,
    DELETE_POINT,
    TOGGLE_KNOB,
});
