import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ToolButton from 'src/components/shared/buttonFactory/ToolButton';

import './styles.scss';

export default function PauseToolButton(props) {
    const { className, ...attributes } = props;

    return (
        <ToolButton className={classnames('PauseToolButton', className)} {...attributes}>
            &#10074; &#10074;
        </ToolButton>
    );
}

PauseToolButton.propTypes = {
    ...ToolButton.propTypes,
    className: PropTypes.string,
};

PauseToolButton.defaultProps = {
    ...ToolButton.defaultProps,
    className: '',
};
