import pixiStore from 'src/pixi/pixiStore';
import resetTransform from 'src/pixi/utils/resetTransform';
import matchTwoDisplayObjectTransforms from 'src/pixi/utils/matchTwoDisplayObjectTransforms';
import extractTransformValuesFromDisplayObject from 'src/pixi/utils/extractTransformValuesFromDisplayObject';

import captureFrame from './captureFrame';
import buildFrameName from './buildFrameName';

export default async function captureAppFrames({
    fps,
    startTime,
    endTime,
    currentTimeManager,
    jumpAppToTime,
    ext,
    updateCapturedFramesPercent,
    isDownloadCancelledRef,
    isDownloadFrames,
    videoDownloadScale,
    videoDownloadPaddingPercentX,
    videoDownloadPaddingPercentY,
}) {
    const app = pixiStore.pixiAppManager.getPixiApp();

    const duration = endTime - startTime;
    const frameDuration = 1 / fps;
    const numberOfFrames = duration / frameDuration;

    const initialTime = currentTimeManager.currentTime;

    // ***********
    // todo - this is a temp measure until a scene's zoom and capture frame feature is implemented.
    const spineDisplayObject = app.stage.children.find((child) => {
        return child instanceof PIXI.spine.Spine;
    });
    const transform = extractTransformValuesFromDisplayObject(spineDisplayObject);
    let displayFrame = new PIXI.Rectangle(0, 0, app.screen.width, app.screen.height);
    if (videoDownloadScale) {
        resetTransform(spineDisplayObject);
        spineDisplayObject.scale.set(videoDownloadScale, videoDownloadScale);
        const bounds = spineDisplayObject.getBounds();
        const paddingX = bounds.width * videoDownloadPaddingPercentX;
        const paddingY = bounds.height * videoDownloadPaddingPercentY;
        spineDisplayObject.position.x = -bounds.x + paddingX;
        spineDisplayObject.position.y = -bounds.y + paddingY;
        displayFrame = new PIXI.Rectangle(
            0,
            0,
            bounds.width + 2 * paddingX,
            bounds.height + 2 * paddingY,
        );
    }
    // ***********

    const allImageFiles = [];
    for (let frameIndex = 0; frameIndex < numberOfFrames; frameIndex++) {
        if (isDownloadCancelledRef.current) {
            break;
        }
        const frameName = buildFrameName({ frameIndex });

        const frameTime = frameIndex * frameDuration + startTime;
        const imageFileProm = captureFrame({
            frameTime,
            frameName,
            jumpAppToTime,
            displayFrame,
            app,
            ext,
            isDownloadFrame: isDownloadFrames,
        });
        // eslint-disable-next-line no-await-in-loop
        const imageFile = await imageFileProm;
        allImageFiles.push(imageFile);

        updateCapturedFramesPercent({ numberOfFrames });
    }

    // todo - see above temp measure todo ***********
    if (videoDownloadScale) {
        matchTwoDisplayObjectTransforms({
            source: transform,
            target: spineDisplayObject,
        });
    }
    // *********************************

    jumpAppToTime(initialTime);

    return allImageFiles;
}
