import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { tracksByIdLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import TrackLayerDnD from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD';
import HiddenTrackLayer from 'src/components/views/SceneEditor/Timeline/Track/HiddenTrackLayer';

import styles from './styles.module.scss';

export default function Track(props) {
    const { className, trackId, tracksById, setClipsById, setTracks, buildClipError } = props;

    const trackLayers = tracksById[trackId].layers;
    return (
        <div className={classnames(styles.Track, className)}>
            {[...trackLayers.allIds].reverse().map((trackLayerId) => {
                const layer = trackLayers.byId[trackLayerId];
                const {
                    settings: { isHidden, isDisabled },
                } = layer;

                return isHidden ? (
                    <HiddenTrackLayer
                        className={styles.Track__hiddenTrackLayer}
                        key={trackLayerId}
                    />
                ) : (
                    <TrackLayerDnD
                        className={styles.Track__trackLayerDnD}
                        trackId={trackId}
                        trackLayerId={trackLayerId}
                        tracksById={tracksById}
                        setClipsById={setClipsById}
                        setTracks={setTracks}
                        isDisabled={isDisabled}
                        buildClipError={buildClipError}
                        key={trackLayerId}
                    />
                );
            })}
        </div>
    );
}

Track.propTypes = {
    className: PropTypes.string,
    trackId: PropTypes.string.isRequired,
    tracksById: tracksByIdLike.isRequired,
    setClipsById: PropTypes.func.isRequired,
    setTracks: PropTypes.func.isRequired,
    buildClipError: PropTypes.func.isRequired,
};

Track.defaultProps = {
    className: '',
};
