import {
    KNOB_INTERACTION_SCALE,
    KNOB_DEFAULT_SCALE,
} from 'src/pixi/tools/TransformTool/constants/transformKnobs';
import movePivotByAmountWithoutMovingParent from 'src/pixi/utils/pivotUtils/movePivotByAmountWithoutMovingParent';
import { handleGhostLayerDragEnd } from 'src/pixi/tools/TransformTool/makeGhostLayer/ghostLayerDragHandlers';
import * as eventNames from 'src/pixi/tools/TransformTool/constants/eventNames';

import calcPivotChangeAmount from './calcPivotChangeAmount';

export function handlePivotKnobDragStart(event) {
    this.data = event.data;
    this.isDragging = true;
    this.scale.set(KNOB_INTERACTION_SCALE);
    this.preDragPosition = this.data.getLocalPosition(this.parent);
    this.dragStartPointerOffset = this.data.getLocalPosition(this);
}

export function handlePivotKnobDragMove() {
    if (this.isDragging) {
        const currentPositon = this.data.getLocalPosition(this.parent);

        this.position.set(
            currentPositon.x - this.dragStartPointerOffset.x,
            currentPositon.y - this.dragStartPointerOffset.y,
        );
    }
}

export function handlePivotKnobDragEnd(event) {
    const { transformTool, isDragging } = this;
    const isFirstInteractionAfterApplyingTransformTool = !isDragging;
    if (isFirstInteractionAfterApplyingTransformTool) {
        handleGhostLayerDragEnd.call(transformTool.ghostLayer, event);
        return;
    }
    const { target } = transformTool;
    const currentPositon = this.data.getLocalPosition(this.parent);

    const pivotChangeAmount = calcPivotChangeAmount({
        transformTool,
        currentPosRelToRotateLayer: currentPositon,
        preDragPosRelToRotateLayer: this.preDragPosition,
    });

    movePivotByAmountWithoutMovingParent({ displayObject: target, amount: pivotChangeAmount });
    transformTool.positionKnobs();

    this.isDragging = false;
    this.data = null;
    this.preDragPosition = null;
    this.dragStartPointerOffset = null;
    this.scale.set(KNOB_DEFAULT_SCALE);

    transformTool.runEventHandlersFor(eventNames.PIVOT_DRAG_END);
    transformTool.runEventHandlersFor(eventNames.ANY_DRAG_END);
}
