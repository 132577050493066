import { isNumber, isString } from 'lodash';

export default function generateFoGraphemeChar({ graphemeChar, duration, startTime }) {
    if (!isNumber(startTime) || startTime < 0) {
        throw new Error(
            `Cannot make a foGraphemeChar without valid startTime. Received: ${startTime}`,
        );
    }
    if (!isNumber(duration) || duration <= 0) {
        throw new Error(
            `Cannot make a foGraphemeChar without valid duration. Received: ${duration}`,
        );
    }
    if (!isString(graphemeChar) || !graphemeChar) {
        throw new Error(
            `Cannot make a foGraphemeChar without valid graphemeChar. Received: ${graphemeChar}`,
        );
    }

    return {
        graphemeChar,
        duration,
        startTime,
    };
}
