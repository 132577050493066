import { useCallback } from 'react';

export default function useHandleNameInputChange({ foTextureComp, setFoTextureComp }) {
    return useCallback(
        function _handleNameInputChange(event) {
            const name = event.target.value;
            const newFoTextureComp = { ...foTextureComp, name };
            setFoTextureComp(newFoTextureComp);
        },
        [foTextureComp, setFoTextureComp],
    );
}
