import getConflictInfo from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getConflictInfo';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import shiftClipsToRight from 'src/utils/businessLogic/clipUtils/insertClip/insertShift/shiftClipsToRight';

export default function shiftClipsToRightIfConflict({
    newClip,
    clipsById,
    isCompactShift,
    timeWidth,
}) {
    const {
        sortedClips,
        isConflictToRight,
        rightNeighbourIndex,
        rightNeighbour,
    } = getConflictInfo({ newClip, clipsById, timeWidth });

    let newClipsById = clipsById;
    if (isConflictToRight) {
        const newClipEnd = getClipEnd({ clip: newClip, timeWidth });
        const overlapWidth = newClipEnd - rightNeighbour.position;

        newClipsById = shiftClipsToRight({
            startIndex: rightNeighbourIndex,
            clipsById: newClipsById,
            sortedClips,
            shiftAmount: overlapWidth,
            isCompactShift,
            timeWidth,
        });
    }

    return newClipsById;
}
