import generateFoGraphemeChar from 'src/utils/businessLogic/transcript/generateFoGraphemeChar';
import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';

export default function buildFoGraphemeCharsFromSortedClips({
    sortedClips,
    timeWidth,
    startTimeOffset = 0,
}) {
    const foGraphemeChars = [];
    sortedClips.forEach((childClip) => {
        const {
            data: { graphemeChar },
            position,
        } = childClip;

        if (!graphemeChar) {
            return;
        }

        const startTime = position / timeWidth + startTimeOffset;

        const clipWidth = getClipWidth({ clipData: childClip.data, timeWidth });
        const duration = clipWidth / timeWidth;

        const foGraphemeChar = generateFoGraphemeChar({ duration, graphemeChar, startTime });

        return foGraphemeChars.push(foGraphemeChar);
    });

    return foGraphemeChars;
}
