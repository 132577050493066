import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

const assetSelectorSlice = createSlice({
    name: 'assetSelector',
    initialState,
    reducers: {
        setFoSpineAsset(state, action) {
            const foSpineAsset = action.payload;

            state.foSpineAssets.byId[foSpineAsset.id] = foSpineAsset;

            const isAlreadyExisting = state.foSpineAssets.allIds.some((currentId) => {
                return currentId === foSpineAsset.id;
            });
            if (!isAlreadyExisting) {
                state.foSpineAssets.allId.push(foSpineAsset.id);
            }
        },
        setFoSpineAssetTextureModifications(state, action) {
            const { textureModifications, foSpineAssetId } = action.payload;
            const foSpineAsset = state.foSpineAssets.byId[foSpineAssetId];
            foSpineAsset.modifications.textures = textureModifications;
        },
        removeFoSpineAssetFromStore(state, action) {
            const foSpineAssetId = action.payload;

            delete state.foSpineAssets.byId[foSpineAssetId];

            const filteredIds = state.foSpineAssets.allIds.filter((currentId) => {
                return currentId !== foSpineAssetId;
            });

            state.foSpineAssets.allIds = filteredIds;
        },
    },
});

export const {
    setFoSpineAsset,
    setFoSpineAssetTextureModification,
    setFoSpineAssetTextureModifications,
    removeFoSpineAssetFromStore,
} = assetSelectorSlice.actions;

export default assetSelectorSlice.reducer;
