export default function clearTransformTool(transformTool) {
    transformTool.visible = false;

    const { target } = transformTool;

    if (target) {
        delete target.originalSize;
        delete transformTool.target;
    }
}
