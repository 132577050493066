export default function calcDeleteTextureModificationAtomsByCompId({
    foSpineAsset,
    foTextureCompId,
}) {
    const { textures: textureModifications } = foSpineAsset.modifications;

    const newTextureModifications = textureModifications.map((textureModification) => {
        const newTextureModification = { ...textureModification };
        if (foTextureCompId === newTextureModification.current) {
            newTextureModification.current = '';
        }

        const newAtomsById = {};
        Object.keys(textureModification.atomsByCompId).forEach(
            function _filterTextureModificaitonAtoms(currentFoTextureCompId) {
                if (currentFoTextureCompId !== foTextureCompId) {
                    const currentTextureModificationAtom =
                        textureModification.atomsByCompId[currentFoTextureCompId];
                    newAtomsById[currentFoTextureCompId] = currentTextureModificationAtom;
                }
            },
        );
        newTextureModification.atomsByCompId = newAtomsById;

        return newTextureModification;
    });

    const filteredTextureModifications = newTextureModifications.filter((textureModification) => {
        const isNoAtoms = Object.keys(textureModification.atomsByCompId).length === 0;
        return !isNoAtoms;
    });

    return filteredTextureModifications;
}
