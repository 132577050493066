import { getSceneEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import reduxStore from 'src/reduxStore';
import { setScene } from 'src/components/views/SceneSelector/sceneSelectorSlice';
import { isSceneSelectorUsingLocalMocks } from 'src/config/localMocksFlags';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function updateSceneOnBackend({
    sceneChanges: receivedSceneChanges,
    selectedSceneId,
}) {
    if (isSceneSelectorUsingLocalMocks) {
        return;
    }

    const reduxState = reduxStore.getState();
    const sceneChanges =
        receivedSceneChanges || reduxState.sceneSelector.scenes.byId[selectedSceneId];

    const sceneEndpoint = getSceneEndpoint(selectedSceneId);
    try {
        const patchResponse = await fetchWithAuth(sceneEndpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sceneChanges),
        });

        if (patchResponse.ok) {
            return;
        }

        const errorData = await patchResponse.json();
        console.error(
            'There was a problem with persisting scene changes to the backend',
            errorData.errors[0].message,
        );
    } catch (err) {
        console.error(err);
    }

    const getResponse = await fetchWithAuth(sceneEndpoint);
    if (getResponse.ok) {
        const confirmedScene = await getResponse.json();

        console.log('Rolling scene back to the backend version.');

        reduxStore.dispatch(setScene(confirmedScene));
    } else {
        console.log("Couldn't roll tracks back to the backend version.");
    }
}
