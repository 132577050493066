import { useState, useEffect, useContext, useRef } from 'react';

import SceneEditorContext from 'src/components/views/SceneEditor/context/SceneEditorContext';

import getIfTimeIndicatorIsAtTimelineEnd from './getIfTimeIndicatorIsAtTimelineEnd';
import useScrollTimelineToTimeIndicatorEfficiently from './useScrollTimelineToTimeIndicatorEfficiently';

export default function useCurrentTimeState({
    horizontalScrollElemRef,
    handlePauseButtonClick,
    timelineWidth,
    timeWidth,
    zoomLevel,
}) {
    const { currentTimeManager } = useContext(SceneEditorContext);

    const { currentTime } = currentTimeManager;
    const [currentTimeState, setCurrentTimeState] = useState(currentTime);

    const {
        scrollTimelineToTimeIndicatorEfficiently,
    } = useScrollTimelineToTimeIndicatorEfficiently({
        timeWidth,
        zoomLevel,
        horizontalScrollElemRef,
    });

    const dependencyRef = useRef({});
    dependencyRef.current = {
        setCurrentTimeState,
        currentTimeManager,
        scrollTimelineToTimeIndicatorEfficiently,
        handlePauseButtonClick,
        timelineWidth,
        timeWidth,
        zoomLevel,
    };

    useEffect(function _updateTimelineCurrentTime() {
        let frameReqId;
        const { currentTimeManager } = dependencyRef.current;
        let { currentTime: previousTime } = currentTimeManager;

        function updateCurrentTimeStateIfChanged() {
            const {
                setCurrentTimeState,
                currentTimeManager,
                scrollTimelineToTimeIndicatorEfficiently,
                handlePauseButtonClick,
                timelineWidth,
                timeWidth,
                zoomLevel,
            } = dependencyRef.current;

            const { currentTime } = currentTimeManager;

            if (previousTime !== currentTime) {
                const isTimeIndicatorAtTimelineEnd = getIfTimeIndicatorIsAtTimelineEnd({
                    currentTime,
                    timelineWidth,
                    timeWidth,
                    zoomLevel,
                });
                if (isTimeIndicatorAtTimelineEnd) {
                    handlePauseButtonClick();
                } else {
                    setCurrentTimeState(currentTime);
                    scrollTimelineToTimeIndicatorEfficiently();
                }
            }
            frameReqId = requestAnimationFrame(updateCurrentTimeStateIfChanged);
            previousTime = currentTime;
        }

        frameReqId = requestAnimationFrame(updateCurrentTimeStateIfChanged);
        return () => {
            cancelAnimationFrame(frameReqId);
        };
    }, []);

    return currentTimeState;
}
