import getClipWidth from 'src/utils/businessLogic/clipUtils/getClipWidth';

export default function getClipGroupCutoffPositions({ clipGroup, timeWidth }) {
    const {
        data: { headTrimPercent, tailTrimPercent },
    } = clipGroup;

    const untrimmedParentWidth = getClipWidth({
        clipData: clipGroup.data,
        timeWidth,
        isGetUntrimmed: true,
    });
    const cutoffStartPosition = untrimmedParentWidth * headTrimPercent;
    const cutoffEndPosition = untrimmedParentWidth * (1 - tailTrimPercent);

    return { cutoffStartPosition, cutoffEndPosition };
}
