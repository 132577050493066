import getClipsInRange from 'src/utils/businessLogic/clipUtils/getClipsInRange';

export default function getLayersWithClipsInRange({
    layersWithSortedClips,
    timeIndicatorPosition,
    timeWidth,
    isPreparingForPlayback,
}) {
    const layersWithClipsInRange = layersWithSortedClips.map(function _getClipsInRange(
        sortedClips,
    ) {
        const clipsInRange = getClipsInRange({
            sortedClips,
            rangeStart: timeIndicatorPosition,
            rangeEnd: isPreparingForPlayback ? undefined : timeIndicatorPosition,
            isIncludeIntersections: true,
            timeWidth,
        });
        return clipsInRange;
    });

    return layersWithClipsInRange;
}
