import clipTypes from 'src/components/constants/clipTypes';
import traverseClipDepthFirst from 'src/utils/businessLogic/clipUtils/traverseClipDepthFirst';
import calcUngrouppedClip from 'src/utils/businessLogic/clipUtils/calcUngrouppedClip';

export default function flattenClipsToOneGroupDeep({ clipsById, timeWidth }) {
    const flattenedClips = [];

    if (!Object.keys(clipsById).length) {
        return flattenedClips;
    }

    const fakeRootClip = {
        position: 0,
        data: {
            clipsById,
            type: clipTypes.CLIP_GROUP,
            headTrimPercent: 0,
            tailTrimPercent: 0,
        },
    };

    traverseClipDepthFirst({
        clip: fakeRootClip,
        clipCallback({ clip, context }) {
            const { parentClipGroupOnTimeline, isIncludeChildClips, isClipGroupCutOff } = context;
            if (!isIncludeChildClips || isClipGroupCutOff) {
                return;
            }

            const clipOnTimeline = calcUngrouppedClip({
                parentClipGroup: parentClipGroupOnTimeline,
                clip,
                timeWidth,
            });
            if (clipOnTimeline) {
                context.flattenedClips.push(clipOnTimeline);
            }
        },
        clipGroupCallback({ clipGroup, context }) {
            const { parentClipGroupOnTimeline, isClipGroupCutOff } = context;
            if (isClipGroupCutOff) {
                return context;
            }

            let clipGroupOnTimeline = clipGroup;
            if (parentClipGroupOnTimeline) {
                clipGroupOnTimeline = calcUngrouppedClip({
                    parentClipGroup: parentClipGroupOnTimeline,
                    clip: clipGroup,
                    timeWidth,
                });
            }

            const updatedContext = {
                ...context,
                isIncludeChildClips: false,
                isClipGroupCutOff: !clipGroupOnTimeline,
                parentClipGroupOnTimeline: clipGroupOnTimeline,
            };

            const isContainsClipGroup = Object.values(clipGroup.data.clipsById).some((clip) => {
                return clip.data.type === clipTypes.CLIP_GROUP;
            });

            if (clipGroupOnTimeline) {
                if (isContainsClipGroup) {
                    updatedContext.isIncludeChildClips = true;
                } else {
                    updatedContext.flattenedClips.push(clipGroupOnTimeline);
                }
            }

            return updatedContext;
        },
        context: {
            flattenedClips,
            isClipGroupCutOff: false,
        },
    });

    return flattenedClips;
}
