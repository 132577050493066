import { calcDistanceBetweenTwoPoints } from 'src/utils/mathUtils/geoMathUtils';

export default function calcBezierCurveLength({
    startPoint,
    controlPoint1,
    controlPoint2,
    destinationPoint,
}) {
    const numberOfApproximationSegments = 10;
    let totalDistance = 0.0;
    let previousPoint = { ...startPoint };

    for (let i = 1; i <= numberOfApproximationSegments; ++i) {
        const t = i / numberOfApproximationSegments;
        const t2 = t * t;
        const t3 = t2 * t;
        const nt = 1.0 - t;
        const nt2 = nt * nt;
        const nt3 = nt2 * nt;

        const x =
            nt3 * startPoint.x +
            3.0 * nt2 * t * controlPoint1.x +
            3.0 * nt * t2 * controlPoint2.x +
            t3 * destinationPoint.x;

        const y =
            nt3 * startPoint.y +
            3.0 * nt2 * t * controlPoint1.y +
            3 * nt * t2 * controlPoint2.y +
            t3 * destinationPoint.y;

        const currentPoint = { x, y };
        const currentDistance = calcDistanceBetweenTwoPoints({
            point1: currentPoint,
            point2: previousPoint,
        });
        previousPoint = currentPoint;

        totalDistance += currentDistance;
    }

    return totalDistance;
}
