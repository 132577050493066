import { sortBy } from 'lodash';

import clipTypes from 'src/components/constants/clipTypes';

export default function traverseClipDepthFirst({
    clip,
    clipCallback,
    clipGroupCallback,
    clipGroupPostTraversalCallback,
    context: contextFromParent,
}) {
    if (clip.data.type === clipTypes.CLIP_SINGLE) {
        if (clipCallback) {
            clipCallback({ clip, context: contextFromParent });
        }
    } else if (clip.data.type === clipTypes.CLIP_GROUP) {
        const sortedClips = sortBy(clip.data.clipsById, 'position');

        let contextForChildren = contextFromParent;
        if (clipGroupCallback) {
            contextForChildren =
                clipGroupCallback({ clipGroup: clip, context: contextFromParent }) ||
                contextFromParent;
        }

        sortedClips.forEach(function _traverseSortedClips(sortedClip) {
            traverseClipDepthFirst({
                clip: sortedClip,
                clipCallback,
                clipGroupCallback,
                clipGroupPostTraversalCallback,
                context: contextForChildren,
            });
        });

        if (clipGroupPostTraversalCallback) {
            clipGroupPostTraversalCallback({
                clipGroup: clip,
                context: contextFromParent,
            });
        }
    } else {
        console.warn("can't find clip type in", clip.data);
    }
}
