import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.scss';

export default function ClipErrorMessage(props) {
    const { className, clipError, ...attributes } = props;

    return (
        <div
            className={classnames(styles.ClipErrorMessage, className)}
            title={clipError.message}
            {...attributes}
        >
            {clipError.message}
        </div>
    );
}

ClipErrorMessage.propTypes = {
    className: PropTypes.string,
    clipError: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }),
};

ClipErrorMessage.defaultProps = {
    className: '',
    clipError: null,
};
