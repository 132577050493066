import calcGlobalScaleRatioOf2DisplayObject from 'src/pixi/utils/calcGlobalScaleRatioOf2DisplayObject';

export default function calcPivotChangeAmount({
    transformTool,
    currentPosRelToRotateLayer,
    preDragPosRelToRotateLayer,
}) {
    const { target } = transformTool;

    const scaleCorrection = calcGlobalScaleRatioOf2DisplayObject({
        displayObject1: target.parent,
        displayObject2: transformTool,
    });

    const pivotChangeAmount = {
        x: (currentPosRelToRotateLayer.x - preDragPosRelToRotateLayer.x) / scaleCorrection.x,
        y: (currentPosRelToRotateLayer.y - preDragPosRelToRotateLayer.y) / scaleCorrection.y,
    };

    return pivotChangeAmount;
}
