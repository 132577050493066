import { useCallback } from 'react';

import useGroupSelectedClips from './useGroupSelectedClips';

export default function useNewGroupingActions({
    selectedSequentialClips,
    selectedTrackLayerClipsById,
    selectedTrackId,
    selectedLayerId,
    setClipsById,
    setClipSelection,
    groupName,
    clipGroupId,
    toggleGroupNameDialogue,
}) {
    const groupSelectedClips = useGroupSelectedClips({
        selectedSequentialClips,
        selectedTrackLayerClipsById,
        selectedTrackId,
        selectedLayerId,
        setClipsById,
        setClipSelection,
        groupName,
        clipGroupId,
    });

    const cancelNewGrouping = useCallback(
        function _handleClickOutsideInputField() {
            toggleGroupNameDialogue();
        },
        [toggleGroupNameDialogue],
    );

    const confirmNewGrouping = useCallback(
        function _handleClickOutsideInputField() {
            groupSelectedClips();
            toggleGroupNameDialogue();
        },
        [groupSelectedClips, toggleGroupNameDialogue],
    );

    return { cancelNewGrouping, confirmNewGrouping };
}
