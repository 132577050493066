import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { userDataLike } from 'src/components/views/UserProfile/userProfileSlice/propTypes';

import styles from './styles.module.scss';

function UserProfile(props) {
    const { className, userData } = props;

    return (
        <div className={classnames(styles.UserProfile, className)}>
            <h1>User Profile</h1>
            {userData && (
                <>
                    <div>{userData.username}</div>
                    <div>{userData.email}</div>
                </>
            )}
        </div>
    );
}

UserProfile.propTypes = {
    className: PropTypes.string,
    userData: userDataLike,
};

UserProfile.defaultProps = {
    className: '',
    userData: null,
};

function mapState(state) {
    const { userData } = state.userProfile;

    return {
        userData,
    };
}

export default connect(mapState)(UserProfile);
