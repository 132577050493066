import {
    calcAngleRadBetween2PointsAndAxisX,
    calculateRotatedPosition,
} from 'src/utils/mathUtils/geoMathUtils';
import getOtherTangentName from 'src/pixi/tools/MaskTool/utils/getOtherTangentName';

export default function makeTangentsOpposites({ point, baseTangentName }) {
    const baseTangent = point[baseTangentName];
    const baseTangentCurrentAngle = calcAngleRadBetween2PointsAndAxisX({
        point1: baseTangent,
        point2: point.main,
    });

    const otherTangentName = getOtherTangentName(baseTangentName);
    const otherTangent = point[otherTangentName];

    if (!otherTangent) {
        return;
    }
    const otherTangentCurrentAngle = calcAngleRadBetween2PointsAndAxisX({
        point1: otherTangent,
        point2: point.main,
    });
    const otherTangentNewAngle = baseTangentCurrentAngle + Math.PI;
    const angleChange = otherTangentNewAngle - otherTangentCurrentAngle;

    const otherTangentNewPosition = calculateRotatedPosition({
        point: otherTangent,
        anchor: point.main,
        angleChangeRad: angleChange,
    });

    otherTangent.x = otherTangentNewPosition.x;
    otherTangent.y = otherTangentNewPosition.y;
}
