import {
    REQUIRED_NUMBER_OF_FRAME_DIGITS,
    FRAME_NAME_BASE,
} from 'src/components/views/SceneEditor/Monitor/VideoDownloadButton/constants';
import addPaddingToString from 'src/utils/stringUtils/addPaddingToString';

export default function buildFrameName({ frameIndex }) {
    const currentNumberOfDigits = `${frameIndex}`.length;
    const missingNumberOfDigits = REQUIRED_NUMBER_OF_FRAME_DIGITS - currentNumberOfDigits;

    const frameName = addPaddingToString({
        string: FRAME_NAME_BASE,
        isPost: true,
        padCharacter: '0',
        paddedLength: missingNumberOfDigits,
        delimiter: '_',
        suffix: `${frameIndex}`,
    });

    return frameName;
}
