import { useEffect, useRef } from 'react';

import pixiStore from 'src/pixi/pixiStore';
import getChildById from 'src/pixi/utils/getChildById';
import getIfContainsChild from 'src/pixi/utils/getIfContainsChild';
import getTextureModificationAtomCurrentlyAssignedToSlot from 'src/utils/businessLogic/foSpineAsset/getTextureModificationAtomCurrentlyAssignedToSlot';
import highlightFoSpineAssetSlotByName from 'src/utils/businessLogic/foSpineAsset/highlightFoSpineAssetSlotByName';

import getTransformTool from './getTransformTool';
import placeFoTextureCompOfSlot from './placeFoTextureCompOfSlot';
import clearPlacement from './clearPlacement';
import useHandleTransformToolDragEnd from './useHandleTransformToolDragEnd';

export default function useUpdateSlotSelectionInPixi({
    foTextureComps,
    foSpineAsset,
    selectedSlotName,
    setFoSpineAssetTextureModifications,
    isFoSpineAssetLoaded,
    setFoSpineAssetLinkOnFoTextureComp,
}) {
    const transformToolRef = useRef();
    const foTextureCompDisplayObjectMapRef = useRef({});
    const foTextureCompRepMap = foTextureCompDisplayObjectMapRef.current;

    const handleTransformToolDragEnd = useHandleTransformToolDragEnd({
        foSpineAsset,
        selectedSlotName,
        setFoSpineAssetTextureModifications,
        transformToolRef,
        setFoSpineAssetLinkOnFoTextureComp,
    });

    useEffect(
        function _updateSlotSelectionInPixi() {
            if (!isFoSpineAssetLoaded) {
                return;
            }
            const app = pixiStore.pixiAppManager.getPixiApp();

            const spineAssetInstance = getChildById({
                parent: app.stage,
                id: foSpineAsset.id,
            });
            highlightFoSpineAssetSlotByName({
                spineAssetInstance,
                slotName: selectedSlotName,
                root: app.stage,
            });

            const transformTool = getTransformTool({
                transformToolRef,
                app,
                handleTransformToolDragEnd,
            });

            clearPlacement({ app, transformTool, foTextureCompRepMap });

            const textureModificationAtom = getTextureModificationAtomCurrentlyAssignedToSlot({
                foSpineAsset,
                slotName: selectedSlotName,
            });
            if (!textureModificationAtom) {
                return;
            }
            const foTextureComp = foTextureComps.byId[textureModificationAtom.foTextureCompId];
            placeFoTextureCompOfSlot({
                app,
                foTextureCompRepMap,
                foTextureComp,
                foSpineAsset,
                textureModificationAtom,
            }).then((foTextureCompDisplayObject) => {
                if (foTextureCompDisplayObject) {
                    const isOnStage = getIfContainsChild({
                        parent: app.stage,
                        child: foTextureCompDisplayObject,
                    });
                    if (isOnStage) {
                        transformTool.applyTo(foTextureCompDisplayObject);
                    }
                }
            });
        },
        [
            foSpineAsset,
            selectedSlotName,
            foTextureComps,
            foTextureCompRepMap,
            handleTransformToolDragEnd,
            isFoSpineAssetLoaded,
            setFoSpineAssetLinkOnFoTextureComp,
        ],
    );
}
