import getPivotPercent from 'src/pixi/utils/pivotUtils/getPivotPercent';
import getGlobalScale from 'src/pixi/utils/getGlobalScale';

import drawGhostLayer from './drawGhostLayer';

export default function positionGhostLayer(transformTool) {
    const { target } = transformTool;

    const pivotPercent = getPivotPercent(target);
    const pivotPercentAdjustedForFlip = { ...pivotPercent };
    if (Math.sign(target.scale.x) === -1) {
        pivotPercentAdjustedForFlip.x = 1 - pivotPercentAdjustedForFlip.x;
    }
    if (Math.sign(target.scale.y) === -1) {
        pivotPercentAdjustedForFlip.y = 1 - pivotPercentAdjustedForFlip.y;
    }

    const { ghostLayer } = transformTool;
    const { width, height } = target;
    let absoluteWidth = Math.abs(width);
    let absoluteHeight = Math.abs(height);

    const targetParentGlobalScale = getGlobalScale(target.parent);
    const transformToolGlobalScale = getGlobalScale(transformTool);
    absoluteWidth *= targetParentGlobalScale.x / transformToolGlobalScale.x;
    absoluteHeight *= targetParentGlobalScale.y / transformToolGlobalScale.y;

    drawGhostLayer({ ghostLayer, width: absoluteWidth, height: absoluteHeight });

    ghostLayer.position.set(
        -absoluteWidth * pivotPercentAdjustedForFlip.x,
        -absoluteHeight * pivotPercentAdjustedForFlip.y,
    );
}
