import { useEffect, useRef } from 'react';

import pixiStore from 'src/pixi/pixiStore';
import TimeTicker from 'src/utils/timeUtils/TimeTicker';

export default function useCurrentTimeManager() {
    const currentTimeManagerRef = useRef(new TimeTicker());

    useEffect(function _setupSceneCurrentTimeUpdates() {
        const app = pixiStore.pixiAppManager.getPixiApp();
        const currentTimeManager = currentTimeManagerRef.current;

        app.ticker.add(currentTimeManager.handleTimeTick, null, PIXI.UPDATE_PRIORITY.HIGH);

        return function _cleanup() {
            app.ticker.remove(currentTimeManager.handleTimeTick);
        };
    }, []);

    const currentTimeManager = currentTimeManagerRef.current;
    return currentTimeManager;
}
