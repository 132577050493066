import { isObject, isNumber } from 'lodash';

export default function assertScaleIsValid(scale) {
    if (!isObject(scale)) {
        throw new Error('scale has to be an object, received: ', scale);
    }
    if (!isNumber(scale.x)) {
        throw new Error('scale.x has to be a number, received: ', scale.x);
    }
    if (!isNumber(scale.y)) {
        throw new Error('scale.y has to be a number, received: ', scale.y);
    }
}
