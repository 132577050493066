import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { trackLayerLike } from 'src/components/views/SceneSelector/sceneSelectorSlice/propTypes';
import TrackLayerController from 'src/components/views/SceneEditor/Timeline/TrackControllers/AssetTrackController/TrackLayerControllers/TrackLayerController';
import TimelineContext from 'src/components/views/SceneEditor/Timeline/context/TimelineContext';

import TrackExpressionLayerControllerButtonWithMenu from './TrackExpressionLayerControllerButtonWithMenu';
import AddExpressionTimelineKeyButton from './AddExpressionTimelineKeyButton';
import EditExpressionTimelineKeyButton from './EditExpressionTimelineKeyButton';

import styles from './styles.module.scss';

export default function TrackExpressionLayerController(props) {
    const { className, layer, trackId } = props;

    const { setClipsById } = useContext(TimelineContext);

    return (
        <TrackLayerController
            className={classnames(styles.TrackExpressionLayerController, className)}
        >
            <TrackExpressionLayerControllerButtonWithMenu
                trackId={trackId}
                setClipsById={setClipsById}
                trackLayerId={layer.trackLayerId}
            />
            <AddExpressionTimelineKeyButton trackId={trackId} trackLayerId={layer.trackLayerId} />
            <EditExpressionTimelineKeyButton trackId={trackId} trackLayerId={layer.trackLayerId} />
        </TrackLayerController>
    );
}

TrackExpressionLayerController.propTypes = {
    className: PropTypes.string,
    trackId: PropTypes.string.isRequired,
    layer: trackLayerLike.isRequired,
};

TrackExpressionLayerController.defaultProps = {
    className: '',
};
