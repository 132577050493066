export default function matchTwoDisplayObjectTransforms({ source, target }) {
    target.position.x = source.position.x;
    target.position.y = source.position.y;
    target.rotation = source.rotation;
    target.pivot.x = source.pivot.x;
    target.pivot.y = source.pivot.y;
    target.scale.x = source.scale.x;
    target.scale.y = source.scale.y;

    if (target.anchor && source.anchor) {
        target.anchor.x = source.anchor.x;
        target.anchor.y = source.anchor.y;
    } else if (
        (target.anchor && !source.anchor && (target.anchor.x !== 0 || target.anchor.y !== 0)) ||
        (!target.anchor && source.anchor && (source.anchor.x !== 0 || source.anchor.y !== 0))
    ) {
        console.warn(
            "source and target, one has anchor the other doesn't. This might lead to unexpected results.",
        );
    }
}
