import { getSceneEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function fetchSceneUpdate({ sceneId, updateProps }) {
    try {
        const sceneEndpoint = getSceneEndpoint(sceneId);
        const response = await fetchWithAuth(sceneEndpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateProps),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.errors[0].message);
        }

        const fetchedScene = await response.json();
        return fetchedScene;
    } catch (err) {
        console.log(err);
        throw err;
    }
}
