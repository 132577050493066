import generateClip from 'src/utils/businessLogic/clipUtils/generateClip';

export default function generateEmptyClip({ position, duration }) {
    const clip = generateClip({
        position,
        clipId: '',
        data: {
            animationId: null,
            animationStart: 0,
            animationEnd: duration,
            originalDuration: duration,
            timeScale: 1,
        },
    });

    return clip;
}
