import {
    handleTangentKnobDragStart,
    handleTangentKnobDragMove,
    handleTangentKnobDragEnd,
} from './tangentKnobDragHandlers';

import makeTangentKnobGraphics from './makeTangentKnobGraphics';

export default function makeTangentKnob({ point, maskTool, tangentName }) {
    const tangentKnob = makeTangentKnobGraphics();
    tangentKnob.maskTool = maskTool;
    tangentKnob.pointId = point.id;
    tangentKnob.tangentName = tangentName;

    tangentKnob.interactive = true;
    tangentKnob.buttonMode = true;

    tangentKnob
        .on('pointerdown', handleTangentKnobDragStart)
        .on('pointermove', handleTangentKnobDragMove)
        .on('pointerup', handleTangentKnobDragEnd)
        .on('pointerupoutside', handleTangentKnobDragEnd);

    return tangentKnob;
}
