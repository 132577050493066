import { sortBy } from 'lodash';

import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';

export default function getConflictInfo({ newClip, clipsById, timeWidth }) {
    const sortedClips = sortBy(clipsById, 'position');
    const rightNeighbourIndex = sortedClips.findIndex((clip) => clip.position >= newClip.position);
    const rightNeighbour = sortedClips[rightNeighbourIndex];
    const leftNeighbourIndex = rightNeighbour ? rightNeighbourIndex - 1 : sortedClips.length - 1;

    const leftNeighbour = sortedClips[leftNeighbourIndex];
    const leftNeighbourEnd = leftNeighbour && getClipEnd({ clip: leftNeighbour, timeWidth });

    const newClipEnd = getClipEnd({ clip: newClip, timeWidth });
    const isConflictToRight = !!rightNeighbour && rightNeighbour.position < newClipEnd;
    const isConflictToLeft = !!leftNeighbour && newClip.position < leftNeighbourEnd;

    return {
        sortedClips,

        isConflictToRight,
        rightNeighbourIndex,
        rightNeighbour,

        isConflictToLeft,
        leftNeighbourIndex,
        leftNeighbour,
    };
}
