export default function clipNumber({
    number,
    topClip = Number.POSITIVE_INFINITY,
    bottomClip = Number.NEGATIVE_INFINITY,
}) {
    let clippedNumber = number;

    if (clippedNumber > topClip) {
        clippedNumber = topClip;
    }

    if (clippedNumber < bottomClip) {
        clippedNumber = bottomClip;
    }

    return clippedNumber;
}
