import { accessTypes } from 'src/components/constants/foSpineAsset';
import { CLOSED_MOUTH_CHARACTER } from 'src/components/constants/audio';

export default {
    foSpineAssets: {
        byId: {
            spineboy: {
                name: 'spineboy',
                id: 'spineboy',
                url: '/spine/spineboy/export/spineboy.json',
                idleAnimationId: 'idle',
                fallbackHistory: [],
                accessType: accessTypes.READ,
                modifications: {
                    textures: [],
                },
            },
            goblin: {
                name: 'goblin',
                id: 'goblin',
                url: '/spine/goblins/export/goblins.json',
                idleAnimationId: '',
                fallbackHistory: [],
                accessType: accessTypes.READ,
                modifications: {
                    textures: [],
                },
            },
            'zombie talking': {
                name: 'talking zombie',
                id: 'zombie talking',
                url: '/spine/talking zombie/skeleton.json',
                // idleAnimationId: 'lipsing/neutral',
                // idleAnimationId: 'foot_tab',
                idleAnimationId: '',
                fallbackHistory: [],
                accessType: accessTypes.READ,
                modifications: {
                    textures: [],
                },
                graphemeToAnimationMap: {
                    a: 'lipsing/AEI',
                    l: 'lipsing/L',
                    v: 'lipsing/F',
                    b: 'bpm',
                    ō: 'lipsing/OU',
                    s: 'lipsing/L',
                    k: 'lipsing/L',
                    [CLOSED_MOUTH_CHARACTER]: 'lipsing/neutral',
                },
            },
            zombie: {
                name: 'zombie',
                id: 'zombie',
                url: '/spine/zombie/skeleton.json',
                // idleAnimationId: 'foot_tab',
                idleAnimationId: '',
                // If a new foSpineAsset is generated from this,
                // this asset's id will be pushed into the array.
                // If the array is empty, that means the asset is the original.
                fallbackHistory: [],
                accessType: accessTypes.READ,
                modifications: {
                    textures: [],
                },
            },
            zombie_01: {
                name: 'zombie vahram',
                id: 'zombie_01',
                url: '/spine/zombie/skeleton.json',
                // idleAnimationId: '',
                idleAnimationId: 'foot_tab',
                fallbackHistory: ['zombie'],
                accessType: accessTypes.READ_AND_WRITE,
                modifications: {
                    textures: [
                        {
                            slotName: 'Chapter',
                            current: 'fo_texture_comp-1',
                            atomsByCompId: {
                                'fo_texture_comp-1': {
                                    slotName: 'Chapter',
                                    foTextureCompId: 'fo_texture_comp-1',
                                    relativeTransform: {
                                        positionDiffToSizeRatio: {
                                            x: 0.0051297759450322465,
                                            y: -0.32280893833090213,
                                        },
                                        pivot: {
                                            x: 81.28861566858137,
                                            y: 82.2081864972635,
                                        },
                                        rotationDiff: 0.028716781321262985,
                                        sizeRatio: {
                                            x: 0.5637265526448924,
                                            y: 0.7097106466793393,
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
            },
            cupid: {
                name: 'cupid',
                id: 'cupid',
                url: '/spine/cupid/Cupid.json',
                idleAnimationId: '',
                fallbackHistory: [],
                accessType: accessTypes.READ,
                modifications: {
                    textures: [],
                },
                graphemeToAnimationMap: {
                    a: 'grpm/a',
                    l: 'grpm/l',
                    v: 'grpm/w',
                    b: 'grpm/b',
                    ō: 'grpm/u',
                    s: 'grpm/l',
                    k: 'grpm/l',
                    [CLOSED_MOUTH_CHARACTER]: 'grpm/neutral',
                },
            },
            cupid3d: {
                name: 'cupid 3d',
                id: 'cupid3d',
                url: '/spine/cupid3d/skeleton.json',
                // idleAnimationId: 'body/idle',
                idleAnimationId: '',
                fallbackHistory: [],
                accessType: accessTypes.READ,
                modifications: {
                    textures: [],
                },
                graphemeToAnimationMap: {
                    a: 'grpm/a',
                    l: 'grpm/l',
                    v: 'grpm/v',
                    b: 'grpm/b',
                    ō: 'grpm/u',
                    s: 'grpm/s',
                    k: 'grpm/k',
                    [CLOSED_MOUTH_CHARACTER]: 'neutral',
                },
            },
            stepan: {
                name: 'stepan',
                id: 'stepan',
                url: '/spine/stepan/skeleton.json',
                // idleAnimationId: 'body/idle',
                idleAnimationId: '',
                fallbackHistory: [],
                accessType: accessTypes.READ,
                modifications: {
                    textures: [],
                },
                graphemeToAnimationMap: {
                    a: 'grpm/a',
                    l: 'grpm/l',
                    v: 'grpm/v',
                    b: 'grpm/b',
                    ō: 'grpm/u',
                    s: 'grpm/s',
                    k: 'grpm/k',
                    [CLOSED_MOUTH_CHARACTER]: 'neutral',
                },
            },
        },
        allIds: [
            'spineboy',
            'goblin',
            'zombie talking',
            'zombie',
            'zombie_01',
            'cupid',
            'cupid3d',
            'stepan',
        ],
    },
};
// const initialState = {
//     byId: {
//         zombie_01: {
//             name: 'zombie',
//             id: 'zombie_01',
//             url: '/spine/goblins/export/goblins.json',
//             idleAnimationId: 'walk',
//         },
//     },
//     allIds: ['zombie_01'],
// };
