import { useEffect } from 'react';

export default function useEnsureSelectedTrack({
    selectedScene,
    selectedFoSpineAssetTrackId,
    setSelectedFoSpineAssetTrackId,
    isSceneLoaded,
}) {
    let selectedTrack;
    if (isSceneLoaded) {
        selectedTrack = Object.values(selectedScene.tracks.byId).find(
            function _getIfTrackIsSelectedTrack(track) {
                return track.trackId === selectedFoSpineAssetTrackId;
            },
        );
    }
    useEffect(
        function _selectFirstTrackIfNonSelected() {
            if (isSceneLoaded && !selectedTrack) {
                const firstTrackId = selectedScene.tracks.allIds[0];
                setSelectedFoSpineAssetTrackId(firstTrackId);
            }
        },
        [selectedTrack, isSceneLoaded, selectedScene, setSelectedFoSpineAssetTrackId],
    );

    return selectedTrack;
}
