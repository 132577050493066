import { useRef } from 'react';

import convertAnimationListToTree from 'src/components/views/SceneEditor/Bins/Animations/hooks/useAnimationTreeNodes/convertAnimationListToTree';
import rearrangeAnimationTreeNodes from 'src/components/views/SceneEditor/Bins/Animations/hooks/useAnimationTreeNodes/rearrangeAnimationTreeNodes';

export default function useAnimationTreeNodes(filteredAnimations) {
    const prevFilteredAnimationsRef = useRef(filteredAnimations);
    const prevRearrangedAnimationTreeNodesRef = useRef([]);

    if (prevFilteredAnimationsRef.current !== filteredAnimations) {
        const animationTreeNodes = convertAnimationListToTree(filteredAnimations);
        const rearrangedAnimationTreeNodes = rearrangeAnimationTreeNodes(animationTreeNodes);
        prevFilteredAnimationsRef.current = filteredAnimations;
        prevRearrangedAnimationTreeNodesRef.current = rearrangedAnimationTreeNodes;
    }

    return prevRearrangedAnimationTreeNodesRef.current;
}
