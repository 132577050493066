import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    IS_PLAYING,
    IS_STOPPED,
    IS_PAUSED,
} from 'src/components/views/SceneEditor/constants/playbackStatuses';

import StopToolButton from 'src/components/shared/buttonFactory/StopToolButton';
import PlayToolButton from 'src/components/shared/buttonFactory/PlayToolButton';
import PauseToolButton from 'src/components/shared/buttonFactory/PauseToolButton';

import styles from './styles.module.scss';

export default function PlaybackControls(props) {
    const playbackControlsRef = useRef(null);
    const {
        className,
        onPlayButtonClick,
        onStopButtonClick,
        onPauseButtonClick,
        playbackStatus,
    } = props;

    return (
        <div ref={playbackControlsRef} className={classnames(styles.PlaybackContols, className)}>
            <PlayToolButton onClick={onPlayButtonClick} isActive={playbackStatus === IS_PLAYING} />
            <PauseToolButton onClick={onPauseButtonClick} isActive={playbackStatus === IS_PAUSED} />
            <StopToolButton onClick={onStopButtonClick} isActive={playbackStatus === IS_STOPPED} />
        </div>
    );
}

PlaybackControls.propTypes = {
    className: PropTypes.string,
    onPlayButtonClick: PropTypes.func.isRequired,
    onStopButtonClick: PropTypes.func.isRequired,
    onPauseButtonClick: PropTypes.func.isRequired,
};

PlaybackControls.defaultProps = {
    className: '',
};
