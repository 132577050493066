import getGlobalScale from 'src/pixi/utils/getGlobalScale';

export default function calcGlobalScaleRatioOf2DisplayObject({ displayObject1, displayObject2 }) {
    const displayObject1GlobalScale = getGlobalScale(displayObject1);
    const displayObject2GlobalScale = getGlobalScale(displayObject2);

    const scaleRatio = {
        x: displayObject1GlobalScale.x / displayObject2GlobalScale.x,
        y: displayObject1GlobalScale.y / displayObject2GlobalScale.y,
    };

    return scaleRatio;
}
