import { noop } from 'lodash';

export default function traverseBreadthFirst({ root, callback, getIsStop = noop }) {
    const arr = [];
    arr.push(root);
    for (let currentIndex = 0; currentIndex < arr.length; currentIndex++) {
        const node = arr[currentIndex];

        callback(node);

        const isStop = getIsStop(node);
        if (isStop) {
            return;
        }

        if (node.children) {
            for (let childIndex = 0; childIndex < node.children.length; childIndex++) {
                const childNode = node.children[childIndex];
                arr.push(childNode);
            }
        }
    }
}
