export default function getFoSpineAssetsByTrackId(state, { selectedSceneId }) {
    const {
        assetSelector: { foSpineAssets },
    } = state;

    const { tracks } = state.sceneSelector.scenes.byId[selectedSceneId];

    const foSpineAssetsByTrackId = {};
    Object.keys(tracks.byId).forEach((trackId) => {
        const track = tracks.byId[trackId];
        const { id: foSpineAssetId } = track.foSpineAsset;
        const foSpineAsset = foSpineAssets.byId[foSpineAssetId];
        foSpineAssetsByTrackId[trackId] = foSpineAsset;
    });

    return foSpineAssetsByTrackId;
}
