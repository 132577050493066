import { Graphics } from 'pixi.js';

import {
    KNOB_SIZE,
    KNOB_OUTLINE_SIZE,
} from 'src/pixi/tools/TransformTool/constants/transformKnobs';

export default function makeResizeKnobGraphics() {
    const resizeKnob = new Graphics();
    resizeKnob.lineStyle(KNOB_OUTLINE_SIZE, 0xffffff, 1);
    resizeKnob.alpha = 0.8;
    resizeKnob.beginFill(0x000000);
    resizeKnob.drawRect(0, 0, KNOB_SIZE, KNOB_SIZE);
    resizeKnob.endFill();
    resizeKnob.pivot.set((KNOB_SIZE + KNOB_OUTLINE_SIZE) / 2, (KNOB_SIZE + KNOB_OUTLINE_SIZE) / 2);

    return resizeKnob;
}
