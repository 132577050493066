import reduxStore from 'src/reduxStore';
import { isSceneSelectorUsingLocalMocks } from 'src/config/localMocksFlags';
import { updateAudioTrack } from 'src/components/views/AudioSelector/audioSelectorSlice';

import fetchTransciptUpdates from './fetchTransciptUpdates';

async function updateTranscriptsOnBackend({ selectedSceneId }) {
    if (isSceneSelectorUsingLocalMocks) {
        return;
    }

    const reduxState = reduxStore.getState();
    const { tracks } = reduxState.sceneSelector.scenes.byId[selectedSceneId];
    const { timeWidth } = reduxState.timeline;

    try {
        const updateResponses = await fetchTransciptUpdates({ tracks, timeWidth });

        const failedResponses = updateResponses.filter((updateResponse) => {
            return !updateResponse.ok;
        });

        if (failedResponses.length) {
            const errorDataPromArr = failedResponses.map((failedResponse) => {
                return failedResponse.json();
            });
            const errorDataArr = await Promise.all(errorDataPromArr);
            errorDataArr.forEach((errorData) => {
                console.error(
                    'There was a problem with updating foTranscriptWord to the backend',
                    errorData.errors[0].message,
                );
            });
        } else {
            const audioTracksProm = updateResponses.map((updateResponse) => {
                return updateResponse.json();
            });
            const audioTracks = await Promise.all(audioTracksProm);
            audioTracks.forEach((audioTrack) => {
                reduxStore.dispatch(updateAudioTrack(audioTrack));
            });
        }
    } catch (err) {
        console.error(err);
    }
}

const flagsBySceneId = {};
export default async function debouncedUpdate(...args) {
    const { selectedSceneId } = args[0];
    flagsBySceneId[selectedSceneId] = flagsBySceneId[selectedSceneId] || {
        timeoutId: null,
        isUpdatePending: false,
    };

    const { isUpdatePending, timeoutId } = flagsBySceneId[selectedSceneId];
    if (timeoutId) {
        clearTimeout(timeoutId);
    }

    if (isUpdatePending) {
        flagsBySceneId[selectedSceneId].timeoutId = setTimeout(() => {
            flagsBySceneId[selectedSceneId].timeoutId = null;
            debouncedUpdate(...args);
        }, 300);
    } else {
        flagsBySceneId[selectedSceneId].timeoutId = null;
        flagsBySceneId[selectedSceneId].isUpdatePending = true;
        await updateTranscriptsOnBackend(...args);
        flagsBySceneId[selectedSceneId].isUpdatePending = false;
    }
}
