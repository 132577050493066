import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

export default function Button(props) {
    const { className, isActive, isDisabled, nodeRef, ...attributes } = props;
    return (
        <button
            className={classnames(
                'Button',
                {
                    'Button--active': isActive,
                    'Button--disabled': isDisabled,
                },
                className,
            )}
            disabled={isDisabled}
            type='button'
            ref={nodeRef}
            {...attributes}
        />
    );
}

Button.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

Button.defaultProps = {
    className: '',
    isActive: false,
    isDisabled: false,
};
