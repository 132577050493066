import { useCallback, useRef } from 'react';

const ZOOM_STEP = 0.1;

export default function useZoomButtonInteractions({ updateZoom, zoomLevel }) {
    const zoomLevelRef = useRef();
    zoomLevelRef.current = zoomLevel;

    const handleMinusButtonDown = useCallback(
        function _handleMinusButtonDown() {
            const zoomLevel = zoomLevelRef.current;
            const newZoomLevel = Math.max(zoomLevel - ZOOM_STEP, 0);
            updateZoom(newZoomLevel);
        },
        [updateZoom],
    );

    const handlePlusButtonDown = useCallback(
        function _handlePlusButtonDown() {
            const zoomLevel = zoomLevelRef.current;
            const newZoomLevel = zoomLevel + ZOOM_STEP;
            updateZoom(newZoomLevel);
        },
        [updateZoom],
    );

    return { handleMinusButtonDown, handlePlusButtonDown };
}
