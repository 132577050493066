import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function TrackController(props) {
    const { className, name = 'TrackLayer', children, ...attributes } = props;

    return (
        <div className={classnames(styles.TrackController, className)} {...attributes}>
            {name && (
                <div className={classnames(styles.TrackController__name)} title={name}>
                    {name}
                </div>
            )}
            {children}
        </div>
    );
}

TrackController.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
};

TrackController.defaultProps = {
    className: '',
    name: '',
};
