export default function getMatchingInstanceAnimation({ clip, spineInstanceAnimations }) {
    const animationId = clip?.data?.animationId;
    let instanceAnimation = null;
    if (animationId) {
        instanceAnimation = spineInstanceAnimations.find((animation) => {
            return animation.name === animationId;
        });
    }

    return instanceAnimation;
}
