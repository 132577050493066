import { useState, useCallback } from 'react';

export default function useSigninFormPassword() {
    const [password, setPassword] = useState('');
    const handlePasswordChange = useCallback(
        function _handlePasswordChange(event) {
            setPassword(event.target.value);
        },
        [setPassword],
    );

    return { password, handlePasswordChange };
}
