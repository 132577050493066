import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

export default function Textarea(props) {
    const { className, ...attributes } = props;

    return <textarea className={classnames('Textarea', className)} {...attributes} />;
}

Textarea.propTypes = {
    className: PropTypes.string,
};

Textarea.defaultProps = {
    className: '',
};
