import { useEffect, useRef, useState } from 'react';

import mountPixi from 'src/pixi/utils/mountPixi';
import placeSpineAssetInstanceOnScreen from 'src/spine/utils/placeSpineAssetInstanceOnScreen';
import pixiStore from 'src/pixi/pixiStore';
import loadFoSpineAsset from 'src/utils/businessLogic/foSpineAsset/loadFoSpineAsset';

import setupInteractions from './setupInteractions';
import removeInteractions from './removeInteractions';

export default function useSetupTextureManagerApp({
    pixiContainerElemRef,
    foSpineAsset,
    selectedSlotName,
    selectSlotByName,
    foTextureComps,
}) {
    const [isFoSpineAssetLoaded, setIsFoSpineAssetLoaded] = useState(false);

    const changeableParamsRef = useRef();
    changeableParamsRef.current = {
        selectedSlotName,
        selectSlotByName,
        foTextureComps,
        setIsFoSpineAssetLoaded,
    };

    useEffect(() => {
        mountPixi(pixiContainerElemRef.current);
        const app = pixiStore.pixiAppManager.getPixiApp();

        loadFoSpineAsset({ app, foSpineAsset, foTextureComps })
            .then((spineAssetInstance) => {
                placeSpineAssetInstanceOnScreen({
                    spineAssetInstance,
                    app,
                });

                setupInteractions({
                    app,
                    spineAssetInstance,
                    changeableParamsRef,
                });

                const { setIsFoSpineAssetLoaded, selectedSlotName } = changeableParamsRef.current;

                const slot = spineAssetInstance.skeleton.findSlot(selectedSlotName);
                if (!slot) {
                    const { selectSlotByName } = changeableParamsRef.current;
                    selectSlotByName({ slotName: '' });
                }

                setIsFoSpineAssetLoaded(true);
            })
            .catch((error) => {
                console.log('error: ', error);
            });

        return function _dismantleTexureManagerApp() {
            pixiStore.pixiAppManager.clearPixiApp();
            removeInteractions();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isFoSpineAssetLoaded;
}
