import { useRef, useEffect } from 'react';

export default function useIsComponentMountedRef() {
    const isComponentMountedRef = useRef(false);
    useEffect(function _handleMountAndUnmount() {
        isComponentMountedRef.current = true;

        return function _cleanup() {
            isComponentMountedRef.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isComponentMountedRef;
}
