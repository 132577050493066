import { getSceneEndpoint } from 'src/utils/backendUtils/apiEndpoints';
import fetchWithAuth from 'src/utils/backendUtils/fetchWithAuth';

export default async function fetchScene(sceneId) {
    try {
        const sceneEndpoint = getSceneEndpoint(sceneId);

        const response = await fetchWithAuth(sceneEndpoint);

        if (response.ok) {
            const fetchedScene = await response.json();
            return fetchedScene;
        }

        const errorData = await response.json();
        throw new Error(errorData.errors[0].message);
    } catch (err) {
        console.log(err);
        throw err;
    }
}
