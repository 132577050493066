import getAnimationOriginalDuration from 'src/spine/utils/getAnimationOriginalDuration';
import buildClipForAnimation from 'src/utils/businessLogic/clipUtils/buildClipForAnimation';
import selectExpressionInflucenceByLikeleyhood from 'src/utils/businessLogic/trackLayerUtils/generateTrackLayersFromExpressions/selectExpressionInflucenceByLikeleyhood';
import { LAYER_DEFAULT_MIX_DURATION } from 'src/components/views/SceneEditor/Timeline/constants/defaultValues';

import getMostRelevantAnimationName from './getMostRelevantAnimationName';
import fillAreaWithClips from './fillAreaWithClips';

export default function buildClipsByIdForExpression({
    bodyPart,
    expressionTimelineKey,
    position: startPosition,
    expressionDuration,
    foSpineAssetId,
    animationsByExpression,
    bodyPartsAlreadyUsedForThisKey,
    timeWidth,
}) {
    const selectedInfluence = selectExpressionInflucenceByLikeleyhood({
        expressionTimelineKey,
    });

    const animationName = getMostRelevantAnimationName({
        bodyPart,
        influence: selectedInfluence,
        animationsByExpression,
        bodyPartsAlreadyUsedForThisKey,
    });

    let clipsById = {};
    if (!animationName) {
        return clipsById;
    }

    const { animationFamily, isStatic } = animationsByExpression[selectedInfluence.expression][
        animationName
    ];

    // todo - when custom mix duration is implemented, use whatever mix duration the clip will have.
    const staticClipDuration = LAYER_DEFAULT_MIX_DURATION * 2.5;
    const isMultipleStaticAnimsNeeded = !!isStatic && animationFamily?.length > 1;
    if (isMultipleStaticAnimsNeeded) {
        clipsById = fillAreaWithClips({
            startPosition,
            areaDuration: expressionDuration,
            foSpineAssetId,
            timeWidth,
            animationNames: animationFamily,
            firstAnimationName: animationName,
            clipDuration: staticClipDuration,
        });
    } else {
        const originalDuration = getAnimationOriginalDuration({
            animationId: animationName,
            foSpineAssetId,
        });

        const clip = buildClipForAnimation({
            animationId: animationName,
            duration: expressionDuration,
            originalDuration,
            position: startPosition,
        });

        clipsById[clip.clipId] = clip;
    }

    return clipsById;
}
