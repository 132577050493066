import { useEffect } from 'react';

import getChildById from 'src/pixi/utils/getChildById';
import pixiStore from 'src/pixi/pixiStore';
import clipTypes from 'src/components/constants/clipTypes';
import expressionsByAnimation from 'src/utils/businessLogic/expressions/constants/expressionsByAnimation';

import logMissingAndExtraAnimationNames from './logMissingAndExtraAnimationNames';

export default function useExtractAnimations({ foSpineAssetId, setAnimations, isPixiMounted }) {
    useEffect(
        function _extractAnimations() {
            const app = pixiStore.pixiAppManager.getPixiApp();
            const selectedTrackInstance = getChildById({
                parent: app.stage,
                id: foSpineAssetId,
            });

            if (!selectedTrackInstance) {
                return;
            }

            const { animations } = selectedTrackInstance.skeleton.data;
            const animationsById = {};
            animations.forEach((animation) => {
                const { name: animationName, duration } = animation;
                animationsById[animationName] = {
                    type: clipTypes.CLIP_SINGLE,
                    animationId: animationName,
                    title: animationName.replaceAll('_', ' ').toLowerCase(),
                    originalDuration: duration,
                    animationStart: 0,
                    animationEnd: duration,
                    timeScale: 1,
                };
            });

            setAnimations({ animationsById });

            logMissingAndExtraAnimationNames({
                animationsById,
                foSpineAssetId,
                expressionsByAnimation,
            });
        },
        [foSpineAssetId, setAnimations, isPixiMounted],
    );
}
