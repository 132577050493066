import getAllowedLeftShiftAmount from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getAllowedLeftShiftAmount';
import getClipEnd from 'src/utils/businessLogic/clipUtils/getClipEnd';
import trimClipData from 'src/utils/businessLogic/clipUtils/trimClipData';
import getConflictInfo from 'src/components/views/SceneEditor/Timeline/Track/TrackLayerDnD/utils/getConflictInfo';

export default function limitLeftExpansionIfNoRoom({
    newClip,
    clipsById,
    isCompactShift,
    timeWidth,
    trimMode,
}) {
    const {
        //
        isConflictToLeft,
        leftNeighbour,
        leftNeighbourIndex,
        sortedClips,
    } = getConflictInfo({ newClip, clipsById, timeWidth });

    if (!isConflictToLeft) {
        return newClip;
    }

    const leftNeighbourEnd = getClipEnd({ clip: leftNeighbour, timeWidth });
    const shiftAmount = leftNeighbourEnd - newClip.position;

    const allowedLeftShiftAmount = getAllowedLeftShiftAmount({
        shiftAmount,
        sortedClips,
        startIndex: leftNeighbourIndex,
        isCompactShift,
        timeWidth,
    });
    const leftmostAllowedPosition = leftNeighbourEnd - allowedLeftShiftAmount;
    const illegalWidthChange = leftmostAllowedPosition - newClip.position;
    const allowedNewClipTrimmedData = trimClipData({
        headTrimAmount: illegalWidthChange,
        clipData: newClip.data,
        trimMode,
        timeWidth,
    });

    const allowedNewClip = {
        ...newClip,
        position: leftmostAllowedPosition,
        data: allowedNewClipTrimmedData,
    };

    return allowedNewClip;
}
