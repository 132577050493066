import {
    handleEscDown,
    handleStageClick,
    handleWindowResize,
} from 'src/pixi/tools/TransformTool/makeBgGhostLayer/bgGhostLayerInteractionHandlers';

export default function destroyTransformTool(transformTool) {
    transformTool.clear();
    window.removeEventListener('keydown', handleEscDown);
    window.removeEventListener('resize', handleWindowResize);
    delete window.transformTool;

    delete transformTool.pivotKnob.transformTool;
    delete transformTool.pivotKnob;

    Object.values(transformTool.rotateKnobs).forEach((rotateKnob) => {
        delete rotateKnob.transformTool;
    });
    delete transformTool.rotateKnobs;

    Object.values(transformTool.resizeKnobs).forEach((resizeKnob) => {
        delete resizeKnob.transformTool;
    });
    delete transformTool.resizeKnobs;

    delete transformTool.ghostLayer.transformTool;
    delete transformTool.ghostLayer;

    delete transformTool.bgGhostLayer.transformTool;
    delete transformTool.bgGhostLayer;

    transformTool.stage.off('pointerdown', handleStageClick);
    delete transformTool.stage.transformTool;
    delete transformTool.stage;
}
