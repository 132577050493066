import { useEffect, useState } from 'react';

import { isSceneSelectorUsingLocalMocks } from 'src/config/localMocksFlags';
import fetchScene from 'src/utils/backendUtils/fetchScene';

export default function useLoadScene({ selectedSceneId, isSceneLoaded, setScene }) {
    const [sceneLoadingErrorMessage, setSceneLoadingErrorMessage] = useState('');

    useEffect(
        function _loadSceneIfNotLoaded() {
            async function fetchSceneForView() {
                if (selectedSceneId && !isSceneLoaded) {
                    try {
                        if (isSceneSelectorUsingLocalMocks) {
                            throw new Error('Empty local mocks are not supposed to be loaded');
                        }
                        const loadedScene = await fetchScene(selectedSceneId);
                        setScene(loadedScene);
                    } catch (err) {
                        setSceneLoadingErrorMessage(err);
                    }
                }
            }
            fetchSceneForView();
        },
        [selectedSceneId, isSceneLoaded, setScene],
    );

    return sceneLoadingErrorMessage;
}
